import { Box, BoxProps, styled } from '@mui/material';

import { setColorLightness } from 'functions/color';

export interface ElementContainerProps extends BoxProps<'div'> {
  color?: string;
  disabled?: boolean;
}

const ElementContainer = styled(Box, {
  shouldForwardProp: propName => propName !== 'color' && propName !== 'disabled',
})<ElementContainerProps>(({ theme, color, disabled }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  backgroundColor: disabled ? theme.palette.grey[100] : (color && setColorLightness(color, 93)) || theme.palette.white,
  position: 'relative',
  overflow: 'hidden',
  [`& > .actions`]: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    // backgroundColor: color ? setColorLightness(color, 93) : theme.palette.white,
    // padding: `0 ${theme.spacing(0.5)}`,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    backgroundImage: disabled
      ? `linear-gradient(90deg, transparent 0px, ${theme.palette.grey[100]} ${theme.spacing(1)}, ${theme.palette.grey[100]} 100%)`
      : `linear-gradient(90deg, transparent 0px, ${color ? setColorLightness(color, 93) : theme.palette.white} ${theme.spacing(
          1,
        )}, ${color ? setColorLightness(color, 93) : theme.palette.white} 100%)`,
    opacity: 0,
  },
  [`&:hover > .actions`]: {
    opacity: 1,
  },
}));

export default ElementContainer;
