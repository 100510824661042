import {
  BlockMetadata,
  BlockMetadataNumberContent,
  BlockMetadataOptionContent,
  BlockMetadataStringContent,
  MetadataType,
} from '@dametis/core';

export const isBlockMetadataStringContentValid = (content: BlockMetadataStringContent): boolean => content?.value?.trim().length > 0;

export const isBlockMetadataNumberContentValid = (content: BlockMetadataNumberContent): boolean =>
  !Number.isNaN(parseFloat(`${content?.value}`));

export const isBlockMetadataOptionContentValid = (content: BlockMetadataOptionContent): boolean => content.selectedOptionId !== null;

export const isBlockMetadataValid = (metadata: BlockMetadata): boolean => {
  if (metadata.type === MetadataType.STRING) {
    return isBlockMetadataStringContentValid(metadata.content as BlockMetadataStringContent);
  }
  if (metadata.type === MetadataType.NUMBER) {
    return isBlockMetadataNumberContentValid(metadata.content as BlockMetadataNumberContent);
  }
  if (metadata.type === MetadataType.OPTION) {
    return isBlockMetadataOptionContentValid(metadata.content as BlockMetadataOptionContent);
  }
  return false;
};
