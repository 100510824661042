import { createSvgIcon } from '@mui/material';

export const BatchOutlined = createSvgIcon(
  <svg
    color="currentcolor"
    fill="currentcolor"
    height="24px"
    stroke="currentcolor"
    viewBox="0 -960 960 960"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M240-280h240v-80H240v80Zm120-160h240v-80H360v80Zm120-160h240v-80H480v80ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z" />
  </svg>,
  'BatchOutlined',
);
