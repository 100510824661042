import { alpha, useTheme } from '@mui/material';
import type { Series, TooltipFormatterContextObject } from 'highcharts';
import { FC, useMemo } from 'react';

import { safeInterpolationSearch } from 'components/DaChart/helpers/safeInterpolationSearch';

import SeriesBullet from '../UI/SeriesBullet';
import SeriesContainer from '../UI/SeriesContainer';
import SeriesLabel from '../UI/SeriesLabel';
import SeriesValue from '../UI/SeriesValue';

type Props = Omit<TooltipFormatterContextObject, 'x'> & {
  seriesItem: Series;
};

const LineSeriesTooltip: FC<Props> = ({ seriesItem, series, point }) => {
  const theme = useTheme();

  const xData = useMemo(() => seriesItem.data.map(dataPoint => dataPoint.x), [seriesItem.data]);
  const yData = useMemo(() => seriesItem.data.map(dataPoint => dataPoint.y), [seriesItem.data]);
  const truePointIndex = useMemo(() => safeInterpolationSearch(xData, yData, point.x, point.y), [xData, yData, point.x, point.y]);
  const truePoint = useMemo(() => seriesItem.data[truePointIndex] ?? point, [seriesItem.data, truePointIndex, point]);

  return (
    <SeriesContainer color={seriesItem.userOptions.id === series.userOptions.id ? alpha(truePoint.color.toString(), 0.3) : undefined}>
      <SeriesBullet color={truePoint.color.toString()} />
      <SeriesLabel style={{ marginRight: theme.spacing(3) }}>{seriesItem.userOptions.name}</SeriesLabel>
      <SeriesValue point={truePoint} series={seriesItem} />
    </SeriesContainer>
  );
};

export default LineSeriesTooltip;
