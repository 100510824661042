import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BlockInfo, BlockTypeInfo, UUID } from '@dametis/core';

export interface LegoState {
  // currentFolderUuid: UUID | null;
  blockTypesById: Record<UUID, BlockTypeInfo>;
  blocksById: Record<UUID, BlockInfo>;
  navigationTools: {
    groupByType: boolean;
  };
}

export const initialState: LegoState = {
  // currentFolderUuid: null,
  blockTypesById: {},
  blocksById: {},
  navigationTools: {
    groupByType: true,
  },
};

export const legoSlice = createSlice({
  name: 'lego',
  initialState,
  reducers: {
    // setCurrentFolderUuid: (state, action: PayloadAction<UUID | null>) => {
    //   state.currentFolderUuid = action.payload;
    // },
    setBlockTypesById: (state, action: PayloadAction<Record<UUID, BlockTypeInfo>>) => {
      state.blockTypesById = action.payload;
    },
    setBlocksById: (state, action: PayloadAction<Record<UUID, BlockInfo>>) => {
      state.blocksById = action.payload;
    },
    setNavigationGroupByType: (state, action: PayloadAction<boolean>) => {
      state.navigationTools.groupByType = action.payload;
    },
    clearLegoStore: () => initialState,
  },
});

export const { setBlockTypesById, setBlocksById, setNavigationGroupByType } = legoSlice.actions;

export default legoSlice.reducer;
