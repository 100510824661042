import { ShareType } from 'types/share';

export default {
  title: {
    shared: 'Partage avancé',
    unassigned: 'Non assigné',
    custom: 'Personnalisé',
    teams: 'Équipes',
    users: 'Utilisateurs',
  },
  text: {
    users: 'Utilisateurs',
    list: 'Équipes',
    custom: 'Personnalisé',
    owner: 'Propriétaire',
    you: 'Vous',
    notfound: 'Aucun utilisateur/équipe ne correspond à cette recherche',
    addPeople: 'Ajouter une personne ou une équipe...',
    public: 'Public',
    unassigned: 'Non assigné',
  },
  button: {
    add: 'Ajouter',
    ok: 'OK',
  },
  select: {
    reader: 'Lecteur',
    writer: 'Éditeur',
  },
  invitations: {
    title: "Partager via lien d'invitation",
  },
  alert: {
    public: 'Toutes les personnes possédant ce lien pourront se créer un compte.',
    reportPrivate: "Le rapport doit d'abord être rendu public afin d'être partagé avec des utilisateurs n'ayant pas encore de compte.",
    synopticPrivate: "Le synoptique doit d'abord être rendu public afin d'être partagé avec des utilisateurs n'ayant pas encore de compte.",
    playgroundPrivate:
      "Le playground doit d'abord être rendu public afin d'être partagé avec des utilisateurs n'ayant pas encore de compte.",
  },
  toast: {
    [`shareSuccess_${ShareType.REPORT}`]: 'Le rapport a bien été partagé.',
    [`privatizeSuccess_${ShareType.REPORT}`]: 'Le rapport a bien été rendu privé.',
    [`shareSuccess_${ShareType.PLAYGROUND}`]: 'Le playground a bien été partagé.',
    [`privatizeSuccess_${ShareType.PLAYGROUND}`]: 'Le playground a bien été rendu privé.',
    [`shareSuccess_${ShareType.SYNOPTIC}`]: 'Le synoptique a bien été partagé.',
    [`privatizeSuccess_${ShareType.SYNOPTIC}`]: 'Le synoptique a bien été rendu privé.',
    [`shareSuccess_${ShareType.ALARM}`]: "L'alarme a bien été partagée.",
    [`privatizeSuccess_${ShareType.ALARM}`]: "L'alarme a bien été rendue privée.",
    [`shareSuccess_${ShareType.PROJECT}`]: 'Le projet a bien été partagé.',
    [`privatizeSuccess_${ShareType.PROJECT}`]: 'Le projet a bien été rendu privé.',
    addUserSuccess: "L'utilisateur a bien été ajouté au partage.",
    addTeamSuccess: "L'équipe a bien été ajoutée au partage.",
    deleteUserSuccess: "L'utilisateur a bien été supprimé du partage.",
    deleteTeamSuccess: "L'équipe a bien été supprimée du partage.",
    changeUserRoleSuccess: "Les droits de modification de l'utilisateur ont bien été modifiés.",
    changeTeamRoleSuccess: "Les droits de modification de l'équipe ont bien été modifiés.",
    shareError: 'Une erreur est survenue lors de la modification des droits de partages.',
  },
};
