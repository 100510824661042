import { Add, RefreshOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { DataGridPremium, GridPaginationModel, GridRow, GridRowProps, useGridApiRef } from '@mui/x-data-grid-premium';
import { subMilliseconds } from 'date-fns';
import Fuse from 'fuse.js';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { BoxInfo, PromiseLimit, RawPoint } from '@dametis/core';

import CenteredPage from 'components/UI/CenteredPage/CenteredPage';
import CircularLoader from 'components/UI/CircularLoader/CircularLoader';
import Header from 'components/UI/Header/Header';
import HeaderPrimary from 'components/UI/Header/HeaderPrimary';
import HeaderPrimaryLeft from 'components/UI/Header/HeaderPrimaryLeft';
import HeaderPrimaryLeftTitle from 'components/UI/Header/HeaderPrimaryLeftTitle';
import HeaderPrimaryRight from 'components/UI/Header/HeaderPrimaryRight';
import HeaderPrimaryRightButton from 'components/UI/Header/HeaderPrimaryRightButton';
import SearchInput from 'components/UI/SearchInput/SearchInput';
import { onColumnHeaderClick, onColumnHeaderDoubleClick } from 'functions/dataGrid/autoresize';
import { sdk } from 'sdk';
import { useDispatch } from 'store';
import { displaySdkErrorToast } from 'store/actions/toasts';

import NewBox from '../Box/NewBox';

import { getGridColDefObj } from './BoxesGridColumns';

export enum BoxStatus {
  UNKNOWN = 'UNKNOWN',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export interface ExtendedBoxInfo extends BoxInfo {
  status: BoxStatus;
  lastSeen: Date;
}

const BOXES_GRID_SIZE = 50;
const PAGE_SIZE_OPTIONS = [25, 50, 100];

export const BOX_STATUS_LIMIT_DATE = subMilliseconds(new Date(), 60000);
const Boxes: FC = () => {
  const { t } = useTranslation('admin');
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const [filter, setFilter] = useState<string>('');
  const [allBoxes, setAllBoxes] = useState<ExtendedBoxInfo[]>([]);
  const [filteredBoxes, setFilteredBoxes] = useState<ExtendedBoxInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [newBoxDialogOpen, setNewBoxDialogOpen] = useState<boolean>(false);

  const gridColDef = useMemo(() => Object.values(getGridColDefObj()), []);

  const [pageModel, setPageModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: BOXES_GRID_SIZE,
  });

  const handleChangePageSize = useCallback((model: GridPaginationModel) => {
    setPageModel(model);
  }, []);

  const fuse = useMemo(() => {
    const keys = ['name', 'reference', 'machineId'];
    const index = Fuse.createIndex(keys, allBoxes);

    return new Fuse(allBoxes, { keys, shouldSort: true, threshold: 0.3 }, index);
  }, [allBoxes]);

  const handleFilterBoxes = useCallback(
    (newFilter: string) => {
      setFilter(newFilter);

      if (newFilter.length === 0) {
        setFilteredBoxes(allBoxes);
        return;
      }

      if (debounceTimeout.current !== null) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        debounceTimeout.current = null;

        setFilteredBoxes(fuse.search(newFilter).map(result => result.item));
      }, 500);
    },
    [allBoxes, fuse],
  );

  const handleNewBox = useCallback(() => {
    setNewBoxDialogOpen(true);
  }, []);

  const buildExtendedBoxInfo = useCallback((boxInfo: BoxInfo, rawBoxStatus: RawPoint): ExtendedBoxInfo => {
    const lastSeen = rawBoxStatus === undefined ? new Date(0) : new Date(rawBoxStatus.time);
    let status: BoxStatus;
    if (rawBoxStatus === undefined) {
      status = BoxStatus.UNKNOWN;
    } else if (new Date(rawBoxStatus.time) > BOX_STATUS_LIMIT_DATE) {
      status = BoxStatus.ONLINE;
    } else {
      status = BoxStatus.OFFLINE;
    }

    return { ...boxInfo, lastSeen, status };
  }, []);

  const fetchAllBoxes = useCallback(async () => {
    setLoading(true);
    let boxes: BoxInfo[] = [];

    try {
      boxes = (await sdk.box.ListAll({})).data;
    } catch (err) {
      dispatch(displaySdkErrorToast(err));
      console.error(err);
    }

    const builtBoxes = await PromiseLimit.do(boxes, async box => {
      try {
        const { data: rawBoxStatus } = await sdk.box.ReadStatus(box.uuid);
        return buildExtendedBoxInfo(box, rawBoxStatus);
      } catch (err) {
        console.error(err);
        return { ...box, status: BoxStatus.UNKNOWN, lastSeen: new Date(0) };
      }
    });

    setAllBoxes(builtBoxes);
    setFilteredBoxes(builtBoxes);
    setLoading(false);
  }, [buildExtendedBoxInfo, dispatch]);

  useEffect(() => {
    void fetchAllBoxes();
  }, [fetchAllBoxes]);

  const DataGridRow: FC<GridRowProps> = useCallback(
    props => (
      <Box component={NavLink} sx={{ textDecoration: 'none', color: 'inherit' }} to={`/admin/boxes/${props.row.uuid}`}>
        <GridRow {...props} />
      </Box>
    ),
    [],
  );

  return (
    <CenteredPage>
      <Header>
        <HeaderPrimary>
          <HeaderPrimaryLeft>
            <HeaderPrimaryLeftTitle>{t('boxes.title.boxes')}</HeaderPrimaryLeftTitle>
          </HeaderPrimaryLeft>
          <HeaderPrimaryRight>
            <SearchInput
              autoFocus
              extendable
              placeholder={t('boxes.nameReferenceMachineId')}
              sx={{ minWidth: 400 }}
              value={filter}
              onChange={handleFilterBoxes}
            />
            <HeaderPrimaryRightButton startIcon={<RefreshOutlined />} onClick={fetchAllBoxes}>
              {t('boxes.refresh')}
            </HeaderPrimaryRightButton>
            <HeaderPrimaryRightButton startIcon={<Add />} variant="contained" onClick={handleNewBox}>
              {t('boxes.addBox')}
            </HeaderPrimaryRightButton>
          </HeaderPrimaryRight>
        </HeaderPrimary>
      </Header>

      <NewBox open={newBoxDialogOpen} setOpen={setNewBoxDialogOpen} />

      <Box sx={{ height: '70vh', overflow: 'hidden' }}>
        <CircularLoader loading={loading}>
          <DataGridPremium
            hideFooterSelectedRowCount
            pagination
            apiRef={apiRef}
            columns={gridColDef}
            density="compact"
            getDetailPanelHeight={() => 'auto'}
            getRowId={row => row.uuid}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            paginationModel={pageModel}
            rowHeight={70}
            rows={filteredBoxes}
            slots={{ row: DataGridRow }}
            onColumnHeaderClick={onColumnHeaderClick}
            onColumnHeaderDoubleClick={onColumnHeaderDoubleClick(apiRef)}
            onPaginationModelChange={handleChangePageSize}
          />
        </CircularLoader>
      </Box>
    </CenteredPage>
  );
};

export default Boxes;
