export default {
  button: {
    addVariable: 'Variable',
    clearAllSelection: 'Clear',
    removeCol: 'Remove column',
    delete: 'Delete',
    edit: 'Edit',
    manualEntry: 'Apply',
    existingVariable: 'Add a variable',
    createNewVariable: 'Create a manual variable',
    keepExistingData: 'Cancel',
    keepNewData: 'Apply anyway',
    addFirstVariable: 'Select a first variable',
  },
  helper: {
    here: 'here',
    movedCsvImport: 'Find the CSV import ',
    noVariables: 'Select a list or add a variable',
    rightClick: 'Right click on a line for more options',
  },
  input: {
    date: 'Date display',
    dateFormat: 'Date and time display',
  },
  legend: {
    edited: 'New data',
    deleted: 'Deleted data',
  },
  menu: {
    upsertRow: 'Insert a line above',
    insertRow: 'Insert a line below',
    deleteRow: 'Delete line',
    restoreRow: 'Restore line',
  },
  table: {
    column: {
      date: 'Date',
      variable: 'Variable',
    },
    row: {
      noRows: 'No data',
    },
  },
  text: {
    existingDataWarning: 'You already have data at this timestamp. Do you want to replace it ?',
    fetchLimitWarning: 'You are only seeing 10,000 first lines',
    noImportList: 'No import list',
    selectList: 'Select a list',
    newList: 'New list',
    more_one: 'more',
    more_other: 'more',
    or: 'or',
  },
  title: {
    manualEntry: 'Manual entry',
    existingDataWarning: 'Existing data',
    recentList: 'Recently used lists',
  },
  toast: {
    manualEntryPatchSuccess: 'Operation in progress',
  },
  tooltip: {
    deleteList: 'Delete this list',
    createList: 'Create list',
    saveList: 'Save this list',
  },
};
