import { SvgIconProps } from '@mui/material';
import { FC, ReactNode, useMemo } from 'react';

import { Shortcut, ShortcutCategory } from '@dametis/core';

import ShortcutIcon from 'components/UI/Shortcut/ShortcutIcon';

import { EntitiesByCategory } from '../types';

import ListItemBlockIcon from './ListItemBlockIcon';
import ListItemBlockTypeIcon from './ListItemBlockTypeIcon';
import ListItemStandardBlockIcon from './ListItemStandardBlockIcon';

export interface ListItemEntityIconProps extends Omit<SvgIconProps, 'color'> {
  shortcut: Shortcut;
  entitiesByCategory: EntitiesByCategory;
}

const ListItemEntityIcon: FC<ListItemEntityIconProps> = ({ shortcut, entitiesByCategory, ...props }) => {
  const icon: ReactNode = useMemo(() => {
    switch (shortcut.category) {
      case ShortcutCategory.BLOCK_TYPE:
        return <ListItemBlockTypeIcon entitiesByCategory={entitiesByCategory} shortcut={shortcut} {...props} />;
      case ShortcutCategory.STANDARD_BLOCK:
        return <ListItemStandardBlockIcon entitiesByCategory={entitiesByCategory} shortcut={shortcut} {...props} />;
      case ShortcutCategory.BLOCK:
        return <ListItemBlockIcon entitiesByCategory={entitiesByCategory} shortcut={shortcut} {...props} />;
      default:
        return <ShortcutIcon category={shortcut.category} {...props} />;
    }
  }, [shortcut, entitiesByCategory, props]);

  return icon;
};

export default ListItemEntityIcon;
