import { FC } from 'react';

import UnitPickerProvider from './UnitPickerContext';
import UnitPopoverBase, { UnitPopoverBaseProps } from './UnitPopoverBase';
import { CommonProps, UnitPickerMenu } from './types';

export type UnitPopoverProps = CommonProps & Partial<UnitPopoverBaseProps>;

const UnitPopover: FC<UnitPopoverProps> = ({
  value = null,
  onChange,
  freeMode = true,
  baseUnit = undefined,
  defaultMenu = UnitPickerMenu.LIST,
  anchorEl = undefined,
  open = undefined,
  onClose = undefined,
  ...props
}) => {
  return (
    <UnitPickerProvider baseUnit={baseUnit} defaultMenu={defaultMenu} freeMode={freeMode} value={value} onChange={onChange} {...props}>
      <UnitPopoverBase anchorEl={anchorEl} open={open} onClose={onClose} />
    </UnitPickerProvider>
  );
};

export default UnitPopover;
