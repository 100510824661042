import { SearchOffOutlined } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';
import Fuse from 'fuse.js';
import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BlockInfo, BlockTypeInfo, OrderBy, ShortStandardBlockInfo, ShortUserInfo, UUID } from '@dametis/core';

import BlockTile from 'components/Lego/UI/Block/BlockTile/BlockTile';
import EntityAccordion from 'components/UI/EntityAccordion/EntityAccordion';
import EntityAccordionDetails from 'components/UI/EntityAccordion/EntityAccordionDetails';
import getEntitiesById from 'functions/getEntitiesById';
import { isBefore } from 'localization/localizedDateFns';
import { useBlockTypes } from 'store/api/blockTypes';
import { useStandardBlocks } from 'store/api/standardBlocks';

import BlockListToolbar from './BlockListToolbar/BlockListToolbar';

export enum SortedBy {
  BLOCK_NAME = 'blockName',
  OWNER = 'owner',
  BLOCKTYPE_NAME = 'blockTypeName',
  NB_PARAMETERS = 'nbParameters',
  NB_METRICS = 'nbMetrics',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}

const blockTypesEmptyArray: BlockTypeInfo[] = [];
const standardBlocksEmptyArray: ShortStandardBlockInfo[] = [];

export interface BlockListProps {
  blocks: BlockInfo[];
  search: string;
}

const BlockList: FC<BlockListProps> = ({ blocks, search }) => {
  const { t } = useTranslation('lego');
  const navigate = useNavigate();

  const { folderId, entityId } = useParams<{ folderId?: UUID; entityId?: UUID }>();

  const { data: blockTypes = blockTypesEmptyArray } = useBlockTypes();
  const { data: standardBlocks = standardBlocksEmptyArray } = useStandardBlocks();

  const [sortedBy, setSortedBy] = useState<SortedBy>(SortedBy.BLOCK_NAME);
  const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.ASC);
  const [selectedUsersUuid, setSelectedUsersUuid] = useState<UUID[]>([]);
  const [selectedStandardBlockIds, setSelectedStandardBlockIds] = useState<UUID[]>([]);
  const [selectedTagsId, setSelectedTagsId] = useState<UUID[]>([]);
  const [isBlockListOpen, setIsBlockListOpen] = useState<boolean>(true);

  const blockTypesById = useMemo(() => getEntitiesById<BlockTypeInfo>(blockTypes), [blockTypes]);
  const standardBlocksById = useMemo(() => getEntitiesById<ShortStandardBlockInfo>(standardBlocks), [standardBlocks]);

  const users = useMemo(
    () =>
      Object.values(
        blocks.reduce<Record<UUID, ShortUserInfo>>((acc, block) => {
          if (block.owner && !acc[block.owner.uuid]) {
            acc[block.owner.uuid] = block.owner;
          }
          return acc;
        }, {}),
      ),
    [blocks],
  );

  const displayedBlocks = useMemo(() => {
    const filteredBlocks = blocks.filter(
      block =>
        selectedStandardBlockIds.includes(block.standardBlockId) &&
        block.owner?.uuid &&
        selectedUsersUuid.includes(block.owner?.uuid) &&
        selectedTagsId.every(tag => block.tags.some(tag2 => tag2.uuid === tag)),
      // block.tags.some(tag => selectedTagsUuid.includes(tag.uuid)),
    );
    if (search?.length > 0) {
      const fuse = new Fuse(filteredBlocks, {
        keys: ['name'],
      });
      return fuse.search(search).map(fR => fR.item);
    }
    return filteredBlocks.sort((blockA, blockB) => {
      let result = 0;
      if (sortedBy === SortedBy.BLOCK_NAME) {
        result = blockA.name.localeCompare(blockB.name);
      }
      if (sortedBy === SortedBy.OWNER) {
        const nameA = `${blockA.owner?.firstName ?? ''} ${blockA.owner?.lastName ?? ''}`;
        const nameB = `${blockB.owner?.firstName ?? ''} ${blockB.owner?.lastName ?? ''}`;
        result = nameA.localeCompare(nameB);
      }
      if (sortedBy === SortedBy.BLOCKTYPE_NAME) {
        result = standardBlocksById[blockA.standardBlockId].name.localeCompare(standardBlocksById[blockB.standardBlockId].name);
      }
      if (sortedBy === SortedBy.NB_PARAMETERS) {
        const requiredParametersA = blockA.parameters.filter(parameter => parameter.isSelected);
        const requiredParametersB = blockB.parameters.filter(parameter => parameter.isSelected);
        result = requiredParametersB.length - requiredParametersA.length;
      }
      if (sortedBy === SortedBy.NB_METRICS) {
        const availableMetricsA = blockA.metrics.filter(metric => metric.isSelected);
        const availableMetricsB = blockB.metrics.filter(metric => metric.isSelected);
        result = availableMetricsB.length - availableMetricsA.length;
      }
      if (sortedBy === SortedBy.CREATED_AT) {
        result = isBefore(new Date(blockB.createdAt ?? 0), new Date(blockA.createdAt ?? 0)) ? 1 : -1;
      }
      if (sortedBy === SortedBy.UPDATED_AT) {
        result = isBefore(new Date(blockB.updatedAt ?? 0), new Date(blockA.updatedAt ?? 0)) ? 1 : -1;
      }
      return result * (orderBy === OrderBy.DESC ? -1 : 1);
    });
  }, [blocks, search, selectedStandardBlockIds, selectedUsersUuid, sortedBy, orderBy, standardBlocksById, selectedTagsId]);

  const handleSelectBlock = useCallback(
    (childBlockId: UUID): MouseEventHandler =>
      () => {
        if (entityId === childBlockId) {
          navigate(`/lego/${folderId}`);
        } else {
          navigate(`/lego/${folderId}/${childBlockId}`);
        }
      },
    [navigate, folderId, entityId],
  );

  const handleChangeBlockListOpen = useCallback(() => {
    setIsBlockListOpen(state => !state);
  }, []);

  useEffect(() => {
    setSelectedStandardBlockIds(standardBlocks.map(standardBlock => standardBlock.uuid));
  }, [standardBlocks]);

  useEffect(() => {
    setSelectedUsersUuid(users.map(user => user.uuid));
  }, [users]);

  return (
    <EntityAccordion expanded={isBlockListOpen} onChange={handleChangeBlockListOpen}>
      <BlockListToolbar
        isOpen={isBlockListOpen}
        orderBy={orderBy}
        selectedStandardBlockIds={selectedStandardBlockIds}
        selectedTagsUuid={selectedTagsId}
        selectedUsersUuid={selectedUsersUuid}
        setOrderBy={setOrderBy}
        setSelectedStandardBlockIds={setSelectedStandardBlockIds}
        setSelectedTagsUuid={setSelectedTagsId}
        setSelectedUsersUuid={setSelectedUsersUuid}
        setSortedBy={setSortedBy}
        sortedBy={sortedBy}
        users={users}
      />

      <EntityAccordionDetails>
        {displayedBlocks.length > 0 && (
          <Grid container spacing={2}>
            {displayedBlocks.map(block => (
              <Grid key={block.uuid} item lg={4} md={6} sm={12} xs={12}>
                <BlockTile
                  block={block}
                  blockType={blockTypesById[standardBlocksById[block.standardBlockId].blockTypeId]}
                  selected={entityId === block.uuid}
                  standardBlock={standardBlocksById[block.standardBlockId]}
                  onClick={handleSelectBlock(block.uuid)}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {blocks.length === 0 && (
          <Stack alignItems="center" direction="row" justifyContent="center" p={4} spacing={3}>
            <Typography fontSize={16} variant="subtitle2">
              {t('text.noBlock')}
            </Typography>
          </Stack>
        )}
        {displayedBlocks.length === 0 && blocks.length !== 0 && (
          <Stack alignItems="center" direction="row" justifyContent="center" p={4} spacing={3}>
            <SearchOffOutlined sx={{ fontSize: 50, color: theme => theme.palette.grey[600] }} />
            <Typography fontSize={16} variant="subtitle2">
              {t('text.noCorrespondingBlock')}
            </Typography>
          </Stack>
        )}
      </EntityAccordionDetails>
    </EntityAccordion>
  );
};

export default BlockList;
