import { DataVariable, Period, ReadMacroProgressResult, ReportType, StyleFormat, TextAlign, UUID } from '@dametis/core';

import { DamIcon, DamIconName } from './iconPicker';

export enum WidgetCategories {
  CHARTS = 'charts',
  TABLES = 'tables',
  BASICS = 'basics',
  PRETTY = 'pretty',
}

export enum WidgetScope {
  BASIC = 'basic',
  SUBPERIODS = 'subperiods',
  YEARONLY = 'yearOnly',
  MONTHONLY = 'monthOnly',
  WEEKONLY = 'weekOnly',
}

export type ChartVariable = Record<string, DataVariable>;

export interface MacrosResults {
  uuid: UUID;
  results: ReadMacroProgressResult['returnValue'];
  period: Period;
}

export const IsFixedReportType = (reportType: ReportType) =>
  reportType === ReportType.YEAR || reportType === ReportType.MONTH || reportType === ReportType.WEEK;

export interface FontPanelStyle {
  text: string;
  format?: StyleFormat[];
  fontSize?: number;
  textAlign?: TextAlign;
  fontColor?: string;
  fillColor?: string;
  borderColor?: string;
  icon?: DamIcon | DamIconName;
}
