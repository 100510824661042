import type { Axis } from 'highcharts';
import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';

export interface YAxisContextState {
  axis: Axis | null;
  unit: string | null;
}

export const initialState = {
  axis: null,
  unit: null,
};

export const YAxisContext = createContext<YAxisContextState>(initialState);

interface YAxisProviderProps {
  axis: Axis;
  unit: string | null;
}

const YAxisProvider: FC<PropsWithChildren<YAxisProviderProps>> = ({ axis, unit, children = undefined }) => {
  const contextValues: YAxisContextState = useMemo(() => ({ axis, unit }), [axis, unit]);

  return <YAxisContext.Provider value={contextValues}>{children}</YAxisContext.Provider>;
};

export const useYAxisContext = () => {
  const context = useContext(YAxisContext);

  if (!context) {
    throw Error('useYAxisContext must be used inside an YAxisProvider');
  }

  return context;
};

export default YAxisProvider;
