import { Stack, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateStandardBlockBody, UpdateStandardBlockBody } from '@dametis/core';

import { StyledStepConnector } from '../../BlockType/CreateBlockTypeModal/ModalStepper';

export interface ModalStepperProps {
  activeStep: number;
  standardBlockBody: CreateStandardBlockBody | UpdateStandardBlockBody;
}

const ModalStepper: FC<ModalStepperProps> = ({ activeStep, standardBlockBody }) => {
  const { t } = useTranslation('lego');

  return (
    <Stepper activeStep={activeStep} connector={<StyledStepConnector />} orientation="vertical">
      <Step>
        <StepLabel>{t('stepper.general')}</StepLabel>
        <StepContent TransitionProps={{ in: activeStep > 0 }}>
          <Typography variant="caption">{t('stepperContent.name', { name: standardBlockBody.name })}</Typography>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>{t('stepper.metadataLists')}</StepLabel>
        <StepContent TransitionProps={{ in: activeStep > 1 }}>
          <Typography variant="caption">
            {t('stepperContent.standardMetadataListLength', { count: (standardBlockBody.standardMetadataList ?? []).length })}
          </Typography>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>{t('stepper.parametersAndMetrics')}</StepLabel>
        <StepContent TransitionProps={{ in: activeStep > 2 }}>
          <Stack>
            <Typography variant="caption">
              {t('stepperContent.parametersLength', {
                count: (standardBlockBody.parameters ?? []).filter(parameter => parameter.isSelected).length,
              })}
            </Typography>
            <Typography variant="caption">
              {t('stepperContent.metricsLength', { count: (standardBlockBody.metrics ?? []).filter(metric => metric.isSelected).length })}
            </Typography>
          </Stack>
        </StepContent>
      </Step>
    </Stepper>
  );
};

export default ModalStepper;
