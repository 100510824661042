import { DametisFormat, DateSystem, Ecma376Format, Format, FormatCategory, FormatType } from '@dametis/core';

export const ecma376DateFormats: Ecma376Format[] = [
  {
    type: FormatType.ECMA376,
    category: FormatCategory.DATE,
    hideUnit: true,
    dateSystem: DateSystem.TIMESTAMP_UNIX,
    withTimezoneOffset: true,
    exampleValue: 35086,
    code: 'dd/mm/yyyy',
  },
  {
    type: FormatType.ECMA376,
    category: FormatCategory.DATE,
    hideUnit: true,
    dateSystem: DateSystem.TIMESTAMP_UNIX,
    withTimezoneOffset: true,
    exampleValue: 35086,
    code: 'dd/mm/yyyy hh:mm',
  },
  {
    type: FormatType.ECMA376,
    category: FormatCategory.DATE,
    hideUnit: true,
    dateSystem: DateSystem.TIMESTAMP_UNIX,
    withTimezoneOffset: true,
    exampleValue: 35086,
    code: 'dd/mm/yyyy hh:mm:ss',
  },
  {
    type: FormatType.ECMA376,
    category: FormatCategory.DATE,
    hideUnit: true,
    dateSystem: DateSystem.TIMESTAMP_UNIX,
    withTimezoneOffset: true,
    exampleValue: 35086,
    code: 'ddd dd mmmm',
  },
  {
    type: FormatType.ECMA376,
    category: FormatCategory.DATE,
    hideUnit: true,
    dateSystem: DateSystem.TIMESTAMP_UNIX,
    withTimezoneOffset: true,
    exampleValue: 35086,
    code: 'mm/yyyy',
  },
  {
    type: FormatType.ECMA376,
    category: FormatCategory.DATE,
    hideUnit: true,
    dateSystem: DateSystem.EXCEL_SERIAL_TIME,
    withTimezoneOffset: false,
    exampleValue: 0.0508,
    code: '[hh]"h" mm"m" ss"s"',
  },
  {
    type: FormatType.ECMA376,
    category: FormatCategory.DATE,
    hideUnit: true,
    dateSystem: DateSystem.EXCEL_SERIAL_TIME,
    withTimezoneOffset: false,
    exampleValue: 0.0508,
    code: '[hh]:mm:ss',
  },
  {
    type: FormatType.ECMA376,
    category: FormatCategory.DATE,
    hideUnit: true,
    dateSystem: DateSystem.EXCEL_SERIAL_TIME,
    withTimezoneOffset: false,
    exampleValue: 0.0508,
    code: '[mm]:ss',
  },
  {
    type: FormatType.ECMA376,
    category: FormatCategory.DATE,
    hideUnit: true,
    dateSystem: DateSystem.EXCEL_SERIAL_TIME,
    withTimezoneOffset: false,
    exampleValue: 0.0508,
    code: '[hh]:mm',
  },
];

export const dametisDateFormats: DametisFormat[] = [];

export const ecma376NumberFormats: Ecma376Format[] = [
  {
    type: FormatType.ECMA376,
    category: FormatCategory.NUMBER,
    hideUnit: false,
    dateSystem: DateSystem.EXCEL_SERIAL_TIME,
    withTimezoneOffset: false,
    exampleValue: 1234.5678,
    code: '# ##0',
  },
  {
    type: FormatType.ECMA376,
    category: FormatCategory.NUMBER,
    hideUnit: false,
    dateSystem: DateSystem.EXCEL_SERIAL_TIME,
    withTimezoneOffset: false,
    exampleValue: 1234.5678,
    code: '0.00',
  },
  {
    type: FormatType.ECMA376,
    category: FormatCategory.NUMBER,
    hideUnit: false,
    dateSystem: DateSystem.EXCEL_SERIAL_TIME,
    withTimezoneOffset: false,
    exampleValue: 1234.5678,
    code: '#,##0.00',
  },
  {
    type: FormatType.ECMA376,
    category: FormatCategory.NUMBER,
    hideUnit: false,
    dateSystem: DateSystem.EXCEL_SERIAL_TIME,
    withTimezoneOffset: false,
    exampleValue: 1234.5678,
    code: '##0.0E+0',
  },
  {
    type: FormatType.ECMA376,
    category: FormatCategory.NUMBER,
    hideUnit: false,
    dateSystem: DateSystem.EXCEL_SERIAL_TIME,
    withTimezoneOffset: false,
    exampleValue: 0.7,
    code: '0%',
  },
  {
    type: FormatType.ECMA376,
    category: FormatCategory.NUMBER,
    hideUnit: false,
    dateSystem: DateSystem.EXCEL_SERIAL_TIME,
    withTimezoneOffset: false,
    exampleValue: 1.6,
    code: '##0/##0',
  },
  {
    type: FormatType.ECMA376,
    category: FormatCategory.NUMBER,
    hideUnit: false,
    dateSystem: DateSystem.EXCEL_SERIAL_TIME,
    withTimezoneOffset: false,
    exampleValue: 1234.56,
    code: '+#,##0;-#,##0;0',
  },
];

export const dametisNumberFormats: DametisFormat[] = [];

export const dateFormats: Format[] = [...ecma376DateFormats, ...dametisDateFormats];

export const numberFormats: Format[] = [...ecma376NumberFormats, ...dametisNumberFormats];

export const allFormats = [...dateFormats, ...numberFormats];
