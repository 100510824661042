export default {
  title: {
    backups: 'Backups',
    newBackup: 'Nueva backup',
    beforeRestoring: '¿Estás seguro?',
  },
  text: {
    createdAt: 'Creado el',
    noBackup: 'Sin backup',
  },
  input: {
    label: {
      name: 'Nombre',
    },
  },
  button: {
    backup: 'Backup',
    cancel: 'Cancelar',
    create: 'Crear',
    restore: 'Sí, restaurar',
  },
  tooltip: {
    restore: 'Restaurar la backup',
    delete: 'Vaciar la backup',
  },
  toast: {
    createBackupSuccess: 'Backup creada con éxito.',
    createBackupError: 'Error al crear la backup.',
    restoreBackupSuccess: 'Backup restaurada con éxito.',
    restoreBackupError: 'Error al restaurar la backup.',
    deleteBackupSuccess: 'Backup eliminada con éxito.',
    deleteBackupError: 'Error al vaciar la backup.',
    fetchBackupsError: 'Error al recuperar las backups.',
  },
  columns: {
    name: 'Nombre',
    createdAt: 'Fecha de creación',
    uuid: 'UUID',
  },
};
