import { PhysicalQuantity } from '@dametis/core';
import { UnitName } from '@dametis/unit';

import { UnitPickerMenu } from 'components/UI/UnitPicker/types';

const unitNames: Record<UnitName, string> = {
  [UnitName.GRAM]: 'gramme',
  [UnitName.TONNE]: 'tonne',
  [UnitName.POUND_WEIGHT]: 'livre',
  [UnitName.OUNCE]: 'once',
  [UnitName.GRAM_PER_CUBIC_METER]: 'gramme par mètre cube',
  [UnitName.GRAM_PER_NORMAL_CUBIC_METER]: 'gramme par mètre cube',
  [UnitName.METER]: 'mètre',
  [UnitName.MILES]: 'mile',
  [UnitName.INCH]: 'pouce',
  [UnitName.SQUARE_METER]: 'mètre carré',
  [UnitName.HECTAR]: 'hectare',
  [UnitName.CELSIUS]: 'celsius',
  [UnitName.KELVIN]: 'kelvin',
  [UnitName.FAHRENHEIT]: 'fahrenheit',
  [UnitName.CUBIC_METER]: 'mètre cube',
  [UnitName.LITRE]: 'litre',
  [UnitName.NORMAL_CUBIC_METER]: 'normo mètre cube',
  [UnitName.GALLON_US]: 'gallon U.S.',
  [UnitName.GALLON_IMPERIAL]: 'gallon impérial',
  [UnitName.BAR]: 'bar',
  [UnitName.PASCAL]: 'pascal',
  [UnitName.PSI]: 'psi',
  [UnitName.LITRE_PER_SECOND]: 'litre par seconde',
  [UnitName.LITRE_PER_MINUTE]: 'litre par minute',
  [UnitName.LITRE_PER_HOUR]: 'litre par heure',
  [UnitName.CUBIC_METER_PER_SECOND]: 'mètre cube par seconde',
  [UnitName.CUBIC_METER_PER_MINUTE]: 'mètre cube par minute',
  [UnitName.CUBIC_METER_PER_HOUR]: 'mètre cube par heure',
  [UnitName.NORMAL_CUBIC_METER_PER_HOUR]: 'normo mètre cube par heure',
  [UnitName.TON_PER_HOUR]: 'tonne par heure',
  [UnitName.GRAM_PER_HOUR]: 'gramme par heure',
  [UnitName.METER_PER_SECOND]: 'mètre par seconde',
  [UnitName.METER_PER_HOUR]: 'mètre par heure',
  [UnitName.MILES_PER_HOUR]: 'miles par heure',
  [UnitName.SECOND]: 'seconde',
  [UnitName.MINUTE]: 'minute',
  [UnitName.HOUR]: 'heure',
  [UnitName.DAY]: 'jour',
  [UnitName.WEEK]: 'semaine',
  [UnitName.AMPERE]: 'ampère',
  [UnitName.VOLT]: 'volt',
  [UnitName.VOLT_AMPERE]: 'voltampère',
  [UnitName.HERTZ]: 'hertz',
  [UnitName.WATT]: 'watt',
  [UnitName.HOT_WATT]: 'watt chaud',
  [UnitName.COLD_WATT]: 'watt froid',
  [UnitName.ELEC_WATT]: 'watt elec',
  [UnitName.JOULE_PER_HOUR]: 'joule par heure',
  [UnitName.VOLT_AMPERE_REACTIVE]: 'voltampère réactif',
  [UnitName.VOLT_AMPERE_REACTIVE_HOUR_POSITIVE]: 'voltampère réactif positif',
  [UnitName.VOLT_AMPERE_REACTIVE_HOUR_NEGATIVE]: 'voltampère réactif négatif',
  [UnitName.HORSE_POWER]: 'cheval-vapeur',
  [UnitName.TON_OF_REFRIGERATION]: 'tonne de réfrigération',
  [UnitName.BRITISH_THERMAL_UNIT_PER_HOUR]: 'british thermal unit par heure',
  [UnitName.WATT_HOUR]: 'wattheure',
  [UnitName.HOT_WATT_HOUR]: 'wattheure chaud',
  [UnitName.COLD_WATT_HOUR]: 'wattheure froid',
  [UnitName.ELEC_WATT_HOUR]: 'wattheure elec',
  [UnitName.WATT_HOUR_PCI]: 'wattheure PCI',
  [UnitName.WATT_HOUR_PCS]: 'wattheure PCS',
  [UnitName.VOLT_AMPERE_HOUR]: 'volt ampère-heure',
  [UnitName.VOLT_AMPERE_REACTIVE_HOUR]: 'voltampère réactif heure',
  [UnitName.JOULE]: 'joule',
  [UnitName.BRITISH_THERMAL_UNIT]: 'british thermal unit',
  [UnitName.CALORIE]: 'calorie',
  [UnitName.JOULE_PER_KILOGRAM]: 'joule par kilogramme',
  [UnitName.ROTATION_PER_MINUTE]: 'rotation par minute',
  [UnitName.PERCENT]: 'pourcentage',
  [UnitName.EURO]: 'euro',
  [UnitName.DOLLAR]: 'dollar',
  [UnitName.LIVRE]: 'livre',
  [UnitName.ENERGY_PER_NORMAL_CUBIC_METER]: 'énergie par normo mètre cube',
  [UnitName.NORMAL_CUBIC_METER_PER_ENERGY]: 'normo mètre cube par énergie',
  [UnitName.KILO_GRAM_CO2]: 'kilogramme de CO2',
  [UnitName.TONNE_CO2]: 'tonne de CO2',
  [UnitName.KILO_GRAM_CO2_PER_KILO_WATT_HOUR]: 'kilogramme de CO2 par kilowattheure',
  [UnitName.KILO_GRAM_CO2_PER_KILO_HOT_WATT_HOUR]: 'kilogramme de CO2 par kilowattheure chaud',
  [UnitName.KILO_GRAM_CO2_PER_KILO_COLD_WATT_HOUR]: 'kilogramme de CO2 par kilowattheure froid',
  [UnitName.KILO_GRAM_CO2_PER_KILO_ELEC_WATT_HOUR]: 'kilogramme de CO2 par kilowattheure elec',
  [UnitName.TONNE_CO2_PER_KILO_WATT_HOUR]: 'tonne de CO2 par kilowattheure',
  [UnitName.TONNE_CO2_PER_KILO_HOT_WATT_HOUR]: 'tonne de CO2 par kilowattheure chaud',
  [UnitName.TONNE_CO2_PER_KILO_COLD_WATT_HOUR]: 'tonne de CO2 par kilowattheure froid',
  [UnitName.TONNE_CO2_PER_KILO_ELEC_WATT_HOUR]: 'tonne de CO2 par kilowattheure elec',
  [UnitName.TONNE_OF_OIL_EQUIVALENT]: "Tonne d'équivalent pétrole",
  [UnitName.STOKE]: 'stokes',
  [UnitName.EURO_PER_MEGA_WATT_HOUR]: 'euro par megawattheure',
  [UnitName.EURO_PER_MEGA_HOT_WATT_HOUR]: 'euro par megawattheure chaud',
  [UnitName.EURO_PER_MEGA_COLD_WATT_HOUR]: 'euro par megawattheure froid',
  [UnitName.EURO_PER_MEGA_ELEC_WATT_HOUR]: 'euro par megawattheure elec',
  [UnitName.EURO_PER_KILO_WATT_HOUR]: 'euro par kilowattheure',
  [UnitName.EURO_PER_KILO_HOT_WATT_HOUR]: 'euro par kilowattheure chaud',
  [UnitName.EURO_PER_KILO_COLD_WATT_HOUR]: 'euro par kilowattheure froid',
  [UnitName.EURO_PER_KILO_ELEC_WATT_HOUR]: 'euro par kilowattheure elec',
  [UnitName.DOLLAR_PER_MEGA_WATT_HOUR]: 'dollar par megawattheure',
  [UnitName.DOLLAR_PER_MEGA_HOT_WATT_HOUR]: 'dollar par megawattheure chaud',
  [UnitName.DOLLAR_PER_MEGA_COLD_WATT_HOUR]: 'dollar par megawattheure froid',
  [UnitName.DOLLAR_PER_MEGA_ELEC_WATT_HOUR]: 'dollar par megawattheure elec',
  [UnitName.DOLLAR_PER_KILO_WATT_HOUR]: 'dollar par kilowattheure',
  [UnitName.DOLLAR_PER_KILO_HOT_WATT_HOUR]: 'dollar par kilowattheure chaud',
  [UnitName.DOLLAR_PER_KILO_COLD_WATT_HOUR]: 'dollar par kilowattheure froid',
  [UnitName.DOLLAR_PER_KILO_ELEC_WATT_HOUR]: 'dollar par kilowattheure elec',
  [UnitName.BYTE_EN]: 'octet',
  [UnitName.BYTE_FR]: 'octet',
  [UnitName.GRAM_PER_LITRE]: 'gramme par litre',
  [UnitName.SIEMENS]: 'siemens',
  [UnitName.SIEMENS_PER_CENTIMETER]: 'siemens par centimètre',
  [UnitName.NO_UNIT]: '',
  // [UnitName.NO_UNIT]: 'Sans unité',
};

export default {
  text: {
    baseUnit: 'Unité de base : {{unit}}',
    newOption: 'Unité personnalisée : "{{value}}"',
    noUnit: "Pas d'unité",
    noUnitElement: 'Sans unité',
    unitUndefined: 'Unité non définie',
    unitUndefinedWithBase: '{{base}} (défaut)',
    inputLabel: 'Unité',
  },
  tooltip: {
    menu: {
      [UnitPickerMenu.LIST]: 'Par unité',
      [UnitPickerMenu.COLUMNS]: 'Par grandeur physique',
    },
    clear: 'Effacer',
  },
  unit: unitNames,
  physicalQuantity: {
    [PhysicalQuantity.NONE]: 'aucune',
    [PhysicalQuantity.OTHER]: 'autre',
    [PhysicalQuantity.PRESSURE]: 'pression',
    [PhysicalQuantity.ENERGY]: 'énergie',
    [PhysicalQuantity.SPECIFIC_ENERGY]: "densité massique d'énergie",
    [PhysicalQuantity.POWER]: 'puissance',
    [PhysicalQuantity.TEMPERATURE]: 'température',
    [PhysicalQuantity.SPEED]: 'vitesse',
    [PhysicalQuantity.ACCELERATION]: 'accélération',
    [PhysicalQuantity.DENSITY]: 'masse volumique',
    // [PhysicalQuantity.MASS_DENSITY]: '',
    [PhysicalQuantity.TIME]: 'temps',
    [PhysicalQuantity.MASS]: 'masse',
    [PhysicalQuantity.VOLUME]: 'volume',
    [PhysicalQuantity.FLOW]: 'débit',
    [PhysicalQuantity.LENGTH]: 'longueur',
    [PhysicalQuantity.SURFACE]: 'surface',
    [PhysicalQuantity.FORCE]: 'force',
    [PhysicalQuantity.DEW_POINT]: 'point de rosée',
    [PhysicalQuantity.HUMIDITY]: 'humiditée',
    [PhysicalQuantity.THERMAL_CONDUCTIVITY]: 'conductivité thermale',
    [PhysicalQuantity.ELECTRICAL_CONDUCTIVITY]: 'conductivité électrique',
    [PhysicalQuantity.ELECTRICAL_CONDUCTANCE]: 'conductance électrique',
    [PhysicalQuantity.VOLTAGE]: 'tension',
    [PhysicalQuantity.CURRENT]: 'intensité',
    [PhysicalQuantity.FREQUENCY]: 'fréquence',
    [PhysicalQuantity.CURRENCY]: 'monnaie',
    [PhysicalQuantity.FILE_SIZE]: 'taille de fichier',
    [PhysicalQuantity.VISCOSITY]: 'viscosité',
  } as Record<PhysicalQuantity, string>,
};
