import { FolderOff, FolderOffOutlined, Inventory2, Inventory2Outlined } from '@mui/icons-material';
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, SvgIcon, Typography, darken } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { FolderInfo } from '@dametis/core';

import { getFolderColor } from 'components/Lego/helpers/getFolderColor';

import { MenuTab } from '../LegoCatalog';

import NavigationTools from './NavigationTools';
import TreeNavigation from './TreeNavigation';

export interface CatalogMenuProps {
  currentPath: FolderInfo[];
  selectedMenuTab: MenuTab;
}

const CatalogMenu: FC<CatalogMenuProps> = ({ currentPath, selectedMenuTab }) => {
  const { t } = useTranslation('lego');

  return (
    <Paper
      sx={{
        position: 'sticky',
        top: theme => `calc(${theme.others.headerHeight} + ${theme.spacing(2)})`,
        maxHeight: theme => `calc(100vh - ${theme.others.headerHeight} - ${theme.spacing(4)})`,
        overflow: 'auto',
      }}
    >
      <List sx={{ py: 1 }}>
        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="/blockTypes">
            <ListItemIcon sx={{ mr: 2, minWidth: 'unset' }}>
              <SvgIcon
                component={selectedMenuTab === MenuTab.FOLDER_CONTENT ? Inventory2 : Inventory2Outlined}
                fontSize="small"
                sx={{ color: theme => darken(theme.palette.picker.peach, 0.1) }}
              />
            </ListItemIcon>
            <ListItemText>
              <Typography fontWeight={selectedMenuTab === MenuTab.FOLDER_CONTENT ? 700 : undefined}>
                {t(`menuTab.${MenuTab.FOLDER_CONTENT}`)}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="/blockTypes/noFolder">
            <ListItemIcon sx={{ mr: 2, minWidth: 'unset' }}>
              <SvgIcon
                component={selectedMenuTab === MenuTab.NO_FOLDER_CONTENT ? FolderOff : FolderOffOutlined}
                fontSize="small"
                sx={{ color: theme => getFolderColor(null, theme) }}
              />
            </ListItemIcon>
            <ListItemText>
              <Typography fontWeight={selectedMenuTab === MenuTab.NO_FOLDER_CONTENT ? 700 : undefined}>
                {t(`menuTab.${MenuTab.NO_FOLDER_CONTENT}`)}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        {/* <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ mr: 2, minWidth: 'unset' }}>
              <GradeOutlined color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Favoris</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ mr: 2, minWidth: 'unset' }}>
              <ScheduleOutlined color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Récents</ListItemText>
          </ListItemButton>
        </ListItem> */}
      </List>
      <NavigationTools />
      <Collapse in={selectedMenuTab === MenuTab.FOLDER_CONTENT}>
        <TreeNavigation currentPath={currentPath} />
      </Collapse>
    </Paper>
  );
};

export default CatalogMenu;
