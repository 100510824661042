import { VariableKind } from '@dametis/core';

import { FakeVariableKind } from 'types/variables';

export default {
  Error: {
    sameVarSelect: 'Veuillez sélectionner une variable différente',
    variableListNameRequired: 'Un nom pour la liste est requis',
    variableListNameAlreadyExist: 'Le nom de la liste est déjà pris',
    badFileType: 'Mauvais type de fichier',
    dateError: 'Veuillez selectionner une colonne pour la date',
    duplicateHeaderError:
      "Votre fichier contient un en-tête en double, désactivez l'option 'Mon fichier contient des en-têtes' ou modifiez la première ligne du fichier",
  },
  Autocomplete: {
    noOption: "Pas d'options",
    noUnit: "Pas d'unité",
    noList: 'Pas de liste',
    noValue: 'Aucune',
    list: 'Liste',
    clear: 'Vider',
    close: 'Fermer',
    open: 'Ouvrir',
    select: 'Sélectionner',
    unknown: 'Unité inconnue',
    addUnitOption: "Ajouter l'unité « {{name}} »",
    placeholder: 'Unité',
  },
  Select: {
    placeholder: 'Sélectionner une liste...',
    addList: 'Nouvelle liste',
  },
  Import: {
    title: 'Importer des données manuelles',
    addOrEditList: 'Veuillez ajouter ou sélectionner une liste',
    date: 'Date',
    dateConfirmation: 'Êtes-vous sûr de cette date ?',
    warningDates: 'Attention certaines dates peuvent être erronées',
    Action: {
      Button: {
        save: 'Enregistrer',
        addVar: 'Ajouter',
        showMore: 'Afficher plus',
        addVariableList: 'Liste',
        editVariableList: 'Modifier la liste',
      },
      ImportFile: {
        importVariables: 'Importer',
        replaceData: 'Tout remplacer...',
        concatData: 'Ajouter à la suite...',
        cancelImport: 'Annuler',
        validateImport: 'Valider',
      },
      SelectorFile: {
        title: 'Importer un fichier de variables',
        dragAndDrop: 'Glisser Déposer',
        or: 'ou',
        selectFile: 'Sélectionner un fichier',
        selectedFile: 'Fichier sélectionné',
      },
    },
    Dialog: {
      titleEditList: 'Éditer une liste',
      titleAddList: 'Ajouter une liste',
      Button: {
        addVar: 'Ajouter',
        confirm: 'Continuer',
        cancel: 'Annuler',
        delete: 'Supprimer',
      },
      SelectList: {
        manualVariables: 'Variables manuelles',
        name: 'Nom',
      },
    },
    WelcomeMessage: {
      create: 'Créer une nouvelle liste',
      or: 'ou',
    },
    Field: {
      Timestamp: {
        label: 'Date',
        labelTooltip: 'Date',
      },
    },
    ImportTable: {
      newList: 'Nouvelle liste',
    },
    Footer: {
      Button: {
        newEntry: 'Nouvelle entrée',
      },
    },
    tooltip: {
      createList: 'Créer une nouvelle liste',
    },
    ImportDataDialog: {
      title: 'Fichier importé',
      checkbox: 'Mon fichier contient des en-têtes',
      column_space: 'Colonne ',
      start_line: 'Mes valeurs commencent à la ligne :',
      formats: {
        format1: 'JJ/MM/AAAA hh:mm:ss',
        format2: 'MM/JJ/AAAA hh:mm:ss',
        format3: 'AAAA/MM/JJ hh:mm:ss',
        format4: 'AAAA/JJ/MM hh:mm:ss',
      },
      noValidDate: "Aucune date valide trouvée, essayez d'échanger la colonne associée ou le format de la date",
      parameters: 'Paramètres',
      matching: 'Correspondance de champs',
      preview: 'Prévisualisation',
      results: 'Résultats',
      linesWillBeImported: ' lignes seront importées',
      lineWillBeImported: ' ligne sera importée',
      table: {
        header1: 'Champs',
        header2: 'Colonnes du fichier',
      },
    },
  },
  Export: {
    title: 'Exporter des variables',
    Typo: {
      dateInterval: 'Intervalle de dates',
      periodicity: 'Intervalle de temps',
      optionExport: "Option d'export",
      varSelector: 'Variables',
    },
    Button: {
      export: 'Télécharger',
    },
    Time: {
      seconds: 'Secondes',
      minutes: 'Minutes',
      hours: 'Heures',
      days: 'Jours',
      weeks: 'Semaines',
    },
    Input: {
      Label: {
        to: 'Fin',
        from: 'Début',
      },
    },
  },
  MyVariable: {
    title: 'Mes Variables',
    unit: 'Unité',
    type: 'Type',
    variableType: 'Type de variable',
    operation: 'Opération',
    Typo: {
      createVar: 'Ajouter',
      varManual: 'Manuelle',
      varManualDesc: 'Importez vous-même les données.',

      varCalculate: 'Calculée',
      varCalculateDesc: "Cette variable est basée sur d'autres variables existantes",
    },
    Button: {
      createVar: 'Ajouter',
      advancedSearch: 'Recherche avancée',
      validate: 'Valider',
      cancel: 'Annuler',
      saveVar: 'Sauvegarder',
    },
    Textfield: {
      name: 'Nom',
      description: 'Description',
      reference: 'Référence',
      unit: 'Unité',
    },
    Type: {
      all: 'Tout',
      allType: 'Tous les types',
      allUnit: 'Toutes les unités',
      number: 'Nombre',
      bool: 'Booléen',
    },
    Field: {
      ActionMenu: {
        label: 'Action',
        labelTooltip: 'Action Menu',
        Menu: {
          refresh: 'Rafraîchir',
          createAlarm: 'Créer une alarme',
          viewAlarm: 'Afficher les alarmes',
          playground: 'Ouvrir playground',
        },
      },
      TypeVariable: {
        label: 'Type Var',
        labelTooltip: 'Type de Variable',
        Type: {
          calculated: 'Calculée',
          real: 'Acquise',
          manual: 'Manuelle',
          unknown: 'Inconnue',
        },
      },
      Name: {
        label: 'Nom',
        labelTooltip: 'Nom',
      },
      Ref: {
        label: 'Référence',
        labelTooltip: 'Référence',
      },
      Desc: {
        label: 'Description',
        labelTooltip: 'Description',
      },
      Unit: {
        label: 'Unité',
        labelTooltip: 'Unité',
      },
      Type: {
        label: 'Type',
        labelTooltip: 'Type',
      },
      LastImport: {
        label: 'Dernier Import',
        labelTooltip: 'Dernier Import',
      },
      LastValue: {
        label: 'Dern Val',
        labelTooltip: 'Dernière Valeur',
      },
      LastAction: {
        label: 'Dern Act',
        labelTooltip: 'Dernière action',
      },
    },
  },
  variableKind: {
    [VariableKind.CALCULATED]: 'Calculée',
    [VariableKind.CONSTANT]: 'Constante',
    [VariableKind.MANUAL]: 'Manuelle',
    [VariableKind.NONE]: 'Inconnue',
    [VariableKind.REAL]: 'Acquise',
    [FakeVariableKind.ALARM]: 'Alarme',
    [FakeVariableKind.BATCH]: 'Batch',
    [FakeVariableKind.VAPOR_MIX]: 'Mix vapeur',
  },
};
