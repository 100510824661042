import { Tab, Tabs } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import clsx from 'clsx';
import { Duration, add } from 'date-fns';
import i18next from 'i18next';
import { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CalculationVariable, Period } from '@dametis/core';

import { usePermission } from '../../../hooks/usePermission';
import PopperPanel from '../PopperPanel/PopperPanel';

import BatchTab from './BatchTab';
import CalendarTab from './CalendarTab';
import CalendarTabLite from './CalendarTabLite';
import useDateTimePickerStyles from './DateTimeRangePanel.styles';

interface Props {
  anchorRef?: HTMLButtonElement;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  period: Period;
  setPeriod: Dispatch<SetStateAction<Period>>;
  onChange: ([from, to]: Array<Date>) => void;
  inputFormat?: string;
  displayFormat?: string;
  isLite?: boolean;
  customPeriodsDuration?: Duration;
  placement?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
}

const DateTimeRangePopper: FC<Props> = ({
  anchorRef = undefined,
  open,
  setOpen,
  period,
  setPeriod,
  onChange,
  inputFormat = null,
  displayFormat = 'eee PPpp',
  isLite = undefined,
  customPeriodsDuration = undefined,
  placement = undefined,
}) => {
  const canBatch = usePermission('canAccessBatch');

  const [format, setFormat] = useState<string>('');
  const [batch, setBatch] = useState<CalculationVariable | null>({ exp: '', vars: {}, operator: undefined });
  const [tabIndex, setTabIndex] = useState<number>(0);

  const [batchDate, setBatchDate] = useState<DateRange<Date>>([null, null]);

  const { t } = useTranslation('dateTimePicker');
  const classes = useDateTimePickerStyles();

  const availableFormats: Record<string, string> = useMemo(
    () => ({
      fr: 'dd/MM/yyyy HH:mm:ss',
      es: 'dd/MM/yyyy HH:mm:ss',
      en: 'MM/dd/yyyy HH:mm:ss',
    }),
    [],
  );

  const handleTabIndex = useCallback((e: ChangeEvent<any>, index: number) => {
    setTabIndex(index);
  }, []);

  const updatePeriod = useCallback(
    ([newFrom, newTo]: Array<Date>) => {
      const to = isLite && customPeriodsDuration ? add(newFrom, customPeriodsDuration) : newTo;
      onChange([newFrom, to]);
      setPeriod(new Period({ from: newFrom, to }));
      setOpen(false);
    },
    [onChange, setPeriod, setOpen, isLite, customPeriodsDuration],
  );

  useEffect(() => {
    if (batchDate[0] === null && batchDate[1] === null) {
      setBatchDate([period.from, period.to]);
    }
  }, [period, batchDate]);

  useEffect(() => {
    setFormat(inputFormat ?? availableFormats[i18next.language ?? 'en']);
  }, [inputFormat, availableFormats]);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <PopperPanel
      anchorEl={anchorRef}
      open={open}
      placement={placement ?? { vertical: 'bottom', horizontal: 'right' }}
      onClickAway={handleClose}
    >
      {isLite && customPeriodsDuration ? (
        <CalendarTabLite
          customPeriodsDuration={customPeriodsDuration}
          format={format}
          from={period.from}
          setOpenPanel={setOpen}
          to={period.to}
          onChange={updatePeriod}
        />
      ) : (
        <>
          {canBatch && !isLite && (
            <Tabs
              aria-label={t('tabs.label')}
              classes={{
                indicator: clsx(
                  classes.tabs__indicator,
                  tabIndex === 0 ? classes.tabs__indicator__calendar : classes.tabs__indicator__batch,
                ),
              }}
              className={classes.tabs}
              value={tabIndex}
              variant="fullWidth"
              onChange={handleTabIndex}
            >
              <Tab label={t('tabs.calendar')} />
              <Tab label={t('tabs.batches')} />
            </Tabs>
          )}
          {tabIndex === 0 && (
            <CalendarTab
              displayFormat={displayFormat}
              format={format}
              from={period.from}
              setBatchDate={setBatchDate}
              setOpenPanel={setOpen}
              to={period.to}
              onChange={updatePeriod}
            />
          )}
          {canBatch && tabIndex === 1 && batch && (
            <BatchTab batch={batch} batchDate={batchDate} changeTimeRange={updatePeriod} setBatch={setBatch} setBatchDate={setBatchDate} />
          )}
        </>
      )}
    </PopperPanel>
  );
};

export default DateTimeRangePopper;
