import { ButtonBase, ButtonBaseProps, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const ACTIONS_CLASSNAME = 'actions';

export type ContentItemContainerProps = ButtonBaseProps<typeof NavLink, { component?: typeof NavLink }> & {
  selected?: boolean;
};

const ContentItemContainer = styled(
  ({ component = NavLink, ...props }: ButtonBaseProps) => <ButtonBase component={component} {...props} />,
  {
    shouldForwardProp: propName => propName !== 'selected',
  },
)<ContentItemContainerProps>(({ theme, selected = false }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  marginTop: `-${theme.spacing(0.5)}`,
  marginBottom: `-${theme.spacing(0.5)}`,
  cursor: 'pointer',
  borderRadius: theme.spacing(1),
  textAlign: 'inherit',
  [`& .${ACTIONS_CLASSNAME}`]: { opacity: 0 },
  [`&:hover .${ACTIONS_CLASSNAME}`]: { opacity: 1 },
  '&:hover': {
    zIndex: 2,
    background: theme.palette.background.paper,
  },
  ...(selected && {
    background: theme.palette.background.paper,
  }),
}));

export default ContentItemContainer;
