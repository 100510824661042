import { EntityType } from 'types/entity';

export default {
  title: {
    entitySelector: 'Selection',
    [`tab_${EntityType.ALARM}`]: 'Alarms',
    [`tab_${EntityType.PLAYGROUND}`]: 'Playgrounds',
    [`tab_${EntityType.PROJECT}`]: 'Projects',
    [`tab_${EntityType.REPORT}`]: 'Reports',
    [`tab_${EntityType.SYNOPTIC}`]: 'Synoptics',
  },
  button: {
    cancel: 'Cancel',
    select: 'Select',
  },
  text: {
    noSelection: 'No selection',
    selected: 'Selected : {{context}}',
    selectedPrivate: 'The entity you selected is private. Make it public so that it can be seen by all your collaborators.',
    [`no_${EntityType.ALARM}`]: 'No alarms yet',
    [`no_${EntityType.PLAYGROUND}`]: 'No playgrounds yet',
    [`no_${EntityType.PROJECT}`]: 'No projects yet',
    [`no_${EntityType.REPORT}`]: 'No reports yet',
    [`no_${EntityType.SYNOPTIC}`]: 'No synoptics yet',
  },
};
