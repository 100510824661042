import { sdk } from 'sdk';
import { TypedThunk } from 'store';
import { setSiteStats } from 'store/slices/auth';

export const fetchStats = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const siteId = state.auth.selectedSite?.uuid;
  if (!siteId) throw new Error();
  try {
    const { data } = await sdk.site.ArchStats(siteId);
    dispatch(setSiteStats(data));
  } catch (err) {
    console.error(err);
  }
};
