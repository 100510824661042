import { MoreVert } from '@mui/icons-material';
import { IconButton, MenuProps, Tooltip } from '@mui/material';
import { FC, HTMLAttributes, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocalizedGroupBy } from 'localization/useLocalizedGroupBy';
import { useVncStore } from 'zustand/stores/vnc';

import VariableMenu, { VariableMenuProps } from '../VariableMenu/VariableMenu';
import { BlockAggregator } from '../styled';

const CalcVarProps: FC = () => {
  const { t } = useTranslation('vnc');

  const [buttonMenu, setButtonMenu] = useState(false);
  const [contextualMenu, setContextualMenu] = useState<MenuProps['anchorPosition']>(undefined);

  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const calVarProps = useVncStore(state => state.calcVarProps);
  const setCalcVarProp = useVncStore(state => state.setCalcVarProp);

  const groupBy = useLocalizedGroupBy(calVarProps.groupBy, true);

  const openButtonMenu = useCallback(() => {
    setButtonMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setButtonMenu(false);
    setContextualMenu(undefined);
  }, []);

  const openContextualMenu = useCallback<NonNullable<HTMLAttributes<HTMLSpanElement>['onContextMenu']>>(event => {
    event.preventDefault();
    setContextualMenu(prevState => (prevState ? undefined : { left: event.clientX, top: event.clientY }));
  }, []);

  const changeVariable = useCallback<VariableMenuProps['onVariableChange']>(
    varCalc => {
      setCalcVarProp(varCalc);
    },
    [setCalcVarProp],
  );

  return (
    <>
      <BlockAggregator
        sx={{ position: 'relative', cursor: 'context-menu', textDecoration: 'underline dotted', top: 0, left: 0 }}
        onContextMenu={openContextualMenu}
      >
        {Boolean(calVarProps.operator) && t('global:operator.opShort', { context: calVarProps.operator })}
        {Boolean(groupBy) && ` ${groupBy}`}
        {!calVarProps.operator && !calVarProps.groupBy && t('text.opOrGroupBy')}
      </BlockAggregator>
      <Tooltip title={t('tooltip.calculationMenu')}>
        <IconButton ref={menuButtonRef} sx={{ width: 18, height: 18, transform: 'translateY(-1px)' }} onClick={openButtonMenu}>
          <MoreVert sx={{ fontSize: 12 }} />
        </IconButton>
      </Tooltip>
      <VariableMenu
        global
        anchorEl={menuButtonRef.current}
        anchorPosition={contextualMenu}
        anchorReference={contextualMenu ? 'anchorPosition' : 'anchorEl'}
        open={buttonMenu || Boolean(contextualMenu)}
        variable={calVarProps}
        onClose={closeMenu}
        onVariableChange={changeVariable}
      />
    </>
  );
};

export default CalcVarProps;
