import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { BatchElement, BatchVariable } from '@dametis/core';

import { createEmptyText } from 'components/VNC/slate/text';

export const createBatchElement = (batch: BatchElement['batch']): BatchElement => ({
  type: 'batch',
  batch,
  children: [createEmptyText()],
});

export const insertBatchInSlate = (editor: Editor, batch: BatchVariable) => {
  const batchElement = createBatchElement(batch);
  ReactEditor.focus(editor);
  Transforms.insertNodes(editor, batchElement);
  Transforms.move(editor);
};
