import { Menu, MenuItem, MenuProps } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DateSystem } from '@dametis/core';

export interface DateSystemMenuProps extends Omit<MenuProps, 'value' | 'onChange'> {
  value: DateSystem;
  onChange: (newDateSystem: DateSystem) => void;
}

const DateSystemMenu: FC<DateSystemMenuProps> = ({ value, onChange, ...props }) => {
  const { t } = useTranslation('format');

  const handleSelectValue = useCallback(
    (dateSystem: DateSystem) => () => {
      onChange(dateSystem);
    },
    [onChange],
  );

  return (
    <Menu {...props}>
      {Object.values(DateSystem).map(dateSystem => (
        <MenuItem key={dateSystem} selected={dateSystem === value} onClick={handleSelectValue(dateSystem)}>
          {t(`dateSystem.${dateSystem}`)}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default DateSystemMenu;
