import { CalculationVariable, FillOperators, setOptionsToLeaves } from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

export const keepRootProps = (calculation: CalculationVariable): Partial<CalculationVariable> => ({
  ...(calculation.period && { period: calculation.period }),
  ...(calculation.timeZone && { timeZone: calculation.timeZone }),
  ...(calculation.maxPoints && { maxPoints: calculation.maxPoints }),
  ...(calculation.targetPoints && { targetPoints: calculation.targetPoints }),
  ...(calculation.autoGroupByReplacement && { autoGroupByReplacement: calculation.autoGroupByReplacement }),
  ...(calculation.stats && { stats: calculation.stats }),
});

export const removeRootProps = ({
  period,
  timeZone,
  maxPoints,
  targetPoints,
  autoGroupByReplacement,
  ...rest
}: CalculationVariable): CalculationVariable => rest;

export const addLevel = (calculation: CalculationVariable, rootProps: Partial<CalculationVariable> = {}): CalculationVariable => ({
  exp: 'var_0',
  vars: {
    var_0: removeRootProps(calculation),
  },
  ...keepRootProps(calculation),
  ...rootProps,
});

export const applySmartFill = (calculation: CalculationVariable): CalculationVariable => {
  if (getSingleVariableCalculation(calculation)) {
    setOptionsToLeaves(calculation, { fill: FillOperators.NULL }, false);
  }
  return calculation;
};
