import { t } from 'i18next';
import { NumberSchema, StringSchema } from 'yup';

import { AUTO_GROUPBY, MUTED_GROUPBY, timeIntervalRegex } from '@dametis/core';

/**
 * IPv4 validation for string field
 * @param message error message when no match
 * @returns
 */
export const ipv4 = function fIpv4(this: any, message = 'Invalid IP address'): StringSchema {
  return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
    message,
    excludeEmptyString: true,
  }).test('ip', message, (value: string | undefined) =>
    value === undefined || value.trim() === '' ? true : value.split('.').find(i => parseInt(i, 10) > 255) === undefined,
  );
};

/**
 * Step validation for number field
 * @param val step to match with
 * @param message error message when no match
 * @returns
 */
export const step = function fStep(this: any, val = 1, message = 'Invalid number step'): NumberSchema {
  return this.test('step', message, (value: number) => value % val === 0);
};

export const isNot = function fIsNot(this: any, str: string, message = 'Should not be this value'): StringSchema {
  return this.test('isNot', message, (value: string | undefined) => value !== str);
};

export const notEmptyString = function fNotEmptyString(this: any, message: string = t('validations:required')): StringSchema {
  return this.test('notEmptyString', message, (value: string | undefined) => value !== undefined);
};

export const delay = function fDelay(this: any, message: string = t('validations:invalidDelay')): StringSchema {
  return this.test(
    'delay',
    message,
    (value: string | null) => value === MUTED_GROUPBY || (timeIntervalRegex.test(value) && Number(value.replace(/[^0-9]/g, ''))),
  );
};

export const livableDelay = function fLivableDelay(this: any, message: string = t('validations:invalidDelay')): StringSchema {
  return this.test('livableDelay', message, (value: string | null) => value === MUTED_GROUPBY || timeIntervalRegex.test(value));
};

export const variableDelay = function fVariableDelay(this: any, message: string = t('validations:invalidDelay')): StringSchema {
  return this.test(
    'variableDelay',
    message,
    (value: string | null) =>
      value === AUTO_GROUPBY || value === MUTED_GROUPBY || (timeIntervalRegex.test(value) && Number(value.replace(/[^0-9]/g, ''))),
  );
};
