import { ColumnType, SourceColumnRealByHeader, SourceColumnRealByIndex, SourceColumnVirtual } from '@dametis/core';

export const createSourceColumnRealByIndex = ({
  type = ColumnType.REAL_BY_INDEX,
  value = 0,
}: Partial<SourceColumnRealByIndex> = {}): SourceColumnRealByIndex => ({
  type,
  value,
});

export const createSourceColumnRealByHeader = ({
  type = ColumnType.REAL_BY_HEADER,
  value = '',
}: Partial<SourceColumnRealByHeader> = {}): SourceColumnRealByHeader => ({
  type,
  value,
});

export const createSourceColumnVirtual = ({
  type = ColumnType.VIRTUAL,
  value = '',
}: Partial<SourceColumnVirtual> = {}): SourceColumnVirtual => ({
  type,
  value,
});
