import { FC, PropsWithChildren, useEffect } from 'react';
import { toast } from 'sonner';

import CustomToast from 'components/UI/Toast/CustomToast';
import { useDispatch, useSelector } from 'store';
import { removeToast } from 'store/slices/toast';

import DefaultLayout from './DefaultLayout';

const Layouts: FC<PropsWithChildren> = ({ children = undefined }) => {
  const dispatch = useDispatch();

  const isAuth = useSelector(state => Boolean(state.auth.user));
  const toastList = useSelector(state => state.toast.toastList);

  useEffect(() => {
    if (toastList && toastList.length > 0) {
      toastList.forEach(toastItem => {
        toast.custom(t => <CustomToast id={t} toastItem={toastItem} />, {
          duration: toastItem.persist ? Infinity : 4000,
        });
        dispatch(removeToast(toastItem.id));
      });
    }
  }, [dispatch, toastList]);

  return isAuth ? <DefaultLayout>{children}</DefaultLayout> : <main>{children}</main>;
};

export default Layouts;
