import { Descendant, Editor, Element, Transforms } from 'slate';

import { CalculationVariable, IsCalculationVariable, ParagraphElement } from '@dametis/core';

import { tadaToSlate } from './tada';
import { createEmptyText } from './text';

export const createParagraph = (children: ParagraphElement['children'] = [createEmptyText()]): ParagraphElement => ({
  type: 'paragraph',
  children,
});

export const insertCalculationInSlate = (editor: Editor, calculation: CalculationVariable | Descendant[]) => {
  const nodes = IsCalculationVariable(calculation) ? tadaToSlate(calculation) : calculation;
  const [firstNode, ...lastNodes] = nodes;
  if (Element.isElement(firstNode) && firstNode.type === 'paragraph') {
    Transforms.insertNodes(editor, firstNode.children);
    Transforms.insertNodes(editor, lastNodes);
  } else {
    Transforms.insertNodes(editor, nodes);
  }
};
