import { DialogProps } from '@mui/material';
import { FC, ReactNode } from 'react';

import { CreateCommentInfo } from '@dametis/core';

import { CommentModalMode, CommentsProps } from 'types/comment';

import CommentProvider from '../Comment/context/CommentContext';

import CommentModalBase from './CommentModalBase';

const dialogPropsDefaultProp: Omit<DialogProps, 'open' | 'onClose' | 'fullWidth' | 'maxWidth'> = {};

export interface CommentModalProps {
  open: boolean;
  defaultValue?: CreateCommentInfo;
  mode?: `${CommentModalMode}`;
  title?: ReactNode;
  subtitle?: ReactNode;
  isMessageRequired?: boolean;
  onCreate?: (comment: CreateCommentInfo) => Promise<void>;
  onUpdate?: (comment: CreateCommentInfo) => Promise<void>;
  onClose?: () => void;
  dialogProps?: Omit<DialogProps, 'open' | 'onClose' | 'fullWidth' | 'maxWidth'>;
  footerText?: string;
  datePicker?: boolean;
  date?: Date;
}

const CommentModal: FC<CommentModalProps & CommentsProps> = ({
  mode = CommentModalMode.CREATE,
  defaultValue = undefined,
  title = undefined,
  subtitle = undefined,
  onCreate = undefined,
  onUpdate = undefined,
  onClose = undefined,
  isMessageRequired = false,
  dialogProps = dialogPropsDefaultProp,
  footerText = undefined,
  datePicker = false,
  date = undefined,
  ...props
}) => (
  <CommentProvider>
    <CommentModalBase
      date={date}
      datePicker={datePicker}
      defaultValue={defaultValue}
      dialogProps={dialogProps}
      footerText={footerText}
      isMessageRequired={isMessageRequired}
      mode={mode}
      subtitle={subtitle}
      title={title}
      onClose={onClose}
      onCreate={onCreate}
      onUpdate={onUpdate}
      {...props}
    />
  </CommentProvider>
);

export default CommentModal;
