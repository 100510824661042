import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { TreeViewComponents } from '@mui/x-tree-view/themeAugmentation';

export default {
  defaultProps: {
    slots: {
      collapseIcon: ExpandMore,
      expandIcon: ChevronRight,
    },
  },
} as TreeViewComponents['MuiTreeView'];
