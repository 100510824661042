import { Done } from '@mui/icons-material';
import { ListItem, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import { FC, MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Format } from '@dametis/core';

import { areSameFormats } from 'functions/areSameFormats';

import { useFormatPickerContext } from './FormatPickerContext';
import { formatNameFromFormat } from './functions/formatNameFromFormat';
import { formatValueFromFormat } from './functions/formatValueFromFormat';

export interface FormatListProps {
  subheader: string;
  formatList: Format[];
  onFormatClick: (format: Format) => void;
}

const FormatList: FC<FormatListProps> = ({ subheader, formatList, onFormatClick }) => {
  const { t } = useTranslation('format');

  const { pickerValue } = useFormatPickerContext();

  const handleFormatClick = useCallback(
    (format: Format): MouseEventHandler<HTMLDivElement> =>
      () => {
        onFormatClick(format);
      },
    [onFormatClick],
  );

  return (
    <>
      <li>
        <ul>
          <ListSubheader disableGutters>{subheader}</ListSubheader>
          {formatList.map(format => (
            <ListItem key={formatNameFromFormat(format, t)} disablePadding>
              <ListItemButton
                onClick={handleFormatClick(format)}
                // ref={itemRef}
                // selected={index === focusIndex}
                // onMouseEnter={handleEnterUnit}
                // onMouseLeave={handleLeaveUnit}
              >
                <ListItemText
                  primary={formatNameFromFormat(format, t)}
                  secondary={
                    !areSameFormats(pickerValue, format) && format.exampleValue !== undefined ? (
                      formatValueFromFormat(format.exampleValue, format)
                    ) : (
                      <Done fontSize="small" sx={{ color: theme => theme.palette.black }} />
                    )
                  }
                  secondaryTypographyProps={areSameFormats(pickerValue, format) ? { sx: { lineHeight: 0 } } : undefined}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'space-between' }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </ul>
      </li>
    </>
  );
};

export default FormatList;
