import { FolderInfo, PicoEntityInfo, Shortcut, ShortcutCategory, UUID } from '@dametis/core';

export enum ExplorerItemType {
  FOLDER = 'folder',
  SHORTCUT = 'shortcut',
}

export interface ExplorerItemContent {
  [ExplorerItemType.FOLDER]: FolderInfo;
  [ExplorerItemType.SHORTCUT]: Shortcut;
}

export interface ExplorerItem<T extends ExplorerItemType = ExplorerItemType> {
  type: T;
  content: ExplorerItemContent[T];
}

export type ExplorerFolder = ExplorerItem<ExplorerItemType.FOLDER>;

export const IsExplorerFolder = (item: ExplorerItem): item is ExplorerFolder => item?.type === ExplorerItemType.FOLDER;

export type ExplorerShortcut = ExplorerItem<ExplorerItemType.SHORTCUT>;

export const IsExplorerShortcut = (item: ExplorerItem): item is ExplorerShortcut => item?.type === ExplorerItemType.SHORTCUT;

export type AvailableShortcutCategory = ShortcutCategory.BLOCK_TYPE | ShortcutCategory.STANDARD_BLOCK | ShortcutCategory.BLOCK;

export const availableShortcutCategories: AvailableShortcutCategory[] = [
  ShortcutCategory.BLOCK_TYPE,
  ShortcutCategory.STANDARD_BLOCK,
  ShortcutCategory.BLOCK,
];

export const IsAvailableShortcutCategory = (category: ShortcutCategory): category is AvailableShortcutCategory =>
  availableShortcutCategories.includes(category as AvailableShortcutCategory);

export const DEFAULT_DISPLAYED_SHORTCUTS: AvailableShortcutCategory[] = [
  ShortcutCategory.BLOCK_TYPE,
  ShortcutCategory.STANDARD_BLOCK,
  ShortcutCategory.BLOCK,
];

export type EntitiesByCategory = Record<AvailableShortcutCategory, Record<UUID, PicoEntityInfo>>;
