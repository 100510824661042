import { FC, PropsWithChildren, useEffect } from 'react';

interface Props {
  title: string;
}

const DocumentTitle: FC<PropsWithChildren<Props>> = ({ title, children = null }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return <>{children}</>;
};

export default DocumentTitle;
