import { OrderBy } from '@dametis/core';

import { ChangesOption } from 'components/Monitoring/ipseite/SiteTile/AdminFilters/AdminFilters';
import { StateIconVariant } from 'components/UI/StateIcon/StateIcon';
import { DamcoSubPeriodType } from 'store/slices/monitoring';
import { DataPeriod, SortBy } from 'store/slices/oldMonitoring';

export default {
  title: {
    page: 'Monitoring',
    box: 'Caja: {{name}}',
    boxes: 'Cajas',
    devices: 'Equipos',
    variables: 'Variables',
    detailsModal: '{{entities}} disponibles',
    editBoxAlertThresholds: 'Editar umbrales de alerta de caja',
    editDeviceAlertThresholds: 'Editar umbrales de alerta del equipo',
    editVariableAlertThresholds: 'Editar umbrales de alerta de variable',
  },
  label: {
    sites: 'Sitios',
    groups: 'Grupos',
    boxes_one: 'Caja',
    boxes_other: 'Cajas',
    devices: 'Equipos',
    variables: 'Variables',
    hideUnchangedSites: 'Ocultar sitios sin cambios',
    stateIconVariants: 'Estado',
    pending: 'Retrasado',
    error_one: 'error',
    error_other: 'errores',
    connected_one: 'conectado',
    connected_other: '{{count}} conectados',
    disconnected_one: 'desconectado',
    disconnected_other: '{{count}} desconectados',
    stateIconVariant: {
      [StateIconVariant.ERROR]: 'Cambio a inactivo',
      [StateIconVariant.WARNING]: '',
      [StateIconVariant.SUCCESS]: 'Cambio a activo',
      [StateIconVariant.UNSET]: 'Sin cambios',
    },
    changesOptions: 'Últimos cambios',
    changesOption: {
      [ChangesOption.DEVICES_NO]: 'Sin cambios recientes',
      [ChangesOption.DEVICES_MORE]: 'Ganancias',
      [ChangesOption.DEVICES_LESS]: 'Pérdidas',
      [ChangesOption.VARIABLES_NO]: 'Sin cambios recientes',
      [ChangesOption.VARIABLES_MORE]: 'Ganancias',
      [ChangesOption.VARIABLES_LESS]: 'Pérdidas',
    },
    dataPeriods: {
      [DataPeriod.ONE_DAY]: '1 día',
      [DataPeriod.THREE_DAYS]: '3 días',
      [DataPeriod.ONE_WEEK]: '1 semana',
      [DataPeriod.TWO_WEEKS]: '2 semanas',
      [DataPeriod.ONE_MONTH]: '1 mes',
    },
    orderBy: {
      [OrderBy.ASC]: 'Ascendente',
      [OrderBy.DESC]: 'Descendente',
    },
    sortBy: {
      [SortBy.GROUP_NAME]: 'Nombre del grupo',
      [SortBy.SITE_NAME]: 'Nombre del sitio',
      [SortBy.NB_DEVICES_CHANGES]: 'Número de últimos cambios (equipos)',
      [SortBy.NB_VARIABLES_CHANGES]: 'Número de últimos cambios (variables)',
      [SortBy.DATE_DEVICES_CHANGES]: 'Fecha de últimos cambios (equipos)',
      [SortBy.DATE_VARIABLES_CHANGES]: 'Fecha de últimos cambios (variables)',
    },
    deviceState: 'Equipo',
    deviceLagging: 'Desconectado',
    deviceNotLagging: 'Conectado',
    deviceNoData: 'Sin datos',
    variableLag: 'Variables',
    variableLagging: 'Retrasado',
    variableDataHole: 'Disponibilidad',
    noVariables: 'Sin variables',
    lastValue: 'Último valor',
  },
  text: {
    infrastructureState: 'Estado de la infraestructura',
    allSelected: 'Seleccionar todo',
    boxAvailability: 'Disponibilidad de la caja',
    changes_zero: 'Sin cambio',
    changes_one: '{{count}} cambio',
    changes_other: '{{count}} cambios',
    date: {
      from: 'desde el {{date}}',
      to: 'al {{date}}',
      complete: 'desde el {{from}} al {{to}}',
    },
    period: {
      forOneHour: 'desde hace 1 hora',
      forFiveHours: 'desde hace 5 horas',
      forTwelveHours: 'desde hace 12 horas',
      forOneDay: 'desde hace 1 día',
      forTwoDays: 'desde hace 2 días',
      forOneWeek: 'desde hace 1 semana',
    },
    boxesDown: 'Cajas no disponibles',
    devicesDown: 'Equipos no disponibles',
    variablesDown: 'Variables no disponibles',
    noValue: 'n/a',
    noRecentChanges: 'Sin cambios recientes.',
    variablesAvailability: {
      period: 'desde el {{from}} al {{to}}',
      value: '{{value}} variables disponibles',
      noData: 'Sin datos',
    },
    unknownSite: 'Sitio desconocido',
    nothingToDisplay: 'Ningún sitio para mostrar. Intente cambiar la configuración de sus filtros.',
    fetchingSitesData: 'Recuperando datos de los sitios...',
    neverConnected: 'Nunca conectado',
    pending: 'Retrasado',
    upToDate: 'Al día',
    subPeriodType: {
      [DamcoSubPeriodType.HOLE]: 'Pérdida',
      [DamcoSubPeriodType.LAG]: 'Retraso',
    },
    inProgress: 'En progreso...',
    received: 'Recibido',
    alertThreshold: 'Límite de alertas',
    noBox: 'No hay caja',
    noDevice: 'No hay equipo',
  },
  grid: {
    site: 'Sitio',
    group: 'Grupo',
    availability: 'Disponibilidad de la caja',
    period: 'Período de datos',
  },
  tooltip: {
    handleNotification: 'Gestionar notificaciones',
    launchArmageddon: 'Iniciar el armagedón',
    launchArmageddonOnDevice: 'Iniciar el armagedón en este equipo',
    accessLog: 'Acceder a los registros',
    editBoxAlertThresholds: 'Editar umbrales de alerta de caja',
    editDeviceAlertThresholds: 'Editar umbrales de alerta del equipo',
    editVariableAlertThresholds: 'Editar umbrales de alerta de variable',
  },
  button: {
    cancel: 'Cancelar',
    save: 'Guardar',
    add: 'Añadir',
  },
  devicesGrid: {
    status: 'Estado',
    name: 'Nombre',
    availability: 'Disponibilidad',
    lastPointTime: 'Último valor',
    lateVariables: 'Retrasado',
    defaultVariableDataDelay: 'Entre dos actualizaciones',
    deviceHeartbeatFrequency: 'Entre dos ejecuciones',
    defaultVariableFrequency: 'Entre dos puntos',
    tooltip: {
      defaultVariableDataDelay: 'Frecuencia esperada entre dos actualizaciones de datos del equipo',
      deviceHeartbeatFrequency: 'Frecuencia esperada entre dos ejecuciones del equipo',
      defaultVariableFrequency: 'Frecuencia esperada entre dos puntos de una variable (por defecto)',
    },
  },
  variablesGrid: {
    lastLagState: 'Estado',
    name: 'Nombre',
    availability: 'Disponibilidad',
    lastPointValue: 'Último valor',
    lastPointDate: 'Fecha del último valor',
    delay: 'Tiempo',
    frequency: 'Frecuencia',
    variableFrequency: 'Entre dos puntos',
  },
  detailPanelGrid: {
    type: 'Tipo',
    from: 'Inicio',
    to: 'Fin',
    duration: 'Duración',
  },
  prompt: {
    armageddon: 'El modo Armagedón elimina todos los datos de monitoring; utilice esta función solo si está seguro de lo que está haciendo',
  },
  input: {
    boxDataMaxDelay: 'Frecuencia esperada entre dos ejecuciones de la caja',
    defaultVariableDataDelay: 'Frecuencia esperada entre dos actualizaciones de datos del equipo',
    deviceHeartbeatFrequency: 'Frecuencia esperada entre dos ejecuciones del equipo',
    defaultVariableFrequency: 'Frecuencia esperada entre dos puntos de una variable (por defecto)',
    variableFrequency: 'Frecuencia esperada entre dos puntos',
  },
};
