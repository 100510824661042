import { HelpOutline } from '@mui/icons-material';
import { Alert, AlertColor, AlertTitle, Box, Skeleton, Stack, Tooltip, Typography, alertClasses, capitalize } from '@mui/material';
import { CanceledError } from 'axios';
import { isFuture, subWeeks } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSlate } from 'slate-react';

import {
  ApplicationError,
  CalculationVariable,
  ErrorName,
  FillOperators,
  IsEmptyCalculation,
  Operator,
  ShortAliasInfo,
  TraverseCalculation,
  setOptionsToLeaves,
} from '@dametis/core';
import { ValidateCalculation, getSingleVariableCalculation, mathJs } from '@dametis/mathjs';

import CalculationMenuContainer from 'components/UI/CalculationMenu/CalculationMenuContainer';
import { getFormattedValue } from 'components/UI/UnitPicker/functions/getFormattedValue';
import { UnitResult } from 'components/UI/UnitPicker/types';
import { WipFeatures } from 'config/featureFlags';
import { createCalculationVariable } from 'functions/createCalculationVariable';
import { getCalculationUnit } from 'functions/getCalculationUnit';
import { map } from 'functions/numberMap';
import { Tada } from 'functions/tada/tada';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { localizedPeriod } from 'localization';
import { useSelector } from 'store';
import { useAliases } from 'store/api/aliases';
import { useVncStore } from 'zustand/stores/vnc';

import { KBM } from '../../../UI/Hotkeys/KBM';
import { PropsContext } from '../../context';
import { hasForbiddenOperators, slateToTada } from '../../slate/tada';

import PreviewUnitButton from './PreviewUnitButton';

const MIN_RESULT_LENGTH = 0;
const MAX_RESULT_LENGTH = 16;
const MIN_RESULT_FONT_SIZE = 16;
const MAX_RESULT_FONT_SIZE = 30;

const Container: FC<PropsWithChildren<{ enableCalculation: boolean; parsedCalculation: CalculationVariable | null }>> = ({
  children = null,
  enableCalculation,
  parsedCalculation,
}) => {
  if (enableCalculation) {
    return (
      <Box
        calculation={parsedCalculation}
        component={CalculationMenuContainer}
        sx={{ width: theme => theme.spacing(28), py: 1, pr: 1, height: 1 }}
      >
        {children}
      </Box>
    );
  }
  return <Box sx={{ width: theme => theme.spacing(28), py: 1, pr: 1, height: 1 }}>{children}</Box>;
};

const emptyArray: ShortAliasInfo[] = [];

const Preview: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['vnc', 'global']);
  const editor = useSlate();

  const { standardBlockMetricMode, disableMaths, variableOperatorOptions, unitPicker, output } = useContext(PropsContext);

  const enableCalculation = useFeatureFlags(WipFeatures.ENABLE_CALCULATION);

  const storeFrom = useSelector(state => state.period.present.period.from);
  const storeTo = useSelector(state => state.period.present.period.to);

  const calcVarUnit = useVncStore(state => state.calcVarProps?.unit ?? null);
  const calcVarProps = useVncStore(state => state.calcVarProps);
  const setIsCalculationValid = useVncStore(state => state.setIsCalculationValid);
  const setCalcVarProp = useVncStore(state => state.setCalcVarProp);

  const { data: aliases = emptyArray } = useAliases();

  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const [loading, setLoading] = useState(false);
  const [parsedCalculation, setParsedCalculation] = useState<CalculationVariable | null>(null);
  const [userUnit, setUserUnit] = useState<UnitResult>(calcVarUnit);

  const calcVar = useMemo(() => slateToTada(editor.children, { withSlate: false, calcVarProps }), [editor.children, calcVarProps]);
  const calcVarIsEmpty = useMemo(() => IsEmptyCalculation(calcVar), [calcVar]);
  const calcVarHasHistoryOp = useMemo(() => {
    let hasHistoryOp = false;
    TraverseCalculation(calcVar, node => {
      // FKL: Needs DVI's number of points prediction function
      if (node.operator === Operator.HISTORY) {
        hasHistoryOp = true;
      }
    });
    return hasHistoryOp;
  }, [calcVar]);
  const fontSize = useMemo(
    () =>
      map(
        result.length + (userUnit?.trim().length ?? 0),
        [MIN_RESULT_LENGTH, MAX_RESULT_LENGTH],
        [MIN_RESULT_FONT_SIZE, MAX_RESULT_FONT_SIZE],
        true,
      ),
    [result.length, userUnit],
  );
  const severity = useMemo<AlertColor>(() => {
    if (error) return 'error';
    if (calcVarIsEmpty) return 'info';
    if (warning) return 'warning';
    return 'success';
  }, [error, calcVarIsEmpty, warning]);
  const periodIsFuture = useMemo(() => isFuture(storeTo), [storeTo]);
  const to = useMemo(() => (periodIsFuture ? new Date() : storeTo), [periodIsFuture, storeTo]);
  const from = useMemo(
    () => (calcVarHasHistoryOp || periodIsFuture ? subWeeks(to, 1) : storeFrom),
    [calcVarHasHistoryOp, periodIsFuture, to, storeFrom],
  );
  const range = useMemo(() => localizedPeriod(from, to), [from, to]);

  const calculationUnit = useMemo(() => getCalculationUnit(parsedCalculation, false), [parsedCalculation]);

  const handleChangeUnit = useCallback(
    (unit: UnitResult) => {
      setUserUnit(unit);
      setCalcVarProp({ unit: unit ?? undefined });
    },
    [setCalcVarProp],
  );

  const getPreview = useCallback(
    async (controller: AbortController) => {
      setResult('');
      setError('');
      setWarning('');
      setLoading(true);
      setParsedCalculation(null);
      if (calcVarIsEmpty) {
        setUserUnit(null);
        setIsCalculationValid(true);
        setLoading(false);
        return;
      }
      setIsCalculationValid(false);
      try {
        if (disableMaths && !getSingleVariableCalculation(calcVar)) {
          setError(t('alert.disableMaths'));
          setIsCalculationValid(false);
          setLoading(false);
          return;
        }
        const forbiddenOperators = hasForbiddenOperators(calcVar, variableOperatorOptions);
        if (forbiddenOperators.length) {
          setError(
            t('alert.forbiddenOperators', {
              operators: forbiddenOperators.map(op => capitalize(t('global:operator.op', { context: op }))),
              count: forbiddenOperators.length,
            }),
          );
          setIsCalculationValid(false);
          setLoading(false);
          return;
        }
        const calcVarForcePrevious = cloneDeep(calcVar);
        // forcer le fill previous pour éviter d'avoir un null dans le LAST et donc aucune valeur qui s'affiche ("Ok!")
        setOptionsToLeaves(calcVarForcePrevious, { fill: FillOperators.PREVIOUS }, true);
        const payloadLast = createCalculationVariable({
          operator: Operator.LAST,
          exp: 'var_0',
          vars: { var_0: calcVarForcePrevious },
          period: { from: from.toISOString(), to: to.toISOString() },
        });
        ValidateCalculation(mathJs, payloadLast);
        if (standardBlockMetricMode) {
          setResult(t('alert.tadaOk'));
          setIsCalculationValid(true);
          setLoading(false);
          return;
        }
        setParsedCalculation(calcVar);
        const payloadCount = createCalculationVariable({
          operator: Operator.COUNT,
          exp: 'var_0',
          vars: { var_0: calcVar },
          period: { from: from.toISOString(), to: to.toISOString() },
        });
        const {
          data: [{ results, errors }, { results: resultsCount }],
        } = await Tada(
          [payloadLast, payloadCount],
          {
            signal: controller.signal,
          },
          { singleValue: output === 'singleValue' },
        );
        const resultLast = results.at(-1)?.value;
        if (errors?.length) {
          setError(`${errors.map(err => err.message).join('. ')}.`);
          setIsCalculationValid(false);
        } else if (!Number.isFinite(resultLast)) {
          setResult(t('alert.tadaOk'));
          setIsCalculationValid(true);
        } else {
          setResult(getFormattedValue({ value: resultLast, userUnit: getCalculationUnit(payloadLast) }));
          setIsCalculationValid(true);
        }
        const resultCount = resultsCount.at(-1)?.value;
        if (output === 'singleValue' && resultCount !== undefined && resultCount !== 1) {
          setWarning(t('alert.tadaWarningSingleValue', { counter: getFormattedValue({ value: resultCount }) }));
        } else if (output === 'multipleValues' && resultCount !== undefined && resultCount === 1) {
          setWarning(t('alert.tadaWarningMultipleValues'));
        }
        setLoading(false);
      } catch (e) {
        if (e instanceof CanceledError) return;
        if (e instanceof SyntaxError || (e instanceof ApplicationError && e.errorName === ErrorName.INVALID_MATH_EXPRESSION)) {
          setError(e.message);
        } else {
          setError(t('alert.tadaError'));
          console.error(e);
        }
        setIsCalculationValid(false);
        setLoading(false);
      } // Don't use finally to setLoading. Why? Idk.
    },
    [
      calcVarIsEmpty,
      setIsCalculationValid,
      disableMaths,
      calcVar,
      variableOperatorOptions,
      from,
      to,
      standardBlockMetricMode,
      output,
      t,
      language,
    ],
  );

  useEffect(() => {
    const controller = new AbortController();
    void getPreview(controller);
    return () => {
      controller.abort();
    };
  }, [getPreview, aliases]);

  useEffect(() => {
    if (calcVarUnit) {
      setUserUnit(calcVarUnit);
    }
  }, [calcVarUnit]);

  return (
    <Container enableCalculation={enableCalculation} parsedCalculation={parsedCalculation}>
      {loading ? (
        <Skeleton animation="wave" height="100%" variant="rounded" width="100%" />
      ) : (
        <Alert severity={severity} sx={{ height: 1, [`& .${alertClasses.message}`]: { width: 1, overflow: 'unset' } }}>
          {severity === 'error' && error}
          {severity === 'info' &&
            (disableMaths ? (
              t('alert.disableMaths')
            ) : (
              <>
                {t('alert.ignoreSymbols1')} <KBM>&quot;</KBM> {t('alert.ignoreSymbols2')}
              </>
            ))}
          {(severity === 'success' || severity === 'warning') && (
            <Stack sx={{ height: 1 }}>
              <AlertTitle color="inherit" sx={{ my: 1, textAlign: 'right', fontSize, flexGrow: 1 }}>
                <Stack alignItems="center" direction="row" flexWrap="wrap" justifyContent="flex-end" sx={{ height: 1 }}>
                  <Box overflow="hidden">{result}</Box>
                  {parsedCalculation && !calculationUnit && unitPicker && <PreviewUnitButton unit={userUnit} onChange={handleChangeUnit} />}
                </Stack>
              </AlertTitle>
              <Typography color="inherit" textAlign="right" variant="caption">
                <Stack alignItems="center" direction="row" justifyContent="flex-end" spacing={0.5}>
                  <span>{range}</span>
                  {(periodIsFuture || calcVarHasHistoryOp) && (
                    <Tooltip title={t(`tooltip.${calcVarHasHistoryOp ? 'calcVarHasHistoryOp' : 'periodIsFuture'}`)}>
                      <HelpOutline fontSize="inherit" />
                    </Tooltip>
                  )}
                </Stack>
              </Typography>
              {warning && <Box sx={{ mt: 1 }}>{warning}</Box>}
            </Stack>
          )}
        </Alert>
      )}
    </Container>
  );
};

export default Preview;
