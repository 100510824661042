import { VariableKind } from '@dametis/core';

import { FakeVariableKind } from 'types/variables';

export default {
  Error: {
    sameVarSelect: 'Please select a different variable',
    variableListNameRequired: 'List name is required',
    variableListNameAlreadyExist: 'List name already exist',
    badFileType: 'Bad file type',
    dateError: 'Please select a column for the date',
    duplicateHeaderError: "You file contain a duplicate header, deactivate the option 'My file contains headers' or fix it in the file",
  },
  Autocomplete: {
    noOption: 'No options',
    noUnit: 'No units',
    noList: 'No list',
    noValue: 'None',
    list: 'list',
    clear: 'Clear',
    close: 'Close',
    open: 'Open',
    select: 'Select',
    unknown: 'Unknown unit',
    addUnitOption: 'Add "{{name}}" unit',
    placeholder: 'Unit',
  },
  Select: {
    placeholder: 'Select a list...',
    addList: 'New List',
  },
  Import: {
    title: 'Import manual data',
    addOrEditList: 'Please add or select a list',
    date: 'Date',
    dateConfirmation: 'Are you sure about that date ?',
    warningDates: 'Please note that some dates may be incorrect',
    Action: {
      Button: {
        save: 'Save',
        addVar: 'Add',
        showMore: 'Show more',
        addVariableList: 'List',
        editVariableList: 'Edit list',
      },
      ImportFile: {
        importVariables: 'Import',
        replaceData: 'Replace all...',
        concatData: 'Add after...',
        cancelImport: 'Cancel',
        validateImport: 'Validate',
      },
      SelectorFile: {
        title: 'Import variables file',
        dragAndDrop: 'Drag And Drop',
        or: 'or',
        selectFile: 'Select a file',
        selectedFile: 'File selected',
      },
    },
    Dialog: {
      titleEditList: 'Edit a list',
      titleAddList: 'Add a list',
      Button: {
        addVar: 'Add',
        confirm: 'Next',
        cancel: 'Cancel',
        delete: 'Delete',
      },
      SelectList: {
        manualVariables: 'Manual variables',
        name: 'Name',
      },
    },
    WelcomeMessage: {
      create: 'Create a new list',
      or: 'or',
    },
    Field: {
      Timestamp: {
        label: 'Timestamp',
        labelTooltip: 'Timestamp',
      },
    },
    ImportTable: {
      newList: 'New List',
    },
    Footer: {
      Button: {
        newEntry: 'New entry',
      },
    },
    tooltip: {
      createList: 'Create a new list',
    },
    ImportDataDialog: {
      title: 'Imported File',
      checkbox: 'My file has headers',
      column_space: 'Column ',
      start_line: 'My data starts at line:',
      formats: {
        format1: 'DD/MM/YYYY hh:mm:ss',
        format2: 'MM/DD/YYYY hh:mm:ss',
        format3: 'YYYY/MM/DD hh:mm:ss',
        format4: 'YYYY/DD/MM hh:mm:ss',
      },
      noValidDate: 'No valid date found, please try to change the column associated or the date format',
      parameters: 'Parameters',
      matching: 'Match fields',
      preview: 'Preview',
      results: 'Results',
      linesWillBeImported: ' lines will be imported',
      lineWillBeImported: ' line will be imported',
      table: {
        header1: 'Fields name',
        header2: 'File columns',
      },
    },
  },
  Export: {
    title: 'Export Variables',
    Typo: {
      dateInterval: 'Date interval',
      periodicity: 'Periodicity',
      optionExport: 'Export option',
      varSelector: 'Variables',
    },
    Button: {
      export: 'Download',
    },
    Time: {
      seconds: 'Seconds',
      minutes: 'Minutes',
      hours: 'Hours',
      days: 'Days',
      weeks: 'Weeks',
    },
    Input: {
      Label: {
        to: 'Ending',
        from: 'Begining',
      },
    },
  },
  MyVariable: {
    operation: 'Operation',
    title: 'Variables',
    unit: 'Unit',
    type: 'Type',
    variableType: 'Variable type',
    Typo: {
      createVar: 'Add',
      varManual: 'Manual',
      varManualDesc: 'Import the data yourself',

      varCalculate: 'Calculated',
      varCalculateDesc: 'This variable is based on other existing variables',
    },
    Button: {
      createVar: 'Creation',
      advancedSearch: 'Advanced search',
      validate: 'Validate',
      cancel: 'Cancel',
      saveVar: 'Save',
    },
    Textfield: {
      name: 'Name',
      description: 'Description',
      reference: 'Reference',
      unit: 'Unit',
    },
    Type: {
      all: 'All',
      allType: 'All types',
      allUnit: 'All units',
      number: 'Number',
      bool: 'Booléen',
    },
    Field: {
      ActionMenu: {
        label: 'Action',
        labelTooltip: 'Action Menu',
        Menu: {
          refresh: 'Refresh',
          createAlarm: 'Create an alarm',
          viewAlarm: 'View all alarms',
          playground: 'Open playground',
        },
      },
      TypeVariable: {
        label: 'Var Type',
        labelTooltip: 'Variable Type',
        Type: {
          calculated: 'Calculated',
          real: 'Acquired',
          manual: 'Manual',
          unknown: 'Unknown',
        },
      },
      Name: {
        label: 'Name',
        labelTooltip: 'Name',
      },
      Ref: {
        label: 'Reference',
        labelTooltip: 'Reference',
      },
      Desc: {
        label: 'Description',
        labelTooltip: 'Description',
      },
      Unit: {
        label: 'Unit',
        labelTooltip: 'Unit',
      },
      Type: {
        label: 'Type',
        labelTooltip: 'Type',
      },
      LastImport: {
        label: 'Last Import',
        labelTooltip: 'Last Import',
      },
      LastValue: {
        label: 'Last Val',
        labelTooltip: 'Last Value',
      },
      LastAction: {
        label: 'Last Act',
        labelTooltip: 'LastAction',
      },
    },
  },
  variableKind: {
    [VariableKind.CALCULATED]: 'Calculated',
    [VariableKind.CONSTANT]: 'Constant',
    [VariableKind.MANUAL]: 'Manual',
    [VariableKind.NONE]: 'Unknown',
    [VariableKind.REAL]: 'Acquired',
    [FakeVariableKind.ALARM]: 'Alarm',
    [FakeVariableKind.BATCH]: 'Batch',
    [FakeVariableKind.VAPOR_MIX]: 'Vapor mix',
  },
};
