import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogTitle, Paper, Stack } from '@mui/material';
import { Dispatch, FC, MouseEventHandler, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTypeInfo, BlockTypeMetadata, UUID, UpdateBlockTypeBody } from '@dametis/core';

import { createUpdateBlockTypeBody } from 'components/Lego/helpers/blockType/createUpdateBlockTypeBody';
import { useDispatch } from 'store';
import { useUpdateBlockTypeMutation } from 'store/api/blockTypes';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import ConnectionsStep from '../CreateBlockTypeModal/ConnectionsStep';
import GeneralStep from '../CreateBlockTypeModal/GeneralStep';
import MetadataListsStep, { MetadataListName } from '../CreateBlockTypeModal/MetadataListsStep';
import MetricsStep from '../CreateBlockTypeModal/MetricsStep';
import ModalStepper from '../CreateBlockTypeModal/ModalStepper';
import ParametersStep from '../CreateBlockTypeModal/ParametersStep';
import { getIsConnectionsStepValid } from '../CreateBlockTypeModal/helpers/getIsConnectionsStepValid';
import { getIsGeneralStepValid } from '../CreateBlockTypeModal/helpers/getIsGeneralStepValid';
import { getIsMetadataListsStepValid } from '../CreateBlockTypeModal/helpers/getIsMetadataListsStepValid';
import { getIsMetricsStepValid } from '../CreateBlockTypeModal/helpers/getIsMetricsStepValid';
import { getIsParametersStepValid } from '../CreateBlockTypeModal/helpers/getIsParametersStepValid';

export interface EditBlockTypeModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  folderId?: UUID;
  blockType: BlockTypeInfo;
  onUpdate?: (updatedBlockType: BlockTypeInfo) => Promise<void> | void;
}

const EditBlockTypeModal: FC<EditBlockTypeModalProps> = ({ isOpen, setIsOpen, blockType, folderId = undefined, onUpdate = undefined }) => {
  const { t } = useTranslation('lego');
  const dispatch = useDispatch();

  const [updateBlockType, { isLoading: isUpdating }] = useUpdateBlockTypeMutation();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [updateBlockTypeBody, setUpdateBlockTypeBody] = useState<UpdateBlockTypeBody>(createUpdateBlockTypeBody);
  const [metadataList, setMetadataList] = useState<BlockTypeMetadata[]>([]);
  const [listNameByMetadataId, setListNameByMetadataId] = useState<Record<UUID, MetadataListName>>({});

  const isGeneralStepValid = useMemo(() => getIsGeneralStepValid(updateBlockTypeBody), [updateBlockTypeBody]);

  const isMetadataListsStepValid = useMemo(() => getIsMetadataListsStepValid(metadataList), [metadataList]);

  const isParametersStepValid = useMemo(() => getIsParametersStepValid(updateBlockTypeBody), [updateBlockTypeBody]);

  const isConnectionsStepValid = useMemo(() => getIsConnectionsStepValid(updateBlockTypeBody), [updateBlockTypeBody]);

  const isMetricsStepValid = useMemo(() => getIsMetricsStepValid(updateBlockTypeBody), [updateBlockTypeBody]);

  const isFormValid = useMemo(
    () => isGeneralStepValid && isMetadataListsStepValid && isParametersStepValid && isConnectionsStepValid && isMetricsStepValid,
    [isConnectionsStepValid, isMetadataListsStepValid, isParametersStepValid, isGeneralStepValid, isMetricsStepValid],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handlePreviousStep: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setActiveStep(state => state - 1);
  }, []);

  const handleNextStep: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setActiveStep(state => state + 1);
  }, []);

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(async () => {
    try {
      const blockTypeBody: UpdateBlockTypeBody = {
        ...updateBlockTypeBody,
        blockMetadataList: metadataList.filter(metadata => listNameByMetadataId[metadata.uuid] === MetadataListName.BLOCK),
        standardMetadataList: metadataList.filter(metadata => listNameByMetadataId[metadata.uuid] === MetadataListName.STANDARD),
      };

      const data = await updateBlockType({ uuid: blockType?.uuid, body: blockTypeBody }).unwrap();
      if (onUpdate) {
        await onUpdate(data);
      }
      setIsOpen(false);
      dispatch(addToast({ message: t('toast.updateBlockTypeSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [blockType, updateBlockTypeBody, metadataList, dispatch, setIsOpen, t, updateBlockType, onUpdate, listNameByMetadataId]);

  useEffect(() => {
    if (isOpen) {
      setUpdateBlockTypeBody(createUpdateBlockTypeBody({ ...blockType, folderId }));
      setMetadataList([...blockType.standardMetadataList, ...blockType.blockMetadataList]);
      const newListNameByMetadataId: Record<UUID, MetadataListName> = {};
      blockType.standardMetadataList.forEach(metadata => {
        newListNameByMetadataId[metadata.uuid] = MetadataListName.STANDARD;
      });
      blockType.blockMetadataList.forEach(metadata => {
        newListNameByMetadataId[metadata.uuid] = MetadataListName.BLOCK;
      });
      setListNameByMetadataId(newListNameByMetadataId);
    }
  }, [isOpen, blockType, folderId]);

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 1 }}>{t('title.editBlockTypeModal')}</DialogTitle>

      <Stack direction="row" gap={1} overflow="hidden" px={3}>
        <Box flexShrink={0} width={250}>
          <ModalStepper activeStep={activeStep} blockTypeBody={updateBlockTypeBody} listNameByMetadataId={listNameByMetadataId} />
        </Box>
        <Paper sx={{ p: 2, flexGrow: 1, display: 'flex', alignItems: 'stretch', overflow: 'hidden' }}>
          {activeStep === 0 && (
            <GeneralStep<UpdateBlockTypeBody> blockTypeBody={updateBlockTypeBody} setBlockTypeBody={setUpdateBlockTypeBody} />
          )}
          {activeStep === 1 && (
            <MetadataListsStep
              listNameByMetadataId={listNameByMetadataId}
              metadataList={metadataList}
              setListNameByMetadataId={setListNameByMetadataId}
              setMetadataList={setMetadataList}
            />
          )}
          {activeStep === 2 && (
            <ParametersStep<UpdateBlockTypeBody> blockTypeBody={updateBlockTypeBody} setBlockTypeBody={setUpdateBlockTypeBody} />
          )}
          {activeStep === 3 && (
            <ConnectionsStep<UpdateBlockTypeBody> blockTypeBody={updateBlockTypeBody} setBlockTypeBody={setUpdateBlockTypeBody} />
          )}
          {activeStep === 4 && (
            <MetricsStep<UpdateBlockTypeBody> blockTypeBody={updateBlockTypeBody} setBlockTypeBody={setUpdateBlockTypeBody} />
          )}
        </Paper>
      </Stack>

      <DialogActions>
        {activeStep === 0 && (
          <>
            <Button color="primary" variant="text" onClick={handleClose}>
              {t('button.close')}
            </Button>
            <Button
              color="secondary"
              disabled={!isGeneralStepValid}
              endIcon={<ArrowForwardIos />}
              variant="contained"
              onClick={handleNextStep}
            >
              {t('button.next')}
            </Button>
          </>
        )}
        {activeStep === 1 && (
          <>
            <Button color="primary" startIcon={<ArrowBackIosNew />} variant="text" onClick={handlePreviousStep}>
              {t('button.previous')}
            </Button>
            <Button
              color="secondary"
              disabled={!isMetadataListsStepValid}
              endIcon={<ArrowForwardIos />}
              variant="contained"
              onClick={handleNextStep}
            >
              {t('button.next')}
            </Button>
          </>
        )}
        {activeStep === 2 && (
          <>
            <Button color="primary" startIcon={<ArrowBackIosNew />} variant="text" onClick={handlePreviousStep}>
              {t('button.previous')}
            </Button>
            <Button
              color="secondary"
              disabled={!isParametersStepValid}
              endIcon={<ArrowForwardIos />}
              variant="contained"
              onClick={handleNextStep}
            >
              {t('button.next')}
            </Button>
          </>
        )}
        {activeStep === 3 && (
          <>
            <Button color="primary" startIcon={<ArrowBackIosNew />} variant="text" onClick={handlePreviousStep}>
              {t('button.previous')}
            </Button>
            <Button
              color="secondary"
              disabled={!isConnectionsStepValid}
              endIcon={<ArrowForwardIos />}
              variant="contained"
              onClick={handleNextStep}
            >
              {t('button.next')}
            </Button>
          </>
        )}
        {activeStep === 4 && (
          <>
            <Button color="primary" disabled={isUpdating} startIcon={<ArrowBackIosNew />} variant="text" onClick={handlePreviousStep}>
              {t('button.previous')}
            </Button>
            <LoadingButton color="secondary" disabled={!isFormValid} loading={isUpdating} variant="contained" onClick={handleSubmit}>
              {t('button.edit')}
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditBlockTypeModal;
