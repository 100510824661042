import { Box, Stack, StackProps, useTheme } from '@mui/material';
import { CSSProperties, Dispatch, ReactNode, SetStateAction, createContext, forwardRef, useMemo, useRef, useState } from 'react';

import { RIGHT_PANEL_WIDTH } from 'theme/others';

export interface RightPanelDrawerContextProps {
  open: boolean;
  size: 'md' | 'lg' | 'xl';
  resizable: boolean;
  container: Element | null;
  panelWidth: CSSProperties['width'];
  setPanelWidth?: Dispatch<SetStateAction<CSSProperties['width']>>;
  isResizing: boolean;
  setIsResizing?: Dispatch<SetStateAction<boolean>>;
}

export const RightPanelDrawerContext = createContext<RightPanelDrawerContextProps>({
  open: false,
  size: 'md',
  resizable: false,
  container: null,
  panelWidth: RIGHT_PANEL_WIDTH,
  setPanelWidth: undefined,
  isResizing: false,
  setIsResizing: undefined,
});

export interface RightPanelDrawerContainerProps extends StackProps {
  open: boolean;
  size?: 'md' | 'lg' | 'xl';
  resizable?: boolean;
  children: [ReactNode, ReactNode];
}

const RightPanelDrawerContainer = forwardRef<HTMLDivElement, RightPanelDrawerContainerProps>(
  ({ open, size = 'md', resizable = false, children, ...props }, ref) => {
    const muiTheme = useTheme();

    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [panelWidth, setPanelWidth] = useState<CSSProperties['width']>(
      { md: muiTheme.others.rightPanelWidth, lg: muiTheme.others.largeRightPanelWidth, xl: muiTheme.others.extraLargeRightPanelWidth }[
        size
      ],
    );

    const container = useRef<Element | null>(null);

    const value = useMemo<RightPanelDrawerContextProps>(
      () => ({ open, container: container.current, panelWidth, setPanelWidth, size, resizable, isResizing, setIsResizing }),
      [open, panelWidth, size, resizable, isResizing],
    );

    return (
      <RightPanelDrawerContext.Provider value={value}>
        <Stack
          ref={ref}
          direction="row"
          sx={[{ position: 'relative', width: 1 }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
          {...props}
        >
          <Box
            sx={theme => ({
              flexGrow: 1,
              width: open ? `calc(100% - ${panelWidth})` : 1,
              transition: !isResizing ? theme.transitions.create('width') : undefined,
            })}
          >
            {children[0]}
          </Box>
          <Box
            ref={container}
            sx={theme => ({
              width: open ? panelWidth : 0,
              transition: !isResizing ? theme.transitions.create('width') : undefined,
              overflowX: 'clip',
            })}
          >
            {children[1]}
          </Box>
        </Stack>
      </RightPanelDrawerContext.Provider>
    );
  },
);

RightPanelDrawerContainer.defaultProps = {
  size: 'md',
  resizable: false,
};

RightPanelDrawerContainer.displayName = 'RightPanelDrawerContainer';

export default RightPanelDrawerContainer;
