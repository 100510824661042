import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { OperationInfo } from '@dametis/core';

export interface OperationsState {
  list: OperationInfo[];
}

const initialState: OperationsState = {
  list: [],
};

export const operationsSlice = createSlice({
  name: 'operations',
  initialState,
  reducers: {
    setOperations: (state, action: PayloadAction<OperationInfo[]>) => {
      state.list = action.payload;
    },
    newOperation: (state, action: PayloadAction<OperationInfo>) => {
      state.list.push(action.payload);
    },
    clearOperationsStore: () => initialState,
  },
});

export const { setOperations, newOperation, clearOperationsStore } = operationsSlice.actions;

export default operationsSlice.reducer;
