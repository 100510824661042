import { Breadcrumbs, Link, Stack } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getExplorerItemId } from './getExplorerItemId';
import { getExplorerItemName } from './getExplorerItemName';
import { EntitiesByCategory, ExplorerItem } from './types';

export interface ExplorerBreadcrumbsProps {
  path: ExplorerItem[];
  onSelectItem: (newSelectedItem: ExplorerItem) => void;
  entitiesByCategory: EntitiesByCategory;
}

const ExplorerBreadcrumbs: FC<ExplorerBreadcrumbsProps> = ({ path, onSelectItem, entitiesByCategory }) => {
  const { t } = useTranslation('lego');

  const handleClickItem = useCallback(
    (newSelectedItem: ExplorerItem) => () => {
      onSelectItem(newSelectedItem);
    },
    [onSelectItem],
  );

  return (
    <Stack alignItems="center" direction="row" flexShrink={0} height={25} px={3}>
      <Breadcrumbs separator="›">
        {path.map(item => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link key={getExplorerItemId(item)} sx={{ backgroundColor: 'unset', p: 'unset' }} onClick={handleClickItem(item)}>
            {getExplorerItemName(item, entitiesByCategory, t)}
          </Link>
        ))}
      </Breadcrumbs>
    </Stack>
  );
};

export default ExplorerBreadcrumbs;
