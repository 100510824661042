import { ModelKeyField } from '@dametis/core';

export default {
  title: {
    models: 'Modèles',
    yVar: 'Variable expliquée',
    vncEquation: 'Équation',
    xVars: 'Variables explicatives',
    others: 'Autres variables expliquées',
    editModel: 'Modifier le modèle « {{name}} »',
  },
  subtitle: {
    noModels: 'Aucun modèle trouvé',
    noYVar: 'Aucune variable expliquée',
    noXVars: 'Aucune variable explicative',
  },
  button: {
    createModel: 'Modèle',
    duplicateModel: 'Dupliquer',
    deleteModel: 'Supprimer',
    editModel: 'Renommer',
    cancel: 'Annuler',
    save: 'Enregistrer',
  },
  tooltip: {
    duplicateModel: 'Dupliquer ce modèle',
    deleteModel: 'Supprimer ce modèle',
    editModel: 'Modifier ce modèle',
  },
  typography: {
    createdAtBy: 'Créé {{date}} par {{owner}}',
    period: 'Période de référence',
    groupBy: 'Sous-périodes',
    formula: 'Équation',
    r2: 'R²',
    mape: 'MAPE',
    rmse: 'RMSE',
  },
  input: {
    label: {
      name: 'Nom',
    },
  },
  text: {
    modelVariableWithPath: {
      [ModelKeyField.MODEL]: '{{model}} ▸ modèle',
      [ModelKeyField.Y_VAR]: '{{model}} ▸ variable expliquée',
      [ModelKeyField.ABSOLUTE_GAP]: '{{model}} ▸ écart absolu',
      [ModelKeyField.RELATIVE_GAP]: '{{model}} ▸ écart relatif',
      [ModelKeyField.X_VAR]: '{{model}} ▸ variable explicative X{{variable}}',
      modelCalculation: '{{model}} ▸ modèle',
    },
    copy: 'Copie',
  },
};
