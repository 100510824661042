export default {
  title: {
    settings: 'Paramètres',
    columns: 'Colonnes',
  },
  label: {
    field: 'Colonne nº{{index}}',
    gridUseHeader: 'Ce fichier contient un en-tête',
    valuesStart: 'Début des valeurs',
    valuesEnd: 'Fin des valeurs',
    notSelected: 'Non sélectionnée',
  },
  text: {
    lineNumber: 'ligne nº',
  },
  button: {
    clear: 'Vider',
  },
};
