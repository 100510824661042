import { styled } from '@mui/material';

const HeaderPrimaryLeft = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  overflow: 'hidden',
}));

export default HeaderPrimaryLeft;
