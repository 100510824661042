import { Done } from '@mui/icons-material';
import { ListItem, ListItemButton, ListItemText, ListSubheader, Tooltip } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListChildComponentProps, areEqual } from 'react-window';

import { UnitName } from '@dametis/unit';

import { useUnitPickerContext } from './UnitPickerContext';
import { IsListElementItem, IsListElementNewOption, IsListElementSubheader, ListElement } from './types';

const UnitListRow = memo<ListChildComponentProps<ListElement[]>>(({ data, index, style }) => {
  const { t } = useTranslation('unit');

  const { focusIndex, pickerSubmit, value } = useUnitPickerContext();

  const [isEllipsed, setIsEllipsed] = useState<boolean>(false);

  const item: ListElement = useMemo(() => data[index], [data, index]);
  const isValue = useMemo(() => {
    const parsedValue = value?.trim();
    if (
      parsedValue !== undefined &&
      IsListElementItem(item) &&
      ((item.data.unit === UnitName.NO_UNIT && item.data.unitName === parsedValue) || item.data.unitWithMultiple === parsedValue)
    ) {
      return true;
    }
    return false;
  }, [item, value]);

  const secondaryTextRef = useRef<HTMLDivElement | null>(null);

  const handleSelectUnit = useCallback(() => {
    pickerSubmit(item);
  }, [item, pickerSubmit]);

  const onSizeChange = useCallback(() => {
    if (!secondaryTextRef.current) return;
    const { offsetWidth, scrollWidth } = secondaryTextRef.current;
    setIsEllipsed(offsetWidth < scrollWidth);
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(onSizeChange);
    if (secondaryTextRef.current) {
      resizeObserver.observe(secondaryTextRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [onSizeChange]);

  return (
    <>
      {IsListElementSubheader(item) && (
        <ListSubheader disableGutters sx={style}>
          {item.data.unitName}
        </ListSubheader>
      )}
      {(IsListElementItem(item) || IsListElementNewOption(item)) && (
        <Tooltip placement="top" title={isEllipsed && IsListElementItem(item) ? item.data.unitWithMultipleName : ''}>
          <ListItem disablePadding sx={style}>
            <ListItemButton selected={index === focusIndex} onClick={handleSelectUnit}>
              {IsListElementItem(item) && (
                <ListItemText
                  primary={item.data.unitWithMultiple}
                  secondary={item.data.unitWithMultipleName}
                  secondaryTypographyProps={{ noWrap: true, ref: secondaryTextRef }}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                />
              )}
              {IsListElementNewOption(item) && <ListItemText primary={t('text.newOption', { value: item.data.value })} />}
              {isValue && <Done fontSize="small" />}
            </ListItemButton>
          </ListItem>
        </Tooltip>
      )}
    </>
  );
}, areEqual);

UnitListRow.displayName = 'UnitListRow';

export default UnitListRow;
