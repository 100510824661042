import { Done } from '@mui/icons-material';
import { Chip, ChipProps, PaletteColor, chipClasses, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ProjectPriority } from '@dametis/core';

import { setColorLightness } from 'functions/color';

interface Props extends Omit<ChipProps, 'variant' | 'className'> {
  variant: ProjectPriority;
  selected?: boolean;
}

const PriorityChip = styled(({ variant, selected, ...props }: Props) => {
  const { t } = useTranslation('projects');
  if (!variant) return null;
  return (
    <Chip
      color="default"
      icon={selected ? <Done fontSize="small" /> : undefined}
      label={t('priority.variant', { context: variant.toLowerCase() })}
      variant={selected ? 'filled' : 'outlined'}
      {...props}
    />
  );
})<{ variant: ProjectPriority }>(({ variant, theme }) => {
  let priority: PaletteColor | undefined;
  switch (variant) {
    case ProjectPriority.IMPROVEMENT:
      priority = theme.palette.info;
      break;
    case ProjectPriority.NECESSARY:
      priority = theme.palette.warning;
      break;
    case ProjectPriority.CRITICAL:
      priority = theme.palette.error;
      break;
    default:
      priority = undefined;
  }
  return {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 12,
    width: 'fit-content',
    paddingInline: '1px',
    [`& .${chipClasses.icon}`]: {
      fontSize: 18,
      color: priority && priority.dark,
    },
    borderColor: priority && priority.main,
    color: priority && priority.main,
    [`&.${chipClasses.filled}`]: {
      opacity: 1,
      paddingInline: '0px',
      backgroundColor: priority && setColorLightness(priority.main, 95),
      color: priority && priority.dark,
      border: priority && `2px solid ${priority.main}`,
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
});

export default PriorityChip;
