import { cloneDeep } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { AUTO_GROUPBY, AnyGroupBy, CalculationVariable, CommentInfo, IsDataVariable, TadaApiResponse, UUID } from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import DaBarSeries from 'classes/DaCharts/DaBarSeries';
import DaLineSeries from 'classes/DaCharts/DaLineSeries';
import { getFormattedValueUncomposed } from 'components/UI/UnitPicker/functions/getFormattedValue';
import { parseTadaErrors } from 'errors/parseErrors';
import { calculationToString } from 'functions/calculationToString';
import { createCalculationVariable } from 'functions/createCalculationVariable';
import { getCalculationUnit } from 'functions/getCalculationUnit';
import { getGroupBy } from 'functions/tada/getGroupBy';
import { PLAYGROUND_TARGET_POINTS, Tada } from 'functions/tada/tada';
import { sdk } from 'sdk';
import { TypedThunk } from 'store';
import { displaySdkErrorToast, displayTadaErrorToasts, hasTadaErrors } from 'store/actions/toasts';
import { tychartUpdateVariable, updateVariableStats } from 'store/slices/playground';

import { chartColors } from '../../../../functions/color';
import { IPeriodWithDashStyle, ITab, ITyChart, ITyGroup, ITyVariable, ITyVariableProps, IsTyChartTab, marginCoef } from '../../types';
import { createVariable } from '../Variable';
import { getStoreTab } from '../shared';

import { getGreatestDiff } from './TyChart';

// ACTIONS

// TODOTO
export const updateTyVariable =
  (tabUuid: UUID, variableUuid: UUID, options: ITyVariableProps): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTyChartTab(tab)) return;
    const variable = dispatch(getStoreTyVariable(variableUuid, tabUuid));
    dispatch(tychartUpdateVariable({ tabUuid, groupUuid: variable.groupUuid, variableUuid, options }));
    let seriesToUpdate = variable.daSeries.yAxis.series.filter(s => (s as DaLineSeries).uuid === variable.uuid);
    // let seriesBarToUpdate = variable.daSeries.yAxis.series.filter(s => (s as DaBarSeries).uuid === variable.uuid);
    if (!seriesToUpdate.length) seriesToUpdate = [variable.daSeries];
    if (options.type !== undefined) {
      if (options.type === 'line') {
        seriesToUpdate.forEach(s => (s as DaLineSeries).update({ type: 'line' }));
      } else {
        seriesToUpdate.forEach(s => (s as DaBarSeries).update({ type: 'column' }));
      }
    }
    if (options.color) {
      seriesToUpdate.forEach(s => (s as DaLineSeries).setColor(options.color, false));
    }
    if (options.hidden !== undefined) {
      seriesToUpdate.forEach(s => s.setVisible(!options.hidden));
      // variable.daSeries.setVisibility(!options.hidden);
      variable.daSeries.yAxis.update(
        {
          visible: variable.daSeries.chart.series.some(
            s => s.visible === true && s.yAxis.options.id === variable.daSeries.yAxis.options.id,
          ),
        },
        false,
      );
    }
    if (options.unit != null) {
      const apiUnit = getCalculationUnit(variable.expression);
      seriesToUpdate.forEach(s => (s as DaLineSeries).setUnit(options.unit, apiUnit));
      // variable.daSeries.setUnit(options.unit, apiUnit);
    }
    if (options.expression) {
      const singleCalc = getSingleVariableCalculation(options.expression);
      seriesToUpdate.forEach(serie => {
        (serie as DaLineSeries).uuid = singleCalc && IsDataVariable(singleCalc) ? singleCalc.variableUuid : undefined;
      });
      await dispatch(
        updateTyVariable(tab.uuid, variable.uuid, {
          name: options.expression.nickname?.length ? options.expression.nickname : calculationToString(options.expression),
        }),
      );
      const { tabs, selectedTab } = getState().playground;
      const storeTab = tabs.find(t => t.uuid === selectedTab) as ITab<ITyChart>;
      await dispatch(getTyVariableData({ ...variable, expression: options.expression }, storeTab));
    }
    if (options.name) {
      variable.daSeries.setName(options.name);
    }
    if (options.format !== undefined) {
      variable.daSeries.setFormat(options.format);
    }
    if (options.smoothing !== undefined) {
      await dispatch(getTyVariableData(variable, tab));
    }

    variable.daSeries?.chart.redraw();
  };

// OTHERS

export const createTyVariable = ({
  uuid = uuidv4(),
  name = '',
  expression = createCalculationVariable(),
  daSeries = null,
  unit = null,
  apiUnit = null,
  color = chartColors[0],
  hidden = false,
  groupUuid = null,
  dashStyle = undefined,
  format = null,
}: ITyVariableProps = {}): ITyVariable => ({
  ...createVariable({ uuid, name: name || expression.nickname || calculationToString(expression) }),
  expression,
  daSeries,
  unit,
  apiUnit,
  color,
  dashStyle,
  hidden,
  groupUuid,
  format,
});

export const exportTyVariable = (variable: ITyVariable): ITyVariable => {
  if (!variable) return null;
  return { ...variable, daSeries: null };
};

type GetDataResults = TadaApiResponse & { comments: CommentInfo[] };

export const getTyVariableData =
  (
    currentVariable: ITyVariable,
    currentTab: ITab<ITyChart>,
    daLineSeries?: DaLineSeries,
    customPeriod?: IPeriodWithDashStyle,
  ): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const tab = dispatch(getStoreTab(currentTab.uuid));
    const variable = dispatch(getStoreTyVariable(currentVariable.uuid, currentTab.uuid)) ?? currentVariable;
    if (!variable) return;
    const { expression, smoothing } = variable;
    const series = (customPeriod?.xAxis?.series.find(s => (s as DaLineSeries).uuid === variable.uuid) ?? variable.daSeries) as DaLineSeries;
    const daSeries = daLineSeries ?? series;
    const {
      period: {
        present: { period, groupBy: storeGroupBy },
      },
      boxes: { maxLoopTime },
      auth: { selectedSite },
    } = getState();
    let { from, to } = period;
    if (!IsTyChartTab(tab)) return;
    if (customPeriod && tab.customPeriod?.length) {
      if (tab.chart.isBatchChart) {
        const batchFrom = new Date(customPeriod.from);
        const greatestDiff = getGreatestDiff(tab.customPeriod);
        from = new Date(batchFrom.getTime() - greatestDiff * marginCoef);
        to = new Date(batchFrom.getTime() + greatestDiff + greatestDiff * marginCoef);
      } else {
        from = new Date(customPeriod.from);
        to = new Date(customPeriod.to);
      }
    }
    let groupBy: AnyGroupBy = storeGroupBy;
    if (customPeriod) {
      if (variable.type === 'bar' || tab.chart.isBarChart) {
        groupBy = getGroupBy(from, to, null, maxLoopTime, 50);
      } else {
        groupBy = getGroupBy(from, to, null, maxLoopTime, PLAYGROUND_TARGET_POINTS);
      }
    } else if (variable.type === 'bar' || tab.chart.isBarChart) {
      groupBy = tab.chart.groupBy === AUTO_GROUPBY ? getGroupBy(from, to, null, maxLoopTime, 50) : tab.chart.groupBy;
    }
    const {
      variables: { byId },
    } = getState();
    const getData = async (f: Date, t: Date): Promise<GetDataResults> => {
      const calculation: CalculationVariable = {
        ...cloneDeep(expression),
        period: { from: f.toISOString(), to: t.toISOString() },
        stats: true,
      };
      try {
        const { data } = await Tada([calculation], undefined, {
          rawMode: !tab.chart.isBarChart,
          groupBy,
          smoothing,
          fill: 'AUTO',
          targetPoints: daSeries.chart.plotWidth,
        });
        if (hasTadaErrors(data)) {
          console.error(data[0].errors);
          dispatch(displayTadaErrorToasts(parseTadaErrors(data)));
          return null;
        }
        const results: GetDataResults = {
          ...data[0],
          comments: [],
        };
        dispatch(updateVariableStats({ uuid: currentVariable.uuid, statistics: data[0]?.stats ?? null }));
        const computation = getSingleVariableCalculation(expression);
        if (computation && selectedSite) {
          if (IsDataVariable(computation)) {
            try {
              const { data: comments } = await sdk.comment.ListComments(selectedSite.uuid, {
                variableId: computation.variableUuid,
                from: f.toISOString(),
                to: t.toISOString(),
              });
              results.comments = comments;
            } catch (err) {
              console.error(err);
              dispatch(displaySdkErrorToast(err));
            }
          }
        }
        return results;
      } catch (err) {
        console.error(err);
        dispatch(displaySdkErrorToast(err));
        return null;
      }
    };
    const data = await getData(from, to);
    if (data !== null) {
      daSeries.addData(
        DaLineSeries.convertDataFromApiToHighcharts(
          data.results.map(r => {
            if (r.value === null) return r;
            return {
              ...r,
              value: getFormattedValueUncomposed({
                value: r.value,
                baseUnit: variable.apiUnit,
                userUnit: variable.unit,
              }).value,
            };
          }),
        ),
        period,
        groupBy,
        data.comments,
      );
    }
    const firstVar = getSingleVariableCalculation(expression);
    if (variable.unit == null && IsDataVariable(firstVar)) {
      try {
        const { unit } = byId[firstVar.variableUuid];
        await dispatch(updateTyVariable(tab.uuid, variable.uuid, { unit }));
      } catch (err) {
        //
      }
    }
  };

export const getStoreTyVariable =
  (variableUuid: UUID, tabUuid?: UUID): TypedThunk<ITyVariable> =>
  (dispatch, getState) => {
    const { tabs, selectedTab } = getState().playground;
    const tab = tabs.find(t => t.uuid === (tabUuid ?? selectedTab));
    if (!IsTyChartTab(tab)) return null;
    return tab.chart.groups.reduce<ITyVariable>(
      (variable, group) => variable ?? group.variables.find(v => v.uuid === variableUuid) ?? null,
      null,
    );
  };

export const getStoreTyGroup =
  (groupUuid: UUID, tabUuid?: UUID): TypedThunk<ITyGroup> =>
  (dispatch, getState) => {
    const { tabs, selectedTab } = getState().playground;
    const tab = tabs.find(t => t.uuid === (tabUuid ?? selectedTab));
    if (!IsTyChartTab(tab)) return null;
    return tab.chart.groups.find(g => g.uuid === groupUuid) ?? null;
  };
