import { Components } from '@mui/material';
import { MouseEvent } from 'react';

export default {
  defaultProps: {
    // https://github.com/mui-org/material-ui/issues/29060
    PaperProps: { onClick: (e: MouseEvent) => e.stopPropagation() },
    onBackdropClick: e => e.stopPropagation(),
  },
} as Components['MuiDialog'];
