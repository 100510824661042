import { FindFolder, FolderInfo, ShortcutCategory, UUID } from '@dametis/core';

export const getBlockUrl = (rootBlockFolder: FolderInfo | null, blockId: UUID): string | null => {
  if (!rootBlockFolder) {
    return null;
  }
  const parentFolder = FindFolder(
    rootBlockFolder,
    child => child.shortcuts.find(shortcut => shortcut.category === ShortcutCategory.BLOCK && shortcut.uuid === blockId) !== undefined,
  );
  if (!parentFolder) {
    return null;
  }
  return `/lego/${parentFolder.uuid}/${blockId}`;
};
