import { v4 as uuidv4 } from 'uuid';

import { UUID } from '@dametis/core';

import { getFormattedValue } from 'components/UI/UnitPicker/functions/getFormattedValue';
import { availableIcons } from 'config/iconPicker';
import { calculationToString } from 'functions/calculationToString';
import { createCalculationVariable } from 'functions/createCalculationVariable';
import { getCalculationUnit } from 'functions/getCalculationUnit';
import { getContent, getStyledRules } from 'functions/styledRule';
import { TypedThunk } from 'store';
import { selectStyleConfigurations } from 'store/api/styleConfigurations';
import { tableUpdateVariable } from 'store/slices/playground';

import { chartColors } from '../../../../functions/color';
import DamIconComponent from '../../../UI/IconPicker/DamIconComponent';
import TableChartStyledCell from '../../Charts/Table/TableChartStyledCell';
import { ICellType, IMacroColsProps, IMacroColsSettings, ITableVariable, ITableVariableProps, IsTableTab } from '../../types';
import { createVariable } from '../Variable';
import { getStoreTab, getStoreVariable } from '../shared';

import { getTableData, updateMacroConfig, updateTableVariableExp, updateTableVariableUnitAndFormat } from './Table';

// ACTIONS

export const updateTableVariable =
  (tabUuid: UUID, variableUuid: UUID, options: ITableVariableProps): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTableTab(tab)) return;
    const variable = dispatch(getStoreVariable<ITableVariable>(tabUuid, variableUuid));
    dispatch(tableUpdateVariable({ tabUuid, variableUuid, options }));
    if (options.color) {
      // variable.daSeries.setColor(options.color);
    }
    if (options.unit !== undefined || options.format !== undefined) {
      dispatch(updateTableVariableUnitAndFormat(tabUuid, variable.uuid, options.unit, options.format));
    }
    if (options.expression) {
      const { expression } = options;
      const unit = getCalculationUnit(expression) ?? null;
      const name = expression?.nickname?.length ? expression.nickname : calculationToString(expression, true, true);
      await dispatch(updateTableVariable(tabUuid, variable.uuid, { name }));
      await dispatch(updateTableVariableExp(tabUuid, { ...variable, expression: options.expression, unit }));
      await dispatch(getTableData(tabUuid));
    }
    if (options.name) {
      const { apiRef } = tab.chart;
      const currentCol = apiRef.getCurrent().getColumn(variable.uuid);
      apiRef.getCurrent().updateColumns([{ ...currentCol, headerName: options.name }]);
      apiRef.getCurrent().forceUpdate();
    }
    if (options.styledRules) {
      const { apiRef } = tab.chart;
      const col = apiRef.getCurrent().getColumn(variable.uuid);
      apiRef.getCurrent().updateColumns([
        {
          ...col,
          valueFormatter: (value: number | undefined) => {
            const styleConfigurations = selectStyleConfigurations()(getState()).data;
            const styledRules = getStyledRules(options.styledRules, styleConfigurations ?? []);
            const content = getContent(styledRules, value);
            const realValue = Number.isFinite(value)
              ? getFormattedValue({
                  value,
                  baseUnit: getCalculationUnit(variable.expression),
                  userUnit: variable.unit,
                  format: variable.format,
                })
              : '-';

            return content.text?.length > 0 ? content.text : realValue;
          },
          renderCell: ({ value, formattedValue }) => {
            const styleConfigurations = selectStyleConfigurations()(getState()).data;
            const styledRules = getStyledRules(options.styledRules, styleConfigurations ?? []);

            return <TableChartStyledCell styledRules={styledRules} type={ICellType.VAR} unformattedValue={value} value={formattedValue} />;
          },
        },
      ]);
    }
  };

// OTHERS

export const createTableVariable = ({
  uuid = uuidv4(),
  name = '',
  expression = createCalculationVariable(),
  unit = null,
  format = null,
  color = chartColors[0],
  index = undefined,
  styledRules = [],
}: ITableVariableProps = {}): ITableVariable => ({
  ...createVariable({ uuid, name: name || expression.nickname || calculationToString(expression) }),
  expression,
  unit,
  format,
  color,
  index,
  styledRules,
});

export const exportTableVariable = (variable: ITableVariable): ITableVariable => {
  if (!variable) return null;
  return { ...variable };
};

export const updateTableMacroField =
  (tabUuid: UUID, field: IMacroColsSettings, options?: IMacroColsProps): TypedThunk<void> =>
  (dispatch, getState) => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTableTab(tab)) return;
    const { macro, apiRef } = tab.chart;
    let newMacro = { ...macro };
    if (options.label) {
      const newConfig = macro?.config.map(f => (f.name === field.name ? { ...f, label: options.label || field.name } : f));
      newMacro = {
        ...macro,
        config: newConfig,
      };
      const currentCol = apiRef.getCurrent().getColumn(field.name);
      apiRef.getCurrent().updateColumns([{ ...currentCol, headerName: options.label || field.name }]);
    }
    if (options.styledRules) {
      const newConfig = macro?.config.map(f => (f.name === field.name ? { ...f, styledRules: options.styledRules } : f));
      newMacro = {
        ...macro,
        config: newConfig,
      };
      const col = apiRef.getCurrent().getColumn(field.name);
      apiRef.getCurrent().updateColumns([
        {
          ...col,
          renderCell: ({ value }) => {
            const styleConfigurations = selectStyleConfigurations()(getState()).data;
            const styledRules = getStyledRules(options.styledRules, styleConfigurations ?? []);
            const content = getContent(styledRules, value);
            return (
              <div>
                {content?.icon && <DamIconComponent prop={availableIcons[content.icon]?.prop} type={availableIcons[content.icon]?.type} />}
                {content.text?.length > 0 ? content.text : getFormattedValue({ value, baseUnit: field?.unit })}
              </div>
            );
          },
        },
      ]);
    }
    dispatch(updateMacroConfig(tabUuid, newMacro));
  };
