import { ButtonBase, Stack, Typography, alpha, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const B_DBB_H = '56px';
export const S_DBB_H = '32px';
export const S_DBB_W = '64px';
export const S_DBB_PX = '20px';

export const DrawerNavLink = styled(NavLink)({
  textDecoration: 'none',
});

export const DrawerLabel = styled(Typography, {
  shouldForwardProp: propName => propName !== 'isActive',
})<{ isActive?: boolean }>(({ isActive }) => ({
  fontSize: 14,
  fontWeight: isActive ? 700 : 500,
  lineHeight: '20px',
  letterSpacing: 0.5,
  whiteSpace: 'nowrap',
}));

export const DrawerButtonStack = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  height: B_DBB_H,
  color: theme.palette.white,
  overflow: 'visible',
}));

export const DrawerButtonButtonBase = styled(ButtonBase, {
  shouldForwardProp: propName => propName !== 'isExtendedDrawer' && propName !== 'isActive',
})<{ isExtendedDrawer: boolean; isActive: boolean }>(({ theme, isExtendedDrawer, isActive }) => ({
  paddingLeft: isExtendedDrawer ? theme.spacing(2) : S_DBB_PX,
  paddingRight: isExtendedDrawer ? theme.spacing(3) : S_DBB_PX,
  height: isExtendedDrawer ? B_DBB_H : S_DBB_H,
  width: isExtendedDrawer ? '100%' : S_DBB_W,
  borderRadius: 28,
  justifyContent: 'unset',
  textAlign: 'unset',
  flexShrink: 0,
  backgroundColor: isActive ? alpha(theme.palette.white, 0.3) : undefined,
  transition: theme.transitions.create(['height', 'width', 'padding', 'background-color']),
  '.drawerButtonStack:hover &': {
    backgroundColor: isActive ? alpha(theme.palette.white, 0.4) : alpha(theme.palette.white, 0.1),
  },
}));
