import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Format } from '@dametis/core';

import { formatNameFromFormat } from './functions/formatNameFromFormat';

export interface FormatItemProps {
  format: Format;
}

const FormatItem: FC<FormatItemProps> = ({ format }) => {
  const { t } = useTranslation('format');

  return (
    <Stack spacing={0.75}>
      <Stack>
        <Typography fontSize={10} lineHeight="11px" variant="overline">
          {format.dateSystem && t(`dateSystem.${format.dateSystem}`)}
          {format.hideUnit && ` / ${t('text.hideUnit')}`}
        </Typography>
        <Typography fontSize={10} lineHeight="11px" variant="overline">
          {format.withTimezoneOffset && t('text.timezoneOffset')}
        </Typography>
      </Stack>
      <Typography lineHeight="1">{formatNameFromFormat(format, t)}</Typography>
    </Stack>
  );
};

export default FormatItem;
