import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { ThresholdDirection } from '../types';

export interface ThresholdContextState {
  direction: `${ThresholdDirection}`;
  value: number;
  color: string;
}

export const initialState = {
  direction: undefined,
  value: undefined,
  color: undefined,
};

export const ThresholdContext = createContext<ThresholdContextState>(initialState);

type ThresholdProviderProps = ThresholdContextState;

const ThresholdProvider: FC<PropsWithChildren<ThresholdProviderProps>> = ({ direction, value, color, children = undefined }) => {
  const contextValues: ThresholdContextState = useMemo(() => ({ direction, value, color }), [direction, value, color]);

  return <ThresholdContext.Provider value={contextValues}>{children}</ThresholdContext.Provider>;
};

export const useThresholdContext = () => {
  const context = useContext(ThresholdContext);

  if (!context) {
    throw Error('useThresholdContext must be used inside a ThresholdProvider');
  }

  return context;
};

export default ThresholdProvider;
