import { Breadcrumbs, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FolderInfo } from '@dametis/core';

import CustomCollapsedIcon, { CustomCollapsedIconProps } from './CustomCollapsedIcon';

export interface SuggestionBreadcrumbsProps {
  path: FolderInfo[];
}

const SuggestionBreadcrumbs: FC<SuggestionBreadcrumbsProps> = ({ path }) => {
  const { t } = useTranslation('lego');

  const collapsedIconPath = useMemo(() => path.map(folder => folder.name), [path]);

  return (
    <Breadcrumbs
      maxItems={2}
      separator="›"
      slotProps={{ collapsedIcon: { path: collapsedIconPath } as CustomCollapsedIconProps }}
      slots={{ CollapsedIcon: CustomCollapsedIcon }}
    >
      {path.map((folder, index) => (
        <Typography key={folder.uuid} variant="caption">
          {index === 0 ? t('text.factory') : folder.name}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};

export default SuggestionBreadcrumbs;
