import { Components } from '@mui/material';

export default {
  defaultProps: {
    shrink: true,
  },
  styleOverrides: {
    root: {
      transform: 'none !important',
      whiteSpace: 'nowrap',
    },
  },
} as Components['MuiInputLabel'];
