import type { SeriesLineOptions } from 'highcharts';

import { Format } from '@dametis/core';

export interface LineSeriesCustomOptions {
  baseUnit: string | null;
  userUnit: string | null;
  originalColor: SeriesLineOptions['color'];
  format?: Format;
}

export interface RangeSeriesCustomOptions {
  baseUnit: string | null;
  userUnit: string | null;
}

export interface BarSeriesCustomOptions {
  baseUnit: string | null;
  userUnit: string | null;
}

export interface GaugeSeriesCustomOptions {
  baseUnit: string | null;
  userUnit: string | null;
}

export interface ScatterSeriesCustomOptions {
  baseUnit: string | null;
  userUnit: string | null;
}

// export interface PieSeriesCustomOptions {}

export interface SankeySeriesCustomOptions {
  displayedValue: string | null;
}

export interface SankeySeriesDataItem {
  from: string;
  to: string;
  weight: number;
  custom?: SankeySeriesCustomOptions;
}

export type SankeySeriesData = SankeySeriesDataItem[];

export enum ThresholdDirection {
  UP = 'up',
  DOWN = 'down',
}

export interface NetworkgraphSeriesDataItem {
  from: string;
  to: string;
}

export type NetworkgraphSeriesData = NetworkgraphSeriesDataItem[];
