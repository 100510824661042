import { GridLocaleText } from '@mui/x-data-grid-premium';

export default {
  title: {
    macros: 'Macros',
  },
  text: {
    noMacros: 'No macro',
    noMacroLibs: 'No library',
  },
  button: {
    addMacro: 'Macro',
    addMacroLib: 'Library',
    resetCaches: 'Reset caches',
  },
  table: {
    cells: {
      name: 'Name',
      description: 'Description',
      status: 'Status',
    },
  },
  tabs: {
    tableMacroList: 'Macros',
    tableMacroLibList: 'Libraries',
  },
  xgrid: {
    noMacrosLabel: 'No macro',
    footerTotalMacros: 'Total macros:',
    noMacroLibsLabel: 'No library',
    footerTotalMacroLibs: 'Total libraries:',
  } as Partial<GridLocaleText>,
  tooltip: {
    resetCaches: 'reset caches',
    createMacro: 'create a macro',
    createLibrary: 'create a library',
  },
};
