import { CreateProjectBody, ProjectPriority, ProjectType } from '@dametis/core';

export interface CreateProjectBodyForm extends Omit<CreateProjectBody, 'deadline'> {
  deadline: Date | null;
}

export const createCreateProjectBodyForm = ({
  name = '',
  type = ProjectType.GLOBALPROJECT,
  priority = ProjectPriority.IMPROVEMENT,
  deadline = null,
  currency = '€',
  tagIds = [],
}: Partial<CreateProjectBodyForm> = {}): CreateProjectBodyForm => ({
  name,
  type,
  priority,
  deadline,
  currency,
  tagIds,
});

export const IsCreateProjectBodyFormValid = (body: CreateProjectBodyForm): body is CreateProjectBody => body.deadline !== null;
