import { ArrowRight, SvgIconComponent } from '@mui/icons-material';
import {
  ListItemButtonProps,
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  ListItemTextProps,
  MenuItem,
  SvgIcon,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { forwardRef } from 'react';

const listItemIconPropsDefaultProp: ListItemIconProps = {};
const listItemTextPropsDefaultProp: ListItemTextProps = {};
const tooltipPropsDefaultProp: Omit<TooltipProps, 'title' | 'children'> = {};

/**
 * extends ListItemButtonProps for easy to use disabled and onClick
 */
export interface CalculationMenuItemProps extends ListItemButtonProps {
  label: string;
  icon?: SvgIconComponent;
  arrow?: boolean;
  listItemIconProps?: ListItemIconProps;
  listItemTextProps?: ListItemTextProps;
  tooltip?: string;
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
}

const CalculationMenuItem = forwardRef<HTMLLIElement, CalculationMenuItemProps>(
  (
    {
      label,
      icon = undefined,
      arrow = false,
      listItemIconProps = listItemIconPropsDefaultProp,
      listItemTextProps = listItemTextPropsDefaultProp,
      tooltip = undefined,
      tooltipProps = tooltipPropsDefaultProp,
      ...props
    },
    ref,
  ) => (
    <Tooltip title={tooltip} {...tooltipProps}>
      <MenuItem ref={ref} component="a" {...props}>
        {icon && (
          <ListItemIcon sx={{ mr: 2, minWidth: 'unset', ...listItemIconProps.sx }} {...listItemIconProps}>
            <SvgIcon component={icon} fontSize="small" />
          </ListItemIcon>
        )}
        <ListItemText primary={label} {...listItemTextProps} />
        {arrow && <ArrowRight fontSize="small" />}
      </MenuItem>
    </Tooltip>
  ),
);

CalculationMenuItem.defaultProps = {
  icon: undefined,
  arrow: false,
  listItemIconProps: {},
  listItemTextProps: {},
  tooltip: undefined,
  tooltipProps: {},
};

CalculationMenuItem.displayName = 'CalculationMenuItem';

export default CalculationMenuItem;
