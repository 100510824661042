import { Shortcut } from '@mui/icons-material';
import { TabContext, TabList, TabListProps, TabPanel, tabPanelClasses } from '@mui/lab';
import { Box, Divider, Stack, Tab, Typography } from '@mui/material';
import { useLiveQuery } from 'dexie-react-hooks';
import Fuse from 'fuse.js';
import { Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IsShortCorporateAlias, ModelInfo, ShortAliasInfo, ShortBlockInfo, ShortCorporateAliasInfo } from '@dametis/core';

import { PropsContext } from 'components/VNC/context';
import { ListTab } from 'components/VNC/types';
import { usePermission } from 'hooks/usePermission';
import { useSelector } from 'store';
import { useAliases } from 'store/api/aliases';
import { useBlocks } from 'store/api/blocks';
import { useModels } from 'store/api/models';
import { useVncStore } from 'zustand/stores/vnc';

import Counter from '../../../UI/Counter/Counter';

import BlockDiagramList from './BlockDiagramList/BlockDiagramList';
import HistoryAndAliasesList from './HistoryAndAliasesList/HistoryAndAliasesList';
import { db } from './HistoryAndAliasesList/db';
import ModelsList from './ModelsList/ModelsList';
import VariablesList from './VariablesList/VariablesList';

const modelsEmptyArray: ModelInfo[] = [];
const blocksEmptyArray: ShortBlockInfo[] = [];
const emptyArray: ShortAliasInfo[] | ShortCorporateAliasInfo[] = [];

export interface ListProps {
  activeListTab: ListTab;
  setActiveListTab: Dispatch<SetStateAction<ListTab>>;
}

const List: FC<ListProps> = ({ activeListTab, setActiveListTab }) => {
  const { t } = useTranslation('vnc');

  const { listTab, disableLego, disableModels, multiple, disableMaths, covarianceVariable } = useContext(PropsContext);

  const canLego = usePermission('canAccessLego');

  const group = useSelector(state => state.auth.selectedGroup!.uuid);
  const site = useSelector(state => state.auth.selectedSite?.uuid ?? 'null');
  const storeVariables = useSelector(state => state.variables.byId);
  const corporate = useSelector(state => !state.auth.selectedSite);
  const covVarName = useSelector(state => state.variables.byId[covarianceVariable?.variableUuid ?? '']?.name ?? '');

  const { data: blocks = blocksEmptyArray } = useBlocks();
  const { data: models = modelsEmptyArray } = useModels();
  const { data: aliases = emptyArray } = useAliases();

  const storeVariablesLength = useMemo(() => Object.values(storeVariables).length, [storeVariables]);

  const variablesLength = useVncStore(state =>
    state.search.length || Object.values(state.filters).some(filter => Object.values(filter).some(bool => bool))
      ? state.results.variables.length
      : storeVariablesLength,
  );

  const search = useVncStore(state => state.search);
  const filters = useVncStore(state => state.filters);

  const historyLength = useLiveQuery(() => db.history.where({ group, site }).count(), [group, site]) ?? 0;

  const searched = useMemo<ShortAliasInfo[] | ShortCorporateAliasInfo[]>(() => {
    if (!search) {
      return aliases;
    }
    const fuse = new Fuse(aliases, {
      keys: ['name'],
      threshold: 0.3,
      ignoreLocation: true,
      useExtendedSearch: true,
    });
    return fuse.search(search).map(({ item }) => item);
  }, [aliases, search]);

  const filteredAliases: (ShortAliasInfo | ShortCorporateAliasInfo)[] = useMemo(() => {
    const filteredData = searched.filter(alias => {
      if (filters.isCorporate || !Object.values(filters.sites).some(siteId => siteId)) {
        return true;
      }
      if (IsShortCorporateAlias(alias)) {
        return filters.sites[alias.siteId];
      }
      return true;
    });
    return filteredData;
  }, [filters.isCorporate, filters.sites, searched]);

  const changeTab = useCallback<NonNullable<TabListProps['onChange']>>(
    (e, value) => {
      setActiveListTab(value);
    },
    [setActiveListTab],
  );

  useEffect(() => {
    setActiveListTab(listTab === ListTab.BLOCKS && (disableLego || corporate) ? ListTab.VARIABLES : listTab);
  }, [listTab, disableLego, corporate, setActiveListTab]);

  return (
    <Stack sx={{ flexGrow: 1, overflow: 'hidden' }}>
      <TabContext value={activeListTab}>
        <Stack alignItems="center" direction="row" spacing={2} sx={{ pr: 2 }}>
          <TabList sx={{ flexShrink: 0 }} onChange={changeTab}>
            {!disableMaths && (
              <Tab
                label={
                  <span>
                    {t('tab.history')} <Counter count={historyLength + filteredAliases.length} limit={50} />
                  </span>
                }
                value={ListTab.HISTORY}
              />
            )}
            <Tab
              label={
                <span>
                  {t('tab.variables')} <Counter count={variablesLength} limit={99} />
                </span>
              }
              value={ListTab.VARIABLES}
            />
            {canLego && !disableLego && !corporate && (
              <Tab
                label={
                  <span>
                    {t('tab.lego')} <Counter count={blocks.length} />
                  </span>
                }
                value={ListTab.BLOCKS}
              />
            )}
            {!disableModels && !corporate && (
              <Tab
                label={
                  <span>
                    {t('tab.models')} <Counter count={models.length} />
                  </span>
                }
                value={ListTab.MODELS}
              />
            )}
          </TabList>
          {activeListTab === ListTab.VARIABLES && (
            <>
              <Box sx={{ py: 0.5, height: 1 }}>
                <Divider orientation="vertical" />
              </Box>
              <Typography noWrap>
                {/* eslint-disable-next-line no-nested-ternary */}
                {search.length
                  ? t('subtitle.resultsFor', { search })
                  : covVarName.length
                    ? t('subtitle.allInfluentVariables', { name: covVarName })
                    : t('subtitle.allVariablesByUpdateDate')}
              </Typography>
            </>
          )}
          {activeListTab === ListTab.HISTORY && Boolean(search.length) && (
            <>
              <Box sx={{ py: 0.5, height: 1 }}>
                <Divider orientation="vertical" />
              </Box>
              <Typography noWrap>{t('subtitle.resultsFor', { search })}</Typography>
            </>
          )}
          {multiple && activeListTab !== ListTab.HISTORY && (
            <Stack alignItems="center" direction="row" sx={{ ml: 'auto !important', pl: 2 }}>
              <Typography noWrap variant="subtitle2">
                {t('subtitle.multipleSelection')}
              </Typography>
              <Shortcut
                sx={{
                  color: 'text.secondary',
                  transform: 'rotate(90deg)',
                  mx: '2px',
                  mt: '18px',
                }}
              />
            </Stack>
          )}
        </Stack>
        <Box sx={{ overflow: 'hidden', flexGrow: 1, px: 0.5, pt: 0.5, [`& .${tabPanelClasses.root}`]: { p: 0, height: '100%' } }}>
          <TabPanel value={ListTab.HISTORY}>
            <HistoryAndAliasesList filteredAliases={filteredAliases} />
          </TabPanel>
          <TabPanel value={ListTab.VARIABLES}>
            <VariablesList />
          </TabPanel>
          <TabPanel value={ListTab.BLOCKS}>
            <BlockDiagramList />
          </TabPanel>
          <TabPanel value={ListTab.MODELS}>
            <ModelsList />
          </TabPanel>
        </Box>
      </TabContext>
    </Stack>
  );
};

export default List;
