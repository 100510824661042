import { differenceInSeconds } from 'date-fns';

import {
  AUTO_GROUPBY,
  AnyGroupBy,
  CalculationVariable,
  Operator,
  POINT_TO_POINT_GROUPBY,
  isAllowedPointToPointOperator,
  timeGroupBy,
} from '@dametis/core';

export const getRawGroupBy = (
  dateFrom: Date,
  dateTo: Date,
  computation: CalculationVariable | null,
  maxGroupBy: number,
  targetPoints: number,
): number => Math.max(Math.floor(differenceInSeconds(dateTo, dateFrom) / targetPoints), maxGroupBy / 1000);

export const getPrettyGroupBy = (rawGroupBy: number): timeGroupBy => {
  if (rawGroupBy < 60) return `${rawGroupBy - (rawGroupBy % 5)}s`;
  if (rawGroupBy < 600) return `${(rawGroupBy - (rawGroupBy % 60)) / 60}m`;
  if (rawGroupBy < 3600) return `${(rawGroupBy - (rawGroupBy % 300)) / 60}m`;
  if (rawGroupBy < 86400) return `${(rawGroupBy - (rawGroupBy % 3600)) / 60 / 60}h`;
  if (rawGroupBy < 604800) return `${(rawGroupBy - (rawGroupBy % 86400)) / 86400}d`;
  if (rawGroupBy < 4233600) return `${(rawGroupBy - (rawGroupBy % 604800)) / 604800}w`;
  return `${Math.floor(rawGroupBy)}s`;
};

export const getGroupBy = (
  dateFrom: Date,
  dateTo: Date,
  computation: CalculationVariable | null,
  maxGroupBy: number,
  targetPoints: number,
): timeGroupBy => {
  const rawGroupBy = getRawGroupBy(dateFrom, dateTo, computation, maxGroupBy, targetPoints);
  return getPrettyGroupBy(rawGroupBy);
};

export const getMinGroupBy = (dateFrom: Date, dateTo: Date): timeGroupBy => {
  const groupByS = Math.floor((differenceInSeconds(dateTo, dateFrom) / 5 / 5000) * 5);
  if (groupByS < 60) return `${groupByS - (groupByS % 5) + 5}s`;
  if (groupByS < 600) return `${(groupByS - (groupByS % 60) + 60) / 60}m`;
  if (groupByS < 3600) return `${(groupByS - (groupByS % 300) + 300) / 60}m`;
  if (groupByS < 86400) return `${(groupByS - (groupByS % 3600) + 3600) / 60 / 60}h`;
  if (groupByS < 604800) return `${(groupByS - (groupByS % 86400)) / 86400}d`;
  if (groupByS < 4233600) return `${(groupByS - (groupByS % 604800)) / 604800}w`;
  return `${Math.floor((differenceInSeconds(dateTo, dateFrom) / 5 / 10000) * 5)}s`;
};

export const getDefaultGroupByForOperator = (
  operator: Operator | undefined,
  { oldGroupBy, isCalculatedVariable }: { oldGroupBy?: AnyGroupBy; isCalculatedVariable?: boolean } = {},
): AnyGroupBy | undefined => {
  if (isCalculatedVariable) {
    if (operator === undefined || operator === Operator.HISTORY) {
      return undefined;
    }
    if (isAllowedPointToPointOperator(operator)) {
      return POINT_TO_POINT_GROUPBY;
    }
    if (oldGroupBy && oldGroupBy !== POINT_TO_POINT_GROUPBY) {
      return oldGroupBy;
    }
    return '1h';
  }
  if (!operator || operator === Operator.HISTORY) {
    return undefined;
  }
  if (oldGroupBy) {
    return oldGroupBy;
  }
  return AUTO_GROUPBY;
};
