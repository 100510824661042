import { DialogContent, Stack } from '@mui/material';
import { FC, useContext, useState } from 'react';

import { useSelector } from 'store';
import { useVncStore } from 'zustand/stores/vnc';

import { PropsContext } from '../context';
import { ListTab } from '../types';

import Filters from './Filters/Filters';
import List from './List/List';
import Selection from './Tools/Selection';
import { StyledDrawer } from './styled';

const Content: FC = () => {
  const someSelected = useVncStore(state => Boolean(state.selection.length));

  const corporate = useSelector(state => !state.auth.selectedSite);

  const { listTab, disableLego } = useContext(PropsContext);

  const [activeListTab, setActiveListTab] = useState<ListTab>(
    listTab === ListTab.BLOCKS && (disableLego || corporate) ? ListTab.VARIABLES : listTab,
  );

  return (
    <DialogContent sx={{ p: 0 }}>
      <Stack direction="row" sx={{ height: 1 }}>
        <Filters activeListTab={activeListTab} />
        <List activeListTab={activeListTab} setActiveListTab={setActiveListTab} />
        {someSelected && (
          <StyledDrawer anchor="right">
            <Selection />
          </StyledDrawer>
        )}
      </Stack>
    </DialogContent>
  );
};

export default Content;
