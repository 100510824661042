import {
  AcUnitOutlined,
  AccountTreeOutlined,
  AirOutlined,
  AnalyticsOutlined,
  Balance,
  BatteryFull,
  Block,
  BoltOutlined,
  CalendarTodayOutlined,
  Dangerous,
  DashboardOutlined,
  DataThresholdingOutlined,
  DirectionsBusOutlined,
  DirectionsOutlined,
  Done,
  EnergySavingsLeafOutlined,
  EuroOutlined,
  Expand,
  FactoryOutlined,
  Filter1,
  Filter2,
  Filter3,
  Filter4,
  Filter5,
  Filter6,
  Filter7,
  Filter8,
  Filter9,
  Gradient,
  Help,
  Info,
  KeyboardReturn,
  LightbulbOutlined,
  NotificationsOutlined,
  PowerOutlined,
  PowerSettingsNewOutlined,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  ThermostatOutlined,
  Warning,
  WbSunnyOutlined,
  WindPowerOutlined,
} from '@mui/icons-material';

import { DeployedCodeOutlined } from 'assets/icons/DeployedCodeOutlined';
import { FireOutlined } from 'assets/icons/FireOutlined';
import { PlaygroundOutlined } from 'assets/icons/PlaygroundOutlined';
import { WaterOutlined } from 'assets/icons/WaterOutlined';

import { DamIcon, DamIconName, DamIconType } from '../types/iconPicker';

export const availableIcons: Record<DamIconName, DamIcon> = {
  [DamIconName.EMPTY]: {
    name: DamIconName.EMPTY,
    type: DamIconType.MUI,
    prop: Block,
  },
  [DamIconName.DANGER]: {
    name: DamIconName.DANGER,
    type: DamIconType.MUI,
    prop: Dangerous,
  },
  [DamIconName.WARNING]: {
    name: DamIconName.WARNING,
    type: DamIconType.MUI,
    prop: Warning,
  },
  [DamIconName.DONE]: {
    name: DamIconName.DONE,
    type: DamIconType.MUI,
    prop: Done,
  },
  [DamIconName.HELP]: {
    name: DamIconName.HELP,
    type: DamIconType.MUI,
    prop: Help,
  },
  [DamIconName.INFO]: {
    name: DamIconName.INFO,
    type: DamIconType.MUI,
    prop: Info,
  },
  [DamIconName.SATISFIED]: {
    name: DamIconName.SATISFIED,
    type: DamIconType.MUI,
    prop: SentimentSatisfiedAlt,
  },
  [DamIconName.UNSATISFIED]: {
    name: DamIconName.UNSATISFIED,
    type: DamIconType.MUI,
    prop: SentimentVeryDissatisfied,
  },
  [DamIconName.BOLT]: {
    name: DamIconName.BOLT,
    type: DamIconType.MUI,
    prop: BoltOutlined,
  },
  [DamIconName.LIGHTBULB]: {
    name: DamIconName.LIGHTBULB,
    type: DamIconType.MUI,
    prop: LightbulbOutlined,
  },
  [DamIconName.INDUSTRY]: {
    name: DamIconName.INDUSTRY,
    type: DamIconType.MUI,
    prop: FactoryOutlined,
  },
  [DamIconName.PLUG]: {
    name: DamIconName.PLUG,
    type: DamIconType.MUI,
    prop: PowerOutlined,
  },
  [DamIconName.POWEROFF]: {
    name: DamIconName.POWEROFF,
    type: DamIconType.MUI,
    prop: PowerSettingsNewOutlined,
  },
  [DamIconName.TINT]: {
    name: DamIconName.TINT,
    type: DamIconType.MUI,
    prop: WaterOutlined,
  },
  [DamIconName.FIRE]: {
    name: DamIconName.FIRE,
    type: DamIconType.MUI,
    prop: FireOutlined,
  },
  [DamIconName.LEAF]: {
    name: DamIconName.LEAF,
    type: DamIconType.MUI,
    prop: EnergySavingsLeafOutlined,
  },
  [DamIconName.SUN]: {
    name: DamIconName.SUN,
    type: DamIconType.MUI,
    prop: WbSunnyOutlined,
  },
  [DamIconName.WIND]: {
    name: DamIconName.WIND,
    type: DamIconType.MUI,
    prop: AirOutlined,
  },
  [DamIconName.FAN]: {
    name: DamIconName.FAN,
    type: DamIconType.MUI,
    prop: WindPowerOutlined,
  },
  [DamIconName.THERMOMETER]: {
    name: DamIconName.THERMOMETER,
    type: DamIconType.MUI,
    prop: ThermostatOutlined,
  },
  [DamIconName.SNOWFLAKE]: {
    name: DamIconName.SNOWFLAKE,
    type: DamIconType.MUI,
    prop: AcUnitOutlined,
  },
  [DamIconName.EURO]: {
    name: DamIconName.EURO,
    type: DamIconType.MUI,
    prop: EuroOutlined,
  },
  [DamIconName.BELL]: {
    name: DamIconName.BELL,
    type: DamIconType.MUI,
    prop: NotificationsOutlined,
  },
  [DamIconName.PLAYGROUNDS]: {
    name: DamIconName.PLAYGROUNDS,
    type: DamIconType.MUI,
    prop: PlaygroundOutlined,
  },
  [DamIconName.REPORTS]: {
    name: DamIconName.REPORTS,
    type: DamIconType.MUI,
    prop: DashboardOutlined,
  },
  [DamIconName.SYNOPTICS]: {
    name: DamIconName.SYNOPTICS,
    type: DamIconType.MUI,
    prop: AccountTreeOutlined,
  },
  [DamIconName.CALENDAR]: {
    name: DamIconName.CALENDAR,
    type: DamIconType.MUI,
    prop: CalendarTodayOutlined,
  },
  [DamIconName.DIRECTIONS]: {
    name: DamIconName.DIRECTIONS,
    type: DamIconType.MUI,
    prop: DirectionsOutlined,
  },
  [DamIconName.BALANCE]: {
    name: DamIconName.BALANCE,
    type: DamIconType.MUI,
    prop: Balance,
  },
  [DamIconName.DIRECTIONSBUS]: {
    name: DamIconName.DIRECTIONSBUS,
    type: DamIconType.MUI,
    prop: DirectionsBusOutlined,
  },
  [DamIconName.ANALYTICS]: {
    name: DamIconName.ANALYTICS,
    type: DamIconType.MUI,
    prop: AnalyticsOutlined,
  },
  [DamIconName.BATTERY]: {
    name: DamIconName.BATTERY,
    type: DamIconType.MUI,
    prop: BatteryFull,
  },
  [DamIconName.DATATHRESHOLD]: {
    name: DamIconName.DATATHRESHOLD,
    type: DamIconType.MUI,
    prop: DataThresholdingOutlined,
  },
  [DamIconName.EXPAND]: {
    name: DamIconName.EXPAND,
    type: DamIconType.MUI,
    prop: Expand,
  },
  [DamIconName.GRADIENT]: {
    name: DamIconName.GRADIENT,
    type: DamIconType.MUI,
    prop: Gradient,
  },
  [DamIconName.BLOCKTYPE]: {
    name: DamIconName.BLOCKTYPE,
    type: DamIconType.MUI,
    prop: DeployedCodeOutlined,
  },
  [DamIconName.RETURN]: {
    name: DamIconName.RETURN,
    type: DamIconType.MUI,
    prop: KeyboardReturn,
  },
  [DamIconName.FILTER1]: {
    name: DamIconName.FILTER1,
    type: DamIconType.MUI,
    prop: Filter1,
  },
  [DamIconName.FILTER2]: {
    name: DamIconName.FILTER2,
    type: DamIconType.MUI,
    prop: Filter2,
  },
  [DamIconName.FILTER3]: {
    name: DamIconName.FILTER3,
    type: DamIconType.MUI,
    prop: Filter3,
  },
  [DamIconName.FILTER4]: {
    name: DamIconName.FILTER4,
    type: DamIconType.MUI,
    prop: Filter4,
  },
  [DamIconName.FILTER5]: {
    name: DamIconName.FILTER5,
    type: DamIconType.MUI,
    prop: Filter5,
  },
  [DamIconName.FILTER6]: {
    name: DamIconName.FILTER6,
    type: DamIconType.MUI,
    prop: Filter6,
  },
  [DamIconName.FILTER7]: {
    name: DamIconName.FILTER7,
    type: DamIconType.MUI,
    prop: Filter7,
  },
  [DamIconName.FILTER8]: {
    name: DamIconName.FILTER8,
    type: DamIconType.MUI,
    prop: Filter8,
  },
  [DamIconName.FILTER9]: {
    name: DamIconName.FILTER9,
    type: DamIconType.MUI,
    prop: Filter9,
  },
};

export const defaultIconSet: DamIcon[] = [
  availableIcons[DamIconName.DANGER],
  availableIcons[DamIconName.WARNING],
  availableIcons[DamIconName.DONE],
  availableIcons[DamIconName.HELP],
  availableIcons[DamIconName.INFO],
  availableIcons[DamIconName.SATISFIED],
  availableIcons[DamIconName.UNSATISFIED],
  availableIcons[DamIconName.BOLT],
  availableIcons[DamIconName.LIGHTBULB],
  availableIcons[DamIconName.INDUSTRY],
  availableIcons[DamIconName.PLUG],
  availableIcons[DamIconName.POWEROFF],
  availableIcons[DamIconName.TINT],
  availableIcons[DamIconName.FIRE],
  availableIcons[DamIconName.LEAF],
  availableIcons[DamIconName.SUN],
  availableIcons[DamIconName.WIND],
  availableIcons[DamIconName.FAN],
  availableIcons[DamIconName.THERMOMETER],
  availableIcons[DamIconName.SNOWFLAKE],
  availableIcons[DamIconName.EURO],
  availableIcons[DamIconName.BELL],
  availableIcons[DamIconName.CALENDAR],
  availableIcons[DamIconName.DIRECTIONS],
  availableIcons[DamIconName.REPORTS],
  availableIcons[DamIconName.ANALYTICS],
  availableIcons[DamIconName.BATTERY],
  availableIcons[DamIconName.DATATHRESHOLD],
  availableIcons[DamIconName.EXPAND],
  availableIcons[DamIconName.GRADIENT],
  availableIcons[DamIconName.BLOCKTYPE],
  availableIcons[DamIconName.RETURN],
  availableIcons[DamIconName.FILTER1],
  availableIcons[DamIconName.FILTER2],
  availableIcons[DamIconName.FILTER3],
  availableIcons[DamIconName.FILTER4],
  availableIcons[DamIconName.FILTER5],
  availableIcons[DamIconName.FILTER6],
  availableIcons[DamIconName.FILTER7],
  availableIcons[DamIconName.FILTER8],
  availableIcons[DamIconName.FILTER9],
];

export const emptyIcon: DamIcon = availableIcons[DamIconName.EMPTY];
