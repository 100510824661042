import { ListItemButton, styled, svgIconClasses } from '@mui/material';

export const ListItemButtonWithArrow = styled(ListItemButton)(({ theme, selected }) => ({
  [`& > .${svgIconClasses.root}`]: {
    opacity: selected ? 1 : 0,
    transition: theme.transitions.create(['opacity']),
  },
  [`&:hover > .${svgIconClasses.root}`]: {
    opacity: 1,
  },
}));
