import { Components, Theme, checkboxClasses } from '@mui/material';
import switchBaseClasses from '@mui/material/internal/switchBaseClasses';

import { LIST_PADDING } from './MuiList';

export default {
  styleOverrides: {
    root: {
      [`& .${checkboxClasses.root}.${switchBaseClasses.edgeStart}`]: {
        marginTop: -9,
        marginBottom: -9,
        marginLeft: -9,
      },
    },
    dense: {
      paddingTop: LIST_PADDING / 2,
      paddingBottom: LIST_PADDING / 2,
    },
    /**
     * @deprecated
     */
    button: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      paddingLeft: 8,
      paddingRight: 8,
      marginTop: LIST_PADDING,
      marginBottom: LIST_PADDING,
    }),
    gutters: {
      '&$button': {
        marginLeft: LIST_PADDING,
        marginRight: LIST_PADDING,
        width: `calc(100% - 2 * ${LIST_PADDING}px)`,
      },
    },
  },
} as Components<Theme>['MuiListItem'];
