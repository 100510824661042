import { Alert, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view';
import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BlockTypeInfo, FlattenFolderTree, FolderInfo, ShortStandardBlockInfo, ShortcutCategory, UUID } from '@dametis/core';

import getEntitiesById from 'functions/getEntitiesById';
import { useReadBlockTypesQuery } from 'store/api/blockTypes';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';
import { useStandardBlocks } from 'store/api/standardBlocks';

import { generateTree } from './generateTree';

const blockTypesEmptyArray: BlockTypeInfo[] = [];
const standardBlocksEmptyArray: ShortStandardBlockInfo[] = [];

export interface TreeNavigationProps {
  currentPath: FolderInfo[];
}

const TreeNavigation: FC<TreeNavigationProps> = ({ currentPath }) => {
  const { t } = useTranslation('lego');
  const navigate = useNavigate();

  const { folderId } = useParams<{ folderId?: UUID }>();

  const { data: blockTypes = blockTypesEmptyArray } = useReadBlockTypesQuery();
  const { data: standardBlocks = standardBlocksEmptyArray } = useStandardBlocks();

  const {
    data: globalRootFolder = null,
    isLoading: isGlobalRootFolderLoading,
    isUninitialized: isGlobalRootFolderUninitialized,
  } = useReadGlobalRootFolderQuery();

  const blockTypesById = useMemo(() => getEntitiesById<BlockTypeInfo>(blockTypes), [blockTypes]);
  const standardBlocksById = useMemo(() => getEntitiesById<ShortStandardBlockInfo>(standardBlocks), [standardBlocks]);
  const selectedNodeId = useMemo(() => folderId ?? null, [folderId]);
  const expandedNodeIds = useMemo(() => currentPath.map(folder => folder.uuid), [currentPath]);

  const isGlobalRootFolderFetched = useMemo(
    () => !isGlobalRootFolderLoading && !isGlobalRootFolderUninitialized,
    [isGlobalRootFolderLoading, isGlobalRootFolderUninitialized],
  );

  const allFolders = useMemo(() => (globalRootFolder ? FlattenFolderTree(globalRootFolder) : []), [globalRootFolder]);

  const handleSelectNode = useCallback(
    (_event: SyntheticEvent<Element, Event>, nodeId: string | null) => {
      if (nodeId && blockTypesById[nodeId]) {
        const parentFolder = allFolders.find(folder =>
          folder.shortcuts.find(shortcut => shortcut.category === ShortcutCategory.BLOCK_TYPE && shortcut.uuid === nodeId),
        );
        if (parentFolder) {
          navigate(`/blockTypes/${parentFolder.uuid}/${nodeId}`);
        }
      } else {
        navigate(`/blockTypes/${nodeId}`);
      }
    },
    [navigate, allFolders, blockTypesById],
  );

  return (
    <>
      <Divider sx={{ mb: 1 }} />
      <Stack gap={2} p={2}>
        <Typography variant="h6">{t('title.navigation')}</Typography>
        {globalRootFolder && globalRootFolder.folders.length > 0 && (
          <SimpleTreeView
            expandedItems={expandedNodeIds}
            multiSelect={false}
            selectedItems={selectedNodeId}
            onSelectedItemsChange={handleSelectNode} // onNodeToggle={() => {}}
          >
            {generateTree(globalRootFolder, t, blockTypesById, standardBlocksById)}
          </SimpleTreeView>
        )}
        {globalRootFolder && globalRootFolder.folders.length === 0 && (
          <Typography align="center" variant="subtitle2">
            {t('text.noAvailableFolder')}
          </Typography>
        )}
        {!globalRootFolder && !isGlobalRootFolderFetched && (
          <Stack gap={1} p={1}>
            <Skeleton height={25} variant="rounded" />
            <Skeleton height={25} variant="rounded" />
            <Skeleton height={25} variant="rounded" />
          </Stack>
        )}
        {!globalRootFolder && isGlobalRootFolderFetched && (
          <Alert severity="warning" sx={{ m: 1 }}>
            {t('text.canNotLoadFolders')}
          </Alert>
        )}
      </Stack>
    </>
  );
};

export default TreeNavigation;
