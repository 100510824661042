import { Box } from '@mui/material';
import { FC, useCallback, useContext } from 'react';
import { Transforms } from 'slate';
import { useFocused, useSlateStatic } from 'slate-react';
import { EditableProps } from 'slate-react/dist/components/editable';

import { WipFeatures } from 'config/featureFlags';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import { PropsContext } from '../../../context';
import FunctionPopper from '../Functions/FunctionPopper';
import { StyledEditable } from '../styled';

import CalcVarProps from './CalcVarProps';
import { RenderElement } from './RenderElement';
import { RenderLeaf } from './RenderLeaf';

interface Props {
  readOnly: boolean;
}

const Slate: FC<Props> = ({ readOnly }) => {
  const editor = useSlateStatic();
  const focused = useFocused();

  const enableFormatSwitch = useFeatureFlags(WipFeatures.ENABLE_FORMAT_SWITCH);

  const { disableMaths } = useContext(PropsContext);

  const onKeyDown = useCallback<NonNullable<EditableProps['onKeyDown']>>(
    event => {
      if (editor.selection) {
        if (event.nativeEvent.key === 'ArrowLeft' || event.nativeEvent.key === 'ArrowRight') {
          event.preventDefault();
          if (!event.nativeEvent.shiftKey) {
            Transforms.collapse(editor);
          }
          Transforms.move(editor, {
            unit: 'offset',
            reverse: event.nativeEvent.key === 'ArrowLeft',
            edge: event.nativeEvent.shiftKey ? 'anchor' : undefined,
          });
        }
      }
    },
    [editor],
  );

  return (
    <>
      <StyledEditable
        autoFocus
        focused={focused}
        readOnly={readOnly}
        renderElement={RenderElement}
        renderLeaf={RenderLeaf}
        sx={[!disableMaths && { pt: `${20 - 2}px` }, enableFormatSwitch && !disableMaths && { pb: `${34 - 2}px` }]}
        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
        tabIndex={1}
        onKeyDown={onKeyDown}
      />
      {!disableMaths && (
        <Box sx={{ position: 'absolute', fontSize: 11, left: 2, top: 2, px: 0.5 }}>
          <CalcVarProps />
        </Box>
      )}
      <FunctionPopper />
    </>
  );
};

export default Slate;
