import { CheckCircle, Error } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Health, HealthStatus } from '@dametis/core';

interface Props {
  open: boolean;
  onClose: () => void;
  health: Health;
}

const HealthDetailsModal: FC<Props> = ({ open, onClose, health }) => {
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('global:text.health.details')}</DialogTitle>
      <DialogContent>
        {Object.entries(health.services).map(([serviceName, checks]) => (
          <Accordion key={serviceName}>
            <AccordionSummary>
              <Stack alignItems="center" direction="row" justifyContent="flex-start" spacing={1}>
                {Object.values(checks).every(c => c.healthStatus === HealthStatus.HEALTHY) ? (
                  <CheckCircle color="success" />
                ) : (
                  <Error color={Object.values(checks).some(c => c.healthStatus === HealthStatus.ERROR) ? 'error' : 'warning'} />
                )}
                {serviceName.charAt(0).toUpperCase() + serviceName.slice(1)}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {Object.entries(checks).map(([checkName, serviceCheck]) => (
                <Accordion key={checkName}>
                  <AccordionSummary>
                    <Stack alignItems="center" direction="row" justifyContent="flex-start" spacing={1}>
                      {serviceCheck.healthStatus === HealthStatus.HEALTHY ? (
                        <CheckCircle color="success" />
                      ) : (
                        <Error color={serviceCheck.healthStatus === HealthStatus.ERROR ? 'error' : 'warning'} />
                      )}
                      {checkName.charAt(0).toUpperCase() + checkName.slice(1)}
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>{serviceCheck.message}</AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default HealthDetailsModal;
