import { CssBaseline } from '@mui/material';
import { FC } from 'react';

import 'assets/fonts';

// import NewsLetter from 'components/NewsLetter/NewsLetter';

import CalculationMenu from 'components/UI/CalculationMenu/CalculationMenu';

import ErrorBoundary from './components/Errors/ErrorBoundary';
import Layouts from './components/Layouts/Layouts';
import Reconnect from './components/Reconnect/Reconnect';
import Routes from './components/Routes/Routes';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SnackBar from './components/SnackBar/SnackBar';
import Wrapper from './components/Wrapper/Wrapper';
import { globalStyles } from './styles/global.styles';

const App: FC = () => {
  return (
    <Wrapper>
      <CssBaseline />
      {globalStyles}
      <ErrorBoundary>
        <ScrollToTop />
        <CalculationMenu />
        <Reconnect>
          <SnackBar>
            <Layouts>
              <Routes />
            </Layouts>
          </SnackBar>
          {/* <NewsLetter /> */}
        </Reconnect>
      </ErrorBoundary>
    </Wrapper>
  );
};

// cache reset
export default App;
