import { v4 as uuidv4 } from 'uuid';

import { UUID } from '@dametis/core';

import DaCategorySeries from 'classes/DaCharts/DaCategorySeries';
import { TypedThunk } from 'store';
import { histogramAddVariable, histogramDeleteVariable, histogramUpdateVariable } from 'store/slices/playground';
import { IHistogram, IHistogramProps, IHistogramVariable, IsHistogramTab } from 'types';

import { createChart, setDaChart } from '../Chart';
import { chartToPng, getStoreTab } from '../shared';

import { exportHistogramVariable, getHistogramVariableData, getStoreHistogramVariable } from './HistogramVariable';

export const addHistogramVariableToDaChart =
  (tabUuid: UUID, variable: IHistogramVariable): TypedThunk<Promise<void>> =>
  async dispatch => {
    const storeTab = dispatch(getStoreTab(tabUuid));
    // const storeVariable = dispatch(getStoreHistogramVariable(tab.uuid));
    if (!IsHistogramTab(storeTab)) return;
    try {
      const daSeries = storeTab.chart.daChart.addSeries({
        name: variable.name,
        color: variable.color,
        unit: '%',
        style: {
          type: 'category',
        },
      }) as DaCategorySeries;
      storeTab.chart.daChart.setUnit(variable.unit);
      dispatch(histogramUpdateVariable({ tabUuid, options: { daSeries } }));
      await dispatch(getHistogramVariableData(tabUuid));
    } catch (err) {
      console.error();
    }
  };

export const createHistogram = ({ uuid = uuidv4(), daChart = null, variable = null }: IHistogramProps = {}): IHistogram => {
  const chart = createChart({ uuid, daChart }) as IHistogram;
  // addHistogramVariableToDaChart(chart, variable);
  return { ...chart, variable };
};

export const exportHistogram = async (chart: IHistogram): Promise<IHistogram> => {
  if (!chart) return null;
  const { daChart, variable } = chart;
  const preview = await chartToPng(daChart);
  return { ...chart, preview, daChart: null, variable: exportHistogramVariable(variable) };
};

export const getHistogramData =
  (tabUuid: UUID): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsHistogramTab(tab)) return;
    await dispatch(getHistogramVariableData(tabUuid));
    tab.chart.daChart.redraw();
  };

// ACTIONS

export const addHistogramVariableToHistogram =
  (tabUuid: UUID, variable: IHistogramVariable): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsHistogramTab(tab)) return;
    dispatch(histogramAddVariable(variable));
    const storeVariable = dispatch(getStoreHistogramVariable(tab.uuid));
    if (!storeVariable) return;
    await dispatch(addHistogramVariableToDaChart(tabUuid, storeVariable));
    tab.chart.daChart.redraw();
  };

export const deleteHistogramVariableFromHistogram =
  (chart: IHistogram, variable: IHistogramVariable): TypedThunk<void> =>
  dispatch => {
    chart.daChart.removeSeries(variable.daSeries);
    dispatch(histogramDeleteVariable());
    chart.daChart.redraw();
  };

export const setDaChartToHistogram =
  (tabUuid: UUID, container: HTMLDivElement): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsHistogramTab(tab)) return;
    const daChart = dispatch(
      setDaChart(tab.chart, container, {
        type: 'category',
        periodZoom: false,
        legend: false,
        height: null,
      }),
    );
    const variable = dispatch(getStoreHistogramVariable(tabUuid));
    if (!variable) return;
    await dispatch(addHistogramVariableToDaChart(tabUuid, variable));
    daChart.redraw();
  };
