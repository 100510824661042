import { ModbusTcpDataType, QueryStrategy, TransformerType, VariableKind } from '@dametis/core';

import { RecomputeFromMethod } from 'components/Configuration/Variables/CreateVariableModal/VariableSettingsForm/CalculatedVariableSettingsForm/RecomputeFromPicker';
import { EntityKey } from 'components/Configuration/Variables/VariablesRightPanel/DependenciesPanel/DependenciesPanel';
import { ModbusTcpFunction } from 'types';
import { BulkEditTagsFunction, FakeVariableKind, PanelTab, VariablesGridColumn, VariablesGridColumnScope } from 'types/variables';

const columns: Record<VariablesGridColumn, string> = {
  [VariablesGridColumn.CHECKBOX]: '',
  [VariablesGridColumn.KIND]: 'Type',
  [VariablesGridColumn.NAME]: 'Nom',
  [VariablesGridColumn.SENSOR_NAME]: 'Nom du capteur',
  [VariablesGridColumn.REFERENCE]: 'Référence',
  [VariablesGridColumn.DESCRIPTION]: 'Description',
  [VariablesGridColumn.PHYSICAL_QUANTITY]: 'Grandeur physique',
  [VariablesGridColumn.LAST_POINT_VALUE]: 'Dernière valeur',
  [VariablesGridColumn.UNIT]: 'Unité',
  [VariablesGridColumn.LAST_POINT_DATE]: 'Date de la dernière valeur',
  [VariablesGridColumn.LAST_PROGRESS_VALUE]: 'Progression',
  [VariablesGridColumn.PROTOCOL]: 'Protocol',
  [VariablesGridColumn.TAGS]: 'Tags',
  [VariablesGridColumn.DEVICE]: 'Équipement',
  [VariablesGridColumn.OFFSET]: 'Décalage',
  [VariablesGridColumn.VARIABLE_CONFIG_TRANSFORMER_TYPE]: 'Transformateur',
  [VariablesGridColumn.VARIABLE_CONFIG_TRANSFORMER]: 'Valeur du transformateur',
  [VariablesGridColumn.MODBUS_TCP_UID]: 'Unit ID',
  [VariablesGridColumn.MODBUS_TCP_FUNCTION]: 'Fonction',
  [VariablesGridColumn.MODBUS_TCP_ADDRESS]: 'Adresse',
  [VariablesGridColumn.MODBUS_TCP_INVERTWORDS]: 'Mots inversés',
  [VariablesGridColumn.MODBUS_TCP_INVERTBYTES]: 'Octets inversés',
  [VariablesGridColumn.MODBUS_TCP_DATATYPE]: 'Type',
  [VariablesGridColumn.MODBUS_TCP_BIT]: 'Bit',
  [VariablesGridColumn.OPC_UA_NODE_ID]: 'Node ID',
  [VariablesGridColumn.S7_ADDRESS]: 'Adresse',
  [VariablesGridColumn.SQL_MODE]: 'Mode',
  [VariablesGridColumn.SQL_REQUEST]: 'Requête',
  [VariablesGridColumn.SQL_VARIABLES]: 'Variables',
  [VariablesGridColumn.BACNET_ID]: 'ID',
  [VariablesGridColumn.MONITORING_EXPECTED_FREQUENCY]: 'Fréquence attendue',
  [VariablesGridColumn.MONITORING_ABNORMALITY_DELAY]: 'Délai sur valeur anormale',
  [VariablesGridColumn.MONITORING_MIN]: 'Minimum',
  [VariablesGridColumn.MONITORING_MAX]: 'Maximum',
};

const columnExamples: Partial<Record<VariablesGridColumn, string>> = {
  [VariablesGridColumn.NAME]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.SENSOR_NAME]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.REFERENCE]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.DESCRIPTION]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.UNIT]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.TAGS]: 'Tag 1, Tag 2, ...',
  [VariablesGridColumn.OFFSET]: '$t(variables:fieldTypes.number)',
  [VariablesGridColumn.VARIABLE_CONFIG_TRANSFORMER_TYPE]: Object.values(TransformerType).join(' | '),
  [VariablesGridColumn.VARIABLE_CONFIG_TRANSFORMER]:
    '$t(variables:fieldTypes.number) | in1: $t(variables:fieldTypes.number), in2: $t(variables:fieldTypes.number), out1: $t(variables:fieldTypes.number), out2: $t(variables:fieldTypes.number)',
  [VariablesGridColumn.MODBUS_TCP_UID]: '$t(variables:fieldTypes.number) (min: 0 / max: 254)',
  [VariablesGridColumn.MODBUS_TCP_FUNCTION]: '1 | 2 | 3 | 4',
  [VariablesGridColumn.MODBUS_TCP_ADDRESS]: '$t(variables:fieldTypes.number) (min: 0 / max: 65535)',
  [VariablesGridColumn.MODBUS_TCP_INVERTWORDS]: '$t(variables:fieldTypes.boolean)',
  [VariablesGridColumn.MODBUS_TCP_INVERTBYTES]: '$t(variables:fieldTypes.boolean)',
  [VariablesGridColumn.MODBUS_TCP_DATATYPE]: Object.values(ModbusTcpDataType).join(' | '),
  [VariablesGridColumn.MODBUS_TCP_BIT]: '$t(variables:fieldTypes.number) (min: 1 / max: 15)',
  [VariablesGridColumn.OPC_UA_NODE_ID]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.S7_ADDRESS]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.SQL_MODE]: Object.values(QueryStrategy).join(' | '),
  [VariablesGridColumn.SQL_REQUEST]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.SQL_VARIABLES]:
    '$t(variables:fieldTypes.string): $t(variables:fieldTypes.string), $t(variables:fieldTypes.string): $t(variables:fieldTypes.string), ...',
  [VariablesGridColumn.BACNET_ID]: '$t(variables:fieldTypes.number)',
};

const columnScopes: Record<VariablesGridColumnScope, string> = {
  [VariablesGridColumnScope.COMMON]: 'Général',
  [VariablesGridColumnScope.ACQUISITION]: 'Acquisition',
  [VariablesGridColumnScope.DATA_TRANSFORMER]: 'Donnée',
  [VariablesGridColumnScope.MCO]: 'Monitoring',
  [VariablesGridColumnScope.MODBUS_TCP]: 'Modbus TCP',
  [VariablesGridColumnScope.OPC_UA]: 'OPC UA',
  [VariablesGridColumnScope.S7]: 'Siemens S7',
  [VariablesGridColumnScope.SQL]: 'SQL',
  [VariablesGridColumnScope.BACNET]: 'BACnet',
  [VariablesGridColumnScope.MQTT]: 'MQTT',
  [VariablesGridColumnScope.MONITORING]: 'Monitoring',
};

const variableUsesEntity: Record<EntityKey, string> = {
  alarms: 'Alarmes',
  batches: 'Batchs',
  blockTypes: 'Types de bloc',
  calculatedVariables: 'Variables',
  models: 'Modèles',
  playgrounds: 'Playgrounds',
  reports: 'Rapports',
  synoptics: 'Synoptiques',
  variableLists: 'Listes de variables',
  blocks: 'Blocks',
  aliases: 'Alias',
};

const queryStrategies: Record<QueryStrategy, string> = {
  [QueryStrategy.DEFAULT]: 'Défaut',
  [QueryStrategy.TEMPLATE]: 'Template',
  [QueryStrategy.BULK]: 'Bulk',
};

export default {
  title: {
    variables: 'Variables',
    createVariableModal: 'Créer une nouvelle variable',
    duplicateVariableModal: 'Dupliquer "{{name}}"',
    // rightPanel: '{{count}} variables sélectionnées',
    rightPanel_one: 'Détails de la variable',
    rightPanel_other: 'Détails des variables',
    rightPanelSubtitle: '{{count}} variables sélectionnées',
    acquisitionSettings: 'Acquisition',
    dataTransformerSettings: 'Transformation de la donnée',
    mcoSettings: 'Monitoring',
    importRealVariablesModal: 'Importer des variables acquises',
    importCalculatedVariablesModal: 'Importer des variables calculées',
    promptBeforeClosing: 'Êtes-vous sûr ?',
  },
  button: {
    variable: 'Variable',
    create: 'Créer',
    close: 'fermer',
    next: 'Suivant',
    previous: 'Précédent',
    columns: 'Colonnes',
    edit: 'Modifier',
    duplicate: 'Dupliquer',
    delete: 'Supprimer',
    cancel: 'Annuler',
    save: 'Enregistrer',
    addComment: 'Ajouter un commentaire',
    export: 'Exporter',
    exportAsCsv: 'Exporter le CSV',
    exportAsExcel: 'Exporter le fichier Excel',
    refresh: 'Rafraîchir',
    new: 'Nouveau',
    import: 'Importer',
    downloadTemplateCsv: 'exemple.csv',
    recompute: 'Recalculer',
    yesClose: 'Fermer sans sauvegarder',
    noClose: 'Annuler',
    debug: 'Debug',
  },
  label: {
    uuid: 'UUID',
    name: 'Nom',
    enabled: 'Active',
    favorite: 'Favori',
    unit: 'Unité',
    sensorName: 'Nom du capteur',
    reference: 'Référence',
    description: 'Description',
    kind: 'Type de variable',
    kindShort: 'Type',
    tags: 'Tags',
    device: 'Équipement',
    address: 'Adresse',
    uid: 'Unit ID',
    id: 'ID',
    function: 'Fonction',
    dataType: 'Type',
    bit: 'Bit',
    transformer: 'Valeur',
    transformerType: 'Transformateur',
    invertWords: 'Mots inversés',
    invertBytes: 'Octets inversés',
    in1: 'In1',
    out1: 'Out1',
    in2: 'In2',
    out2: 'Out2',
    nodeId: 'Node ID',
    params: 'Expression',
    accumulate: 'Accumulateur',
    delay: 'Délai',
    value: 'Valeur',
    physicalQuantity: 'Grandeur physique',
    protocol: 'Protocol',
    request: 'Requête',
    variables: 'Variables',
    lastPointValue: 'Dernière valeur',
    lastPointDate: 'Date de la dernière valeur',
    offset: 'Décalage',
    prefix: 'Préfixe',
    suffix: 'Suffixe',
    tagsFunction: {
      [BulkEditTagsFunction.REPLACE_TAGS]: 'Remplacer',
      [BulkEditTagsFunction.MERGE_TAGS]: 'Ajouter aux existants',
    },
    dependencies: 'Dépendances',
    withoutTag: 'Sans tag',
    field: 'Colonne nº{{index}}',
    settings: 'Paramètres',
    columns: 'Colonnes',
    preview: 'Prévisualitation',
    notSelected: 'Non sélectionnée',
    gridUseHeader: 'Ce fichier contient un en-tête',
    valuesStart: 'Début des valeurs',
    valuesEnd: 'Fin des valeurs',
    mode: 'Mode',
    recomputeFromMethod: 'Modifier les données',
    progress: 'Progression',
  },
  kind: {
    [VariableKind.CALCULATED]: 'Calculée',
    [VariableKind.CONSTANT]: 'Constante',
    [VariableKind.MANUAL]: 'Manuelle',
    [VariableKind.NONE]: 'Inconnue',
    [VariableKind.REAL]: 'Acquise',
    [`${VariableKind.CALCULATED}_one`]: 'Calculée',
    [`${VariableKind.CALCULATED}_other`]: 'Calculées',
    [`${VariableKind.CONSTANT}_one`]: 'Constante',
    [`${VariableKind.CONSTANT}_other`]: 'Constantes',
    [`${VariableKind.MANUAL}_one`]: 'Manuelle',
    [`${VariableKind.MANUAL}_other`]: 'Manuelles',
    [`${VariableKind.NONE}_one`]: 'Inconnue',
    [`${VariableKind.NONE}_other`]: 'Inconnues',
    [`${VariableKind.REAL}_one`]: 'Acquise',
    [`${VariableKind.REAL}_other`]: 'Acquises',
    [FakeVariableKind.ALARM]: 'Alarme',
    [FakeVariableKind.BATCH]: 'Batch',
    [FakeVariableKind.VAPOR_MIX]: 'Mix vapeur',
  },
  alert: {
    kind: {
      [VariableKind.CALCULATED]:
        "Une variable calculée est une variable basée sur d'autres variables, et dont l'expression détermine sa valeur",
      [VariableKind.CONSTANT]: 'Une variable constante est une variable dont la valeur ne change pas',
      [VariableKind.MANUAL]: 'Une variable manuelle est une variable dont les valeurs seront définies suite à un import manuel',
      [VariableKind.NONE]: '',
      [VariableKind.REAL]: "Une variable acquise est une variable dont les données sont reçues à partir d'un équipement",
    },
  },
  stepper: {
    common: 'Général',
    settings: 'Réglages',
    importing: 'Importation',
    creating: 'Création',
    file: 'Fichier',
    data: 'Données',
  },
  modbusTcp: {
    [`function_${ModbusTcpFunction.READCOILS_1}`]: '1 - Read Coils',
    [`function_${ModbusTcpFunction.READDISCRETEINPUTS_2}`]: '2 - Read Discrete Inputs',
    [`function_${ModbusTcpFunction.READHOLDINGREGISTERS_3}`]: '3 - Read Holding Registers',
    [`function_${ModbusTcpFunction.READINPUTREGISTERS_4}`]: '4 - Read Input Registers',
  },
  transformerType: {
    [TransformerType.MULTIPLIER]: 'Multiplicateur',
    [TransformerType.LINEAR_INTERPOLATION]: 'Interpolation linéaire',
  },
  text: {
    allDevices: 'Tous les équipements',
    searchingVariables: 'Recherche de variables...',
    displayAll: 'Tout afficher',
    noSelection: 'Aucune sélection',
    exportFileName: 'dametis_{{group}}_{{site}}_{{date}}',
    noDependency: 'Aucune dépendance',
    fetchedAt: 'Dernières valeurs récupérées le {{date}}',
    realVariable: 'Variable acquise',
    true: 'Oui',
    false: 'Non',
    createVariable: 'Créer une variable',
    importVariables: 'Importer des variables',
    importRealVariables: 'Variables acquises',
    importCalculatedVariables: 'Variables calculées',
    selectFile: 'Déposez un fichier ou cliquez pour en choisir un',
    dropFile: 'Déposez le fichier ici',
    fileNotAllowed: "Ce type de fichier n'est pas autorisé",
    successCreateCalculatedVariable: 'Variables calculées importées avec succès',
    errorCreateCalculatedVariable: "Une erreur est survenue pendant l'import",
    errors_one: '1 erreur',
    errors_other: '{{count}} erreurs',
    lineNumber: 'ligne nº',
    promptBeforeClosing: 'Vous fermez le mode édition.',
    notExistingVariable_one: "Cette variable n'existe pas.",
    notExistingVariable_other: "Ces variables n'existent pas.",
    deleteVariableWarning_one:
      "La suppression de cette variable peut affecter le fonctionnement des outils (Rapports, Synoptiques, Playgrounds, etc...) l'utilisant. Vérifiez son usage avant sa suppression.",
    deleteVariableWarning_other:
      'La suppression de ces variables peut affecter le fonctionnement des outils (Rapports, Synoptiques, Playgrounds, etc...) les utilisant. Vérifiez leurs usages avant leur suppression.',
    selectAll: 'Tout sélectionner',
    unknownTag: 'Tag inconnu',
  },
  fieldTypes: {
    string: 'Chaîne de caractères',
    number: 'Nombre',
    boolean: 'Booléen',
  },
  columns,
  columnScopes,
  columnExamples,
  tooltip: {
    importVariables: 'importer des variables',
    createVariable: 'créer une variable',
    editVariable: 'modifier une variable',
    duplicateVariable: 'dupliquer une variable',
    deleteVariable: 'supprimer une variable',
    addComment: 'ajouter un commentaire',
    copyToClipboard: 'copier dans le presse-papier',
    recompute: 'recalculer',
    cannotDuplicateRealVariable: 'impossible du dupliquer une variable acquise',
  },
  toast: {
    copyToClipboard: 'UUID de la variable ajouté au presse-papier',
    fileReadingAborted: 'Lecture du fichier annulée',
    fileReadingFailed: 'Lecture du fichier impossible',
    csvInvalid: 'Format CSV invalide',
    successRecompute: 'Demande prise en compte, recalcul en cours...',
  },
  panelTab: {
    [PanelTab.INFORMATIONS]: 'Informations',
    [PanelTab.COMMENTS]: 'Commentaires',
    [PanelTab.ACTIVITIES]: 'Activités',
    [PanelTab.DEPENDENCIES]: 'Dépendances',
  },
  variableUsesEntity,
  queryStrategies,
  dataGrid: {
    noRowsLabel: 'Aucune variable',
    footerTotalRows: 'Total variables :',
  },
  recomputeFromMethod: {
    [RecomputeFromMethod.START]: 'Depuis toujours',
    [RecomputeFromMethod.NOW]: 'À partir de maintenant',
    // [RecomputeFromMethod.DATE]: '',
  },
  recomputeFromLabel: {
    [RecomputeFromMethod.START]: 'Toutes les données seront recalculées avec la nouvelle expression.',
    [RecomputeFromMethod.NOW]:
      "Les données déjà calculées jusqu'à maintenant seront conservées (ancienne expression).\nLa nouvelle expression prendra effet pour toutes les nouvelles données.",
    // date: "Les données déjà calculées jusqu'à la date choisie seront conservées (ancienne expression).\nLa nouvelle expression prendra effet pour toutes les données à partir de cette date.",
  },
  notDefined: 'Non défini',
  mco: {
    title: {
      settings: 'Monitoring',
    },
    label: {
      expectedFrequencyEnabled: 'Surveiller la fréquence des données',
      expectedFrequencyAuto: "Fréquence de l'équipement ({{inheritedValue}})",
      expectedFrequencyCustom: 'Cette fréquence',
      abnormalityDelayEnabled: 'Surveiller les valeurs hors plage',
      minimumThreshold: 'Seuil minimal',
      maximumThreshold: 'Seuil maximal',
      abnormalityDelayCustom: 'Délai de dépassement',
    },
    link: {
      notifications: 'Gérer les notifications',
    },
  },
};
