import { Box, Link, Popover, PopoverProps } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Format, IsEcma376Format } from '@dametis/core';

import { dateFormats, numberFormats } from 'config/formats';
import { addFormatHistoryItem } from 'functions/formatHistory';
import { FormatPickerMenu } from 'types/format';
import { UserHelperName } from 'types/userHelpers';

import Helper from '../Helper/Helper';

import FormatHistory from './FormatHistory';
import FormatInput from './FormatInput';
import FormatLists from './FormatLists';
import { defaultFormat, useFormatPickerContext } from './FormatPickerContext';
import FormatPopoverFooter from './FormatPopoverFooter';
import FormatSettings from './FormatSettings';
import { isExistingFormatCode } from './functions/isExistingFormatCode';

export interface FormatPopoverBaseProps {
  anchorEl: PopoverProps['anchorEl'];
  onClose?: () => void | undefined;
  open?: boolean;
}

const FormatPopoverBase: FC<FormatPopoverBaseProps> = ({ anchorEl, open: userOpen = undefined, onClose: userOnClose = undefined }) => {
  const { t } = useTranslation('format');

  const { open, setOpen, selectedMenu, setSelectedMenu, value, setPickerValue, pickerValue } = useFormatPickerContext();

  const [inputValue, setInputValue] = useState<string>('');

  const isEmptyValue = useMemo(() => IsEcma376Format(pickerValue) && pickerValue.code?.trim().length === 0, [pickerValue]);

  const handleClose = useCallback(
    (newValue: Format) => () => {
      setOpen(false);
      if (userOnClose !== undefined) {
        userOnClose();
      }
      if (!isEmptyValue) {
        addFormatHistoryItem(newValue);
      }
    },
    [setOpen, userOnClose, isEmptyValue],
  );

  // const handlePopoverKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
  //   event => {
  //     if (selectedMenu === FormatPickerMenu.LISTS && listsKeyDownHandler) {
  //       listsKeyDownHandler(event);
  //     }
  //     if (selectedMenu === FormatPickerMenu.HISTORY) {
  //       historyKeyDownHandler(event);
  //     }
  //   },
  //   [listsKeyDownHandler, historyKeyDownHandler, selectedMenu],
  // );

  useEffect(() => {
    if (open) {
      // setFocusIndex(-1);
      setInputValue('');
      setPickerValue(value ?? defaultFormat);
      if (value && IsEcma376Format(value) && !isExistingFormatCode([...numberFormats, ...dateFormats], value.code)) {
        setInputValue(value.code);
      }
    }
  }, [open, value, setPickerValue, setInputValue]);

  useEffect(() => {
    if (open) {
      setSelectedMenu(FormatPickerMenu.LISTS);
    }
  }, [open, setSelectedMenu]);

  useEffect(() => {
    if (userOpen !== undefined) {
      setOpen(userOpen);
    }
  }, [userOpen, setOpen]);

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        PaperProps={{ sx: { width: 300 } }}
        onClose={handleClose(pickerValue)}
      >
        <Helper name={UserHelperName.FORMAT_PICKER_HELPER} sx={{ mx: 1, mt: 1 }}>
          <Box>{t('helper.description')}</Box>
          <Link href="https://customformats.com/" rel="noopener noreferrer" target="_blank">
            {t('helper.redirection')}
          </Link>
        </Helper>
        <FormatInput setValue={setInputValue} value={inputValue} />
        {selectedMenu === FormatPickerMenu.HISTORY && <FormatHistory />}
        {selectedMenu === FormatPickerMenu.LISTS && (
          <FormatLists inputValue={inputValue} isEmptyValue={isEmptyValue} setInputValue={setInputValue} onClose={handleClose} />
        )}
        {selectedMenu === FormatPickerMenu.SETTINGS && <FormatSettings isEmptyValue={isEmptyValue} />}
        <FormatPopoverFooter isEmptyValue={isEmptyValue} />
      </Popover>
    </>
  );
};

export const formatPopoverBaseDefaultProps = {
  open: undefined,
  onClose: undefined,
};

export default FormatPopoverBase;
