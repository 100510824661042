import { CalculationVariable, CreatePlaygroundBody, IsCalculationVariable, TabType } from '@dametis/core';

import { createPlayground, exportPlayground } from 'components/Playground/functions/Playground';
import { createTyChart } from 'components/Playground/functions/TY_CHART/TyChart';
import { createTyGroup } from 'components/Playground/functions/TY_CHART/TyGroup';
import { createTyVariable } from 'components/Playground/functions/TY_CHART/TyVariable';
import { createTab } from 'components/Playground/functions/Tab';
import { ITyVariable } from 'types';

import { getNextColor } from './color';
import { getCalculationUnit } from './getCalculationUnit';

export const getPlaygroundBody = async (calculations: CalculationVariable[], isBarChart?: boolean): Promise<CreatePlaygroundBody> => {
  const chart = createTyChart({ isBarChart });
  const groups: Record<string, ITyVariable[]> = {};
  const generatedVariables: ITyVariable[] = [];
  calculations.forEach(calculation => {
    if (!IsCalculationVariable(calculation)) return;
    const unit = getCalculationUnit(calculation) ?? 'NO_UNIT';
    const variable = createTyVariable({
      expression: { ...calculation, slate: undefined },
      unit,
      color: getNextColor(generatedVariables),
    });
    generatedVariables.push(variable);
    groups[unit] = [...(groups[unit] ?? []), { ...variable, unit: unit === 'NO_UNIT' ? null : unit }];
  });
  Object.entries(groups).forEach(([unit, groupVariables]) => {
    const group = createTyGroup({ variables: groupVariables, unit: unit === 'NO_UNIT' ? null : unit });
    chart.groups.push(group);
  });
  const tab = createTab({ type: isBarChart ? TabType.BAR_CHART : TabType.TY_CHART, chart });
  const playground = await exportPlayground(createPlayground({ tabs: [tab] }));
  return playground;
};
