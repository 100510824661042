import { OnChange, OnMount } from '@monaco-editor/react';
import { FC, useCallback, useMemo } from 'react';
import { useSlateStatic } from 'slate-react';

import { CalculationVariable } from '@dametis/core';
import { ValidateCalculation, mathJs } from '@dametis/mathjs';

import { useSelector } from 'store';
import { useVncStore } from 'zustand/stores/vnc';

import { keepProps, slateToTada, tadaToSlate } from '../../../slate/tada';

import MonacoInput from './MonacoInput';

interface Props {
  readOnly?: boolean;
}

const Monaco: FC<Props> = ({ readOnly = false }) => {
  const slateEditor = useSlateStatic();

  const isFKL = useSelector(state => state.auth.user?.email.includes('florent.klein') ?? false);

  const calcVarProps = useVncStore(state => state.calcVarProps);
  const setCalcVarProps = useVncStore(state => state.setCalcVarProps);
  const setMonacoEditor = useVncStore(state => state.setMonacoEditor);

  const defaultValue = useMemo(
    () => JSON.stringify(slateToTada(slateEditor.children, { withSlate: isFKL, calcVarProps }), undefined, 2),
    [calcVarProps, isFKL, slateEditor.children],
  );

  const handleDidMount = useCallback<OnMount>(
    editor => {
      setMonacoEditor(editor);
    },
    [setMonacoEditor],
  );

  const handleChange = useCallback<OnChange>(
    value => {
      if (value === undefined) return;
      try {
        const tada: CalculationVariable = JSON.parse(value);
        ValidateCalculation(mathJs, tada);
        delete tada.slate;
        setCalcVarProps(keepProps(tada));
        slateEditor.children = tadaToSlate(tada);
        slateEditor.onChange();
      } catch (err) {
        console.error(err);
      }
    },
    [setCalcVarProps, slateEditor],
  );

  return <MonacoInput defaultValue={defaultValue} options={{ readOnly }} onChange={handleChange} onMount={handleDidMount} />;
};

export default Monaco;
