export default {
  title: {
    dragAndDrop: 'Drag and drop',
    or: 'or',
  },
  button: {
    browse: 'Browse files',
  },
  text: {
    selectedFile: 'Selected file:',
    formatsAllowed: 'Authorized formats: ',
    file: 'File',
    browse: 'Drag and drop a file or click to select one',
  },
  tooltip: {
    upload: 'Upload a file',
  },
};
