import { SvgIconComponent, TuneOutlined } from '@mui/icons-material';
import {
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  BoxProps,
  Fade,
  IconButton,
  IconButtonProps,
  Popover,
  Stack,
  SvgIcon,
  accordionSummaryClasses,
} from '@mui/material';
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

const titleContainerPropsDefaultProp: BoxProps = {};

const contentContainerPropsDefaultProp: BoxProps = {};

export interface EntityAccordionSummaryProps extends Omit<AccordionSummaryProps, 'children' | 'title' | 'content'> {
  title?: ReactNode;
  titleContainerProps?: BoxProps;
  content?: ReactNode;
  contentContainerProps?: BoxProps;
  contentMenu?: ReactNode;
  contentMenuIcon?: SvgIconComponent;
  onSizeChange?: (isContentHidden: boolean, offsetWidth: number, scrollWidth: number) => void;
}

const EntityAccordionSummary: FC<EntityAccordionSummaryProps> = ({
  title = undefined,
  titleContainerProps = titleContainerPropsDefaultProp,
  content = undefined,
  contentContainerProps = contentContainerPropsDefaultProp,
  contentMenu = undefined,
  contentMenuIcon = TuneOutlined,
  onSizeChange: userOnSizeChange = undefined,
  ...props
}) => {
  const [isContentHidden, setIsContentHidden] = useState<boolean>(false);
  const [contentMenuAnchorEl, setContentMenuAnchorEl] = useState<HTMLElement | null>(null);

  const isContentMenuOpen = useMemo(() => Boolean(contentMenuAnchorEl), [contentMenuAnchorEl]);

  const toolbarRef = useRef<HTMLDivElement>(null);

  const onSizeChange = useCallback(() => {
    if (!toolbarRef.current || !toolbarRef.current) return;
    const { offsetWidth, scrollWidth } = toolbarRef.current;
    setIsContentHidden(offsetWidth < scrollWidth);
    if (userOnSizeChange) {
      userOnSizeChange(offsetWidth < scrollWidth, offsetWidth, scrollWidth);
    }
    if (offsetWidth >= scrollWidth) {
      setContentMenuAnchorEl(null);
    }
  }, [userOnSizeChange]);

  const handleOpenMenu = useCallback<NonNullable<IconButtonProps['onClick']>>(event => {
    event.stopPropagation();
    setContentMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setContentMenuAnchorEl(null);
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(onSizeChange);
    if (toolbarRef.current) {
      resizeObserver.observe(toolbarRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [onSizeChange]);

  return (
    <>
      <AccordionSummary {...props} sx={{ [`& .${accordionSummaryClasses.content}`]: { minWidth: 0 } }}>
        <Stack
          ref={toolbarRef}
          alignItems="center"
          direction="row"
          justifyContent="flex-start"
          maxWidth={1}
          position="relative"
          spacing={3}
          width={1}
        >
          {title && (
            <Box flexGrow={!content && !contentMenu ? 1 : 0} width={!content && !contentMenu ? 0 : 'auto'} {...titleContainerProps}>
              {title}
            </Box>
          )}
          {content && (
            <Fade in={!isContentHidden}>
              <Box flexGrow={1} {...contentContainerProps}>
                {content}
              </Box>
            </Fade>
          )}
          {contentMenu && (
            <Fade in={isContentHidden}>
              <Box position="absolute" right={0}>
                <IconButton size="small" onClick={handleOpenMenu}>
                  <SvgIcon component={contentMenuIcon} fontSize="small" />
                </IconButton>
              </Box>
            </Fade>
          )}
        </Stack>
      </AccordionSummary>
      {contentMenu && (
        <Popover
          anchorEl={contentMenuAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={isContentMenuOpen}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleCloseMenu}
        >
          <Box p={1}>{contentMenu}</Box>
        </Popover>
      )}
    </>
  );
};

export default EntityAccordionSummary;
