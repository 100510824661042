import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, InputLabel, Popover, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { ChangeEventHandler, FC, KeyboardEventHandler, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { BlockTypeMetadataOption } from '@dametis/core';

import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';

export interface MetadataOptionSettingsProps {
  value: BlockTypeMetadataOption;
  onChange: (newValue: BlockTypeMetadataOption) => void;
}

const MetadataOptionSettings: FC<MetadataOptionSettingsProps> = ({ value, onChange }) => {
  const { t } = useTranslation('lego');

  const [optionValue, setOptionValue] = useState<string>('');
  const [optionMenuAnchorEl, setOptionMenuAnchorEl] = useState<HTMLElement | null>(null);

  const isOptionMenuOpen = useMemo(() => Boolean(optionMenuAnchorEl), [optionMenuAnchorEl]);

  const isValidOption = useMemo(
    () => optionValue.trim().length > 0 && value.content.availableOptions.every(availableOption => availableOption.value !== optionValue),
    [optionValue, value.content.availableOptions],
  );

  const handleOpenOptionMenu: MouseEventHandler<HTMLElement> = useCallback(event => {
    setOptionValue('');
    setOptionMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseOptionMenu = useCallback(() => {
    setOptionMenuAnchorEl(null);
  }, []);

  const handleChangeOptionValue: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setOptionValue(event.target.value);
  }, []);

  const handleAddOption = useCallback(() => {
    const newValue: BlockTypeMetadataOption = {
      ...value,
      content: { ...value.content, availableOptions: [...value.content.availableOptions, { uuid: uuidv4(), value: optionValue.trim() }] },
    };
    setOptionMenuAnchorEl(null);
    onChange(newValue);
  }, [value, optionValue, onChange]);

  const handleDeleteOption = useCallback(
    (optionIndex: number) => () => {
      const newValue: BlockTypeMetadataOption = {
        ...value,
        content: {
          ...value.content,
          availableOptions: value.content.availableOptions.filter((_option, index) => index !== optionIndex),
        },
      };
      onChange(newValue);
    },
    [value, onChange],
  );

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.key === 'Enter' && isValidOption) {
        event.preventDefault();
        event.stopPropagation();
        const newValue: BlockTypeMetadataOption = {
          ...value,
          content: {
            ...value.content,
            availableOptions: [...value.content.availableOptions, { uuid: uuidv4(), value: optionValue.trim() }],
          },
        };
        setOptionMenuAnchorEl(null);
        onChange(newValue);
      }
    },
    [value, optionValue, onChange, isValidOption],
  );

  return (
    <>
      <Stack gap={1}>
        <Stack alignItems="flex-start" direction="row" justifyContent="space-between">
          <InputLabel>{t('label.options')}</InputLabel>
          <ActionButton startIcon={<AddOutlined />} onClick={handleOpenOptionMenu}>
            {t('button.add')}
          </ActionButton>
        </Stack>
        {value.content.availableOptions.map((availableOption, index) => (
          <Stack key={availableOption.uuid} alignItems="center" direction="row" justifyContent="space-between">
            <Typography>{availableOption.value}</Typography>
            <Tooltip title={t('tooltip.delete')}>
              <IconButton size="small" onClick={handleDeleteOption(index)}>
                <DeleteOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        ))}
        {value.content.availableOptions.length === 0 && (
          <Typography align="center" p={1} variant="subtitle2">
            {t('text.noOption')}
          </Typography>
        )}
      </Stack>
      <Popover
        anchorEl={optionMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isOptionMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseOptionMenu}
      >
        <Stack direction="row" p={2} spacing={1}>
          <Box width={180}>
            <TextField
              autoFocus
              fullWidth
              placeholder={t('placeholder.value')}
              size="small"
              value={optionValue}
              onChange={handleChangeOptionValue}
              onKeyDown={handleKeyDown}
            />
          </Box>
          <Button
            color="secondary"
            disabled={!isValidOption}
            sx={{ minWidth: 'unset', padding: '6px' }}
            type="submit"
            variant="contained"
            onClick={handleAddOption}
          >
            <AddOutlined />
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

export default MetadataOptionSettings;
