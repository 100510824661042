import { Box, Checkbox, Divider, ListItemText, MenuItem, SelectChangeEvent } from '@mui/material';
import { Dispatch, FC, MouseEventHandler, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShortStandardBlockInfo, UUID } from '@dametis/core';

import SelectFilter from 'components/UI/SelectFilter/SelectFilter';
import { useStandardBlocks } from 'store/api/standardBlocks';

const standardBlocksEmptyArray: ShortStandardBlockInfo[] = [];

export interface SelectStandardBlocksProps {
  selectedStandardBlockIds: UUID[];
  setSelectedStandardBlockIds: Dispatch<SetStateAction<UUID[]>>;
  isOpen: boolean;
}

const SelectStandardBlocks: FC<SelectStandardBlocksProps> = ({ selectedStandardBlockIds, setSelectedStandardBlockIds, isOpen }) => {
  const { t } = useTranslation('lego');

  const { data: standardBlocks = standardBlocksEmptyArray } = useStandardBlocks();

  const sortedStandardBlocks = useMemo(
    () => [...standardBlocks].sort((standardBlockA, standardBlockB) => standardBlockA.name.localeCompare(standardBlockB.name)),
    [standardBlocks],
  );

  const handleStandardBlocks = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      setSelectedStandardBlockIds(event.target.value as UUID[]);
    },
    [setSelectedStandardBlockIds],
  );

  const handleAllStandardBlocks: MouseEventHandler<HTMLLIElement> = useCallback(
    event => {
      event.stopPropagation();
      if (!selectedStandardBlockIds.length) {
        setSelectedStandardBlockIds(standardBlocks.map(standardBlock => standardBlock.uuid));
      } else {
        setSelectedStandardBlockIds([]);
      }
    },
    [selectedStandardBlockIds?.length, standardBlocks, setSelectedStandardBlockIds],
  );

  return (
    <SelectFilter
      multiple
      disabled={!isOpen}
      placeholder={t('label.standardBlocks')}
      size="small"
      value={selectedStandardBlockIds}
      onChange={handleStandardBlocks}
    >
      <Box>
        <MenuItem onClick={handleAllStandardBlocks}>
          <Checkbox
            checked={selectedStandardBlockIds.length === standardBlocks.length}
            indeterminate={selectedStandardBlockIds.length !== 0 && selectedStandardBlockIds.length !== standardBlocks.length}
          />
          {t('text.allSelected')}
        </MenuItem>
      </Box>
      <Divider />
      {sortedStandardBlocks.map(standardBlock => (
        <MenuItem key={standardBlock.uuid} value={standardBlock.uuid}>
          <Checkbox checked={selectedStandardBlockIds.includes(standardBlock.uuid)} />
          <ListItemText primary={standardBlock.name} />
        </MenuItem>
      ))}
    </SelectFilter>
  );
};

export default SelectStandardBlocks;
