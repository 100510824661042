export default {
  alertMessage: {
    wrongPasswordNotMatch: 'Las dos contraseñas no son idénticas',
    emptyPassword: '¡Debe ingresar una contraseña!',
    invalidStrength: 'Por favor elija una contraseña más segura. Intente con una combinación de letras, números y símbolos.',
    emptyField: 'Por favor complete todos los campos',
    successInit: 'Cuenta activada, ¡ahora puede iniciar sesión!',
    failInit: 'Fallo en la inicialización de su cuenta',
    invalidInitToken: 'Este enlace no funciona. Por favor contacte al ',
    validInitToken: '¡Ahora puede iniciar sesión!',
    logDeadApi: 'Se ha producido un error, por favor inténtelo de nuevo más tarde',
  },
  title: {
    titleSection: 'Activación de su cuenta',
  },
  input: {
    firstname: 'Nombre',
    lastname: 'Apellido',
    language: 'Idioma',
    email: 'Correo electrónico',
    langue: {
      label: 'Elegir un idioma',
      fr: 'Francés',
      en: 'Inglés',
      es: 'Español',
    },
    password: 'Contraseña',
    passwordConfirm: 'Confirmación',
  },
  button: {
    initialize: 'Activar su cuenta',
  },
};
