export default {
  title: {
    period: 'Period',
  },
  input: {
    label: {
      name: 'Name',
      months: 'Months',
      weeklySchedule: 'Weekly planner',
    },
    value: {
      noPeriods: 'No periods created yet',
      always: 'Always (by default)',
    },
  },
  button: {
    cancel: 'Cancel',
    save: 'Save',
  },
  list: {
    item: {
      addPeriod: 'Create a new period',
      openPeriodsPage: 'Go to Periods page',
    },
  },
  text: {
    everyMonth: 'No matter the month',
    allDayEveryday: 'No matter the day or time',
  },
  alert: {
    mismatchedTimeZonesTitle: 'Your time zones mismatch!',
    mismatchedTimeZones:
      'You appear to be in "{{currTZ}}" while this site is in "{{siteTZ}}". Be aware that timestamps bellow are "{{currTZ}}".',
  },
  tooltip: {
    addPeriod: 'add period',
    openPeriodsPage: 'open periods page',
  },
};
