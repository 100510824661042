export default {
  button: {
    register: 'I sign up for the demo',
    close: 'Close',
  },
  text: {
    intro1: 'Sign up for the',
    intro2: 'demo of updates and new features',
    intro3: 'of your Dametis platform!',
    date: 'Tuesday, October 3, from 11am to 12pm',
    team: 'Your Dametis speakers for this demo:',
  },
};
