import { ComponentPropsWithoutRef, FC } from 'react';

export interface SeriesBulletProps extends ComponentPropsWithoutRef<'span'> {
  color?: string;
}

const SeriesBullet: FC<SeriesBulletProps> = ({ color = 'transparent', style, ...props }) => (
  <span style={{ color, fontSize: 14, ...style }} {...props}>
    {'\u25CF'}
  </span>
);

export default SeriesBullet;
