import { AdminPanelSettingsOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

const AdminButton: FC = () => (
  <Button color="icon" component={NavLink} size="small" startIcon={<AdminPanelSettingsOutlined />} to="/admin/variables">
    Admin
  </Button>
);

export default AdminButton;
