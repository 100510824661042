import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface PromptBeforeDeletingProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onDelete: (...args: unknown[]) => unknown | Promise<unknown>;
}

const PromptBeforeDeleting: FC<PropsWithChildren<PromptBeforeDeletingProps>> = ({ open, children = null, setOpen, onDelete }) => {
  const { t } = useTranslation('prompts');

  const [deleting, setDeleting] = useState<boolean>(false);

  const handleCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleDelete = useCallback(async () => {
    try {
      setDeleting(true);
      if (onDelete) {
        await onDelete();
      }
      setDeleting(false);
      setOpen(false);
    } catch (err) {
      console.error(err);
      setDeleting(false);
    }
  }, [setDeleting, onDelete, setOpen]);

  const handlePressEnter = useCallback<NonNullable<DialogProps['onKeyDown']>>(
    event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        void handleDelete();
      }
    },
    [handleDelete],
  );

  return (
    <Dialog open={open} onClose={handleCancel} onKeyDown={handlePressEnter}>
      <DialogTitle>{t('title.beforeDeleting')}</DialogTitle>
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions>
        <Button color="primary" onClick={handleCancel}>
          {t('button.noDelete')}
        </Button>
        <LoadingButton color="error" loading={deleting} onClick={handleDelete}>
          {t('button.yesDelete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PromptBeforeDeleting;
