import { FC, ReactNode } from 'react';
import { Toaster } from 'sonner';

interface Props {
  children?: ReactNode;
}

const SnackBar: FC<Props> = ({ children = null }) => (
  <>
    <Toaster visibleToasts={5} />
    {children}
  </>
);

export default SnackBar;
