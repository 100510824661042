import { GroupTypes } from 'components/Synoptic/types';

import { GridView } from '../../types/grid';

export default {
  title: {
    synoptics: 'Synoptiques',
    allSynoptics: 'Tous les synoptiques',
    mySynoptics: 'Mes synoptiques',
    sharedSynoptics: 'Partagés avec moi',
    privateSynoptics: 'Privés',
    synoptic: 'Synoptique',
    newSynoptic: 'Nouveau synoptique',
    deleteSynoptic: 'Supprimer le synoptique ?',
    editSynoptic: 'Modifier le synoptique',
    duplicateSynoptic: 'Dupliquer le synoptique',
    cellPicker: 'Ajouter un élément',
    device: 'Appareil',
    value: 'Valeur',
    light: "Voyant d'état",
    gauge: 'Jauge',
    text: 'Zone de texte',
    link: 'Lien',
    room: 'Zone',
    image: 'Image personnalisée',
    linkTips: 'Le saviez-vous ?',
    shortcut: 'Raccourci',
    custom: 'Élément personnalisé',
    addLink: 'Ajouter un lien',
    imageVariantWithIndex: 'Variante {{index}}',
    selection: 'Sélection multiple',
    createSynoptic: 'Créer un synoptique',
  },
  subtitle: {
    ports: 'Ports',
    noMatchesFound: 'Aucun élément correspondant',
    imageVariants: 'Variantes',
    data: 'Données',
    plotBands: 'Zones',
  },
  button: {
    newSynoptic: 'Synoptique',
    cancel: 'Annuler',
    create: 'Créer',
    createSynoptic: 'Créer un synoptique vierge',
    createSynopticFromModel: 'Créer un synoptique depuis un autre',
    save: 'Enregistrer',
    edit: 'Modifier',
    rename: 'Renommer',
    delete: 'Supprimer',
    duplicate: 'Dupliquer',
    no: 'Non',
    yes: 'Oui !',
    closeRightPanel: 'Fermer',
    closeMenu: 'Fermer',
    goToConfiguration: 'Configuration',
    goToPlayground: 'Playground',
    uploadImage: 'Envoyer une image...',
    addCustomElement: 'Élément personnalisé',
    addPort: 'Ajouter',
    addStyledRule: 'Ajouter',
    addVariable: 'Ajouter',
    addPlotBand: 'Ajouter',
    share: 'Partager',
    addImageVariant: 'Ajouter',
    editTags: 'Éditer les tags',
    saveStyleConfiguration: 'Liste',
    addStyleConfiguration: 'Liste',
    select: 'Sélectionner',
  },
  input: {
    label: {
      global: 'Global',
      newSynoptic: 'Nom',
      link: 'Liens',
      color: 'Couleur du tuyau',
      deviceName: "Nom de l'appareil",
      deviceColor: 'Couleur',
      valueLabel: 'Nom de la valeur',
      valueLabelDisplay: "Afficher sur l'élément",
      valueStroke: 'Couleur',
      colors: 'Couleurs',
      colorOn: 'État 1',
      colorOff: 'État 0',
      colorUndefined: 'État indéfini',
      valueIcon: 'Icône',
      invert: 'Inverser',
      lightLabel: "Nom du voyant d'état",
      gaugeLabel: 'Nom de la jauge',
      textLabel: 'Contenu',
      textFontSize: 'Taille de la police',
      linkType: 'Type du lien',
      roomLabel: 'Nom de la zone',
      customElementName: "Nom de l'élément*",
      image: 'Image*',
      name: 'Nom',
      shortcut: 'Raccourci',
    },
    placeholder: {
      deviceName: 'Facultatif',
      valueLabel: 'Facultatif',
      valueIcon: 'Facultatif',
      lightLabel: 'Facultatif',
      gaugeLabel: 'Facultatif',
      textLabel: 'Requis',
      roomLabel: 'Facultatif',
      customElementName: 'Requis',
      search: 'Recherche...',
    },
    value: {
      noIcon: 'Aucun',
    },
  },
  error: {
    imageUpload_DEFAULT: "Une erreur est survenue lors de l'importation de votre image. Merci de réessayer.",
    imageUpload_TOO_BIG: 'La taille de votre image est supérieure à 5 Mo. Veuillez réessayer avec une image plus petite.',
    fetchingValueData: 'Erreur en récupérant les données',
    portsError: 'Le pourcentage doit être compris entre 0 et 100',
    nameRequired: "Le nom de l'élément est requis",
  },
  text: {
    [`group_${GroupTypes.ELEMENTS}`]: 'Éléments de base',
    [`group_${GroupTypes.EQUIPMENT}`]: 'Équipements',
    [`group_${GroupTypes.GLOBAL}`]: 'Éléments globaux',
    [`group_${GroupTypes.CUSTOM}`]: 'Mes éléments',
    updatedAt: 'Modifié {{date}}',
    me: 'moi',
    noSynoptics: "Vous n'avez pas encore de synoptique",
    noPorts: 'Pas de port',
    noImageVariants: 'Pas de variante',
    noSelectedData: 'Aucune donnée sélectionnée',
    port: 'Port',
    name: 'Nom',
    type: 'Type',
    tag: 'Tags',
    shortCreatedAt: 'Créé',
    shortUpdatedAt: 'Modifié',
    addCell: 'Éléments',
    sharedBy: 'Partagé par {{name}}',
    ownedBy: 'Appartient à {{name}}',
    link_coldWaterPipe: "Tuyau d'eau froide",
    link_hotWaterPipe: "Tuyau d'eau chaude",
    link_veryHotWaterPipe: "Tuyau d'eau très chaude",
    link_coldAirPipe: "Tuyau d'air froid",
    link_electricCable: 'Câble électrique',
    link_gasPipe: 'Tuyau de gaz',
    link_customPipe: 'Tuyau personalisé',
    device_boiler: 'Chaudière',
    device_chiller: 'Refroidisseur',
    device_counter: 'Compteur',
    device_flowMeter: 'Débitmètre',
    device_heatExchanger: 'Échangeur thermique',
    device_pumpL: 'Pompe (gauche à droite)',
    device_pumpR: 'Pompe (droite à gauche)',
    device_tankV: 'Réservoir',
    device_tankBigV: 'Grand réservoir',
    device_valve: 'Vanne',
    device_valveT: 'Vanne 3 voies',
    device_compressorAbc1: 'Compresseur ABC (1)',
    device_compressorAbc2: 'Compresseur ABC (2)',
    device_compressorAf1: 'Compresseur AF (1)',
    device_compressorAf2: 'Compresseur AF (2)',
    device_sullair: 'Sullair',
    device_airCompressor: 'Compresseur à air',
    device_coolingTowerPhoto: 'Tour de ref. (photo)',
    device_coolingTower: 'Tour de refroidissement',
    device_evaporativeCondenserPhoto: 'Condensateur à évap. (photo)',
    device_evaporativeCondenser: 'Condensateur à évaporation',
    device_steamBoilerPhoto: 'Chaudière à vapeur (photo)',
    device_steamBoilerPhoto2: 'Chaudière à vapeur (photo 2)',
    device_steamBoiler: 'Chaudière à vapeur',
    device_tankH: 'Bâche alimentaire',
    device_tankH2: 'Bouteille BP',
    device_barrel: 'Barrilet',
    device_adsorptionDryer: 'Sécheur par adsorption',
    device_refrigerationDryer: 'Sécheur frigorifique',
    device_transformer: 'Transformateur',
    device_coldCompressor: 'Compresseur froid',
    device_CompressorHPInterior: 'Compresseur HP Intérieur 1',
    device_CompressorHPInterior2: 'Compresseur HP Intérieur 2',
    device_CompressorLP: 'Compresseur BP',
    device_CentrifugalCompressorWithRecovery: 'Compresseur Centrifuge',
    device_CentrifugalCompressorWithoutRecovery: 'Compresseur Centrifuge (Sans récup)',
    device_AbsorptionDryer: 'Sécheur à absorption',
    device_RefrigerationDryer: 'Sécheur frigo',
    device_ColdBottleHorizontal: 'Bouteille Froid Horizontale',
    device_ColdBottleVertical: 'Bouteille Froid Verticale',
    device_PistonCompressorWithConsole: 'Compresseur à piston avec console',
    device_PistonCompressorWithoutConsole: 'Compresseur à piston sans console',
    device_ScrewCompressor: 'Compresseur à vis',
    device_AirCooler: 'Aero refroidisseur',
    device_EvaporativeCondenser: 'Condenseur évaporatif 1',
    device_EvaporativeCondenser2: 'Condenseur évaporatif 2',
    device_ExpansionValve: 'Détendeur',
    device_AirCooledCondenser: 'Condenseur à air',
    device_AirCooledCondenser2: 'Condenseur à air 2',
    device_Chiller: 'Groupe froid 1',
    device_Chiller2: 'Groupe froid 2',
    device_Duct: 'Bâche',
    device_BarrelOneOutlet: 'Barillet 1 Départ',
    device_BarrelTwoOutlets: 'Barillet 2 Départs',
    device_BarrelThreeOutlets: 'Barillet 3 Départs',
    device_BarrelFourOutlets: 'Barillet 4 Départs',
    device_HotWaterBoiler: 'Chaudière eau chaude',
    device_StandardChimneyHalfFace: 'Cheminée Standard demi-face',
    device_StandardChimney: 'Cheminée Standard',
    device_ChimneyWithEco: 'Cheminée avec Eco',
    device_SteamBoilerWithoutChimney: 'Chaudière vapeur sans cheminée',
    device_SteamBoilerWithChimney: 'Chaudière vapeur avec cheminée',
    device_Degasser: 'Dégazeur',
    device_HotWaterHeater: 'Préparateur ECS',
    device_MudPumpRight: 'Pompe à Boue Droite',
    device_MudPumpLeft: 'Pompe à Boue Gauche',
    device_DistributionPumpLeft: 'Pompe Distrib Gauche',
    device_DistributionPumpRight: 'Pompe Distrib Droite',
    device_HorizontalPumpLeft: 'Pompe horizontale Gauche',
    device_NH3Pump: 'Pompe NH3',
    device_VerticalPump: 'Pompe Verticale',
    device_ValveTwoPort: 'Vanne 2V',
    device_ValveThreePort: 'Vanne 3V',
    device_ButterflyValve: 'Vanne Volante',
    device_WaterSoftener: 'Adoucisseur',
    device_WaterMeter: 'Compteur Eau',
    device_GasMeter: 'Compteur Gaz',
    device_OtherMeter: 'Compteur Autre',
    device_FlowMeter: 'Débitmètre',
    device_TubularHeatExchangerLeft: 'Echangeur tubulaire Gauche',
    device_TubularHeatExchangerRight: 'Echangeur tubulaire Droite',
    device_ReverseOsmosisUnit: 'Osmoseur',
    device_SolarPanelInclined: 'Panneau solaire incliné',
    device_SolarPanel: 'Panneau solaire',
    device_Tanks: 'Tanks 1',
    device_Tanks2: 'Tanks 2',
    device_MilkSilo: 'Silo Lait',
    device_BiologicalTreatment: 'Traitement Biologique',
    device_NaturalDischarge: 'Rejet Nature',
    device_Drilling: 'Forage',
    device_Desanding: 'Dessablage',
    device_Screening: 'Dégrillage 1',
    device_Screening2: 'Dégrillage 2',
    device_AirTankVertical: "Réservoir d'air Vertical",
    device_AirTankHorizontal: "Réservoir d'air Horizontal",
    device_Centrifuge: 'Centrigeuse',
    device_Clarification: 'Clarification',
    device_Settling: 'Décantation',
    device_BarrelDepartureAddition: 'Ajout départ Barillet',
    device_StatusIndicatorLight: "Voyant d'état",
    face_Front: '2D',
    face_Side: '3D',
    cell_value: 'Valeur',
    cell_gauge: 'Jauge',
    cell_light: "Voyant d'état",
    cell_text: 'Zone de texte',
    cell_room: 'Zone',
    cell_image: 'Image personnalisée',
    cell_shortcut: 'Raccourci vers ...',
    linkTips:
      "Vous pouvez cliquer sur un lien pour y ajouter un point d'ancrage. Et vous pouvez double-cliquer sur ce point pour le supprimer.",
    icon_water: 'Eau froide',
    icon_hotWater: 'Eau chaude',
    icon_veryHotWater: 'Eau très chaude',
    icon_fire: 'Feu',
    icon_electricity: 'Électricité',
    icon_air: 'Air',
    noStyledRules: 'Pas de règles de style',
    rules: 'Règle',
    copy: 'Copie',
    public: 'Public',
    private: 'Privé',
    shared: 'Partagé',
    view: {
      [GridView.TILE]: 'Vue tuile',
      [GridView.LIST]: 'Vue liste',
    },
    duplicated: ' - Copie',
    cartHelper: 'Vous pouvez facilement ajouter des variables à votre panier en faisant clique droit sur le widget.',
    selectedElements: 'éléments sélectionnés.',
    view_Front: '2D',
    view_Side: '3D',
  },
  form: {
    label: {
      confidentiality: 'Confidentialité',
    },
    value: {
      confidentiality_private: 'Privé',
      confidentiality_shared: 'Partagé',
    },
    tooltip: {
      owner_shared: 'Si cette option est cochée, ce synoptique sera visible par les autres utilisateurs',
      owner_private: 'Si cette option est cochée, ce synoptique sera seulement visible par vous ',
      not_owner: "Vous ne pouvez pas changer la confidentialité de ce synoptique car vous n'en êtes pas le propriétaire",
    },
  },
  tooltip: {
    createSynoptic: 'créer un synoptique',
    editSynoptic: 'modifier le synoptique',
    saveSynoptic: 'sauvegarder le synoptique',
    renameSynoptic: 'renommer le synoptique',
    editTags: 'modifier les tags du synoptique',
    duplicateSynoptic: 'dupliquer le synoptique',
    shareSynoptic: 'partager le synoptique',
    deleteSynoptic: 'supprimer le synoptique',
    undo: 'Annuler',
    redo: 'Refaire',
    toggleGridOn: 'Activer le magnétisme',
    toggleGridOff: 'Désactiver le magnétisme',
    sortZIndexes: "Réinitialiser l'ordre des éléments",
    zoomOut: 'Dézoomer',
    zoomIn: 'Zoomer',
    zoomToFit: 'Maximiser',
    toggleFullScreen: 'Mode plein écran',
    deleteImageVariant: 'Supprimer',
    alreadyInCart: 'Cette variable est déjà dans le panier',
    variableNotValid: 'Variable invalide',
    showPorts: 'Afficher les ports',
    hidePorts: 'Cacher les ports',
    cartButton_zero: 'Panier',
    cartButton_one: 'Panier ({{count}} variable)',
    cartButton_other: 'Panier ({{count}} variables)',
    addStyledRule: 'Ajouter une règle',
    saveStyleConfiguration: 'Enregistrer ces règles dans une liste',
    addStyleConfiguration: 'Utiliser une liste de règles',
    setWidgetGlobal: 'mettre en global un widget',
    cannotAddPlotBand: 'Vous devez renseigner un min et un max valident pour ajouter une zone',
    rotateLeft: 'Rotation à gauche',
    rotateRight: 'Rotation à droite',
    flipVertically: 'Retourner verticalement',
    flipHorizontally: 'Retourner horizontalement',
    moveToBack: 'Mettre au dernier plan',
    moveToBackByOne: 'Reculer',
    moveToFrontByOne: 'Avancer',
    moveToFront: 'Mettre au premier plan',
    clone: 'Cloner',
    delete: 'Supprimer',
    returnLinks: 'Inverser le sens',
  },
  alert: {
    cantEditWhenYet: "Vous pourrez modifier la condition une fois l'élément posé.",
    canNoLongerEditDo: "Vous ne pouvez plus modifier l'image après leur création.",
    reuseImage: 'Votre image sera ajoutée à la liste de vos éléments',
  },
  cart: {
    title: 'Panier',
    variables: 'Variables',
    noVariables: 'Aucune variable',
    button: {
      variable: 'Variable',
      clear: 'Vider le panier',
      open: 'Ouvrir un playground',
    },
  },
  toast: {
    create: {
      success: 'Le synoptique a bien été créé.',
      fail: 'Une erreur est survenue durant la création du synoptique.',
    },
    save: {
      success: 'Le synoptique a bien été enregistré.',
      fail: 'Une erreur est survenue durant la sauvegarde du synoptique.',
    },
    delete: {
      success: 'Le synoptique a bien été supprimé.',
      fail: 'Une erreur est survenue durant la suppression du synoptique..',
    },
    duplicate: {
      success: 'Le synoptique a bien été dupliqué.',
      fail: 'Une erreur est survenue durant la duplication du synoptique.',
    },
  },
  select: {
    view: 'Vue',
    view_Front: '2D',
    view_Side: '3D',
  },
};
