import store from 'store';

export const getBatchPath = (batchUuid: string): string => {
  const { allBatches } = store.getState().batch;
  let path: string[] = [];

  const buildPath = (batches: any[], arr: string[]) => {
    batches.forEach(b => {
      if (b.uuid === batchUuid) {
        path = arr.concat(b.name);
        return true;
      }
      if (b.children.length) {
        return buildPath(b.children, arr.concat(b.name));
      }
      return arr;
    });
  };

  buildPath(allBatches, []);
  return path.join('/');
};
