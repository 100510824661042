import { Stack, StackProps, styled } from '@mui/material';

/**
 @private Use Hotkeys instead
 */
export const KBMs = styled((props: StackProps) => <Stack alignItems="center" direction="row" {...props} />)(() => ({
  display: 'inline-flex',
  fontFamily: "'Ubuntu Mono', monospace",
  fontWeight: 500,
  lineHeight: 1,
}));
