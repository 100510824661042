import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalTypeaheadMenuPlugin, MenuRenderFn } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { Popper } from '@mui/material';
import { TextNode } from 'lexical';
import { FC, useCallback, useMemo, useRef, useState } from 'react';

import { useCommentsContext } from 'components/UI/Comments/context/CommentsContext';

import CollaboratorListItem from './CollaboratorListItem';
import EmptyCollaboratorListItem from './EmptyCollaboratorListItem';
import { $createMentionNode } from './MentionNode';
import { MentionTypeaheadOption } from './config/MentionPluginConfig';
import { getPossibleQueryMatch } from './functions/MentionPluginFunctions';
import { useMentionLookupService } from './useMentionLookupService';

const MentionsPlugin: FC = () => {
  const { collaborators } = useCommentsContext();
  const [editor] = useLexicalComposerContext();
  const [queryString, setQueryString] = useState<string | null>(null);
  const [visibility, setVisibility] = useState<boolean>(false);
  const currentQuery = useRef<string>('');

  const results = useMentionLookupService(queryString, collaborators);

  const options = useMemo(() => results.map(result => new MentionTypeaheadOption(result)), [results]);

  const onSelectOption = useCallback(
    (selectedOption: MentionTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(selectedOption.user);
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }
        closeMenu();
      });
    },
    [editor],
  );

  const menuRenderer = useCallback<MenuRenderFn<MentionTypeaheadOption>>(
    (anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) =>
      anchorElementRef ? (
        <Popper
          anchorEl={anchorElementRef.current}
          open={visibility}
          placement="top-start"
          sx={{
            zIndex: 1300,
            backgroundColor: '#FFF',
            padding: '6px 4px',
            borderRadius: '6px',
            boxShadow: theme => `0 10px 15px -3px ${theme.palette.grey[900]}40, 0 4px 6px -2px ${theme.palette.grey[900]}60`,
            maxHeight: '400px',
            overflow: 'auto',
          }}
        >
          {results.length > 0 || currentQuery.current.length === 0 ? (
            options.map((option, i: number) => (
              <CollaboratorListItem
                key={option.user.uuid}
                index={i}
                isSelected={selectedIndex === i}
                option={option}
                onClick={() => {
                  setHighlightedIndex(i);
                  selectOptionAndCleanUp(option);
                }}
                onMouseEnter={() => {
                  setHighlightedIndex(i);
                }}
              />
            ))
          ) : (
            <EmptyCollaboratorListItem
              search={
                currentQuery.current.length > 12 ? `${currentQuery.current.substring(0, 11)}...` : currentQuery.current.substring(0, 11)
              }
            />
          )}
        </Popper>
      ) : null,
    [options, results.length, visibility],
  );

  const checkForMentionMatch = useCallback((text: string) => {
    setVisibility(false);
    const mentionMatch = getPossibleQueryMatch(text);
    if (mentionMatch) {
      setTimeout(() => {
        setVisibility(true);
        if (currentQuery.current !== mentionMatch.matchingString) {
          currentQuery.current = mentionMatch.matchingString;
        }
      }, 300);
    }
    return mentionMatch ?? null;
  }, []);

  return (
    <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
      menuRenderFn={menuRenderer}
      options={options}
      triggerFn={checkForMentionMatch}
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
    />
  );
};
export default MentionsPlugin;
