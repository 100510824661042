export default {
  title: {
    newMacroLib: 'Nueva biblioteca',
  },
  button: {
    createMacroLib: 'Crear',
    deleteMacroLib: 'Suprimir',
    editMacroLib: 'Modificar',
    saveMacroLib: 'Guardar',
    cancel: 'Cancelar',
  },
  toast: {
    createMacroLibSuccess: 'Biblioteca creada con éxito.',
    createMacroLibError: 'Se produjo un error al crear la biblioteca.',
    saveMacroLibSuccess: 'Cambios guardados.',
    saveMacroLibError: 'Se produjo un error al guardar los cambios.',
    deleteMacroLibSuccess: 'Biblioteca eliminada con éxito.',
    deleteMacroLibError: 'Se produjo un error al vaciar la biblioteca.',
    duplicateMacroLibSuccess: 'Biblioteca duplicada con éxito.',
    duplicateMacroLibError: 'Se produjo un error al duplicar la biblioteca.',
  },
  input: {
    label: {
      name: 'Nombre',
      description: 'Descripción',
    },
  },
};
