import { MenuOption, PUNCTUATION } from '@lexical/react/LexicalTypeaheadMenuPlugin';

import { TinyUserInfo } from '@dametis/core';

export declare type QueryMatch = {
  leadOffset: number;
  matchingString: string;
  replaceableString: string;
};

const NAME = `\\b[A-Z][^\\s${PUNCTUATION}]`;

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
};

export const CapitalizedNameMentionsRegex = new RegExp(`(^|[^#])((?:${DocumentMentionsRegex.NAME}{' + 1 + ',})$)`);

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ['@'].join('');

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = `[^${TRIGGERS}${PUNC}\\s]`;

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS = `(?:\\.[ |$]||[${PUNC}]|)`;

const LENGTH_LIMIT = 75;

export const AtSignMentionsRegex = new RegExp(`(^|\\s|\\()([${TRIGGERS}]((?:${VALID_CHARS}${VALID_JOINS}){0,${LENGTH_LIMIT}}))$`);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
export const AtSignMentionsRegexAliasRegex = new RegExp(`(^|\\s|\\()([${TRIGGERS}]((?:${VALID_CHARS}){0,${ALIAS_LENGTH_LIMIT}}))$`);

export class MentionTypeaheadOption extends MenuOption {
  user: TinyUserInfo;

  constructor(user: TinyUserInfo) {
    super(user.uuid);
    this.user = user;
  }
}
