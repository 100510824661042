import { styled } from '@mui/material';
import { forwardRef } from 'react';

import StyledElevationCard, { StyledElevationCardProps } from '../SelectableCard/StyledElevationCard';

export type RightPanelCardProps = StyledElevationCardProps;

const StyledCard = styled(StyledElevationCard)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  flexDirection: 'column',
  '& > div': {
    width: '100%',
  },
  border: ` 1px solid ${theme.palette.divider}`,
  marginLeft: `-${theme.spacing(1.5)}`,
  marginRight: `-${theme.spacing(1.5)}`,
}));

const RightPanelCard = forwardRef<HTMLDivElement, StyledElevationCardProps>(({ children, title = undefined, ...props }, ref) => (
  <StyledCard ref={ref} title={title} {...props} elevation={0}>
    {children}
  </StyledCard>
));

RightPanelCard.displayName = 'RightPanelCard';

export default RightPanelCard;
