export default {
  title: {
    beforeLeaving: 'Êtes-vous sûr ?',
    beforeDeleting: 'Êtes-vous sûr ?',
    beforeApplying: 'Êtes-vous sûr ?',
    beforeRegen: 'Êtes-vous sûr ?',
  },
  text: {
    beforeLeaving: 'Vous avez des changements non sauvegardés.',
  },
  button: {
    yesLeave: 'Quitter sans sauvegarder',
    noLeave: 'Annuler',
    yesDelete: 'Oui, supprimer',
    noRegen: 'Annuler',
    yesRegen: 'Oui, regénérer',
    noDelete: 'Annuler',
    yesDisable: 'Oui, désactiver',
    noDisable: 'Annuler',
    yesApply: 'Oui, appliquer',
    noApply: 'Annuler',
  },
};
