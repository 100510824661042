import Highcharts from 'highcharts/es-modules/masters/highcharts.src';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en';
import es from './es';
import fr from './fr';
import getLocale from './getLocale';
import { setDefaultLocale } from './localizedDateFns';
import localizedNumber from './localizedNumber';
import { localizedPeriod } from './localizedPeriod';

export { localizedNumber, getLocale, localizedPeriod };

export const defaultNS = 'global';
export const resources = { en, fr, es } as const;

i18next.on('languageChanged', lng => {
  setDefaultLocale(lng);
});

export const i18nInit = i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: Object.keys(resources.en),
    defaultNS,
    resources,
    fallbackLng: ['en', 'fr'],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'],
    },
    debug: process.env.NODE_ENV === 'development',
  });

Highcharts.setOptions({
  lang: {
    shortMonths: [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ].map(month => i18next.t(`global:date.month.${month}`, { context: 'short' })),
    months: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map(
      month => i18next.t(`global:date.month.${month}`),
    ),
    resetZoom: i18next.t('dachart:label.resetZoom'),
  },
});
