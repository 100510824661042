export interface DateTimeFormat {
  value: string;
  available: boolean;
}

export const getAvailableFormats = (): DateTimeFormat[] => [
  { value: 'dd/LL/yyyy HH:mm:ss', available: true },
  { value: 'LL/dd/yyyy HH:mm:ss', available: true },
  { value: 'yyyy-LL-dd HH:mm:ss', available: true },
  { value: 'dd-LL-yyyy HH:mm:ss', available: true },
  { value: 'dd.LL.yyyy HH:mm:ss', available: true },
  { value: 'dd-LLL-yy HH:mm:ss.S', available: true },
];
