import { FormHelperText, Link } from '@mui/material';
import { FC, ReactNode, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CalculationVariable } from '@dametis/core';

import { VncProps } from '../../types';

import NicknameTextField from './NicknameTextField';

interface Props {
  value: VncProps['value'];
  onChange: VncProps['onChange'];
  helperText: ReactNode;
  enableNickname: boolean;
}

const HelperText: FC<Props> = ({ helperText, enableNickname, value, onChange }) => {
  const { t } = useTranslation('vnc');

  const [nickname, setNickname] = useState<CalculationVariable['nickname']>(value?.nickname ?? null);

  const nicknameDebounce = useRef<ReturnType<typeof setTimeout>>();

  const changeNickname = useCallback(
    (newNickname: CalculationVariable['nickname']) => {
      setNickname(newNickname);
      clearTimeout(nicknameDebounce.current);
      nicknameDebounce.current = setTimeout(async () => {
        await onChange({
          ...value,
          nickname: newNickname,
        });
      }, 400);
    },
    [value, onChange],
  );

  const toggleNickname = useCallback(() => {
    setNickname(oldNickname => {
      const res = oldNickname == null ? '' : null;
      clearTimeout(nicknameDebounce.current);
      nicknameDebounce.current = setTimeout(async () => {
        await onChange({
          ...value,
          nickname: res,
        });
      }, 400);
      return res;
    });
  }, [value, onChange]);

  return (
    <div>
      {enableNickname && <NicknameTextField nickname={nickname} onNicknameChange={changeNickname} />}
      {(Boolean(helperText) || enableNickname) && (
        <FormHelperText component="div" variant="outlined">
          {Boolean(helperText) && <>{helperText} </>}
          {enableNickname && ( // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link onClick={toggleNickname}>{t(`input.helperText.${nickname != null ? 'remove' : 'add'}Nickname`)}</Link>
          )}
        </FormHelperText>
      )}
    </div>
  );
};

export default HelperText;
