import { CommentInfo, CreateCommentInfo, Period, UUID, stringToLexicalComment } from '@dametis/core';

import { EntityType } from 'types/comment';

export type EntityFromComment = { uuid: UUID; type: EntityType };

export const createCreateCommentInfo = (
  entity: EntityType,
  entityUuid: UUID,
  period: Period,
  options: Partial<CommentInfo> | undefined = {},
): CreateCommentInfo | null => {
  if (entity === EntityType.REPORT) {
    return {
      ...options,
      reportId: entityUuid,
      message: options.message ?? stringToLexicalComment(''),
      shortcut: options.shortcut ?? null,
      date: options.date ?? new Date(),
      period: { from: period.from.toISOString(), to: period.to.toISOString() },
    };
  }
  if (entity === EntityType.PROJECT) {
    return {
      ...options,
      projectId: entityUuid,
      date: options.date ?? new Date(),
      message: options.message ?? stringToLexicalComment(''),
      shortcut: options.shortcut ?? null,
    };
  }
  if (entity === EntityType.TASK) {
    return {
      ...options,
      date: options.date ?? new Date(),
      taskId: entityUuid,
      message: options.message ?? stringToLexicalComment(''),
      shortcut: options.shortcut ?? null,
    };
  }
  if (entity === EntityType.VARIABLE) {
    return {
      ...options,
      variableId: entityUuid,
      message: options.message ?? stringToLexicalComment(''),
      date: options.date ?? new Date(),
      shortcut: options.shortcut ?? null,
    };
  }
  if (entity === EntityType.ALARM) {
    return {
      ...options,
      alarmId: entityUuid,
      message: options.message ?? stringToLexicalComment(''),
      date: options.date ?? new Date(),
      shortcut: options.shortcut ?? null,
    };
  }
  return null;
};
