import { Components } from '@mui/material';
import { lightBlue } from '@mui/material/colors';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    link: true;
  }
}

export default {
  styleOverrides: {
    root: {
      overflowWrap: 'break-word',
    },
  },
  variants: [
    {
      props: { variant: 'link' },
      style: () => ({
        color: `${lightBlue[600]} !important`,
        backgroundColor: lightBlue[50],
        padding: '2px 5px',
        marginTop: -2,
        marginBottom: -2,
        borderRadius: 4,
        cursor: 'pointer',
        '&:hover': {
          color: `${lightBlue[400]} !important`,
        },
      }),
    },
  ],
} as Components['MuiTypography'];
