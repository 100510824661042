import { Paper, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';

import { BlockTypeConnection } from '@dametis/core';

import { setColorLightness } from 'functions/color';
import { useSelector } from 'store';

import ConnectionPreviewTitle from '../ConnectionPreviewTitle';

export interface BlockTypeConnectionPreviewProps {
  connection: BlockTypeConnection;
}

const BlockTypeConnectionPreview: FC<BlockTypeConnectionPreviewProps> = ({ connection }) => {
  const theme = useTheme();

  const blockTypesById = useSelector(state => state.lego.blockTypesById);

  const blockType = useMemo(() => blockTypesById[connection.blockTypeId], [blockTypesById, connection.blockTypeId]);
  const color = useMemo(() => (blockType ? blockType.content.color : theme.palette.text.primary), [blockType, theme]);

  return (
    <Paper sx={{ background: setColorLightness(color, 93) }}>
      <ConnectionPreviewTitle blockType={blockType} connection={connection} />
    </Paper>
  );
};

export default BlockTypeConnectionPreview;
