import { Box, styled } from '@mui/material';

export enum ResizeHandlerOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export interface ResizeHandlerProps {
  isResizing?: boolean;
  orientation?: `${ResizeHandlerOrientation}`;
}

const ResizeHandler = styled(Box, {
  shouldForwardProp: propName => propName !== 'isResizing' && propName !== 'orientation',
})<ResizeHandlerProps>(({ theme, isResizing = false, orientation = ResizeHandlerOrientation.HORIZONTAL }) => ({
  width: orientation === ResizeHandlerOrientation.VERTICAL ? 4 : '100%',
  height: orientation === ResizeHandlerOrientation.VERTICAL ? '100%' : 4,
  cursor: orientation === ResizeHandlerOrientation.VERTICAL ? 'col-resize' : 'row-resize',
  opacity: isResizing ? 1 : 0,
  transition: theme.transitions.create(['opacity']),
  transitionDelay: '0ms',
  '&:hover': {
    opacity: 1,
    transitionDelay: '250ms',
  },
  background: isResizing ? theme.palette.secondary.main : theme.palette.secondary.light,
}));

export default ResizeHandler;
