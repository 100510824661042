import { Box, Typography } from '@mui/material';
import { FC } from 'react';

interface PeriodLabelProps {
  fromLabel: string;
  fromValue: string;
  toLabel: string;
  toValue: string;
  fontWeight?: number;
}

const PeriodLabel: FC<PeriodLabelProps> = ({ fromLabel, toLabel, fromValue, toValue, fontWeight = 600 }) => {
  return (
    <Box m={1} textAlign="center">
      <Typography variant="caption">
        <Box component="span">{fromLabel}</Box>
        <Typography component="span" fontWeight={fontWeight} variant="caption">
          {` ${fromValue} `}
        </Typography>
        <Box component="span">{toLabel}</Box>
        <Typography component="span" fontWeight={fontWeight} variant="caption">
          {` ${toValue}`}
        </Typography>
      </Typography>
    </Box>
  );
};

export default PeriodLabel;
