import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { MouseEventHandler, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockParameter, CalculationVariable, PhysicalQuantity } from '@dametis/core';
import { UnitConverter } from '@dametis/unit';

import { LegoParameter } from 'components/Lego/types';
import ElementContainer, { ElementContainerProps } from 'components/UI/ElementContainer/ElementContainer';
import GoToPlaygroundIconButton from 'components/UI/GoToPlaygroundButton/GoToPlaygroundIconButton';
import PhysicalQuantityAvatar from 'components/UI/PhysicalQuantity/PhysicalQuantityAvatar';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import { getFormattedValue } from 'components/UI/UnitPicker/functions/getFormattedValue';
import VariableValue from 'components/UI/VariableValue/VariableValue';
import { getPhysicalQuantities } from 'config';
import { calculationToString } from 'functions/calculationToString';
import { setColorLightness } from 'functions/color';

export interface LegoParameterPreviewProps<T extends LegoParameter = LegoParameter> extends ElementContainerProps {
  parameter: Partial<Omit<T, 'blockKey'>> & Pick<T, 'blockKey'>;
  displayCalculation?: boolean;
  playgroundCalculation?: CalculationVariable;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
  onDelete?: MouseEventHandler<HTMLButtonElement>;
}

const LegoParameterPreview = <T extends LegoParameter = LegoParameter>({
  parameter,
  displayCalculation = false,
  playgroundCalculation = undefined,
  onEdit = undefined,
  onDelete = undefined,
  disabled,
  ...props
}: LegoParameterPreviewProps<T>) => {
  const { t } = useTranslation('lego');
  const theme = useTheme();

  const displayActions = useMemo(
    () => !!onEdit || !!onDelete || playgroundCalculation !== undefined,
    [onEdit, onDelete, playgroundCalculation],
  );

  const physicalQuantities = useMemo(() => getPhysicalQuantities(t, theme), [t, theme]);

  const physicalQuantity = useMemo(
    () => UnitConverter.Parse(parameter.unit ?? null)?.physicalQuantity ?? PhysicalQuantity.NONE,
    [parameter.unit],
  );

  const physicalQuantityColor = useMemo(() => physicalQuantities[physicalQuantity]?.color, [physicalQuantities, physicalQuantity]);

  const calculations = useMemo(() => (playgroundCalculation ? [playgroundCalculation] : null), [playgroundCalculation]);

  const title = useMemo(() => {
    if (parameter.unit && parameter.unit.length > 0) {
      return `${parameter.blockKey} (${parameter.unit})`;
    }
    return parameter.blockKey;
  }, [parameter]);

  const formattedDefaultValue = useMemo(
    () =>
      parameter.defaultValue !== undefined && parameter.defaultValue !== null
        ? getFormattedValue({ userUnit: parameter.unit, value: parameter.defaultValue })
        : undefined,
    [parameter],
  );

  return (
    <ElementContainer color={physicalQuantityColor} disabled={disabled} {...props}>
      <Stack alignItems="center" direction="row" spacing={1}>
        <PhysicalQuantityAvatar
          physicalQuantity={physicalQuantity}
          size="small"
          sx={{ bgcolor: disabled ? 'grey.1400' : physicalQuantityColor }}
        />
        <Stack alignItems="center" direction="row">
          <TypographyEllipse sx={{ color: disabled ? 'grey.1400' : setColorLightness(physicalQuantityColor, 30) }} variant="h6">
            {title}
          </TypographyEllipse>
        </Stack>
        {formattedDefaultValue && (
          <VariableValue noWrap sx={{ my: 0, ml: 'auto' }}>
            {formattedDefaultValue}
          </VariableValue>
        )}
      </Stack>
      {displayCalculation && (
        // SLE: here do a IsBlockParameter, IsStandardParameter and IsBlockTypeParameter
        <TypographyEllipse>{calculationToString((parameter as unknown as BlockParameter).calculation)}</TypographyEllipse>
      )}
      {displayActions && (
        <Stack alignItems="center" className="actions" direction="row" spacing={0.5}>
          {calculations && (
            <Tooltip placement="top" title={t('tooltip.seeCalculation')}>
              <GoToPlaygroundIconButton calculations={calculations} tooltipProps={{ placement: 'top' }} />
            </Tooltip>
          )}
          {onEdit && (
            <Tooltip title={t('tooltip.edit')}>
              <IconButton size="small" onClick={onEdit}>
                <EditOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {onDelete && (
            <Tooltip title={t('tooltip.delete')}>
              <IconButton size="small" onClick={onDelete}>
                <DeleteOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      )}
    </ElementContainer>
  );
};

export default LegoParameterPreview;
