import { PrimaryEnergies } from '@dametis/core';

export default {
  contract: 'Contrato "{{name}}"',
  contracts: 'Contratos',
  bill: 'Factura "{{name}}"',
  bills: 'Facturas',
  button: {
    undo: 'Cancelar',
    redo: 'Restaurar',
    close: 'Cerrar',
    save: 'Guardar',
    addFirstElem: 'Añadir un elemento',
    addPeriod: 'Añadir un período',
    addValidationFormula: 'Añadir una fórmula de validación',
    addContract: 'Contrato',
    refresh: 'Actualizar',
    no: 'No',
    yes: 'Sí',
    deselectAll: 'Deseleccionar todo',
    cancel: 'Cancelar',
    addBill: 'Factura',
    add: 'Añadir',
    uploadBill: 'Descargar la factura',
    uploadContract: 'Descargar el contrato',
    edit: 'Modificar',
    seeRelatedContract: 'Ver contrato asociado',
    seeRelatedBills: 'Ver facturas asociadas',
    confirm: 'Confirmar',
    addPricing: 'Añadir una tarifa',
  },
  energy: {
    [PrimaryEnergies.ELECTRICITY]: 'Electricidad',
    [PrimaryEnergies.GAS]: 'Gas',
    [PrimaryEnergies.WATER]: 'Agua',
  },
  text: {
    billingStrategy: 'Estrategia de facturación',
    informations: 'Informaciones',
    general: 'General',
    contract: 'Contrato',
    validation: 'Validación',
    absolutTime: 'Absoluto',
    averageTime: 'Medio',
    count: 'Número',
    integral: 'Integral',
    maximum: 'Máximo',
    VALID: 'Válido',
    INVALID: 'Inválido',
    TO_VALIDATE: 'Por validar',
    unableToDeleteContract: 'No puedes vaciar este contrato porque tiene facturas asociadas.',
    deleteContract: 'Vaciar contrato',
    pricing: 'Tarifas',
    noSelectedPricingMethod: 'Ninguna tarifa seleccionada',
    noPricing: 'Sin tarifa',
    noAsset: 'Sin documento',
  },
  title: {
    newContract: 'Nuevo contrato',
    deleteContract: 'Vaciar contrato',
    contracts: 'Contratos',
    newBill: 'Nueva factura',
    bills: 'Facturas',
    asset: 'Documento',
    general: 'General',
    period: 'Períodos',
    validation: 'Validación',
    description: 'Descripción',
    home: 'Elija el tipo de energía:',
    warning: 'Advertencia',
  },
  input: {
    label: {
      name: 'Nombre',
      periodName: 'Nombre del período',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de fin',
      energy: 'Energía',
      provider: 'Proveedor',
      search: 'Buscar',
      color: 'Color',
      period: 'Período',
      pricings: 'Tarifas',
      overload: 'Exceso',
      overloadTime: 'Tiempo de exceso',
      billedConsumption: 'Consumo facturado',
      billedPrice: 'Precio facturado',
      description: 'Descripción',
      billAsset: 'Factura',
      contractName: 'Nombre del contrato',
      validated: 'Validado',
      billId: 'Número de factura',
      totalPrice: 'Monto de la factura',
      validation: 'Validación',
      total: 'Total',
      clientId: 'ID de cliente',
      deliveryPoint: 'Punto de suministro',
      mainFormula: 'Fórmula principal',
      formula: 'Fórmula',
      unit: 'Unidad',
      price: 'Precio',
      contractPower: {
        value: 'Potencia contratada',
      },
      validationState: 'Estado',
      optional: 'Opcional',
    },
    placeholderEnergies: 'Sin energía seleccionada',
    search: 'Buscar...',
    energyType: 'Tipo de Energía',
    contract: 'Contrato',
  },
  prompt: {
    deleteBill: 'Vaciar',
  },
  table: {
    cell: {
      name: 'Nombre',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de fin',
      delete: 'Vaciar',
      energy: 'Energía',
      dates: 'Fechas',
      provider: 'Proveedor',
      billingPeriod: 'Período facturado',
      measuredConsumption: 'Consumo medido',
      billedConsumption: 'Consumo facturado',
      contractName: 'Nombre del contrato',
      totalBilledConsumption: 'Consumo facturado',
      totalMeasuredConsumption: 'Consumo medido',
      validationState: 'Estado',
      id: 'Número de factura',
      contractNameUnknown: 'Nombre de contrato desconocido',
      duplicatePeriod: 'Duplicar período',
      totalPrice: 'Monto',
    },
  },
  tooltip: {
    createBill: 'Crear una factura',
    editBill: 'Modificar la factura',
    deleteBill: 'Vaciar la factura',
    createContract: 'Crear un contrato',
    editContract: 'Modificar el contrato',
    deleteContract: 'Vaciar el contrato',
    deletePeriod: 'Vaciar período',
    duplicatePeriod: 'Duplicar período',
    validationDelta: 'Diferencia con la fórmula de validación principal (n°1)',
    deleteRule: 'Vaciar fórmula',
    editPricing: 'Editar tarifa',
    deletePricing: 'Vaciar tarifa',
  },
  xGrid: {
    period: { noData: 'Aucune période' },
    noContract: 'Ningún contrato',
    totalContracts: 'Número de contratos',
    noBill: 'Ninguna factura',
    totalBills: 'Número de facturas',
  },
  alert: {
    mismatchedTimeZonesTitle: '¡Sus zonas horarias son diferentes!',
    mismatchedTimeZones:
      'Está en "{{currTZ}}" mientras que el sitio está en "{{siteTZ}}". Tenga en cuenta que los horarios a continuación están en "{{currTZ}}".',
    weekNotCovered: 'Debe ingresar una tarificación para cada hora de la semana para poder crear un período',
    weekNotCoveredTitle: 'Semana no cubierta',
    incompletePeriodsTitle: 'Períodos incompletos',
    incompletePeriod: 'Los períodos deben cubrir toda la duración del contrato',
  },
  error: {
    dateOrder: 'Fechas inválidas',
    datesNotInContractDateRange: 'Las fechas no están dentro del intervalo del contrato',
    datesOverlap: 'Las fechas se superponen',
    colorAlreadyExists: 'Color ya utilizado',
    required: 'Requerido',
    nameAlreadyExists: 'Nombre ya utilizado',
  },
  helper: {
    PCS: 'PCS', // Pouvoir Calorifique Supérieur
    cannotEditDates: 'No puede editar las fechas porque hay facturas asociadas a este contrato.',
    cannotEditDatesBecausePeriods: 'No puede editar las fechas porque hay períodos asociados.',
    cannotEditPeriods: 'No puede editar los períodos porque hay facturas asociadas a este contrato',
    cannotEditValidation: 'No puede editar las fórmulas porque hay facturas asociadas a este contrato',
  },
  warning: {
    editBill:
      'Los datos de consumo medido se actualizarán y pueden ser diferentes de los registrados en caso de que la configuración haya sido modificada.',
  },
  pricingTemplates: {
    PT: 'Punta',
    OPT: 'Valle',
    ST: 'Punta de temporada alta',
    WPT: 'Punta de temporada baja',
    WOPT: 'Horas punta de temporada alta',
    SPT: 'Horas valle de temporada alta',
    SOPT: 'Horas punta de temporada baja',
  },
};
