const parseErrors = response => {
  if (!response) return {};
  const { data } = response;
  if (!data) return {};
  const { details } = data;
  if (!Array.isArray(details)) return data;
  return details.reduce((acc, { context, message }) => ({ ...acc, [context ? context.key : 'error']: message }), {});
};

export default parseErrors;
