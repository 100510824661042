import { dia, shapes } from '@dametis/rappid';

export enum GroupTypes {
  ELEMENTS = 'ELEMENTS',
  EQUIPMENT = 'EQUIPMENT',
  GLOBAL = 'GLOBAL',
  CUSTOM = 'CUSTOM',
}

export interface GroupConfig {
  type: GroupTypes;
}

export interface Stencil {
  name: keyof typeof shapes.stencil;
  attrs: dia.Cell.Selectors;
  group: GroupTypes;
}

export interface PortConfig {
  group: string;
  args: Record<string, any>;
}

export interface PortWithPortConfig extends dia.Element.Port {
  port: PortConfig;
}

export interface SizeConfig {
  width: number;
  height: number;
}

export interface DeviceConfig {
  ports: PortWithPortConfig[];
  img: string;
  size: SizeConfig;
}

export interface StencilWithDevice<D = DeviceConfig> extends Stencil {
  device?: D;
}

export type StencilGroups = Record<GroupTypes, StencilWithDevice[]>;
