import { Components, Theme } from '@mui/material';

import { LIST_PADDING } from './MuiList';

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
    }),
    dense: {
      paddingTop: LIST_PADDING / 2,
      paddingBottom: LIST_PADDING / 2,
    },
  },
} as Components<Theme>['MuiListItemButton'];
