import { Box, Popover, PopoverProps } from '@mui/material';
import { FC, useMemo } from 'react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';

import { CalculationVariable, PartialCalculationVariable } from '@dametis/core';

import { RenderElement } from 'components/VNC/components/Title/Textarea/RenderElement';
import { RenderLeaf } from 'components/VNC/components/Title/Textarea/RenderLeaf';
import { withCustomElements } from 'components/VNC/slate';
import { tadaToSlate } from 'components/VNC/slate/tada';

export interface CalculationPopoverProps extends Omit<PopoverProps, 'children'> {
  calculation: CalculationVariable | PartialCalculationVariable;
}

export interface CalculationSlateProps {
  calculation: CalculationVariable | PartialCalculationVariable;
}

export const CalculationSlate: FC<CalculationSlateProps> = ({ calculation }) => {
  const slate = useMemo(() => tadaToSlate(calculation as CalculationVariable), [calculation]);
  const editor = useMemo(() => withCustomElements(withHistory(withReact(createEditor())), false), []);

  return (
    <Slate editor={editor} initialValue={slate}>
      <Editable readOnly renderElement={RenderElement} renderLeaf={RenderLeaf} />
    </Slate>
  );
};

const CalculationPopover: FC<CalculationPopoverProps> = ({ calculation, ...props }) => (
  <Popover
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    {...props}
  >
    <Box p={2} sx={{ maxWidth: 600 }}>
      <CalculationSlate calculation={calculation} />
    </Box>
  </Popover>
);

export default CalculationPopover;
