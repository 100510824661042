import { ComponentPropsWithoutRef, FC } from 'react';

export interface SeriesArrowProps extends ComponentPropsWithoutRef<'span'> {
  color?: string;
}

const SeriesArrow: FC<SeriesArrowProps> = ({ color = 'transparent', style, ...props }) => (
  <span style={{ color, fontSize: 16, ...style }} {...props}>
    {'\u2192'}
  </span>
);

export default SeriesArrow;
