import { createContext, useContext } from 'react';
import { createStore, useStore } from 'zustand';

import { NavbarActions, createActions } from 'zustand/actions/layout';
import { NavbarState, initialState } from 'zustand/states/layout';

export type LayoutStore = NavbarState & NavbarActions;

export const createLayoutStore = () =>
  createStore<LayoutStore>(setState => ({
    ...initialState,
    ...createActions(setState),
  }));

export const LayoutStoreContext = createContext(createLayoutStore());

export const useLayoutStore = <U>(selector: (state: LayoutStore) => U) => {
  const store = useContext(LayoutStoreContext);
  return useStore(store, selector);
};
