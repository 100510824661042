import { SettingsOutlined } from '@mui/icons-material';
import { FormLabel } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { To } from 'react-router-dom';

import { DataVariable } from '@dametis/core';

import LinkButton from '../../../../UI/Buttons/LinkButton/LinkButton';

interface Props {
  variable: Partial<DataVariable>;
}

const DataVariableDetails: FC<Props> = ({ variable }) => {
  const { t } = useTranslation('vnc');

  const linkTo: To = useMemo(() => {
    return `/configuration/variables?selectedVariables=${variable.variableUuid}`;
  }, [variable]);

  return (
    <div>
      <FormLabel>{t('input.label.openIn')}</FormLabel>
      <div>
        <LinkButton size="small" startIcon={<SettingsOutlined />} to={linkTo}>
          {t('playground:button.configuration')}
        </LinkButton>
      </div>
    </div>
  );
};

export default DataVariableDetails;
