import { TFunction } from 'i18next';

import { EntitiesByCategory, ExplorerItem, IsAvailableShortcutCategory, IsExplorerFolder, IsExplorerShortcut } from './types';

export const getExplorerItemName = (item: ExplorerItem, entitiesByCategory: EntitiesByCategory, t: TFunction): string => {
  if (IsExplorerFolder(item)) {
    return item.content.name;
  }
  if (IsExplorerShortcut(item) && item.content.uuid && IsAvailableShortcutCategory(item.content.category)) {
    return entitiesByCategory[item.content.category]?.[item.content.uuid]?.name ?? t('lego:text.unknownName');
  }
  return t('lego:text.unknownName');
};
