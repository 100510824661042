import { ListItemButton, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import UserAvatar from 'components/UI/UserAvatar/UserAvatar';

import { MentionTypeaheadOption } from './config/MentionPluginConfig';

interface Props {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: MentionTypeaheadOption;
}

const CollaboratorListItem: FC<Props> = ({ index, isSelected, onClick, onMouseEnter, option }) => (
  <ListItemButton
    key={option.key}
    ref={option.setRefElement}
    aria-selected={isSelected}
    id={`typeahead-item-${index}`}
    role="option"
    selected={isSelected}
    tabIndex={-1}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
  >
    <Stack alignItems="center" direction="row" spacing={1}>
      <UserAvatar user={option.user} />
      <Typography noWrap mr={1} variant="body1">{`${option.user.firstName} ${option.user.lastName}`}</Typography>
    </Stack>
  </ListItemButton>
);

export default CollaboratorListItem;
