import { Accordion, AccordionProps, AccordionSummary, Box, Chip, Stack, Tooltip, Typography, accordionSummaryClasses } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PromiseLimit, TagInfo, UUID } from '@dametis/core';

import Counter from 'components/UI/Counter/Counter';
import SearchInput from 'components/UI/SearchInput/SearchInput';
import { useDispatch, useSelector } from 'store';
import { useLazyReadTagsQuery } from 'store/api/tags';
import { useVncStore } from 'zustand/stores/vnc';

import { FilterElementProps } from './Filters';
import TagsFiltersBlock from './TagsFiltersBlock';
import { FilterAccordion, FilterAccordionDetails, FilterAccordionSummaryStack, FilterListSubheader } from './styled';

interface TagInfoWithSiteId extends TagInfo {
  siteId: UUID;
}

const TagsFilters: FC<FilterElementProps> = ({ expanded, setExpanded, accordionsNumber }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('vnc');

  const tagsFilters = useVncStore(state => state.filters.tags);
  const getResults = useVncStore(state => state.getResults);
  const setFilterValue = useVncStore(state => state.setFilterValue);

  const sites = useSelector(state => state.auth.selectedGroup!.sites);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);
  const [readTags] = useLazyReadTagsQuery();

  const [tags, setTags] = useState<TagInfoWithSiteId[]>([]);
  const [search, setSearch] = useState<string>('');
  const [isSelectionExpanded, setIsSelectionExpanded] = useState<boolean>(false);

  const selectedTags = useMemo(() => tags.filter(tag => tagsFilters[tag.uuid] === true), [tags, tagsFilters]);

  const handleChangeExpanded = useCallback<NonNullable<AccordionProps['onChange']>>(
    (_event, isExpanded) => {
      setExpanded(isExpanded ? 'tags' : false);
      setIsSelectionExpanded(false);
    },
    [setExpanded],
  );

  const makeToggleTag = useCallback(
    (tagId: UUID, checked: boolean) => async () => {
      setFilterValue('tags', tagId, checked);
      await dispatch(getResults());
    },
    [dispatch, getResults, setFilterValue],
  );

  const handleSearch = useCallback((newSearch: string) => {
    setSearch(newSearch);
  }, []);

  const displayAllTagFilters = useCallback(() => {
    setIsSelectionExpanded(true);
  }, []);

  const getTags = useCallback(
    async (targetSiteId: UUID) => {
      const newTags = await readTags({ siteId: targetSiteId }).unwrap();
      return newTags
        .toSorted((tag1, tag2) => tag1.name.toLocaleLowerCase().localeCompare(tag2.name.toLocaleLowerCase()))
        .map(tag => ({ ...tag, siteId: targetSiteId }));
    },
    [readTags],
  );

  useEffect(() => {
    void (async () => {
      if (!siteId) {
        try {
          const newTags = (await PromiseLimit.do(sites, site => getTags(site.uuid))).flat();
          setTags(newTags);
        } catch (error) {
          console.error(error);
          setTags([]);
        }
      } else {
        try {
          const newTags = await getTags(siteId);
          setTags(newTags);
        } catch (error) {
          console.error(error);
          setTags([]);
        }
      }
    })();
  }, [getTags, siteId, sites]);

  return (
    <FilterAccordion expanded={expanded} filterAccordionsNumber={accordionsNumber} onChange={handleChangeExpanded}>
      <AccordionSummary>
        <FilterAccordionSummaryStack>
          <FilterListSubheader>{t('accordion.tags')}</FilterListSubheader>
          {selectedTags.length > 0 && <Counter count={selectedTags.length} />}
        </FilterAccordionSummaryStack>
      </AccordionSummary>
      <FilterAccordionDetails>
        {(!siteId ? sites.length === 0 : tags.length === 0) ? (
          <Typography align="center" p={1} pt={0} variant="subtitle2">
            {t('text.noTagFilter')}
          </Typography>
        ) : (
          <Stack spacing={1}>
            <Stack px={2} spacing={1}>
              <SearchInput extendable={false} placeholder={t('input.placeholder.searchTag')} value={search} onChange={handleSearch} />
              {selectedTags.length === 0 && (
                <Typography alignContent="center" height={24} variant="subtitle2">
                  {t('text.noDisplayedTagFilters')}
                </Typography>
              )}
              {selectedTags.length > 0 &&
                (!isSelectionExpanded ? (
                  <Stack direction="row" spacing={1} width={1}>
                    <Chip
                      key={selectedTags[0].uuid}
                      label={selectedTags[0].name}
                      size="small"
                      sx={{ flexGrow: 1, maxWidth: 'fit-content', width: 0 }}
                      onDelete={makeToggleTag(selectedTags[0].uuid, false)}
                    />
                    {selectedTags.length > 1 && (
                      <Tooltip
                        placement="right"
                        title={selectedTags
                          .slice(1)
                          .map(tag => tag.name)
                          .join(', ')}
                      >
                        <Chip label={`+${selectedTags.length - 1}`} size="small" sx={{ fontSize: '12px' }} onClick={displayAllTagFilters} />
                      </Tooltip>
                    )}
                  </Stack>
                ) : (
                  <Stack direction="row" flexWrap="wrap" spacing={1} width={1}>
                    {selectedTags.map(tag => (
                      <Chip key={tag.uuid} label={tag.name} size="small" onDelete={makeToggleTag(tag.uuid, false)} />
                    ))}
                  </Stack>
                ))}
            </Stack>
            {!siteId ? (
              <Box sx={{ px: 1, pb: 1 }}>
                {sites.map(site => {
                  const selectedTagsCount = selectedTags.filter(tag => tag.siteId === site.uuid).length;
                  return (
                    <Accordion key={site.uuid} elevation={1}>
                      <AccordionSummary
                        sx={{
                          pl: 2,
                          pr: 1,
                          minHeight: 'unset',
                          [`&.${accordionSummaryClasses.expanded}`]: {
                            minHeight: 'unset',
                          },
                          [`.${accordionSummaryClasses.content}`]: {
                            my: 1,
                          },
                          [`.${accordionSummaryClasses.content}.${accordionSummaryClasses.expanded}`]: {
                            my: 2,
                          },
                        }}
                      >
                        <FilterAccordionSummaryStack>
                          {site.name}
                          {selectedTagsCount > 0 && <Counter count={selectedTagsCount} />}
                        </FilterAccordionSummaryStack>
                      </AccordionSummary>
                      <FilterAccordionDetails>
                        <TagsFiltersBlock makeToggleTag={makeToggleTag} search={search} siteId={site.uuid} />
                      </FilterAccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            ) : (
              <TagsFiltersBlock makeToggleTag={makeToggleTag} search={search} siteId={siteId} />
            )}
          </Stack>
        )}
      </FilterAccordionDetails>
    </FilterAccordion>
  );
};

export default TagsFilters;
