import { BookmarkAddOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSlate } from 'slate-react';

import { CreateAliasBody, IsEmptyCalculation, NewUUIDv5 } from '@dametis/core';

import { calculationToString } from 'functions/calculationToString';
import makePermissionLabel from 'functions/makePermissionLabel';
import { openReplayEvent } from 'functions/openReplay';
import { usePermission } from 'hooks/usePermission';
import { useSelector } from 'store';
import { useVncStore } from 'zustand/stores/vnc';

import { PropsContext } from '../../context';
import { useInsertVariable } from '../../hooks';
import { slateToTada } from '../../slate/tada';
import { OpenReplayEvent } from '../../types';
import AliasDialog, { AliasDialogProps } from '../List/HistoryAndAliasesList/AliasDialog';
import { db } from '../List/HistoryAndAliasesList/db';

interface Props {
  setOpen: (open: boolean) => void;
}

const Actions: FC<Props> = ({ setOpen }) => {
  const { t } = useTranslation('vnc');
  const editor = useSlate();

  const calcVarProps = useVncStore(state => state.calcVarProps);
  const selection = useVncStore(state => state.selection);
  const isCalculationValid = useVncStore(state => state.isCalculationValid);
  const insertVariable = useInsertVariable();

  const site = useSelector(state => state.auth.selectedSite?.uuid ?? 'null');
  const group = useSelector(state => state.auth.selectedGroup?.uuid ?? 'null');
  const isCorpo = useSelector(state => !state.auth.selectedSite);

  const canEditAlias = usePermission('canEditAlias');

  const {
    multiple,
    standardBlockMetricMode,
    onSelectionChange,
    onChange,
    calculatedVariableMode,
    defaultVariableOperator,
    sourceCategory,
    sourceUuid,
    disableCreateAlias,
  } = useContext(PropsContext);

  const alias = useMemo<CreateAliasBody>(
    () => ({
      name: '',
      calculation: slateToTada(editor.children, { calcVarProps }),
      source: { category: sourceCategory, uuid: sourceUuid ?? undefined },
    }),
    [calcVarProps, editor.children, sourceCategory, sourceUuid],
  );
  const calculationIsEmpty = useMemo(() => IsEmptyCalculation(alias.calculation), [alias.calculation]);

  const [submitting, setSubmitting] = useState(false);
  const [openAlias, setOpenAlias] = useState(false);

  const cancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const submit = useCallback(async () => {
    setSubmitting(true);
    try {
      if (multiple && selection.length) {
        openReplayEvent(OpenReplayEvent.VNC_SUBMIT_WITH_MULTISELECT);
        await onSelectionChange?.(selection);
      } else {
        const { children } = editor;
        const tada = slateToTada(children, { calcVarProps });
        openReplayEvent(OpenReplayEvent.VNC_SUBMIT_WITHOUT_MULTISELECT);
        await onChange(tada);
        if (!IsEmptyCalculation(tada) && !standardBlockMetricMode) {
          await db.history.put({
            id: NewUUIDv5(calculationToString(tada, true, true)),
            slate: children,
            group,
            site,
            createdAt: new Date(),
          });
        }
      }
      setSubmitting(false);
      setOpen(false);
    } catch (e) {
      console.error(e);
      setSubmitting(false);
    }
  }, [multiple, selection, setOpen, onSelectionChange, editor, calcVarProps, onChange, standardBlockMetricMode, group, site]);

  const openAliasDialog = useCallback(() => {
    setOpenAlias(true);
  }, []);

  const handleCreateAliasSuccess = useCallback<NonNullable<AliasDialogProps['onSuccess']>>(
    newAlias => {
      insertVariable({ aliasUuid: newAlias.uuid }, true, defaultVariableOperator ?? undefined, calculatedVariableMode);
    },
    [calculatedVariableMode, defaultVariableOperator, insertVariable],
  );

  return (
    <DialogActions>
      {Boolean(selection.length) && <Typography sx={{ mr: 1 }}>{t('text.selectedVariables', { count: selection.length })}</Typography>}
      <Button onClick={cancel}>{t('button.cancel')}</Button>
      {!disableCreateAlias && !isCorpo && (
        <>
          <Tooltip title={makePermissionLabel(t('tooltip.createAlias'), canEditAlias)}>
            <span>
              <Button
                disabled={calculationIsEmpty || !isCalculationValid || !canEditAlias}
                startIcon={<BookmarkAddOutlined />}
                onClick={openAliasDialog}
              >
                {t('button.createAlias')}
              </Button>
            </span>
          </Tooltip>
          <AliasDialog alias={alias} open={openAlias} setOpen={setOpenAlias} onSuccess={handleCreateAliasSuccess} />
        </>
      )}
      <LoadingButton
        color="secondary"
        disabled={!selection.length && !isCalculationValid}
        loading={submitting}
        variant="contained"
        onClick={submit}
      >
        {t('button.ok')}
      </LoadingButton>
    </DialogActions>
  );
};

export default Actions;
