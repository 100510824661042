// GLOBAL PERIOD

export const UPDATE_PERIOD = 'UPDATE_PERIOD';
export const UPDATE_DATA_PERIOD = 'UPDATE_DATA_PERIOD';
export const UPDATE_GROUPBY = 'UPDATE_GROUPBY';
export const UPDATE_USER_GROUPBY = 'UPDATE_USER_GROUPBY';
export const UPDATE_MAX_GROUPBY = 'UPDATE_MAX_GROUPBY';
export const UNDO_PERIOD = 'UNDO_PERIOD';
export const REDO_PERIOD = 'REDO_PERIOD';
export const CLEAR_PERIOD = 'CLEAR_PERIOD';

// PLAYGROUND
export const PG_SET_PLAYGROUND = 'PG_SET_PLAYGROUND';
export const PG_SET_EDIT = 'PG_SET_EDIT';
export const PG_SET_EDITING_MODE = 'PG_SET_EDITING_MODE';
export const PG_CLEAR_PLAYGROUND = 'PG_CLEAR_PLAYGROUND';
export const PG_CLEAR_STORE = 'PG_CLEAR_STORE';
export const PG_UPDATE_PLAYGROUND = 'PG_UPDATE_PLAYGROUND';
export const PG_SET_SELECTED_TAB = 'PG_SET_SELECTED_TAB';
export const PG_ADD_TAB = 'PG_ADD_TAB';
export const PG_REMOVE_TAB = 'PG_REMOVE_TAB';
export const PG_UPDATE_TAB = 'PG_UPDATE_TAB';
export const PG_SET_SAVING = 'PG_SET_SAVING';
export const PG_UPDATE_PANEL_OPEN = 'PG_UPDATE_PANEL_OPEN';
export const PG_SET_TEMPLATE = 'PG_SET_TEMPLATE';
export const PG_SET_PLAYGROUND_BLOCK = 'PG_SET_PLAYGROUND_BLOCK';
export const PG_OPEN_COMMENT = 'PG_OPEN_COMMENT';
export const PG_EDIT_COMMENT = 'PG_EDIT_COMMENT';

export const PG_CHART_SET_DACHART = 'PG_CHART_SET_DACHART';
export const PG_CHART_SET_TABLE = 'PG_CHART_SET_TABLE';

export const PG_TYCHART_ADD_GROUP = 'PG_TYCHART_ADD_GROUP';
export const PG_TYCHART_DELETE_GROUP = 'PG_TYCHART_DELETE_GROUP';
export const PG_TYCHART_UPDATE_GROUP = 'PG_TYCHART_UPDATE_GROUP';
export const PG_TYCHART_UPDATE_GROUPS = 'PG_TYCHART_UPDATE_GROUPS';
export const PG_TYCHART_ADD_VARIABLE = 'PG_TYCHART_ADD_VARIABLE';
export const PG_TYCHART_DELETE_VARIABLE = 'PG_TYCHART_DELETE_VARIABLE';
export const PG_TYCHART_UPDATE_VARIABLE = 'PG_TYCHART_UPDATE_VARIABLE';

export const PG_TABLE_ADD_VARIABLE = 'PG_TABLE_ADD_VARIABLE';
export const PG_TABLE_DELETE_VARIABLE = 'PG_TABLE_DELETE_VARIABLE';
export const PG_TABLE_UPDATE_VARIABLE = 'PG_TABLE_UPDATE_VARIABLE';
export const PG_TABLE_UPDATE_GROUPBY = 'PG_TABLE_UPDATE_GROUPBY';
export const PG_TABLE_SET_MACRO = 'PG_TABLE_SET_MACRO';
export const PG_TABLE_UPDATE_BATCHTARGET = 'PG_TABLE_UPDATE_BATCHTARGET';
export const PG_TABLE_SET_LOADING = 'PG_TABLE_SET_LOADING';

export const PG_LIVECHART_UPDATE_CHART = 'PG_LIVECHART_UPDATE_CHART';
export const PG_LIVECHART_ADD_VARIABLE = 'PG_LIVECHART_ADD_VARIABLE';
export const PG_LIVECHART_DELETE_VARIABLE = 'PG_LIVECHART_DELETE_VARIABLE';
export const PG_LIVECHART_UPDATE_VARIABLE = 'PG_LIVECHART_UPDATE_VARIABLE';

export const PG_BARCHART_ADD_VARIABLE = 'PG_BARCHART_ADD_VARIABLE';
export const PG_BARCHART_DELETE_VARIABLE = 'PG_BARCHART_DELETE_VARIABLE';
export const PG_BARCHART_UPDATE_GROUPBY = 'PG_BARCHART_UPDATE_GROUPBY';
export const PG_BARCHART_UPDATE_VARIABLE = 'PG_BARCHART_UPDATE_VARIABLE';

export const PG_HISTOGRAM_ADD_VARIABLE = 'PG_HISTOGRAM_ADD_VARIABLE';
export const PG_HISTOGRAM_DELETE_VARIABLE = 'PG_HISTOGRAM_DELETE_VARIABLE';
export const PG_HISTOGRAM_UPDATE_VARIABLE = 'PG_HISTOGRAM_UPDATE_VARIABLE';

export const PG_XYCHART_ADD_YVARIABLE = 'PG_XYCHART_ADD_YVARIABLE';
export const PG_XYCHART_DELETE_YVARIABLE = 'PG_XYCHART_DELETE_YVARIABLE';
export const PG_XYCHART_ADD_XVARIABLE = 'PG_XYCHART_ADD_XVARIABLE';
export const PG_XYCHART_DELETE_XVARIABLE = 'PG_XYCHART_DELETE_XVARIABLE';
export const PG_XYCHART_UPDATE_GROUPBY = 'PG_XYCHART_UPDATE_GROUPBY';
export const PG_XYCHART_UPDATE_YVARIABLE = 'PG_XYCHART_UPDATE_YVARIABLE';
export const PG_XYCHART_UPDATE_XVARIABLE = 'PG_XYCHART_UPDATE_XVARIABLE';

export const PG_SET_ADDING_VARIABLE = 'PG_SET_ADDING_VARIABLE';

// LEGO
export const LEGO_ADD_CHILD = 'LEGO_ADD_CHILD';
export const LEGO_ADD_SUBVECTOR = 'LEGO_ADD_SUBVECTOR';
export const LEGO_ADD_VECTOR = 'LEGO_ADD_VECTOR';
export const LEGO_EDIT_BLOCK = 'LEGO_EDIT_BLOCK';
export const LEGO_EDIT_BLOCK_CHILDREN = 'LEGO_EDIT_BLOCK_CHILDREN';
export const LEGO_RAPPID_STORE = 'LEGO_RAPPID_STORE';
export const LEGO_REMOVE_CHILD = 'LEGO_REMOVE_CHILD';
export const LEGO_SET_BLOCK = 'LEGO_SET_BLOCK';
export const LEGO_UPDATE_EDITING_MODE = 'LEGO_UPDATE_EDITING_MODE';
export const LEGO_UPDATE_VECTORS = 'LEGO_UPDATE_VECTORS';
export const LEGO_CLEAR_STORE = 'LEGO_CLEAR_STORE';
