import { useCallback, useContext, useEffect, useRef } from 'react';
import { useFocused } from 'slate-react';
import { EditableProps } from 'slate-react/dist/components/editable';

import { Symbols } from '@dametis/core';

import { useDispatch } from 'store';
import { useVncStore } from 'zustand/stores/vnc';

import { FunctionArgContext } from '../Functions/FunctionArgContext';
import { LeafSpan } from '../styled';

const findFocusedFunctionArgIndex = (el: HTMLElement): number => {
  const childrenCollection = el.parentElement?.parentElement?.children;
  if (!childrenCollection) return 0;
  const children = Array.from(childrenCollection);
  let index = 0;
  for (let i = 0; i < children.length; i += 1) {
    const child = children[i];
    if (child.children[0] === el) break;
    const hasComma = child.getAttribute('data-slate-symbol') === Symbols.COMMA;
    if (hasComma) {
      index += 1;
    }
  }
  return index;
};

export const RenderLeaf: EditableProps['renderLeaf'] = ({ attributes, children, leaf }) => {
  const dispatch = useDispatch();
  const focused = useFocused();

  const spanRef = useRef<HTMLSpanElement>(null);

  const setSearch = useVncStore(state => state.setSearch);
  const getResults = useVncStore(state => state.getResults);

  const { setFocused } = useContext(FunctionArgContext);

  const getResultsDebounce = useRef<ReturnType<typeof setTimeout>>();
  const getResultscontroller = useRef<AbortController | null>(null);

  const onFocus = useCallback(() => {
    if (!spanRef.current) return;
    const selection = window.getSelection();
    if (!selection) return;
    const oldSelected = spanRef.current.getAttribute('selected') === 'true';
    const newSelected = selection.type !== 'None' && focused && selection?.focusNode?.parentElement?.parentElement === spanRef.current;
    if (oldSelected !== newSelected) {
      spanRef.current.setAttribute('selected', newSelected.toString());
    }
    if (setFocused && newSelected) {
      setFocused(findFocusedFunctionArgIndex(spanRef.current));
    }
    if (newSelected && (leaf.text.length || oldSelected)) {
      // ☝ fetch en vidant l'input, mais pas en changeant d'input
      setSearch(leaf.text);
      clearTimeout(getResultsDebounce.current);
      getResultsDebounce.current = setTimeout(async () => {
        if (getResultscontroller.current !== null) getResultscontroller.current.abort();
        getResultscontroller.current = new AbortController();
        await dispatch(getResults(getResultscontroller.current));
      }, 50);
    }
  }, [focused, setFocused, leaf.text, setSearch, dispatch, getResults]);

  const onBlur = useCallback(() => {
    if (!spanRef.current) return;
    spanRef.current.setAttribute('selected', 'false');
  }, []);

  useEffect(() => {
    window.addEventListener('keyup', onFocus);
    window.addEventListener('mouseup', onFocus);
    window.addEventListener('focusout', onBlur);
    return () => {
      window.removeEventListener('keyup', onFocus);
      window.removeEventListener('mouseup', onFocus);
      window.removeEventListener('focusout', onBlur);
    };
  }, [onFocus, onBlur]);

  return (
    <LeafSpan ref={spanRef} leafText={leaf.text} {...attributes}>
      {children}
    </LeafSpan>
  );
};
