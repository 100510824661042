import { Grid, List } from '@mui/material';
import { ChangeEventHandler, Dispatch, MouseEventHandler, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MetricCategory, StandardBlockMetricBody, StandardBlockParameterBody } from '@dametis/core';

import { MetricDependencies, getMetricDependencies } from 'components/Lego/helpers/getMetricDependencies';
import { isMetricWorking } from 'components/Lego/helpers/isMetricWorking';
import { StandardBlockBody } from 'components/Lego/types';

import ElementsList from './ElementsList';
import MetricListElement from './MetricListElement';
import ParameterListElement from './ParameterListElement';

export interface ParametersAndMetricsStepProps<T extends StandardBlockBody> {
  standardBlockBody: T;
  setStandardBlockBody: Dispatch<SetStateAction<T>>;
}

const ParametersAndMetricsStep = <T extends StandardBlockBody = StandardBlockBody>({
  standardBlockBody,
  setStandardBlockBody,
}: ParametersAndMetricsStepProps<T>) => {
  const { t } = useTranslation('lego');

  const [isMetricHover, setIsMetricHover] = useState<boolean>(false);
  const [hoveredMetricDependencies, setHoveredMetricDependencies] = useState<MetricDependencies>({});

  const metricMetrics = useMemo(
    () => (standardBlockBody.metrics ?? []).filter(metric => metric.category === MetricCategory.METRIC),
    [standardBlockBody.metrics],
  );

  const discrepancyMetrics = useMemo(
    () => (standardBlockBody.metrics ?? []).filter(metric => metric.category === MetricCategory.DISCREPANCY),
    [standardBlockBody.metrics],
  );

  const technicalMetrics = useMemo(
    () => (standardBlockBody.metrics ?? []).filter(metric => metric.category === MetricCategory.TECHNICAL),
    [standardBlockBody.metrics],
  );

  const handleEnterMetric = useCallback(
    (metric: StandardBlockMetricBody): MouseEventHandler =>
      () => {
        setHoveredMetricDependencies(getMetricDependencies(metric, standardBlockBody));
        setIsMetricHover(true);
      },
    [standardBlockBody],
  );

  const handleLeaveMetric = useCallback(() => {
    setIsMetricHover(false);
    setHoveredMetricDependencies({});
  }, []);

  const handleClickParameterListItem = useCallback(
    (blockKey: string): MouseEventHandler<HTMLDivElement> =>
      () => {
        setStandardBlockBody(state => ({
          ...state,
          parameters: (state.parameters ?? []).map(parameter =>
            parameter.blockKey === blockKey ? { ...parameter, isSelected: !parameter.isSelected } : parameter,
          ),
        }));
      },
    [setStandardBlockBody],
  );

  const handleChangeIsSelectedParameter = useCallback(
    (blockKey: string): ChangeEventHandler<HTMLInputElement> =>
      event => {
        setStandardBlockBody(state => ({
          ...state,
          parameters: (state.parameters ?? []).map(parameter =>
            parameter.blockKey === blockKey ? { ...parameter, isSelected: event.target.checked } : parameter,
          ),
        }));
      },
    [setStandardBlockBody],
  );

  const handleClickMetricListItem = useCallback(
    (blockKey: string): MouseEventHandler<HTMLDivElement> =>
      () => {
        setStandardBlockBody(state => ({
          ...state,
          metrics: (state.metrics ?? []).map(metric =>
            metric.blockKey === blockKey ? { ...metric, isSelected: !metric.isSelected } : metric,
          ),
        }));
      },
    [setStandardBlockBody],
  );

  const handleChangeIsSelectedMetric = useCallback(
    (blockKey: string): ChangeEventHandler<HTMLInputElement> =>
      event => {
        setStandardBlockBody(state => ({
          ...state,
          metrics: (state.metrics ?? []).map(metric =>
            metric.blockKey === blockKey ? { ...metric, isSelected: event.target.checked } : metric,
          ),
        }));
      },
    [setStandardBlockBody],
  );

  return (
    <>
      <Grid container spacing={1.5} width={1}>
        <Grid item sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', maxHeight: 1 }} xs={6}>
          <List subheader={<li />} sx={{ mt: -1, position: 'relative', overflow: 'auto', '& ul': { p: 0 } }}>
            <ElementsList<StandardBlockParameterBody>
              elements={standardBlockBody.parameters ?? []}
              noElementLabel={t('text.noParameter')}
              renderElement={parameter => (
                <ParameterListElement
                  parameter={parameter}
                  sx={{ opacity: isMetricHover && !hoveredMetricDependencies[parameter.blockKey] ? 0.4 : 1 }}
                  onChangeIsSelected={handleChangeIsSelectedParameter}
                />
              )}
              title={t('label.parameters')}
              onClickElement={handleClickParameterListItem}
            />
          </List>
        </Grid>
        <Grid item sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', maxHeight: 1 }} xs={6}>
          <List subheader={<li />} sx={{ mt: -1, position: 'relative', overflow: 'auto', '& ul': { p: 0 } }}>
            <ElementsList<StandardBlockMetricBody>
              elements={metricMetrics}
              noElementLabel={t('text.noMetricMetrics')}
              renderElement={metric => (
                <MetricListElement
                  isWorking={metric.isSelected && !isMetricWorking(metric, standardBlockBody, standardBlockBody.parameters ?? [])}
                  metric={metric}
                  onChangeIsSelected={handleChangeIsSelectedMetric}
                  onMouseEnter={handleEnterMetric(metric)}
                  onMouseLeave={handleLeaveMetric}
                />
              )}
              title={t('label.metricMetrics')}
              onClickElement={handleClickMetricListItem}
            />
            <ElementsList<StandardBlockMetricBody>
              elements={discrepancyMetrics}
              noElementLabel={t('text.noDiscrepancyMetrics')}
              renderElement={metric => (
                <MetricListElement
                  isWorking={metric.isSelected && !isMetricWorking(metric, standardBlockBody, standardBlockBody.parameters ?? [])}
                  metric={metric}
                  onChangeIsSelected={handleChangeIsSelectedMetric}
                  onMouseEnter={handleEnterMetric(metric)}
                  onMouseLeave={handleLeaveMetric}
                />
              )}
              title={t('label.discrepancyMetrics')}
              onClickElement={handleClickMetricListItem}
            />
            <ElementsList<StandardBlockMetricBody>
              elements={technicalMetrics}
              noElementLabel={t('text.noTechnicalMetrics')}
              renderElement={metric => (
                <MetricListElement
                  isWorking={metric.isSelected && !isMetricWorking(metric, standardBlockBody, standardBlockBody.parameters ?? [])}
                  metric={metric}
                  onChangeIsSelected={handleChangeIsSelectedMetric}
                  onMouseEnter={handleEnterMetric(metric)}
                  onMouseLeave={handleLeaveMetric}
                />
              )}
              title={t('label.technicalMetrics')}
              onClickElement={handleClickMetricListItem}
            />
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default ParametersAndMetricsStep;
