import { Divider, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { BlockTypeInfo, ShortStandardBlockInfo } from '@dametis/core';

export interface BlockTileSubtitleProps {
  standardBlock?: ShortStandardBlockInfo;
  blockType?: BlockTypeInfo;
}

const BlockTileSubtitle: FC<BlockTileSubtitleProps> = ({ standardBlock = undefined, blockType = undefined }) => (
  <>
    {(blockType || standardBlock) && (
      <Stack direction="row" gap={1}>
        {blockType && (
          <Typography lineHeight={1} variant="caption">
            {blockType.name}
          </Typography>
        )}
        {blockType && standardBlock && <Divider flexItem orientation="vertical" sx={{ borderColor: theme => theme.palette.grey[900] }} />}
        {standardBlock && (
          <Typography lineHeight={1} variant="caption">
            {standardBlock.name}
          </Typography>
        )}
      </Stack>
    )}
  </>
);

export default BlockTileSubtitle;
