import { Components, Theme, fabClasses } from '@mui/material';

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[300],
      boxShadow: 'none !important',
      '&:hover': {
        backgroundColor: theme.palette.grey[400],
      },
      [`&.${fabClasses.disabled}`]: {
        color: theme.palette.grey[500],
        backgroundColor: theme.palette.grey[100],
      },
    }),
    primary: ({ theme }) => ({
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    }),
    secondary: ({ theme }) => ({
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    }),
  },
} as Components<Theme>['MuiFab'];
