import i18next from 'i18next';
import { object, string } from 'yup';

import '../yup-extented';

export const createBoxSchema = object({
  name: string().typeError(i18next.t('validations:type.string')).required(i18next.t('validations:required')),
  reference: string().typeError(i18next.t('validations:type.string')).required(i18next.t('validations:required')),
  machineId: string().uuid().typeError(i18next.t('validations:type.uuid')).required(i18next.t('validations:required')),
  siteId: string().uuid().typeError(i18next.t('validations:type.string')).required(i18next.t('validations:required')),
});

export const updateBoxSchema = object({
  name: string().typeError(i18next.t('validations:type.string')).required(i18next.t('validations:required')),
  reference: string().typeError(i18next.t('validations:type.string')).required(i18next.t('validations:required')),
  machineId: string().uuid().typeError(i18next.t('validations:type.uuid')).required(i18next.t('validations:required')),
});
