import { DeleteOutlined } from '@mui/icons-material';
import { Card, CardActions, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'store';
import { setDrawSegmentButtonState } from 'store/slices/playground';

import { durationDisplay } from '../../../functions/durationDisplay';

import useChartSegmentLabelStyles from './ChartSegmentLabel.style';

interface DeltaData {
  unit: string;
  value: string;
}

interface DerivativeData {
  unit: string;
  value: string;
}

interface SegmentData {
  duration: number;
  data: {
    delta: DeltaData;
    derivative: DerivativeData;
  }[];
}

interface Props {
  segment: SegmentData;
  handleDelete: () => void;
}

const ChartSegmentLabel: FC<Props> = ({ segment, handleDelete }) => {
  const { t } = useTranslation('dachart');
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    dispatch(setDrawSegmentButtonState(true));
  }, [dispatch]);

  const deleteSegment = () => {
    handleClose();
    handleDelete();
  };

  const classes = useChartSegmentLabelStyles();

  return (
    <Card className={classes.container} id="segment-label">
      <Typography variant="body1">{t('label.delta')}</Typography>
      {segment.data.map((axis, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Stack key={i} direction="row">
          <Typography className={classes.title} variant="body2">
            {axis.delta.value} {axis.delta.unit} {` (${axis.derivative.value} ${axis.derivative.unit})`}
          </Typography>
        </Stack>
      ))}
      <br />
      <Typography variant="body1">{t('label.duration')}</Typography>
      <Typography variant="body2">{durationDisplay(segment.duration)}</Typography>
      <CardActions className={classes.actionButton}>
        <Tooltip open={open} title={t('tooltip.deleteMeasure')} onClose={handleClose} onOpen={handleOpen}>
          <IconButton className={classes.actionButton__iconButton} size="small" onClick={deleteSegment}>
            <DeleteOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default ChartSegmentLabel;
