import { OperationStatus, OperationType } from '@dametis/core';

export default {
  userMenu: {
    logOut: 'Log out',
    myAccount: 'My account',
    support: 'Help and assistance',
    stopImpersonation: 'Stop impersonation',
  },
  siteSelect: {
    group: 'Group',
    groups: 'Groups',
    sites: 'Sites',
    site: 'Site',
    corporate: 'Corporate',
  },
  statusMenu: {
    status: 'Connection',
    msg_normal: 'Normal',
    msg_warning: 'Anomaly',
    msg_critical: 'Issue',
    msg_noBox: 'No box',
  },
  operationMenu: {
    title: 'Operations',
    clear: 'Clear',
    tooltip_none: 'No operation',
    tooltip_one: '{{count}} operation in progress',
    tooltip_other: '{{count}} operations in progress',
    tooltip: {
      [OperationStatus.LOADING]: {
        text_one: '{{count}} operation in progress',
        text_other: '{{count}} operations in progress',
      },
      [OperationStatus.SUCCESS]: {
        text_one: '{{count}} operation complete',
        text_other: '{{count}} operations complete',
      },
      [OperationStatus.CANCELLED]: {
        text_one: '{{count}} operation cancelled',
        text_other: '{{count}} operations cancelled',
      },
      [OperationStatus.WARNING]: {
        text_one: '{{count}} warning',
        text_other: '{{count}} warnings',
      },
      [OperationStatus.ERROR]: {
        text_one: '{{count}} error',
        text_other: '{{count}} errors',
      },
    },
    buttonLabel: {
      over_one: '{{count}} over',
      over_other: '{{count}} over',
      warning_one: '{{count}} warning',
      warning_other: '{{count}} warnings',
      error_one: '{{count}} error',
      error_other: '{{count}} errors',
    },
    operations: {
      [OperationType.CREATE_CALC_VAR]: {
        name: 'Creating calculated variable',
        name_withName: 'Creating {{name}}',
        [`description_${OperationStatus.SUCCESS}`]: 'Variable created with success.',
        [`description_${OperationStatus.CANCELLED}`]: 'Variable cancelled during calculation.',
        [`description_${OperationStatus.WARNING}`]: 'Variable created but missing data.',
        [`description_${OperationStatus.ERROR}`]: 'An error occurred.',
      },
      [OperationType.EDIT_CALC_VAR]: {
        name: 'Editing calculated variable',
        name_withName: 'Editing {{name}}',
        [`description_${OperationStatus.SUCCESS}`]: 'Variable edited with success.',
        [`description_${OperationStatus.CANCELLED}`]: 'Variable cancelled during calculation.',
        [`description_${OperationStatus.WARNING}`]: 'Variable edited but missing data.',
        [`description_${OperationStatus.ERROR}`]: 'An error occurred.',
      },
      [OperationType.CREATE_BATCH]: {
        name: 'Creating batch',
        name_withName: 'Creating {{name}}',
        [`description_${OperationStatus.SUCCESS}`]: 'Batch created with success.',
        [`description_${OperationStatus.CANCELLED}`]: 'Batch cancelled during calculation.',
        [`description_${OperationStatus.WARNING}`]: 'Batch created but missing data.',
        [`description_${OperationStatus.ERROR}`]: 'An error occurred.',
      },
      [OperationType.EDIT_BATCH]: {
        name: 'Editing batch',
        name_withName: 'Editing {{name}}',
        [`description_${OperationStatus.SUCCESS}`]: 'Batch edited with success.',
        [`description_${OperationStatus.CANCELLED}`]: 'Batch cancelled during calculation.',
        [`description_${OperationStatus.WARNING}`]: 'Batch edited but missing data.',
        [`description_${OperationStatus.ERROR}`]: 'An error occurred.',
      },
      [OperationType.EDIT_DATA]: {
        name: 'Editing Data',
        name_withNumber: 'Editing {{number}} Variable Data',
        [`description_${OperationStatus.SUCCESS}`]: 'Data edited with success.',
        [`description_${OperationStatus.ERROR}`]: 'An error occured.',
      },
      [OperationType.IMPORT_DATA]: {
        name: 'Importing Data',
        name_withNumber: 'Importing {{number}} Variable Data',
        [`description_${OperationStatus.SUCCESS}`]: 'Data imported with success.',
        [`description_${OperationStatus.ERROR}`]: 'An error occured.',
      },
      [OperationType.DELETE_DATA]: {
        name: 'Deleting Data',
        name_withNumber: 'Deleting {{number}} Variable Data',
        [`description_${OperationStatus.SUCCESS}`]: 'Data deleted with success.',
        [`description_${OperationStatus.ERROR}`]: 'An error occured.',
      },
    },
  },
  helpMenu: {
    tooltip: 'Question, problem or bug?',
    button: 'Support',
  },
  maintenance: {
    msg: 'The platform is currently undergoing major updates.\nSome features may be temporarily unavailable or slowed down but will be back shortly.',
    ovh: 'Our server host is currently experiencing a major incident.\nSome features may be temporarily unavailable or slowed down but will be back shortly.',
  },
  tooltip: {
    siteConfiguration: 'Site configuration',
    groupConfiguration: 'Group configuration',
    favorite: 'Add to favorites',
    unfavorite: 'Remove from favorites',
  },
  helper: {
    menu: 'to open this menu.',
  },
};
