import { BoxStatusLabel } from '../../types/box';

export default {
  title: {
    box: 'Mis módulos',
    config: 'Mi configuración',
  },
  text: {
    updatedAt: 'Modificado {{date}}',
    noBox: 'No tienes ninguna caja todavía.',
    lastWeek: 'Esta semana',
    lastMonth: 'Este mes',
    connected: 'Conectado',
    disconnected: 'Durante {{duration}}',
    disconnectedInternetDetails: 'El módulo no pudo conectarse a Internet durante {{duration}}.',
    disconnectedPowerDetails: 'El módulo fue desconectado durante {{duration}}, lo que resultó en una pérdida de datos.',
    numberVariables: 'Número de variables',
    day: 'j',
    internet: 'Conexión a Internet',
    power: 'Alimentación eléctrica',
    pastConnection_default: 'Cargando...',
    pastConnection_normal: 'Conectada sin interrupción',
    pastConnection_critical: 'Desconectada al menos 1 vez',
  },
  chip: {
    connection_default: 'Cargando...',
    [`connection_${BoxStatusLabel.NORMAL}`]: 'Conectado',
    [`connection_${BoxStatusLabel.WARNING}`]: 'Error',
    [`connection_${BoxStatusLabel.CRITICAL}`]: 'Desconectada',
  },
  error: {
    unknown: 'Desconocida',
  },
};
