import { createSlice } from '@reduxjs/toolkit';

import { SiteInvitationInfo } from '@dametis/core';

export interface InvitationState {
  invitations: SiteInvitationInfo[];
}

const initialState: InvitationState = {
  invitations: [],
};

export const invitationsSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    setInvitations: (state, action) => {
      state.invitations = action.payload;
    },
    addInvitation: (state, action) => {
      state.invitations = [action.payload, ...state.invitations];
    },
    deleteInvitation: (state, action) => {
      state.invitations = state.invitations.filter(invitation => invitation.uuid !== action.payload);
    },
    clearInvitations: () => initialState,
  },
});

export const { setInvitations, addInvitation, deleteInvitation, clearInvitations } = invitationsSlice.actions;

export default invitationsSlice.reducer;
