import type { AxisLabelsFormatterContextObject } from 'highcharts';
import { FC } from 'react';

import { localizedFormat } from 'localization/localizedDateFns';

export type DateTickProps = AxisLabelsFormatterContextObject;

const DateTick: FC<DateTickProps> = ({ value, isLast }) => (
  <>
    <div style={{ textAlign: 'center' }}>{typeof value === 'number' && localizedFormat(value, 'p')}</div>
    <div
      style={{
        textAlign: 'center',
        ...(isLast && {
          position: 'absolute',
          width: '300%',
          right: 0,
          oveflowWrap: 'break-word',
          whiteSpace: 'normal',
          textAlign: 'right',
        }),
      }}
    >
      {typeof value === 'number' && localizedFormat(value, 'eee PP')}
    </div>
  </>
);

export default DateTick;
