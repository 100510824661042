import { ShareType } from 'types/share';

export default {
  title: {
    shared: 'Compartir avanzado',
    unassigned: 'Sin asignar',
    custom: 'Personalizado',
    teams: 'Equipos',
    users: 'Usuarios',
  },
  text: {
    users: 'Usuarios',
    list: 'Equipos',
    custom: 'Personalizado',
    owner: 'Propietario',
    you: 'Tú',
    notfound: 'Ningún usuario/equipo coincide con esta búsqueda',
    addPeople: 'Añadir una persona o equipo...',
    public: 'Público',
    unassigned: 'Sin asignar',
  },
  button: {
    add: 'Añadir',
    ok: 'OK',
  },
  select: {
    reader: 'Lector',
    writer: 'Editor',
  },
  invitations: {
    title: 'Compartir mediante enlace',
  },
  alert: {
    public: 'Cualquiera con este enlace puede crear una cuenta.',
    reportPrivate: 'El informe debe hacerse público primero para poder compartirlo con los usuarios que aún no tienen cuenta.',
    synopticPrivate: 'El sinóptico debe hacerse público primero para poder compartirlo con los usuarios que aún no tienen cuenta.',
    playgroundPrivate: 'El playground debe hacerse público primero para poder compartirlo con los usuarios que aún no tienen cuenta.',
  },
  toast: {
    [`shareSuccess_${ShareType.REPORT}`]: 'El informe ha sido compartido correctamente.',
    [`privatizeSuccess_${ShareType.REPORT}`]: 'El informe ha sido hecho privado correctamente.',
    [`shareSuccess_${ShareType.PLAYGROUND}`]: 'El playground ha sido compartido correctamente.',
    [`privatizeSuccess_${ShareType.PLAYGROUND}`]: 'El playground ha sido hecho privado correctamente.',
    [`shareSuccess_${ShareType.SYNOPTIC}`]: 'El sinóptico ha sido compartido correctamente.',
    [`privatizeSuccess_${ShareType.SYNOPTIC}`]: 'El sinóptico ha sido hecho privado correctamente.',
    [`shareSuccess_${ShareType.ALARM}`]: 'La alarma ha sido compartida correctamente.',
    [`privatizeSuccess_${ShareType.ALARM}`]: 'La alarma ha sido hecha privada correctamente.',
    [`shareSuccess_${ShareType.PROJECT}`]: 'El proyecto ha sido compartido correctamente.',
    [`privatizeSuccess_${ShareType.PROJECT}`]: 'El proyecto ha sido hecho privado correctamente.',
    addUserSuccess: 'El usuario ha sido añadido correctamente a la compartición.',
    addTeamSuccess: 'El equipo ha sido añadido correctamente a la compartición.',
    deleteUserSuccess: 'El usuario ha sido eliminado correctamente de la compartición.',
    deleteTeamSuccess: 'El equipo ha sido eliminado correctamente de la compartición.',
    changeUserRoleSuccess: 'Los derechos de modificación del usuario han sido modificados correctamente.',
    changeTeamRoleSuccess: 'Los derechos de modificación del equipo han sido modificados correctamente.',
    shareError: 'Se produjo un error al modificar los derechos de compartición.',
  },
};
