import { chipClasses } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Shortcut } from '@dametis/core';

import ChipEllipse, { ChipEllipseProps } from 'components/UI/ChipEllipse/ChipEllipse';

import ShortcutIcon from './ShortcutIcon';

export interface ShortcutUnknownChipProps extends Omit<ChipEllipseProps, 'label'> {
  shortcut: Shortcut;
}

const ShortcutUnknownChip: FC<ShortcutUnknownChipProps> = ({ shortcut, ...props }) => {
  const { t } = useTranslation('shortcut');

  return (
    <ChipEllipse
      icon={<ShortcutIcon withTooltip category={shortcut.category} sx={{ color: 'black' }} />}
      label={t('text.notFound')}
      sx={{
        bgcolor: 'daRed.100',
        [`.${chipClasses.icon}`]: {
          color: 'black',
        },
      }}
      {...props}
    />
  );
};

export default ShortcutUnknownChip;
