// import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from '@mui/x-data-grid-premium';

import { CreateRealVariableBody, PhysicalQuantity, TransformerType, UUID, VariableKind } from '@dametis/core';

export const MODBUSTCP_BIT_VALUES = [...Array(16).keys()];

export const ADVANCED_ENTRY_VARIABLES_MAX_COUNT = 10;
export const CART_ITEMS_STORAGE_KEY = 'cartItems';
export const COLUMN_VISIBILITY_MODEL_STORAGE_KEY = 'columnVisibilityModel';
export const COLUMN_ORDER_STORAGE_KEY = 'columnOrder';
export const IMPORT_COLUMN_NOT_SELECTED = 'notSelected';

export enum VariablesGridColumnScope {
  COMMON = 'common',
  ACQUISITION = 'acquisition',
  DATA_TRANSFORMER = 'dataTransformer',
  MCO = 'mco',
  MODBUS_TCP = 'modbusTcp',
  OPC_UA = 'opcUa',
  S7 = 's7',
  SQL = 'sql',
  BACNET = 'bacnet',
  MQTT = 'mqtt',
  MONITORING = 'monitoring',
}

export enum VariablesGridColumn {
  CHECKBOX = 'checkbox',
  KIND = 'kind',
  NAME = 'name',
  REFERENCE = 'reference',
  DESCRIPTION = 'description',
  PHYSICAL_QUANTITY = 'physicalQuantity',
  LAST_POINT_VALUE = 'lastPointValue',
  UNIT = 'unit',
  LAST_POINT_DATE = 'lastPointDate',
  LAST_PROGRESS_VALUE = 'lastProgressValue',
  TAGS = 'tags',
  DEVICE = 'device',
  PROTOCOL = 'protocol',
  SENSOR_NAME = 'sensorName',
  // PROTOCOL = GRID_DETAIL_PANEL_TOGGLE_FIELD,
  OFFSET = 'offset',
  VARIABLE_CONFIG_TRANSFORMER_TYPE = 'variableConfigTransformerType',
  VARIABLE_CONFIG_TRANSFORMER = 'variableConfigTransformer',
  MODBUS_TCP_UID = 'modbusTcpUid',
  MODBUS_TCP_FUNCTION = 'modbusTcpFunction',
  MODBUS_TCP_ADDRESS = 'modbusTcpAddress',
  MODBUS_TCP_INVERTWORDS = 'modbusTcpInvertWords',
  MODBUS_TCP_INVERTBYTES = 'modbusTcpInvertBytes',
  MODBUS_TCP_DATATYPE = 'modbusTcpDataType',
  MODBUS_TCP_BIT = 'modbusTcpBit',
  OPC_UA_NODE_ID = 'opcUaNodeId',
  S7_ADDRESS = 's7Address',
  SQL_REQUEST = 'sqlRequest',
  SQL_MODE = 'sqlMode',
  SQL_VARIABLES = 'sqlVariables',
  BACNET_ID = 'bacnetId',
  MONITORING_MIN = 'monitoringMin',
  MONITORING_MAX = 'monitoringMax',
  MONITORING_EXPECTED_FREQUENCY = 'monitoringFrequencyDelay',
  MONITORING_ABNORMALITY_DELAY = 'monitoringAbnormalityDelay',
}

export interface VariablesGridColumnConfig {
  column: VariablesGridColumn;
  scope: VariablesGridColumnScope;
  required?: boolean;
}

export const ALL_DEVICES = 'allDevices';
export const WITHOUT_TAG = 'withoutTag';

export interface VariablesGridFilters {
  kinds: AllVariableKind[];
  device: UUID | typeof ALL_DEVICES;
  physicalQuantities: PhysicalQuantity[];
  tags: UUID[];
}

export type ColumnVisibilityModel = Partial<Record<VariablesGridColumn, boolean>>;

export enum BulkEditTagsFunction {
  REPLACE_TAGS = 'replaceTags',
  MERGE_TAGS = 'mergeTags',
}

export const IsTransformerType = (type: any): type is TransformerType => Object.values(TransformerType).includes(type);

export enum PanelTab {
  INFORMATIONS = 'informations',
  COMMENTS = 'comments',
  ACTIVITIES = 'activities',
  DEPENDENCIES = 'dependencies',
}

export interface ImportRealVariableBody extends Omit<CreateRealVariableBody, 'tagIds'> {
  tags: string[];
}

export enum FakeVariableKind {
  ALARM = 'ALARM',
  BATCH = 'BATCH',
  VAPOR_MIX = 'VAPOR_MIX',
}

export const DEFAULT_AVAILABLE_VARIABLE_KINDS = [
  VariableKind.REAL,
  VariableKind.CALCULATED,
  VariableKind.MANUAL,
  VariableKind.CONSTANT,
  FakeVariableKind.ALARM,
  FakeVariableKind.BATCH,
  FakeVariableKind.VAPOR_MIX,
];

export type AllVariableKind = VariableKind | FakeVariableKind;

export const IsStandardKind = (kind: AllVariableKind): kind is VariableKind => Object.values<string>(VariableKind).includes(kind);

export const IsFakeKind = (kind: AllVariableKind): kind is FakeVariableKind => Object.values<string>(FakeVariableKind).includes(kind);

export interface VariableFlags {
  physicalQuantities: PhysicalQuantity[];
  sites: string[];
  kinds: AllVariableKind[];
}
