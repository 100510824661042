import { v4 as uuidv4 } from 'uuid';

import { BlockTypeConnection, ConnectionType } from '@dametis/core';

export const createBlockTypeConnection = ({
  uuid = uuidv4(),
  blockKey = '',
  blockTypeId = '',
  type = ConnectionType.MULTIPLE,
}: Partial<BlockTypeConnection> = {}): BlockTypeConnection => ({
  uuid,
  blockKey,
  blockTypeId,
  type,
});
