export default {
  title: {
    dragAndDrop: 'Glisser et déposer',
    or: 'ou',
  },
  button: {
    browse: 'Parcourir les fichiers',
  },
  text: {
    selectedFile: 'Fichier sélectionné :',
    formatsAllowed: 'Formats autorisés : ',
    file: 'Fichier',
    browse: 'Déposer un fichier ou cliquer pour le sélectionner',
  },
  tooltip: {
    upload: 'Télécharger un fichier',
  },
};
