import { Typography, TypographyProps, styled } from '@mui/material';
import { FC, ReactNode } from 'react';

import TypographyEllipse, { TypographyEllipseProps } from '../TypographyEllipse/TypographyEllipse';

const StyledBox = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  flexGrow: 1,
  marginLeft: theme.spacing(0.5),
  '&:first-child': {
    marginLeft: 0,
  },
}));

const subtitlePropsDefaultProp: Omit<TypographyProps, 'children'> = {};

export interface RightPanelHeaderTextProps {
  title: TypographyEllipseProps['children'];
  titleProps?: Omit<TypographyEllipseProps, 'children'>;
  subtitle?: ReactNode;
  subtitleProps?: Omit<TypographyProps, 'children'>;
}

const RightPanelHeaderText: FC<RightPanelHeaderTextProps> = ({
  title,
  titleProps = undefined,
  subtitle = null,
  subtitleProps = subtitlePropsDefaultProp,
}) => (
  <StyledBox>
    <TypographyEllipse sx={{ maxHeight: 48 }} variant="h4" {...titleProps}>
      {title}
    </TypographyEllipse>
    {subtitle && (
      <Typography noWrap mt={0.5} variant="caption" {...subtitleProps}>
        {subtitle}
      </Typography>
    )}
  </StyledBox>
);

export default RightPanelHeaderText;
