export default {
  button: {
    register: 'Me inscribo en la demostración',
    close: 'Cerrar',
  },
  text: {
    intro1: 'Inscríbase en la',
    intro2: 'demo de actualizaciones y novedades',
    intro3: 'de su plataforma Dametis !',
    date: 'martes 3 de octubre, de 11h a 12h',
    team: 'Sus presentadores Dametis para esta demostración :',
  },
};
