import { CheckCircleOutlineOutlined, ErrorOutlineOutlined, InfoOutlined, ReportProblemOutlined } from '@mui/icons-material';
import { Alert, Button, Stack, styled } from '@mui/material';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { Toast, ToastSeverity } from 'types';

interface ToastProps {
  id: string | number;
  toastItem: Toast;
}

const StyledAlert = styled(Alert)(({ theme }) => ({
  width: '380px',
  borderRadius: theme.shape.borderRadius,
  paddingBlock: theme.spacing(0.75),
  paddingInline: theme.spacing(2),
}));

const CustomToast: FC<ToastProps> = ({ id, toastItem }) => {
  const { t } = useTranslation('toast');

  // const lastToast = useSelector(state => state.toast.lastToast);

  const toastIcon = useMemo(() => {
    switch (toastItem.severity) {
      case ToastSeverity.ERROR:
        return <ErrorOutlineOutlined sx={{ marginRight: 1 }} />;
      case ToastSeverity.WARNING:
        return <ReportProblemOutlined sx={{ marginRight: 1 }} />;
      case ToastSeverity.SUCCESS:
        return <CheckCircleOutlineOutlined sx={{ marginRight: 1 }} />;
      default:
        return <InfoOutlined sx={{ marginRight: 1 }} />;
    }
  }, [toastItem.severity]);

  const closeToast: MouseEventHandler<HTMLButtonElement | HTMLDivElement> = useCallback(
    event => {
      event.stopPropagation();
      toast.dismiss(id);
    },
    [id],
  );

  // const closeAllToasts: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
  //   event.stopPropagation();
  //   toast.dismiss(null);
  // }, []);

  // const isLastToast = useMemo(() => lastToast === toastItem.id, [toastItem.id, lastToast]);

  return (
    <StyledAlert
      action={
        <Stack height="100%" justifyContent="center" onClick={closeToast}>
          {toastItem.action ? (
            toastItem.action
          ) : (
            <Button color="inherit" size="small" onClick={closeToast}>
              {t('button.ok')}
            </Button>
          )}
        </Stack>
      }
      elevation={8}
      icon={toastIcon}
      severity={toastItem.severity}
      sx={{ color: 'white' }}
      variant="filled"
    >
      <Stack
        alignItems="center"
        color="inherit"
        direction="row"
        height="100%"
        sx={{
          fontSize: '0.875rem',
          lineHeight: '1.43',
          letterSpacing: '0.01071em',
        }}
      >
        {toastItem.message}
      </Stack>
      {/* <Button
          size="small"
          onClick={closeAllToasts}
          sx={{ position: 'absolute', bottom: -30, left: 0, display: isLastToast ? 'block' : 'none' }}
        >
          {t('button.closeAll')}
        </Button> */}
    </StyledAlert>
  );
};

export default CustomToast;
