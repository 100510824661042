import { IsModbusTcpDevice, IsOpcUaDevice } from '@dametis/core';

import { sdk } from 'sdk';
import { TypedThunk } from 'store';
import { BoxesState, setBoxesData } from 'store/slices/boxes';

import { displaySdkErrorToast } from './toasts';

export const fetchEquipment =
  (rawSiteId?: string): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const groupId = state.auth.selectedGroup?.uuid;
    const siteId = rawSiteId ?? state.auth.selectedSite?.uuid;
    if (!groupId) throw new Error();
    try {
      const { data: boxes } = siteId ? await sdk.box.List(siteId) : await sdk.corporate.ListBoxes(groupId);
      const boxesData: BoxesState = { maxLoopTime: 5000, list: boxes, allIds: [], devices: boxes.map(box => box.devices).flat() };
      boxes.forEach(box => {
        boxesData.allIds.push(box.uuid);
        box.devices?.forEach(device => {
          if (IsModbusTcpDevice(device) && device.modbusTcp.loopTime > boxesData.maxLoopTime)
            boxesData.maxLoopTime = device.modbusTcp.loopTime;
          if (IsOpcUaDevice(device) && device.opcUa.loopTime > boxesData.maxLoopTime) boxesData.maxLoopTime = device.opcUa.loopTime;
        });
      });
      dispatch(setBoxesData(boxesData));
    } catch (error) {
      console.error(error);
      dispatch(displaySdkErrorToast(error));
    }
  };
