export default {
  button: {
    register: "Je m'inscris à la démo",
    close: 'Fermer',
  },
  text: {
    intro1: 'Inscrivez-vous à la',
    intro2: 'démo des mises à jour et nouveautés',
    intro3: 'de votre plateforme Dametis !',
    date: 'mardi 3 octobre, de 11h à 12h',
    team: 'Vos intervenants Dametis pour cette démo :',
  },
};
