import deepEqual from 'deep-equal';

import { CalculationVariable } from '@dametis/core';

import { CartItem } from 'types/cart';

export const checkParsedCalculationInCart = (cartItems: CartItem[], calculation: CalculationVariable): boolean => {
  /**
   * SLE: do that to remove all undefined values from calculation to check properly because undefined values in localStorage are removed
   */
  const parsedCalculation = JSON.parse(JSON.stringify(calculation));
  return cartItems.some(item => deepEqual(parsedCalculation, item.variable));
};
