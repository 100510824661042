import { DeleteOutlined } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ModelInfo, ShortBlockInfo } from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import ElementContainer from 'components/UI/ElementContainer/ElementContainer';
import GoToPlaygroundIconButton from 'components/UI/GoToPlaygroundButton/GoToPlaygroundIconButton';
import { calculationToString } from 'functions/calculationToString';
import { setColorLightness } from 'functions/color';
import { getVariableColor } from 'hooks/useVariableProp';
import { useSelector } from 'store';
import { useBlocks } from 'store/api/blocks';
import { useModels } from 'store/api/models';
import { CartItem } from 'types/cart';

const modelsEmptyArray: ModelInfo[] = [];
const blocksEmptyArray: ShortBlockInfo[] = [];

interface Props {
  item: CartItem;
  onDelete?: MouseEventHandler<HTMLButtonElement>;
}

const CartItemPreview: FC<Props> = ({ item, onDelete = undefined }) => {
  const { t } = useTranslation('playground');
  const theme = useTheme();

  const variables = useSelector(state => state.variables.byId);

  const { data: models = modelsEmptyArray } = useModels();
  const { data: blocks = blocksEmptyArray } = useBlocks();

  const variable = useMemo(() => getSingleVariableCalculation(item.variable), [item.variable]);
  const color = useMemo(
    () => (variable ? getVariableColor(variable, { variables, blocks, models }, theme) : theme.palette.primary.main),
    [variable, variables, blocks, models, theme],
  );

  const handleDelete: MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      if (onDelete) {
        onDelete(event);
      }
    },
    [onDelete],
  );

  return (
    <>
      <ElementContainer color={color}>
        <Typography sx={{ color: setColorLightness(color, 30) }} variant="h6">
          {calculationToString(item.variable)}
        </Typography>
        <Stack alignItems="center" className="actions" direction="row" spacing={0.5}>
          <GoToPlaygroundIconButton calculations={[item.variable]} tooltipProps={{ placement: 'top' }} />
          {onDelete && (
            <Tooltip placement="top" title={t('tooltip.deleteVariable')}>
              <IconButton size="small" onClick={handleDelete}>
                <DeleteOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </ElementContainer>
    </>
  );
};

export default CartItemPreview;
