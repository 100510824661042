import {
  AccordionProps,
  AccordionSummary,
  Checkbox,
  CheckboxProps,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShortStandardBlockInfo, UUID } from '@dametis/core';

import { DeployedCodeOutlined } from 'assets/icons/DeployedCodeOutlined';
import Counter from 'components/UI/Counter/Counter';
import { PropsContext } from 'components/VNC/context';
import getEntitiesById from 'functions/getEntitiesById';
import { useStandardBlocks } from 'store/api/standardBlocks';
import { useVncStore } from 'zustand/stores/vnc';

import { FilterElementProps } from './Filters';
import { FilterAccordion, FilterAccordionDetails, FilterAccordionSummaryStack, FilterList, FilterListSubheader } from './styled';

const standardBlocksEmptyArray: ShortStandardBlockInfo[] = [];

interface StandardBlockFilter {
  key: UUID;
  standardBlock: ShortStandardBlockInfo;
  enabled: boolean;
}

const StandardBlocksFilters: FC<FilterElementProps> = ({ expanded, setExpanded, accordionsNumber }) => {
  const { t } = useTranslation('vnc');

  const { data: standardBlocks = standardBlocksEmptyArray } = useStandardBlocks();

  const disabled = useContext(PropsContext).disableFilters.includes('standardBlocks');

  const standardBlocksFilters = useVncStore(state => state.filters.standardBlocks);
  const setFilterValue = useVncStore(state => state.setFilterValue);

  const trueStandardBlocksCount = useMemo(() => Object.values(standardBlocksFilters).filter(Boolean).length, [standardBlocksFilters]);

  const standardBlocksById = useMemo(() => getEntitiesById<ShortStandardBlockInfo>(standardBlocks), [standardBlocks]);

  const sortedStandardBlockFilters = useMemo<StandardBlockFilter[]>(
    () =>
      Object.entries(standardBlocksFilters)
        .map(([key, enabled]) => ({ key, standardBlock: standardBlocksById[key], enabled }))
        .sort((standardBlockFilterA, standardBlockFilterB) =>
          standardBlockFilterA.standardBlock.name
            .toLocaleLowerCase()
            .localeCompare(standardBlockFilterB.standardBlock.name.toLocaleLowerCase()),
        ),
    [standardBlocksFilters, standardBlocksById],
  );

  const handleChangeExpanded = useCallback<NonNullable<AccordionProps['onChange']>>(
    (event, isExpanded) => {
      setExpanded(isExpanded ? 'standardBlocks' : false);
    },
    [setExpanded],
  );

  const handleToggleBlockType = useCallback(
    (standardBlockFilter: StandardBlockFilter) => () => {
      setFilterValue('standardBlocks', standardBlockFilter.key, !standardBlockFilter.enabled);
    },
    [setFilterValue],
  );

  const handleChangeBlockType = useCallback(
    (standardBlockFilter: StandardBlockFilter): CheckboxProps['onChange'] =>
      (event, checked) => {
        setFilterValue('standardBlocks', standardBlockFilter.key, checked);
      },
    [setFilterValue],
  );

  return (
    <FilterAccordion expanded={expanded} filterAccordionsNumber={accordionsNumber} onChange={handleChangeExpanded}>
      <AccordionSummary>
        <FilterAccordionSummaryStack>
          <FilterListSubheader>{t('accordion.standardBlocks')}</FilterListSubheader>
          {trueStandardBlocksCount > 0 && <Counter count={trueStandardBlocksCount} />}
        </FilterAccordionSummaryStack>
      </AccordionSummary>
      <FilterAccordionDetails>
        <FilterList>
          {sortedStandardBlockFilters.map(filter => (
            <ListItem
              key={filter.key}
              disablePadding
              secondaryAction={
                <Checkbox checked={filter.enabled} disabled={disabled} edge="end" tabIndex={-1} onChange={handleChangeBlockType(filter)} />
              }
            >
              <ListItemButton disabled={disabled} selected={filter.enabled} onClick={handleToggleBlockType(filter)}>
                <ListItemIcon sx={{ mr: 2, minWidth: 'unset' }}>
                  <DeployedCodeOutlined sx={{ fontSize: 18, color: filter.standardBlock.ui.color }} />
                </ListItemIcon>
                <ListItemText primary={filter.standardBlock.name} primaryTypographyProps={{ sx: { wordBreak: 'break-word' } }} />
              </ListItemButton>
            </ListItem>
          ))}
        </FilterList>
      </FilterAccordionDetails>
    </FilterAccordion>
  );
};

export default StandardBlocksFilters;
