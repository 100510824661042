import { Dispatch, SetStateAction, useMemo } from 'react';
import { useLocalStorage as useLocalStorageTs } from 'usehooks-ts';

import { Options, append } from '../functions/localStorage';

export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
  { userId, groupId, siteId }: Options = {},
): [T, Dispatch<SetStateAction<T>>, () => void] => {
  const newKey = useMemo(() => append(append(append(key, userId), groupId), siteId), [key, userId, groupId, siteId]);
  return useLocalStorageTs<T>(newKey, initialValue);
};
