export default {
  ac_debit: {
    name: 'AC_Debit',
    description: 'Flujo de aire comprimido',
  },
  categories: {
    air_compressed: 'Aire comprimido',
  },
  inputs: {
    temperature: {
      name: 'Temperatura',
      id: 'T',
    },
    pressure: {
      name: 'Presión',
      id: 'P',
    },
    delta_pressure: {
      name: 'Delta presión',
      id: 'dP',
    },
    threshold_delta_pressure: {
      name: 'Umbral de delta presión',
      id: 'sdP',
    },
    diameter_in: {
      name: 'Diámetro interior',
      id: 'Dint',
    },
    diameter_out: {
      name: 'Diámetro exterior',
      id: 'Dext',
    },
    reynolds: {
      name: 'Reynolds',
      id: 'Rd',
    },
    altitude: {
      name: 'Altitud',
      id: 'Altitud',
    },
  },
};
