import { TagTab } from 'types/tags';

export default {
  title: {
    editTags: 'Tags',
    tags: 'Tags',
    tagsDetails: 'Tags details',
    column: {
      name: 'Name',
      dependencies: 'Dependencies',
    },
    entityType: {
      blocks: 'Blocks',
      playgrounds: 'Playgrounds',
      projects: 'Projects',
      reports: 'Reports',
      synoptics: 'Synoptics',
      variables: 'Variables',
      opcos: 'Vapor mixes',
    },
    tab: {
      [TagTab.INFORMATIONS]: 'Informations',
      [TagTab.DEPENDENCIES]: 'Dependencies',
    },
  },
  label: {
    dependencies: 'Dependencies',
  },
  input: {
    tags: 'Tags',
    tagName: 'Tag name',
    targetTagName: 'Target tag name',
  },
  button: {
    tag: 'Tag',
    editTags: 'Edit tags',
    close: 'Close',
    cancel: 'Cancel',
    save: 'Save',
    create: 'Create',
    merge: 'Merge',
    confirmDelete: 'Yes, delete',
    validate: 'Validate',
    createTag: 'Create tag',
    editTag: 'Edit',
    mergeTags: 'Merge',
    deleteTag: 'Delete',
    deleteTag_one: 'Delete this tag',
    deleteTag_other: 'Delete these {{count}} tags',
  },
  tagAutocomplete: {
    newTag: 'New tag',
    unknownTag: 'Unknown tag',
    emptyTag: 'No tag selected',
  },
  text: {
    noDependency: 'No dependency',
    noOptions: 'No options',
    deleteTagsAdvice:
      'Deleting $t(text.thisTag, {"count": {{tagCount}} }) will remove it from $t(text.entity, {"count": {{dependenciesCount}} }). Please check these dependencies before deletion.',
    mergeTagsAdvice:
      'Merging $t(text.thisTag, {"count": {{tagCount}} }) will move $t(text.entity, {"count": {{dependenciesCount}} }) in target tag and remove source tags. Please check these dependencies before merger.',
    thisTag_one: 'this tag',
    thisTag_other: 'these {{count}} tags',
    entity_one: '{{count}} entity',
    entity_other: '{{count}} entities',
    updatedAt: 'Updated {{date}}',
  },
  error: {
    maxTagAllowed: 'Maximum number of tags reached',
  },
  toast: {
    success: 'New tags saved.',
    error: 'An error occured during tags saving.',
    createTagSuccess: 'The tag was successfully created.',
    deleteTagSuccess_one: 'The tag was successfully deleted.',
    deleteTagSuccess_other: 'The tags were successfully deleted.',
    mergeTagsSuccess: 'The tags were successfully merged.',
    mergeTagsItselfFailure: 'Impossible to merge a tag with only itself',
    updateTagSuccess: 'The tag was successfully updated.',
  },
  modal: {
    cancelEdition: 'Are you sure ? You are closing edit mode.',
  },
};
