import { capitalize } from '@mui/material';
import { t } from 'i18next';
import { lowerFirst } from 'lodash-es';

const makePermissionLabel = (shortLabel: string, canEdit: boolean, shownWhenValid?: boolean) => {
  if (!canEdit) {
    return `${t('global:tooltip.noRight')}${lowerFirst(shortLabel)}`;
  }
  return shownWhenValid ? capitalize(shortLabel) : '';
};

export default makePermissionLabel;
