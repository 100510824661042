import { ExpandMore } from '@mui/icons-material';
import { Components } from '@mui/material';

export default {
  defaultProps: {
    expandIcon: <ExpandMore />,
  },
  // styleOverrides: {
  // root: {
  //   padding: 0,
  //   flexDirection: 'row-reverse',
  //   justifyContent: 'flex-end',
  //   [`&.${accordionSummaryClasses.focusVisible}`]: {
  //     background: 'unset',
  //   },
  //   [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
  //     transform: 'rotate(-90deg)',
  //     [`&.${accordionClasses.expanded}`]: {
  //       transform: 'rotate(0deg)',
  //     },
  //   },
  // },
  // },
} as Components['MuiAccordionSummary'];
