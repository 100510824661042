export default {
  title: {
    metrics: 'Weekly metrics',
    alarms: 'Actives alarms',
  },
  label: {
    displayReport: 'Display a report',
  },
  text: {
    dametis: 'MyDametis.',
    dametisCorporate: 'MyDametis Corporate.',
    welcome: 'Welcome on ',
    intro: 'Hello {{name}}',
    onSite: 'You are on the site ',
    ofGroup: ', belonging to the group ',
    onGroup: 'You are on the group ',
    alarmCount_none: 'No alarm',
    alarmCount_one: '{{count}} alarm',
    alarmCount_other: '{{count}} alarms',
    active_none: 'is active',
    active_one: 'is active',
    active_other: 'are active',
    alarmAck: 'Acknowledged',
    alarmNotAck: 'Not acknowledged',
    versionStart: {
      v_success: 'Your',
      v_default: 'Your',
      v_warning: 'Your',
      v_danger: 'Your',
    },
    versionDametisBox: {
      v_success: 'Dametis box',
      v_default: 'Dametis box',
      v_warning: 'Dametis box',
      v_danger: 'Dametis box',
    },
    versionEnd: {
      v_success: 'is synchronized.',
      v_default: 'is synchronizing.',
      v_warning: 'is currently offline, it will synchronize at its next connection.',
      v_danger: 'synchronization failed.',
    },
  },
  button: {
    save: 'Save',
    cancel: 'Cancel',
    seeAll: 'See all alarms',
    changeReport: 'Change report',
  },
  toast: {
    errorSelectingReport: 'Error while saving report.',
  },
};
