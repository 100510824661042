import { MacroFieldType } from '@dametis/core';

export default {
  title: {
    newMacro: 'Nouvelle macro',
    macroSelector: 'Macros',
    macro: 'Macro',
  },
  input: {
    label: {
      name: 'Nom',
      description: 'Description',
      fields: 'Sorties',
      packages: 'Packages',
      script: 'Script',
      result: 'Résultat',
      logs: 'Logs',
      tableFields: 'Champs',
      colLabel: 'Intitulé de colonne',
      type: 'Type',
      unit: 'Unité',
      pivot: 'Pivot',
      message: 'Message',
      macroResults: 'Résultats',
    },
  },
  button: {
    createMacro: 'Créer',
    editMacro: 'Modifier',
    saveMacro: 'Enregistrer',
    executeMacro: 'Exécuter',
    cantExecuteMacro: "Enregistrez la macro pour l'exécuter",
    cancel: 'Annuler',
    noChanges: 'Aucune modification',
    deleteMacro: 'Supprimer',
    export: 'Exporter en CSV',
    close: 'Fermer',
    selectMacro: 'Sélectionner une macro',
    macroMode: 'Mode macro',
    runMacro: 'Exécuter',
    duplicateMacro: 'Dupliquer',
    cantDuplicateMacro: 'Enregistrez la macro pour la dupliquer',
    forceDeploy: 'Déployer',
    reset: 'Vider Cache',
    addLib: 'Bibliothèques',
    confirm: 'Confirmer',
  },
  tooltip: {
    editMacro: 'éditer la macro',
    status_READY: 'Prête',
    status_FAILED: 'Échec',
    status_RUNNING: 'En cours',
    status_UNKNOWN: '',
  },
  toast: {
    createMacroSuccess: 'Macro créée avec succès.',
    createMacroWarning: 'Macro créée mais non fonctionnelle.',
    createMacroError: 'Une erreur est survenue lors de la création de la macro.',
    saveMacroSuccess: 'Changements enregistrés.',
    saveMacroWarning: 'Changements enregistrés mais contient des erreurs.',
    saveMacroError: 'Une erreur est survenue lors de la sauvegarde des changements.',
    deleteMacroSuccess: 'Macro supprimée avec succès.',
    deleteMacroError: 'Une erreur est survenue lors de la suppression de la macro.',
    duplicateMacroSuccess: 'Macro dupliquée avec succès.',
    duplicateMacroError: 'Une erreur est survenue lors de la duplication de la macro.',
    executeMacro: 'Exécution de la macro',
    deployMacroSuccess: 'Macro déployée avec succès',
    deployMacroWarning: 'Macro déployée mais contient des erreurs.',
    deployMacroError: 'Une erreur est survenue lors du déployement de la macro.',
  },
  text: {
    copy: 'Copie',
    total: 'TOTAL',
  },
  tabs: {
    jsonMacroResult: 'JSON',
    tableMacroResult: 'TABLE',
  },
  placeholder: {
    emptyDescription: 'Aucune description',
    noMacros: 'Aucune macro ajoutée',
    noMacro: 'Aucune macro sélectionnée',
    selectMacroEdit: 'Sélectionner une macro',
    noMacroLibs: 'Aucune bibliothèque ajoutée',
  },
  type: {
    [MacroFieldType.BOOLEAN]: 'Booléen',
    [MacroFieldType.DATE]: 'Date',
    [MacroFieldType.DURATION]: 'Durée',
    [MacroFieldType.STRING]: 'Texte',
    [MacroFieldType.NUMBER]: 'Nombre',
  },
  dialog: {
    title: {
      addLib: 'Bibliothèques',
    },
  },
};
