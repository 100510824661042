import { Dispatch, SetStateAction } from 'react';

import {
  CalculationVariable,
  CreateCalculatedVariableBody,
  DataVariable,
  ListVariableInfo,
  Operator,
  POINT_TO_POINT_GROUPBY,
  PhysicalQuantity,
  UUID,
  VariableInfo,
  VariableKind,
} from '@dametis/core';

// import * as CSV from 'csv/sync';
import readStringAsync from 'functions/daReadString';
import { sdk } from 'sdk';

import { addLevel } from '../../../../functions/tada/helpers';

const fieldNames = [
  '',
  'reference',
  'description',
  'tag1',
  'tag2',
  'tag3',
  'tag4',
  'nom',
  'unite',
  'utilites',
  'accumulateur',
  'maintien',
  'expression',
  'var[0-9]+_nom_variable',
  'var[0-9]+_operateur',
];
export interface CSVData {
  reference: string;
  tag1: string;
  tag2: string;
  tag3: string;
  tag4: string;

  description: string;

  nom: string;
  unite: string;
  utilites: string;
  accumulateur: string;
  maintien: string;
  expression: string;
  [key: `var${number}_nom_variable`]: string;
  [key: `var${number}_operateur`]: string;
}

export enum CsvParserStep {
  PARSING = 'parsing',
  BUILDING = 'building',
  POSTING = 'posting',
}

export interface CsvParserError {
  variable: string;
  errors: string[];
}

export const findVariable = (variables: ListVariableInfo[], name: string): ListVariableInfo | undefined =>
  variables.find(variable => variable.name.trim() === name.trim());

const buildVars = (
  variables: ListVariableInfo[],
  variableCSV: CSVData,
): { success: boolean; errors?: string[]; vars: Record<string, DataVariable> } => {
  const vars: Record<string, DataVariable> = {};
  const variableErrors: string[] = [];

  Object.entries(variableCSV).forEach(([key, value]) => {
    if (!(!key.startsWith('var') || value === '')) {
      const split = key.split('_');
      const v = split[0];
      const vType = split[1];
      if (vType === 'nom') {
        const dependencyVariable = findVariable(variables, value);
        if (dependencyVariable === undefined) {
          variableErrors.push(`Variable ${value} not Found`);
        } else {
          vars[v] = {
            variableUuid: dependencyVariable.uuid,
          };
        }
      }
      if (vType === 'operateur') {
        const operator = value;
        if (vars[v] === undefined) {
          variableErrors.push(`operator before nom`);
        } else if (operator === 'DELTA') {
          vars[v].operator = Operator.DELTA;
          vars[v].groupBy = POINT_TO_POINT_GROUPBY;
        } else if (operator === 'VALEUR') {
          vars[v].operator = Operator.HISTORY;
        } else {
          variableErrors.push(`unknown operator ${operator}`);
        }
      }
    }
  });

  if (variableErrors.length > 0) {
    return {
      success: false,
      errors: variableErrors,
      vars,
    };
  }

  return {
    success: true,
    vars,
  };
};

export const parseCsvTextToArray = async (fileContent: string, trimHeader: boolean = true, worker: boolean = false) => {
  const parsedFileContent = fileContent.replace(/(^,{2,})/, '');
  const csvParser = await readStringAsync(parsedFileContent, {
    // delimiter: ';',
    skipEmptyLines: 'greedy',
    header: trimHeader,
    // transformHeader isn't supported by the worker (not serializable)
    transformHeader: !worker
      ? (header: string) => header.trim().toLowerCase().replace(/ /g, '_').replace(/é/g, 'e').replace(/\./g, '_')
      : undefined,
    worker,
  });
  if (csvParser.errors?.length > 0) {
    return {
      success: false as const,
      errors: csvParser.errors,
    };
  }
  return {
    hasHeader: true as const,
    success: true,
    data: csvParser.data,
    errors: undefined,
  };
};

export const csvToCalculatedVariables = async (
  siteId: UUID,
  data: CSVData[],
  setStep: Dispatch<SetStateAction<CsvParserStep>>,
): Promise<{ success: boolean; step?: CsvParserStep; errors?: CsvParserError[]; data?: VariableInfo[] }> => {
  setStep(CsvParserStep.PARSING);

  const errors: CsvParserError[] = [];

  const parsedData = data.filter(variable => !Object.values(variable).every(field => field === ''));

  for (let index = 0; index < parsedData.length; index += 1) {
    const variable = parsedData[index];
    const variableErrors = [];
    Object.keys(variable).forEach(key => {
      if (!fieldNames.includes(key)) {
        if (!key.startsWith('var')) {
          variableErrors.push(`UNKNOWN FIELD: '${key}'`);
        }

        if (!key.endsWith('_nom_variable') && !key.endsWith('_operateur')) {
          variableErrors.push(`UNKNOWN FIELD: '${key}'`);
        }
      }
    });
    variable.accumulateur = variable.accumulateur.toLowerCase();
    if (!['oui', 'non'].includes(variable.accumulateur)) {
      variableErrors.push(`WRONG ACC FIELD: ${variable.accumulateur}`);
    }
    variable.maintien = variable.maintien.toLowerCase();
    if (!['oui', 'non'].includes(variable.maintien)) {
      variableErrors.push(`WRONG MAINTIEN FIELD: ${variable.maintien}`);
    }
    if (variableErrors.length > 0) {
      errors.push({
        variable: variable.nom,
        errors: variableErrors,
      });
    }
  }

  if (errors.length > 0) {
    return {
      success: false,
      step: CsvParserStep.PARSING,
      errors,
    };
  }

  setStep(CsvParserStep.BUILDING);

  const { data: variables } = await sdk.variable.ListOfSite(siteId);

  const calculatedVariableBodies: CreateCalculatedVariableBody[] = [];

  parsedData.forEach(variableCSV => {
    const builtVars = buildVars(variables, variableCSV);

    const variable = findVariable(variables, variableCSV.nom);
    if (variable !== undefined) {
      console.warn(`${variable.name} already exists`);
      return;
    }

    if (!builtVars.success) {
      errors.push({
        variable: variableCSV.nom,
        errors: builtVars.errors ?? [],
      });
    } else {
      let calculation: CalculationVariable<DataVariable> = {
        exp: variableCSV.expression,
        vars: builtVars.vars,
        flags: {
          accumulate: variableCSV.accumulateur === 'oui',
        },
      };

      if (variableCSV.maintien === 'oui') {
        calculation = addLevel(calculation) as CalculationVariable<DataVariable>;
      }

      calculatedVariableBodies.push({
        name: `${variableCSV.nom}`,
        unit: variableCSV.unite.toLowerCase(),
        description: variableCSV.description ?? '',
        params: calculation,
        offset: 0,
        kind: VariableKind.CALCULATED,
        reference: variableCSV.reference ?? '',
        sensorName: '',
        physicalQuantity: PhysicalQuantity.MASS,
      });
    }
  });

  if (errors.length > 0) {
    return {
      success: false,
      step: CsvParserStep.BUILDING,
      errors,
    };
  }

  setStep(CsvParserStep.POSTING);

  let createdCalculatedVariables: VariableInfo[] = [];

  try {
    const createUpdateBulkOfVariablesResponse = await sdk.variable.CreateUpdateBulkOfVariables(siteId, calculatedVariableBodies);
    createdCalculatedVariables = createUpdateBulkOfVariablesResponse.data;
  } catch (err) {
    console.error(err);
    errors.push({
      variable: 'An error occured',
      errors: ['Adding variables to database failed'],
    });
  }

  if (errors.length > 0) {
    return {
      success: false,
      step: CsvParserStep.POSTING,
      errors,
    };
  }

  return {
    success: true,
    data: createdCalculatedVariables,
  };
};
