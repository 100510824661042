import { ActivityType, ActivityVerb } from '@dametis/core';

export default {
  title: {
    activityDetails: 'Activity details',
  },
  type: {
    [ActivityVerb.CREATE]: 'created',
    [ActivityVerb.RESTORE]: 'restored',
    [ActivityVerb.DUPLICATE]: 'duplicated',
    [ActivityVerb.TRANSFER]: 'transfered',
    [ActivityVerb.UPDATE]: 'updated',
    [ActivityVerb.DELETE]: 'deleted',
    [ActivityVerb.UPLOAD]: 'uploaded',

    [ActivityVerb.SIGNIN_PASSWORD]: 'signed in with password',
    [ActivityVerb.SIGNIN_INVITATION]: 'signed in with invitation',
    [ActivityVerb.SIGNIN_SSO]: 'signed in with sso',
    [ActivityVerb.LOGOUT]: 'signed out',
    [ActivityVerb.CREATE_INVITATION]: 'invited',
    [ActivityVerb.FINALIZE_INVITATION]: 'finalized invitation',
    [ActivityVerb.LOST_PASSWORD]: 'lost password',
    [ActivityVerb.RESET_PASSWORD]: 'reset password',
  },
  object: {
    [ActivityType.GROUP]: 'the group',
    [ActivityType.SITE]: 'the site',
    [ActivityType.BOX]: 'the site',
    [ActivityType.DEVICE]: 'the device',
    [ActivityType.PLAYGROUND]: 'the playground',
    [ActivityType.REPORT]: 'the report',
    [ActivityType.SYNOPTIC]: 'the synoptic',
    [ActivityType.VARIABLE]: 'the variable',
    [ActivityType.USER]: 'the user',
    [ActivityType.ALARM]: 'the alarm',
    [ActivityType.ASSET]: 'a document',
    [ActivityType.PROJECT]: 'the project',
    [ActivityType.TASK]: 'a task',
    [ActivityType.BATCH]: 'a batch',
    [ActivityType.MODEL]: 'a model',
    [ActivityType.COMMENT]: 'a comment',
    [ActivityType.BLOCK]: 'a block',
    [ActivityType.BLOCK_TYPE]: 'a block type',
    [ActivityType.STYLE_CONFIGURATION]: 'a style configuration',
    [ActivityType.ALIAS]: 'an alias',
    [ActivityType.IMAGE]: 'an image',
    [ActivityType.TAG]: 'a tag',
    [ActivityType.OPCO]: 'a steam mix',
    [ActivityType.CALENDAR]: 'a calendar',
  },
  text: {
    activities: 'Activities',
    label: '{{firstName}} {{lastName}} $t(type.{{type}}) $t(object.{{object}})',
    noActivities: 'No activity recorded',
    displayOnlyChangedFields: 'Display only changed fields.',
    variable: {
      noUpdatedVariableFields: 'No modification',
      updatedVariableFields_zero: ' and $t(configuration:input.{{field}})',
      updatedVariableFields_one: ', $t(configuration:input.{{field}}) and an other field',
      updatedVariableFields_other: ', $t(configuration:input.{{field}}) and {{count}} other fields',
    },
  },
  button: {
    close: 'Close',
    restore: 'Restore',
    order: {
      newest: 'Newest first',
      oldest: 'Oldest first',
    },
  },
  toast: {
    restoreVariableSuccess: 'Variable restored.',
  },
};
