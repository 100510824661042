import { NumberSchema, Schema, StringSchema, addMethod, number, string } from 'yup';

import { delay, ipv4, isNot, livableDelay, notEmptyString, step, variableDelay } from './methods';

addMethod<StringSchema>(string, 'ipv4', ipv4);
addMethod<StringSchema>(string, 'isNot', isNot);
addMethod<NumberSchema>(number, 'step', step);
addMethod<StringSchema>(string, 'notEmptyString', notEmptyString);
addMethod<StringSchema>(string, 'delay', delay);
addMethod<StringSchema>(string, 'livableDelay', livableDelay);
addMethod<StringSchema>(string, 'variableDelay', variableDelay);

declare module 'yup' {
  interface StringSchema extends Schema {
    ipv4(message: string): StringSchema;
    isNot(str: string, message: string): StringSchema;
    notEmptyString(message?: string): StringSchema;
    delay(message?: string): StringSchema;
    livableDelay(message?: string): StringSchema;
    variableDelay(message?: string): StringSchema;
  }

  interface NumberSchema extends Schema {
    step(val: number, message: string): NumberSchema;
  }
}
