import { CreateAliasBody, ShortAliasInfo, ShortCorporateAliasInfo, UUID } from '@dametis/core';

import { sdk } from '../../sdk';
import store, { RootState, useSelector } from '../index';

import { api } from './index';

export const aliasesApi = api.injectEndpoints({
  endpoints: build => ({
    readAliases: build.query<ShortAliasInfo[] | ShortCorporateAliasInfo[], { groupId?: string; siteId?: string } | void>({
      providesTags: ['Aliases'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const groupId = (arg && arg.groupId) ?? state.auth.selectedGroup!.uuid;
        const siteId = (arg && arg.siteId) ?? state.auth.selectedSite?.uuid;
        try {
          const { data } = await (siteId ? sdk.alias.List(siteId) : sdk.corporate.ListAliases(groupId));
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    createAlias: build.mutation<ShortAliasInfo, CreateAliasBody>({
      invalidatesTags: ['Aliases'],
      queryFn: async (alias, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite!.uuid;
        try {
          const { data } = await sdk.alias.Create(siteId, alias);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateAlias: build.mutation<ShortAliasInfo, ShortAliasInfo>({
      invalidatesTags: ['Aliases'],
      queryFn: async alias => {
        try {
          const { data } = await sdk.alias.Update(alias.uuid, alias);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteAlias: build.mutation<void, UUID>({
      invalidatesTags: ['Aliases'],
      queryFn: async alias => {
        try {
          const { data } = await sdk.alias.Delete(alias);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const { useCreateAliasMutation, useDeleteAliasMutation, useUpdateAliasMutation } = aliasesApi;

export const selectAliases: typeof aliasesApi.endpoints.readAliases.select = arg =>
  aliasesApi.endpoints.readAliases.select(
    arg ?? { groupId: store.getState().auth.selectedGroup?.uuid, siteId: store.getState().auth.selectedSite?.uuid },
  );

export const useAliases: typeof aliasesApi.useReadAliasesQuery = (arg, options) => {
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return aliasesApi.useReadAliasesQuery(arg ?? { siteId, groupId }, { skip: !siteId && !groupId, ...options });
};
