import { FunctionsOutlined, ShuffleOutlined, SvgIconComponent } from '@mui/icons-material';
import i18next from 'i18next';

import { ModelKind } from '@dametis/core';

interface ModelConfig {
  name: string;
  mode: ModelKind;
  icon: SvgIconComponent;
}

export const modelConfig: Record<ModelKind, ModelConfig> = {
  [ModelKind.RLM]: {
    name: i18next.t(`models:text.mode.${ModelKind.RLM}`),
    mode: ModelKind.RLM,
    icon: ShuffleOutlined,
  },
  [ModelKind.EQUATION]: {
    name: i18next.t(`models:text.mode.${ModelKind.EQUATION}`),
    mode: ModelKind.EQUATION,
    icon: FunctionsOutlined,
  },
};

export const modelConfigArr: ModelConfig[] = Object.values(modelConfig);

export enum ModelPreviewType {
  LINE = 'line',
  BAR = 'bars',
}
