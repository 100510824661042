import { ArrowRight } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ShortStandardBlockInfo, UUID } from '@dametis/core';

import { DeployedCodeOutlined } from 'assets/icons/DeployedCodeOutlined';

import ContentItemContainer, { ACTIONS_CLASSNAME } from './ContentItemContainer';

export interface StandardBlockItemProps {
  standardBlock: ShortStandardBlockInfo;
  selected?: boolean;
}

const StandardBlockItem: FC<StandardBlockItemProps> = ({ standardBlock, selected = false }) => {
  // const { t } = useTranslation('lego');

  const { folderId } = useParams<{ folderId?: UUID }>();

  const itemTo = useMemo(() => `/blockTypes/${folderId}/${standardBlock.uuid}`, [folderId, standardBlock]);

  return (
    <ContentItemContainer selected={selected} state={{ noScrollTop: true }} to={itemTo}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={false}>
          <Stack alignItems="center" justifyContent="center" position="relative" width={35}>
            <DeployedCodeOutlined sx={{ color: standardBlock.ui.color }} />
          </Stack>
        </Grid>
        <Grid item xs>
          <Typography variant="h6">{standardBlock.name}</Typography>
        </Grid>
        <Grid item xs={false}>
          <Stack alignItems="center" className={ACTIONS_CLASSNAME} justifyContent="center">
            <ArrowRight />
          </Stack>
        </Grid>
      </Grid>
    </ContentItemContainer>
  );
};

export default StandardBlockItem;
