import { Add } from '@mui/icons-material';
import { Box, List } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePermission } from 'hooks/usePermission';
import { useSelector } from 'store';
import { DrawerActionName, DrawerActionType, RouteScope, RouteWithIndex } from 'types/drawer';

import SubRoute, { RouteItem } from './SubRoute';

export interface DrawerActionsListProps {
  route: RouteWithIndex;
  type: DrawerActionType;
}

const DrawerActionsList: FC<DrawerActionsListProps> = ({ route, type }) => {
  const { t } = useTranslation('navbar');
  const navigate = useNavigate();

  const canEditReport = usePermission('canEditReport');

  const corporate = useSelector(state => !state.auth.selectedSite);

  const unfilteredSubRoutes = useMemo(
    () => ({
      [DrawerActionType.PLAYGROUNDS]: [
        {
          action: () => {
            navigate('/playgrounds/new', { state: { drawerAction: DrawerActionName.NEW_PLAYGROUND_WITH_TY_CHART } });
          },
          icon: Add,
          name: DrawerActionName.NEW_PLAYGROUND_WITH_TY_CHART,
          scopes: [RouteScope.SITE, RouteScope.GROUP],
        },
      ],
      ...(canEditReport && {
        [DrawerActionType.REPORTS]: [
          {
            action: () => {
              navigate('/reports/new');
            },
            icon: Add,
            name: DrawerActionName.NEW_REPORT_CUSTOM_PERIOD,
            scopes: [RouteScope.SITE, RouteScope.GROUP],
          },
        ],
      }),
    }),
    [canEditReport, navigate],
  );

  const subRoutes = useMemo(
    () => unfilteredSubRoutes[type]?.filter(action => action.scopes.includes(corporate ? RouteScope.GROUP : RouteScope.SITE)),
    [corporate, type, unfilteredSubRoutes],
  );

  if (!unfilteredSubRoutes[type]) {
    return null;
  }

  return (
    <Box sx={theme => ({ pt: theme.others.pagePaddingTop, pb: theme.others.pagePaddingBottom })}>
      <List>
        <RouteItem disabled to="">
          {t('menu.item.actions', { count: subRoutes?.length ?? 0 })}
        </RouteItem>
        {subRoutes?.map(subRoute => (
          <SubRoute key={subRoute.name} route={route} subRoute={{ ...subRoute, name: t(`menu.actions.${subRoute.name}`) }} />
        ))}
      </List>
    </Box>
  );
};

export default DrawerActionsList;
