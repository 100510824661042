import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Virtuoso } from 'react-virtuoso';

import { useVncStore } from 'zustand/stores/vnc';

import ListItem from './ListItem';
import ListItemSkeleton from './ListItemSkeleton';

const skeletonItems = Array.from({ length: 10 }, (v, k) => k);

const VariablesList: FC = () => {
  const { t } = useTranslation('vnc');

  const variables = useVncStore(state => state.results.variables);
  const loadingResults = useVncStore(state => state.loadingResults);

  if (!variables.length && !loadingResults)
    return (
      <Typography align="center" sx={{ mt: 4 }} variant="subtitle2">
        {t('subtitle.noVariables')}
      </Typography>
    );
  return (
    <AutoSizer>
      {({ width, height }) =>
        loadingResults ? (
          <Virtuoso
            data={skeletonItems}
            // eslint-disable-next-line react/no-unstable-nested-components
            itemContent={index => <ListItemSkeleton dataLength={skeletonItems.length} index={index} />}
            style={{ width, height }}
          />
        ) : (
          <Virtuoso
            data={variables}
            // eslint-disable-next-line react/no-unstable-nested-components
            itemContent={(index, data) => <ListItem dataLength={variables.length} index={index} item={data} />}
            style={{ width, height }}
          />
        )
      }
    </AutoSizer>
  );
};

export default VariablesList;
