export default {
  title: 'Ajustes de grupo',
  groupMenu: {
    informations: {
      name: 'Información',
      description: '',
    },
    users: {
      name: 'Usuarios',
      description: '',
    },
  },
  informations: {
    form: {
      siteName: 'Nombre del sitio',
      groupName: 'Nombre del grupo',
      defaultReport: 'Informe de inicio predeterminado',
      selectReport: 'Seleccionar un informe',
      noReport: 'Ningún informe predeterminado',
      address: 'Dirección',
      language: 'Idioma del sitio',
      timeZone: 'Zona horaria',
      noTimeZoneFound: 'No hay ninguna zona horaria correspondiente',
    },
  },
  toast: {
    successSaveChanges: 'Los cambios se han guardado correctamente.',
    errorSaveChanges: 'No se pueden guardar los cambios.',
    infoUserInvitation: 'Se ha enviado una invitación por correo electrónico.',
    errorUserInvitation: 'No se puede enviar la invitación.',
    userAlreadyExists: 'Esta dirección de correo electrónico ya está asociada a una cuenta.',
    successNewPeriod: 'El nuevo período ha sido guardado.',
    successRemovePeriod: 'El período ha sido eliminado correctamente.',
  },
  tooltip: {
    editGroupInfo: 'Modificar la información del grupo',
  },
  modal: {
    title: 'Modificar usuario',
    addTitle: 'Añadir un usuario',
    personalInformation: 'Información personal',
    permissions: 'Permisos',
    firstName: 'Nombre',
    lastName: 'Apellido',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    corporateLabel: 'Acceso al grupo',
    corporate: 'Corporate',
    userSiteList: 'Sitios del usuario',
    warningEmptySites: 'El usuario debe tener al menos un sitio',
    select: {
      groupRole: 'Rol del grupo',
      siteRole: 'Rol del sitio',
      roleSITE_ADMINPrimary: 'Administrador',
      roleSITE_ADMINSecondary: 'Administración, lectura y escritura',
      roleCLIENTPrimary: 'Usuario estándar',
      roleCLIENTSecondary: 'Lectura y escritura',
      roleReadOnlyPrimary: 'Usuario limitado',
      roleReadOnlySecondary: 'Solo lectura',
    },
    button: {
      next: 'Siguiente',
      previous: 'Anterior',
    },
  },
  stepper: {
    informations: 'Información',
    permissions: 'Permisos',
  },
};
