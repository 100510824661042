import { v4 as uuidv4 } from 'uuid';

import { PhysicalQuantity, StandardBlockParameter, StandardBlockParameterBody } from '@dametis/core';

export const createStandardBlockParameter = ({
  uuid = uuidv4(),
  blockKey = '',
  unit = '',
  physicalQuantity = PhysicalQuantity.NONE,
  defaultValue = null,
  isSelected = false,
}: Partial<StandardBlockParameter>): StandardBlockParameter => ({
  uuid,
  blockKey,
  unit,
  physicalQuantity,
  defaultValue,
  isSelected,
});

export const createStandardBlockParameterBody = ({
  blockKey = '',
  unit = '',
  physicalQuantity = PhysicalQuantity.NONE,
  defaultValue = null,
  isSelected = false,
}: Partial<StandardBlockParameterBody> = {}): StandardBlockParameterBody => ({
  blockKey,
  unit,
  physicalQuantity,
  defaultValue,
  isSelected,
});
