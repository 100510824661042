import { SvgIconComponent } from '@mui/icons-material';

import { IsOneOf, Shortcut, ShortcutCategory, UUID } from '@dametis/core';

import { EntityType } from './entity';

export interface ShortcutConfig {
  icon?: SvgIconComponent;
  category: ShortcutCategory;
  enabled: boolean;
  corporate?: boolean;
  svg?: string;
}

export const entityToShortcutTypes = {
  [EntityType.ALARM]: ShortcutCategory.ALARM as const,
  [EntityType.PLAYGROUND]: ShortcutCategory.PLAYGROUND as const,
  [EntityType.PROJECT]: ShortcutCategory.PROJECT as const,
  [EntityType.REPORT]: ShortcutCategory.REPORT as const,
  [EntityType.SYNOPTIC]: ShortcutCategory.SYNOPTIC as const,
};

export const shortcutToEntityTypes = {
  [ShortcutCategory.ALARM]: EntityType.ALARM as const,
  [ShortcutCategory.PLAYGROUND]: EntityType.PLAYGROUND as const,
  [ShortcutCategory.PROJECT]: EntityType.PROJECT as const,
  [ShortcutCategory.REPORT]: EntityType.REPORT as const,
  [ShortcutCategory.SYNOPTIC]: EntityType.SYNOPTIC as const,
};

export const entityTypes = Object.values(shortcutToEntityTypes);
export const shortcutTypes = Object.values(entityToShortcutTypes);

export type SelectableShortcutEntityCategory = keyof typeof entityToShortcutTypes;
export type SelectableEntityShortcutCategory = keyof typeof shortcutToEntityTypes;
export type SelectableEntityShortcut = {
  category: SelectableEntityShortcutCategory;
  groupId: UUID;
  label?: string;
  siteId?: UUID;
  url?: string;
  uuid: UUID;
};
export type SelectableHrefShortcut = { category: ShortcutCategory.HREF; label: string; url: string };

export type SelectableShortcut = SelectableEntityShortcut | SelectableHrefShortcut;

export const externalUrlRegex = /^(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\\/]))?$/;

export const IsSelectableEntityShortcut = (shortcut: Shortcut | undefined): shortcut is SelectableEntityShortcut =>
  shortcut !== undefined && IsOneOf(shortcut.category, Object.values(entityToShortcutTypes));

export const IsSelectableHrefShortcut = (shortcut: Shortcut | undefined): shortcut is SelectableHrefShortcut =>
  shortcut !== undefined && shortcut.category === ShortcutCategory.HREF;

export const IsSelectableShortcut = (shortcut: Shortcut | undefined): shortcut is SelectableShortcut =>
  IsSelectableEntityShortcut(shortcut) || IsSelectableHrefShortcut(shortcut);
