import { AlarmPriority } from '@dametis/core';

export default {
  title: {
    alarm: 'Alarme « {{name}} »',
    newAlarm: 'Nouvelle alarme',
    when: 'Conditions',
    do: 'Actions',
    logs: 'Journal',
    leave: 'Quitter sans sauvegarder',
  },
  button: {
    createAlarm: 'Créer',
    addRule: 'Ajouter',
    addAction: 'Ajouter',
    cancel: 'Annuler',
    add: 'Ajouter',
    validate: 'Valider',
  },
  input: {
    label: {
      name: 'Nom',
      criticality: 'Criticité',
      durationValue: 'Durée minimale avant activation',
      calendar: "Période d'activation",
      condition: "Règle d'activation",
      email: 'Adresse email',
      phone: 'Téléphone',
      autoAck: 'AutoAck',
      tamatPoste: 'Poste Tamat',
      ackCommentRequired: "Commentaire à l'acquittement",
    },
    helperText: {
      advanced: 'Comparer à un calcul',
      simple: 'Comparer à une valeur',
    },
    adornment: {
      durationValue: 'pendant',
    },
    value: {
      joinWith_and: 'ET',
      joinWith_or: 'OU',
      required: 'Obligatoire',
      optional: 'Optionnel',
    },
  },
  actions: {
    app: {
      showInActiveAlarms: 'Recevoir une notification dans Alarmes actives',
    },
    emails: {
      title: 'Email',
      sendEmail: 'Recevoir un email à...',
    },
    phone: {
      title: 'Téléphone',
      sendText: 'Recevoir un SMS au...',
    },
    whatsapp: {
      title: 'WhatsApp',
      sendMessage: 'Recevoir un message WhatsApp au...',
    },
    tamat: {
      title: 'Tamat',
      sendAlarm: "Envoyer l'alarme au Tamat...",
    },
  },
  criticality: {
    [`variant_${AlarmPriority.NOTIFICATION}`]: 'Notification',
    [`variant_${AlarmPriority.WARNING}`]: 'Attention',
    [`variant_${AlarmPriority.CRITICAL}`]: 'Critique',
  },
  toast: {
    createAlarmSuccess: 'Alarme créée avec succès.',
    createAlarmError: "Une erreur est survenue lors de la création de l'alarme.",
    saveAlarmSuccess: 'Changements enregistrés.',
    saveAlarmError: 'Une erreur est survenue lors de la sauvegarde des changements.',
    deleteAlarmSuccess: 'Alarme supprimée avec succès.',
    deleteAlarmError: "Une erreur est survenue lors de la suppression de l'alarme.",
  },
  tooltip: {
    saveAlarm: "enregistrer l'alarme",
    acknowledgeAlarm: "acquitter l'alarme",
    shareAlarm: "partager l'alarme",
    cancelAlarm: "annuler l'alarme",
    deleteAlarm: "Supprimer l'alarme",
    muteAlarm: "mettre en sourdine l'alarme",
    unmuteAlarm: "rendre active l'alarme",
    addCondition: 'ajouter une alarme',
    deleteCondition: "supprimer l'alarme",
    addAction: 'ajouter une action',
    noChangeYet: 'Pas encore de changement',
    previousChart: 'Graphique précédent',
    nextChart: 'Graphique suivant',
    autoAck:
      "Quand l'option AutoAck est activée, l'alarme est automatiquement acquittée lors de la résolution de l'incident. Vous recevrez toujours une notification lors du déclenchement de l'alarme.",
  },
};
