import { Box, styled } from '@mui/material';

const RightPanelSectionContent = styled(Box)<{ noPadding?: boolean }>(({ theme, noPadding }) => ({
  padding: noPadding ? undefined : `0 ${theme.spacing(2)}`,
  '& > :first-child': {
    marginTop: 0,
  },
  '& > :last-child': {
    marginBottom: 0,
  },
}));

export default RightPanelSectionContent;
