import { AddOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { IconButton, InputLabel, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockConnection, BlockTypeConnection, BlockTypeInfo, ConnectionType, IsBlockConnection } from '@dametis/core';

import { setColorLightness } from 'functions/color';

import BlockTypeIcon from './BlockType/BlockTypeIcon';

export const CONNECTION_ACTIONS_CLASSNAME = 'connectionActions';

export interface ConnectionPreviewTitleProps {
  connection: BlockTypeConnection | BlockConnection;
  blockType: BlockTypeInfo | undefined;
  onAddBlock?: () => Promise<void> | void;
}

const ConnectionPreviewTitle: FC<ConnectionPreviewTitleProps> = ({ connection, blockType, onAddBlock = undefined }) => {
  const { t } = useTranslation('lego');
  const theme = useTheme();

  const [isAddingBlock, setIsAddingBlock] = useState<boolean>(false);

  const color = useMemo(() => (blockType ? blockType.content.color : theme.palette.text.primary), [blockType, theme]);
  const canAddBlock = useMemo(
    () => IsBlockConnection(connection) && !(connection.type === ConnectionType.UNIQUE && connection.blockIds.length > 0),
    [connection],
  );

  const handleAddBlock = useCallback(async () => {
    if (!onAddBlock) {
      return;
    }
    setIsAddingBlock(true);
    await onAddBlock();
    setIsAddingBlock(false);
  }, [onAddBlock]);

  return (
    <Stack
      alignItems="center"
      direction="row"
      p={1}
      position="relative"
      spacing={1}
      sx={{ [`& .${CONNECTION_ACTIONS_CLASSNAME}`]: { opacity: 0 }, [`&:hover .${CONNECTION_ACTIONS_CLASSNAME}`]: { opacity: 1 } }}
    >
      {blockType ? (
        <BlockTypeIcon primaryIconColor={blockType.content.color} sx={{ fontSize: 18 }} />
      ) : (
        <WarningAmberOutlined color="warning" sx={{ fontSize: 18 }} />
      )}
      <Stack gap={0.5}>
        {/* <Typography variant="overline">{t(`connectionType.${connection.type}`)}</Typography> */}
        <Stack direction="row" gap={1}>
          <Typography lineHeight={1} variant="caption">
            {blockType ? blockType.name : t('tooltip.unknownBlockType')}
          </Typography>
          {/* <Divider flexItem orientation="vertical" />
          <Typography lineHeight={1} variant="caption">
            {t(`connectionType.${connection.type}`)}
          </Typography> */}
        </Stack>
        <InputLabel sx={{ lineHeight: 1, color: setColorLightness(color, 30) }}>{connection.blockKey}</InputLabel>
      </Stack>
      {/* <Tooltip title={t(`connectionType.${connection.type}`)}>
        <SvgIcon
          component={connection.type === ConnectionType.UNIQUE ? FiberManualRecordOutlined : WorkspacesOutlined}
          sx={{ ml: 'auto' }}
          color="icon"
          fontSize="small"
        />
      </Tooltip> */}
      {!!onAddBlock && canAddBlock && (
        <Stack
          alignItems="center"
          className={CONNECTION_ACTIONS_CLASSNAME}
          direction="row"
          height={1}
          position="absolute"
          pr={1}
          right={0}
          sx={{
            background: setColorLightness(color, 93),
            borderRadius: '6px',
          }}
          top={0}
        >
          <Tooltip title={t('tooltip.addBlocks')}>
            <IconButton disabled={isAddingBlock} size="small" onClick={handleAddBlock}>
              <AddOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};

export default ConnectionPreviewTitle;
