import { Multiple } from '@dametis/unit';

export enum UnitPickerMenu {
  LIST = 'list',
  COLUMNS = 'columns',
}

export interface CommonProps {
  value?: UnitResult;
  onChange: (value: UnitResult) => void;
  baseUnit?: string;
  freeMode?: boolean;
  noUnitEnabled?: boolean;
  defaultMenu?: UnitPickerMenu.LIST;
}

export type UnitResult = string | null;

export enum ListElementType {
  SUBHEADER = 'subheader',
  ITEM = 'item',
  NEW_OPTION = 'newOption',
}

export interface ListElementSubheaderData {
  unit: string;
  unitName: string;
}

export interface ListElementItemData {
  unit: string;
  multiple: Multiple;
  unitName: string;
  unitWithMultiple: string;
  unitWithMultipleName: string;
}

export interface ListElementNewOptionData {
  value: string;
}

export interface ListElementDataOptions {
  [ListElementType.SUBHEADER]: ListElementSubheaderData;
  [ListElementType.ITEM]: ListElementItemData;
  [ListElementType.NEW_OPTION]: ListElementNewOptionData;
}

export type ListElementData<T extends ListElementType = ListElementType> = ListElementDataOptions[T];

export interface ListElement<T extends ListElementType = ListElementType> {
  type: T;
  data: ListElementData<T>;
}

export type ListElementSubheader = ListElement<ListElementType.SUBHEADER>;

export const IsListElementSubheader = (listElement: ListElement): listElement is ListElementSubheader =>
  listElement.type === ListElementType.SUBHEADER;

export type ListElementItem = ListElement<ListElementType.ITEM>;

export const IsListElementItem = (listElement: ListElement): listElement is ListElementItem => listElement.type === ListElementType.ITEM;

export type ListElementNewOption = ListElement<ListElementType.NEW_OPTION>;

export const IsListElementNewOption = (listElement: ListElement): listElement is ListElementNewOption =>
  listElement.type === ListElementType.NEW_OPTION;
