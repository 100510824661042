import { ButtonBase, Paper, PaperProps, alpha, styled } from '@mui/material';

export interface BlockTileContainerProps extends PaperProps {
  selected?: boolean;
}

const BlockTileContainer = styled(
  (props: BlockTileContainerProps) => <Paper component={props.onClick ? ButtonBase : undefined} {...props} />,
  { shouldForwardProp: propName => propName !== 'selected' },
)(({ theme, selected }) => ({
  width: '100%',
  display: 'block',
  boxShadow: selected ? `0 0 0 3px ${theme.palette.secondary.light}` : undefined,
  padding: theme.spacing(2),
  textAlign: 'left',
  position: 'relative',
  backgroundColor: alpha(theme.palette.background.paper, 1),
}));

export default BlockTileContainer;
