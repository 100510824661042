export default {
  button: {
    addRow: 'Agregar una línea',
    addVariable: 'Variable',
    clearAllSelection: 'Vaciar',
    removeCol: 'Vaciar columna',
    delete: 'Suprimir',
    edit: 'Modificar',
    manualEntry: 'Guardar',
    existingVariable: 'Agregar una variable',
    createNewVariable: 'Crear una variable manual',
    keepExistingData: 'Cancelar',
    keepNewData: 'Reemplazar',
  },
  helper: {
    here: 'aquí',
    movedCsvImport: 'Encuentra la importación CSV',
    noVariables: 'Seleccionar una lista o agregar una variable',
  },
  input: {
    date: 'Fecha',
    dateFormat: 'Fecha y hora',
  },
  legend: {
    edited: 'Nuevo dato',
    deleted: 'Dato eliminado',
  },
  menu: {
    upsertRow: 'Insertar una línea arriba',
    insertRow: 'Insertar una línea abajo',
    deleteRow: 'Suprimir línea',
    restoreRow: 'Restaurar línea',
  },
  table: {
    column: {
      date: 'Fecha',
      variable: 'Variable',
    },
    row: {
      noRows: 'Ninguna línea',
    },
  },
  text: {
    existingDataWarning: 'Ya existe un dato en esta fecha, ¿desea reemplazarlo?',
    noImportList: 'Ninguna lista de importación',
    selectList: 'Seleccionar una lista',
    newList: 'Nueva lista',
    more_one: 'otro',
    more_other: 'otros',
  },
  title: {
    manualEntry: 'Entrada manual',
    existingDataWarning: 'Dato existente',
    recentList: 'Listas utilizadas recientemente',
  },
  toast: {
    manualEntryPatchSuccess: 'Operación en curso',
  },
  tooltip: {
    createList: 'Crear una lista',
    deleteList: 'Suprimir esta lista',
    saveList: 'Guardar esta lista',
  },
};
