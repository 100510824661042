import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import GoToPlaygroundButtonBase, { GoToPlaygroundIconButtonProps } from './GoToPlaygroundButtonBase';

const GoToPlaygroundIconButton: FC<GoToPlaygroundIconButtonProps> = ({ tooltipProps, ...props }) => {
  const { t } = useTranslation('playground');

  return (
    <Tooltip title={t('tooltip.openInPlayground')} {...(tooltipProps ?? {})}>
      <GoToPlaygroundButtonBase isIcon {...props} />
    </Tooltip>
  );
};

export default GoToPlaygroundIconButton;
