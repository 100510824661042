import { List } from '@mui/material';
import Fuse from 'fuse.js';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import { MultipleName, MultiplesByName, UnitName } from '@dametis/unit';

import UnitListRow from './UnitListRow';
import { useUnitPickerContext } from './UnitPickerContext';
import { getUnitName } from './functions/getUnitName';
import { getUnitRelevantMultiples } from './functions/getUnitRelevantMultiples';
import { IsListElementItem, ListElement, ListElementItem, ListElementNewOption, ListElementType } from './types';

export interface UnitListProps {
  units: string[];
}

const UnitList: FC<UnitListProps> = ({ units }) => {
  const { t } = useTranslation('unit');

  const { inputValue, setListElements, freeMode, focusIndex } = useUnitPickerContext();

  const listRef = useRef<VariableSizeList<ListElement<ListElementType>[]> | null>(null);

  const listElements: ListElement[] = useMemo(
    () =>
      units.reduce<ListElement[]>((acc, unit) => {
        if (unit === UnitName.NO_UNIT) {
          acc.push({
            type: ListElementType.ITEM,
            data: {
              unit,
              multiple: MultiplesByName[MultipleName.NONE],
              unitName: '',
              unitWithMultiple: t('text.noUnitElement'),
              unitWithMultipleName: '',
            },
          });
          return acc;
        }
        return acc.concat(
          getUnitRelevantMultiples(unit).map<ListElementItem>(multiple => ({
            type: ListElementType.ITEM,
            data: {
              unit,
              multiple,
              unitName: getUnitName(unit, t),
              unitWithMultiple: `${multiple.symbol}${unit}`,
              unitWithMultipleName: `${multiple.symbol !== '' ? multiple.name : ''}${getUnitName(unit, t)}`,
            },
          })),
        );
      }, []),
    [units, t],
  );

  const filteredListElements: ListElement[] = useMemo(() => {
    const searched = inputValue.trim().length !== 0;
    let newListElements: ListElement[] = [];

    if (searched) {
      // Si il y a une recherche, filtrer les éléments
      const fuse = new Fuse(listElements, {
        keys: ['data.unit', 'data.unitName', 'data.unitWithMultiple', 'data.unitWithMultipleName', 'data.multiple.name'],
      });
      newListElements = fuse.search(inputValue).map(fR => fR.item);
    } else {
      // Sinon, renvoyer la liste de base
      newListElements = listElements;
    }

    if (searched && freeMode && !newListElements.some(element => IsListElementItem(element) && element.data.unit === inputValue.trim())) {
      const newOptionElement: ListElementNewOption = {
        type: ListElementType.NEW_OPTION,
        data: {
          value: inputValue.trim(),
        },
      };
      newListElements.unshift(newOptionElement);
    }

    return newListElements;
  }, [inputValue, freeMode, listElements]);

  const getItemSize = useCallback(() => 50, []);

  useEffect(() => {
    setListElements(filteredListElements);
  }, [filteredListElements, setListElements]);

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.scrollToItem(focusIndex);
    }
  }, [focusIndex]);

  return (
    <List sx={{ height: 300, width: 300 }}>
      <AutoSizer>
        {({ width, height }) => (
          <VariableSizeList
            ref={listRef}
            height={height}
            itemCount={filteredListElements.length}
            itemData={filteredListElements}
            itemSize={getItemSize}
            style={{ scrollBehavior: 'smooth' }}
            width={width}
          >
            {UnitListRow}
          </VariableSizeList>
        )}
      </AutoSizer>
    </List>
  );
};

export default UnitList;
