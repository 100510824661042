import { Close } from '@mui/icons-material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BlockTypeInfo, ShortStandardBlockInfo, UUID } from '@dametis/core';

// import EntityPanelSubtitle from 'components/UI/EntityPanelSubtitle/EntityPanelSubtitle';
import EntityPanelSubtitle from 'components/UI/EntityPanelSubtitle/EntityPanelSubtitle';
import { RightPanel, RightPanelHeader, RightPanelHeaderButton, RightPanelHeaderText } from 'components/UI/RightPanel';
import RightPanelDrawer from 'components/UI/RightPanel/RightPanelDrawer';
import { useBlockTypes } from 'store/api/blockTypes';
import { useStandardBlocks } from 'store/api/standardBlocks';

import BlockTypeRightPanel from './BlockTypeRightPanel/BlockTypeRightPanel';
import StandardBlockRightPanel from './StandardBlockRightPanel/StandardBlockRightPanel';

const blockTypesEmptyArray: BlockTypeInfo[] = [];
const standardBlocksEmptyArray: ShortStandardBlockInfo[] = [];

const CatalogRightPanel: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('lego');

  const { folderId, entityId } = useParams<{ folderId?: UUID; entityId?: UUID }>();

  const { data: blockTypes = blockTypesEmptyArray } = useBlockTypes();
  const { data: standardBlocks = standardBlocksEmptyArray } = useStandardBlocks();
  const currentBlockType: BlockTypeInfo | null = useMemo(
    () => blockTypes.find(blockType => blockType.uuid === entityId) ?? null,
    [blockTypes, entityId],
  );

  const currentStandardBlock: ShortStandardBlockInfo | null = useMemo(
    () => standardBlocks.find(standardBlock => standardBlock.uuid === entityId) ?? null,
    [standardBlocks, entityId],
  );

  const currentEntity = useMemo(() => currentBlockType ?? currentStandardBlock ?? null, [currentBlockType, currentStandardBlock]);

  const handleClosePanel = useCallback(() => {
    navigate(`/blockTypes/${folderId}`, { state: { noScrollTop: true } });
  }, [navigate, folderId]);

  return (
    <>
      <RightPanelDrawer>
        <RightPanel>
          <RightPanelHeader>
            <RightPanelHeaderButton icon={Close} onClick={handleClosePanel} />
            <RightPanelHeaderText
              subtitle={currentEntity ? <EntityPanelSubtitle entity={currentEntity} /> : undefined}
              title={currentEntity?.name ?? t('text.unknownType')}
            />
          </RightPanelHeader>
          {currentBlockType && <BlockTypeRightPanel blockType={currentBlockType} />}
          {currentStandardBlock && <StandardBlockRightPanel standardBlock={currentStandardBlock} />}
        </RightPanel>
      </RightPanelDrawer>
    </>
  );
};

export default CatalogRightPanel;
