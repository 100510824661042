import { CalculationVariable, ExpressionVariable, VarCalc } from '@dametis/core';

export const createCalculationVariable = <T extends VarCalc = VarCalc>({
  exp = '',
  vars = {},
  ...rest
}: Partial<CalculationVariable<T>> = {}): ExpressionVariable<T> => ({
  exp,
  vars,
  ...rest,
});
