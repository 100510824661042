import { RelativeToNowPeriod } from 'components/UI/RelativeToNowPeriodPicker/RelativeToNowPeriodPicker';

const relativeToNowPeriods: Record<RelativeToNowPeriod, string> = {
  [RelativeToNowPeriod.ONE_DAY]: '1 day',
  [RelativeToNowPeriod.THREE_DAYS]: '3 days',
  [RelativeToNowPeriod.ONE_WEEK]: '1 week',
  [RelativeToNowPeriod.TWO_WEEKS]: '2 weeks',
  [RelativeToNowPeriod.ONE_MONTH]: '1 month',
};

export default {
  title: {
    absoluteTimeRange: 'Absolute time range',
    relativeTimeRanges: 'Relative time ranges',
    refreshRate: 'Refresh rate',
    liveMode: 'Live',
    history: 'History',
  },
  input: {
    from: {
      label: 'From',
    },
    to: {
      label: 'To',
    },
  },
  button: {
    lastXUnitOfTime: 'Last {{value}} $t(global:unit.time_{{unit}}, { "count": {{value}} })',
    refreshRate: '{{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
    refreshRate_off: 'off',
    submit: 'Submit',
    nextQuarterPeriod: 'Move forward by a quarter of a period',
    previousQuarterPeriod: 'Move backward by a quarter of a period',
    nextPeriod: 'Move one period forward',
    previousPeriod: 'Move one period backward',
    clear: 'Clear',
  },
  tabs: {
    calendar: 'Calendar',
    batches: 'Batches',
    label: 'Tabs',
  },
  text: {
    since: 'Since',
    empty: 'Nothing to display',
    noTimeRange: 'No time range in history',
    fixedPeriodDuration: 'Each custom period has the same duration as the first one, i.e. {{duration}}.',
    duration: 'Duration: ',
  },
  tooltip: {
    previousPeriod: 'Previous period',
    nextPeriod: 'Next period',
  },
  periods: {
    year: 'Year',
    month: 'Month',
  },
  relativeToNowPeriods,
  sinceRelative: 'Since $t(dateTimePicker:relativeToNowPeriods.{{value}})',
  now: 'Now',
};
