import { StandardTaskStatus } from '@dametis/core';

export default {
  title: {
    tasks: 'Tâches',
    redirection: 'Redirection',
  },
  button: {
    cancel: 'Annuler',
    continue: 'Continuer',
    reset: 'Réinitialiser',
  },
  label: {
    template: 'Modèle',
    project: 'Projet',
  },
  select: {
    withoutTemplate: 'Sans modèle',
  },
  text: {
    redirection: 'Vous allez être redirigé vers le site',
    unassigned: 'Non assignée',
    noTasks: 'Aucune tâche',
    noOptions: 'Aucune option',
    projectsLength_zero: 'Aucun projet',
    projectsLength_one: '1 projet',
    projectsLength_other: '{{count}} projets',
    noTemplate: 'Projets sans template',
    noProject: 'Aucun projet',
  },
  tasks: {
    status: {
      [`variant_${StandardTaskStatus.TODO}`]: 'À faire',
      [`variant_${StandardTaskStatus.ONGOING}`]: 'En cours',
      [`variant_${StandardTaskStatus.TESTING}`]: 'À tester',
      [`variant_${StandardTaskStatus.DONE}`]: 'Terminée',
    },
  },
};
