import { Add } from '@mui/icons-material';
import { styled } from '@mui/material';

/**
 @private Use Hotkeys instead
 */
export const KBMPlus = styled(Add, { shouldForwardProp: propName => propName !== 'size' })<{ size?: 'small' | 'medium' }>(
  ({ theme, size }) => ({
    opacity: 0.75,
    color: 'inherit',
    margin: size === 'small' ? undefined : `0 ${theme.spacing(0.25)}`,
    fontSize: size === 'small' ? '14px' : '1em',
  }),
);
