export default {
  toast: {
    errorUploadingPreview: 'Error durante la descarga de la imagen.',
    errorReadingFilePreview: 'No se puede leer el archivo.',
    errorFileTypePreview: 'Tipo de archivo incorrecto.',
    errorDeletingPreview: 'Error durante la eliminación de la imagen.',
  },
  tooltip: {
    editPhoto: `Changer d'image`,
    deletePhoto: `Supprimer l'image`,
  },
};
