export default {
  label: {
    default: 'Default',
    live: 'Live',
    muted: 'Muted',
    custom: 'Custom',
    invalid: 'Invalid',
  },
  select: {
    default: 'Default',
    live: 'Live',
    muted: 'Muted',
    custom: 'Custom',
  },
  text: {
    noValue: 'n/a',
  },
  tooltip: {
    default: 'Default',
    live: 'Live',
    muted: 'Muted',
    custom: 'Custom',
  },
};
