export default {
  button: {
    addRow: 'Ajouter une ligne',
    addVariable: 'Variable',
    clearAllSelection: 'Vider',
    removeCol: 'Retirer la colonne',
    delete: 'Supprimer',
    edit: 'Modifier',
    manualEntry: 'Appliquer',
    existingVariable: 'Ajouter une variable',
    createNewVariable: 'Créer une variable manuelle',
    keepExistingData: 'Annuler',
    keepNewData: 'Remplacer',
    addFirstVariable: 'Sélectionner une première variable',
  },
  helper: {
    here: 'ici',
    movedCsvImport: "Retrouvez l'import CSV ",
    noVariables: 'Sélectionner une liste ou ajouter une variable',
    rightClick: "Cliquez droit sur une ligne pour plus d'options",
  },
  input: {
    date: 'Date',
    dateFormat: 'Date et heure',
  },
  legend: {
    edited: 'Nouvelle donnée',
    deleted: 'Donnée supprimée',
  },
  menu: {
    upsertRow: 'Insérer une ligne au dessus',
    insertRow: 'Insérer une ligne en dessous',
    deleteRow: 'Supprimer la ligne',
    restoreRow: 'Restaurer la ligne',
  },
  table: {
    column: {
      date: 'Date',
      variable: 'Variable',
    },
    row: {
      noRows: 'Aucune donnée',
    },
  },
  text: {
    existingDataWarning: 'De la donnée existe déjà à cette date, voulez-vous la remplacer ?',
    fetchLimitWarning: 'Vous ne voyez que les 10 000 premières lignes',
    noImportList: "Aucune liste d'import",
    selectList: 'Sélectionner une liste',
    newList: 'Nouvelle liste',
    more_one: 'autre',
    more_other: 'autres',
    or: 'ou',
  },
  title: {
    manualEntry: 'Saisie manuelle',
    existingDataWarning: 'Donnée existante',
    recentList: 'Listes récemment utilisées',
  },
  toast: {
    manualEntryPatchSuccess: 'Opération en cours de traitement',
  },
  tooltip: {
    createList: 'Créer une liste',
    deleteList: 'Supprimer cette liste',
    saveList: 'Sauvegarder cette liste',
  },
};
