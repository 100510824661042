import { v4 as uuidv4 } from 'uuid';

import {
  BlockTypeMetadata,
  BlockTypeMetadataNumberContent,
  BlockTypeMetadataOptionContent,
  BlockTypeMetadataStringContent,
  MetadataScope,
  MetadataType,
} from '@dametis/core';

export const createBlockTypeMetadata = ({
  uuid = uuidv4(),
  name = '',
  type = MetadataType.STRING,
  content = createBlockTypeMetadataStringContent(),
  ui = {},
}: Partial<Omit<BlockTypeMetadata, 'scope'>> = {}): BlockTypeMetadata => ({
  uuid,
  name,
  type,
  scope: MetadataScope.BLOCK_TYPE,
  content,
  ui,
});

export const createBlockTypeMetadataStringContent = (): BlockTypeMetadataStringContent => ({});
// ({}: Partial<BlockTypeMetadataStringContent> = {}): BlockTypeMetadataStringContent => ({});

export const createBlockTypeMetadataOptionContent = ({
  availableOptions = [],
}: Partial<BlockTypeMetadataOptionContent> = {}): BlockTypeMetadataOptionContent => ({ availableOptions });

export const createBlockTypeMetadataNumberContent = ({
  unit = null,
}: Partial<BlockTypeMetadataNumberContent> = {}): BlockTypeMetadataNumberContent => ({ unit });
