import { UpdateFolderBody } from '@dametis/core';

export const createUpdateFolderBody = ({
  name = '',
  shortcuts = [],
  ui = {},
  operation = undefined,
}: Partial<UpdateFolderBody> = {}): UpdateFolderBody => ({
  name,
  shortcuts,
  ui,
  operation,
});
