import { LoadingButton, LoadingButtonProps, loadingButtonClasses } from '@mui/lab';
import { Tooltip, TooltipProps, buttonClasses, styled } from '@mui/material';
import { FC, forwardRef } from 'react';

type EmptyTooltipProps = Omit<TooltipProps, 'children'>;

export interface ActionButtonProps extends LoadingButtonProps {
  tooltipProps?: EmptyTooltipProps;
  loadingPosition?: LoadingButtonProps['loadingPosition'];
}

const Wrapper: FC<TooltipProps> = ({ children = undefined, title, ...tooltipProps }) =>
  title ? (
    <Tooltip title={title} {...tooltipProps}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  );

const StyledButton = styled(LoadingButton)(({ children, theme, color }) => ({
  paddingTop: 1,
  paddingBottom: 1,
  backgroundColor: `${color === 'secondary' ? theme.palette.secondary.light : theme.palette.primary.light}11`,
  textTransform: 'unset',
  minWidth: 0,
  border: 'none!important',
  ...(!children && {
    [`& .${buttonClasses.startIcon}`]: {
      margin: 0,
    },
    [`& .${loadingButtonClasses.loadingIndicator}`]: {
      left: 10,
    },
  }),
}));

const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ tooltipProps = { title: '' }, children, className, loadingPosition = 'start', ...props }, ref) => (
    <Wrapper {...tooltipProps}>
      <StyledButton ref={ref} loadingPosition={loadingPosition} {...props}>
        {children}
      </StyledButton>
    </Wrapper>
  ),
);

ActionButton.defaultProps = {
  tooltipProps: { title: '' },
  loadingPosition: 'start',
};

ActionButton.displayName = 'ActionButton';

export default ActionButton;
