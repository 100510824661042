import type { Axis } from 'highcharts';
import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';

export interface XAxisContextState {
  axis: Axis | null;
}

export const initialState = {
  axis: null,
};

export const XAxisContext = createContext<XAxisContextState>(initialState);

interface XAxisProviderProps {
  axis: Axis;
}

const XAxisProvider: FC<PropsWithChildren<XAxisProviderProps>> = ({ axis, children = undefined }) => {
  const contextValues: XAxisContextState = useMemo(() => ({ axis }), [axis]);

  return <XAxisContext.Provider value={contextValues}>{children}</XAxisContext.Provider>;
};

export const useXAxisContext = () => {
  const context = useContext(XAxisContext);

  if (!context) {
    throw Error('useXAxisContext must be used inside an XAxisProvider');
  }

  return context;
};

export default XAxisProvider;
