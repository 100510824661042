import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IsEmptyCalculation, VarCalc } from '@dametis/core';

import BatchFilterInput, { BatchFilterInputProps } from '../BatchFilter/BatchFilterInput';

export interface DataFilterInputProps {
  filter: VarCalc['filter'];
  onFilterChange: (filter: VarCalc['filter']) => void;
  disabled?: boolean;
  editing?: boolean;
}

const DataFilterInput: FC<DataFilterInputProps> = ({ filter, onFilterChange, disabled = false, editing = true }) => {
  const { t } = useTranslation('dataFilter');

  const handleChange = useCallback<BatchFilterInputProps['setBatch']>(
    batch => {
      if (batch !== null) {
        onFilterChange(IsEmptyCalculation(batch) ? null : batch);
      }
    },
    [onFilterChange],
  );

  return (
    <BatchFilterInput
      noOperator
      batch={filter ?? null}
      disabled={disabled}
      editing={editing}
      label={t('input.label.dataFilter')}
      setBatch={handleChange}
    />
  );
};

export default DataFilterInput;
