import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, styled } from '@mui/material';

export const SectionAccordion = styled(({ defaultExpanded = true, children, ...props }: AccordionProps) => (
  <Accordion defaultExpanded={defaultExpanded} {...props}>
    {children}
  </Accordion>
))(() => ({
  background: 'unset',
  '&:before': {
    display: 'none',
  },
}));

export const SectionAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  position: 'sticky',
  top: 0,
  background: theme.palette.background.default,
  zIndex: 2,
  // [`& .${accordionSummaryClasses.content}`]: { m: '0!important' },
  // pb: 2,
  // minHeight: '0!important',
}));

export const SectionAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 0,
}));
