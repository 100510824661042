import { createSvgIcon } from '@mui/material';

export const DeployedCodeOutlined = createSvgIcon(
  <path d="M 10.7671 20.8737 v -8.1995 L 3.585 8.5146 v 8.1995 l 7.182 4.1596 Z m 2.394 0 l 7.182 -4.1596 v -8.1995 L 13.1611 12.6742 v 8.1995 Z m -1.197 -10.2643 l 7.0923 -4.0998 l -7.0923 -4.0998 l -7.0923 4.0998 l 7.0923 4.0998 Z M 2.388 18.8089 q -0.5686 -0.3292 -0.8828 -0.8678 T 1.191 16.744 v -9.5162 q 0 -0.6584 0.3142 -1.197 t 0.8828 -0.8678 l 8.3791 -4.818 q 0.5686 -0.3292 1.197 -0.3292 t 1.197 0.3292 l 8.3791 4.818 q 0.5686 0.3292 0.8828 0.8678 t 0.3142 1.197 v 9.5162 q 0 0.6584 -0.3142 1.197 T 21.5401 18.8089 L 13.1611 23.6268 q -0.5686 0.3292 -1.197 0.3292 t -1.197 -0.3292 L 2.388 18.8089 Z m 9.5761 -6.8229 Z" />,
  'DeployedCodeOutlined',
);

// 0.02992518703

// Y : 26.35

// X : -2.4
