import { AccordionProps, AccordionSummary, Checkbox, CheckboxProps, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SiteInfo } from '@dametis/core';

import { OpenReplayEvent } from 'components/VNC/types';
import { openReplayEvent } from 'functions/openReplay';
import { useDispatch, useSelector } from 'store';
import { useVncStore } from 'zustand/stores/vnc';

import Counter from '../../../UI/Counter/Counter';
import { PropsContext } from '../../context';

import { FilterElementProps } from './Filters';
import { FilterAccordion, FilterAccordionDetails, FilterAccordionSummaryStack, FilterList, FilterListSubheader } from './styled';

interface SortedSite extends SiteInfo {
  enabled: boolean;
}

const SitesFilters: FC<FilterElementProps> = ({ expanded, setExpanded, accordionsNumber }) => {
  const { t } = useTranslation('vnc');
  const dispatch = useDispatch();

  const { disableFilters } = useContext(PropsContext);
  const sitesDisabled = disableFilters.includes('sites');
  const isCorporateDisabled = disableFilters.includes('isCorporate');

  const userSites = useSelector(state => state.auth.selectedGroup!.sites);

  const sites = useVncStore(state => state.filters.sites);
  const isCorporateFilter = useVncStore(state => state.filters.isCorporate);
  const setFilterValue = useVncStore(state => state.setFilterValue);
  const setFiltersValues = useVncStore(state => state.setFiltersValues);
  const getResults = useVncStore(state => state.getResults);

  const sortedSites = useMemo<SortedSite[]>(
    () =>
      Object.entries(sites)
        .reduce<SortedSite[]>((acc, [key, enabled]) => {
          const userSite = userSites.find(site => site.uuid === key);
          if (userSite) {
            acc.push({ ...userSite, enabled });
          }
          return acc;
        }, [])
        .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())),
    [sites, userSites],
  );
  const trueSitesCount = useMemo(() => Object.values(sites).filter(Boolean).length + Number(isCorporateFilter), [sites, isCorporateFilter]);

  const changeExpanded = useCallback<NonNullable<AccordionProps['onChange']>>(
    (event, isExpanded) => {
      setExpanded(isExpanded ? 'sites' : false);
    },
    [setExpanded],
  );

  const toggleUtility = useCallback(
    (site: SortedSite) => async () => {
      setFilterValue('sites', site.uuid, !site.enabled);
      if (!site.enabled) {
        openReplayEvent(OpenReplayEvent.VNC_FILTER_OTHER);
      }
      await dispatch(getResults());
    },
    [dispatch, getResults, setFilterValue],
  );

  const changeUtility = useCallback(
    (site: SortedSite): CheckboxProps['onChange'] =>
      async (event, checked) => {
        setFilterValue('sites', site.uuid, checked);
        if (checked) {
          openReplayEvent(OpenReplayEvent.VNC_FILTER_OTHER);
        }
        await dispatch(getResults());
      },
    [dispatch, getResults, setFilterValue],
  );

  const toggleCorporate = useCallback(async () => {
    setFiltersValues({ isCorporate: !isCorporateFilter });
    await dispatch(getResults());
  }, [setFiltersValues, isCorporateFilter, dispatch, getResults]);

  const changeCorporate = useCallback<NonNullable<CheckboxProps['onChange']>>(
    async (event, checked) => {
      setFiltersValues({ isCorporate: checked });
      await dispatch(getResults());
    },
    [dispatch, getResults, setFiltersValues],
  );

  return (
    <FilterAccordion expanded={expanded} filterAccordionsNumber={accordionsNumber} onChange={changeExpanded}>
      <AccordionSummary>
        <FilterAccordionSummaryStack>
          <FilterListSubheader>{t('accordion.sites')}</FilterListSubheader>
          {trueSitesCount > 0 && <Counter count={trueSitesCount} />}
        </FilterAccordionSummaryStack>
      </AccordionSummary>
      <FilterAccordionDetails>
        <FilterList>
          <ListItem
            disablePadding
            divider
            secondaryAction={
              <Checkbox checked={isCorporateFilter} disabled={isCorporateDisabled} edge="end" tabIndex={-1} onChange={changeCorporate} />
            }
          >
            <ListItemButton disabled={isCorporateDisabled} selected={isCorporateFilter} onClick={toggleCorporate}>
              <ListItemText primary={t('list.text.isCorporate')} />
            </ListItemButton>
          </ListItem>
          {sortedSites.map(site => (
            <ListItem
              key={site.uuid}
              disablePadding
              secondaryAction={
                <Checkbox checked={site.enabled} disabled={sitesDisabled} edge="end" tabIndex={-1} onChange={changeUtility(site)} />
              }
            >
              <ListItemButton disabled={sitesDisabled} selected={site.enabled} onClick={toggleUtility(site)}>
                <ListItemText primary={site.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </FilterList>
      </FilterAccordionDetails>
    </FilterAccordion>
  );
};

export default SitesFilters;
