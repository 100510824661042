/* eslint-disable react/no-unstable-nested-components */

import { ListSubheader, Typography } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupedVirtuoso } from 'react-virtuoso';

import { ShortAliasInfo, ShortCorporateAliasInfo } from '@dametis/core';

import Counter from 'components/UI/Counter/Counter';
import { useVncStore } from 'zustand/stores/vnc';

import { PropsContext } from '../../../context';

import AliasesListItem from './AliasesListItem';

type Alias = ShortAliasInfo | ShortCorporateAliasInfo;
type Categorized = [Alias[], Alias[], Alias[]];

interface AliasesListProps {
  filteredAliases: (ShortAliasInfo | ShortCorporateAliasInfo)[];
}

const AliasesList: FC<AliasesListProps> = ({ filteredAliases }) => {
  const { t } = useTranslation('vnc');

  const { sourceUuid, sourceCategory } = useContext(PropsContext);

  const search = useVncStore(state => state.search);

  const categories = useMemo<Categorized>(() => {
    return filteredAliases.reduce<Categorized>(
      (acc, cur) => {
        if (cur.source.uuid && sourceUuid && cur.source.uuid === sourceUuid) {
          acc[0].push(cur);
        } else if (cur.source.category === sourceCategory) {
          acc[1].push(cur);
        } else {
          acc[2].push(cur);
        }
        return acc;
      },
      [[], [], []],
    );
  }, [filteredAliases, sourceCategory, sourceUuid]);

  const groupCounts = useMemo(() => [categories[0].length, categories[1].length, categories[2].length], [categories]);
  const groupLastsIndexes = useMemo(
    () => [groupCounts[0] - 1, groupCounts[0] + groupCounts[1] - 1, groupCounts[0] + groupCounts[1] + groupCounts[2] - 1],
    [groupCounts],
  );

  const flattenedCategories = useMemo(() => categories.flat(), [categories]);

  const getCategoryLabel = useCallback(
    (category: number, count: number): string => {
      switch (category) {
        case 0:
          return t('list.subheader.sameEntity', { category: sourceCategory, count });
        case 1:
          return t('list.subheader.sameCategory', { category: sourceCategory, count });
        case 2:
          return t('list.subheader.others', { count });
        default:
          return '';
      }
    },
    [sourceCategory, t],
  );

  if (!filteredAliases.length) {
    return (
      <Typography align="center" sx={{ mt: 4, px: 6 }} variant="subtitle2">
        {t(search.length ? 'subtitle.noAliasesForSearch' : 'subtitle.noAliases', { search })}
      </Typography>
    );
  }
  return (
    <GroupedVirtuoso
      groupContent={index => (
        <ListSubheader>
          {getCategoryLabel(index, groupCounts[index])} <Counter count={groupCounts[index]} />
        </ListSubheader>
      )}
      groupCounts={groupCounts}
      itemContent={(index, groupIndex) => (
        <AliasesListItem isLast={groupLastsIndexes[groupIndex] === index} item={flattenedCategories[index]} />
      )}
    />
  );
};

export default AliasesList;
