import i18next from 'i18next';

export const durationDisplay = (duration: number): string => {
  if (!duration) return '';
  const days = Math.floor(duration / 86400);
  const hours = Math.floor((duration - days * 86400) / 3600);
  const minutes = Math.floor((duration - days * 86400 - hours * 3600) / 60);
  const seconds = Math.floor(duration - days * 86400 - hours * 3600 - minutes * 60);

  const shortDays = i18next.t('global:unit.time_day_short', { count: days });
  const shortHours = i18next.t('global:unit.time_hour_short', { count: hours });
  const shortMinutes = i18next.t('global:unit.time_minute_short', { count: minutes });
  const shortSeconds = i18next.t('global:unit.time_second_short', { count: seconds });

  if (duration < 60) {
    return `${seconds < 10 ? `0${seconds}` : seconds}${shortSeconds}`;
  }
  if (duration < 3600) {
    return `${minutes < 10 ? `0${minutes}` : minutes}${shortMinutes} ${seconds < 10 ? `0${seconds}` : seconds}${shortSeconds}`;
  }
  if (duration < 86400) {
    return `${hours < 10 ? `0${hours}` : hours}${shortHours} ${minutes < 10 ? `0${minutes}` : minutes}${shortMinutes} ${seconds < 10 ? `0${seconds}` : seconds}${shortSeconds}`;
  }
  return `${days}${shortDays} ${hours < 10 ? `0${hours}` : hours}${shortHours} ${minutes < 10 ? `0${minutes}` : minutes}${shortMinutes} ${
    seconds < 10 ? `0${seconds}` : seconds
  }${shortSeconds}`;
};

export const shortDurationDisplay = (duration: number): string => {
  const days = Math.floor(duration / 86400);
  const hours = Math.floor((duration - days * 86400) / 3600);
  const minutes = Math.floor((duration - days * 86400 - hours * 3600) / 60);
  const seconds = Math.floor(duration - days * 86400 - hours * 3600 - minutes * 60);

  const shortDays = i18next.t('global:unit.time_day_short', { count: days });
  const shortHours = i18next.t('global:unit.time_hour_short', { count: hours });
  const shortMinutes = i18next.t('global:unit.time_minute_short', { count: minutes });
  const shortSeconds = i18next.t('global:unit.time_second_short', { count: seconds });

  let res = '';
  if (duration < 60) {
    res = `${seconds < 10 ? `0${seconds}` : seconds}${shortSeconds}`;
  } else if (duration < 3600) {
    res = `${minutes ? `${minutes < 10 ? `0${minutes}` : minutes}${shortMinutes}` : ''}`;
    res += `${seconds ? `${seconds < 10 ? ` 0${seconds}` : ` ${seconds}`}${shortSeconds}` : ''}`;
  } else if (duration < 86400) {
    res = `${hours ? `${hours < 10 ? `0${hours}` : hours}${shortHours}` : ''}`;
    res += `${minutes || seconds ? ` ${minutes < 10 ? ` 0${minutes}` : ` ${minutes}`}${shortMinutes}` : ''}`;
    res += `${seconds ? `${seconds < 10 ? ` 0${seconds}` : ` ${seconds}`}${shortSeconds}` : ''}`;
  } else {
    res = `${days}${shortDays}`;
    res += `${hours || minutes || seconds ? `${hours < 10 ? ` 0${hours}` : ` ${hours}`}${shortHours}` : ''}`;
    res += `${minutes || seconds ? ` ${minutes < 10 ? ` 0${minutes}` : ` ${minutes}`}${shortMinutes}` : ''}`;
    if (seconds) res += `${seconds < 10 ? ` 0${seconds}` : ` ${seconds}`}${shortSeconds}`;
  }
  return res;
};
