export default {
  title: 'Inviter via un lien',
  text: {
    expiration: "Ce lien d'invitation expirera",
  },
  select: {
    one_day: 'dans 24 heures',
    two_days: 'dans 48 heures',
    one_week: 'dans 7 jours',
    one_month: 'dans 30 jours',
    never: 'jamais',
  },
  button: {
    generate: 'GÉNERER',
    close: 'OK',
    invite: 'Inviter via un lien',
  },
  alert: 'Toutes les personnes possédant ce lien pourront se créer un compte.',
  table: {
    kind: 'Redirige vers',
    owner: 'Créé par',
    link: "Lien d'invitation",
    invitation: 'Invitation',
    expiration: 'Expiration',
    site: 'MyDametis',
    noInvitation: 'Aucune invitation',
    unknown: 'Utilisateur supprimé',
  },
  toast: {
    copy: "Lien d'invitation copié dans le presse-papier",
    revoke: 'Invitation revoquée avec succès',
  },
};
