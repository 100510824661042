import { ToggleButton } from '@mui/material';
import { styled } from '@mui/styles';

const SmallToggleButton = styled(ToggleButton)(({ theme, disabled }) => ({
  margin: '0!important',
  padding: theme.spacing(0.5),
  lineHeight: 1,
  textTransform: 'unset',
  ...(disabled && { border: 'unset!important' }),
}));

export default SmallToggleButton;
