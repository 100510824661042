import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from '@mui/material';
import { FC, MouseEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FolderInfo } from '@dametis/core';

import PromptBeforeDeleting from 'components/UI/PromptBeforeDeleting/PromptBeforeDeleting';
import { useDispatch } from 'store';
import { useDeleteFolderMutation } from 'store/api/folders';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import UpdateFolderModal from '../UpdateFolderModal/UpdateFolderModal';

export interface FolderTileMenuProps {
  folder: FolderInfo;
  isOpen: boolean;
  onClose: () => void;
  anchorEl: MenuProps['anchorEl'];
}

const FolderTileMenu: FC<FolderTileMenuProps> = ({ folder, isOpen, onClose, anchorEl }) => {
  const { t } = useTranslation('lego');

  const dispatch = useDispatch();

  const [deleteFolder] = useDeleteFolderMutation();

  const [isDeletingPromptOpen, setIsDeletingPromptOpen] = useState<boolean>(false);
  const [isUpdateFolderModalOpen, setIsUpdateFolderModalOpen] = useState<boolean>(false);

  const handleOpenDeletingPrompt: MouseEventHandler<HTMLLIElement> = useCallback(() => {
    setIsDeletingPromptOpen(true);
  }, []);

  const handleOpenEditFolderModal = useCallback(() => {
    setIsUpdateFolderModalOpen(true);
    onClose();
  }, [onClose]);

  const handleDeleteFolder = useCallback(async () => {
    try {
      await deleteFolder(folder.uuid).unwrap();
      dispatch(addToast({ message: t('toast.deleteFolderSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [deleteFolder, dispatch, folder.uuid, t]);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={isOpen}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={onClose}
      >
        <MenuItem onClick={handleOpenEditFolderModal}>
          <ListItemIcon>
            <EditOutlined color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('button.edit')} />
        </MenuItem>
        <MenuItem onClick={handleOpenDeletingPrompt}>
          <ListItemIcon>
            <DeleteOutlined color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('button.delete')} />
        </MenuItem>
      </Menu>
      <PromptBeforeDeleting open={isDeletingPromptOpen} setOpen={setIsDeletingPromptOpen} onDelete={handleDeleteFolder} />
      <UpdateFolderModal folder={folder} isOpen={isUpdateFolderModalOpen} setIsOpen={setIsUpdateFolderModalOpen} />
    </>
  );
};

export default FolderTileMenu;
