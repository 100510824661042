import Editor from '@monaco-editor/react';
import { TextField, Typography, TypographyProps, alpha, outlinedInputClasses, styled, typographyClasses } from '@mui/material';
import { HTMLAttributes, forwardRef } from 'react';
import { Editable } from 'slate-react';

import searchImg from 'assets/images/vnc/search.svg';
import { setColorLightness } from 'functions/color';

export const TitleTextareaBox = styled('div', { shouldForwardProp: propName => propName !== 'fullWidth' })<{ fullWidth: boolean }>(
  ({ theme, fullWidth }) => ({
    padding: theme.spacing(1),
    width: fullWidth ? '100%' : `calc(100% - ${theme.spacing(28)})`,
    transition: theme.transitions.create('width'),
    willChange: 'width',
  }),
);

export const TitlePreviewBox = styled('div', { shouldForwardProp: propName => propName !== 'hidden' })<{ hidden: boolean }>(
  ({ theme, hidden }) => ({
    overflow: 'hidden',
    width: hidden ? 0 : theme.spacing(28),
    transition: theme.transitions.create('width'),
    willChange: 'width',
  }),
);

export const StyledMonaco = styled(Editor, { shouldForwardProp: propName => propName !== 'focused' })<{
  focused: boolean;
}>(({ theme, focused }) => ({
  overflow: 'hidden',
  border: `solid 2px ${theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  borderColor: focused ? theme.palette.grey[900] : undefined,
  '&:hover': {
    borderColor: focused ? undefined : theme.palette.grey[500],
  },
}));

export const StyledEditable = styled(Editable, { shouldForwardProp: propName => propName !== 'focused' })<{
  focused: boolean;
}>(({ theme, focused }) => ({
  width: '100%',
  height: '100%',
  padding: 8 - 2, // OutlinedInput.padding - this.borderWidth (because MUI uses outline)
  overflow: 'auto',
  backgroundColor: theme.palette.white,
  color: theme.palette.text.primary,
  fontFamily: "'JuliaMono', monospace",
  fontSize: 16,
  fontWeight: 300,
  lineHeight: theme.typography.body1.lineHeight,
  border: `solid 2px ${theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  borderColor: focused ? theme.palette.grey[900] : undefined,
  outline: 'none',
  '&:hover': {
    borderColor: focused ? undefined : theme.palette.grey[500],
  },
}));

export const ONE_CHARACTER_WIDTH = 10.77;

const BS = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>((props, ref) => <span {...props} ref={ref} />);
BS.displayName = 'BlockSpan';
export const BlockSpan = styled(BS, {
  shouldForwardProp: propName => propName !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  display: 'inline-block',
  position: 'relative',
  border: `1px ${selected ? 'solid' : 'dotted'} ${theme.palette.primary.light}`,
  borderRadius: theme.shape.borderRadius / 2,
  margin: `0 ${theme.spacing(0.375)}`,
  padding: `calc(11px + ${theme.spacing(0.25)}) ${theme.spacing(0.25)} ${theme.spacing(0.25)}`,
  minWidth: theme.spacing(8),
  lineHeight: 1.35,
}));

export const BlockAggregator = styled((props: TypographyProps) => (
  <Typography color="primary" contentEditable={false} variant="overline" {...props} />
))(() => ({ fontSize: '11px', lineHeight: 1 }));

const VS = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>((props, ref) => (
  <Typography component="span" contentEditable={false} fontSize="inherit" {...props} ref={ref} />
));
VS.displayName = 'VariableSpan';
export const VariableSpan = styled(VS, {
  shouldForwardProp: propName => propName !== 'selected' && propName !== 'pqColor',
})<{ selected?: boolean; pqColor: string }>(({ selected, pqColor, theme }) => ({
  lineHeight: '18px',
  margin: `0 ${theme.spacing(0.375)}`,
  color: setColorLightness(pqColor, 45),
  backgroundColor: selected ? setColorLightness(pqColor, 90) : setColorLightness(pqColor, 95),
  borderRadius: theme.shape.borderRadius / 2,
  '&::selection': {
    backgroundColor: 'transparent',
  },
}));

export const OperatorSpan = styled(
  ({ className, ...props }: TypographyProps) => (
    <span className={className}>
      <Typography variant="overline" {...props} />
    </span>
  ),
  {
    shouldForwardProp: propName => propName !== 'pqColor' && propName !== 'selected',
  },
)<{ pqColor: string; selected: boolean }>(({ theme, pqColor, selected }) => ({
  lineHeight: '18px',
  fontSize: '1em',
  marginRight: theme.spacing(0.5),
  padding: `0 ${theme.spacing(0.5)}`,
  borderRadius: `${theme.shape.borderRadius / 2}px 0 0 ${theme.shape.borderRadius / 2}px`,
  backgroundColor: selected ? setColorLightness(pqColor, 80) : setColorLightness(pqColor, 85),
  [`& .${typographyClasses.overline}`]: {
    color: selected ? setColorLightness(pqColor, 35) : setColorLightness(pqColor, 40),
    verticalAlign: 'middle',
    fontSize: '0.7em',
  },
  '&::selection': {
    backgroundColor: 'transparent',
  },
}));

export const FunctionSpan = styled('span', { shouldForwardProp: propName => propName !== 'selected' })<{ selected: boolean }>(
  ({ theme, selected }) => ({
    color: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius / 2,
    marginLeft: theme.spacing(0.375),
    marginRight: theme.spacing(0.375),
    backgroundColor: selected ? setColorLightness(theme.palette.primary.main, 90) : undefined,
  }),
);

export const SearchTextField = styled(TextField)(() => ({
  [`& .${outlinedInputClasses.root}`]: {
    minHeight: 70,
    fontSize: 16,
  },
}));

export const LeafSpan = styled('span')<{ leafText: string }>(({ leafText, theme }) => ({
  paddingLeft: !leafText ? '0.1px' : undefined,
  backgroundImage: `url(${searchImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
  borderRadius: '3px',
  paddingRight: 0,
  backgroundColor: alpha(theme.palette.grey[100], 0),
  backgroundSize: 0,
  transition: theme.transitions.create(['padding-right', 'background-color', 'background-size'], {
    duration: 100,
  }),
  [`&[selected="true"]`]: { paddingRight: 18, backgroundColor: theme.palette.grey[100], backgroundSize: '16px' },
}));

export const FunctionName = styled(Typography)(({ theme }) => ({
  fontFamily: "'JuliaMono', monospace",
  color: theme.palette.primary.main,
}));

export const FunctionInputsTable = styled('table')(({ theme }) => ({
  borderSpacing: 0,
  borderCollapse: 'collapse',
  '& tbody td': {
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  '& tbody tr:not(:last-of-type) td': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& thead th': {
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1),
  },
}));

export const FunctionInputName = styled('span')<{ selected: boolean }>(({ theme, selected }) => ({
  borderRadius: '3px',
  backgroundColor: selected ? setColorLightness(theme.palette.secondary.main, 93) : theme.palette.grey[100],
  fontFamily: "'JuliaMono', monospace",
  color: selected ? theme.palette.secondary.dark : undefined,
}));
