export default {
  input: {
    label: {
      groupBy: 'Subperíodos',
    },
    value: {
      auto: 'Automático',
      pointToPoint: 'Punto a punto',
      time: 'Tiempo',
      batch: 'Batch',
    },
  },
  none: 'Ninguno',
  auto: 'Automático',
  pointToPoint: 'Punto a punto',
  pointToPoint_short: 'PAP',
  batch: 'Batch',
};
