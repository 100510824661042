import { Divider, Grid } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';

import { ModelInfo } from '@dametis/core';

import { useModels } from 'store/api/models';
import { useVncStore } from 'zustand/stores/vnc';

import ModelColumn from './ModelColumn';
import ModelsColumn from './ModelsColumn';

const emptyModels: ModelInfo[] = [];

const ModelsList: FC = () => {
  const { data: models = emptyModels } = useModels();

  const selectedModelUuid = useVncStore(state => state.models.selectedModelUuid);
  const setSelectedModelUuid = useVncStore(state => state.setSelectedModelUuid);

  const selectedModel = useMemo(() => models.find(model => model.uuid === selectedModelUuid), [models, selectedModelUuid]);

  useEffect(() => {
    setSelectedModelUuid(null);
  }, [setSelectedModelUuid]);

  return (
    <Grid container sx={{ height: '100%', borderBottom: theme => `1px solid ${theme.palette.divider}` }}>
      <Grid item xs sx={{ overflow: 'auto', height: '100%', px: 0.5 }}>
        <ModelsColumn />
      </Grid>
      <Grid item>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs sx={{ overflow: 'hidden', height: '100%', pl: 0.5 }}>
        {selectedModel !== undefined && <ModelColumn selectedModel={selectedModel} />}
      </Grid>
    </Grid>
  );
};

export default ModelsList;
