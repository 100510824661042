export default {
  user: 'utilisateur',
  button: {
    validate: 'Valider',
    cancel: 'Annuler',
    previous: 'Précédent',
    next: 'Suivant',
    configureTwoFA: 'Activer',
    generate: 'Générer',
    regenerate: 'Regénérer',
    copy: 'Copier',
    save: 'Enregister',
    addPasskey: "Créer une clé d'accès",
  },
  label: {
    site: 'Site',
  },
  personalInformation: {
    title: 'Informations personnelles',
    firstname: 'Prénom',
    lastname: 'Nom',
    phone: 'Téléphone',
    email: 'Email',
  },
  settings: {
    title: 'Paramètres',
    vnc: 'Sélecteur de variables',
    searchHighlight: 'Surligner les mots en correspondance de la recherche',
  },
  security: {
    title: 'Sécurité',
    password: 'Mot de passe',
    currentPassword: 'Mot de passe actuel',
    newPassword: 'Nouveau mot de passe',
    confirmation: 'Confirmation',
  },
  preference: {
    title: 'Préférences',
    language: 'Langue',
    languageDico: {
      es: 'Español',
      fr: 'Français',
      en: 'English',
    },
    timezone: {
      title: 'Fuseau horaire ',
      local: 'Heure locale',
      site: 'Heure du site industriel',
    },
  },
  twofa: {
    title: 'Double authentification',
    phone: 'Téléphone',
    mail: 'Email',
    totp: "Application d'authentification",
    passkey: "Clés d'accès",
    configure: 'Configurer',
    modal: {
      phoneTitle: 'Entrez votre numéro de téléphone',
      phoneError: 'Numéro de téléphone invalide',
      emailTitle: 'Entrez votre email',
      email: 'Email',
      emailError: 'Email invalide',
      OTPTitle: 'Scannez le QR Code et entrez le code de vérification',
      OTPHelp: "Scannez le QR Code dans votre application d'authentification préférée et entrez le code de vérification",
      OTPKey: 'Clé',
      OTPTimebased: 'Time-Based',
      OTPBackToQRCode: 'Scannez le QR code',
      type: 'Type',
      cantScanHelper: "Pour ajouter l'entrée manuellement, fournissez les détails suivants votre application d'authentification",
      cantScan: 'Vous ne pouvez pas scanner le QR Code ?',
      codeTitle: 'Entrez le code de vérification',
      code: 'Code de vérification',
      codeError: 'Code invalide',
      codeSentToEmail1: 'Code envoyé !',
      codeSentToEmail2:
        "Merci de confirmer l'activation de la double authentification en entrant le code que nous vous avons envoyé à {{email}}.",
      codeSentToEmail2_noEmail:
        "Merci de confirmer l'activation de la double authentification en entrant le code que nous vous avons envoyé par <strong>email</strong>.",
      codeSentToPhone1: 'Code envoyé !',
      codeSentToPhone2:
        "Merci de confirmer l'activation de la double authentification en entrant le code que nous vous avons envoyé au {{phone}}.",
      codeSentToPhone2_noPhone:
        "Merci de confirmer l'activation de la double authentification en entrant le code que nous vous avons envoyé par <strong>SMS</strong>.",
      pleaseWaitForCode: 'La réception de votre code peut prendre une minute. Votre code reste valide 5 minutes.',
      sendCodeAgain: 'Recevoir un nouveau code.',
      enrollError: "Une erreur est survenue lors de l'envoi du code à {{email}}. Veuillez réessayer plus tard.",
    },
    createdAt: 'Date de création',
    lastUsed: 'Dernière utilisation',
    notYetUsed: 'Pas encore utilisé',
    modify: 'Modifier',
    delete: 'Supprimer',
    active: 'Activé',
    inactive: 'Désactivé',
    editForbidden: "Vous ne pouvez pas modifier votre dernier mode d'authentification à deux facteurs",
    deleteForbidden: "Vous ne pouvez pas supprimer votre dernier mode d'authentification à deux facteurs",
    webauthnHelper:
      "Les clés d'accès vous permettent de vous connecter de manière sécurisée à votre compte sans mot de passe ou avec une authentification à deux facteurs, en utilisant votre empreinte digitale, votre visage ou une clé de sécurité matérielle.",
  },
  token: {
    title: 'API',
    key: "Clés d'API personnelles",
    noUserToken: "Aucune clé d'API",
    noSelectedSite: 'Aucun site sélectionné',
  },
  sessions: {
    title: 'Vos appareils',
    nbByDeviceType: '{{value}} appareil(s) sur {{deviceType}}',
    current: 'Session en cours',
    unknownBrowser: 'Inconnu',
    unknownOS: 'Autres',
    browser: 'Navigateur',
    addressIp: 'Adresse IP',
    buttonDelete: 'Déconnecter',
    device_one: '{{value}} appareil sur {{deviceType}}',
    device_other: '{{value}} appareils sur {{deviceType}}',
  },
  toast: {
    copyToClipboard: 'UUID de la clé copié dans le presse-papier ',
  },
  error: {
    notSamePassword: 'Les mots de passe sont différents',
    incorrectPassword: 'Mauvais mot de passe',
    invalidStrength: 'Veuillez choisir un mot de passe plus sûr. Essayez un mélange de lettres, chiffres et symboles.',
    emptyField: 'Veuillez remplir tous les champs',
    apiProblem: 'Problème de connexion',
    authenticator: "Votre authentificateur n'a pas pu générer de clés d'accès",
    webauthnUnsupported: 'Votre navigateur ne prend pas en charge WebAuthn',
  },
  tooltip: {
    showPassword: 'Afficher le mot de passe',
    hidePassword: 'Cacher le mot de passe',
    token: 'clé',
  },
};
