import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useContext, useMemo } from 'react';

import { AllNullableKeys, FixedVariable, ListVariableInfo, ReferencedBy, SourceColumn } from '@dametis/core';

import { TimeZone } from 'types/auth';

export type FormFixedVariable = AllNullableKeys<FixedVariable>;

export const IsFixedVariable = (fixedVariable: FormFixedVariable | null): fixedVariable is FixedVariable =>
  fixedVariable !== null && fixedVariable.source !== null && fixedVariable.variableId !== null;

export type MappingSettingsContextState = MappingSettingsProviderProps;
// export interface MappingSettingsContextState extends MappingSettingsProviderProps {}

export interface MappingSettingsProviderProps {
  referencedBy: ReferencedBy;
  useHeader: boolean;
  usedSources: SourceColumn[];
  dateTimeSource: SourceColumn | null;
  setDateTimeSource: Dispatch<SetStateAction<SourceColumn | null>>;
  referenceSource: SourceColumn | null;
  setReferenceSource: Dispatch<SetStateAction<SourceColumn | null>>;
  valueSource: SourceColumn | null;
  setValueSource: Dispatch<SetStateAction<SourceColumn | null>>;
  skippedColumns: (SourceColumn | null)[];
  setSkippedColumns: Dispatch<SetStateAction<(SourceColumn | null)[]>>;
  fixedVariables: FormFixedVariable[];
  setFixedVariables: Dispatch<SetStateAction<FormFixedVariable[]>>;
  dateTimeZone: TimeZone | null;
  setDateTimeZone: Dispatch<SetStateAction<TimeZone | null>>;
  dateTimeFormat: string;
  setDateTimeFormat: Dispatch<SetStateAction<string>>;
  variablesByReferences: Record<string, ListVariableInfo>;
}

export const MappingSettingsContext = createContext<MappingSettingsContextState | undefined>(undefined);

const MappingSettingsProvider: FC<PropsWithChildren<MappingSettingsProviderProps>> = ({
  referencedBy,
  useHeader,
  usedSources,
  dateTimeSource,
  setDateTimeSource,
  referenceSource,
  setReferenceSource,
  valueSource,
  setValueSource,
  skippedColumns,
  setSkippedColumns,
  fixedVariables,
  setFixedVariables,
  dateTimeZone,
  setDateTimeZone,
  dateTimeFormat,
  setDateTimeFormat,
  variablesByReferences,
  children = undefined,
}) => {
  const contextValues = useMemo(
    () => ({
      referencedBy,
      useHeader,
      usedSources,
      dateTimeSource,
      setDateTimeSource,
      referenceSource,
      setReferenceSource,
      valueSource,
      setValueSource,
      skippedColumns,
      setSkippedColumns,
      fixedVariables,
      setFixedVariables,
      dateTimeZone,
      setDateTimeZone,
      dateTimeFormat,
      setDateTimeFormat,
      variablesByReferences,
    }),
    [
      referencedBy,
      useHeader,
      usedSources,
      dateTimeSource,
      setDateTimeSource,
      referenceSource,
      setReferenceSource,
      valueSource,
      setValueSource,
      skippedColumns,
      setSkippedColumns,
      fixedVariables,
      setFixedVariables,
      dateTimeZone,
      setDateTimeZone,
      dateTimeFormat,
      setDateTimeFormat,
      variablesByReferences,
    ],
  );

  return <MappingSettingsContext.Provider value={contextValues}>{children}</MappingSettingsContext.Provider>;
};

export const useMappingSettingsContext = () => {
  const context = useContext(MappingSettingsContext);

  if (!context) {
    throw Error('useMappingSettingsContext must be used inside a MappingSettingsProvider');
  }

  return context;
};

export default MappingSettingsProvider;
