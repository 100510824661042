export default {
  title: {
    smoothing: 'Alisado',
  },
  input: {
    label: {
      operator: 'Operador',
    },
  },
};
