import { Stack } from '@mui/material';
import clsx from 'clsx';
import { FC, useMemo } from 'react';

import { EntityStyleConfiguration, ShortStyleConfigurationInfo, StyledRule } from '@dametis/core';

import { getContent, getStyledRules } from 'functions/styledRule';
import { useStyleConfigurations } from 'store/api/styleConfigurations';

import { availableIcons } from '../../../../config/iconPicker';
import DamIconComponent from '../../../UI/IconPicker/DamIconComponent';
import { usePlaygroundStyles } from '../../Playground.styles';
import { ICellType } from '../../types';

interface Props {
  type: ICellType;
  value: number | string;
  unformattedValue: number | string;
  valueFormatter?: (value) => string;
  batchTarget?: number;
  className?: string;
  styledRules?: EntityStyleConfiguration | StyledRule[];
}

const emptyArray: ShortStyleConfigurationInfo[] = [];

const TableChartStyledCell: FC<Props> = ({
  type,
  value,
  unformattedValue,
  batchTarget = undefined,
  className = undefined,
  styledRules = undefined,
  valueFormatter = undefined,
}) => {
  const classes = usePlaygroundStyles();

  const { data: styleConfigurations = emptyArray } = useStyleConfigurations();

  const parsedStyledRules = useMemo(() => getStyledRules(styledRules, styleConfigurations), [styledRules, styleConfigurations]);
  const styleContent = useMemo(
    () => (typeof unformattedValue === 'number' ? getContent(parsedStyledRules, unformattedValue) : null),
    [parsedStyledRules, unformattedValue],
  );

  switch (type) {
    case ICellType.DURATION:
      return (
        <div className={clsx(typeof value === 'number' && batchTarget < value && classes['styledRule__cell--time'])}>
          {valueFormatter ? valueFormatter(value) : value}
        </div>
      );
    case ICellType.HEADER:
      return <span className={clsx(className && classes[className])}>{value}</span>;
    case ICellType.VAR:
      return (
        <Stack direction="row" justifyContent={styleContent?.icon ? 'space-between' : 'flex-end'} width="100%">
          {styleContent?.icon && (
            <DamIconComponent
              className={classes['styledRule__cell--icon']}
              prop={availableIcons[styleContent.icon]?.prop}
              size={34}
              type={availableIcons[styleContent.icon]?.type}
            />
          )}
          {styleContent?.text?.length > 0 ? styleContent.text : value}
        </Stack>
      );
    default:
      return <div>{valueFormatter ? valueFormatter(value) : value}</div>;
  }
};

export default TableChartStyledCell;
