import { ClearOutlined } from '@mui/icons-material';
import { Badge, Button, ButtonProps, IconButton, Stack, Tooltip, capitalize } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import UnitPopover from 'components/UI/UnitPicker/UnitPopover';
import { getUnitName } from 'components/UI/UnitPicker/functions/getUnitName';
import { UnitResult } from 'components/UI/UnitPicker/types';

export interface PreviewUnitButtonProps {
  unit: UnitResult;
  onChange: (unit: UnitResult) => void;
}

const PreviewUnitButton: FC<PreviewUnitButtonProps> = ({ unit, onChange }) => {
  const { t } = useTranslation('vnc');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const displayedValue = useMemo(() => unit?.trim() ?? '', [unit]);
  const unitName = useMemo(() => {
    if (unit === null) {
      return t('text.unitNa');
    }
    return displayedValue.length > 0 ? capitalize(getUnitName(displayedValue, t)) : t('text.noUnit');
  }, [displayedValue, t, unit]);

  const handleChange = useCallback(
    (newValue: UnitResult) => {
      onChange(newValue);
      setAnchorEl(undefined);
    },
    [onChange],
  );

  const handleClear = useCallback<NonNullable<ButtonProps['onClick']>>(() => {
    onChange(null);
  }, [onChange]);

  const handleOpen = useCallback<NonNullable<ButtonProps['onClick']>>(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  return (
    <Stack alignItems="baseline" direction="row" gap={0.5}>
      <Badge
        badgeContent={
          unit !== null ? (
            <Tooltip placement="top" title={t('button.clearUnit')}>
              <IconButton
                size="small"
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', fontSize: 'inherit' }}
                onClick={handleClear}
              >
                <ClearOutlined sx={{ color: 'inherit', fontSize: 'inherit' }} />
              </IconButton>
            </Tooltip>
          ) : undefined
        }
        sx={{ color: 'inherit' }}
      >
        <Tooltip placement="top" title={unitName}>
          <Button
            color="inherit"
            sx={{
              flexShrink: 0,
              textTransform: 'unset',
              fontSize: 'inherit',
              lineHeight: 'inherit',
              backgroundColor: 'inherit',
              textDecoration: 'underline dotted !important',
              fontWeight: 500,
              px: '0.35em',
              mr: '-0.35em',
              py: '0.25em',
              minWidth: 'unset',
            }}
            onClick={handleOpen}
          >
            {displayedValue.length ? displayedValue : <>&nbsp;&nbsp;</>}
          </Button>
        </Tooltip>
      </Badge>
      <UnitPopover anchorEl={anchorEl} open={open} value={unit} onChange={handleChange} onClose={handleClose} />
    </Stack>
  );
};

export default PreviewUnitButton;
