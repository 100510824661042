import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FC, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export enum RelativeToNowPeriod {
  ONE_DAY = 'oneDay',
  THREE_DAYS = 'threeDays',
  ONE_WEEK = 'oneWeek',
  TWO_WEEKS = 'twoWeeks',
  ONE_MONTH = 'oneMonth',
}

export interface RelativeToNowPeriodPickerProps {
  value: RelativeToNowPeriod;
  onChange: (newValue: RelativeToNowPeriod) => void;
  availablePeriods?: RelativeToNowPeriod[];
}

const RelativeToNowPeriodPicker: FC<RelativeToNowPeriodPickerProps> = ({
  value,
  onChange,
  availablePeriods = Object.values(RelativeToNowPeriod),
}) => {
  const { t } = useTranslation('dateTimePicker');

  const handleChange = useCallback(
    (_event: MouseEvent<HTMLElement>, newValue: RelativeToNowPeriod | null) => {
      if (newValue) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  return (
    <ToggleButtonGroup exclusive value={value} onChange={handleChange}>
      {availablePeriods.map(availablePeriod => (
        <ToggleButton key={availablePeriod} sx={{ lineHeight: 1, margin: '0 !important', padding: 1 }} value={availablePeriod}>
          {t(`relativeToNowPeriods.${availablePeriod}`)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default RelativeToNowPeriodPicker;
