import type { PointOptionsType, SeriesColumnOptions, SeriesOptionsType } from 'highcharts';
import ColumnSeries from 'highcharts/es-modules/Series/Column/ColumnSeries';

import { DaAxis } from './DaAxis';
import { DaChart } from './DaChart';

interface Props {
  name?: string;
  color?: string;
  unit?: string;
  selectedUnit?: string;
  yAxis?: DaAxis;
  hidden?: boolean;
}

export default class DaCategorySeries extends ColumnSeries {
  declare chart: DaChart;

  declare yAxis: DaAxis;

  public constructor(chart: DaChart, { name = '', color, unit = '', selectedUnit = undefined, yAxis, hidden = false }: Props = {}) {
    const options: SeriesColumnOptions = {
      type: 'column',
      name,
      color,
      visible: !hidden,
      tooltip: {
        valueSuffix: unit ?? '',
        valuePrefix: selectedUnit,
      },
    };
    let yAxis2 = yAxis;
    if (yAxis2 === undefined) {
      yAxis2 = new DaAxis(chart, { color, unit });
    }
    options.yAxis = yAxis2.options.id;
    super();
    super.init(chart, options);
  }

  addData(data: PointOptionsType[]): void {
    super.setData(data, false, false, false);
  }

  update(options: SeriesOptionsType): void {
    super.update(options, false);
    if (this.graph !== undefined) this.graph.destroy();
  }

  setName(name = ''): void {
    this.update({ name } as SeriesOptionsType);
  }
}
