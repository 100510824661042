const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'] as const;

const dict = {
  '0': '₀',
  '1': '₁',
  '2': '₂',
  '3': '₃',
  '4': '₄',
  '5': '₅',
  '6': '₆',
  '7': '₇',
  '8': '₈',
  '9': '₉',
};

const IsDigit = (string: string): string is (typeof digits)[number] => digits.some(digit => digit === string);

export const numberToSubscript = (number: number): string => {
  return number.toString().replace(/[0123456789]/g, match => (IsDigit(match) ? dict[match] : ''));
};
