import type { ParseError as PapaParseError } from 'papaparse';
import type { ErrorCode as DropzoneErrorCode } from 'react-dropzone';

import { CSVDelimiter, ColumnType, FileImportStatus, FileParseErrorCodes, ReferencedBy, VirtualColumnElementType } from '@dametis/core';

import { SourceValue } from 'components/Data/FileImport/UI/SourceValuePicker/SourceValuePicker';

export default {
  title: {
    fileImport: 'Importar datos',
    createFileConfigModal: 'Nueva configuración',
    createFileImportModal: 'Descargar documentos',
    settings: 'Ajustes',
    virtualColumns: 'Columnas virtuales',
    createVirtualColumnModal: 'Nueva columna virtual',
    dateTime: 'Fecha y hora',
    reference: 'Referencias de variables',
    values: 'Valores',
    value: 'Valor',
    preview: 'Vista previa',
    configurations: 'Configuraciones',
    files: 'Documentos',
    selectedFiles: 'Documentos seleccionados',
    mapping: 'Correspondencia de columnas',
    skippedColumns: 'Columnas ignoradas',
    saveFileImportModal: '¿Qué desea hacer?',
    uploadsPanel: 'Descargas',
    fixedVariables: 'Variables permanentes',
    informations: 'Información',
    errors: 'Errores',
  },
  button: {
    createFileConfig: 'Configuración',
    close: 'Cerrar',
    next: 'Siguiente',
    previous: 'Anterior',
    add: 'Añadir',
    create: 'Crear',
    refresh: 'Refresh',
    refreshLong: 'Refresh vista previa',
    upload: 'Descargar',
    see: 'Ver',
    importData: 'Importar datos',
    save: 'Guardar',
    uploads: 'Descargas',
    cancel: 'Cancelar',
    validate: 'Validar',
  },
  label: {
    referencedBy: '¿Cómo se hacen referencia a las variables en sus documentos?',
    typicalFile: 'Tipo de archivo',
    delimiter: 'Delimitador',
    jq: 'Transformador JQ',
    chooseFileConfig: '¿Qué configuración para importar sus documentos?',
    name: 'Nombre',
    description: 'Descripción',
    elements: 'Elementos',
    dateTimeSource: 'Fuente',
    dateTimeFormat: 'Formato de la fuente',
    dateTimeZone: 'Huso horario de la fuente',
    referenceSource: 'Fuente',
    valuesSource: 'Fuente',
    valueSource: 'Fuente',
    skippedColumnWithIndex: 'Columna ignorada {{index}}',
    startLine: 'Inicio del documento',
    stopLine: 'Fin del documento',
    associatedFiles_zero: 'Ningún archivo asociado',
    associatedFiles_one: '{{count}} archivo asociado',
    associatedFiles_other: '{{count}} archivos asociados',
    useHeader: 'Los documentos utilizan un encabezado que contiene las referencias de las variables',
    ignoreEmptyLines: 'Ignorar líneas vacías',
    chooseExistingFileImport: 'Elegir una configuración existente',
    existingFileImport: 'Configuraciones existentes',
    uploadsInProgress_zero: 'No hay descargas en curso',
    uploadsInProgress_one: 'Una descarga en curso',
    uploadsInProgress_other: '{{count}} descargas en curso',
    doImportData: 'Importar datos de estos documentos',
    doSaveFileImport: 'Guardar esta configuración de importación',
    createdAt: 'Creación',
    updatedAt: 'Actualización',
    size: 'Tamaño',
    owner: 'Propietario',
    charset: 'Charset',
    globalErrors: 'Errores globales ({{length}})',
    rowWithNumber: 'Línea {{number}} ({{length}})',
    variable: 'Variable',
  },
  referencedBy: {
    [ReferencedBy.COLUMNS]: 'Por columnas',
    [ReferencedBy.ROWS]: 'Por filas',
  },
  helper: {
    referencedBy: {
      [ReferencedBy.COLUMNS]: 'Las variables se hacen referencia en los encabezados de las columnas de valores de sus documentos.',
      [ReferencedBy.ROWS]: 'Las variables se hacen referencia en cada línea de valor de sus documentos.',
    },
    dateTimeFormatDescription: 'Encuentre la documentación del formato ',
    dateTimeFormatRedirection: 'aquí.',
    chooseConfigurationBeforeFiles: 'Debe seleccionar una configuración antes de elegir los documentos a enviar.',
    createConfigurationBeforeFiles: 'Debe crear una primera configuración de importación para comenzar a enviar sus documentos.',
    typicalFile:
      'Elija un tipo de archivo de esta configuración. Este servirá como base para configurar el análisis de los futuros documentos importados.',
    virtualColumns:
      'Las columnas virtuales le permiten simular columnas en sus documentos a partir de su contenido. Ejemplo: la columna virtual "fecha y hora" es la concatenación de las columnas existentes "fecha" y "hora".',
    fixedVariables:
      'Asociar permanentemente una variable a una columna permite el uso de documentos sin encabezado o con una referencia de variable incorrecta.',
  },
  csvDelimiter: {
    [CSVDelimiter.AUTO]: 'Auto',
    [CSVDelimiter.COMMA]: '","',
    [CSVDelimiter.SEMICOLON]: '";"',
  },
  tooltip: {
    seeMore: 'Ver {{count}} otras configuraciones',
    createFileConfig: 'Nueva configuración',
    deleteConfig: 'Borrar la configuración',
    cannotDeleteConfig: 'No puede eliminar esta configuración porque la utiliza un equipo',
    seeFiles: 'Ver documentos',
    correspondingVariable: 'Variable correspondiente: {{variableName}}',
    notCorrespondingVariable: 'La referencia "{{reference}}" no parece corresponder a ninguna variable del sitio',
    jqDesc: 'JQ es un lenguaje para la manipulación avanzada de los datos de su fichero.',
    docLink: 'Documentación',
  },
  virtualColumnElementType: {
    [VirtualColumnElementType.COLUMN]: 'Columna',
    [VirtualColumnElementType.TEXT]: 'Texto',
    [VirtualColumnElementType.SEPARATOR]: 'Separador',
  },
  columnType: {
    [ColumnType.REAL_BY_INDEX]: 'Columnas existentes (por número)',
    [ColumnType.REAL_BY_HEADER]: 'Columnas existentes (por nombre)',
    [ColumnType.VIRTUAL]: 'Columnas virtuales',
  },
  importStatusDescription: {
    [FileImportStatus.IMPORTED]: 'Datos del documento importados con éxito',
    [FileImportStatus.READY]: 'Los datos de este documento no parecen haber sido importados',
    [FileImportStatus.FAILED]: 'La importación de datos ha fallado, se ha producido un error',
    [FileImportStatus.IN_PROGRESS]: 'Importando datos del documento',
    missingFile: 'Este documento ya no está disponible',
    unknownStatus: 'Estado desconocido',
  },
  column: {
    notSelected: 'No seleccionado',
    skipped: 'Ignorado',
    valueSourceDefault: 'Valor (por defecto)',
    skippedSourceDefault: 'Ignorado (por defecto)',
    dateTimeSource: 'Fecha y hora',
    referenceSource: 'Referencias de variables',
    valuesSource: 'Valores',
    valueSource: 'Valor',
    valuesSourceWithIndex: 'Valores {{index}}',
  },
  stepper: {
    csvConfig: 'Documentos',
    mapping: 'Valores',
  },
  text: {
    noVirtualColumn: 'Ninguna columna virtual.',
    noVirtualColumnElement: 'Ningún elemento',
    separator: '"{{value}}"',
    realByIndexColumn: 'Columna nº{{index}}',
    realByHeaderColumn: 'Columna "{{name}}"',
    virtualColumn: 'Columna "{{name}}"',
    separatorMustFollowColumnOrText: 'Un separador debe seguir a un elemento Columna o Texto',
    virtualColumnNameIsAlreayUsed: 'Otra columna virtual ya tiene este nombre',
    noDateTimeZoneFound: 'No hay huso horario correspondiente',
    noDateTimeFormatFound: 'No hay formato correspondiente',
    useOption: 'Usar "{{option}}"',
    lineNumber: 'línea nº',
    lineNumberWithValue: 'línea nº{{value}}',
    noPreview: 'Ninguna vista previa',
    noConfiguration: 'Ninguna configuración',
    globalParseErrors: 'Errores de análisis',
    alreadyUploadedFile: 'Archivo ya en proceso: {{fileName}}',
    noFile: 'Ningún documento',
    noSkippedColumn: 'Ninguna columna ignorada',
    importFromNewFileImport: 'Descargar documentos desde una nueva configuración',
    unknownSize: 'Tamaño desconocido',
    noUpload: 'Sin descargas',
    noFixedVariable: 'Ninguna variable permanente',
    loadingForms: 'Cargando columnas...',
    dateTimeFormatExample: 'Ej: {{example}}',
    unknownParseError: 'Error desconocido',
    configUsed: 'Esta configuración la utilizan los equipos Mich ',
    emptyJq: 'Sin',
  },
  dropzone: {
    dropFiles: 'Suelta uno o varios documentos para descargar',
    or: 'o',
    browse: 'Examinar',
    acceptedFiles: 'Archivos admitidos:',
    maxSize: 'Tamaño máximo : {{size}}MB',
    dropFilesHere: 'Suelta tus documentos aquí',
    fileNotAllowed: 'Este tipo de archivo no está permitido',
  },
  toast: {
    uploadInProgress_one: 'Descargando documento',
    uploadInProgress_other: 'Descargando documentos',
    uploadInProgressWithRedirect_one: 'Descargando documento, puede consultar su progreso en la página de la configuración asociada.',
    uploadInProgressWithRedirect_other: 'Descargando documentos, puede consultar su progreso en la página de la configuración asociada.',
    fileImportSaved: 'Configuración guardada',
  },
  sourceValue: {
    [`${SourceValue.DEFAULT}_useHeader_${ReferencedBy.COLUMNS}`]: 'Valor (desde referencia de variable)', // default referenced by rows useHeader
    [`${SourceValue.DEFAULT}_${ReferencedBy.COLUMNS}`]: 'Ignorado (por defecto)', // default referenced by rows not useHeader
    [`${SourceValue.DEFAULT}_useHeader_${ReferencedBy.ROWS}`]: 'Ignorado (por defecto)', // default referenced by rows useHeader
    [`${SourceValue.DEFAULT}_${ReferencedBy.ROWS}`]: 'Ignorado (por defecto)', // default referenced by rows notUseHeader
    [SourceValue.DATE_TIME]: 'Fecha y hora',
    [SourceValue.REFERENCE]: 'Referencia',
    [SourceValue.VALUE]: 'Valor',
    [SourceValue.SKIPPED_COLUMN]: 'Columna ignorada',
    [SourceValue.FIXED_VARIABLE]: 'Valor (desde selección de variable)',
  },
  errors: {
    jqError: 'Tu código JQ contiene errores.',
    fileParse: {
      [FileParseErrorCodes.InvalidDate]: 'La fecha no es válida',
      [FileParseErrorCodes.InvalidValue]: 'El valor no es válido',
      [FileParseErrorCodes.InvalidReference]: 'La referencia no es válida',
      [FileParseErrorCodes.MissingColumn]: 'Falta columna',
      [FileParseErrorCodes.MissingReference]: 'Falta referencia',
    } as Record<FileParseErrorCodes, string>,
    papaParse: {
      MissingQuotes: 'Faltan comillas',
      UndetectableDelimiter: 'No se puede detectar el delimitador',
      TooFewFields: 'Demasiados pocos campos',
      TooManyFields: 'Demasiados campos',
      InvalidQuotes: 'Comillas inválidas',
    } as Record<PapaParseError['code'], string>,
    upload: {
      'file-too-large': 'El archivo supera el tamaño máximo autorizado',
      'file-too-small': 'El archivo es demasiado pequeño',
      'file-invalid-type': 'Este tipo de archivo no está permitido',
      'too-many-files': 'Demasiados archivos seleccionados',
    } as Record<DropzoneErrorCode, string>,
  },
};
