import { ConnectionType, MetadataType, MetricCategory, OrderBy } from '@dametis/core';

// import { BlockDiagramView } from 'components/Lego/BlockDiagram/BlockDiagramTopBar';
import { SortedBy as BlockSortedBy } from 'components/Lego/BlockDiagram/BlockList/BlockList';
import { SortedBy as FolderSortedBy } from 'components/Lego/BlockDiagram/FolderList/FolderList';
import { BlockTypePanelTab } from 'components/Lego/LegoCatalog/CatalogRightPanel/BlockTypeRightPanel/BlockTypeRightPanel';
import { StandardBlockPanelTab } from 'components/Lego/LegoCatalog/CatalogRightPanel/StandardBlockRightPanel/StandardBlockRightPanel';
import { MenuTab } from 'components/Lego/LegoCatalog/LegoCatalog';
import { MetadataListName } from 'components/Lego/UI/BlockType/CreateBlockTypeModal/MetadataListsStep';
import { TabBlockFiltersElements } from 'types/lego';

export default {
  title: {
    lego: 'Schéma-bloc',
    blockTypes: 'Types de bloc',
    typesCatalog: 'Catalogue des types',
    createBlockTypeModal: 'Nouveau type de bloc',
    createStandardBlockModal: 'Nouveau bloc standard',
    editBlockTypeModal: 'Modifier le type de bloc',
    blockTypePanel: 'Type de bloc',
    blocks: 'Blocs',
    folders: 'Dossiers',
    createBlockModal: 'Nouveau bloc',
    createFolderModal: 'Nouveau dossier',
    updateBlockModal: 'Modifier le bloc',
    updateFolderModal: 'Modifier le dossier',
    blockPanel: 'Bloc',
    folderPanel: 'Dossier',
    blockFilters: 'Filtrer les paramètres et les métriques',
    nextAction: 'Vous venez de créer un bloc. Que voulez-vous faire maintenant ?',
    setBlockParameters: 'Paramètres du bloc « {{blockName}} »',
    moveTo: 'Déplacer vers...',
    chooseBlock: 'Choisissez un bloc',
    chooseBlockFromBlockType: 'Choisissez un bloc « {{blockTypeName}} »',
    navigation: 'Navigation',
    editFolder: 'Modifier le dossier',
    tools: 'Outils',
    editStandardBlockModal: 'Modifier le bloc standard',
  },
  subtitle: {
    folders: 'Dossiers',
    blockTypes: 'Types',
    standardBlocks: 'Blocs standards',
  },
  button: {
    add: 'Ajouter',
    addBlock: 'Ajouter un bloc',
    addBlocks: 'Ajouter des blocs',
    newBlockType: 'Type de bloc',
    newBlock: 'Bloc',
    newFolder: 'Dossier',
    close: 'Fermer',
    cancel: 'Annuler',
    create: 'Créer',
    update: 'Modifier',
    duplicate: 'Dupliquer',
    setParameters: 'Paramètres',
    edit: 'Modifier',
    submit: 'Valider',
    delete: 'Supprimer',
    previous: 'Précédent',
    next: 'Suivant',
    blocks: 'Blocs',
    blocks_zero: '0 bloc',
    blocks_one: '{{count}} bloc',
    blocks_other: '{{count}} blocs',
    goToBlocks: 'Aller aux blocs',
    open: 'Ouvrir',
    openInPlayground: 'Ouvrir dans un playground',
    createAnOtherBlock: 'Créer un autre bloc',
    setBlockParameters: 'Définir les paramètres',
    save: 'Enregistrer',
    sortedBy: 'Trier par : $t(lego:label.sortedBy.{{value}})',
    new: 'Nouveau',
    createBlock: 'Bloc',
    createFolder: 'Dossier',
    moveTo: 'Déplacer vers',
    move: 'Déplacer',
    newStandardBlock: 'Nouveau bloc standard',
  },
  label: {
    name: 'Nom',
    description: 'Description',
    parameters: 'Paramètres',
    parametersWithCount_zero: 'Aucun paramètre',
    parametersWithCount_one: '{{count}} paramètre',
    parametersWithCount_other: '{{count}} paramètres',
    metrics: 'Métriques',
    connections: 'Liaisons',
    availableParameters: 'Paramètres disponibles',
    blockTypes: 'Types de bloc',
    owners: 'Propriétaires',
    undefinedParameters_one: '{{count}} non assigné',
    undefinedParameters_other: '{{count}} non assignés',
    comment: 'Commentaire',
    sortedBy: {
      [BlockSortedBy.BLOCK_NAME]: 'Nom du bloc',
      [BlockSortedBy.OWNER]: 'Propriétaire',
      [BlockSortedBy.BLOCKTYPE_NAME]: 'Nom du type de bloc',
      [BlockSortedBy.NB_PARAMETERS]: 'Nombre de paramètres',
      [BlockSortedBy.NB_METRICS]: 'Nombre de métriques',
      [BlockSortedBy.CREATED_AT]: 'Date de création',
      [BlockSortedBy.UPDATED_AT]: 'Date de modification',
      [FolderSortedBy.FOLDER_NAME]: 'Nom',
      [FolderSortedBy.NB_BLOCKS]: 'Nombre de blocs',
      [FolderSortedBy.NB_FOLDERS]: 'Nombre de dossiers',
    },
    parametersLength_zero: 'Aucun paramètre',
    parametersLength_one: '{{count}} paramètre',
    parametersLength_other: '{{count}} paramètres',
    metricsLength_zero: 'Aucune métrique',
    metricsLength_one: '{{count}} métrique',
    metricsLength_other: '{{count}} métriques',
    connectionsLength_zero: 'Aucune liaison',
    connectionsLength_one: '{{count}} liaison',
    connectionsLength_other: '{{count}} liaisons',
    blocksLength_zero: 'Aucun bloc',
    blocksLength_one: '{{count}} bloc',
    blocksLength_other: '{{count}} blocs',
    standardBlocksLength_zero: 'Aucun bloc standard',
    standardBlocksLength_one: '{{count}} bloc standard',
    standardBlocksLength_other: '{{count}} blocs standards',
    blockTypesLength_zero: 'Aucun type',
    blockTypesLength_one: '{{count}} type',
    blockTypesLength_other: '{{count}} types',
    foldersLength_zero: 'Aucun dossier',
    foldersLength_one: '{{count}} dossier',
    foldersLength_other: '{{count}} dossiers',
    availableBlocksLength_zero: 'Aucun fonctionnel',
    availableBlocksLength_one: '{{count}} fonctionnel',
    availableBlocksLength_other: '{{count}} fonctionnels',
    unavailableBlocksLength_zero: 'Aucun incomplet',
    unavailableBlocksLength_one: '{{count}} incomplet',
    unavailableBlocksLength_other: '{{count}} incomplets',
    blockListToolbar:
      '$t(lego:label.blocksLength, { "count": {{blocksLength}} }) ($t(lego:label.availableBlocksLength, { "count": {{availableBlocksLength}} }), $t(lego:label.unavailableBlocksLength, { "count": {{unavailableBlocksLength}} }))',
    filtersAndSort: 'Filtres et Tri',
    newBlock: 'Nouveau bloc',
    newBlockType: 'Nouveau type',
    newFolder: 'Nouveau dossier',
    tags: 'Tags',
    metadataList: 'Métadonnées',
    options: 'Options',
    isVirtual: 'métrique virtuelle',
    folder: 'Dossier',
    blockType: 'Type de bloc',
    standardMetadataList: 'Métadonnées catalogue',
    blockMetadataList: 'Métadonnées bloc',
    newStandardBlock: 'Nouveau bloc standard',
    metricMetrics: 'Métriques',
    discrepancyMetrics: 'Discordances',
    technicalMetrics: 'Métier',
    standardBlock: 'Bloc standard',
    standardBlocks: 'Blocs standards',
    groupByType: 'Grouper par type',
    metadata: 'Métadonnées',
  },
  placeholder: {
    name: 'Nom',
    calculation: 'Calcul',
    blockKey: 'Identifiant',
    unit: 'Unité',
    blockType: 'Type de bloc',
    defaultValue: 'Valeur par défaut',
    value: 'Valeur',
  },
  stepper: {
    general: 'Général',
    metrics: 'Métriques',
    parameters: 'Paramètres',
    blockType: 'Type de bloc',
    informations: 'Informations',
    connections: 'Liaisons',
    metadataLists: 'Métadonnées',
    metadataList: 'Métadonnées',
    parametersAndMetrics: 'Paramètres & métriques',
  },
  stepperContent: {
    name: 'Nom : {{name}}',
    standardMetadataListLength_zero: 'Aucune catalogue',
    standardMetadataListLength_one: '1 catalogue',
    standardMetadataListLength_other: '{{count}} catalogue',
    blockMetadataListLength_zero: 'Aucune bloc',
    blockMetadataListLength_one: '1 bloc',
    blockMetadataListLength_other: '{{count}} bloc',
    parametersLength_zero: 'Aucun paramètre',
    parametersLength_one: '1 paramètre',
    parametersLength_other: '{{count}} paramètres',
    connectionsLength_zero: 'Aucune liaison',
    connectionsLength_one: '1 liaison',
    connectionsLength_other: '{{count}} liaisons',
    metricsLength_zero: 'Aucune métrique',
    metricsLength_one: '1 métrique',
    metricsLength_other: '{{count}} métriques',
  },
  text: {
    noBlockType: 'Aucun type de bloc',
    noBlock: 'Aucun bloc',
    noStandardBlock: 'Aucun bloc standard',
    noParameter: 'Aucun paramètre',
    noMetric: 'Aucun métrique',
    availableParameter: '{{blockKey}} ({{unit}})',
    otherParametersLength: '+{{count}} paramètre(s)',
    otherMetricsLength: '+{{count}} métrique(s)',
    metricsCount_zero: 'Aucun métrique',
    metricsCount_one: 'Un métrique',
    metricsCount_other: '{{count}} métriques',
    creationDate: 'Créé le {{date}}',
    updateDate: 'Mis à jour le {{date}}',
    addFirstBlockType: 'Ajouter un premier type de bloc',
    addFirstBlock: 'Ajouter un premier bloc',
    canNotDeleteMetricReferredInAnOther: 'Impossible de supprimer un métrique utilisé dans un autre.',
    openInPlayground: 'Ouvrir dans un playground.',
    editParameter: 'Modifier le paramètre.',
    blockVariableWithPath: '{{block}} ▸ {{variable}}',
    // blockVariableWithPath: '{{standardBlock}} ▸ {{block}} ▸ {{variable}}'
    unusedParameters: 'Paramètres inutilisés :',
    usedParameters: 'Paramètres déjà utilisés :',
    favorite: 'Favori',
    allSelected: 'Tout sélectionner',
    chooseBlockType: 'Choisissez un type de bloc :',
    chooseRequiredParameters: 'Choisissez les paramètres requis :',
    nbRequiredParametersSelected_zero: 'Aucun paramètre sélectionné.',
    nbRequiredParametersSelected_one: 'Un paramètre sélectionné.',
    nbRequiredParametersSelected_other: '{{count}} paramètres sélectionnés.',
    metricsAvailable: 'Disponibilité des métriques :',
    nbMetricsAvailable_zero: 'Aucun métrique disponible.',
    nbMetricsAvailable_one: 'Un métrique disponible.',
    nbMetricsAvailable_other: '{{count}} métriques disponibles.',
    noMetricWithHelper: "Aucun métrique n'est disponible pour ce bloc. Vous pouvez ajouter des paramètres pour utiliser plus de métriques.",
    parameterUnset_one: 'Ce paramètre est requis pour le fonctionnement du bloc',
    parameterUnset_other: 'Ces paramètres sont requis pour le fonctionnement du bloc',
    parameterUnsetVnc: `Ce paramètre n'a pas été défini`,
    orderBy: {
      [OrderBy.ASC]: 'Croissant',
      [OrderBy.DESC]: 'Décroissant',
    },
    home: 'Accueil',
    factory: 'Usine',
    noBlockInFolder: "Vous n'avez aucun bloc dans ce dossier",
    noFolderInFolder: "Vous n'avez aucun dossier",
    noCorrespondingBlock: 'Aucun bloc ne correspond à votre recherche',
    noCorrespondingFolder: 'Aucun dossier ne correspond à votre recherche',
    noFolder: 'Aucun dossier',
    noParameterToDefine: 'Aucun paramètre à définir',
    editToAddParameters: 'Vous devez modifier ce bloc pour lui ajouter des paramètres.',
    copy: 'Copie',
    noConnection: 'Aucune liaison',
    noConnectedBlock: 'Aucun bloc lié',
    noMetadata: 'Aucune métadonnée',
    noOption: 'Aucune option disponible',
    connectionName: 'Liaison « {{name}} »',
    unknownBlock: 'Bloc inconnu',
    unknownType: 'Type inconnu',
    noAvailableBlock: 'Aucun bloc disponible',
    noAvailableOption: 'Aucune option disponible',
    connectedBlocks: 'Blocs liés',
    emptyFolder: 'Dossier vide',
    noAvailableFolder: 'Aucun dossier disponible',
    canNotLoadFolders: 'Impossible de charger les dossiers',
    unknownName: 'Nom inconnu',
    noOptionSelected: 'Aucune option sélectionnée',
    noMetricMetrics: 'Aucune métrique',
    noDiscrepancyMetrics: 'Aucune discordance',
    noTechnicalMetrics: 'Aucune métier',
    noElement: 'Aucun élément',
    metadataOptionsLength_zero: 'Aucune option',
    metadataOptionsLength_one: '1 option',
    metadataOptionsLength_other: '{{count}} options',
    deleteBlockTypeWarning_one:
      'Ce type de bloc est lié à un autre type de bloc. Sa suppression peut affecter le bon fonctionnement de certains outils.',
    deleteBlockTypeWarning_other:
      'Ce type de bloc est lié à {{count}} autres types de bloc. Sa suppression peut affecter le bon fonctionnement de certains outils.',
    deleteBlockWarning_one: 'Ce bloc est lié à un autre bloc. Sa suppression peut affecter le bon fonctionnement de certains outils.',
    deleteBlockWarning_other:
      'Ce bloc est lié à {{count}} autres blocs. Sa suppression peut affecter le bon fonctionnement de certains outils.',
  },
  toast: {
    // FOLDER
    createFolderSuccess: 'Le dossier a bien été créé',
    updateFolderSuccess: 'Le dossier a bien été mis à jour',
    deleteFolderSuccess: 'Le dossier a bien été supprimé',
    duplicateFolderSuccess: 'Le dossier a bien été dupliqué',
    moveFolderSuccess: 'Le dossier a bien été déplacé',
    // BLOCK
    createBlockSuccess: 'Le bloc a bien été créé',
    updateBlockSuccess: 'Le bloc a bien été mis à jour',
    deleteBlockSuccess: 'Le bloc a bien été supprimé',
    duplicateBlockSuccess: 'Le bloc a bien été dupliqué',
    moveBlockSuccess: 'Le bloc a bien été déplacé',
    // BLOCK TYPE
    createBlockTypeSuccess: 'Le type de bloc a bien été créé',
    updateBlockTypeSuccess: 'Le type de bloc a bien été mis à jour',
    deleteBlockTypeSuccess: 'Le type de bloc a bien été supprimé',
    duplicateBlockTypeSuccess: 'Le type de bloc a bien été dupliqué',
    moveBlockTypeSuccess: 'Le type de bloc a bien été déplacé',
    // STANDARD BLOCK
    createStandardBlockSuccess: 'Le bloc standard a bien été créé',
    updateStandardBlockSuccess: 'Le bloc standard a bien été mis à jour',
    deleteStandardBlockSuccess: 'Le bloc standard a bien été supprimé',
    duplicateStandardBlockSuccess: 'Le bloc standard a bien été dupliqué',
    moveStandardBlockSuccess: 'Le bloc standard a bien été déplacé',
  },
  blockFilters: {
    elements: {
      [TabBlockFiltersElements.ALL]: 'Tous',
      [TabBlockFiltersElements.FAVORITES]: 'Favoris',
      [TabBlockFiltersElements.PARAMETERS]: 'Paramètres',
      [TabBlockFiltersElements.METRICS]: 'Métriques',
    },
  },
  tooltip: {
    // view: {
    //   [BlockDiagramView.FOLDERS]: 'Dossiers',
    //   [BlockDiagramView.ALL_BLOCKS]: 'Tous les blocs',
    // },
    createEntity: 'créer une entité',
    seeCalculation: "Voir l'expression",
    createBlock: 'créer un bloc',
    createFolder: 'créer un dossier',
    createBlockType: 'créer un type de bloc',
    addBlock: 'Ajouter un bloc',
    addBlocks: 'Ajouter des blocs',
    blockDoesNotExist: 'Impossible de récupérer ce bloc',
    edit: 'Modifier',
    delete: 'Supprimer',
    settings: 'Réglages',
    unknownBlockType: 'Type de bloc inconnu',
    notWorkingStandardBlockMetric:
      'Cette métrique nécessite certains paramètres non sélectionnés et sans valeur par défaut pour fonctionner.',
    notWorkingBlockMetric: "Cette métrique n'a pu être générée. Assurez vous que les paramètres dont elle dépend sont bien définis",
    folderContentLength:
      '$t(lego:label.blockTypesLength, { "count": {{blockTypesLength}} }) / $t(lego:label.standardBlocksLength, { "count": {{standardBlocksLength}} }) / $t(lego:label.foldersLength, { "count": {{foldersLength}} })',
  },
  connectionType: {
    [ConnectionType.UNIQUE]: 'Unique',
    [ConnectionType.MULTIPLE]: 'Multiple',
  },
  panelTab: {
    [BlockTypePanelTab.INFORMATIONS]: 'Informations',
    [BlockTypePanelTab.STANDARD_BLOCKS]: 'Blocs standards',
    [StandardBlockPanelTab.BLOCKS]: 'Blocs',
  },
  menuTab: {
    [MenuTab.FOLDER_CONTENT]: 'Tous les types',
    [MenuTab.NO_FOLDER_CONTENT]: 'Sans dossier',
  },
  metricCategory: {
    [MetricCategory.METRIC]: 'Métrique',
    [MetricCategory.DISCREPANCY]: 'Discordance',
    [MetricCategory.TECHNICAL]: 'Métier',
  },
  metadataType: {
    [MetadataType.STRING]: 'Chaîne de caractère',
    [MetadataType.OPTION]: 'Liste à choix',
    [MetadataType.NUMBER]: 'Valeur',
  },
  metadataListName: {
    [MetadataListName.STANDARD]: 'Catalogue',
    [MetadataListName.BLOCK]: 'Bloc',
  },
};
