import { TextFieldProps } from '@mui/material';
import { FC, useCallback, useRef, useState } from 'react';

import { useDispatch } from 'store';
import { useVncStore } from 'zustand/stores/vnc';

import { SearchTextField } from './styled';

const Search: FC = () => {
  const dispatch = useDispatch();

  const search = useVncStore(state => state.search);
  const setSearch = useVncStore(state => state.setSearch);
  const getResults = useVncStore(state => state.getResults);

  const debounce = useRef<ReturnType<typeof setTimeout>>();
  const controller = useRef<AbortController | null>(null);

  const [localSearch, setLocalSearch] = useState(search);

  const changeSearch = useCallback<NonNullable<TextFieldProps['onChange']>>(
    event => {
      setLocalSearch(event.target.value);
      setSearch(event.target.value);
      clearTimeout(debounce.current);
      debounce.current = setTimeout(async () => {
        if (controller.current !== null) controller.current.abort();
        controller.current = new AbortController();
        await dispatch(getResults(controller.current));
      }, 50);
    },
    [dispatch, getResults, setSearch],
  );

  return <SearchTextField autoFocus fullWidth value={localSearch} onChange={changeSearch} />;
};

export default Search;
