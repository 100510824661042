import { MoreHoriz } from '@mui/icons-material';
import { ListItem, ListItemText, Stack, capitalize } from '@mui/material';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { MathFunctionHelper } from '@dametis/core';

import { useVncStore } from 'zustand/stores/vnc';

import { FunctionArgContext } from './FunctionArgContext';

interface Props {
  index: number;
  func: MathFunctionHelper;
  input: MathFunctionHelper['inputs'][number];
}

const FunctionPopperListItem: FC<Props> = ({ index, input, func }) => {
  const { t } = useTranslation('vnc');

  const { focused } = useContext(FunctionArgContext);

  const hasSearch = useVncStore(state => Boolean(state.search.length));

  if (hasSearch && focused !== null && (index === focused - 1 || index === focused + 1)) {
    return (
      <Stack direction="row" justifyContent="center">
        <MoreHoriz color="disabled" />
      </Stack>
    );
  }
  if (hasSearch && index !== focused) {
    return null;
  }
  return (
    <ListItem
      key={input.name}
      secondaryAction={
        <>
          <div>{t(`functions.type.${input.type}`)}</div>
          <div>{input.unit}</div>
        </>
      }
      selected={index === focused}
      sx={{ pr: 12, borderRadius: 1 }}
    >
      <ListItemText
        primary={
          <>
            {capitalize(t(`functions.inputs.name.${input.name}`))}
            {input.required && '*'}
          </>
        }
        secondary={t(`functions.inputs.description.${func.name}_${input.name}`)}
      />
    </ListItem>
  );
};

export default FunctionPopperListItem;
