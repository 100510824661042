// import { TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material';
// import {
//   Box,
//   Checkbox,
//   CircularProgress,
//   Divider,
//   ListItemText,
//   ListSubheader,
//   MenuItem,
//   SelectChangeEvent,
//   Stack,
//   Typography,
// } from '@mui/material';
// import { FC, MouseEventHandler, useCallback, useMemo, useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useSearchParams } from 'react-router-dom';

// import { OrderBy, UUID } from '@dametis/core';

// import PageSecondaryLeft from 'components/UI/PageTitleBar/PageSecondaryLeft';
// import PageSecondaryRight from 'components/UI/PageTitleBar/PageSecondaryRight';
// import PageSecondaryToolbar from 'components/UI/PageTitleBar/PageSecondaryToolbar';
// import SelectFilter from 'components/UI/SelectFilter/SelectFilter';
// import { useDispatch, useSelector } from 'store';
// import { SearchParamKey, setFiltersChangesOptionsWithUrl, setFiltersGroupsWithUrl } from 'store/actions/monitoring';
// import {
//   DataPeriod,
//   SortBy,
//   setDataPeriod,
//   setFiltersChangesOptions,
//   setFiltersGroups,
//   setOrderBy,
//   setSortBy,
// } from 'store/slices/oldMonitoring';

// import OrderAndSortMenu from './OrderAndSortMenu';

export enum ChangesOption {
  DEVICES_NO = 'devicesNo',
  DEVICES_MORE = 'devicesMore',
  DEVICES_LESS = 'devicesLess',
  VARIABLES_NO = 'variablesNo',
  VARIABLES_MORE = 'variablesMore',
  VARIABLES_LESS = 'variablesLess',
}

// export interface AdminFiltersProps {
//   isFetching: boolean;
// }

// const AdminFilters: FC<AdminFiltersProps> = ({ isFetching }) => {
//   const { t } = useTranslation('monitoring');
//   const dispatch = useDispatch();

//   const [searchParams, setSearchParams] = useSearchParams();
//   // const [searchGroupInput, setSearchGroupInput] = useState<string>('');

//   const availableGroups = useSelector(state => state.auth.groups);
//   const selectedGroups = useSelector(state => state.monitoring.admin.filters.groups);
//   const selectedChangesOptions = useSelector(state => state.monitoring.admin.filters.changesOptions);

//   const [isInit, setIsInit] = useState<boolean>(false);

//   const availableGroupsUuids = useMemo(() => availableGroups.map(group => group.uuid), [availableGroups]);
//   const availableChangesOptions = useMemo(() => Object.values(ChangesOption), []);

//   const sortedGroups = useMemo(
//     () => [...availableGroups].sort((groupA, groupB) => groupA.name.localeCompare(groupB.name)),
//     [availableGroups],
//   );

//   // const fuse = useMemo(
//   //   () =>
//   //     new Fuse(sortedGroups, {
//   //       keys: ['name', 'normalizedName'],
//   //     }),
//   //   [sortedGroups],
//   // );

//   // const filteredGroups = useMemo(
//   //   () => (searchGroupInput.trim().length > 0 ? fuse.search(searchGroupInput).map(fuseResult => fuseResult.item) : sortedGroups),
//   //   [searchGroupInput, fuse, sortedGroups],
//   // );

//   const handleGroups = useCallback(
//     (event: SelectChangeEvent<UUID[]>) => {
//       dispatch(setFiltersGroupsWithUrl(event.target.value as UUID[], searchParams, setSearchParams));
//     },
//     [dispatch, searchParams, setSearchParams],
//   );

//   const handleAllGroups: MouseEventHandler<HTMLLIElement> = useCallback(
//     event => {
//       event.stopPropagation();
//       if (!selectedGroups.length) {
//         dispatch(setFiltersGroupsWithUrl(availableGroupsUuids, searchParams, setSearchParams));
//       } else {
//         dispatch(setFiltersGroupsWithUrl([], searchParams, setSearchParams));
//       }
//     },
//     [selectedGroups?.length, availableGroupsUuids, dispatch, searchParams, setSearchParams],
//   );

//   const handleChangesOptions = useCallback(
//     (event: SelectChangeEvent<ChangesOption[]>) => {
//       dispatch(setFiltersChangesOptionsWithUrl(event.target.value as ChangesOption[], searchParams, setSearchParams));
//     },
//     [dispatch, searchParams, setSearchParams],
//   );

//   const handleAllChangesOptions: MouseEventHandler<HTMLLIElement> = useCallback(
//     event => {
//       event.stopPropagation();
//       if (!selectedChangesOptions.length) {
//         dispatch(setFiltersChangesOptionsWithUrl(availableChangesOptions, searchParams, setSearchParams));
//       } else {
//         dispatch(setFiltersChangesOptionsWithUrl([], searchParams, setSearchParams));
//       }
//     },
//     [selectedChangesOptions?.length, availableChangesOptions, dispatch, searchParams, setSearchParams],
//   );

//   const initDisplay = useCallback(() => {
//     if (isInit) {
//       return;
//     }
//     const urlGroupsUuids = searchParams.getAll(SearchParamKey.GROUPS);
//     const urlChangesOptions = searchParams.getAll(SearchParamKey.CHANGES_OPTIONS);
//     const urlDataPeriod = searchParams.get(SearchParamKey.DATA_PERIOD);
//     const urlSortBy = searchParams.get(SearchParamKey.SORT_BY);
//     const urlOrderBy = searchParams.get(SearchParamKey.ORDER_BY);

//     const newSearchParams = new URLSearchParams();

//     const groupsUuidsParam =
//       urlGroupsUuids?.length > 0 ? urlGroupsUuids.filter(groupUuid => availableGroupsUuids.includes(groupUuid)) : availableGroupsUuids;
//     dispatch(setFiltersGroups(groupsUuidsParam));
//     groupsUuidsParam.forEach(groupsUuidParam => {
//       newSearchParams.append(SearchParamKey.GROUPS, groupsUuidParam);
//     });

//     const changesOptionsParam =
//       urlChangesOptions?.length > 0
//         ? (urlChangesOptions.filter(changesOption => urlChangesOptions.includes(changesOption)) as ChangesOption[])
//         : [ChangesOption.DEVICES_MORE, ChangesOption.DEVICES_LESS, ChangesOption.VARIABLES_MORE, ChangesOption.VARIABLES_LESS];
//     dispatch(setFiltersChangesOptions(changesOptionsParam));
//     changesOptionsParam.forEach(changesOptionParam => {
//       newSearchParams.append(SearchParamKey.CHANGES_OPTIONS, changesOptionParam);
//     });

//     const dataPeriodParam =
//       urlDataPeriod && Object.values(DataPeriod).includes(urlDataPeriod as DataPeriod)
//         ? (urlDataPeriod as DataPeriod)
//         : DataPeriod.THREE_DAYS;
//     dispatch(setDataPeriod(dataPeriodParam));
//     newSearchParams.set(SearchParamKey.DATA_PERIOD, dataPeriodParam);

//     const orderByParam = urlOrderBy && Object.values(OrderBy).includes(urlOrderBy as OrderBy) ? (urlOrderBy as OrderBy) : OrderBy.ASC;
//     dispatch(setOrderBy(orderByParam));
//     newSearchParams.set(SearchParamKey.ORDER_BY, orderByParam);

//     const sortByParam = urlSortBy && Object.values(SortBy).includes(urlSortBy as SortBy) ? (urlSortBy as SortBy) : SortBy.GROUP_NAME;
//     dispatch(setSortBy(sortByParam));
//     newSearchParams.set(SearchParamKey.SORT_BY, sortByParam);

//     setSearchParams(newSearchParams);
//     setIsInit(true);
//   }, [availableGroupsUuids, dispatch, searchParams, setSearchParams, isInit]);

//   // const handleSearchGroup = useCallback((newSearch: string) => {
//   //   setSearchGroupInput(newSearch);
//   // }, []);

//   useEffect(() => {
//     initDisplay();
//   }, [initDisplay]);

//   return (
//     <>
//       <PageSecondaryToolbar>
//         <PageSecondaryLeft>
//           <SelectFilter
//             multiple
//             value={selectedGroups}
//             onChange={handleGroups}
//             placeholder={t('label.groups')}
//             size="small"
//             MenuProps={{ sx: { maxHeight: 500 } }}
//           >
//             <Box>
//               {/* <ListItem>
//                 <SearchInput value={searchGroupInput} onChange={handleSearchGroup} extendable={false} autoFocus />
//               </ListItem> */}
//               <MenuItem onClick={handleAllGroups}>
//                 <Checkbox
//                   checked={selectedGroups.length === sortedGroups.length}
//                   indeterminate={selectedGroups.length !== 0 && selectedGroups.length !== sortedGroups.length}
//                 />
//                 {t('text.allSelected')}
//               </MenuItem>
//             </Box>
//             <Divider />
//             {sortedGroups.map(group => (
//               <MenuItem key={group.uuid} value={group.uuid}>
//                 <Checkbox checked={selectedGroups.includes(group.uuid)} />
//                 <ListItemText primary={group.name} />
//               </MenuItem>
//             ))}
//           </SelectFilter>
//           <SelectFilter
//             multiple
//             value={selectedChangesOptions}
//             onChange={handleChangesOptions}
//             placeholder={t('label.changesOptions')}
//             size="small"
//           >
//             <Box>
//               <MenuItem onClick={handleAllChangesOptions}>
//                 <Checkbox
//                   checked={selectedChangesOptions.length === availableChangesOptions.length}
//                   indeterminate={selectedChangesOptions.length !== 0 && selectedChangesOptions.length !== availableChangesOptions.length}
//                 />
//                 {t('text.allSelected')}
//               </MenuItem>
//             </Box>
//             <Divider />
//             <ListSubheader disableGutters sx={{ lineHeight: 'unset' }}>
//               {t('title.devices')}
//             </ListSubheader>
//             <MenuItem value={ChangesOption.DEVICES_NO}>
//               <Checkbox checked={selectedChangesOptions.includes(ChangesOption.DEVICES_NO)} />
//               <TrendingFlat fontSize="small" color="icon" />
//               <ListItemText sx={{ ml: 1 }} primary={t(`label.changesOption.${ChangesOption.DEVICES_NO}`)} />
//             </MenuItem>
//             <MenuItem value={ChangesOption.DEVICES_MORE}>
//               <Checkbox checked={selectedChangesOptions.includes(ChangesOption.DEVICES_MORE)} />
//               <TrendingUp fontSize="small" color="success" />
//               <ListItemText sx={{ ml: 1 }} primary={t(`label.changesOption.${ChangesOption.DEVICES_MORE}`)} />
//             </MenuItem>
//             <MenuItem value={ChangesOption.DEVICES_LESS}>
//               <Checkbox checked={selectedChangesOptions.includes(ChangesOption.DEVICES_LESS)} />
//               <TrendingDown fontSize="small" color="error" />
//               <ListItemText sx={{ ml: 1 }} primary={t(`label.changesOption.${ChangesOption.DEVICES_LESS}`)} />
//             </MenuItem>
//             <Divider />
//             <ListSubheader disableGutters sx={{ lineHeight: 'unset' }}>
//               {t('title.variables')}
//             </ListSubheader>
//             <MenuItem value={ChangesOption.VARIABLES_NO}>
//               <Checkbox checked={selectedChangesOptions.includes(ChangesOption.VARIABLES_NO)} />
//               <TrendingFlat fontSize="small" color="icon" />
//               <ListItemText sx={{ ml: 1 }} primary={t(`label.changesOption.${ChangesOption.VARIABLES_NO}`)} />
//             </MenuItem>
//             <MenuItem value={ChangesOption.VARIABLES_MORE}>
//               <Checkbox checked={selectedChangesOptions.includes(ChangesOption.VARIABLES_MORE)} />
//               <TrendingUp fontSize="small" color="success" />
//               <ListItemText sx={{ ml: 1 }} primary={t(`label.changesOption.${ChangesOption.VARIABLES_MORE}`)} />
//             </MenuItem>
//             <MenuItem value={ChangesOption.VARIABLES_LESS}>
//               <Checkbox checked={selectedChangesOptions.includes(ChangesOption.VARIABLES_LESS)} />
//               <TrendingDown fontSize="small" color="error" />
//               <ListItemText sx={{ ml: 1 }} primary={t(`label.changesOption.${ChangesOption.VARIABLES_LESS}`)} />
//             </MenuItem>
//           </SelectFilter>
//           {isFetching && (
//             <Stack direction="row" ml={1} spacing={1} alignItems="center">
//               <CircularProgress size={20} color="secondary" />
//               <Typography variant="subtitle2" fontStyle="italic">
//                 {t('text.fetchingSitesData')}
//               </Typography>
//             </Stack>
//           )}
//         </PageSecondaryLeft>
//         <PageSecondaryRight>
//           <OrderAndSortMenu />
//         </PageSecondaryRight>
//       </PageSecondaryToolbar>
//     </>
//   );
// };

// export default AdminFilters;
