import { Tooltip, TooltipProps, Typography, TypographyProps } from '@mui/material';
import { FC, ReactNode, useRef } from 'react';

import useIsEllipsed from 'hooks/useIsEllipsed';

/**
 * @param {number}[width] if not provided, the width of the Typography will be used
 *  -> useful when using in DataGrids
 */

export interface TypographyEllipseProps extends TypographyProps {
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'> & { title?: ReactNode };
  width?: number;
}

const TypographyEllipse: FC<TypographyEllipseProps> = ({ children = undefined, tooltipProps = undefined, width = undefined, ...props }) => {
  const textRef = useRef(null);

  const isEllipsed = useIsEllipsed(textRef, width);

  return (
    <Tooltip placement="top-start" {...tooltipProps} title={isEllipsed ? (tooltipProps?.title ?? children) : ''}>
      <Typography noWrap {...props} ref={textRef}>
        {children}
      </Typography>
    </Tooltip>
  );
};

export default TypographyEllipse;
