import { UUID } from '@dametis/core';

import { TypedThunk } from 'store';
import { updateSiteAndGroup } from 'store/actions/auth';

const switchGroupSite =
  (entity: { group?: { uuid: UUID }; site?: { uuid: UUID }; isCorporate?: boolean }): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const {
      auth: { selectedGroup, selectedSite, groups },
    } = getState();
    try {
      // une entité appartient toujours à un group
      const entityGroup = groups.find(group => group.uuid === entity.group?.uuid);
      if (!entityGroup) throw new Error();
      // si c'est corporate
      if (entity.isCorporate) {
        // et que l'on est pas sur le bon groupe OU on est pas en mode corpo
        if (entity.group?.uuid !== selectedGroup?.uuid || selectedSite !== null) {
          // on redirige corpo (avec group de l'entité et site à null)
          await dispatch(updateSiteAndGroup({ group: entityGroup, site: null }));
        }
        // si c'est pas corpo et que l'on est pas sur le bon site
      } else if (entity.site?.uuid !== selectedSite?.uuid) {
        // on redirige site (avec group et site de l'entité)
        const entitySite = entityGroup.sites.find(site => site.uuid === entity.site?.uuid);
        await dispatch(updateSiteAndGroup({ group: entityGroup, site: entitySite }));
      }
    } catch (err) {
      console.error(err);
    }
  };

export default switchGroupSite;
