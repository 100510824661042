import { PrimaryEnergies } from '@dametis/core';

export default {
  contract: 'Contrat « {{name}} »',
  contracts: 'Contrats',
  bill: 'Facture « {{name}} »',
  bills: 'Factures',
  button: {
    undo: 'Annuler',
    redo: 'Rétablir',
    close: 'Fermer',
    save: 'Enregistrer',
    addFirstElem: 'Ajouter un élément',
    addPeriod: 'Ajouter une période',
    addValidationFormula: 'Ajouter une formule de validation',
    addContract: 'Contrat',
    refresh: 'Actualiser',
    no: 'Non',
    yes: 'Oui',
    deselectAll: 'Tout désélectionner',
    cancel: 'Annuler',
    addBill: 'Facture',
    add: 'Ajouter',
    uploadBill: 'Télécharger la facture',
    uploadContract: 'Télécharger le contrat',
    edit: 'Modifier',
    seeRelatedContract: 'Voir le contrat associé',
    seeRelatedBills: 'Voir les factures associées',
    confirm: 'Confirmer',
    addPricing: 'Ajouter un tarif',
  },
  energy: {
    [PrimaryEnergies.ELECTRICITY]: 'Électricité',
    [PrimaryEnergies.GAS]: 'Gaz',
    [PrimaryEnergies.WATER]: 'Eau',
  },
  text: {
    billingStrategy: 'Stratégie de facturation',
    informations: 'Informations',
    general: 'Général',
    contract: 'Contrat',
    validation: 'Validation',
    absolutTime: 'Absolu',
    averageTime: 'Moyen',
    count: 'Nombre',
    integral: 'Integrale',
    maximum: 'Maximum',
    VALID: 'Valide',
    INVALID: 'Invalide',
    TO_VALIDATE: 'À valider',
    unableToDeleteContract: 'Vous ne pouvez pas supprimer ce contrat car il a des factures associées.',
    deleteContract: 'Supprimer le contrat',
    pricing: 'Tarifs',
    noSelectedPricingMethod: 'Pas de tarif sélectionné',
    noPricing: 'Aucun tarif',
    noAsset: 'Aucun document',
  },
  title: {
    newContract: 'Nouveau contrat',
    deleteContract: 'Supprimer le contrat',
    contracts: 'Contrats',
    newBill: 'Nouvelle facture',
    bills: 'Factures',
    asset: 'Document',
    general: 'Général',
    period: 'Périodes',
    validation: 'Validation',
    description: 'Description',
    home: "Choisissez le type d'énergie :",
    warning: 'Avertissement',
  },
  input: {
    label: {
      name: 'Nom',
      periodName: 'Nom de la période',
      startDate: 'Date de début',
      endDate: 'Date de fin',
      energy: 'Energie',
      provider: 'Fournisseur',
      search: 'Rechercher',
      color: 'Couleur',
      period: 'Période',
      pricings: 'Tarifs',
      overload: 'Dépassement',
      overloadTime: 'Temps de dépassement',
      billedConsumption: 'Consommation facturée',
      billedPrice: 'Prix facturé',
      description: 'Description',
      billAsset: 'Facture',
      contractName: 'Nom du contrat',
      validated: 'Validé',
      billId: 'Numéro de facture',
      totalPrice: 'Montant de la facture',
      validation: 'Validation',
      total: 'Total',
      clientId: 'ID client',
      deliveryPoint: 'Point de livraison',
      mainFormula: 'Formule principale',
      formula: 'Formule',
      unit: 'Unité',
      price: 'Prix',
      contractPower: {
        value: 'Puissance souscrite',
      },
      validationState: 'État',
      optional: 'Optionnel',
    },
    placeholderEnergies: "Pas d'énergie sélectionnée",
    search: 'Rechercher...',
    energyType: "Type d'énergie",
    contract: 'Contrat',
  },
  prompt: {
    deleteBill: 'Supprimer',
  },
  table: {
    cell: {
      name: 'Nom',
      startDate: 'Date de début',
      endDate: 'Date de fin',
      delete: 'Supprimer',
      energy: 'Energie',
      dates: 'Dates',
      provider: 'Fournisseur',
      billingPeriod: 'Période facturée',
      measuredConsumption: 'Consommation mesurée',
      billedConsumption: 'Consommation facturée',
      contractName: 'Nom du contrat',
      totalBilledConsumption: 'Consommation facturée',
      totalMeasuredConsumption: 'Consommation mesurée',
      validationState: 'État',
      id: 'Numéro de facture',
      contractNameUnknown: 'Nom du contrat inconnu',
      duplicatePeriod: 'Dupliquer la période',
      totalPrice: 'Montant',
    },
  },
  tooltip: {
    createBill: 'créer une facture',
    editBill: 'modifier la facture',
    deleteBill: 'supprimer la facture',
    createContract: 'créer un contrat',
    editContract: 'modifier le contrat',
    deleteContract: 'supprimer le contrat',
    deletePeriod: 'Supprimer la période',
    duplicatePeriod: 'Dupliquer la période',
    validationDelta: 'Delta avec la formule de validation pricipale (n°1)',
    deleteRule: 'Supprimer la formule',
    editPricing: 'Editer le tarif',
    deletePricing: 'Supprimer le tarif',
  },
  xGrid: {
    period: { noData: 'Aucune période' },
    noContract: 'Aucun contrat',
    totalContracts: 'Nombre de contrats',
    noBill: 'Aucune facture',
    totalBills: 'Nombre de factures',
  },
  alert: {
    mismatchedTimeZonesTitle: 'Vos fuseaux horaires diffèrent !',
    mismatchedTimeZones:
      'Vous êtes en « {{currTZ}} » alors que le site est en « {{siteTZ}} ». Sachez que les horaires ci-dessous sont en « {{currTZ}} ».',
    weekNotCovered: 'Vous devez saisir une tarification pour chaque heure de la semaine afin de pouvoir créer une période',
    weekNotCoveredTitle: 'Semaine non couverte',
    incompletePeriodsTitle: 'Périodes incomplètes',
    incompletePeriod: 'Les périodes doivent couvrir toute la durée du contrat',
  },
  error: {
    dateOrder: 'Dates invalides',
    datesNotInContractDateRange: "Les dates ne sont pas comprises dans l'interval du contrat",
    datesOverlap: 'Les dates se chevauchent',
    colorAlreadyExists: 'Couleur déjà utilisée',
    required: 'Requis',
    nameAlreadyExists: 'Nom déjà utilisé',
  },
  helper: {
    PCS: 'PCS', // Pouvoir Calorifique Supérieur
    cannotEditDates: 'Vous ne pouvez pas éditer les dates car il y a des factures associées à ce contrat.',
    cannotEditDatesBecausePeriods: 'Vous ne pouvez pas éditer les dates car il y a des périodes associées.',
    cannotEditPeriods: 'Vous ne pouvez pas éditer les périodes car il y a des factures associées à ce contrat',
    cannotEditValidation: 'Vous ne pouvez pas éditer les formules car il y a des factures associées à ce contrat',
  },
  warning: {
    editBill:
      "Les données de consommation mesurées seront actualisées et sont susceptibles d'être différentes de celles enregistrées dans le cas où la configuration aurait été modifiée.",
  },
  pricingTemplates: {
    PT: 'HP',
    OPT: 'HC',
    ST: 'PTE',
    WPT: 'HPH',
    WOPT: 'HCH',
    SPT: 'HPE',
    SOPT: 'HCE',
  },
};
