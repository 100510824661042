import { makeStyles } from '@mui/styles';

export const useATFStyles = makeStyles(theme => ({
  atf__notEditing: {
    display: 'inline-block',
  },
  'atf__notEditing--fullWidth': {
    width: '100%',
  },
  'atf__notEditing--marginNone': {
    marginTop: 0,
    marginBottom: 0,
  },
  'atf__notEditing--marginDense': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  'atf__notEditing--marginNormal': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));
