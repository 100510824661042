import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { FC, FormEventHandler, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CalculationVariable, CreateAliasBody, ShortAliasInfo } from '@dametis/core';

import { useCreateAliasMutation, useUpdateAliasMutation } from 'store/api/aliases';

import VncInput from '../../../VncInput';
import { PropsContext } from '../../../context';

import AliasChip from './AliasChip';

export interface AliasDialogProps {
  alias: CreateAliasBody | ShortAliasInfo;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess?: (alias: ShortAliasInfo) => void | Promise<void>;
  edit?: boolean;
}

const AliasDialog: FC<AliasDialogProps> = ({ alias, open, setOpen, edit = false, onSuccess = undefined }) => {
  const { t } = useTranslation('vnc');

  const [createAlias, { isLoading: creating }] = useCreateAliasMutation();
  const [updateAlias, { isLoading: updating }] = useUpdateAliasMutation();

  const [name, setName] = useState<string>(alias.name);
  const [calculation, setCalculation] = useState<CalculationVariable>(alias.calculation);

  const { sourceCategory, sourceUuid } = useContext(PropsContext);

  useEffect(() => {
    setName(alias.name);
  }, [alias.name, open]);

  useEffect(() => {
    setCalculation(alias.calculation);
  }, [alias.calculation, open]);

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const changeName = useCallback<NonNullable<TextFieldProps['onChange']>>(event => {
    setName(event.target.value);
  }, []);

  const handleCreateAlias = useCallback<FormEventHandler<HTMLFormElement>>(
    async event => {
      event.preventDefault();
      try {
        const newAlias = await createAlias({
          ...(alias as CreateAliasBody),
          name,
        }).unwrap();
        await onSuccess?.(newAlias);
        close();
      } catch (error) {
        console.error(error);
      }
    },
    [createAlias, alias, name, onSuccess, close],
  );

  const handleUpdateAlias = useCallback<FormEventHandler<HTMLFormElement>>(
    async event => {
      event.preventDefault();
      try {
        const newAlias = await updateAlias({
          ...alias,
          name,
          calculation,
        } as ShortAliasInfo).unwrap();
        await onSuccess?.(newAlias);
        close();
      } catch (error) {
        console.error(error);
      }
    },
    [updateAlias, alias, name, calculation, onSuccess, close],
  );

  return (
    <Dialog open={open} onClose={close}>
      <form onSubmit={edit ? handleUpdateAlias : handleCreateAlias}>
        <DialogTitle>{t(edit ? 'dialog.title.updateAlias' : 'dialog.title.createAlias')}</DialogTitle>
        <DialogContent>
          <Grid2 container spacing={1}>
            <Grid2 xs={6}>
              <TextField autoFocus fullWidth required label={t('input.label.aliasName')} value={name} onChange={changeName} />
            </Grid2>
            <Grid2 xs={12}>
              <VncInput
                disableCreateAlias
                editing={edit}
                label={t('input.label.aliasSlate')}
                sourceCategory={sourceCategory}
                sourceUuid={sourceUuid}
                value={calculation}
                onChange={setCalculation}
              />
            </Grid2>
            <Grid2 xs={4}>
              <FormLabel>{t('input.label.aliasSource')}</FormLabel>
              <div>
                <AliasChip source={alias.source} />
              </div>
            </Grid2>
            {'owner' in alias && alias.owner && (
              <Grid2 xs={4}>
                <Stack>
                  <FormLabel>{t('input.label.aliasOwner')}</FormLabel>
                  <Typography>
                    {alias.owner.firstName} {alias.owner.lastName}
                  </Typography>
                </Stack>
              </Grid2>
            )}
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>{t('dialog.button.cancel')}</Button>
          <LoadingButton color="secondary" disabled={name.length === 0} loading={creating || updating} type="submit" variant="contained">
            {t(edit ? 'dialog.button.updateAlias' : 'dialog.button.createAlias')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AliasDialog;
