export default {
  label: {
    noData: 'No data to display',
    loading: 'Loading...',
    smartScale: 'Smart scales',
    regularScale: 'Regular scales',
    resetZoom: 'Reset zoom',
    start: 'Start:',
    end: 'End:',
    date: 'Date:',
    dependency: 'Dependencies:',
    week: 'Week',
    duration: 'Duration',
    delta: 'Delta',
  },
  button: {
    edit: 'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
    add: 'Add',
    save: 'Save',
  },
  input: {
    comment: 'Comment',
  },
  tooltip: {
    expandAdvancedMode: 'Advanced mode',
    insertComment: 'Add comment',
    insertCommentDisabled: "You can't comment calculated variables",
    drawSegment: 'Draw segment',
    statistics: 'View statistics',
    annotationOff: 'Annotations off',
    annotationOn: 'Annotations on',
    commentOff: 'Hide comments',
    commentOn: 'Show comments',
    clearAll: 'Clear all measures',
    deleteMeasure: 'Delete measure',
  },
};
