import { Components, Theme, chipClasses } from '@mui/material';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    link: true;
  }
}

export default {
  variants: [
    {
      props: { variant: 'link' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.link.light,
        color: theme.palette.link.contrastText,
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.link.main,
        },
        [`& .${chipClasses.icon}`]: {
          color: theme.palette.link.contrastText,
        },
        [`&:hover .${chipClasses.icon}`]: {
          color: theme.palette.link.main,
        },
      }),
    },
  ],
  styleOverrides: {
    root: {
      cursor: 'inherit',
    },
    clickable: {
      cursor: 'pointer',
    },
  },
} as Components<Theme>['MuiChip'];
