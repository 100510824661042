import { Box, BoxProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface Props extends BoxProps {
  size?: 'normal' | 'large';
  disablePaddingTop?: boolean;
}

const CenteredPage: FC<PropsWithChildren<Props>> = ({ size = 'normal', disablePaddingTop = false, children = undefined, ...props }) => (
  <Box {...props}>
    <Box
      sx={theme => ({
        width: 1,
        height: 1,
        maxWidth: size === 'normal' ? theme.others.pageSizeNormal : theme.others.pageSizeLarge,
        my: 0,
        mx: 'auto',
        pt: disablePaddingTop ? undefined : theme.others.pagePaddingTop,
        pb: theme.others.pagePaddingBottom,
        pl: theme.others.pagePaddingLeft,
        pr: theme.others.pagePaddingRight,
        '@media print': {
          m: 0,
          p: 0,
        },
      })}
    >
      {children}
    </Box>
  </Box>
);

export default CenteredPage;
