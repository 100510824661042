import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ExplanatoryVariable, ModelInfo, ModelKeyField } from '@dametis/core';

import { calculationToString } from 'functions/calculationToString';

import ModelVariableAvatar from '../../../../Model/ModelVariableAvatar';
import CalculationSlate from '../../../../UI/CalculationSlate/CalculationSlate';

import ModelItem from './ModelItem';

interface Props {
  selectedModelUuid: ModelInfo['uuid'];
  explanatoryVariable: ExplanatoryVariable;
  xVarIndex: number;
  onSelect: () => void;
}

const ModelColumnXVar: FC<Props> = ({ selectedModelUuid, explanatoryVariable, xVarIndex, onSelect }) => {
  const { t } = useTranslation('vnc');

  const primary = useMemo(() => calculationToString(explanatoryVariable.variable), [explanatoryVariable.variable]);

  return (
    <ModelItem
      key={explanatoryVariable.uuid}
      avatar={<ModelVariableAvatar index={xVarIndex} kind="X" sx={[explanatoryVariable.disabled && { opacity: 0.5 }]} />}
      caption={t('list.text.variable')}
      primary={primary}
      secondary={<CalculationSlate calculation={explanatoryVariable.variable} />}
      variable={{
        modelUuid: selectedModelUuid,
        modelKey: ModelKeyField.X_VAR,
        modelXVarUuid: explanatoryVariable.uuid,
      }}
      onClick={onSelect}
    />
  );
};

export default ModelColumnXVar;
