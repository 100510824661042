import { RefObject, useCallback, useEffect, useState } from 'react';

const useIsEllipsed = (ref: RefObject<HTMLDivElement>, maxWidth?: number) => {
  const [isEllipsed, setIsEllipsed] = useState<boolean>(false);

  const handleResize = useCallback(() => {
    if (ref.current) {
      const wantedWidth = maxWidth || ref.current.clientWidth;
      ref.current.style.position = 'absolute';
      const realWidth = ref.current.clientWidth;
      ref.current.style.position = '';
      setIsEllipsed(wantedWidth < realWidth);
    }
  }, [maxWidth, ref]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    if (ref.current) {
      resizeObserver.observe(ref.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
    return undefined;
  }, [handleResize, ref]);

  return isEllipsed;
};

export default useIsEllipsed;
