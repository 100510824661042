import { DataGridPremiumProps, GridApi, gridClasses } from '@mui/x-data-grid-premium';
import { MutableRefObject } from 'react';

export const onColumnHeaderClick: DataGridPremiumProps['onColumnHeaderClick'] = (params, event) => {
  if (event.altKey) {
    event.defaultMuiPrevented = true;
  }
};

const X_PADDING = 32;

export const onColumnHeaderDoubleClick =
  (apiRef: MutableRefObject<GridApi>): DataGridPremiumProps['onColumnHeaderDoubleClick'] =>
  (params, event) => {
    if (!event.altKey) return;
    const header = apiRef.current.getColumnHeaderElement(params.field);
    if (!header) return;
    const title = header.querySelector(`.${gridClasses.columnHeaderTitle}`);
    if (!title) throw new Error();
    const rowIds = apiRef.current.getAllRowIds();
    const width = rowIds.reduce<number>((width1, rowId) => {
      const cell = apiRef.current.getCellElement(rowId, params.field);
      if (!cell) return width1;
      const childrenMaxWidth = Array.from(cell.children).reduce<number>(
        (width2, child) => Math.max(width2, child.scrollWidth + X_PADDING),
        0,
      );
      return Math.max(width1, Math.min(cell.scrollWidth, childrenMaxWidth));
    }, title.scrollWidth + X_PADDING);
    apiRef.current.updateColumns([{ field: params.field, width, maxWidth: Infinity, minWidth: 0, flex: 0 }]);
  };
