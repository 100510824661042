import { Add, AddOutlined, Done } from '@mui/icons-material';
import { Box, Button, Grid, InputLabel, Popover, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { ChangeEventHandler, Dispatch, FormEventHandler, MouseEventHandler, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTypeParameter, BlockTypeParameterBody } from '@dametis/core';

import { createBlockTypeParameterBody } from 'components/Lego/helpers/blockType/createBlockTypeParameterBody';
import { BlockTypeBody } from 'components/Lego/types';
import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import UnitPicker from 'components/UI/UnitPicker/UnitPicker';
import { UnitResult } from 'components/UI/UnitPicker/types';

import LegoParameterPreview from '../../LegoParameterPreview/LegoParameterPreview';

export interface ParametersStepProps<T extends BlockTypeBody> {
  blockTypeBody: T;
  setBlockTypeBody: Dispatch<SetStateAction<T>>;
}

const ParametersStep = <T extends BlockTypeBody = BlockTypeBody>({ blockTypeBody, setBlockTypeBody }: ParametersStepProps<T>) => {
  const { t } = useTranslation('lego');

  const [parameterBody, setParameterBody] = useState<BlockTypeParameterBody>(createBlockTypeParameterBody);
  const [parameterMenuAnchorEl, setParameterMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [editingParameterIndex, setEditingParameterIndex] = useState<number | null>(null);
  const [defaultValueInput, setDefaultValueInput] = useState<string>('');

  const isParameterMenuOpen = useMemo(() => Boolean(parameterMenuAnchorEl), [parameterMenuAnchorEl]);
  const isValidParameterBody = useMemo(() => parameterBody.blockKey.trim().length > 0, [parameterBody]);
  const addParameterMenuIcon = useMemo(() => (editingParameterIndex === null ? Add : Done), [editingParameterIndex]);

  const handleAddParameter: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    setParameterBody(createBlockTypeParameterBody());
    setDefaultValueInput('');
    setEditingParameterIndex(null);
    setParameterMenuAnchorEl(event.currentTarget);
  }, []);

  const handleEditParameter = useCallback(
    (parameter: Required<BlockTypeBody>['parameters'][0], index: number): MouseEventHandler<HTMLButtonElement> =>
      event => {
        setParameterBody(createBlockTypeParameterBody(parameter));
        setDefaultValueInput(parameter.defaultValue !== null ? `${parameter.defaultValue}` : '');
        setEditingParameterIndex(index);
        setParameterMenuAnchorEl(event.currentTarget);
      },
    [],
  );

  const handleClosePopover = useCallback(() => {
    setParameterMenuAnchorEl(null);
  }, []);

  const handleDeleteParameter = useCallback(
    (index: number): MouseEventHandler<HTMLButtonElement> =>
      () => {
        setBlockTypeBody(state => ({
          ...state,
          parameters: (state.parameters ?? []).filter((_parameter, stateIndex) => index !== stateIndex),
        }));
      },
    [setBlockTypeBody],
  );

  const handleChangeParameterBlockKey: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setParameterBody(state => ({ ...state, blockKey: event.target.value }));
  }, []);

  const handleChangeParameterUnit = useCallback((unit: UnitResult) => {
    setParameterBody(state => ({ ...state, unit: unit !== null ? unit : undefined }));
  }, []);

  const handleChangeParameterDefaultValue: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setDefaultValueInput(event.target.value);
    if (event.target.value.trim().length === 0) {
      setParameterBody(state => ({ ...state, defaultValue: null }));
    } else {
      const parsedValue = parseFloat(event.target.value);
      if (!Number.isNaN(parsedValue)) {
        setParameterBody(state => ({ ...state, defaultValue: parsedValue }));
      }
    }
  }, []);

  const handleValidParameter: FormEventHandler = useCallback(
    event => {
      event.preventDefault();
      setParameterMenuAnchorEl(null);
      if (editingParameterIndex !== null) {
        setBlockTypeBody(state => ({
          ...state,
          parameters: (state.parameters ?? []).map((parameter, stateIndex) =>
            editingParameterIndex === stateIndex ? parameterBody : parameter,
          ),
        }));
      } else {
        setBlockTypeBody(state => ({ ...state, parameters: [...(state.parameters ?? []), parameterBody] }));
      }
    },
    [parameterBody, setBlockTypeBody, editingParameterIndex],
  );

  return (
    <>
      <Stack gap={1} width={1}>
        <Stack alignItems="flex-start" direction="row" justifyContent="space-between">
          <InputLabel>{t('label.parameters')}</InputLabel>
          <ActionButton startIcon={<AddOutlined />} onClick={handleAddParameter}>
            {t('button.add')}
          </ActionButton>
        </Stack>
        {(blockTypeBody.parameters ?? []).length > 0 && (
          <Grid container spacing={1}>
            {(blockTypeBody.parameters ?? []).map((parameter, index) => (
              <Grid key={parameter.uuid} item xs={6}>
                <LegoParameterPreview<BlockTypeParameter>
                  parameter={parameter}
                  onDelete={handleDeleteParameter(index)}
                  onEdit={handleEditParameter(parameter, index)}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {(blockTypeBody.parameters ?? []).length === 0 && (
          <Typography p={2} textAlign="center" variant="subtitle2">
            {t('text.noParameter')}
          </Typography>
        )}
        <Popover
          anchorEl={parameterMenuAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={isParameterMenuOpen}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleClosePopover}
        >
          <form onSubmit={handleValidParameter}>
            <Stack direction="row" p={2} spacing={1}>
              <Box width={180}>
                <TextField
                  autoFocus
                  fullWidth
                  autoComplete="off"
                  placeholder={t('placeholder.blockKey')}
                  size="small"
                  value={parameterBody.blockKey}
                  onChange={handleChangeParameterBlockKey}
                />
              </Box>
              <Box width={180}>
                <UnitPicker freeMode value={parameterBody.unit ?? null} onChange={handleChangeParameterUnit} />
              </Box>
              <Box width={180}>
                <TextField
                  fullWidth
                  placeholder={t('placeholder.defaultValue')}
                  size="small"
                  type="number"
                  value={defaultValueInput}
                  onChange={handleChangeParameterDefaultValue}
                />
              </Box>
              <Button
                color="secondary"
                disabled={!isValidParameterBody}
                sx={{ minWidth: 'unset', padding: '6px' }}
                type="submit"
                variant="contained"
                // onClick={handleValidParameter}
              >
                <SvgIcon component={addParameterMenuIcon} />
              </Button>
            </Stack>
          </form>
        </Popover>
      </Stack>
    </>
  );
};

export default ParametersStep;
