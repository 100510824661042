import { Box } from '@mui/material';
import { FC } from 'react';

import BlockElementLinearProgress from './BlockElementLinearProgress';
import ElementProgressLabel from './ElementProgressLabel';

export interface ElementProgressProps {
  label: string;
  total: number;
  available: number;
}

const ElementProgress: FC<ElementProgressProps> = ({ label, total, available }) => (
  <Box>
    <ElementProgressLabel available={available} label={label} total={total} />
    <Box mt={0.5} width="100%">
      <BlockElementLinearProgress value={(available / total) * 100} variant="determinate" />
    </Box>
  </Box>
);

export default ElementProgress;
