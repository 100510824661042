import { BoxStatusLabel } from '../../types/box';

export default {
  title: {
    box: 'Boxes',
    config: 'Configuration',
    editVariable: '',
  },
  text: {
    updatedAt: 'Updated {{date}}',
    noBox: 'You have no box yet.',
    lastWeek: 'Last week',
    lastMonth: 'Last month',
    connected: 'Connected',
    disconnected: 'For {{duration}}',
    disconnectedInternetDetails: 'Internet was down for {{duration}}.',
    disconnectedPowerDetails: 'The power was down for {{duration}} resulting in loss of data.',
    numberVariables: 'Number of variables',
    day: 'd',
    internet: 'Internet',
    power: 'Power',
    pastConnection_default: 'Loading...',
    pastConnection_normal: 'Connected without interruption',
    pastConnection_critical: 'Disconnected at least 1 time',
  },
  chip: {
    connection_default: 'Loading...',
    [`connection_${BoxStatusLabel.NORMAL}`]: 'Connected',
    [`connection_${BoxStatusLabel.WARNING}`]: 'Error',
    [`connection_${BoxStatusLabel.CRITICAL}`]: 'Disconnected',
  },
  error: {
    unknown: 'Unknown',
  },
};
