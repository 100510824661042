import { Chip, ListItem, ListItemButton, ListItemText, Switch, Typography } from '@mui/material';
import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UUID } from '@dametis/core';

import { PropsContext } from 'components/VNC/context';
import useFuse from 'hooks/useFuse';
import { useTags } from 'store/api/tags';
import { useVncStore } from 'zustand/stores/vnc';

import { FilterList } from './styled';

const emptyArray: [] = [];
const filteredOptions = { keys: ['name'] };

export interface TagsFiltersBlockProps {
  makeToggleTag: (tagId: UUID, checked: boolean) => () => Promise<void>;
  search: string;
  siteId: UUID;
}

const TagsFiltersBlock: FC<TagsFiltersBlockProps> = ({ makeToggleTag, search, siteId }) => {
  const { t } = useTranslation('vnc');

  const disabled = useContext(PropsContext).disableFilters.includes('tags');

  const tagsFilters = useVncStore(state => state.filters.tags);

  const { data: tags = emptyArray } = useTags({ siteId }, { skip: !siteId });

  const sortedTags = useMemo(
    () => tags.toSorted((tagA, tagB) => tagA.name.toLocaleLowerCase().localeCompare(tagB.name.toLocaleLowerCase())),
    [tags],
  );

  const filteredTags = useFuse(sortedTags, search, filteredOptions);

  return (
    <FilterList>
      {filteredTags.length === 0 ? (
        <Typography align="center" p={1} pt={0} variant="subtitle2">
          {t('text.noTagFilter')}
        </Typography>
      ) : (
        filteredTags.map(tag => {
          const checked = tagsFilters[tag.uuid] === true;
          return (
            <ListItem
              key={tag.uuid}
              disablePadding
              secondaryAction={
                <Switch
                  checked={checked}
                  color="primary"
                  disabled={disabled}
                  edge="end"
                  size="small"
                  onClick={makeToggleTag(tag.uuid, !checked)}
                />
              }
            >
              <ListItemButton disabled={disabled} selected={checked} onClick={makeToggleTag(tag.uuid, !checked)}>
                <ListItemText primary={<Chip label={tag.name} size="small" sx={{ fontSize: 'inherit' }} />} />
              </ListItemButton>
            </ListItem>
          );
        })
      )}
    </FilterList>
  );
};

export default TagsFiltersBlock;
