import { ChecklistOutlined, SplitscreenOutlined } from '@mui/icons-material';
import { Stack, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { FC, MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SourceColumnRealByHeader, SourceColumnRealByIndex, SourceColumnVirtual } from '@dametis/core';

import SmallToggleButton from 'components/UI/SmallToggleButton/SmallToggleButton';

import { SectionAccordion, SectionAccordionDetails, SectionAccordionSummary } from '../SectionAccordion';

import ColumnsViewSettings from './ColumnsViewSettings';
import SourcesViewSettings from './SourcesViewSettings';

export enum MappingView {
  COLUMNS = 'columns',
  SOURCES = 'sources',
}

export interface MappingSettingsProps {
  availableRealByIndexColumns: SourceColumnRealByIndex[];
  availableRealByHeaderColumns: SourceColumnRealByHeader[];
  availableVirtualColumnSources: SourceColumnVirtual[];
  availableVirtualColumnNames: string[];
}

const WITH_VIEW_PICKER = false;

const MappingSettings: FC<MappingSettingsProps> = ({
  availableRealByIndexColumns,
  availableRealByHeaderColumns,
  availableVirtualColumnSources,
  availableVirtualColumnNames,
}) => {
  const { t } = useTranslation('fileImport');

  const [selectedView, setSelectedView] = useState<MappingView>(MappingView.COLUMNS);

  const handleSelectView = useCallback((event: MouseEvent<HTMLElement>, newView: MappingView | null) => {
    event.stopPropagation();
    if (newView) {
      setSelectedView(newView);
    }
  }, []);

  return (
    <>
      <SectionAccordion>
        <SectionAccordionSummary>
          <Stack alignItems="center" direction="row" justifyContent="space-between" pr={1} width={1}>
            <Typography variant="h6">{t('title.mapping')}</Typography>
            {WITH_VIEW_PICKER && (
              <ToggleButtonGroup exclusive size="small" value={selectedView} onChange={handleSelectView}>
                <SmallToggleButton value={MappingView.COLUMNS}>
                  <Tooltip placement="top" title={t(`tooltip.view.${MappingView.COLUMNS}`)}>
                    <SplitscreenOutlined fontSize="small" />
                  </Tooltip>
                </SmallToggleButton>
                <SmallToggleButton value={MappingView.SOURCES}>
                  <Tooltip placement="top" title={t(`tooltip.view.${MappingView.SOURCES}`)}>
                    <ChecklistOutlined fontSize="small" />
                  </Tooltip>
                </SmallToggleButton>
              </ToggleButtonGroup>
            )}
          </Stack>
        </SectionAccordionSummary>
        <SectionAccordionDetails>
          {(selectedView === MappingView.COLUMNS || !WITH_VIEW_PICKER) && (
            <ColumnsViewSettings
              availableRealByHeaderColumns={availableRealByHeaderColumns}
              availableRealByIndexColumns={availableRealByIndexColumns}
              availableVirtualColumnSources={availableVirtualColumnSources}
            />
          )}
          {selectedView === MappingView.SOURCES && WITH_VIEW_PICKER && (
            <SourcesViewSettings
              availableRealByHeaderColumns={availableRealByHeaderColumns}
              availableRealByIndexColumns={availableRealByIndexColumns}
              availableVirtualColumnNames={availableVirtualColumnNames}
            />
          )}
        </SectionAccordionDetails>
      </SectionAccordion>
    </>
  );
};

export default MappingSettings;
