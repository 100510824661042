import { Components } from '@mui/material';

export const LIST_PADDING = 6;

export default {
  styleOverrides: {
    root: {
      outline: 'none',
      '& *': {
        outline: 'none',
      },
    },
    padding: {
      paddingTop: '0.1px',
      paddingBottom: '0.1px',
    },
  },
} as Components['MuiList'];
