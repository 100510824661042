import { lazy } from 'react';

import { getLocalStorageItem, setLocalStorageItem } from './localStorage';

const lazyWithRetry: typeof lazy = componentImport =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = getLocalStorageItem<boolean>('page-has-been-force-refreshed') ?? false;
    try {
      const component = await componentImport();
      setLocalStorageItem('page-has-been-force-refreshed', false);
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        setLocalStorageItem('page-has-been-force-refreshed', true);
        window.location.reload();
        return componentImport();
      }
      throw error;
    }
  });

export default lazyWithRetry;
