import { Box, Drawer as MuiDrawer, alpha, drawerClasses, styled } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { WipFeatures } from 'config/featureFlags';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useSelector } from 'store';
import { useLayoutStore } from 'zustand/stores/layout';

import DrawerContent from './DrawerContent';
import SecondaryDrawerContent from './SecondaryDrawerContent';
import { adminRoutes } from './adminRoutes';
import { routes } from './routes';

type PreviousStates = 'corporate' | 'admin';
type CurrentStates = 'default' | PreviousStates;

const PrimaryDrawer = styled(MuiDrawer, {
  shouldForwardProp: propName =>
    propName !== 'isExtendedDrawer' && propName !== 'state' && propName !== 'previousState' && propName !== 'currentState',
})<{ isExtendedDrawer: boolean; currentState: CurrentStates; previousState: PreviousStates }>(({
  theme,
  isExtendedDrawer,
  currentState,
  previousState,
}) => {
  let altBackgroundColor = theme.palette.secondary;
  switch (previousState) {
    case 'admin':
      altBackgroundColor = theme.palette.daRed;
      break;

    // corporate
    default:
      break;
  }

  return {
    [`& .${drawerClasses.paper}`]: {
      width: isExtendedDrawer ? theme.others.navBarWidthOpen : theme.others.navBarWidthClosed,
      boxSizing: 'border-box',
      backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.dark} 5%, ${theme.palette.daBlue[200]} 35%, ${theme.palette.daBlue[200]} 45%, ${altBackgroundColor.dark} 55%, ${altBackgroundColor.dark} 60%, ${altBackgroundColor.light} 85%)`,
      backgroundColor: 'unset',
      backgroundSize: '100% 250%',
      backgroundPosition: currentState !== 'default' ? '0% 100%' : '0% 0%',
      border: 'none',
      borderRadius: isExtendedDrawer ? '0 10px 10px 0' : 0,
      transition: `${theme.transitions.create(['width', 'border-radius'])}, ${theme.transitions.create('background-position', {
        duration: 1000,
      })}`,
    },
  };
});

const SecondaryDrawer = styled(MuiDrawer, {
  shouldForwardProp: propName => propName !== 'isExtendedDrawer',
})<{
  isExtendedDrawer: boolean;
}>(({ theme, isExtendedDrawer }) => ({
  [`& .${drawerClasses.paper}`]: {
    width: `calc(${theme.others.navBarWidthOpen}${isExtendedDrawer ? ' + 10px' : ''})`,
    marginLeft: isExtendedDrawer ? `calc(${theme.others.navBarWidthOpen} - 10px)` : theme.others.navBarWidthClosed,
    paddingLeft: isExtendedDrawer ? 10 : undefined,
    backgroundColor: alpha(theme.palette.background.paper, 0.95),
    '@supports  ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
      backgroundColor: theme.palette.background.paperTransparent,
      backdropFilter: 'blur(20px) saturate(180%)',
    },
    backgroundBlendMode: 'overlay',
    border: 'none',
    borderRadius: '0 10px 10px 0',
    transition: theme.transitions.create(['width', 'margin-left', 'padding-left']),
  },
}));

const Drawer: FC = () => {
  const location = useLocation();

  const enableAdmin = useFeatureFlags(WipFeatures.ENABLE_ADMIN);
  const corporate = useSelector(state => !state.auth.selectedSite);
  const admin = useMemo(() => location.pathname.startsWith('/admin') && enableAdmin, [location.pathname, enableAdmin]);

  const [currentState, setCurrentState] = useState<CurrentStates>('default');
  const [previousState, setPreviousState] = useState<PreviousStates>('corporate');

  const selectedRoutes = useMemo(() => (admin ? adminRoutes : routes), [admin]);

  const drawerButtonsContainerRef = useRef<HTMLDivElement>(null);

  const setHoveredRoute = useLayoutStore(state => state.setHoveredRoute);
  const isExtendedDrawer = useLayoutStore(state => state.isExtendedDrawer);
  const hoveredRoute = useLayoutStore(state => state.hoveredRoute);

  const closeSecondaryDrawer = useCallback(() => {
    setHoveredRoute(null);
  }, [setHoveredRoute]);

  useEffect(() => {
    if (admin === true) {
      setCurrentState('admin');
      setPreviousState('admin');
    } else if (corporate === true) {
      setCurrentState('corporate');
      setPreviousState('corporate');
    } else {
      setCurrentState('default');
    }
  }, [corporate, admin]);

  return (
    <Box onMouseLeave={closeSecondaryDrawer}>
      <SecondaryDrawer isExtendedDrawer={isExtendedDrawer} open={Boolean(hoveredRoute)} variant="persistent">
        <SecondaryDrawerContent drawerButtonsContainerRef={drawerButtonsContainerRef} />
      </SecondaryDrawer>
      <PrimaryDrawer
        color="primary"
        currentState={currentState}
        isExtendedDrawer={isExtendedDrawer}
        previousState={previousState}
        variant="permanent"
      >
        <DrawerContent drawerButtonsContainerRef={drawerButtonsContainerRef} routes={selectedRoutes} />
      </PrimaryDrawer>
    </Box>
  );
};

export default Drawer;
