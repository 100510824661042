import { FindFolder, FolderInfo, ShortcutCategory, UUID } from '@dametis/core';

export const getStandardBlockUrl = (globalRootFolder: FolderInfo | null, standardBlockId: UUID): string => {
  if (!globalRootFolder) {
    return '#';
  }
  const parentFolder = FindFolder(
    globalRootFolder,
    child =>
      child.shortcuts.find(shortcut => shortcut.category === ShortcutCategory.STANDARD_BLOCK && shortcut.uuid === standardBlockId) !==
      undefined,
  );
  if (!parentFolder) {
    return `/blockTypes/noFolder/${standardBlockId}`;
  }
  return `/blockTypes/${parentFolder.uuid}/${standardBlockId}`;
};
