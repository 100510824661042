import { ArrowRight, Folder } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
  breadcrumbsClasses,
  useTheme,
} from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FindFolder, FindFolderPath, FolderInfo, ShortBlockInfo, ShortcutCategory, UUID } from '@dametis/core';

import { getFolderColor } from 'components/Lego/helpers/getFolderColor';
import getEntitiesById from 'functions/getEntitiesById';
import { useBlocks } from 'store/api/blocks';
import { useFolders } from 'store/api/folders';
import { useVncStore } from 'zustand/stores/vnc';

import BlockElementsList from './BlockElementsList';
import { ListItemButtonWithArrow } from './ListItemButtonWithArrow';

const blocksEmptyArray: ShortBlockInfo[] = [];

const FoldersView: FC = () => {
  const { t } = useTranslation('vnc');
  const theme = useTheme();

  const { data: rootFolder = null } = useFolders();
  const { data: blocks = blocksEmptyArray } = useBlocks();

  const selectedFolderUuid = useVncStore(state => state.lego.selectedFolderUuid);
  const selectedBlockUuid = useVncStore(state => state.lego.selectedBlockUuid);
  const setSelectedFolderUuid = useVncStore(state => state.setSelectedFolderUuid);
  const setSelectedBlockUuid = useVncStore(state => state.setSelectedBlockUuid);
  const setSelectedStandardBlockUuid = useVncStore(state => state.setSelectedStandardBlockUuid);

  const containerRef = useRef<HTMLDivElement>(null);

  const blocksById = useMemo(() => getEntitiesById<ShortBlockInfo>(blocks), [blocks]);

  const selectedFolder = useMemo(
    () => (rootFolder ? FindFolder(rootFolder, folder => folder.uuid === selectedFolderUuid) : undefined),
    [rootFolder, selectedFolderUuid],
  );
  const selectedBlock = useMemo(() => (selectedBlockUuid !== null ? blocksById[selectedBlockUuid] : null), [blocksById, selectedBlockUuid]);

  const folderPath = useMemo<FolderInfo[]>(() => {
    if (rootFolder === null || selectedFolder === undefined) return [];
    try {
      return FindFolderPath(rootFolder, selectedFolder);
    } catch (err) {
      return [];
    }
  }, [rootFolder, selectedFolder]);

  const folderPathUuids = useMemo(() => folderPath.map(folder => folder.uuid), [folderPath]);

  const handleSelectFolder = useCallback(
    (folderId: UUID) => () => {
      setSelectedBlockUuid(null);
      setSelectedFolderUuid(folderId);
    },
    [setSelectedBlockUuid, setSelectedFolderUuid],
  );

  const handleSelectBlock = useCallback(
    (blockId: UUID | undefined, parentId: UUID) => () => {
      setSelectedFolderUuid(parentId);
      setSelectedBlockUuid(blockId ?? null);
    },
    [setSelectedBlockUuid, setSelectedFolderUuid],
  );

  useEffect(() => {
    if (rootFolder) {
      setSelectedFolderUuid(rootFolder.uuid);
      setSelectedStandardBlockUuid(null);
      setSelectedBlockUuid(null);
    }
  }, [rootFolder, setSelectedStandardBlockUuid, setSelectedBlockUuid, setSelectedFolderUuid]);

  useEffect(() => {
    if (selectedFolderUuid || selectedBlockUuid) {
      if (containerRef.current) {
        containerRef.current.scrollLeft = containerRef.current.scrollWidth;
      }
    }
  }, [selectedFolderUuid, selectedBlockUuid]);

  return (
    <>
      <Stack height="100%">
        <Stack alignItems="center" direction="row" flexShrink={0} px={2} py={1}>
          <Breadcrumbs separator="›" sx={{ [`& .${breadcrumbsClasses.separator}`]: { mx: 1.5 } }}>
            {folderPath.map((folder, index) => (
              <Typography key={folder.uuid}>{index === 0 ? t('text.home') : folder.name}</Typography>
            ))}
            {selectedBlock && <Typography key={selectedBlock.uuid}>{selectedBlock.name}</Typography>}
          </Breadcrumbs>
        </Stack>
        <Box ref={containerRef} sx={{ overflowX: 'auto', flexGrow: 1, borderTop: `1px solid ${theme.palette.divider}` }}>
          <Grid container sx={{ minWidth: '100%', width: 'fit-content', height: '100%' }} wrap="nowrap">
            {folderPath.map(folder => (
              <Grid key={folder.uuid} item xs sx={{ overflow: 'auto', height: '100%', pr: 0.5, minWidth: 300 }}>
                <List subheader={<li />} sx={{ position: 'relative', maxHeight: '100%', overflow: 'auto', '& ul': { p: 0 } }}>
                  <li>
                    <ul>
                      <ListSubheader disableGutters>{t('subtitle.folders')}</ListSubheader>
                      {folder.folders.map(child => (
                        <ListItem key={child.uuid} disablePadding>
                          <ListItemButtonWithArrow selected={folderPathUuids.includes(child.uuid)} onClick={handleSelectFolder(child.uuid)}>
                            <ListItemIcon sx={{ mr: 2, minWidth: 'unset' }}>
                              <Folder fontSize="small" sx={{ color: getFolderColor(child, theme) }} />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ noWrap: true }}>{child.name}</ListItemText>
                            <ArrowRight />
                          </ListItemButtonWithArrow>
                        </ListItem>
                      ))}
                      {folder.folders.length === 0 && (
                        <ListItem>
                          <Typography variant="subtitle2">{t('text.noFolder')}</Typography>
                        </ListItem>
                      )}
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <ListSubheader disableGutters>{t('subtitle.blocks')}</ListSubheader>
                      {folder.shortcuts
                        .filter(
                          shortcut =>
                            shortcut.category === ShortcutCategory.BLOCK && shortcut.uuid !== undefined && blocksById[shortcut.uuid],
                        )
                        .map(shortcut => (
                          <ListItem key={shortcut.uuid} disablePadding>
                            <ListItemButtonWithArrow
                              selected={shortcut.uuid === selectedBlockUuid}
                              onClick={handleSelectBlock(shortcut.uuid, folder.uuid)}
                            >
                              <ListItemText primaryTypographyProps={{ noWrap: true }}>
                                {shortcut.uuid !== undefined && blocksById[shortcut.uuid]?.name}
                              </ListItemText>
                              <ArrowRight />
                            </ListItemButtonWithArrow>
                          </ListItem>
                        ))}
                      {folder.shortcuts.filter(
                        shortcut =>
                          shortcut.category === ShortcutCategory.BLOCK && shortcut.uuid !== undefined && blocksById[shortcut.uuid],
                      ).length === 0 && (
                        <ListItem>
                          <Typography variant="subtitle2">{t('text.noBlock')}</Typography>
                        </ListItem>
                      )}
                    </ul>
                  </li>
                </List>
              </Grid>
            ))}
            {selectedBlock && (
              <Grid item xs sx={{ overflow: 'auto', height: '100%', pr: 0.5, minWidth: 300 }}>
                <BlockElementsList containerRef={containerRef} />
              </Grid>
            )}
          </Grid>
        </Box>
      </Stack>
    </>
  );
};

export default FoldersView;
