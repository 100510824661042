import { OutlinedInput, outlinedInputClasses, styled } from '@mui/material';

const PickerInput = styled(OutlinedInput, { shouldForwardProp: propName => propName !== 'popoverOpen' })<{ popoverOpen?: boolean }>(
  ({ theme, popoverOpen }) => ({
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: `${theme.palette.grey[!popoverOpen ? 300 : 900]}!important`,
    },
    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: `${theme.palette.grey[500]}!important`,
    },
  }),
);

export default PickerInput;
