import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { ElementProgressProps } from './ElementProgress';

export interface ElementProgressLabelProps extends Omit<ElementProgressProps, 'available'> {
  available?: number;
}

const ElementProgressLabel: FC<ElementProgressLabelProps> = ({ label, total, available = undefined }) => (
  <Stack alignItems="flex-end" direction="row" justifyContent="space-between" spacing={1}>
    <Typography noWrap variant="overline">
      {label}
    </Typography>
    {available !== undefined && <Typography variant="h5">{`${available}/${total}`}</Typography>}
    {available === undefined && <Typography variant="h5">{total}</Typography>}
  </Stack>
);

export default ElementProgressLabel;
