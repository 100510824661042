import { Components, inputAdornmentClasses } from '@mui/material';

export default {
  styleOverrides: {
    root: {
      [`&.${inputAdornmentClasses.positionStart}:not(.${inputAdornmentClasses.hiddenLabel})`]: {
        marginTop: '4px !important',
      },
    },
  },
} as Components['MuiInputAdornment'];
