export default {
  name: {
    name: 'Nombre',
    nameTooltip: 'Nombre',
  },
  unit: {
    name: 'Unidad',
    nameTooltip: 'Unidad',
  },
  sensorName: {
    name: 'Sensor',
    nameTooltip: 'Nombre del sensor',
  },
  reference: {
    name: 'Referencia',
    nameTooltip: 'Referencia',
  },
  sensorDescription: {
    name: 'Descripción',
    nameTooltip: 'Descripción',
  },
  enabled: {
    name: 'Act',
    nameTooltip: 'Activo',
  },
  modbusTCPUid: {
    name: 'Uid',
    nameTooltip: 'Uid',
  },
  modbusTCPFunction: {
    name: 'Func',
    nameTooltip: 'Function',
  },
  modbusTCPAddress: {
    name: 'Dirección',
    nameTooltip: 'Dirección',
  },
  modbusTCPInvertsWords: {
    name: 'Invertida',
    nameTooltip: 'Palabra invertida',
  },
  modbusTCPTransformer: {
    name: 'Mult',
    nameTooltip: 'Multiplier',
  },
  modbusTCPDataType: {
    name: 'Type',
    nameTooltip: 'Data Type',
  },
  modbusTCPBit: {
    name: 'Bit',
    nameTooltip: 'Número de bit',
  },
  check: {
    name: 'Check',
    nameTooltip: 'Check',
  },
  delete: {
    name: 'Del',
    nameTooltip: 'Delete',
  },

  groupNameSize: {
    modbus: 'Modbus TCP',
    setting: 'Parámetro general',
    action: 'Acción',
  },
  /*
  functionName: {
    _0: 'Sin signo',
    _1: 'Flotante',
    _2: 'Booleano',
    _3: "Lire les registres d'entrée",
  }, */
  functionName: {
    _0: 'Read Coil',
    _1: 'Read Discrete Input',
    _2: 'Read Holding Register',
    _3: 'Read Input Registers',
  },
  typeName: {
    int16: 'Entero con signo',
    uint16: 'Entero sin signo',
    int32: 'Entero largo con signo',
    uint32: 'Entero largo sin signo',
    float32: 'Flotante',
    boolean: 'Booleano',
  },
};
