import { ClearOutlined } from '@mui/icons-material';
import {
  IconButton,
  ListItemIcon,
  MenuItem,
  SvgIcon,
  Tooltip,
  Typography,
  alpha,
  iconButtonClasses,
  menuItemClasses,
  styled,
} from '@mui/material';
import { FC, MouseEventHandler, PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useMatch } from 'react-router-dom';

import { PermissionKey } from '@dametis/core';

import { stopPropagation } from 'functions/stopPropagation';
import { usePermission } from 'hooks/usePermission';
import { useSelector } from 'store';
import { Route as IRoute, SubRoute as ISubRoute, IsSubRouteAction, IsSubRouteRedirect } from 'types/drawer';
import { useLayoutStore } from 'zustand/stores/layout';

export const RouteItem = styled(
  ({ children, to, ...props }: PropsWithChildren<{ to?: string; selected?: boolean; onClick?: () => unknown; disabled?: boolean }>) => (
    <MenuItem component={to ? NavLink : 'li'} to={to} {...props}>
      {children}
    </MenuItem>
  ),
)(({ theme, to }) => ({
  borderRadius: 33,
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  justifyContent: to ? 'space-between' : undefined,
  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  },
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.3),
    color: theme.palette.secondary.dark,
  },
  [`&.${menuItemClasses.selected}:hover`]: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.4),
  },
  [`& .${iconButtonClasses.root}`]: {
    display: 'none',
    padding: 0,
    margin: 0,
  },
  [`&:hover .${iconButtonClasses.root}`]: {
    display: 'flex',
  },
}));

interface Props {
  route: IRoute;
  subRoute: ISubRoute;
  permission?: PermissionKey;
  onDelete?: MouseEventHandler<HTMLButtonElement>;
}

const SubRoute: FC<Props> = ({ route, subRoute, permission = undefined, onDelete = undefined }) => {
  const { t, i18n } = useTranslation('navbar');

  const user = useSelector(state => state.auth.user);

  const selectedGroupId = useSelector(state => state.auth.selectedGroup?.uuid);

  const hasPermission = usePermission(permission);
  const setHoveredRoute = useLayoutStore(state => state.setHoveredRoute);

  const selected = Boolean(useMatch(IsSubRouteRedirect(subRoute) ? `${subRoute.url}/*` : ''));

  const closeSecondaryDrawer = useCallback(() => {
    if (IsSubRouteAction(subRoute)) {
      subRoute.action();
    }
    setHoveredRoute(null);
  }, [subRoute, setHoveredRoute]);

  if ((permission && !hasPermission) || (subRoute.check && user && selectedGroupId && !subRoute.check(user, selectedGroupId))) {
    return null;
  }

  return (
    <RouteItem
      selected={selected && IsSubRouteRedirect(subRoute)}
      to={IsSubRouteRedirect(subRoute) ? subRoute.url : undefined}
      onClick={closeSecondaryDrawer}
    >
      {IsSubRouteAction(subRoute) && !IsSubRouteRedirect(subRoute) && subRoute.icon && (
        <ListItemIcon>
          <SvgIcon component={subRoute.icon} />
        </ListItemIcon>
      )}
      <Typography noWrap sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {i18n.exists(`navbar:${route.name}.${subRoute.name}`) ? t(`${route.name}.${subRoute.name}`) : subRoute.name}
      </Typography>
      {onDelete && (
        <Tooltip placement="right" title={t('tooltip.removeEntity')}>
          <IconButton size="small" onClick={onDelete} onMouseDown={stopPropagation}>
            <ClearOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </RouteItem>
  );
};

export default SubRoute;
