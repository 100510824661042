export default {
  required: 'Requis',
  integer: 'Doit être un entier',
  minArray: 'Doit contenir au moins {{value}} valeur(s)',
  minNumber: 'Doit être >= {{value}}',
  maxNumber: 'Doit être <= {{value}}',
  minString: 'Minimum {{value}} caractères',
  maxString: 'Maximum {{value}} caractères',
  minDate: 'La date doit être après le {{value}}',
  maxDate: 'La date doit être avant le {{value}}',
  exactString: 'Doit faire {{value}} caractères',
  step: 'Doit être un multiple de {{value}}',
  ipv4: 'Format IPv4 incorrect',
  ipv4Range: 'Format de plage IPv4 incorrect, doit être ip/mask',
  type: {
    numberMinMax: 'Doit être un nombre compris entre {{min}} et {{max}}',
    number: 'Doit être un nombre valide',
    string: 'Doit être une chaîne valide',
    boolean: 'Doit être un booléen',
    object: 'Doit être un objet',
    email: 'Doit être une adresse email valide',
    uuid: 'Doit être un UUID valide',
  },
  oneOf: 'Doit être une option disponible',
  isNot: 'Ne peut pas être "{{value}}"',
  positive: 'Doit être positif',
  mustBeDifferents: 'Doit être différent',
  oneOfMultipleFormat: 'Doit correspondre à au moins un format',
  invalidDelay: 'Délai invalide',
};
