import { Typography } from '@mui/material';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

import useBigButtonStyles from './BigButton.styles';

interface Props {
  children?: ReactNode;
  className?: string;
}

const BigButtonTitle: FC<Props> = ({ children = null, className = '' }) => {
  const classes = useBigButtonStyles();
  return (
    <Typography className={clsx(classes.bigButton__title, className)} variant="overline">
      {children}
    </Typography>
  );
};

export default BigButtonTitle;
