import { Button, ButtonProps } from '@mui/material';
import { FC, MouseEvent, MouseEventHandler, useCallback } from 'react';
import { NavLink, NavLinkProps, useNavigate } from 'react-router-dom';

type LinkButtonNavigate = (path: string) => void;

export type LinkButtonOnClick = (goTo: LinkButtonNavigate, e?: MouseEvent<HTMLButtonElement>) => unknown;

type ButtonAndLinkProps = ButtonProps & Partial<NavLinkProps>;

export interface LinkButtonProps extends Omit<ButtonAndLinkProps, 'onClick' | 'to'> {
  to?: NavLinkProps['to'];
  onClick?: LinkButtonOnClick;
}

const LinkButton: FC<LinkButtonProps> = ({ to = undefined, onClick = undefined, children = undefined, className, ...props }) => {
  const navigate = useNavigate();

  const goToCreator = useCallback<(aux?: boolean) => LinkButtonNavigate>(
    (aux = false) =>
      link => {
        if (typeof link !== 'string' || !link.length) return;
        if (aux) {
          window.open(link, '_blank')?.focus();
        } else {
          navigate(link);
        }
      },
    [navigate],
  );

  const handleClick = useCallback<(aux?: boolean) => MouseEventHandler<HTMLButtonElement>>(
    (aux = false) =>
      e => {
        if (onClick) {
          onClick(goToCreator(aux), e);
        }
      },
    [goToCreator, onClick],
  );

  return (
    <Button
      className={className}
      color="link"
      component={NavLink}
      role={undefined}
      to={to}
      variant="contained"
      onAuxClick={handleClick(true)}
      onClick={handleClick()}
      {...props}
    >
      {children}
    </Button>
  );
};

export default LinkButton;
