import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { CLEAR_EDITOR_COMMAND, LexicalEditor, SerializedEditorState, SerializedElementNode } from 'lexical';
import { ChangeEventHandler, FC, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateCommentInfo, IsCommentOnVariableBody, ShortcutCategory, UUID } from '@dametis/core';

import NewProjectForm from 'components/Projects/NewProjectForm';
import {
  CreateProjectBodyForm,
  IsCreateProjectBodyFormValid,
  createCreateProjectBodyForm,
} from 'components/Projects/helpers/createCreateProjectBodyForm';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import { ProjectTasksTemplate } from 'config/projectTasksTemplates';
import { isValid } from 'localization/localizedDateFns';
import { useDispatch, useSelector } from 'store';
import { createProject } from 'store/actions/project';
import { displaySdkErrorToast } from 'store/actions/toasts';
import { CommentModalMode, CommentsProps, LinkedProjectType } from 'types/comment';

import CommentOptionsFooter from '../Comment/CommentOptionsFooter';
import CommentEditor from '../Comment/Edit/CommentEditor';
import { useCommentContext } from '../Comment/context/CommentContext';
import { createCreateCommentInfo } from '../fonction/createCreateCommentInfo';

import { CommentModalProps } from './CommentModal';
import ProjectList from './ProjectList';

const CommentModalBase: FC<CommentModalProps & CommentsProps> = ({
  open,
  defaultValue,
  mode,
  title,
  subtitle,
  isMessageRequired,
  onCreate,
  onUpdate,
  onClose,
  dialogProps,
  footerText,
  datePicker,
  entity,
  entityUuid,
  date: currentDate,
}) => {
  const { t } = useTranslation('comment');
  const dispatch = useDispatch();
  const { currentComment, setCurrentComment, submitting, setSubmitting } = useCommentContext();

  const period = useSelector(state => state.period.present.period);

  const emptyProject: CreateProjectBodyForm = useMemo(() => createCreateProjectBodyForm(), []);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [withProject, setWithProject] = useState<boolean>(false);
  const [linkedProjectType, setLinkedProjectType] = useState<LinkedProjectType>(LinkedProjectType.NEW);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [createProjectBody, setCreateProjectBody] = useState<CreateProjectBodyForm>(emptyProject);
  const [selectedTemplate, setSelectedTemplate] = useState<ProjectTasksTemplate | undefined>(undefined);
  const [selectedProjectUuid, setSelectedProjectUuid] = useState<UUID | null>(null);
  const editorRef = useRef<LexicalEditor>(null);
  const displayFooter = useMemo(() => (currentComment ? IsCommentOnVariableBody(currentComment) : undefined), [currentComment]);

  useEffect(() => {
    if (setCurrentComment && entityUuid !== null && editorRef.current !== null) {
      setCurrentComment(createCreateCommentInfo(entity, entityUuid, period, { date: currentDate }));
    }
    return () => setCurrentComment && setCurrentComment(null);
  }, [currentDate, entity, entityUuid, period, setCurrentComment]);

  const isNewProjectValid = useMemo(
    () => isValid(createProjectBody.deadline) && createProjectBody.name.trim().length > 0,
    [createProjectBody.name, createProjectBody.deadline],
  );
  const isExistingProjectValid = useMemo(() => !!selectedProjectUuid, [selectedProjectUuid]);

  const isProjectValid = useMemo(
    () =>
      (linkedProjectType === LinkedProjectType.NEW && isNewProjectValid) ||
      (linkedProjectType === LinkedProjectType.EXISTING && isExistingProjectValid),
    [linkedProjectType, isNewProjectValid, isExistingProjectValid],
  );

  const handlePreviousStep: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setActiveStep(state => state - 1);
  }, []);

  const handleNextStep: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setActiveStep(state => state + 1);
  }, []);

  const handleClose = useCallback(() => {
    if (!submitting) {
      setOpenModal(false);
      editorRef?.current?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      if (onClose) {
        onClose();
      }
    }
  }, [onClose, submitting]);

  const getProjectUuid = useCallback(async (): Promise<UUID | null> => {
    let projectUuid = null;
    if (withProject) {
      if (linkedProjectType === LinkedProjectType.NEW && IsCreateProjectBodyFormValid(createProjectBody)) {
        const project = await dispatch(createProject(createProjectBody, selectedTemplate));
        if (project) {
          projectUuid = project.uuid;
        }
      } else {
        projectUuid = selectedProjectUuid;
      }
    }
    return projectUuid;
  }, [withProject, linkedProjectType, dispatch, createProjectBody, selectedTemplate, selectedProjectUuid]);

  const onSubmit = useCallback(
    async (comment: CreateCommentInfo) => {
      if (onCreate && mode === CommentModalMode.CREATE) {
        await onCreate(comment);
      }
      if (onUpdate && mode === CommentModalMode.UPDATE) {
        await onUpdate(comment);
      }
    },
    [mode, onCreate, onUpdate],
  );

  const handleSubmit = useCallback(async () => {
    if (editorRef.current === null) {
      return;
    }
    const editorState = editorRef.current.getEditorState();
    try {
      setSubmitting(true);
      const projectUuid = await getProjectUuid();
      if (entityUuid !== null) {
        const comment = createCreateCommentInfo(entity, entityUuid, period, {
          ...currentComment,
          message: editorState.toJSON() as SerializedEditorState<SerializedElementNode>,
          shortcut: projectUuid !== null ? { category: ShortcutCategory.PROJECT, uuid: projectUuid } : undefined,
        });
        if (comment !== null) {
          await onSubmit(comment);
        }
      }
      editorRef.current.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      setOpenModal(false);
      if (onClose) {
        onClose();
      }
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    } finally {
      setSubmitting(false);
    }
  }, [setSubmitting, getProjectUuid, entity, entityUuid, period, currentComment, onClose, onSubmit, dispatch]);

  const handleChangeWithProject: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setWithProject(event.target.checked);
  }, []);

  const handleChangeLinkedProjectType: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setLinkedProjectType(event.target.value as LinkedProjectType);
  }, []);

  const handleChangeSelectedProject = useCallback((uuid: UUID | null) => {
    setSelectedProjectUuid(uuid);
  }, []);

  useEffect(() => {
    setOpenModal(open);
    if (open) {
      setActiveStep(0);
      setWithProject(!!defaultValue?.shortcut?.uuid);
      setCreateProjectBody(emptyProject);
      setSelectedTemplate(undefined);
      setSelectedProjectUuid(defaultValue?.shortcut?.uuid ?? null);
      setLinkedProjectType(defaultValue?.shortcut?.uuid ? LinkedProjectType.EXISTING : LinkedProjectType.NEW);
    }
  }, [open, emptyProject, defaultValue]);

  return (
    <Dialog {...dialogProps} fullWidth maxWidth="md" open={openModal} onClose={handleClose}>
      <DialogTitle>
        <Stack alignItems="center" direction="row" justifyContent="space-between" overflow="hidden" width={1}>
          <Box overflow="hidden">
            <TypographyEllipse variant="h6">{title ?? t('title.modal')}</TypographyEllipse>
            {subtitle && <TypographyEllipse variant="caption">{subtitle}</TypographyEllipse>}
          </Box>
          {withProject && (
            <Stepper activeStep={activeStep} sx={{ minWidth: 230 }}>
              <Step>
                <StepLabel>{t('stepper.comment')}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{t('stepper.project')}</StepLabel>
              </Step>
            </Stepper>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent>
        {activeStep === 0 && (
          <>
            <CommentEditor
              editing
              fixedHeight
              commentFooter={displayFooter ? <CommentOptionsFooter datePicker={datePicker} /> : undefined}
              editorRef={editorRef}
              editorState={
                defaultValue?.message ??
                (editorRef.current?.getEditorState().toJSON() as SerializedEditorState<SerializedElementNode> | undefined)
              }
              setIsEmpty={setIsEmpty}
            />
            <FormControlLabel
              control={<Checkbox checked={withProject} onChange={handleChangeWithProject} />}
              label={t('label.withProject')}
              sx={{ mt: 2 }}
            />
          </>
        )}
        {activeStep === 1 && (
          <>
            <RadioGroup row value={linkedProjectType} onChange={handleChangeLinkedProjectType}>
              <FormControlLabel
                control={<Radio />}
                label={t(`label.linkedProjectType.${LinkedProjectType.NEW}`)}
                value={LinkedProjectType.NEW}
              />
              <FormControlLabel
                control={<Radio />}
                label={t(`label.linkedProjectType.${LinkedProjectType.EXISTING}`)}
                value={LinkedProjectType.EXISTING}
              />
            </RadioGroup>

            {linkedProjectType === LinkedProjectType.NEW && (
              <NewProjectForm
                body={createProjectBody}
                setBody={setCreateProjectBody}
                setTemplate={setSelectedTemplate}
                tasksTemplate={selectedTemplate}
              />
            )}
            {linkedProjectType === LinkedProjectType.EXISTING && (
              <ProjectList value={selectedProjectUuid} onChange={handleChangeSelectedProject} />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {footerText && (
          <Typography ml={2} mr="auto">
            {footerText}
          </Typography>
        )}
        {activeStep === 0 && (
          <>
            <Button color="primary" disabled={submitting} variant="text" onClick={handleClose}>
              {t('button.close')}
            </Button>
            {withProject && (
              <Button
                color="secondary"
                disabled={isMessageRequired && isEmpty}
                endIcon={<ArrowForwardIos />}
                variant="contained"
                onClick={handleNextStep}
              >
                {t('button.next')}
              </Button>
            )}
            {!withProject && (
              <LoadingButton
                color="secondary"
                disabled={isMessageRequired && isEmpty}
                loading={submitting}
                variant="contained"
                onClick={handleSubmit}
              >
                {t('button.submit')}
              </LoadingButton>
            )}
          </>
        )}
        {activeStep === 1 && (
          <>
            <Button color="primary" disabled={submitting} startIcon={<ArrowBackIosNew />} variant="text" onClick={handlePreviousStep}>
              {t('button.previous')}
            </Button>
            <LoadingButton
              color="secondary"
              disabled={(isMessageRequired && isEmpty) || !isProjectValid}
              loading={submitting}
              variant="contained"
              onClick={handleSubmit}
            >
              {t('button.submit')}
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CommentModalBase;
