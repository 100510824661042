import { PhysicalQuantity } from '@dametis/core';
import { UnitName } from '@dametis/unit';

import { UnitPickerMenu } from 'components/UI/UnitPicker/types';

const unitNames: Record<UnitName, string> = {
  [UnitName.GRAM]: 'gramo',
  [UnitName.TONNE]: 'tonelada',
  [UnitName.POUND_WEIGHT]: 'libra',
  [UnitName.OUNCE]: 'onza',
  [UnitName.GRAM_PER_CUBIC_METER]: 'gramo por metro cúbico',
  [UnitName.GRAM_PER_NORMAL_CUBIC_METER]: 'gramo por metro cúbico normalizado',
  [UnitName.METER]: 'metro',
  [UnitName.MILES]: 'milla',
  [UnitName.INCH]: 'pulgada',
  [UnitName.SQUARE_METER]: 'metro cuadrado',
  [UnitName.HECTAR]: 'hectárea',
  [UnitName.CELSIUS]: 'celsius',
  [UnitName.KELVIN]: 'kelvin',
  [UnitName.FAHRENHEIT]: 'fahrenheit',
  [UnitName.CUBIC_METER]: 'metro cúbico',
  [UnitName.LITRE]: 'litro',
  [UnitName.NORMAL_CUBIC_METER]: 'metro cúbico normalizado',
  [UnitName.GALLON_US]: 'galón U.S.',
  [UnitName.GALLON_IMPERIAL]: 'galón imperial',
  [UnitName.BAR]: 'bar',
  [UnitName.PASCAL]: 'pascal',
  [UnitName.PSI]: 'psi',
  [UnitName.LITRE_PER_SECOND]: 'litro por segundo',
  [UnitName.LITRE_PER_MINUTE]: 'litro por minuto',
  [UnitName.LITRE_PER_HOUR]: 'litro por hora',
  [UnitName.CUBIC_METER_PER_SECOND]: 'metro cúbico por segundo',
  [UnitName.CUBIC_METER_PER_MINUTE]: 'metro cúbico por minuto',
  [UnitName.CUBIC_METER_PER_HOUR]: 'metro cúbico por hora',
  [UnitName.NORMAL_CUBIC_METER_PER_HOUR]: 'metro cúbico normalizado por hora',
  [UnitName.TON_PER_HOUR]: 'tonelada por hora',
  [UnitName.GRAM_PER_HOUR]: 'gramo por hora',
  [UnitName.METER_PER_SECOND]: 'metro por segundo',
  [UnitName.METER_PER_HOUR]: 'metro por hora',
  [UnitName.MILES_PER_HOUR]: 'millas por hora',
  [UnitName.SECOND]: 'segundo',
  [UnitName.MINUTE]: 'minuto',
  [UnitName.HOUR]: 'hora',
  [UnitName.DAY]: 'día',
  [UnitName.WEEK]: 'semana',
  [UnitName.AMPERE]: 'amperio',
  [UnitName.VOLT]: 'voltio',
  [UnitName.VOLT_AMPERE]: 'voltamperio',
  [UnitName.HERTZ]: 'hertz',
  [UnitName.WATT]: 'vatio',
  [UnitName.HOT_WATT]: 'vatio calorífico',
  [UnitName.COLD_WATT]: 'vatio frío',
  [UnitName.ELEC_WATT]: 'vatio eléctrico',
  [UnitName.JOULE_PER_HOUR]: 'julio por hora',
  [UnitName.VOLT_AMPERE_REACTIVE]: 'voltamperio reactivo',
  [UnitName.VOLT_AMPERE_REACTIVE_HOUR_POSITIVE]: 'voltamperio reactivo positivo',
  [UnitName.VOLT_AMPERE_REACTIVE_HOUR_NEGATIVE]: 'voltamperio reactivo negativo',
  [UnitName.HORSE_POWER]: 'caballo de vapor',
  [UnitName.TON_OF_REFRIGERATION]: 'tonelada de refrigeración',
  [UnitName.BRITISH_THERMAL_UNIT_PER_HOUR]: 'british thermal unit por hora',
  [UnitName.WATT_HOUR]: 'watthora',
  [UnitName.HOT_WATT_HOUR]: 'watthora calorífico',
  [UnitName.COLD_WATT_HOUR]: 'watthora frío',
  [UnitName.ELEC_WATT_HOUR]: 'watthora eléctrico',
  [UnitName.WATT_HOUR_PCI]: 'watthora PCI',
  [UnitName.WATT_HOUR_PCS]: 'watthora PCS',
  [UnitName.VOLT_AMPERE_HOUR]: 'voltamperio-hora',
  [UnitName.VOLT_AMPERE_REACTIVE_HOUR]: 'voltamperio reactivo hora',
  [UnitName.JOULE]: 'julio',
  [UnitName.BRITISH_THERMAL_UNIT]: 'british thermal unit',
  [UnitName.CALORIE]: 'caloría',
  [UnitName.JOULE_PER_KILOGRAM]: 'julio por kilogramo',
  [UnitName.ROTATION_PER_MINUTE]: 'revolución por minuto',
  [UnitName.PERCENT]: 'porcentaje',
  [UnitName.EURO]: 'euro',
  [UnitName.DOLLAR]: 'dólar',
  [UnitName.LIVRE]: 'libra',
  [UnitName.ENERGY_PER_NORMAL_CUBIC_METER]: 'energía por metro cúbico normalizado',
  [UnitName.NORMAL_CUBIC_METER_PER_ENERGY]: 'metro cúbico normalizado por energía',
  [UnitName.KILO_GRAM_CO2]: 'kilogramo de CO2',
  [UnitName.TONNE_CO2]: 'tonelada de CO2',
  [UnitName.KILO_GRAM_CO2_PER_KILO_WATT_HOUR]: 'kilogramo de CO2 por kilowatthora',
  [UnitName.KILO_GRAM_CO2_PER_KILO_HOT_WATT_HOUR]: 'kilogramo de CO2 por kilowatthora calorífico',
  [UnitName.KILO_GRAM_CO2_PER_KILO_COLD_WATT_HOUR]: 'kilogramo de CO2 por kilowatthora frío',
  [UnitName.KILO_GRAM_CO2_PER_KILO_ELEC_WATT_HOUR]: 'kilogramo de CO2 por kilowatthora eléctrico',
  [UnitName.TONNE_CO2_PER_KILO_WATT_HOUR]: 'tonelada de CO2 por kilowatthora',
  [UnitName.TONNE_CO2_PER_KILO_HOT_WATT_HOUR]: 'tonelada de CO2 por kilowatthora calorífico',
  [UnitName.TONNE_CO2_PER_KILO_COLD_WATT_HOUR]: 'tonelada de CO2 por kilowatthora frío',
  [UnitName.TONNE_CO2_PER_KILO_ELEC_WATT_HOUR]: 'tonelada de CO2 por kilowatthora eléctrico',
  [UnitName.TONNE_OF_OIL_EQUIVALENT]: 'Tonelada de equivalente de petróleo',
  [UnitName.STOKE]: 'stokes',
  [UnitName.EURO_PER_MEGA_WATT_HOUR]: 'euro por megawatthora',
  [UnitName.EURO_PER_MEGA_HOT_WATT_HOUR]: 'euro por megawatthora calorífico',
  [UnitName.EURO_PER_MEGA_COLD_WATT_HOUR]: 'euro por megawatthora frío',
  [UnitName.EURO_PER_MEGA_ELEC_WATT_HOUR]: 'euro por megawatthora eléctrico',
  [UnitName.EURO_PER_KILO_WATT_HOUR]: 'euro por kilowatthora',
  [UnitName.EURO_PER_KILO_HOT_WATT_HOUR]: 'euro por kilowatthora calorífico',
  [UnitName.EURO_PER_KILO_COLD_WATT_HOUR]: 'euro por kilowatthora frío',
  [UnitName.EURO_PER_KILO_ELEC_WATT_HOUR]: 'euro por kilowatthora eléctrico',
  [UnitName.DOLLAR_PER_MEGA_WATT_HOUR]: 'dólar por megawatthora',
  [UnitName.DOLLAR_PER_MEGA_HOT_WATT_HOUR]: 'dólar por megawatthora calorífico',
  [UnitName.DOLLAR_PER_MEGA_COLD_WATT_HOUR]: 'dólar por megawatthora frío',
  [UnitName.DOLLAR_PER_MEGA_ELEC_WATT_HOUR]: 'dólar por megawatthora eléctrico',
  [UnitName.DOLLAR_PER_KILO_WATT_HOUR]: 'dólar por kilowatthora',
  [UnitName.DOLLAR_PER_KILO_HOT_WATT_HOUR]: 'dólar por kilowatthora calorífico',
  [UnitName.DOLLAR_PER_KILO_COLD_WATT_HOUR]: 'dólar por kilowatthora frío',
  [UnitName.DOLLAR_PER_KILO_ELEC_WATT_HOUR]: 'dólar por kilowatthora eléctrico',
  [UnitName.BYTE_EN]: 'octeto',
  [UnitName.BYTE_FR]: 'octeto',
  [UnitName.GRAM_PER_LITRE]: 'gramo por litro',
  [UnitName.SIEMENS]: 'siemens',
  [UnitName.SIEMENS_PER_CENTIMETER]: 'siemens por centímetro',
  [UnitName.NO_UNIT]: '',
  // [UnitName.NO_UNIT]: 'Sin unidad',
};

export default {
  text: {
    baseUnit: 'Unidad base: {{unit}}',
    newOption: 'Unidad personalizada: "{{value}}"',
    noUnit: 'Sin unidad',
    noUnitElement: 'Sin unidad',
    unitUndefined: 'Unidad no definida',
    unitUndefinedWithBase: '{{base}} (por defecto)',
    inputLabel: 'Unidad',
  },
  tooltip: {
    menu: {
      [UnitPickerMenu.LIST]: 'Por unidad',
      [UnitPickerMenu.COLUMNS]: 'Por magnitud física',
    },
    clear: 'Suprimir',
  },
  unit: unitNames,
  physicalQuantity: {
    [PhysicalQuantity.NONE]: 'ninguno',
    [PhysicalQuantity.OTHER]: 'otro',
    [PhysicalQuantity.PRESSURE]: 'presión',
    [PhysicalQuantity.ENERGY]: 'energía',
    [PhysicalQuantity.SPECIFIC_ENERGY]: 'densidad de energía',
    [PhysicalQuantity.POWER]: 'potencia',
    [PhysicalQuantity.TEMPERATURE]: 'temperatura',
    [PhysicalQuantity.SPEED]: 'velocidad',
    [PhysicalQuantity.ACCELERATION]: 'aceleración',
    [PhysicalQuantity.DENSITY]: 'densidad de masa',
    // [PhysicalQuantity.MASS_DENSITY]: '',
    [PhysicalQuantity.TIME]: 'tiempo',
    [PhysicalQuantity.MASS]: 'masa',
    [PhysicalQuantity.VOLUME]: 'volumen',
    [PhysicalQuantity.FLOW]: 'caudal',
    [PhysicalQuantity.LENGTH]: 'longitud',
    [PhysicalQuantity.SURFACE]: 'área',
    [PhysicalQuantity.FORCE]: 'fuerza',
    [PhysicalQuantity.DEW_POINT]: 'punto de rocío',
    [PhysicalQuantity.HUMIDITY]: 'humedad',
    [PhysicalQuantity.THERMAL_CONDUCTIVITY]: 'conductividad térmica',
    [PhysicalQuantity.ELECTRICAL_CONDUCTIVITY]: 'conductividad eléctrica',
    [PhysicalQuantity.ELECTRICAL_CONDUCTANCE]: 'conductancia eléctrica',
    [PhysicalQuantity.VOLTAGE]: 'tensión',
    [PhysicalQuantity.CURRENT]: 'intensidad',
    [PhysicalQuantity.FREQUENCY]: 'frecuencia',
    [PhysicalQuantity.CURRENCY]: 'moneda',
    [PhysicalQuantity.FILE_SIZE]: 'tamaño de archivo',
    [PhysicalQuantity.VISCOSITY]: 'viscosidad',
  } as Record<PhysicalQuantity, string>,
};
