import type { Point, PointOptionsType, SeriesPieOptions } from 'highcharts';
import PieSeries from 'highcharts/es-modules/Series/Pie/PieSeries';
import { v4 as uuid } from 'uuid';

import { UUID } from '@dametis/core';

import { DaChart } from './DaChart';

interface Props {
  name?: string;
  hidden?: boolean;
  pretty?: boolean;
}

export default class DaPieSeries extends PieSeries {
  declare chart: DaChart;

  public constructor(daChart: DaChart, { name = '', hidden = false, pretty = false }: Props = {}) {
    const options: SeriesPieOptions = {
      id: uuid(),
      name,
      type: 'pie',
      visible: !hidden,
      data: [],
      dataLabels: { enabled: !pretty },
      showInLegend: true,
      innerSize: pretty ? '65%' : '0%',
      borderWidth: pretty ? 0 : 1,
    };
    super();
    super.init(daChart, options);
  }

  updateData(point: Point): void {
    this.data.find(p => p.custom.id === point.custom.id).update(point);
  }

  addData(data: PointOptionsType[]): void {
    super.setData(data, false, false, false);
  }

  addPoint(options: PointOptionsType) {
    super.addPoint(options, false, false, false);
  }

  setUnit(unit: string, key: UUID): void {
    const ret = this.data.find(point => point.custom.id === key);
    ret.update({ custom: { ...ret.custom } }, true);
  }

  setColor(color: string, key: UUID): void {
    this.data.find(point => point.custom.id === key).update({ color });
  }

  togglePretty(pretty: boolean): void {
    this.update(
      {
        type: 'pie',
        dataLabels: { enabled: !pretty },
        showInLegend: !pretty,
        innerSize: pretty ? '65%' : '0%',
        borderWidth: pretty ? 0 : 1,
      },
      true,
    );
  }

  removeData(key: UUID): void {
    const deletePoint = this.data.find(point => point.custom.id === key);
    if (deletePoint !== undefined) deletePoint.remove(false, false);
  }
}
