import { SourceColumn } from '@dametis/core';

import { FormFixedVariable } from '../Home/CreateFileImportModal/MappingSettings/MappingSettingsContext';
import { SourceValue } from '../UI/SourceValuePicker/SourceValuePicker';

import { areSameSourceColumns } from './areSameSourceColumns';
import { isSourceColumnFixedVariable, isSourceColumnSkipped } from './isSourceColumnInList';

export const getColumnSourceValue = (
  column: SourceColumn | null,
  dateTimeSource: SourceColumn | null,
  referenceSource: SourceColumn | null,
  valueSource: SourceColumn | null,
  skippedColumns: (SourceColumn | null)[],
  fixedVariables: FormFixedVariable[],
): SourceValue => {
  if (areSameSourceColumns(dateTimeSource, column)) {
    return SourceValue.DATE_TIME;
  }
  if (areSameSourceColumns(referenceSource, column)) {
    return SourceValue.REFERENCE;
  }
  if (areSameSourceColumns(valueSource, column)) {
    return SourceValue.VALUE;
  }
  if (isSourceColumnSkipped(skippedColumns, column)) {
    return SourceValue.SKIPPED_COLUMN;
  }
  if (isSourceColumnFixedVariable(fixedVariables, column)) {
    return SourceValue.FIXED_VARIABLE;
  }
  return SourceValue.DEFAULT;
};
