import { NotifyType } from '@dametis/core';

import { NotifyItemType } from 'types/share';

export default {
  title: {
    notify_one: '¿Desea notificar a este usuario?',
    notify_other: '¿Desea notificar a estos usuarios?',
  },
  text: {
    [NotifyItemType.PLAYGROUND]: 'este playground.',
    [NotifyItemType.REPORT]: 'este informe.',
    [NotifyItemType.SYNOPTIC]: 'este sinóptico.',
    [NotifyItemType.PROJECT]: 'este proyecto.',
    [NotifyItemType.ALARM]: 'esta alarma.',
    [`${NotifyType.SHARINGS}_one`]: 'Este usuario será informado de que ahora tiene acceso a $t(notify:text.{{itemType}})',
    [`${NotifyType.SHARINGS}_other`]: 'Estos usuarios serán informados de que ahora tienen acceso a $t(notify:text.{{itemType}})',
    [`${NotifyType.ALARM_ACTION}_one`]: 'Este usuario será informado de que ahora recibirá las alertas de esta alarma.',
    [`${NotifyType.ALARM_ACTION}_other`]: 'Estos usuarios serán informados de que ahora recibirán las alertas de esta alarma.',
    [`${NotifyType.PROJECT_TASK_ASSIGNEE}`]: 'Este usuario será informado de que ha sido asignado a una tarea en este proyecto.',
    commentPlaceholder: 'Agregar un mensaje personalizado...',
  },
  input: {
    addComment: 'Agregar un mensaje',
    sendNotify: 'Enviar una notificación',
  },
  button: {
    skip: 'Continuar',
    send: 'Enviar',
  },
  toast: {
    success_one: 'El usuario ha sido notificado.',
    success_other: 'Los usuarios han sido notificados.',
    error: 'Se produjo un error al notificar a los usuarios.',
  },
};
