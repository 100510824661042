import { makeStyles } from '@mui/styles';

export const usePQStyles = makeStyles(() => ({
  avatar: {
    display: 'inline-flex',
    fontWeight: 600,
  },
  'avatar--small': {
    width: 18,
    height: 18,
    fontSize: '12px!important',
  },
  'avatar--medium': {
    width: 24,
    height: 24,
    fontSize: '14px!important',
  },
}));
