import i18next from 'i18next';

import {
  CacheVariableKind,
  CreateDeviceBody,
  CreateVariableListBody,
  DeviceInfo,
  IsRealVariableInfo,
  RawPoint,
  UUID,
  UpdateDeviceBody,
  UpdateVariableListBody,
  VariableInfo,
  VariableListInfo,
  VariableListKind,
} from '@dametis/core';

import {
  setBoxesStatuses,
  setBrandDevices,
  setCurrentExport,
  setIsFetchingLastPoints,
  setLastPoints,
  setMacros,
  setVariableLists,
} from 'store/slices/configuration';
import { setCurrentVariableList } from 'store/slices/manualEntry';

import { boxStatus } from '../../functions/socketIo';
import { sdk } from '../../sdk';
import { ToastSeverity } from '../../types';
import { TypedThunk } from '../index';
import { addToast } from '../slices/toast';

import { getCurrentBox, syncBox } from './currentBox';
import { fetchEquipment } from './fetchEquipment';
import { fetchVariables } from './fetchVariables';
import { displaySdkErrorToast } from './toasts';

export const fetchDevicesLastPoints =
  (groupId: string, devicesIds: UUID[]): TypedThunk<Promise<Record<UUID, RawPoint>>> =>
  async dispatch => {
    dispatch(setIsFetchingLastPoints(true));
    try {
      const { data } = await sdk.tada.Last(
        groupId,
        devicesIds.map(deviceId => ({ kind: CacheVariableKind.DEVICES, uuid: deviceId })),
      );
      return devicesIds.reduce<Record<UUID, RawPoint>>((result, deviceId, index) => {
        if (data[index]) {
          result[deviceId] = data[index];
        }
        return result;
      }, {});
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
      return {};
    } finally {
      dispatch(setIsFetchingLastPoints(false));
    }
  };

export const fetchStoreDevicesLastPoints = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const groupId = state.auth.selectedGroup?.uuid;
  const { devices } = state.boxes;
  if (!groupId) throw new Error();
  try {
    const fetchedAt = new Date();
    const devicesIds = devices.map(device => device.uuid);
    const data = await dispatch(fetchDevicesLastPoints(groupId, devicesIds));
    dispatch(setLastPoints({ data, fetchedAt }));
  } catch (err) {
    console.error(err);
  }
};

export const fetchAllVariableLists = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const siteId = getState().auth.selectedSite?.uuid;
  if (!siteId) {
    dispatch(
      addToast({
        severity: ToastSeverity.ERROR,
        message: i18next.t('toast:errorNoSite'),
      }),
    );
    return;
  }
  try {
    const { data } = await sdk.variableList.List(siteId);
    dispatch(setVariableLists(data));
  } catch (err) {
    console.error(err);
    dispatch(displaySdkErrorToast(err));
  }
};

export const fetchMacros = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const { user } = state.auth;
  const site = state.auth.selectedSite;
  const groupId = state.auth.selectedGroup?.uuid;
  if (!user || !groupId) throw new Error();
  if (!site) return;
  const canAccessMacro = user.acl.HasPermission('canAccessMacro', groupId, site);
  if (!canAccessMacro) return;

  try {
    const { data } = await sdk.macro.List(site.uuid, {});
    dispatch(setMacros(data));
  } catch (err) {
    console.error(err);
    dispatch(displaySdkErrorToast(err));
  }
};

export const createNewVariableList =
  (createListBody: CreateVariableListBody): TypedThunk<Promise<VariableListInfo | null>> =>
  async (dispatch, getState) => {
    const siteId = getState().auth.selectedSite?.uuid;
    if (!siteId) {
      return null;
    }
    try {
      const { data } = await sdk.variableList.Create(siteId, createListBody);
      if (data.kind === VariableListKind.IMPORT) {
        dispatch(setCurrentVariableList(data));
      } else if (data.kind === VariableListKind.EXPORT) {
        dispatch(setCurrentExport(data));
      }
      await dispatch(fetchAllVariableLists());
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successCreateVariableList'),
        }),
      );
      return data;
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
      return null;
    }
  };

export const editVariableList =
  (listUuid: string, updateListBody: UpdateVariableListBody): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      await sdk.variableList.Update(listUuid, updateListBody);
      await dispatch(fetchAllVariableLists());
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successEditVariableList'),
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const deleteVariableList =
  (listId: string): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      await sdk.variableList.Delete(listId);
      await dispatch(fetchAllVariableLists());
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successDeleteVariableList'),
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const deleteVariables =
  (variables: Pick<VariableInfo, 'uuid' | 'kind'>[]): TypedThunk<Promise<void>> =>
  async dispatch => {
    // eslint-disable-next-line @dametis/no-promise-all-map
    const results = await Promise.allSettled(variables.map(variable => sdk.variable.Delete(variable.uuid)));
    const fulfilleds = results.filter(result => result.status === 'fulfilled');
    const rejecteds = results.filter(result => result.status === 'rejected');
    if (fulfilleds.length)
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successDeleteVariable', { count: fulfilleds.length }),
        }),
      );
    if (rejecteds.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const dependants = rejecteds.filter(rejected => rejected.reason.response.status === 409);
      dispatch(
        addToast({
          severity: ToastSeverity.ERROR,
          message: dependants.length ? i18next.t('toast:errorVariableDependants', { count: dependants.length }) : i18next.t('toast:error'),
        }),
      );
    }
    await dispatch(fetchVariables());
    if (variables.some(variable => IsRealVariableInfo(variable))) void dispatch(syncBox());
  };

export const getBrandDevices = (): TypedThunk<Promise<void>> => async dispatch => {
  try {
    const { data } = await sdk.brandDevice.List();
    dispatch(setBrandDevices(data));
  } catch (err) {
    console.error(err);
    dispatch(displaySdkErrorToast(err));
  }
};

export const createNewDevice =
  (boxUuid: string, device: CreateDeviceBody): TypedThunk<Promise<DeviceInfo | undefined>> =>
  async dispatch => {
    try {
      const response = await sdk.device.Create(boxUuid, device);
      await dispatch(fetchEquipment());
      await dispatch(fetchVariables());
      await dispatch(getCurrentBox(boxUuid));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successCreateNewDevice'),
        }),
      );
      void dispatch(syncBox());

      return response.data;
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }

    return undefined;
  };

export const editDevice =
  (boxUuid: string, deviceUuid: string, device: UpdateDeviceBody): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      await sdk.device.Update(deviceUuid, device);
      await dispatch(fetchEquipment());
      await dispatch(getCurrentBox(boxUuid));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successEditDevice'),
        }),
      );
      void dispatch(syncBox());
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const deleteDevice =
  (boxUuid: string, deviceUuid: string): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      await sdk.device.Delete(deviceUuid);
      await dispatch(fetchEquipment());
      await dispatch(getCurrentBox(boxUuid));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successDeleteDevice'),
        }),
      );
      void dispatch(syncBox());
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const toggleDevice =
  (deviceUuid: string, enabled: boolean): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      await sdk.device.Update(deviceUuid, { enabled });
      await dispatch(fetchEquipment());
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t(enabled ? 'toast:successEnableDevice' : 'toast:successDisableDevice'),
        }),
      );

      void dispatch(syncBox());
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const getBoxesStatus = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const boxes = getState().boxes.list;
  try {
    // eslint-disable-next-line @dametis/no-promise-all-map
    const statuses = await Promise.allSettled(boxes.map(box => boxStatus(box.uuid)));
    dispatch(setBoxesStatuses(statuses.reduce((a, v, i) => ({ ...a, [boxes[i].uuid]: v.status === 'fulfilled' ? v.value : null }), {})));
  } catch (err) {
    console.error(err);
    dispatch(
      addToast({
        severity: ToastSeverity.WARNING,
        message: i18next.t('toast:errorBoxVersion'),
      }),
    );
  }
};
