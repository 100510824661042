import { FindFolder, FindFolderPath, FolderInfo } from '@dametis/core';

import { ExplorerFolder, ExplorerItem, ExplorerItemType, IsExplorerFolder, IsExplorerShortcut } from './types';

export const getExplorerFolderPath = (rootFolder: FolderInfo, endFolder: FolderInfo): ExplorerFolder[] => {
  try {
    const path = FindFolderPath(rootFolder, endFolder);
    return path.map(folder => ({ type: ExplorerItemType.FOLDER, content: folder }));
  } catch (err) {
    return [];
  }
};

export const getExplorerItemPath = (rootFolder: FolderInfo | null, item: ExplorerItem | null): ExplorerItem[] => {
  /**
   * Si le rootFolder est null, on renvoie un path vide
   */
  if (!rootFolder || !item) {
    return [];
  }

  /**
   * On définit le dernier dossier de l'arborescence
   */
  let endFolder: FolderInfo | undefined;
  /**
   * Si notre élément est un dossier, il est le dernier dossier de l'arborescence
   */
  if (IsExplorerFolder(item)) {
    endFolder = item.content;
  }
  /**
   * Si notre élément est un raccourci, on cherche son dossier parent
   */
  if (IsExplorerShortcut(item)) {
    endFolder = FindFolder(
      rootFolder,
      folder =>
        folder.shortcuts.find(shortcut => shortcut.category === item.content.category && shortcut.uuid === item.content.uuid) !== undefined,
    );
  }

  if (!endFolder) {
    return [];
  }

  /**
   * On récupère le chemin jusqu'au dernier dossier de l'arborescence
   */
  const explorerFolderPath = getExplorerFolderPath(rootFolder, endFolder);
  /**
   * Si notre élément est un dossier, on renvoie le chemin tel quel
   */
  if (IsExplorerFolder(item)) {
    return explorerFolderPath;
  }
  /**
   * Si notre élément est un raccourci, on l'ajoute au chemin récupéré jusqu'au dossier parent
   */
  if (IsExplorerShortcut(item)) {
    return [...explorerFolderPath, { type: ExplorerItemType.SHORTCUT, content: item.content }];
  }
  return [];
};
