import { styled } from '@mui/material';

const RightPanel = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
}));

export default RightPanel;
