import { StyleFormat, TextAlign } from '@dametis/core';

import { EntityType } from 'types/comment';

export default {
  placeholder: {
    leaveAComment: 'Escribir un comentario...',
    placeholderGeneral: 'Escribe algo...',
  },
  text: {
    fontSize: {
      small: 'Pequeño',
      medium: 'Medio',
      large: 'Grande',
      auto: 'Automático',
      mixed: 'Mixto',
    },
  },
  label: {
    [StyleFormat.BOLD]: 'Negrita',
    [StyleFormat.ITALIC]: 'Cursiva',
    [StyleFormat.UNDERLINE]: 'Subrayado',
    [TextAlign.LEFT]: 'Alineación a la izquierda',
    [TextAlign.CENTER]: 'Alineación al centro',
    [TextAlign.RIGHT]: 'Alineación a la derecha',
    entity: {
      [EntityType.ALARM]: 'alarma',
      [EntityType.PLAYGROUND]: 'parque infantil',
      [EntityType.REPORT]: 'informe',
      [EntityType.PROJECT]: 'proyecto',
      [EntityType.SYNOPTIC]: 'sinóptico',
      [EntityType.VARIABLE]: 'variable',
      [EntityType.TASK]: 'tarea',
    },
  },
  button: {
    submit: 'Validar',
    send: 'Enviar',
    cancel: 'Cancelar',
  },
  tooltip: {
    fontColor: 'Color del texto',
  },
};
