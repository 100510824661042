export default {
  text: {
    createdBy: 'Créé par {{name}}',
    updatedAt: 'Modifié {{date}}',
    me: 'moi',
    notFound: 'Élément introuvable',
  },
  tooltip: {
    notFound: "L'élément que vous cherchez n'existe pas, ou vous n'y avez pas accès",
  },
};
