export default {
  label: {
    default: 'Par défaut',
    live: 'En direct',
    muted: 'En sourdine',
    custom: 'Personnalisé',
    invalid: 'Invalide',
  },
  select: {
    default: 'Par défaut',
    live: 'En direct',
    muted: 'En sourdine',
    custom: 'Personnalisé',
  },
  text: {
    noValue: 'n/a',
  },
  tooltip: {
    default: 'Par défaut',
    live: 'En direct',
    muted: 'En sourdine (Ignoré)',
    custom: 'Personnalisé',
  },
};
