import { OrderBy } from '@dametis/core';

import { ChangesOption } from 'components/Monitoring/ipseite/SiteTile/AdminFilters/AdminFilters';
import { StateIconVariant } from 'components/UI/StateIcon/StateIcon';
import { DamcoSubPeriodType } from 'store/slices/monitoring';
import { DataPeriod, SortBy } from 'store/slices/oldMonitoring';

export default {
  title: {
    page: 'Monitoring',
    box: 'Box : {{name}}',
    boxes: 'Boxes',
    devices: 'Équipements',
    variables: 'Variables',
    detailsModal: '{{entities}} disponibles',
    editBoxAlertThresholds: "Modifier les seuils d'alerte de la box",
    editDeviceAlertThresholds: "Modifier les seuils d'alerte de l'équipement",
    editVariableAlertThresholds: "Modifier les seuils d'alerte de la variable",
  },
  label: {
    sites: 'Sites',
    groups: 'Groupes',
    boxes_one: 'Box',
    boxes_other: 'Boxes',
    devices: 'Équipements',
    variables: 'Variables',
    hideUnchangedSites: 'Cacher les sites sans changement',
    stateIconVariants: 'État',
    pending: 'en retard',
    error_one: 'erreur',
    error_other: 'erreurs',
    connected_one: 'connectée',
    connected_other: '{{count}} connectées',
    disconnected_one: 'déconnectée',
    disconnected_other: '{{count}} déconnectées',
    stateIconVariant: {
      [StateIconVariant.ERROR]: 'Changement vers inactif',
      [StateIconVariant.WARNING]: '',
      [StateIconVariant.SUCCESS]: 'Changement vers actif',
      [StateIconVariant.UNSET]: 'Sans changement',
    },
    changesOptions: 'Derniers changements',
    changesOption: {
      [ChangesOption.DEVICES_NO]: 'Aucun changements',
      [ChangesOption.DEVICES_MORE]: 'Gains',
      [ChangesOption.DEVICES_LESS]: 'Pertes',
      [ChangesOption.VARIABLES_NO]: 'Aucun changements',
      [ChangesOption.VARIABLES_MORE]: 'Gains',
      [ChangesOption.VARIABLES_LESS]: 'Pertes',
    },
    dataPeriods: {
      [DataPeriod.ONE_DAY]: '1 jour',
      [DataPeriod.THREE_DAYS]: '3 jours',
      [DataPeriod.ONE_WEEK]: '1 semaine',
      [DataPeriod.TWO_WEEKS]: '2 semaines',
      [DataPeriod.ONE_MONTH]: '1 mois',
    },
    orderBy: {
      [OrderBy.ASC]: 'Croissant',
      [OrderBy.DESC]: 'Décroissant',
    },
    sortBy: {
      [SortBy.GROUP_NAME]: 'Nom du groupe',
      [SortBy.SITE_NAME]: 'Nom du site',
      [SortBy.NB_DEVICES_CHANGES]: 'Nombre de derniers changements (équipements)',
      [SortBy.NB_VARIABLES_CHANGES]: 'Nombre de derniers changements (variables)',
      [SortBy.DATE_DEVICES_CHANGES]: 'Date des derniers changements (équipements)',
      [SortBy.DATE_VARIABLES_CHANGES]: 'Date des derniers changements (variables)',
    },
    deviceState: 'Equipement',
    deviceLagging: 'Déconnecté',
    deviceNotLagging: 'Connecté',
    deviceNoData: 'Aucune donnée',
    variableLag: 'Variables',
    variableLagging: 'En retard',
    variableDataHole: 'Disponibilité',
    noVariables: 'Aucune variable',
    lastValue: 'Dernière valeur',
  },
  text: {
    infrastructureState: "État de l'infrastructure",
    allSelected: 'Tout sélectionner',
    boxAvailability: 'Disponibilité de la box',
    changes_zero: 'Aucun changement',
    changes_one: '{{count}} changement',
    changes_other: '{{count}} changements',
    date: {
      from: 'du {{date}}',
      to: 'au {{date}}',
      complete: 'du {{from}} au {{to}}',
    },
    period: {
      forOneHour: 'depuis 1 heure',
      forFiveHours: 'depuis 5 heures',
      forTwelveHours: 'depuis 12 heures',
      forOneDay: 'depuis 1 jour',
      forTwoDays: 'depuis 2 jours',
      forOneWeek: 'depuis 1 semaine',
    },
    boxesDown: 'Boxes indisponibles',
    devicesDown: 'Équipements indisponibles',
    variablesDown: 'Variables indisponibles',
    noValue: 'n/a',
    noRecentChanges: 'Aucun changements récents.',
    variablesAvailability: {
      period: 'du {{from}} au {{to}}',
      value: '{{value}} variables disponibles',
      noData: 'Aucune donnée',
    },
    unknownSite: 'Site inconnu',
    nothingToDisplay: 'Aucun site à afficher. Essayez de changer les paramètres de vos filtres.',
    fetchingSitesData: 'Récupération des données des sites...',
    neverConnected: 'Jamais connectée',
    pending: 'En retard',
    upToDate: 'À jour',
    subPeriodType: {
      [DamcoSubPeriodType.HOLE]: 'Perte',
      [DamcoSubPeriodType.LAG]: 'Retard',
    },
    inProgress: 'En cours...',
    received: 'Reçu',
    alertThreshold: 'Seuil des alertes',
    noBox: "Il n'y a pas de box",
    noDevice: "Il n'y a pas d'équipement",
  },
  grid: {
    site: 'Site',
    group: 'Groupe',
    availability: 'Disponibilité de la box',
    period: 'Période des données',
  },
  tooltip: {
    handleNotification: 'Gérer les notifications',
    launchArmageddon: "Lancer l'armageddon",
    launchArmageddonOnDevice: "Lancer l'armageddon sur cet équipement",
    accessLog: 'Accéder aux logs',
    editBoxAlertThresholds: "Modifier les seuils d'alerte de la box",
    editDeviceAlertThresholds: "Modifier les seuils d'alerte de l'équipement",
    editVariableAlertThresholds: "Modifier les seuils d'alerte de la variable",
  },
  button: {
    cancel: 'Annuler',
    save: 'Enregistrer',
    add: 'Ajouter',
  },
  devicesGrid: {
    status: 'État',
    name: 'Nom',
    availability: 'Disponibilité',
    lastPointTime: 'Dernière valeur',
    lateVariables: 'En retard',
    defaultVariableDataDelay: 'Entre deux remontées',
    deviceHeartbeatFrequency: 'Entre deux exécutions',
    defaultVariableFrequency: 'Entre deux points',
    tooltip: {
      defaultVariableDataDelay: "Fréquence attendue entre deux remontées de données de l'équipement",
      deviceHeartbeatFrequency: "Fréquence attendue entre deux exécutions de l'équipement",
      defaultVariableFrequency: "Fréquence attendue entre deux points d'une variable (par défaut)",
    },
  },
  variablesGrid: {
    lastLagState: 'État',
    name: 'Nom',
    availability: 'Disponibilité',
    lastPointValue: 'Dernière valeur',
    lastPointDate: 'Date de la dernière valeur',
    delay: 'Délai',
    frequency: 'Fréquence',
    variableFrequency: 'Entre deux points',
  },
  detailPanelGrid: {
    type: 'Type',
    from: 'Début',
    to: 'Fin',
    duration: 'Durée',
  },
  prompt: {
    armageddon:
      "Le mode Armageddon supprime l'intégralité des données de monitoring ; utilisez cette fonctionnalité seulement si vous êtes bien sûr de ce que vous faites",
  },
  input: {
    boxDataMaxDelay: 'Fréquence attendue entre deux remontées de données de la box',
    defaultVariableDataDelay: "Fréquence attendue entre deux remontées de données de l'équipement",
    deviceHeartbeatFrequency: "Fréquence attendue entre deux exécutions de l'équipement",
    defaultVariableFrequency: "Fréquence attendue entre deux points d'une variable (par défaut)",
    variableFrequency: 'Fréquence attendue entre deux points',
  },
};
