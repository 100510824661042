import { makeStyles } from '@mui/styles';

const useStatusMenuStyles = makeStyles(theme => ({
  button__icon__normal: {
    color: theme.palette.success.light,
  },
  button__icon__warning: {
    color: theme.palette.warning.main,
  },
  button__icon__critical: {
    color: theme.palette.error.main,
  },
  popover: {
    width: '20rem',
  },
  popover__data: {
    display: 'grid',
    gridTemplateColumns: '3rem auto',
    gridTemplateAreas: '"icon number" "text"',
    padding: '1rem',
  },
  popover__icon: {
    marginRight: '0.5rem',
    marginLeft: 'auto',
  },
  popover__keyFigure: {
    marginRight: '0.5rem',
    textAlign: 'right',
  },
  popover__caption: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

export default useStatusMenuStyles;
