import { RawPoint, UUID } from '@dametis/core';

import { ChangesOption } from 'components/Monitoring/ipseite/SiteTile/AdminFilters/AdminFilters';

export interface SiteData {
  isFetchingDevices: boolean;
  numberOfDevices: number;
  isFetchingNumberOfDevicesUp: boolean;
  numberOfDevicesUp: number;
  numberOfDevicesUpData: RawPoint[];
  countDevicesLastUpdate: number;
  dateDevicesLastUpdate: Date;
  isFetchingVariables: boolean;
  numberOfVariables: number;
  isFetchingNumberOfVariablesUp: boolean;
  numberOfVariablesUp: number;
  numberOfVariablesUpData: RawPoint[];
  countVariablesLastUpdate: number;
  dateVariablesLastUpdate: Date;
}

export enum DataPeriod {
  ONE_DAY = 'oneDay',
  THREE_DAYS = 'threeDays',
  ONE_WEEK = 'oneWeek',
  TWO_WEEKS = 'twoWeeks',
  ONE_MONTH = 'oneMonth',
}

export interface Filters {
  groups: UUID[];
  changesOptions: ChangesOption[];
}

export enum SortBy {
  SITE_NAME = 'siteName',
  GROUP_NAME = 'groupName',
  NB_DEVICES_CHANGES = 'nbDevicesChanges',
  NB_VARIABLES_CHANGES = 'nbVariablesChanges',
  DATE_DEVICES_CHANGES = 'dateDevicesChanges',
  DATE_VARIABLES_CHANGES = 'dateVariablesChanges',
}

// export interface MonitoringState {
//   admin: {
//     sitesData: Record<UUID, Partial<SiteData>>;
//     fetching: Record<string, boolean>;
//     filters: Filters;
//     dataPeriod: DataPeriod;
//     sortBy: SortBy;
//     orderBy: OrderBy;
//   };
// }

// const initialState: MonitoringState = {
//   admin: {
//     sitesData: {},
//     fetching: {},
//     filters: {
//       groups: [],
//       changesOptions: [],
//     },
//     sortBy: SortBy.GROUP_NAME,
//     orderBy: OrderBy.ASC,
//     dataPeriod: DataPeriod.THREE_DAYS,
//   },
// };

// export const monitoringSlice = createSlice({
//   name: 'monitoring',
//   initialState,
//   reducers: {
//     setSiteData: <T extends SiteDataKey>(
//       state: Draft<MonitoringState>,
//       action: PayloadAction<{ siteId: UUID; key: T; value: SiteDataValue<T> }>,
//     ) => {
//       const { siteId, key, value } = action.payload;
//       if (!state.admin.sitesData[siteId]) {
//         state.admin.sitesData[siteId] = {};
//       }
//       state.admin.sitesData[siteId][key] = value;
//     },
//     setDataPeriod: (state, action: PayloadAction<DataPeriod>) => {
//       state.admin.dataPeriod = action.payload;
//     },
//     setSortBy: (state, action: PayloadAction<SortBy>) => {
//       state.admin.sortBy = action.payload;
//     },
//     setOrderBy: (state, action: PayloadAction<OrderBy>) => {
//       state.admin.orderBy = action.payload;
//     },
//     setAdminFilters: (state, action: PayloadAction<Filters>) => {
//       state.admin.filters = action.payload;
//     },
//     setFiltersGroups: (state, action: PayloadAction<UUID[]>) => {
//       state.admin.filters.groups = action.payload;
//     },
//     setFiltersChangesOptions: (state, action: PayloadAction<ChangesOption[]>) => {
//       state.admin.filters.changesOptions = action.payload;
//     },
//     clearMonitoringStore: () => initialState,
//   },
// });

// export const {
//   setSiteData,
//   setDataPeriod,
//   setSortBy,
//   setOrderBy,
//   setAdminFilters,
//   setFiltersGroups,
//   setFiltersChangesOptions,
//   clearMonitoringStore,
// } = monitoringSlice.actions;

// export default monitoringSlice.reducer;
