import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import { enUS, esES, frFR } from '@mui/material/locale';
import { enUS as dataGridEnUS, esES as dataGridEsES, frFR as dataGridFrFR } from '@mui/x-data-grid-premium/locales';
import { enUS as datePickerEnUS, esES as datePickerEsES, frFR as datePickerFrFR } from '@mui/x-date-pickers-pro/locales';
import { createBrowserHistory } from 'history';
import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import store from 'store';
import { theme as rawTheme } from 'theme';

import AxiosInterceptor from './AxiosInterceptor';

interface Props {
  children: ReactNode;
}

const history = createBrowserHistory({ window });

const Wrapper: FC<Props> = ({ children }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const muiTranslations = useMemo(() => {
    switch (language) {
      case 'fr':
        return [frFR, dataGridFrFR, datePickerFrFR] as const;
      case 'es':
        return [esES, dataGridEsES, datePickerEsES] as const;
      case 'en':
      default:
        return [enUS, dataGridEnUS, datePickerEnUS] as const;
    }
  }, [language]);

  const theme = useMemo(() => createTheme(rawTheme, ...muiTranslations), [muiTranslations]);

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <HistoryRouter history={history}>
            <AxiosInterceptor />
            {children}
          </HistoryRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default Wrapper;
