import { TagTab } from 'types/tags';

export default {
  title: {
    editTags: 'Tags',
    tags: 'Tags',
    tagsDetails: 'Detalles de los tags',
    column: {
      name: 'Nombre',
      dependencies: 'Dependencias',
    },
    entityType: {
      blocks: 'Bloques',
      playgrounds: 'Playgrounds',
      projects: 'Proyectos',
      reports: 'Informes',
      synoptics: 'Sinópticos',
      variables: 'Variables',
      opcos: 'Mezcla de vapores',
    },
    tab: {
      [TagTab.INFORMATIONS]: 'Información',
      [TagTab.DEPENDENCIES]: 'Dependencias',
    },
  },
  label: {
    dependencies: 'Dependencias',
  },
  input: {
    tags: 'Tags',
    tagName: 'Nombre del tag',
    targetTagName: 'Nombre del tag de destino',
  },
  button: {
    tag: 'Tag',
    editTags: 'Editar tags',
    close: 'Cerrar',
    cancel: 'Cancelar',
    save: 'Guardar',
    validate: 'Validar',
    create: 'Crear',
    merge: 'Fusionar',
    confirmDelete: 'Sí, eliminar',
    createTag: 'Crear un tag',
    editTag: 'Editar',
    mergeTags: 'Fusionar',
    deleteTag: 'Eliminar',
    deleteTag_one: 'Eliminar el tag',
    deleteTag_other: 'Eliminar los {{count}} tags',
  },
  tagAutocomplete: {
    newTag: 'Nueva tag',
    unknownTag: 'Tag desconocida',
    emptyTag: 'Ninguna tag seleccionada',
  },
  text: {
    noOptions: 'Sin opciones',
    deleteTagsAdvice:
      'Eliminar $t(text.thisTag, {"count": {{tagCount}} }) la eliminará de $t(text.entity, {"count": {{dependenciesCount}} }). Verifique las dependencias antes de eliminar.',
    mergeTagsAdvice:
      'Fusionar $t(text.thisTag, {"count": {{tagCount}} }) agregará $t(text.entity, {"count": {{dependenciesCount}} }) a la etiqueta de destino y eliminará las etiquetas de origen. Verifique las dependencias antes de fusionar.',
    thisTag_one: 'el tag',
    thisTag_other: 'los {{count}} tags',
    entity_one: '{{count}} entidad',
    entity_other: '{{count}} entidades',
    updatedAt: 'Modificado {{date}}',
  },
  error: {
    maxTagAllowed: 'Se ha alcanzado el número máximo de tags',
  },
  toast: {
    success: 'Nuevas tags guardadas.',
    error: 'Se produjo un error durante la guardado.',
    createTagSuccess: 'El tag se ha creado correctamente.',
    deleteTagSuccess_one: 'El tag se ha eliminado correctamente.',
    deleteTagSuccess_other: 'Los tags se han eliminado correctamente.',
    mergeTagsSuccess: 'Los tags se han fusionado correctamente.',
    mergeTagsItselfFailure: 'No se puede fusionar un tag solo consigo misma',
    updateTagSuccess: 'El tag se ha actualizado correctamente.',
  },
  modal: {
    cancelEdition: '¿Está seguro? Está a punto de cerrar el modo de edición.',
  },
};
