import { ListSubheader } from '@mui/material';
import { styled } from '@mui/styles';

const CatalogSubheader = styled(ListSubheader)(({ theme }) => ({
  margin: `${theme.spacing(0.5)} 0`,
  padding: theme.spacing(2),
  lineHeight: 1,
  background: theme.palette.background.default,
  position: 'sticky',
  top: theme.others.headerHeight,
  zIndex: 10,
  width: '100%',
}));

export default CatalogSubheader;
