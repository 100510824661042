import { PaletteColor, SimplePaletteColorOptions, alpha } from '@mui/material';
import { blue, green, orange, red } from '@mui/material/colors';
import createPalette from '@mui/material/styles/createPalette';

import { black, daBlue, daGreen, daRed, grey, white } from './colors';

export const palette = createPalette({
  black,
  white,
  grey,
  link: {
    light: blue[50],
    main: blue[400],
    dark: blue[600],
    contrastText: blue[600],
  },
  daBlue,
  primary: daBlue,
  daGreen,
  secondary: daGreen,
  daRed,
  success: {
    contrastText: white,
    dark: green[900],
    main: green[600],
    light: green[400],
  },
  info: {
    contrastText: white,
    dark: blue[900],
    main: blue[600],
    light: blue[400],
  },
  warning: {
    contrastText: white,
    dark: orange[900],
    main: orange[600],
    light: orange[400],
  },
  error: {
    contrastText: white,
    dark: red[900],
    main: red[600],
    light: red[400],
  },
  text: {
    primary: black,
    secondary: grey.A400,
  },
  background: {
    default: white,
    defaultTransparent: alpha(white, 0.65),
    paper: grey[50],
    paperTransparent: alpha('#f5f6f7', 0.65),
  },
  icon: {
    main: grey.A400,
  },
  divider: grey[100],
  gradients: {
    reds: ['#DE3C47', '#e45d66', '#ec9096', '#f5c2c5', '#fae3e5'],
    peachs: ['#f47051', '#f68e76', '#f9ad9b', '#fabcad', '#fcdad2'],
    pinks: ['#EC5FA9', '#f082bc', '#f4a5cf', '#f6b7d8', '#fbdaeb'],
    oranges: ['#F59E33', '#f8b96b', '#faca90', '#fbdcb6', '#fdeedb'],
    yellows: ['#F4D733', '#f6de58', '#f8e67d', '#fbf1b5', '#fdf8db'],
    greens: ['#2E9249', '#67cf63', '#8EC664', '#b8db9d', '#ecf5e5'],
    teals: ['#5AC1B4', '#68c6ba', '#a1dcd4', '#ccece8', '#e9f7f5'],
    cyans: ['#64CBEF', '#75d1f1', '#99ddf5', '#bde9f9', '#e1f5fc'],
    blues: ['#3C7DBE', '#669acf', '#93b8dd', '#c0d5eb', '#dde9f4'],
    charcoals: [black, '#777c7e', '#c0c3c4', '#F7F8F9', '#ffffff'],
    indigos: ['#344955', '#85a3b4', '#9db6c3', '#b6c8d2', '#cedae1'],
    greys: ['#D0D1D0', '#959795', '#818381', '#bcbebc', '#dadbda'],
    limes: ['#8EC664', '#84c156', '#98cb72', '#b8db9d', '#cde6ba'],
  },
  picker: {
    red: '#DE3C47',
    pink: '#EC5FA9',
    green: '#2E9249',
    indigo: '#344955',
    blue: '#3C7DBE',
    teal: '#5AC1B4',
    lime: '#8EC664',
    yellow: '#F4D733',
    orange: '#F59E33',
    cyan: '#64CBEF',
    peach: '#f47051',
    salmon: '#FFA17A',
    charcoal: '#585858',
    grey: '#D0D1D0',
  },
});

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    default: string;
    defaultTransparent: string;
    paper: string;
    paperTransparent: string;
  }

  interface Palette {
    black: string;
    white: string;
    icon: PaletteColor;
    link: PaletteColor;
    daBlue: typeof daBlue;
    daGreen: typeof daGreen;
    daRed: typeof daRed;
    gradients: {
      reds: string[];
      pinks: string[];
      greens: string[];
      blues: string[];
      teals: string[];
      yellows: string[];
      oranges: string[];
      cyans: string[];
      peachs: string[];
      charcoals: string[];
      indigos: string[];
      greys: string[];
      limes: string[];
    };
    picker: {
      red: string;
      pink: string;
      green: string;
      indigo: string;
      blue: string;
      teal: string;
      lime: string;
      yellow: string;
      orange: string;
      cyan: string;
      peach: string;
      salmon: string;
      charcoal: string;
      grey: string;
    };
  }
  interface PaletteOptions {
    black: string;
    white: string;
    icon: SimplePaletteColorOptions;
    link: SimplePaletteColorOptions;
    daBlue: typeof daBlue;
    daGreen: typeof daGreen;
    daRed: typeof daRed;
    gradients: {
      reds: string[];
      pinks: string[];
      greens: string[];
      blues: string[];
      teals: string[];
      yellows: string[];
      oranges: string[];
      cyans: string[];
      peachs: string[];
      charcoals: string[];
      indigos: string[];
      limes: string[];
      greys: string[];
    };
    picker: {
      red: string;
      pink: string;
      green: string;
      indigo: string;
      blue: string;
      teal: string;
      lime: string;
      yellow: string;
      orange: string;
      cyan: string;
      peach: string;
      salmon: string;
      charcoal: string;
      grey: string;
    };
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    icon: true;
  }
}
