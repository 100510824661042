import { addDays, addHours, addMinutes, addMonths, addSeconds, addWeeks, addYears } from 'date-fns';

const addUnit = (date: Date, value: number, unit: string) => {
  if (unit === 'second' || unit === 's') return addSeconds(date, value);
  if (unit === 'minute' || unit === 'm') return addMinutes(date, value);
  if (unit === 'hour' || unit === 'h') return addHours(date, value);
  if (unit === 'day' || unit === 'd') return addDays(date, value);
  if (unit === 'week' || unit === 'w') return addWeeks(date, value);
  if (unit === 'month' || unit === 'mo') return addMonths(date, value);
  if (unit === 'year' || unit === 'y') return addYears(date, value);
  return date;
};

export default addUnit;
