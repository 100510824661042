export default {
  strength: {
    name: 'Complejidad de la contraseña',
    empty: '',
    weak: 'Débil',
    medium: 'Medio',
    strong: 'Fuerte',
  },
  strengthReason: {
    userInputs: 'La contraseña parece contener información sobre su identidad',
    lowComplexity: 'La contraseña no es lo suficientemente compleja, intente con una combinación de letras, números y símbolos',
    empty: 'La contraseña está vacía',
  },
};
