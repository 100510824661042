import { chartColors } from '../../../functions/color';

export const getNextColor = (colors: string[]): string => {
  const usedColors: Record<string, number> = chartColors.reduce(
    (acc, chartColor) => ({ ...acc, [chartColor]: colors.filter(color => color === chartColor).length }),
    {},
  );
  let useLessColor: string;
  chartColors.forEach(color => {
    useLessColor = !useLessColor || usedColors[color] < usedColors[useLessColor] ? color : useLessColor;
  });
  return useLessColor;
};
