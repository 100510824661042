import { v4 as uuidv4 } from 'uuid';

import { Content, Rule, RuleOperator, Style, StyledRule } from '@dametis/core';

export const createStyledRuleStyle = ({
  formats = [],
  fontColor = null,
  fillColor = null,
  borderColor = null,
}: Partial<Style> = {}): Style => ({
  formats,
  fontColor,
  fillColor,
  borderColor,
});

export const createStyledRuleContent = ({ icon = null, text = '', concat = false }: Partial<Content> = {}): Content => ({
  icon,
  text,
  concat,
});

export const createStyledRuleRule = ({ value = null, operator = RuleOperator.EQUAL_TO }: Partial<Rule> = {}): Rule => ({
  operator,
  value,
});

export const createStyledRule = ({
  rule = createStyledRuleRule(),
  style = createStyledRuleStyle(),
  content = createStyledRuleContent(),
}: Partial<Omit<StyledRule, 'uuid'>> = {}): StyledRule => ({
  uuid: uuidv4(),
  rule,
  style,
  content,
});
