import { Box, InputLabel, Stack, Typography } from '@mui/material';
import { Dispatch, FC, MouseEventHandler, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderBy } from '@dametis/core';

import EntityAccordionSummary from 'components/UI/EntityAccordion/EntityAccordionSummary';

import { SortedBy } from '../FolderList';

import OrderAndSortMenu from './OrderAndSortMenu';

export interface FolderListToolbarProps {
  orderBy: OrderBy;
  setOrderBy: Dispatch<SetStateAction<OrderBy>>;
  sortedBy: SortedBy;
  setSortedBy: Dispatch<SetStateAction<SortedBy>>;
  // users: ShortUserInfo[];
  // selectedUsersUuid: UUID[];
  // setSelectedUsersUuid: Dispatch<SetStateAction<UUID[]>>;
  isOpen: boolean;
}

const FolderListToolbar: FC<FolderListToolbarProps> = ({
  orderBy,
  setOrderBy,
  sortedBy,
  setSortedBy,
  // selectedBlockTypesUuid,
  // setSelectedBlockTypesUuid,
  // users,
  // selectedUsersUuid,
  // setSelectedUsersUuid,
  isOpen,
}) => {
  const { t } = useTranslation('lego');

  const handleClickFilterContainer: MouseEventHandler<HTMLDivElement> = useCallback(event => {
    event.stopPropagation();
  }, []);

  return (
    <>
      <EntityAccordionSummary
        content={
          <Stack alignItems="center" direction="row" flexGrow={1} justifyContent="flex-start">
            {/* <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} onClick={handleClickFilterContainer}></Stack> */}
            <Box marginLeft="auto" onClick={handleClickFilterContainer}>
              <OrderAndSortMenu isOpen={isOpen} orderBy={orderBy} setOrderBy={setOrderBy} setSortedBy={setSortedBy} sortedBy={sortedBy} />
            </Box>
          </Stack>
        }
        contentMenu={
          <>
            <InputLabel sx={{ mb: 2 }}>{t('label.filtersAndSort')}</InputLabel>
            <Stack spacing={1}>
              <OrderAndSortMenu isOpen={isOpen} orderBy={orderBy} setOrderBy={setOrderBy} setSortedBy={setSortedBy} sortedBy={sortedBy} />
            </Stack>
          </>
        }
        title={<Typography variant="h4">{t('title.folders')}</Typography>}
      />
    </>
  );
};

export default FolderListToolbar;
