import { Box, Grid, Stack, useTheme } from '@mui/material';
import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { BlockInfo, BlockTypeInfo, FolderInfo, ShortStandardBlockInfo, ShortcutCategory } from '@dametis/core';

import getEntitiesById from 'functions/getEntitiesById';
import { useBlockTypes } from 'store/api/blockTypes';
import { useBlocks } from 'store/api/blocks';
import { useStandardBlocks } from 'store/api/standardBlocks';

import ExplorerBreadcrumbs from './ExplorerBreadcrumbs';
import ExplorerFolderList from './ExplorerFolderList';
import { getExplorerItemId } from './getExplorerItemId';
import { getExplorerItemPath } from './getExplorerItemPath';
import {
  AvailableShortcutCategory,
  DEFAULT_DISPLAYED_SHORTCUTS,
  EntitiesByCategory,
  ExplorerItem,
  ExplorerItemType,
  IsExplorerFolder,
} from './types';

const blockTypesEmptyArray: BlockTypeInfo[] = [];
const standardBlocksEmptyArray: ShortStandardBlockInfo[] = [];
const blocksEmptyArray: BlockInfo[] = [];

export interface FolderExplorerProps {
  rootFolder: FolderInfo;
  value?: ExplorerItem | null;
  onChange?: (newSelectedItem: ExplorerItem) => void;
  displayedShortcut?: AvailableShortcutCategory[];
  isItemDisabled?: (item: ExplorerItem) => boolean;
}

const FolderExplorer: FC<FolderExplorerProps> = ({
  rootFolder,
  value = null,
  onChange = undefined,
  displayedShortcut = DEFAULT_DISPLAYED_SHORTCUTS,
  isItemDisabled = undefined,
}) => {
  const theme = useTheme();

  const { data: blockTypes = blockTypesEmptyArray } = useBlockTypes();
  const { data: standardBlocks = standardBlocksEmptyArray } = useStandardBlocks();
  const { data: blocks = blocksEmptyArray } = useBlocks();

  const [selectedItem, setSelectedItem] = useState<ExplorerItem | null>(null);

  const explorerContainerRef = useRef<HTMLDivElement | null>(null);

  const selectedItemPath = useMemo(() => getExplorerItemPath(rootFolder, selectedItem), [rootFolder, selectedItem]);
  const selectedItemPathIds = useMemo(() => selectedItemPath.map(item => getExplorerItemId(item)), [selectedItemPath]);

  const blockTypesById = useMemo(() => getEntitiesById<BlockTypeInfo>(blockTypes), [blockTypes]);
  const standardBlocksById = useMemo(() => getEntitiesById<ShortStandardBlockInfo>(standardBlocks), [standardBlocks]);
  const blocksById = useMemo(() => getEntitiesById<BlockInfo>(blocks), [blocks]);

  const entitiesByCategory: EntitiesByCategory = useMemo(
    () => ({
      [ShortcutCategory.BLOCK_TYPE]: blockTypesById,
      [ShortcutCategory.STANDARD_BLOCK]: standardBlocksById,
      [ShortcutCategory.BLOCK]: blocksById,
    }),
    [blockTypesById, standardBlocksById, blocksById],
  );

  const parsedPath = useMemo(
    () => (selectedItemPath.length > 0 ? selectedItemPath : [{ type: ExplorerItemType.FOLDER, content: rootFolder }]),
    [selectedItemPath, rootFolder],
  );

  const handleSelectItem = useCallback(
    (newSelectedItem: ExplorerItem) => {
      setSelectedItem(newSelectedItem);
      if (onChange) {
        onChange(newSelectedItem);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (selectedItemPath.length && explorerContainerRef.current) {
      explorerContainerRef.current.scrollLeft += explorerContainerRef.current.clientWidth;
    }
  }, [selectedItemPath]);

  useEffect(() => {
    setSelectedItem(value ?? null);
  }, [value]);

  return (
    <Stack height="100%">
      <ExplorerBreadcrumbs entitiesByCategory={entitiesByCategory} path={parsedPath} onSelectItem={handleSelectItem} />
      <Box ref={explorerContainerRef} sx={{ overflowX: 'auto', flexGrow: 1, borderBottom: `1px solid ${theme.palette.divider}` }}>
        <Grid container alignItems="stretch" sx={{ minWidth: '100%', width: 'fit-content', height: '100%' }} wrap="nowrap">
          {parsedPath.map((item, index) => (
            <Fragment key={getExplorerItemId(item)}>
              {IsExplorerFolder(item) && (
                <ExplorerFolderList
                  displayedShortcut={displayedShortcut}
                  entitiesByCategory={entitiesByCategory}
                  isItemDisabled={isItemDisabled}
                  isLast={index + 1 === selectedItemPath.length}
                  item={item}
                  selectedItemPathIds={selectedItemPathIds}
                  onSelectItem={handleSelectItem}
                />
              )}
            </Fragment>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};

export default FolderExplorer;
