import {
  CreateStyleConfigurationBody,
  ShortStyleConfigurationInfo,
  StyleConfigurationInfo,
  UUID,
  UpdateStyleConfigurationBody,
} from '@dametis/core';

import { sdk } from '../../sdk';
import store, { RootState, useSelector } from '../index';

import { api } from './index';

export const styleConfigurationsApi = api.injectEndpoints({
  endpoints: build => ({
    readStyleConfigurations: build.query<ShortStyleConfigurationInfo[], { siteId?: string; groupId?: string } | void>({
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const groupId = (arg && arg.groupId) ?? state.auth.selectedGroup!.uuid;
        const siteId = (arg && arg.siteId) ?? state.auth.selectedSite?.uuid;
        try {
          const { data } = await (siteId ? sdk.styleConfiguration.List(siteId) : sdk.corporate.ListStyleConfigurations(groupId));
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ['StyleConfigurations'],
    }),
    createStyleConfiguration: build.mutation<StyleConfigurationInfo, CreateStyleConfigurationBody>({
      queryFn: async (body, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite!.uuid;
        try {
          const { data } = await sdk.styleConfiguration.Create(siteId, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ['StyleConfigurations'],
    }),
    updateStyleConfiguration: build.mutation<StyleConfigurationInfo, { uuid: UUID; body: UpdateStyleConfigurationBody }>({
      queryFn: async ({ uuid, body }) => {
        try {
          const { data } = await sdk.styleConfiguration.Update(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ['StyleConfigurations'],
    }),
    deleteStyleConfiguration: build.mutation<UUID, UUID>({
      queryFn: async uuid => {
        try {
          await sdk.styleConfiguration.Delete(uuid);
          return { data: uuid };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ['StyleConfigurations'],
    }),
  }),
});

export const { useCreateStyleConfigurationMutation, useUpdateStyleConfigurationMutation, useDeleteStyleConfigurationMutation } =
  styleConfigurationsApi;

export const selectStyleConfigurations: typeof styleConfigurationsApi.endpoints.readStyleConfigurations.select = arg =>
  styleConfigurationsApi.endpoints.readStyleConfigurations.select(
    arg ?? { groupId: store.getState().auth.selectedGroup?.uuid, siteId: store.getState().auth.selectedSite?.uuid },
  );

export const useStyleConfigurations: typeof styleConfigurationsApi.useReadStyleConfigurationsQuery = (arg, options) => {
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return styleConfigurationsApi.useReadStyleConfigurationsQuery(arg ?? { groupId, siteId }, { skip: !groupId && !siteId, ...options });
};
