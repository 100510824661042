import {
  AUTO_GROUPBY,
  AnyGroupBy,
  IsTimeGroupBy,
  IsVarCalc,
  Operator,
  POINT_TO_POINT_GROUPBY,
  isAllowedGroupByOperator,
  isAllowedPointToPointOperator,
} from '@dametis/core';

import { exhaustiveCheck } from '../../types';

export const getDefaultOperatorForGroupBy = (groupBy: AnyGroupBy, oldOperator: Operator | undefined): Operator | undefined => {
  if (groupBy === undefined || groupBy === null) {
    return Operator.HISTORY;
  }
  if (groupBy === POINT_TO_POINT_GROUPBY) {
    return isAllowedPointToPointOperator(oldOperator) ? oldOperator : Operator.DELTA;
  }
  if (groupBy === AUTO_GROUPBY) {
    return isAllowedGroupByOperator(oldOperator) ? oldOperator : Operator.MEAN;
  }
  if (IsTimeGroupBy(groupBy)) {
    return isAllowedGroupByOperator(oldOperator) ? oldOperator : Operator.MEAN;
  }
  if (IsVarCalc(groupBy)) {
    return oldOperator ?? Operator.MEAN;
  }
  exhaustiveCheck(groupBy);
  return oldOperator;
};

export const filterAllowedOperators = (operators: Operator[], groupBy: AnyGroupBy | undefined): Operator[] => {
  return operators.filter(operator => {
    if (groupBy === undefined || groupBy === null) {
      return operator === undefined || operator === null || operator === Operator.HISTORY;
    }
    if (groupBy === POINT_TO_POINT_GROUPBY) {
      return isAllowedPointToPointOperator(operator);
    }
    if (groupBy === AUTO_GROUPBY) {
      return isAllowedGroupByOperator(operator);
    }
    if (IsTimeGroupBy(groupBy)) {
      return isAllowedGroupByOperator(operator);
    }
    if (IsVarCalc(groupBy)) {
      return true;
    }
    exhaustiveCheck(groupBy);
    return false;
  });
};
