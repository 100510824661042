export default {
  title: {
    period: 'Période',
  },
  input: {
    label: {
      name: 'Nom',
      months: 'Mois',
      weeklySchedule: 'Semainier',
    },
    value: {
      noPeriods: 'Aucune période créée',
      always: 'Tout le temps (par défaut)',
    },
  },
  button: {
    cancel: 'Annuler',
    save: 'Enregistrer',
  },
  list: {
    item: {
      addPeriod: 'Créer une nouvelle période',
      openPeriodsPage: 'Aller à la page Périodes',
    },
  },
  text: {
    everyMonth: 'Peu importe le mois',
    allDayEveryday: "Peu importe le jour ou l'heure",
  },
  alert: {
    mismatchedTimeZonesTitle: 'Vos fuseaux horaires diffèrent !',
    mismatchedTimeZones:
      'Vous êtes en « {{currTZ}} » alors que le site est en « {{siteTZ}} ». Sachez que les horaires ci-dessous sont en « {{currTZ}} ».',
  },
  tooltip: {
    addPeriod: 'ajouter une  période',
    openPeriodsPage: 'ouvrir la page des périodes',
  },
};
