import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormatItem from './FormatItem';
import { useFormatPickerContext } from './FormatPickerContext';

export interface FormatPopoverFooterProps {
  isEmptyValue: boolean;
}

const FormatPopoverFooter: FC<FormatPopoverFooterProps> = ({ isEmptyValue }) => {
  const { t } = useTranslation('format');

  const { pickerValue } = useFormatPickerContext();
  // const { setOpen, pickerValue } = useFormatPickerContext();

  // const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
  //   if (!isEmptyValue) {
  //     addFormatHistoryItem(pickerValue);
  //   }
  //   setOpen(false);
  // }, [setOpen, pickerValue, isEmptyValue]);

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="flex-start"
      p={1}
      sx={{ borderTop: theme => `1px solid ${theme.palette.divider}` }}
    >
      {!isEmptyValue && (
        <>
          <Box
            px={1.5}
            py={1}
            sx={{ backgroundColor: theme => theme.palette.background.paper, borderRadius: theme => `${theme.shape.borderRadius}px` }}
          >
            <FormatItem format={pickerValue} />
          </Box>
        </>
      )}
      {isEmptyValue && <Typography>{t('text.noFormatting')}</Typography>}
      {/* <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={handleSubmit}
        sx={{ ml: 'auto' }}
        // disabled={areSameFormats(pickerValue, value)}
      >
        {t('button.ok')}
      </Button> */}
    </Stack>
  );
};

export default FormatPopoverFooter;
