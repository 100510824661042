import { BoxStatusLabel } from '../../types/box';

export default {
  title: {
    box: 'Mes modules',
    config: 'Ma configuration',
  },
  text: {
    updatedAt: 'Modifié {{date}}',
    noBox: "Vous n'avez pas encore de box.",
    lastWeek: 'Cette semaine',
    lastMonth: 'Ce mois',
    connected: 'Connectée',
    disconnected: 'Pendant {{duration}}',
    disconnectedInternetDetails: "Le module n'a pu se connecter à Internet pendant {{duration}}.",
    disconnectedPowerDetails: 'Le module a été débranché pendant {{duration}}, entraînant une perte de données.',
    numberVariables: 'Nombre de variables',
    day: 'j',
    internet: 'Connexion internet',
    power: 'Alimentation électrique',
    pastConnection_default: 'Chargement...',
    pastConnection_normal: 'Connectée sans interruption',
    pastConnection_critical: 'Déconnectée au moins 1 fois',
  },
  chip: {
    connection_default: 'Chargement...',
    [`connection_${BoxStatusLabel.NORMAL}`]: 'Connectée',
    [`connection_${BoxStatusLabel.WARNING}`]: 'Erreur',
    [`connection_${BoxStatusLabel.CRITICAL}`]: 'Déconnectée',
  },
  error: {
    unknown: 'Inconnue',
  },
};
