import { Theme } from '@mui/material';
import { TreeViewComponents } from '@mui/x-tree-view/themeAugmentation';

export default {
  styleOverrides: {
    content: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      marginBottom: 2,
      paddingTop: 2,
      paddingBottom: 2,
    }),
    label: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
    }),
  },
} as TreeViewComponents<Theme>['MuiTreeItem'];
