import { AddPhotoAlternateOutlined, ArrowForwardIosOutlined, HideImageOutlined } from '@mui/icons-material';
import { Box, BoxProps, Button, CircularProgress, IconButton, Input, Paper, Stack, Tooltip, Typography, alpha } from '@mui/material';
import { ChangeEventHandler, FC, KeyboardEventHandler, MutableRefObject, SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SiteInfo } from '@dametis/core';

import { SitePaper, SitePreview, SitePreviewActions, SitePreviewContainer, StyledSiteButton } from './styled';

interface Props {
  site: SiteInfo;
  onSelect: (event: SyntheticEvent) => void;
  active?: boolean;
  selected?: boolean;
  canEdit?: boolean;
  onChangePreview?: ChangeEventHandler<HTMLInputElement>;
  onDeletePreview?: () => Promise<void>;
  loadingPreview?: string;
  tabIndex?: number;
  refIndex?: MutableRefObject<HTMLButtonElement>;
  onKeyDown?: BoxProps['onKeyDown'];
}

const SiteTile: FC<Props> = ({
  site,
  canEdit = false,
  onSelect,
  onChangePreview = undefined,
  onDeletePreview = undefined,
  loadingPreview = undefined,
  active = false,
  selected = false,
  tabIndex = 0,
  refIndex = null,
  onKeyDown = undefined,
}) => {
  const { t } = useTranslation('corporate');

  const handleEnterKey = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    event => {
      onKeyDown?.(event);
      if (event.key === 'Enter') {
        onSelect(event);
      }
    },
    [onKeyDown, onSelect],
  );

  return (
    <Box onKeyDown={handleEnterKey}>
      <SitePaper active={active} onClick={!canEdit ? onSelect : undefined}>
        {(site.previewPath || onChangePreview) && (
          <SitePreviewContainer alignItems="center" justifyContent="center">
            {loadingPreview ? (
              <>
                <SitePreview blurred src={loadingPreview} />
                <CircularProgress color="primary" variant="indeterminate" />
              </>
            ) : (
              <>
                {canEdit && (
                  <Input
                    id={`site-${site.uuid}-picture-input`}
                    inputProps={{ accept: 'image/png,image/jpeg' }}
                    sx={{ display: 'none' }}
                    type="file"
                    onChange={onChangePreview}
                  />
                )}
                {canEdit && !site.previewPath && (
                  <Button component="label" htmlFor={`site-${site.uuid}-picture-input`} sx={{ width: 1, height: 1 }}>
                    <AddPhotoAlternateOutlined />
                  </Button>
                )}
                {site.previewPath && (
                  <>
                    <SitePreview blurred={false} src={site.previewPath} />
                    {canEdit && (
                      <SitePreviewActions alignItems="flex-start" direction="row-reverse">
                        <Tooltip title={t('tooltip.deletePhoto')}>
                          <IconButton size="small" onClick={onDeletePreview}>
                            <HideImageOutlined fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t('tooltip.editPhoto')}>
                          <IconButton component="label" htmlFor={`site-${site.uuid}-picture-input`} size="small">
                            <AddPhotoAlternateOutlined fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </SitePreviewActions>
                    )}
                  </>
                )}
              </>
            )}
          </SitePreviewContainer>
        )}
        <Paper
          elevation={0}
          sx={{
            position: 'relative',
            backgroundColor: ({ palette }) => alpha(palette.background.paper, 1),
            ...((site.previewPath || onChangePreview) && {
              borderTopLeftRadius: '0!important',
              borderTopRightRadius: '0!important',
            }),
          }}
        >
          <StyledSiteButton
            ref={refIndex}
            selected={selected}
            sx={{
              ...((site.previewPath || onChangePreview) && {
                borderTopLeftRadius: '0!important',
                borderTopRightRadius: '0!important',
              }),
            }}
            tabIndex={tabIndex}
            onClick={canEdit ? onSelect : undefined}
          >
            <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={1} sx={{ width: 1 }}>
              <Stack sx={{ width: `calc(100% - 24px)` }}>
                <Typography noWrap variant="h4">
                  {site.name}
                </Typography>
                <Typography noWrap variant="subtitle2">
                  {site.location?.city}
                  {site.location?.city.length && site.location?.region.length ? ', ' : ''}
                  {site.location?.region}
                </Typography>
                <Stack alignItems="center" direction="row" spacing={1}>
                  {site.location?.countryCode && site.location?.countryCode.length > 0 && (
                    <Box
                      alt={site.location?.country}
                      component="img"
                      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${site.location?.countryCode}.svg`}
                      sx={{ height: 14, border: theme => `1px solid ${theme.palette.divider}`, borderRadius: '4px' }}
                    />
                  )}
                  <Typography variant="subtitle2">{site.location?.country}</Typography>
                </Stack>
              </Stack>
              <ArrowForwardIosOutlined sx={{ color: 'link.main' }} />
            </Stack>
          </StyledSiteButton>
        </Paper>
      </SitePaper>
    </Box>
  );
};

export default SiteTile;
