import { endOfDay, startOfDay, subYears } from 'date-fns';

import { AllProjectSavingsEnergies, Energies } from '@dametis/core';

import { ProjectStatsByEnergy, ProjectStatsTotal } from 'store/slices/project';

export const getStatsByEnergies = <T extends { carbon: number; financial: number; energy: number; targetEnergies: Energies[] }>(
  data: T[],
) => {
  const response: ProjectStatsByEnergy = {};
  const usedEnergies = AllProjectSavingsEnergies.filter(energy =>
    data.some(currentData => currentData.targetEnergies.length === 1 && currentData.targetEnergies[0] === energy),
  );

  usedEnergies.forEach(energy => {
    response[energy] = data
      .filter(currentData => currentData.targetEnergies.length === 1 && currentData.targetEnergies[0] === energy)
      .reduce(
        (acc, current) => ({
          financial: acc.financial + parseFloat(`${current.financial}`),
          carbon: acc.carbon + parseFloat(`${current.carbon}`),
          energy: acc.energy + parseFloat(`${current.energy}`),
        }),
        {
          financial: 0,
          carbon: 0,
          energy: 0,
        },
      );
  });
  return response;
};

export const getStatsByEnergy = <T extends { carbon: number; financial: number; energy: number; targetEnergy: Energies }>(data: T[]) => {
  const response: ProjectStatsByEnergy = {};
  const usedEnergies = AllProjectSavingsEnergies.filter(energy => data.some(currentData => currentData.targetEnergy === energy));

  usedEnergies.forEach(energy => {
    response[energy] = data
      .filter(currentData => currentData.targetEnergy === energy)
      .reduce(
        (acc, current) => ({
          financial: acc.financial + parseFloat(`${current.financial}`),
          carbon: acc.carbon + parseFloat(`${current.carbon}`),
          energy: acc.energy + parseFloat(`${current.energy}`),
        }),
        {
          financial: 0,
          carbon: 0,
          energy: 0,
        },
      );
  });
  return response;
};

export const getDataByYear = <T extends { carbon: number; financial: number; energy: number }>(
  dateRange: { from: Date; to: Date },
  data: T[],
): T[] => {
  const referenceTimeRange = dateRange.to.getTime() - dateRange.from.getTime();
  const yearTimeRange = endOfDay(new Date()).getTime() - startOfDay(subYears(new Date(), 1)).getTime(); // 1 year

  return data.map(currentData => ({
    ...currentData,
    financial: (parseFloat(`${currentData.financial}`) * yearTimeRange) / referenceTimeRange,
    energy: (parseFloat(`${currentData.energy}`) * yearTimeRange) / referenceTimeRange,
    carbon: (parseFloat(`${currentData.carbon}`) * yearTimeRange) / referenceTimeRange,
  }));
};

export const getTotal = <T extends { carbon: number; financial: number }>(data: T[]): ProjectStatsTotal =>
  data.reduce(
    (acc, currentValue) => ({
      carbon: acc.carbon + parseFloat(`${Number.isFinite(currentValue.carbon) ? currentValue.carbon : 0}`),
      financial: acc.financial + parseFloat(`${currentValue.financial}`),
    }),
    { carbon: 0, financial: 0 },
  );

export const getSavingPercentage = (
  savingsByEnergyByYear: ProjectStatsByEnergy,
  consumptionByEnergyByYear: ProjectStatsByEnergy,
): ProjectStatsByEnergy => {
  const usedEnergies = AllProjectSavingsEnergies.filter(
    energy => Object.keys(savingsByEnergyByYear).includes(energy) && Object.keys(consumptionByEnergyByYear).includes(energy),
  );
  const response: ProjectStatsByEnergy = {};

  return usedEnergies.reduce((accu, current) => {
    if (savingsByEnergyByYear[current] && consumptionByEnergyByYear[current]) {
      accu[current] = {
        energy: (savingsByEnergyByYear[current].energy / consumptionByEnergyByYear[current].energy) * 100,
        carbon: (savingsByEnergyByYear[current].carbon / consumptionByEnergyByYear[current].carbon) * 100,
        financial: (savingsByEnergyByYear[current].financial / consumptionByEnergyByYear[current].financial) * 100,
      };
    }
    return accu;
  }, response);
};

export const getTotalSavingPercentage = (
  totalSavings: ProjectStatsTotal,
  totalConsumptionByYear: ProjectStatsTotal,
): ProjectStatsTotal => ({
  financial: (totalSavings.financial / totalConsumptionByYear.financial) * 100,
  carbon: (totalSavings.carbon / totalConsumptionByYear.carbon) * 100,
});
