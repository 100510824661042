import { styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

const SpaceTopBar = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

const Header: FC<PropsWithChildren> = ({ children = undefined }) => (
  <>
    {children}
    <SpaceTopBar />
  </>
);
export default Header;
