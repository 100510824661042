import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTypeInfo, FindFolder, FolderInfo, Shortcut, ShortcutCategory } from '@dametis/core';

import FolderExplorer from 'components/Lego/UI/FolderExplorer/FolderExplorer';
import { ExplorerItem, IsExplorerFolder } from 'components/Lego/UI/FolderExplorer/types';
import { useDispatch } from 'store';
import { useMoveBlockTypeMutation } from 'store/api/blockTypes';
import { useReadGlobalRootFolderQuery, useUpdateGlobalFolderMutation } from 'store/api/globalFolders';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

export interface MoveToBlockTypeModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  blockType: BlockTypeInfo;
}

const MoveToBlockTypeModal: FC<MoveToBlockTypeModalProps> = ({ isOpen, setIsOpen, blockType }) => {
  const { t } = useTranslation('lego');
  const dispatch = useDispatch();

  const { data: globalRootFolder = null } = useReadGlobalRootFolderQuery();
  const [moveBlockType, { isLoading: isMovingBlockType }] = useMoveBlockTypeMutation();
  const [updateGlobalFolder, { isLoading: isUpdatingFolder }] = useUpdateGlobalFolderMutation();

  const [targetFolder, setTargetFolder] = useState<FolderInfo | null>(null);

  const isLoading = useMemo(() => isMovingBlockType || isUpdatingFolder, [isMovingBlockType, isUpdatingFolder]);

  const parsedGlobalRootFolder: FolderInfo | null = useMemo(
    () => (globalRootFolder ? { ...globalRootFolder, name: t('lego:text.factory') } : null),
    [globalRootFolder, t],
  );

  const sourceFolder = useMemo(
    () =>
      globalRootFolder
        ? FindFolder(
            globalRootFolder,
            folder =>
              folder.shortcuts.find(shortcut => shortcut.category === ShortcutCategory.BLOCK_TYPE && shortcut.uuid === blockType?.uuid) !==
              undefined,
          )
        : undefined,
    [globalRootFolder, blockType],
  );

  const isValidTarget = useMemo(
    () => targetFolder !== null && targetFolder.uuid !== sourceFolder?.uuid,
    [sourceFolder?.uuid, targetFolder],
  );

  const isItemDisabled = useCallback((item: ExplorerItem) => !IsExplorerFolder(item), []);

  const handleSelectFolder = useCallback((newTarget: ExplorerItem) => {
    if (IsExplorerFolder(newTarget)) {
      setTargetFolder(newTarget.content);
    }
  }, []);

  const handleMoveTo = useCallback(async () => {
    if (!targetFolder) {
      return;
    }
    try {
      if (sourceFolder) {
        await moveBlockType({ blockTypeId: blockType.uuid, sourceId: sourceFolder?.uuid, targetId: targetFolder.uuid }).unwrap();
      } else {
        const newShortcuts: Shortcut[] = [...targetFolder.shortcuts, { category: ShortcutCategory.BLOCK_TYPE, uuid: blockType.uuid }];
        await updateGlobalFolder({ uuid: targetFolder.uuid, body: { shortcuts: newShortcuts } });
      }
      setIsOpen(false);
      dispatch(addToast({ message: t('toast.moveBlockTypeSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, moveBlockType, updateGlobalFolder, setIsOpen, sourceFolder, t, targetFolder, blockType?.uuid]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <DialogTitle>{t('title.moveTo')}</DialogTitle>
      <DialogContent sx={{ pl: 0, pr: 0, pb: 0 }}>
        {parsedGlobalRootFolder && (
          <FolderExplorer
            displayedShortcut={[ShortcutCategory.BLOCK_TYPE]}
            isItemDisabled={isItemDisabled}
            rootFolder={parsedGlobalRootFolder}
            onChange={handleSelectFolder}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={isLoading} variant="text" onClick={handleClose}>
          {t('button.cancel')}
        </Button>
        <LoadingButton
          color="secondary"
          disabled={isLoading || !isValidTarget}
          loading={isLoading}
          variant="contained"
          onClick={handleMoveTo}
        >
          {t('button.move')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default MoveToBlockTypeModal;
