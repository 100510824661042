import { Components, Theme } from '@mui/material';

declare module '@mui/material/Autocomplete' {
  interface AutocompletePropsSizeOverrides {
    xs: true;
  }
}

export default {
  defaultProps: {
    componentsProps: {
      paper: {
        elevation: 8,
      },
    },
  },
  styleOverrides: {
    groupLabel: ({ theme }) => ({
      backgroundColor: theme.palette.background.default,
      margin: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      width: '100%',
    }),
  },
  variants: [
    {
      props: { size: 'xs' },
    },
  ],
} as Components<Theme>['MuiAutocomplete'];
