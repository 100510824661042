import {
  Accordion,
  AccordionDetails,
  List,
  ListProps,
  ListSubheader,
  ListSubheaderProps,
  Stack,
  StackProps,
  collapseClasses,
  styled,
} from '@mui/material';

export const FilterAccordion = styled(Accordion, {
  shouldForwardProp: propName => propName !== 'filterAccordionsNumber' && propName !== 'loadingFilters',
})<{
  filterAccordionsNumber: number | undefined;
  loadingFilters?: boolean;
}>(({ theme, filterAccordionsNumber, loadingFilters }) => ({
  maxHeight: `calc(100% - ${(filterAccordionsNumber ?? 1) - 1} * ${theme.spacing(6)})`,
  background: 'none',
  margin: '0 !important',
  [`& .${collapseClasses.root}`]: { maxHeight: `calc(100% - ${theme.spacing(8)})`, overflowY: loadingFilters ? 'hidden' : 'auto' },
}));

export const FilterAccordionSummaryStack = styled((props: StackProps) => (
  <Stack alignItems="center" direction="row" justifyContent="space-between" {...props} />
))(({ theme }) => ({ width: '100%', paddingRight: theme.spacing(0.5) }));

export const FilterListSubheader = styled((props: ListSubheaderProps) => <ListSubheader disableGutters disableSticky {...props} />)(() => ({
  lineHeight: 1,
  background: 'none !important',
  padding: 0,
}));

export const FilterAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 0,
}));

export const FilterList = styled((props: ListProps) => <List dense {...props} />)(({ theme }) => ({
  padding: theme.spacing(0.25, 1),
}));
