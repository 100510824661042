import { Components, Theme } from '@mui/material';

export default {
  defaultProps: {
    disableInteractive: true,
    enterDelay: 200,
  },
  styleOverrides: {
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette.grey.A700,
      lineHeight: 1.25,
    }),
    arrow: ({ theme }) => ({
      color: theme.palette.grey.A700,
    }),
    tooltipPlacementTop: {
      margin: '8px 0 !important',
    },
    tooltipPlacementBottom: {
      margin: '8px 0 !important',
    },
    tooltipPlacementLeft: {
      margin: '0 8px !important',
    },
    tooltipPlacementRight: {
      margin: '0 8px !important',
    },
  },
} as Components<Theme>['MuiTooltip'];
