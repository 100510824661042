import {
  Alert,
  AlertTitle,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
  PopoverProps,
  Stack,
  capitalize,
} from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AUTO_GROUPBY, Operator, POINT_TO_POINT_GROUPBY, VarCalc } from '@dametis/core';

import { useVariableName } from 'hooks/useVariableProp';
import { Mode } from 'localization/en/vnc';
import { useLocalizedGroupBy } from 'localization/useLocalizedGroupBy';
import { UserHelperName } from 'types/userHelpers';

import Helper from '../../../../UI/Helper/Helper';
import { PropsContext } from '../../../context';

import Advanced from './Advanced';
import GroupBySelect from './GroupBySelect';
import OperatorsList from './OperatorsList';
import VariableDetails from './VariableDetails';

export interface VariableMenuProps extends PopoverProps {
  variable: Partial<VarCalc>;
  onVariableChange: (variable: Partial<VarCalc>) => void;
  global?: boolean;
}

const VariableMenu: FC<VariableMenuProps> = ({ variable, onVariableChange, global = false, ...props }) => {
  const { t } = useTranslation('vnc');

  const { output } = useContext(PropsContext);

  const variableName = useVariableName(variable);

  const operatorHelper = useMemo(() => {
    let operatorMode: Mode;
    switch (variable.groupBy) {
      case POINT_TO_POINT_GROUPBY:
        operatorMode = 'PTP';
        break;
      case AUTO_GROUPBY:
        operatorMode = output === 'singleValue' ? 'GLOBAL' : 'GROUP_BY';
        break;
      case null:
      case undefined:
        operatorMode = 'GLOBAL';
        break;
      default:
        operatorMode = 'GROUP_BY';
    }

    return t(`operators.operatorDescription.OP_${variable.operator ?? Operator.HISTORY}_${operatorMode}`);
  }, [variable.groupBy, variable.operator, output, t]);

  const { onClose } = props;

  const close = useCallback(() => {
    onClose?.({}, 'escapeKeyDown');
  }, [onClose]);

  const groupByText = useLocalizedGroupBy(variable.groupBy, false, true);

  return (
    <Popover {...props}>
      <Stack sx={{ height: 1, overflow: 'hidden !hidden' }}>
        <DialogTitle sx={{ pb: 0 }}>
          {t(variableName.length ? 'title.variableMenu' : 'title.calculationMenu', { name: variableName })}
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', alignItems: 'stretch', direction: 'row', overflow: 'hidden', px: 0 }}>
          {/* Nasty stuff done with paddings is because the checkbox ripple in GBInput needs to overflow */}
          <Stack spacing={2} sx={{ overflow: 'hidden', width: '400px', pl: 3, pt: 2, pr: 4 }}>
            <GroupBySelect variable={variable} onVariableChange={onVariableChange} />
            <Helper name={UserHelperName.VNC_VARIABLE_MENU_OPERATOR}>{t(`helper.${UserHelperName.VNC_VARIABLE_MENU_OPERATOR}`)}</Helper>
            <OperatorsList global={global} variable={variable} onVariableChange={onVariableChange} />
            <Alert severity="success">
              <AlertTitle>
                {t('operators.title', {
                  operator: capitalize(t('global:operator.op', { context: variable.operator ?? Operator.HISTORY })),
                  groupBy: groupByText,
                })}
              </AlertTitle>
              {operatorHelper}
            </Alert>
          </Stack>
          <Stack spacing={2} sx={{ width: '300px', pr: 3, pt: 2 }}>
            <Advanced global={global} variable={variable} onVariableChange={onVariableChange} />
            {!global && <VariableDetails variable={variable} />}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>{t('button.closeVariableMenu')}</Button>
        </DialogActions>
      </Stack>
    </Popover>
  );
};

export default VariableMenu;
