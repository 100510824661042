import { Paper, Stack } from '@mui/material';
import { FC } from 'react';

import { StandardBlockMetadata } from '@dametis/core';

import MetadataHeader from '../../BlockType/BlockTypeMetadataPreview/MetadataHeader';

import StandardBlockMetadataValue from './StandardBlockMetadataValue';

export interface StandardBlockMetadataPreviewProps {
  metadata: StandardBlockMetadata;
}

const StandardBlockMetadataPreview: FC<StandardBlockMetadataPreviewProps> = ({ metadata }) => (
  <Paper sx={{ p: 1, background: theme => theme.palette.background.default }}>
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <MetadataHeader name={metadata.name} type={metadata.type} />
      <StandardBlockMetadataValue metadata={metadata} sx={{ background: theme => theme.palette.background.paper }} />
    </Stack>
  </Paper>
);

export default StandardBlockMetadataPreview;
