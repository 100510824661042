import { Folder, MoreVertOutlined } from '@mui/icons-material';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FolderInfo } from '@dametis/core';

import { ACTIONS_CLASSNAME } from 'components/Lego/LegoCatalog/CatalogContent/ContentItemContainer';
import BlockTileContainer from 'components/Lego/UI/Block/BlockTile/BlockTileContainer';
import { getFolderColor } from 'components/Lego/helpers/getFolderColor';
import getHighlightedText from 'functions/getHighlightedText';

import FolderTileMenu from './FolderTileMenu';

export interface FolderTileProps {
  folder: FolderInfo;
  search: string;
}

const FolderTile: FC<FolderTileProps> = ({ folder, search }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [folderMenuAnchorEl, setFolderMenuAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isFolderMenuOpen = useMemo(() => Boolean(folderMenuAnchorEl), [folderMenuAnchorEl]);

  const handleClick = useCallback(() => {
    navigate(`/lego/${folder.uuid}`);
  }, [navigate, folder.uuid]);

  const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setFolderMenuAnchorEl(event.currentTarget);
  }, []);

  const handleMouseDownMenu: MouseEventHandler = useCallback(event => {
    event.stopPropagation();
  }, []);

  const handleCloseMenu = useCallback(() => {
    setFolderMenuAnchorEl(null);
  }, []);

  return (
    <>
      <BlockTileContainer
        sx={{
          [`& .${ACTIONS_CLASSNAME}`]: { opacity: 0 },
          [`&:hover .${ACTIONS_CLASSNAME}`]: { opacity: 1 },
        }}
        onClick={handleClick}
      >
        <Stack alignItems="center" direction="row" spacing={1}>
          <Folder fontSize="small" sx={{ color: getFolderColor(folder, theme) }} />
          <Typography variant="h6">{getHighlightedText(folder.name, search)}</Typography>
          <IconButton
            className={ACTIONS_CLASSNAME}
            size="small"
            sx={{ ml: 'auto' }}
            onClick={handleOpenMenu}
            onMouseDown={handleMouseDownMenu}
          >
            <MoreVertOutlined fontSize="small" />
          </IconButton>
        </Stack>
      </BlockTileContainer>
      <FolderTileMenu anchorEl={folderMenuAnchorEl} folder={folder} isOpen={isFolderMenuOpen} onClose={handleCloseMenu} />
    </>
  );
};

export default FolderTile;
