import { MacroFieldType } from '@dametis/core';

export default {
  title: {
    newMacro: 'New macro',
    macroSelector: 'Macros',
    macro: 'Macro',
  },
  input: {
    label: {
      name: 'Name',
      description: 'Description',
      fields: 'Fields',
      packages: 'Packages',
      script: 'Script',
      result: 'Result',
      logs: 'Logs',
      tableFields: 'Fields',
      colLabel: 'Label',
      type: 'Type',
      unit: 'Unit',
      pivot: 'Pivot',
      message: 'Message',
      macroResults: 'Results',
    },
  },
  button: {
    createMacro: 'Create',
    editMacro: 'Edit',
    saveMacro: 'Save',
    executeMacro: 'Execute',
    cantExecuteMacro: 'Save the macro to execute it',
    cancel: 'Cancel',
    noChanges: 'No changes',
    deleteMacro: 'Delete',
    export: 'Export to CSV',
    close: 'Close',
    selectMacro: 'Select a macro',
    macroMode: 'Macro mode',
    runMacro: 'Run',
    duplicateMacro: 'Duplicate',
    cantDuplicateMacro: 'Save the macro to duplicate it',
    forceDeploy: 'Deploy',
    reset: 'Reset Cache',
    addLib: 'Libraries',
    confirm: 'Confirm',
  },
  tooltip: {
    editMacro: 'edit macro',
    status_READY: 'Ready',
    status_FAILED: 'Failed',
    status_RUNNING: 'Running',
    status_UNKNOWN: '',
  },
  toast: {
    createMacroSuccess: 'Macro created successfully.',
    createMacroWarning: 'Macro created but will not work.',
    createMacroError: 'An error occurred while creating the macro.',
    saveMacroSuccess: 'Changes saved.',
    saveMacroWarning: 'Changes saved but contains errors.',
    saveMacroError: 'An error occurred while saving this macro changes.',
    deleteMacroSuccess: 'Macro deleted successfully.',
    deleteMacroError: 'An error occurred while deleting the macro.',
    duplicateMacroSuccess: 'Macro duplicated successfully.',
    duplicateMacroError: 'An error occurred while duplicating the macro.',
    executeMacro: 'Executing macro',
    deployMacroSuccess: 'Macro deployed successfully',
    deployMacroWarning: 'Macro deployed but contains errors.',
    deployMacroError: 'An error occurred while deploying the macro.',
  },
  text: {
    copy: 'Copy',
    total: 'TOTAL',
  },
  tabs: {
    jsonMacroResult: 'JSON',
    tableMacroResult: 'TABLE',
  },
  placeholder: {
    emptyDescription: 'No description',
    noMacros: 'No macro added yet',
    noMacro: 'No macro selected yet',
    selectMacroEdit: 'Select a macro',
    noMacroLibs: 'No library added yet',
  },
  type: {
    [MacroFieldType.BOOLEAN]: 'Boolean',
    [MacroFieldType.DATE]: 'Date',
    [MacroFieldType.DURATION]: 'Duration',
    [MacroFieldType.STRING]: 'Text',
    [MacroFieldType.NUMBER]: 'Number',
  },
  dialog: {
    title: {
      addLib: 'Libraries',
    },
  },
};
