import { Check, Close, PriorityHigh } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { FC, useMemo } from 'react';

import { OperationStatus } from '@dametis/core';

interface Props extends SvgIconProps {
  status: OperationStatus;
}

const OperationStatusIcon: FC<Props> = ({ status, ...props }) => {
  const icon = useMemo(() => {
    switch (status) {
      case OperationStatus.SUCCESS:
        return <Check {...props} />;
      case OperationStatus.CANCELLED:
        return <Close {...props} />;
      case OperationStatus.WARNING:
        return <PriorityHigh {...props} />;
      case OperationStatus.ERROR:
        return <Close {...props} />;
      default:
        return null;
    }
  }, [status, props]);
  return icon;
};

export default OperationStatusIcon;
