import { List, Typography } from '@mui/material';
import { useLiveQuery } from 'dexie-react-hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'store';

import HistoryListItem from './HistoryListItem';
import { db } from './db';

const HistoryList: FC = () => {
  const { t } = useTranslation('vnc');

  const groupId = useSelector(state => state.auth.selectedGroup!.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid ?? 'null');

  const history =
    useLiveQuery(
      () =>
        db.history
          .orderBy('createdAt')
          .limit(50)
          .reverse()
          .filter(({ site, group }) => site === siteId && group === groupId)
          .toArray(),
      [groupId, siteId],
    ) ?? [];

  if (!history.length)
    return (
      <Typography align="center" sx={{ mt: 4, px: 6 }} variant="subtitle2">
        {t('subtitle.noHistory')}
      </Typography>
    );
  return (
    <List sx={{ overflowY: 'auto' }}>
      {history.map((item, index) => (
        <HistoryListItem key={item.id} isLast={index === history.length - 1} item={item} />
      ))}
    </List>
  );
};

export default HistoryList;
