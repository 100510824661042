import { GridLocaleText } from '@mui/x-data-grid-premium';

export default {
  button: {
    acknowledge: 'Acquitamiento',
    advancedSearch: 'Búsqueda avanzada',
    addAlarm: 'Batch',
    deleteAlarm: 'Vaciar esta alarma',
    editAlarm: 'Editar esta alarma',
    deleteAlarmConfirmation_yes: 'Sí, vaciar',
    deleteAlarmConfirmation_cancel: 'Cancelar',
    muteAlarm: 'Desactivar esta alarma',
    unmuteAlarm: 'Reactivar esta alarma',
  },
  title: {
    alarms: 'Alarmas',
    // activeAlarms: 'Alarmas activas',
    // alarmsHistory: 'Historial',
    batchesList: 'Lista de batchs',
  },
  subtitle: {
    updatedAt: 'Modificado {{date}}',
  },
  text: {
    when: 'Cuándo',
    do: 'Hacer',
    deleteAlarmConfirmation: '¿Confirma la eliminación de esta alarma?',
    events: 'Historial de eventos',
    for: 'durante',
    day: 'j',
    noResults: 'Sin resultados.',
    noActiveAlarms: 'Sin alarmas activas',
    noHistoryIncidents: 'Sin incidentes',
    noBatches: 'Sin batch',
  },
  condition: {
    'equation_>': '{{variable}} es mayor que {{value}}',
    'equation_>=': '{{variable}} es mayor o igual que {{value}}',
    'equation_<': '{{variable}} es menor que {{value}}',
    'equation_<=': '{{variable}} es menor o igual que {{value}}',
    'equation_==': '{{variable}} es igual a {{value}}',
    'equation_!=': '{{variable}} es diferente de {{value}}',
    forXUnitOfTime: 'durante {{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
  },
  action: {
    sendEmail: 'Enviar un correo electrónico a {{email}}',
    sendText: 'Enviar un mensaje al {{phone}}',
    sendEmail_notSet: '$t(action.sendNotification)',
    sendNotification: 'Notificar en la aplicación',
  },
  snackbar: {
    message: {
      acknowledge: {
        success: 'La alerta ha sido marcada como vista correctamente',
      },
    },
  },
  table: {
    cells: {
      state: 'Estado',
      name: 'Nombre',
      criticality: 'Criticidad',
      extremeValue: 'Extrema',
      triggerDate: 'Desencadenada el',
      activeDuration: 'Activa durante',
      actions: 'Acción',
      acknowledgementDate: 'Acquitada el',
      acknowledgementActor: 'Acquitada por',
      resolveDate: 'Resolución el',
      date: 'Fecha',
      event: 'Evento',
      comment: 'Comentario',
      res: 'Acquitada el ... por',
    },
    rows: {
      acknowledgedOn: '{{date, dd/MM/yyyy HH:mm:ss}}',
      acknowledgedBy: '{{name}}',
      event_resolved: 'Resolución',
      event_triggered: 'Desencadenada',
      event_notify: 'Notificación enviada',
      event_ack: 'Acquitada',
      event_ackBy: 'Acquitada por {{name}}',
    },
  },
  criticality: {
    text: 'Criticidad',
    variant_notification: 'Notificación',
    variant_warning: 'Atención',
    variant_critical: 'Crítico',
  },
  filters: {
    variablesFilter: 'Variables',
    superBatchFilter: 'Super batch',
  },
  input: {
    timeRange: {
      label: 'Fecha',
      value: {
        lastXUnitOfTime: 'Desde {{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
        value_all: 'Todas',
      },
    },
    criticality: {
      label: 'Criticidad',
      value: {
        variant: '$t(criticality.variant)',
      },
    },
    alarms: {
      label: 'Nombre',
    },
    variable: {
      label: 'Variable',
      error: {
        empty: 'Requerido',
      },
    },
    name: {
      label: 'Nombre de la alarma',
      error: {
        empty: 'Requerido',
        over60characters: 'Demasiado largo',
      },
    },
    operand: {
      error: {
        empty: 'Requerido',
      },
    },
    notificationDelay: {
      error: {
        empty: 'Requerido',
        notPositive: 'No puede ser negativo',
      },
    },
    email: {
      placeholder: 'Enviar un correo electrónico a',
      error: {
        invalid: 'Inválido',
      },
    },
    phone: {
      placeholder: 'Enviar un mensaje al',
      error: {
        invalid: 'Inválido',
      },
    },
    operator: {
      label: 'Operador',
    },
    chip: {
      backgroundColor: 'rojo',
    },
  },
  xgrid: {
    noBatchesLabel: 'Sin batch',
    footerTotalAlarms: 'Total de alarmas:',
    footerPaginationAlarmsPerPage: 'Alarmas por página:',
    noIncidentsLabel: 'Sin incidentes',
    footerTotalIncidents: 'Total de incidentes:',
    footerPaginationIncidentsPerPage: 'Incidentes por página:',
  } as Partial<GridLocaleText>,
  tooltip: {
    createBatch: 'crear un batch',
  },
};
