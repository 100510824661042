export default {
  title: {
    beforeLeaving: '¿Estás seguro?',
    beforeDeleting: '¿Estás seguro?',
    beforeRegen: '¿Estás seguro?',
  },
  text: {
    beforeLeaving: 'Tiene cambios no guardados.',
  },
  button: {
    yesLeave: 'Salir sin guardar',
    noLeave: 'Cancelar',
    yesDelete: 'Sí, vaciar',
    noRegen: 'Cancelar',
    yesRegen: 'Sí, regenerar',
    noDelete: 'Cancelar',
    yesDisable: 'Sí, desactivar',
    noDisable: 'Cancelar',
  },
};
