import { RuleOperator } from '@dametis/core';

export default {
  tooltip: {
    deleteRule: 'Delete the rule',
    concat: 'Concatenate replacement text when several rules are satisfied',
    concatShort: 'Concatenate replacement text',
    resetStyledRuleStyle: 'Reset style',
    duplicateStyledRule: 'Duplicate rule',
    deleteStyledRule: 'Delete rule',
    fontColor: 'Font color',
    borderColor: 'Border color',
    fillColor: 'Background color',
    delete: 'Delete',
  },
  title: {
    styleConfigurationModal: 'Rules lists',
  },
  text: {
    emptySelect: 'Rule undefined.',
    value: 'Value',
    valueWithUnit: 'Value ({{unit}})',
    operator: 'Operator',
    altText: 'Alt text',
    when: 'When...',
    do: 'Apply...',
    noRule: 'No rule',
    rulesLength_zero: 'No rule',
    rulesLength_one: '1 rule',
    rulesLength_other: '{{count}} rules',
    otherRulesLength_one: '+{{count}} rule',
    otherRulesLength_other: '+{{count}} rules',
    withContent: 'content on',
    withoutContent: 'content off',
    withStyle: 'style on',
    withoutStyle: 'style off',
    noStyleConfiguration: 'No rules list saved on this site',
    orderDescription: 'Styled rules priority order is descendant. You can organize it with drag and drop.',
    shortOrderDescription: 'Styled rules priority order is descendant.',
    noOperatorSymbol: '∅',
  },
  rule: {
    [RuleOperator.EQUAL_TO]: {
      label: 'equal to',
      label_withValue: 'equal to {{value}}',
    },
    [RuleOperator.NOT_EQUAL_TO]: {
      label: 'not equal to',
      label_withValue: 'not equal to {{value}}',
    },
    [RuleOperator.GREATER_THAN]: {
      label: 'greather than',
      label_withValue: 'greather than {{value}}',
    },
    [RuleOperator.GREATER_THAN_OR_EQUAL_TO]: {
      label: 'greather than or equal to',
      label_withValue: 'greather than or equal to {{value}}',
    },
    [RuleOperator.LESS_THAN]: {
      label: 'less than',
      label_withValue: 'less than {{value}}',
    },
    [RuleOperator.LESS_THAN_OR_EQUAL_TO]: {
      label: 'less than or equal to',
      label_withValue: 'less than or equal to {{value}}',
    },
    [RuleOperator.BIT_IS_SET]: {
      label: 'bit is set at position',
      label_withValue: 'bit at position {{value}} is set',
    },
    [RuleOperator.BIT_IS_UNSET]: {
      label: 'bit is unset at position',
      label_withValue: 'bit at position {{value}} is unset',
    },
  },
  button: {
    close: 'Close',
    previous: 'Previous',
    next: 'Next',
    submit: 'Submit',
    cancel: 'Cancel',
    downloadFileTemplate: '{{type}} Template',
  },
  stepper: {
    creation: 'Creation',
    selection: 'Selection',
    settings: 'Settings',
  },
  label: {
    withStyle: 'Use style (italic, bold, underlined, colors)',
    withContent: 'Use content (text, icon)',
  },
};
