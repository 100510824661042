import { DeleteOutlineOutlined, EditOutlined, PlayCircleOutlined, SaveOutlined } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { ButtonProps } from '@mui/material';
import { ElementType, MouseEvent, MouseEventHandler, forwardRef, useCallback, useState } from 'react';

import PromptBeforeDeleting, { PromptBeforeDeletingProps } from '../PromptBeforeDeleting/PromptBeforeDeleting';

export type HeaderPrimaryRightButtonProps<C extends ElementType = ElementType> = LoadingButtonProps<C, { component?: C }> & {
  variant?: 'text' | 'contained';
  special?: 'modify' | 'delete' | 'execute';
  active?: boolean;
  color?: ButtonProps['color'];
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const HeaderPrimaryRightButton = forwardRef<HTMLButtonElement, HeaderPrimaryRightButtonProps<ElementType>>(
  ({ special = undefined, variant = undefined, active = false, children, color = 'primary', onClick = undefined, ...props }, ref) => {
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

    const handleDelete: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
      setDeleteDialog(true);
    }, []);

    const handleConfirmDelete = useCallback<PromptBeforeDeletingProps['onDelete']>(
      arg => {
        onClick?.(arg as MouseEvent<HTMLButtonElement>);
      },
      [onClick],
    );

    return (
      <>
        <LoadingButton
          color={(special !== undefined && special === 'delete' && 'error') || (active === true && 'secondary') || color}
          size="small"
          startIcon={
            active ? (
              <SaveOutlined />
            ) : (
              {
                modify: <EditOutlined />,
                delete: <DeleteOutlineOutlined />,
                execute: <PlayCircleOutlined />,
                noIcon: undefined,
              }[special ?? 'noIcon']
            )
          }
          sx={{ ml: 1, flexShrink: 0, flexWrap: 'nowrap', whiteSpace: 'nowrap' }}
          variant={variant || (special === 'modify' && 'contained') || undefined}
          onClick={special === 'delete' ? handleDelete : onClick}
          {...props}
          ref={ref}
        >
          {children}
        </LoadingButton>
        {special === 'delete' && onClick !== undefined && (
          <PromptBeforeDeleting open={deleteDialog} setOpen={setDeleteDialog} onDelete={handleConfirmDelete} />
        )}
      </>
    );
  },
);

HeaderPrimaryRightButton.defaultProps = {
  variant: undefined,
  special: undefined,
  active: false,
  color: 'primary',
  onClick: undefined,
};

HeaderPrimaryRightButton.displayName = 'PageTitleButton';

export default HeaderPrimaryRightButton;
