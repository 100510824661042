import { FC } from 'react';

import { VariableResult } from '../../../types';

import ListItemVariable from './ListItemVariable';

interface Props {
  item: VariableResult;
  index: number;
  dataLength: number;
}

const ListItem: FC<Props> = ({ item, index, dataLength }) => <ListItemVariable dataLength={dataLength} index={index} item={item} />;

ListItem.displayName = 'ListItem';

export default ListItem;
