import { Dialog, Drawer, DrawerProps, dialogClasses, drawerClasses, styled } from '@mui/material';

export const StyledDialog = styled(Dialog)(() => ({
  [`& .${dialogClasses.paper}`]: {
    height: '100%',
  },
}));

export const StyledDrawer = styled((props: DrawerProps) => <Drawer variant="permanent" {...props} />)(({ theme, anchor }) => ({
  [`& .${drawerClasses.paper}`]: {
    position: 'initial',
    width: theme.spacing(28),
    border: 'none',
    borderRadius: anchor === 'right' ? '10px 0 0 10px' : '0 10px 10px 0',
    overflow: 'hidden',
  },
}));
