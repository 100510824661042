import { GridRowId } from '@mui/x-data-grid-premium';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ListVariableInfo, PhysicalQuantity, RawPoint, TinyVariableSearchInfo, UUID } from '@dametis/core';

import { ALL_DEVICES, AllVariableKind, VariableFlags, VariablesGridColumn, VariablesGridFilters } from 'types/variables';

export interface VariablesLastPoints {
  fetchedAt: Date | null;
  data: Record<UUID, RawPoint | null>;
  isFetching: boolean;
}

export interface VariablesLastProgresses {
  fetchedAt: Date | null;
  data: Record<UUID, number | null>;
  isFetching: boolean;
}

export interface VariablesState {
  byId: Record<string, ListVariableInfo>;
  availableVariableFlags: VariableFlags;
  isFetchingVariables: boolean;
  createdVariable: ListVariableInfo | null;
  promptBeforeClosing: {
    shouldBeDisplayed: boolean;
    isOpen: boolean;
    rowIds: GridRowId[] | null;
  };
  available: {
    count: number;
    total: number;
  };
  filters: VariablesGridFilters;
  selectedVariables: UUID[];
  search: {
    value: string;
    results: TinyVariableSearchInfo[];
    isFetching: boolean;
  };
  lastPoints: VariablesLastPoints;
  lastProgresses: VariablesLastProgresses;
  import: {
    // | string is for IMPORT_COLUMN_NOT_SELECTED
    gridHeaderValues: Record<string, VariablesGridColumn | string>;
  };
}

const initialState: VariablesState = {
  byId: {},
  availableVariableFlags: {
    physicalQuantities: [],
    kinds: [],
    sites: [],
  },
  isFetchingVariables: false,
  createdVariable: null,
  promptBeforeClosing: {
    shouldBeDisplayed: false,
    isOpen: false,
    rowIds: null,
  },
  available: {
    count: 0,
    total: 0,
  },
  filters: {
    kinds: [],
    device: ALL_DEVICES,
    physicalQuantities: [],
    tags: [],
  },
  selectedVariables: [],
  search: {
    value: '',
    results: [],
    isFetching: false,
  },
  lastPoints: { isFetching: false, data: {}, fetchedAt: null },
  lastProgresses: { isFetching: false, data: {}, fetchedAt: null },
  import: {
    gridHeaderValues: {},
  },
};

export const variablesSlice = createSlice({
  name: 'variables',
  initialState,
  reducers: {
    setVariablesById: (state, action: PayloadAction<Record<string, ListVariableInfo>>) => {
      state.byId = action.payload;
    },
    setVariableById: (state, action: PayloadAction<ListVariableInfo>) => {
      state.byId[action.payload.uuid] = action.payload;
    },
    addVariablesById: (state, action: PayloadAction<Record<string, ListVariableInfo>>) => {
      state.byId = { ...state.byId, ...action.payload };
    },
    deleteVariableById: (state, action: PayloadAction<UUID>) => {
      delete state.byId[action.payload];
    },
    setAvailableVariableFlags: (state, action: PayloadAction<VariableFlags>) => {
      state.availableVariableFlags = action.payload;
    },
    setCreatedVariable: (state, action: PayloadAction<ListVariableInfo | null>) => {
      state.createdVariable = action.payload;
    },
    setPromptBeforeClosingShouldBeDisplayed: (state, action: PayloadAction<boolean>) => {
      state.promptBeforeClosing.shouldBeDisplayed = action.payload;
    },
    setPromptBeforeClosingIsOpen: (state, action: PayloadAction<boolean>) => {
      state.promptBeforeClosing.isOpen = action.payload;
    },
    setPromptBeforeClosingRowIds: (state, action: PayloadAction<GridRowId[] | null>) => {
      state.promptBeforeClosing.rowIds = action.payload;
    },
    setIsFetchingVariables: (state, action: PayloadAction<boolean>) => {
      state.isFetchingVariables = action.payload;
    },
    setAvailableVariables: (state, action: PayloadAction<{ count: number; total: number }>) => {
      state.available = action.payload;
    },
    setFilters: (state, action: PayloadAction<VariablesGridFilters>) => {
      state.filters = action.payload;
    },
    setFilterKinds: (state, action: PayloadAction<AllVariableKind[]>) => {
      state.filters.kinds = action.payload;
    },
    setFilterDevice: (state, action: PayloadAction<UUID | typeof ALL_DEVICES>) => {
      state.filters.device = action.payload;
    },
    setFilterPhysicalQuantities: (state, action: PayloadAction<PhysicalQuantity[]>) => {
      state.filters.physicalQuantities = action.payload;
    },
    setFilterTags: (state, action: PayloadAction<UUID[]>) => {
      state.filters.tags = action.payload;
    },
    setSelectedVariables: (state, action: PayloadAction<UUID[]>) => {
      state.selectedVariables = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.search.value = action.payload;
    },
    setSearchResults: (state, action: PayloadAction<TinyVariableSearchInfo[]>) => {
      state.search.results = action.payload;
    },
    setSearchIsFetching: (state, action: PayloadAction<boolean>) => {
      state.search.isFetching = action.payload;
    },
    setLastPoints: (state, action: PayloadAction<{ data: Record<UUID, RawPoint | null>; fetchedAt: Date }>) => {
      state.lastPoints.data = action.payload.data;
      state.lastPoints.fetchedAt = action.payload.fetchedAt;
    },
    // SLE: unused till fetchedAt is not linked to variable but to all last points, see with JCE/JBA/NDU if interesting to not always fetch all
    // setLastPoint: (state, action: PayloadAction<{ variableId: UUID; point: RawPoint }>) => {
    //   state.lastPoints.data[action.payload.variableId] = action.payload.point;
    // },
    setIsFetchingLastPoints: (state, action: PayloadAction<boolean>) => {
      state.lastPoints.isFetching = action.payload;
    },
    setLastProgresses: (state, action: PayloadAction<{ data: Record<UUID, number | null>; fetchedAt: Date }>) => {
      state.lastProgresses.data = action.payload.data;
      state.lastProgresses.fetchedAt = action.payload.fetchedAt;
    },
    // SLE: unused till fetchedAt is not linked to variable but to all last points, see with JCE/JBA/NDU if interesting to not always fetch all
    // setLastProgress: (state, action: PayloadAction<{ variableId: UUID; point: number }>) => {
    //   state.lastProgresses.data[action.payload.variableId] = action.payload.point;
    // },
    setIsFetchingLastProgresses: (state, action: PayloadAction<boolean>) => {
      state.lastProgresses.isFetching = action.payload;
    },
    setImportGridHeaderValues: (state, action: PayloadAction<Record<string, VariablesGridColumn | string>>) => {
      state.import.gridHeaderValues = action.payload;
    },
    setImportGridHeaderValue: (state, action: PayloadAction<{ field: string; value: VariablesGridColumn | string }>) => {
      state.import.gridHeaderValues[action.payload.field] = action.payload.value;
    },
    clearVariablesStore: () => initialState,
  },
});

export const {
  setVariablesById,
  setVariableById,
  addVariablesById,
  deleteVariableById,
  setAvailableVariableFlags,
  setCreatedVariable,
  setPromptBeforeClosingShouldBeDisplayed,
  setPromptBeforeClosingIsOpen,
  setPromptBeforeClosingRowIds,
  setIsFetchingVariables,
  setAvailableVariables,
  setFilters,
  setFilterKinds,
  setFilterDevice,
  setFilterPhysicalQuantities,
  setFilterTags,
  setSelectedVariables,
  setSearchValue,
  setSearchResults,
  setSearchIsFetching,
  setLastPoints,
  // setLastPoint,
  setIsFetchingLastPoints,
  setLastProgresses,
  // setLastProgress,
  setIsFetchingLastProgresses,
  setImportGridHeaderValues,
  setImportGridHeaderValue,
  clearVariablesStore,
} = variablesSlice.actions;

export default variablesSlice.reducer;
