import { SearchOffOutlined } from '@mui/icons-material';
import { List, ListItem, Popover, PopoverProps, Skeleton, Stack, Typography, popoverClasses } from '@mui/material';
import Fuse from 'fuse.js';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTypeInfo, FlattenFolderTree, ShortStandardBlockInfo } from '@dametis/core';

import { useBlockTypes } from 'store/api/blockTypes';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';
import { useStandardBlocks } from 'store/api/standardBlocks';

import BlockTypeSuggestion from './BlockTypeSuggestion';
import FolderSuggestion from './FolderSuggestion';
import StandardBlockSuggestion from './StandardBlockSuggestion';
import {
  IsSearchResultBlockType,
  IsSearchResultFolder,
  IsSearchResultStandardBlock,
  SearchResult,
  SearchResultType,
  entitiesToSearchResults,
} from './types';

const blockTypesEmptyArray: BlockTypeInfo[] = [];
const standardBlocksEmptyArray: ShortStandardBlockInfo[] = [];

export interface SuggestionsMenuProps {
  searchValue: string;
  isOpen: boolean;
  anchorEl: PopoverProps['anchorEl'];
}

const SuggestionsMenu: FC<SuggestionsMenuProps> = ({ searchValue, isOpen, anchorEl }) => {
  const { t } = useTranslation('lego');

  const { data: blockTypes = blockTypesEmptyArray } = useBlockTypes();
  const { data: standardBlocks = standardBlocksEmptyArray } = useStandardBlocks();
  const { data: globalRootFolder = null } = useReadGlobalRootFolderQuery();

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [result, setResult] = useState<SearchResult[]>([]);

  const debounce = useRef<ReturnType<typeof setTimeout> | null>(null);

  const parsedGlobalRootFolder = useMemo(
    () => (globalRootFolder ? { ...globalRootFolder, name: t('lego:text.factory') } : null),
    [globalRootFolder, t],
  );

  const folders = useMemo(() => (parsedGlobalRootFolder ? FlattenFolderTree(parsedGlobalRootFolder) : []), [parsedGlobalRootFolder]);

  const searchItems = useMemo(
    () => [
      ...entitiesToSearchResults<SearchResultType.FOLDER>(folders, SearchResultType.FOLDER),
      ...entitiesToSearchResults<SearchResultType.BLOCK_TYPE>(blockTypes, SearchResultType.BLOCK_TYPE),
      ...entitiesToSearchResults<SearchResultType.STANDARD_BLOCK>(standardBlocks, SearchResultType.STANDARD_BLOCK),
    ],
    [folders, blockTypes, standardBlocks],
  );

  const searchItemsFuse = useMemo(
    () =>
      new Fuse(searchItems, {
        keys: ['content.name'],
      }),
    [searchItems],
  );

  const searchResult = useCallback(() => {
    if (debounce.current) {
      clearTimeout(debounce.current);
    }
    debounce.current = setTimeout(() => {
      setIsSearching(true);
      setResult(searchItemsFuse.search(searchValue).map(fuseResult => fuseResult.item));
      setIsSearching(false);
    }, 250);
  }, [searchValue, searchItemsFuse]);

  useEffect(() => {
    searchResult();
  }, [searchValue, searchResult]);

  return (
    <Popover
      disableAutoFocus
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={isOpen}
      sx={{
        pointerEvents: 'none',
        [`& .${popoverClasses.paper}`]: {
          pointerEvents: 'all',
        },
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {!isSearching && (
        <>
          {result.length > 0 && (
            <List sx={{ width: 400, maxHeight: 500 }}>
              {result.map(resultItem => (
                <ListItem key={resultItem.content.uuid} disablePadding>
                  {IsSearchResultFolder(resultItem) && <FolderSuggestion folder={resultItem.content} />}
                  {IsSearchResultBlockType(resultItem) && <BlockTypeSuggestion blockType={resultItem.content} />}
                  {IsSearchResultStandardBlock(resultItem) && <StandardBlockSuggestion standardBlock={resultItem.content} />}
                </ListItem>
              ))}
            </List>
          )}
          {result.length === 0 && (
            <Stack alignItems="center" direction="row" justifyContent="center" px={4} py={2} spacing={2}>
              <SearchOffOutlined fontSize="large" sx={{ color: theme => theme.palette.grey[600] }} />
              <Typography variant="subtitle2">Aucun resultat</Typography>
            </Stack>
          )}
        </>
      )}
      {isSearching && (
        <List sx={{ width: 400 }}>
          <ListItem>
            <Skeleton height={80} variant="rounded" width="100%" />
          </ListItem>
          <ListItem>
            <Skeleton height={80} variant="rounded" width="100%" />
          </ListItem>
        </List>
      )}
    </Popover>
  );
};

export default SuggestionsMenu;
