import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

const BorderBoxBase = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    border: 'solid 3px',
    borderColor: 'secondary.light',
    borderRadius: 'inherit',
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
  }),
);

export interface BorderBoxProps extends BoxProps {
  active?: boolean;
}

export const BorderBox: FC<BorderBoxProps> = ({ active = undefined, children = undefined, ...props }) => {
  if (active === false) return children;
  if (!children) return <BorderBoxBase {...props} />;
  return (
    <Box position="relative">
      {children}
      <BorderBoxBase {...props} />
    </Box>
  );
};
