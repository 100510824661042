import { ArrowBackIos } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import CenteredPage from '../UI/CenteredPage/CenteredPage';

import { useErrorStyles } from './Error.styles';

interface Props {
  title: string;
  text: ReactNode;
  image: string;
  children?: ReactNode | null;
}

const ErrorPageTemplate: FC<Props> = ({ title, text, image, children = null }) => {
  const { t } = useTranslation('errors');

  const classes = useErrorStyles();
  return (
    <CenteredPage>
      <div className={classes.errorPaper}>
        <img alt="error" className={classes.errorPaper__image} src={image} />
        <div className={classes.errorPaper__text}>
          <Typography variant="h1">{title}</Typography>
          <Typography variant="subtitle2">{text}</Typography>
        </div>
        <div className={classes.errorPaper__children}>{children}</div>
        <div className={classes.errorPaper__button}>
          <Button color="secondary" component="a" href="/" startIcon={<ArrowBackIos />} variant="contained">
            {t('button.back')}
          </Button>
        </div>
      </div>
    </CenteredPage>
  );
};

export default ErrorPageTemplate;
