import { Add, AddBoxOutlined, CreateNewFolderOutlined } from '@mui/icons-material';
import {
  Breadcrumbs,
  Collapse,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  breadcrumbsClasses,
  capitalize,
  useTheme,
} from '@mui/material';
import { ChangeEventHandler, FC, FocusEventHandler, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { BlockTypeInfo, FolderInfo, StandardBlockInfo, UUID } from '@dametis/core';

import CreateFolderModal from 'components/Lego/BlockDiagram/CreateFolderModal/CreateFolderModal';
import CreateBlockTypeModal from 'components/Lego/UI/BlockType/CreateBlockTypeModal/CreateBlockTypeModal';
import CreateStandardBlockModal from 'components/Lego/UI/StandardBlock/CreateStandardBlockModal/CreateStandardBlockModal';
import Header from 'components/UI/Header/Header';
import HeaderPrimary from 'components/UI/Header/HeaderPrimary';
import HeaderPrimaryLeft from 'components/UI/Header/HeaderPrimaryLeft';
import HeaderPrimaryLeftTitle from 'components/UI/Header/HeaderPrimaryLeftTitle';
import HeaderPrimaryRight from 'components/UI/Header/HeaderPrimaryRight';
import HeaderPrimaryRightAction from 'components/UI/Header/HeaderPrimaryRightAction';
import HeaderPrimaryRightActions from 'components/UI/Header/HeaderPrimaryRightActions';
import SearchInputBase from 'components/UI/SearchInput/SearchInputBase';
import makePermissionLabel from 'functions/makePermissionLabel';
import { useSelector } from 'store';

import { MenuTab } from '../LegoCatalog';

import SuggestionsMenu from './SuggestionsMenu';

export interface CatalogHeaderProps {
  currentPath: FolderInfo[];
  selectedMenuTab: MenuTab;
}

const CatalogHeader: FC<CatalogHeaderProps> = ({ currentPath, selectedMenuTab }) => {
  const { t } = useTranslation('lego');
  const navigate = useNavigate();
  const theme = useTheme();

  const { folderId } = useParams<{ folderId?: UUID }>();

  const isAdmin = useSelector(state => state.auth.user?.acl.global.HasAnyRole(['EMaaS', 'SUPER_ADMIN']) ?? false);

  const [createMenuAnchorEl, setCreateMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [suggestionsMenuAnchorEl, setSuggestionsMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isCreateBlockTypeModalOpen, setIsCreateBlockTypeModalOpen] = useState<boolean>(false);
  const [isCreateStandardBlockModalOpen, setIsCreateStandardBlockModalOpen] = useState<boolean>(false);
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState<boolean>(false);

  const isCreateMenuOpen = useMemo(() => Boolean(createMenuAnchorEl), [createMenuAnchorEl]);

  const isSuggestionsMenuOpen = useMemo(
    () => Boolean(suggestionsMenuAnchorEl) && searchValue.trim().length > 0,
    [suggestionsMenuAnchorEl, searchValue],
  );

  const handleFocusSearchInput: FocusEventHandler<HTMLInputElement> = useCallback(event => {
    setSuggestionsMenuAnchorEl(event.currentTarget);
  }, []);

  const handleBlurSearchInput: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    setSuggestionsMenuAnchorEl(null);
  }, []);

  const handleChangeSearchValue: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setSearchValue(event.target.value);
  }, []);

  const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    setCreateMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setCreateMenuAnchorEl(null);
  }, []);

  const handleOpenCreateBlockTypeModal: MouseEventHandler<HTMLLIElement> = useCallback(() => {
    setCreateMenuAnchorEl(null);
    setIsCreateBlockTypeModalOpen(true);
  }, []);

  const handleOpenCreateStandardBlockModal: MouseEventHandler<HTMLLIElement> = useCallback(() => {
    setCreateMenuAnchorEl(null);
    setIsCreateStandardBlockModalOpen(true);
  }, []);

  const handleCreateFolder: MouseEventHandler<HTMLLIElement> = useCallback(() => {
    setCreateMenuAnchorEl(null);
    setIsCreateFolderModalOpen(true);
  }, []);

  const handleCreateBlockType = useCallback(
    (newBlockType: BlockTypeInfo, parentFolderId?: UUID) => {
      navigate(`/blockTypes/${parentFolderId ?? 'noFolder'}/${newBlockType.uuid}`, { state: { noScrollTop: true } });
    },
    [navigate],
  );

  const handleCreateStandardBlock = useCallback(
    (newStandardBlock: StandardBlockInfo, parentFolderId: UUID) => {
      navigate(`/blockTypes/${parentFolderId}/${newStandardBlock.uuid}`, { state: { noScrollTop: true } });
    },
    [navigate],
  );

  return (
    <>
      <Header>
        <HeaderPrimary>
          <HeaderPrimaryLeft>
            <HeaderPrimaryLeftTitle>{t('title.typesCatalog')}</HeaderPrimaryLeftTitle>
            <Collapse in={selectedMenuTab === MenuTab.FOLDER_CONTENT}>
              <Breadcrumbs separator="›" sx={{ [`& .${breadcrumbsClasses.separator}`]: { mx: 1.5 } }}>
                {currentPath.map((folder, index) =>
                  index + 1 === currentPath.length ? (
                    <Typography key={folder.uuid}>{index === 0 ? t('text.factory') : folder.name}</Typography>
                  ) : (
                    <Link
                      key={folder.uuid}
                      component={NavLink}
                      sx={{ backgroundColor: 'unset', p: 'unset' }}
                      to={`/blockTypes/${folder.uuid}`}
                    >
                      {index === 0 ? t('text.factory') : folder.name}
                    </Link>
                  ),
                )}
              </Breadcrumbs>
            </Collapse>
          </HeaderPrimaryLeft>
          <HeaderPrimaryRight>
            <SearchInputBase
              value={searchValue}
              onBlur={handleBlurSearchInput}
              onChange={handleChangeSearchValue}
              onFocus={handleFocusSearchInput}
            />
            <SuggestionsMenu anchorEl={suggestionsMenuAnchorEl} isOpen={isSuggestionsMenuOpen} searchValue={searchValue} />
            <Collapse in={selectedMenuTab === MenuTab.FOLDER_CONTENT} orientation="horizontal">
              <HeaderPrimaryRightActions>
                <HeaderPrimaryRightAction
                  icon={Add}
                  sx={{
                    transition: theme.transitions.create(['transform']),
                    ...(isCreateMenuOpen && { transform: 'rotate(45deg)' }),
                  }}
                  text={capitalize(t('tooltip.createEntity'))}
                  onClick={handleOpenMenu}
                />
              </HeaderPrimaryRightActions>
            </Collapse>
          </HeaderPrimaryRight>
        </HeaderPrimary>
      </Header>
      <CreateBlockTypeModal
        folderId={folderId}
        isOpen={isCreateBlockTypeModalOpen}
        setIsOpen={setIsCreateBlockTypeModalOpen}
        onCreate={handleCreateBlockType}
      />
      <CreateStandardBlockModal
        folderId={folderId}
        isOpen={isCreateStandardBlockModalOpen}
        setIsOpen={setIsCreateStandardBlockModalOpen}
        onCreate={handleCreateStandardBlock}
      />
      <CreateFolderModal isGlobal isOpen={isCreateFolderModalOpen} parentId={folderId} setIsOpen={setIsCreateFolderModalOpen} />
      <Menu
        anchorEl={createMenuAnchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={isCreateMenuOpen}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handleCloseMenu}
      >
        <Tooltip title={makePermissionLabel(t('tooltip.createBlockType'), isAdmin)}>
          <span>
            <MenuItem disabled={!isAdmin} onClick={handleOpenCreateBlockTypeModal}>
              <ListItemIcon>
                <AddBoxOutlined color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('label.newBlockType')}</ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip title={makePermissionLabel(t('tooltip.createStandardBlock'), isAdmin)}>
          <span>
            <MenuItem disabled={!isAdmin} onClick={handleOpenCreateStandardBlockModal}>
              <ListItemIcon>
                <AddBoxOutlined color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('label.newStandardBlock')}</ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip title={makePermissionLabel(t('tooltip.createFolder'), isAdmin)}>
          <span>
            <MenuItem disabled={!isAdmin} onClick={handleCreateFolder}>
              <ListItemIcon>
                <CreateNewFolderOutlined color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('label.newFolder')}</ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>
    </>
  );
};

export default CatalogHeader;
