import { SvgIconProps } from '@mui/material';
import { FC, useMemo } from 'react';

import { Shortcut, StandardBlockInfo, UUID } from '@dametis/core';

import { DeployedCodeOutlined } from 'assets/icons/DeployedCodeOutlined';

import { AvailableShortcutCategory, EntitiesByCategory } from '../types';

export interface ListItemStandardBlockIconProps extends Omit<SvgIconProps, 'color'> {
  shortcut: Shortcut;
  entitiesByCategory: EntitiesByCategory;
}

const ListItemStandardBlockIcon: FC<ListItemStandardBlockIconProps> = ({ shortcut, entitiesByCategory, ...props }) => {
  const standardBlocksById: Record<UUID, StandardBlockInfo> = useMemo(
    () => entitiesByCategory[shortcut.category as AvailableShortcutCategory] as Record<UUID, StandardBlockInfo>,
    [entitiesByCategory, shortcut.category],
  );

  const standardBlock: StandardBlockInfo | null = useMemo(
    () => (shortcut.uuid && standardBlocksById ? standardBlocksById[shortcut.uuid] : null),
    [shortcut, standardBlocksById],
  );

  return <DeployedCodeOutlined sx={{ color: standardBlock?.ui?.color }} {...props} />;
};

export default ListItemStandardBlockIcon;
