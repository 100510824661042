import convertToUrl from './convertToUrl';

const downloadFile = (data: BlobPart, contentType: string, name: string) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = convertToUrl(data, contentType);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadFile;
