import { MenuItem, TextField } from '@mui/material';
import { ChangeEventHandler, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockMetadataOptionContent, StandardBlockMetadataOptionContent } from '@dametis/core';

export interface MetadataOptionContentFormProps {
  content: StandardBlockMetadataOptionContent | BlockMetadataOptionContent;
  onChange: (newContent: StandardBlockMetadataOptionContent | BlockMetadataOptionContent) => void;
}

const MetadataOptionContentForm: FC<MetadataOptionContentFormProps> = ({ content, onChange }) => {
  const { t } = useTranslation('lego');

  const handleChangeSelectedOptionId: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const newContent: StandardBlockMetadataOptionContent | BlockMetadataOptionContent = {
        ...content,
        selectedOptionId: event.target.value,
      };
      onChange(newContent);
    },
    [content, onChange],
  );

  return (
    <>
      <TextField fullWidth select value={content.selectedOptionId ?? ''} onChange={handleChangeSelectedOptionId}>
        <MenuItem disabled value="">
          {t('text.noOptionSelected')}
        </MenuItem>
        {content.availableOptions.map(availableOption => (
          <MenuItem key={availableOption.uuid} value={availableOption.uuid}>
            {availableOption.value}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default MetadataOptionContentForm;
