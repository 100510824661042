import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BoxInfo } from '@dametis/core';

export interface CurrentBoxState {
  infos: BoxInfo | null;
}

const initialState: CurrentBoxState = {
  infos: null,
};

export const currentBoxSlice = createSlice({
  name: 'currentBox',
  initialState,
  reducers: {
    setInfos: (state, action: PayloadAction<BoxInfo>) => {
      state.infos = action.payload;
    },
  },
});

export const { setInfos } = currentBoxSlice.actions;

export default currentBoxSlice.reducer;
