import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, List, ListItem, ListItemText, TextField, Tooltip } from '@mui/material';
import { DateRange, DateRangePicker, DateRangePickerProps, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import {
  addDays,
  differenceInDays,
  differenceInMilliseconds,
  endOfDay,
  isSameMonth,
  isSameYear,
  isValid,
  startOfDay,
  subDays,
} from 'date-fns';
import { FC, MouseEventHandler, forwardRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CalculationVariable, Operator, RawPeriod, setOptionsToLeaves } from '@dametis/core';

import { parseTadaErrors } from 'errors/parseErrors';
import { getLocale } from 'localization';
import { localizedFormat } from 'localization/localizedDateFns';
import { useDispatch } from 'store';
import { displaySdkErrorToast, displayTadaErrorToasts, hasTadaErrors } from 'store/actions/toasts';

import { durationDisplay } from '../../../functions/durationDisplay';
import { isSameDate } from '../../../functions/isSameDate';
import { Tada } from '../../../functions/tada/tada';
import BatchFilterInput, { BatchFilterInputProps } from '../BatchFilter/BatchFilterInput';

import useDateTimePickerStyles from './DateTimeRangePanel.styles';

interface ButtonFieldProps {
  label: string;
  setOpen: MouseEventHandler<HTMLButtonElement>;
}

const ButtonField = forwardRef<HTMLButtonElement, ButtonFieldProps>(({ setOpen, label }, ref) => {
  const classes = useDateTimePickerStyles();
  return (
    <Button ref={ref} className={classes.batchDate__picker} onClick={setOpen}>
      {label}
    </Button>
  );
});

ButtonField.displayName = 'ButtonField';

interface Props {
  batch: CalculationVariable;
  setBatch: BatchFilterInputProps['setBatch'];
  batchDate: DateRange<any>;
  setBatchDate: (date: DateRange<any>) => void;
  changeTimeRange: (date: DateRange<any>) => void;
}

const BatchTab: FC<Props> = ({ batch, setBatch, batchDate, setBatchDate, changeTimeRange }: Props) => {
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [runs, setRuns] = useState<RawPeriod[]>([]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { t } = useTranslation('dateTimePicker');
  const classes = useDateTimePickerStyles();

  const handleOpenCalendar = useCallback<NonNullable<ButtonFieldProps['setOpen']>>(
    e => {
      if (!anchorEl) setAnchorEl(e.currentTarget);
      setOpenCalendar(true);
    },
    [anchorEl],
  );

  const handleCloseCalendar = useCallback(() => {
    setOpenCalendar(false);
  }, []);

  const handleDatePicker = useCallback<NonNullable<DateRangePickerProps<Date>['onChange']>>(
    date => {
      setBatchDate(date);
    },
    [setBatchDate],
  );

  const renderDate = (from: Date, to: Date) => {
    if (isSameDate(startOfDay(from), startOfDay(to))) {
      return localizedFormat(from, 'PP');
    }
    if (isSameMonth(from, to) && isSameYear(from, to)) {
      return `${localizedFormat(from, 'dd')} - ${localizedFormat(to, 'dd')} ${localizedFormat(from, 'MMMM')} ${localizedFormat(
        from,
        'yyyy',
      )}`;
    }
    return `${isValid(from) ? localizedFormat(from, 'PP') : t('global:text.noValue')} - ${
      isValid(to) ? localizedFormat(to, 'PP') : t('global:text.noValue')
    }`;
  };

  const handleGoBackDateButton = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    const from = new Date(batchDate[0]);
    const to = new Date(batchDate[1]);
    const val = differenceInDays(to, from);
    setBatchDate([subDays(from, val ?? 1), subDays(to, val ?? 1)]);
  }, [batchDate, setBatchDate]);

  const handleGoForwardDateButton = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    const from = new Date(batchDate[0]);
    const to = new Date(batchDate[1]);
    const val = differenceInDays(to, from);
    setBatchDate([addDays(from, val ?? 1), addDays(to, val ?? 1)]);
  }, [batchDate, setBatchDate]);

  const fetchData: (batchFilter: CalculationVariable, from: string, to: string) => void = useCallback(
    async (batchFilter, from, to) => {
      setLoading(true);
      const payload = {
        exp: '',
        vars: {},
        period: {
          from,
          to,
        },
        operator: Operator.TIME_WEIGHTED_MEAN,
      };
      setOptionsToLeaves(payload, { groupBy: { ...batchFilter } }, true);
      try {
        const { data } = await Tada([payload]);
        if (hasTadaErrors(data)) {
          console.error(data[0].errors);
          dispatch(displayTadaErrorToasts(parseTadaErrors(data)));
          setLoading(false);
          return;
        }
        if (data[0].batchResults?.periods !== undefined) {
          setRuns(data[0].batchResults.periods);
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
        dispatch(displaySdkErrorToast(err));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    setRuns([]);
    if (batch.exp !== '' && batch.exp !== 'unset') {
      if (batchDate[0] !== null && batchDate[1] !== null) {
        const from = startOfDay(new Date(batchDate[0])).toISOString();
        const to = endOfDay(new Date(batchDate[1])).toISOString();
        void fetchData(batch, from, to);
      }
    }
  }, [batch, batchDate, fetchData]);

  return (
    <Box sx={{ width: '480px' }}>
      <div className={classes.batchInput}>
        <BatchFilterInput noOperator batch={batch} setBatch={setBatch} />
        <TextField style={{ display: 'none' }} variant="outlined" />
      </div>
      <div className={classes.batchDate}>
        <Tooltip title={t('tooltip.previousPeriod')}>
          <IconButton disabled={loading} size="large" onClick={handleGoBackDateButton}>
            <ArrowBackIosOutlined />
          </IconButton>
        </Tooltip>
        <LocalizationProvider adapterLocale={getLocale()} dateAdapter={AdapterDateFns}>
          <DateRangePicker
            disableAutoMonthSwitching
            calendars={1}
            minDate={new Date(+0)}
            open={openCalendar}
            slotProps={{
              field: { setOpen: handleOpenCalendar, label: renderDate(batchDate[0], batchDate[1]) } as any,
              popper: { anchorEl },
            }}
            slots={{ field: ButtonField }}
            value={batchDate}
            onChange={handleDatePicker}
            onClose={handleCloseCalendar}
          />
        </LocalizationProvider>
        <Tooltip title={t('tooltip.nextPeriod')}>
          <IconButton disabled={loading} size="large" onClick={handleGoForwardDateButton}>
            <ArrowForwardIosOutlined />
          </IconButton>
        </Tooltip>
      </div>
      {!loading && (
        <List dense className={classes.batch__runs}>
          {runs.length > 0 ? (
            runs
              .slice(0)
              .reverse()
              .map(run => {
                const from = new Date(run.from);
                const to = new Date(run.to);
                return (
                  <ListItem
                    key={`${from.toString()}-${to.toString()}`}
                    button
                    onClick={() => {
                      changeTimeRange([from, to]);
                    }}
                  >
                    <ListItemText
                      className={classes.batch__runs__item}
                      primary={
                        <>
                          <div className={classes.batch__runs__item__dates}>{`${localizedFormat(from, 'eee PPpp')} - ${localizedFormat(
                            to,
                            'eee PPpp',
                          )}`}</div>
                          <div className={classes.batch__runs__item__duration}>
                            {durationDisplay(differenceInMilliseconds(to, from) / 1000)}
                          </div>
                          {/* <div className={classes.batch__runs__item__duration}>{moment.duration(to.diff(from)).humanize()}</div> */}
                        </>
                      }
                    />
                  </ListItem>
                );
              })
          ) : (
            <ListItem>
              <ListItemText primary={t('text.empty')} />
            </ListItem>
          )}
        </List>
      )}
      {loading && (
        <div className={classes.batch__runs__loader}>
          <CircularProgress color="secondary" />
        </div>
      )}
    </Box>
  );
};

export default BatchTab;
