import { FC } from 'react';

import { Shortcut } from '@dametis/core';

import { ChipEllipseProps } from 'components/UI/ChipEllipse/ChipEllipse';
import { IsSelectableEntityShortcut, IsSelectableHrefShortcut, SelectableShortcut } from 'types/shortcut';

import ShortcutEntityChip from './ShortcutEntityChip';
import ShortcutHrefChip from './ShortcutHrefChip';
import ShortcutUnknownChip from './ShortcutUnknownChip';

export interface ShortcutChipProps extends Omit<ChipEllipseProps, 'label'> {
  clickable?: boolean;
  shortcut: SelectableShortcut | Shortcut;
}

const ShortcutChip: FC<ShortcutChipProps> = ({ clickable = true, shortcut, ...props }) => {
  if (IsSelectableEntityShortcut(shortcut)) {
    return <ShortcutEntityChip clickable={clickable} groupId={shortcut.groupId} shortcut={shortcut} siteId={shortcut.siteId} {...props} />;
  }

  if (IsSelectableHrefShortcut(shortcut)) {
    return <ShortcutHrefChip clickable={clickable} shortcut={shortcut} {...props} />;
  }

  return <ShortcutUnknownChip shortcut={shortcut} {...props} />;
};

export default ShortcutChip;
