import { Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import { FC, ReactNode } from 'react';

const stackPropsDefaultProp: StackProps = {};

export interface RightPanelSectionTitleProps extends TypographyProps {
  noPadding?: boolean;
  noMargin?: boolean;
  action?: ReactNode;
  stackProps?: StackProps;
}

const RightPanelSectionTitle: FC<RightPanelSectionTitleProps> = ({
  noPadding = false,
  noMargin = false,
  children = undefined,
  stackProps: { sx: stackSx, ...stackProps } = stackPropsDefaultProp,
  action = undefined,
  ...props
}) => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="space-between"
    sx={{ py: 0, mb: 1, px: noPadding ? 0 : 2, ...(noMargin && { m: '0 !important' }), ...stackSx }}
    {...stackProps}
  >
    <Typography variant="h6" {...props}>
      {children}
    </Typography>
    <Stack alignItems="center" direction="row" spacing={0.5}>
      {action}
    </Stack>
  </Stack>
);

export default RightPanelSectionTitle;
