export default {
  text: {
    logIntoDametis: 'Iniciar sesión:',
    logRecoverDametis: '¿Olvidaste tu contraseña?',
    logChangePasswordDametis: 'Elija su nueva contraseña:',
    logInvalidLostPwdToken: 'Enlace para cambiar la contraseña no válido.',
    logInvalidAccountActToken: 'Enlace para activar su cuenta no válido.',
    logDeadApi: 'Se produjo un error, por favor inténtelo de nuevo más tarde',
    invalid: 'Correo electrónico y contraseña inválidos',
    or: 'o',
    setCookie: 'Creando la cookie...',
    connection: 'Conexión',
    invitation: 'Invitación',
    email: 'Correo electrónico',
    passkey: 'Llave de acceso',
  },
  input: {
    accountNotActive: 'Su cuenta no está activada, contacte a support@dametis.com',
    email: {
      label: 'Correo electrónico',
      error: {
        userNotFound: 'Usuario inexistente',
        emailNotFound: 'Correo electrónico inexistente',
        token: 'Token no válido',
        tokenPassword: 'Enlace no válido, intente generar uno nuevo',
      },
    },
    password: {
      label: 'Contraseña',
      error: {
        wrongPassword: 'Contraseña incorrecta',
        wrongEmailOrPassword: 'Correo electrónico o contraseña incorrectos',
        invalidStrength: 'Por favor elija una contraseña más segura. Intente una combinación de letras, números y símbolos.',
      },
    },
    newPassword: {
      label: 'Nueva contraseña',
      success: '¡Contraseña cambiada con éxito, inicie sesión!',
      error: {
        mismatch: 'Las contraseñas no coinciden',
        invalidToken: 'Enlace no válido',
        noTokenFound: 'Token no válido',
      },
    },
    confirmPassword: {
      label: 'Confirme su contraseña',
    },
  },
  button: {
    logIn: 'Iniciar sesión',
    recover: '¿Olvidaste tu contraseña?',
    reset: 'Validar',
    update: 'Modificar',
    loginWith: 'Iniciar sesión con {{ auth }}',
    next: 'Siguiente',
    login: 'Iniciar sesión',
    retry: 'Intentar otra vez',
  },
  link: {
    alwaysShowGoogle: 'Siempre mostrar',
  },
  alertMessage: {
    invalidEmail: 'Correo electrónico no válido',
    wrongEmail: 'Correo electrónico inexistente',
    successRecoveryEmail:
      'Si hay una cuenta asociada a esta dirección de correo electrónico, recibirá pronto un enlace para restablecer la contraseña',
    emptyField: 'Por favor complete todos los campos',
    successChangePassword: 'Cambio de contraseña exitoso, inicie sesión',
  },
  reconnect: {
    error: 'Se produjo un error durante la conexión.',
  },
  tooltip: {
    showPassword: 'Mostrar contraseña',
    hidePassword: 'Ocultar contraseña',
    refresh: 'Actualizar',
  },
  twoFA: {
    email: 'Recibir un código por correo electrónico',
    phone: 'Recibir un código por SMS',
    totp: 'Aplicación de autenticación',
    passkey: 'Utilice una clave de acceso',
    selectMethod: 'Seleccione su método de autenticación',
    chooseOtherMethod: 'Anterior',
    changeAccount: 'Anterior',
    errorCode: 'Código no válido',
    enterTOTPCode: 'Ingrese el código generado por su aplicación de autenticación.',
    enrollment: {
      explanation: 'Su organización le solicita configurar un segundo método de autenticación',
      selectMethod: 'Seleccione su método de autenticación',
      email: 'Configurar por correo electrónico',
      phone: 'Configurar por SMS',
      otp: 'Configurar con la aplicación de autenticación',
      later: 'Más tarde',
      previous: 'Anterior',
      emailTitle: 'Ingrese su dirección de correo electrónico',
      phoneTitle: 'Ingrese su número de teléfono',
      codeTitle: 'Ingrese el código recibido por SMS',
      emailError: 'Correo electrónico no válido',
      phoneError: 'Número de teléfono no válido',
      code: 'Código de verificación',
      disconnect: 'Desconexión',
    },
  },
  sso: {
    notFound: 'No ha sido invitado a crear una cuenta. Póngase en contacto con su administrador para recibir un enlace de invitación.',
    expired: 'El enlace que tienes ha caducado, ponte en contacto con tu administrador para obtener uno nuevo.',
    invalidId: 'No hemos podido verificar su identidad. Inténtelo de nuevo o póngase en contacto con su administrador.',
    alert: {
      invited: 'Usted ha sido invitado a unirse al sitio',
      by: ' por ',
      askMail: 'Introduzca su dirección de correo electrónico profesional.',
    },
  },
  webauthn: {
    error: {
      authenticator: 'Su autenticador no pudo generar claves de acceso',
    },
  },
};
