import { TextField } from '@mui/material';
import { ChangeEventHandler, FC, useCallback } from 'react';

import { BlockMetadataStringContent, StandardBlockMetadataStringContent } from '@dametis/core';

export interface MetadataStringContentFormProps {
  content: StandardBlockMetadataStringContent | BlockMetadataStringContent;
  onChange: (newContent: StandardBlockMetadataStringContent | BlockMetadataStringContent) => void;
}

const MetadataStringContentForm: FC<MetadataStringContentFormProps> = ({ content, onChange }) => {
  const handleChangeValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const newContent = { ...content, value: event.target.value };
      onChange(newContent);
    },
    [content, onChange],
  );

  return <TextField fullWidth value={content.value} onChange={handleChangeValue} />;
};

export default MetadataStringContentForm;
