export default {
  title: {
    stats: "Statistiques d'insertion des points",
  },
  label: {
    data: 'Données',
    previousData: 'Passées',
    sync: 'Syncs',
    heartbeat: 'HeartBeats',
    unit: '/s',
    period: 'Période',
  },
};
