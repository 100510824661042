import { Feed, FeedOutlined } from '@mui/icons-material';
import { FC, SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTypeInfo } from '@dametis/core';

import { DeployedCode } from 'assets/icons/DeployedCode';
import { DeployedCodeOutlined } from 'assets/icons/DeployedCodeOutlined';
import IconTab from 'components/UI/IconTabs/IconTab';
import IconTabs from 'components/UI/IconTabs/IconTabs';

import InformationsTab from './InformationsTab';
import StandardBlocksTab from './StandardBlocksTab';

export enum BlockTypePanelTab {
  INFORMATIONS = 'informations',
  STANDARD_BLOCKS = 'standardBlocks',
}

export interface BlockTypeRightPanelProps {
  blockType: BlockTypeInfo;
}

const BlockTypeRightPanel: FC<BlockTypeRightPanelProps> = ({ blockType }) => {
  const { t } = useTranslation('lego');

  const [selectedPanelTab, setSelectedPanelTab] = useState<BlockTypePanelTab>(BlockTypePanelTab.INFORMATIONS);

  const handleChangePanelTab = useCallback((_event: SyntheticEvent, newPanelTab: BlockTypePanelTab) => {
    setSelectedPanelTab(newPanelTab);
  }, []);

  return (
    <>
      <IconTabs centered value={selectedPanelTab} variant="fullWidth" onChange={handleChangePanelTab}>
        <IconTab
          activeIcon={<Feed />}
          icon={<FeedOutlined />}
          isActive={BlockTypePanelTab.INFORMATIONS === selectedPanelTab}
          label={t(`panelTab.${BlockTypePanelTab.INFORMATIONS}`)}
          value={BlockTypePanelTab.INFORMATIONS}
        />
        <IconTab
          activeIcon={<DeployedCode />}
          icon={<DeployedCodeOutlined />}
          isActive={BlockTypePanelTab.STANDARD_BLOCKS === selectedPanelTab}
          label={t(`panelTab.${BlockTypePanelTab.STANDARD_BLOCKS}`)}
          value={BlockTypePanelTab.STANDARD_BLOCKS}
        />
      </IconTabs>
      {selectedPanelTab === BlockTypePanelTab.INFORMATIONS && <InformationsTab blockType={blockType} />}
      {selectedPanelTab === BlockTypePanelTab.STANDARD_BLOCKS && <StandardBlocksTab blockType={blockType} />}
    </>
  );
};

export default BlockTypeRightPanel;
