import {
  Box,
  BoxProps,
  ButtonBase,
  Paper,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
  alpha,
  inputClasses,
  styled,
  svgIconClasses,
} from '@mui/material';
import { FC, PropsWithChildren, ReactNode, SyntheticEvent, forwardRef } from 'react';

import { setColorLightness } from 'functions/color';

export const HomeHeader: FC<BoxProps & { corporate: boolean }> = styled(({ corporate, ...props }: BoxProps & { corporate: boolean }) => (
  <Box sx={{ py: 12 }}>
    <Box {...props} />
    <svg height="0" version="1.1" width="0" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="round">
          <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="5" />
          <feColorMatrix in="blur" mode="matrix" result="goo" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" />
          <feComposite in="SourceGraphic" in2="goo" operator="atop" />
        </filter>
      </defs>
    </svg>
  </Box>
))<{ corporate: boolean }>(({ theme, corporate }) => ({
  position: 'relative',
  width: 'fit-content',
  padding: theme.spacing(4),
  backgroundColor: setColorLightness(corporate ? theme.palette.primary.main : theme.palette.daGreen.main, 93),
  borderRadius: `${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px 0`,
  filter: 'url(#round)',
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: theme.spacing(-4),
    left: 0,
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: setColorLightness(corporate ? theme.palette.primary.main : theme.palette.daGreen.main, 93),
    clipPath: 'polygon(100% 0, 0 0, 0 100%)',
  },
}));

export const HomeTitle: FC<TypographyProps> = styled((props: TypographyProps) => <Typography variant="h1" {...props} />)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));

export const HomeHighlight: FC<{ corporate: boolean; children: string }> = styled('span')<{ corporate: boolean; children: string }>(
  ({ theme, corporate }) => ({
    color: corporate ? theme.palette.primary.main : theme.palette.daGreen.main,
  }),
);

export const HomeText: FC<TypographyProps> = styled((props: TypographyProps) => <Typography variant="h4" {...props} />)(({ theme }) => ({
  marginBottom: theme.spacing(0.25),
}));

export const HomeSubtitle: FC<TypographyProps> = styled((props: TypographyProps) => (
  <Typography color="textSecondary" variant="subtitle1" {...props} />
))(() => ({}));

export const HomeBold: FC<{
  corporate: boolean;
  children: ReactNode;
}> = styled('span')<{ corporate: boolean }>(({ theme, corporate }) => ({
  fontWeight: 500,
  color: corporate ? theme.palette.primary.main : theme.palette.daGreen.main,
}));

export const SitePaper = styled(
  (props: PropsWithChildren<{ onClick?: (event: any) => void }>) => <Paper component={props.onClick ? ButtonBase : 'div'} {...props} />,
  {
    shouldForwardProp: propName => propName !== 'active',
  },
)<{ active: boolean }>(({ theme, active, onClick }) => ({
  width: '100%',
  display: 'block',
  border: active ? `${theme.palette.secondary.light} solid 3px` : undefined,
  ...(onClick
    ? {
        [`& .${svgIconClasses.root}`]: {
          opacity: 0,
          pointerEvents: 'none',
          transition: theme.transitions.create(['opacity']),
        },
        [`&:hover .${svgIconClasses.root}`]: {
          pointerEvents: 'default',
          opacity: 1,
        },
      }
    : {}),
}));

export const SitePreviewContainer: FC<StackProps> = styled(Stack)(({ theme }) => ({
  aspectRatio: '16 / 7',
  position: 'relative',
  marginBottom: `-${theme.shape.borderRadius}px`,
  paddingBottom: `${theme.shape.borderRadius}px`,
  backgroundColor: theme.palette.grey[200],
  borderRadius: '8px 8px 0 0',
  overflow: 'hidden',
  [`& > div:not(.${inputClasses.root})`]: {
    opacity: 0,
    transition: theme.transitions.create(['opacity']),
  },
  [`&:hover > div:not(.${inputClasses.root})`]: {
    opacity: 1,
  },
}));

export const SiteButton = forwardRef<HTMLButtonElement, PropsWithChildren<{ onClick?: (event: SyntheticEvent) => void; tabIndex: number }>>(
  (props, ref) => (props.onClick ? <ButtonBase {...props} ref={ref} /> : <Box {...props} ref={ref} />),
);
SiteButton.defaultProps = { onClick: undefined };
SiteButton.displayName = 'SiteButton';

export const StyledSiteButton = styled(SiteButton, {
  shouldForwardProp: propName => propName !== 'active',
})<{ selected: boolean }>(({ theme, selected }) => ({
  padding: theme.spacing(2),
  textAlign: 'left',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  justifyContent: 'flex-start',

  ...(selected && {
    backgroundColor: theme.palette.grey[200],
    border: 'transparent',
  }),

  [`& .${svgIconClasses.root}`]: {
    opacity: 0,
    pointerEvents: 'none',
    transition: theme.transitions.create(['opacity']),
    '&:hover': {
      pointerEvents: 'default',
      opacity: 1,
    },
  },
  '&:focus': {
    backgroundColor: theme.palette.grey[200],
    border: 'transparent',
  },
  '&:focus-visible': {
    outline: 'none',
  },
}));

export const SitePreview: FC<{ blurred: boolean; src: string }> = styled('img', { shouldForwardProp: propName => propName !== 'blurred' })<{
  blurred: boolean;
  src: string;
}>(({ blurred }) => ({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  filter: blurred ? 'blur(20px)' : 'unset',
  position: 'absolute',
  left: 0,
  top: 0,
}));

export const SitePreviewActions: FC<StackProps> = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: theme.spacing(1),
  width: '100%',
  height: '100%',
  background: `linear-gradient(${alpha(theme.palette.background.paper, 0.75)}, ${alpha(theme.palette.background.paper, 0)})`,
}));
