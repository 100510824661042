import type { Point, Series } from 'highcharts';
import { FC, useMemo } from 'react';

import { getFormattedValue } from 'components/UI/UnitPicker/functions/getFormattedValue';

import { LineSeriesCustomOptions, RangeSeriesCustomOptions } from '../../types';

export interface SeriesValueProps {
  series: Series;
  point: Point;
}

const SeriesValue: FC<SeriesValueProps> = ({ series, point }) => {
  const formattedValue = useMemo(() => {
    if (series.userOptions.type === 'arearange') {
      // TODO JCE dangerous any typing
      const min = (series.data[point.index] as any)?.low;
      const max = (series.data[point.index] as any)?.high;
      if (min && max) {
        const formattedMin = getFormattedValue({
          value: min,
          baseUnit: (series.userOptions.custom as RangeSeriesCustomOptions)?.baseUnit,
          userUnit: (series.userOptions.custom as RangeSeriesCustomOptions)?.userUnit,
        });
        const formattedMax = getFormattedValue({
          value: max,
          baseUnit: (series.userOptions.custom as RangeSeriesCustomOptions)?.baseUnit,
          userUnit: (series.userOptions.custom as RangeSeriesCustomOptions)?.userUnit,
        });
        return `${formattedMin} - ${formattedMax}`;
      }
    } else if (series.userOptions.type === 'sankey') {
      if (point.options.custom?.displayedValue) {
        return point.options.custom.displayedValue;
      }
    } else {
      const value = series.data[point.index]?.y;
      if (!Number.isNaN(value)) {
        return getFormattedValue({
          value,
          baseUnit: (series.userOptions.custom as LineSeriesCustomOptions)?.baseUnit,
          userUnit: (series.userOptions.custom as LineSeriesCustomOptions)?.userUnit,
          format: (series.userOptions.custom as LineSeriesCustomOptions)?.format,
        });
      }
    }
    return '-';
  }, [series, point]);

  return <div style={{ flexShrink: 0, fontWeight: 600, justifySelf: 'flex-end', marginLeft: 'auto', fontSize: 13 }}>{formattedValue}</div>;
};

export default SeriesValue;
