import { v4 as uuidv4 } from 'uuid';

import { TimeRange } from '../types/timeRange';

import { getLocalStorageItem, setLocalStorageItem } from './localStorage';

export const getTimeRanges = (): TimeRange[] => {
  const history = getLocalStorageItem<TimeRange[]>('timeRangeHistory') ?? [];
  return history.map(e => ({ ...e, from: new Date(e.from), to: new Date(e.to) }));
};

export const saveTimeRanges = (history: TimeRange[]) => {
  setLocalStorageItem('timeRangeHistory', history);
};

export const addTimeRange = (timeRange: Omit<TimeRange, 'uuid'>): TimeRange[] => {
  const uuid = uuidv4();
  const oldHistory = getTimeRanges();
  const newHistory = [
    { ...timeRange, uuid },
    ...oldHistory
      .filter(period => period.from.getTime() !== timeRange.from.getTime() && period.to.getTime() !== timeRange.to.getTime())
      .slice(0, 100),
  ];
  saveTimeRanges(newHistory);
  return newHistory;
};

export const deleteTimeRange = (uuid: TimeRange['uuid']): TimeRange[] => {
  const oldHistory = getTimeRanges();
  const newHistory = oldHistory.filter(timeRange => timeRange.uuid !== uuid);
  saveTimeRanges(newHistory);
  return newHistory;
};

export const clearTimeRanges = (): void => {
  saveTimeRanges([]);
};
