import { OrderBy } from '@dametis/core';

import { ChangesOption } from 'components/Monitoring/ipseite/SiteTile/AdminFilters/AdminFilters';
import { StateIconVariant } from 'components/UI/StateIcon/StateIcon';
import { DamcoSubPeriodType } from 'store/slices/monitoring';
import { DataPeriod, SortBy } from 'store/slices/oldMonitoring';

export default {
  title: {
    page: 'Monitoring',
    box: 'Box : {{name}}',
    boxes: 'Boxes',
    devices: 'Devices',
    variables: 'Variables',
    detailsModal: '{{entities}} available',
    editBoxAlertThresholds: 'Edit box alert thresholds',
    editDeviceAlertThresholds: 'Edit device alert thresholds',
    editVariableAlertThresholds: 'Edit variable alert thresholds',
  },
  label: {
    sites: 'Sites',
    groups: 'Groups',
    boxes_one: 'Box',
    boxes_other: 'Boxes',
    devices: 'Devices',
    variables: 'Variables',
    hideUnchangedSites: 'Hide sites without changes',
    stateIconVariants: 'State',
    pending: 'pending',
    error_one: 'error',
    error_other: 'errors',
    connected_one: 'connected',
    connected_other: '{{count}} connected',
    disconnected_one: 'disconnected',
    disconnected_other: '{{count}} disconnected',
    stateIconVariant: {
      [StateIconVariant.ERROR]: 'Change to inactive',
      [StateIconVariant.WARNING]: '',
      [StateIconVariant.SUCCESS]: 'Change to active',
      [StateIconVariant.UNSET]: 'No change',
    },
    changesOptions: 'Last changes',
    changesOption: {
      [ChangesOption.DEVICES_NO]: 'No changes',
      [ChangesOption.DEVICES_MORE]: 'More',
      [ChangesOption.DEVICES_LESS]: 'Less',
      [ChangesOption.VARIABLES_NO]: 'No changes',
      [ChangesOption.VARIABLES_MORE]: 'More',
      [ChangesOption.VARIABLES_LESS]: 'Less',
    },
    dataPeriods: {
      [DataPeriod.ONE_DAY]: '1 day',
      [DataPeriod.THREE_DAYS]: '3 days',
      [DataPeriod.ONE_WEEK]: '1 week',
      [DataPeriod.TWO_WEEKS]: '2 weeks',
      [DataPeriod.ONE_MONTH]: '1 month',
    },
    orderBy: {
      [OrderBy.ASC]: 'Croissant',
      [OrderBy.DESC]: 'Décroissant',
    },
    sortedBy: {
      [SortBy.GROUP_NAME]: 'Group name',
      [SortBy.SITE_NAME]: 'Site name',
      [SortBy.NB_DEVICES_CHANGES]: 'Last changes length (devices)',
      [SortBy.NB_VARIABLES_CHANGES]: 'Last changes length (variables)',
      [SortBy.DATE_DEVICES_CHANGES]: 'Last changes date (devices)',
      [SortBy.DATE_VARIABLES_CHANGES]: 'Last changes date (variables)',
    },
    deviceState: 'Device',
    deviceLagging: 'Disconnected',
    deviceNotLagging: 'Connected',
    deviceNoData: 'No data',
    variableLag: 'Variables',
    variableLagging: 'Late',
    variableDataHole: 'Uptime',
    noVariables: 'No variables',
    lastValue: 'Last value',
  },
  text: {
    infrastructureState: 'Infrastructure state',
    allSelected: 'All selected',
    boxAvailability: 'Box availability',
    changes_zero: 'No change',
    changes_one: '{{count}} change',
    changes_other: '{{count}} changes',
    date: {
      from: 'from {{date}}',
      to: 'to {{date}}',
      complete: 'from {{from}} to {{to}}',
    },
    period: {
      forOneHour: 'for 1 hour',
      forFiveHours: 'for 5 hours',
      forTwelveHours: 'for 12 hours',
      forOneDay: 'for 1 day',
      forTwoDays: 'for 2 days',
      forOneWeek: 'for 1 week',
    },
    boxesDown: 'Boxes down',
    devicesDown: 'Devices down',
    variablesDown: 'Variables down',
    noValue: 'n/a',
    noRecentChanges: 'No recent changes.',
    variablesAvailability: {
      period: 'from {{from}} to {{to}}',
      value: '{{value}} variables available',
      noData: 'No data',
    },
    unknownSite: 'Unknown site',
    nothingToDisplay: 'No site to display. Try to change your filters settings.',
    fetchingSitesData: 'Fetching sites data...',
    neverConnected: 'Never connected',
    pending: 'Pending',
    upToDate: 'Up to date',
    subPeriodType: {
      [DamcoSubPeriodType.HOLE]: 'Hole',
      [DamcoSubPeriodType.LAG]: 'Lag',
    },
    inProgress: 'In progress...',
    received: 'Received',
    alertThreshold: 'Alert threshold',
    noBox: 'There is no box',
    noDevice: 'There is no device',
  },
  grid: {
    site: 'Site',
    group: 'Group',
    availability: 'Box availability',
    period: 'Data period',
  },
  tooltip: {
    handleNotification: 'handle notifications',
    launchArmageddon: 'launch armageddon',
    launchArmageddonOnDevice: 'launch armageddon on this device',
    accessLog: 'Access logs',
    editBoxAlertThresholds: 'Edit box alert thresholds',
    editDeviceAlertThresholds: 'Edit device alert thresholds',
    editVariableAlertThresholds: 'Edit variable alert thresholds',
  },
  button: {
    cancel: 'Cancel',
    save: 'Save',
    add: 'Add',
  },
  devicesGrid: {
    status: 'State',
    name: 'Name',
    availability: 'Availability',
    lastPointTime: 'Last Value',
    lateVariables: 'Late',
    defaultVariableDataDelay: 'Between two feedbacks',
    deviceHeartbeatFrequency: 'Between two runs',
    defaultVariableFrequency: 'Between two points',
    tooltip: {
      defaultVariableDataDelay: 'Expected frequency for data feedback',
      deviceHeartbeatFrequency: 'Expected frequency between two equipment runs',
      defaultVariableFrequency: 'Expected frequency between two points (by default)',
    },
  },
  variablesGrid: {
    lastLagState: 'State',
    name: 'Name',
    availability: 'Availability',
    lastPointValue: 'Last Value',
    lastPointDate: 'Last Date',
    delay: 'Delay',
    frequency: 'Frequency',
    variableFrequency: 'Between two points',
  },
  detailPanelGrid: {
    type: 'Type',
    from: 'From',
    to: 'To',
    duration: 'Duration',
  },
  prompt: {
    armageddon: "Armageddon mode deletes all monitoring data ; use this feature only if you're sure of what you're doing.",
  },
  input: {
    boxDataMaxDelay: 'Expected frequency between two box runs',
    defaultVariableDataDelay: 'Expected frequency for data feedback',
    deviceHeartbeatFrequency: 'Expected frequency between two equipment runs',
    defaultVariableFrequency: 'Expected frequency between two points (by default)',
    variableFrequency: 'Expected frequency between two points',
  },
};
