import { Clear } from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  FormControlProps as MuiFormControlProps,
  OutlinedInputProps as MuiOutlinedInputProps,
  OutlinedInput,
  Popover,
  Stack,
  Tooltip,
  Typography,
  drawerClasses,
} from '@mui/material';
import { FC, MouseEventHandler, ReactNode, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IsEmptyCalculation } from '@dametis/core';

import { createCalculationVariable } from 'functions/createCalculationVariable';

import CalculationSlate from '../UI/CalculationSlate/CalculationSlate';

import VncDialog from './VncDialog';
import HelperText from './components/VncInput/HelperText';
import Label from './components/VncInput/Label';
import { VncProps } from './types';

interface Props extends VncProps {
  open?: boolean;
  editing?: boolean;
  enableNickname?: boolean;
  label?: MuiOutlinedInputProps['label'];
  helperText?: ReactNode;
  error?: MuiOutlinedInputProps['error'];
  FormControlProps?: Omit<MuiFormControlProps, 'value' | 'onChange' | 'error'>;
  OutlinedInputProps?: Omit<MuiOutlinedInputProps, 'onChange' | 'variant' | 'label' | 'error'>;
}

const defaultFormControlProps: Props['FormControlProps'] = {};
const defaultOutlinedInputProps: Props['OutlinedInputProps'] = {};

const VncInput: FC<Props> = ({
  open = false,
  editing = true,
  enableNickname = false,
  label = undefined,
  helperText = undefined,
  error = false,
  FormControlProps = defaultFormControlProps,
  OutlinedInputProps = defaultOutlinedInputProps,
  ...vncDialogProps
}) => {
  const { value, onChange, unitPicker, disableMaths } = vncDialogProps;

  const { t } = useTranslation('vnc');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [popperOpen, setPopperOpen] = useState(false);

  const inputRef = useRef<HTMLDivElement>(null);

  const emptyCalculation = useMemo(() => createCalculationVariable(), []);
  const displayedUnit = useMemo(
    () => (unitPicker && value?.unit !== undefined && value.unit !== null && value.unit.trim().length > 0 ? value.unit.trim() : ''),
    [unitPicker, value?.unit],
  );
  const isEmptyCalculation = useMemo(() => IsEmptyCalculation(value), [value]);

  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const clearInput = useCallback<MouseEventHandler<HTMLButtonElement>>(
    e => {
      e.stopPropagation();
      onChange(emptyCalculation);
    },
    [onChange, emptyCalculation],
  );

  const closePopper = useCallback(() => {
    setPopperOpen(false);
  }, []);

  const boundingClientRect = inputRef.current?.getBoundingClientRect();
  const horizontal = (boundingClientRect?.left ?? 0) <= window.innerWidth - (boundingClientRect?.right ?? 0) ? 'left' : 'right';

  useEffect(() => {
    setDialogOpen(open);
  }, [open]);

  return (
    <>
      <FormControl
        fullWidth
        {...FormControlProps}
        sx={[
          { maxWidth: 1, '&:hover .popperButton': { visibility: 'visible' } },
          ...(Array.isArray(FormControlProps.sx) ? FormControlProps.sx : [FormControlProps.sx]),
        ]}
      >
        <Label
          horizontal={horizontal}
          isEmptyCalculation={isEmptyCalculation}
          label={label}
          popperOpen={popperOpen}
          setPopperOpen={setPopperOpen}
        />
        <div ref={inputRef}>
          {editing && (
            <OutlinedInput
              endAdornment={
                Boolean(displayedUnit.length) || !isEmptyCalculation ? (
                  <InputAdornment position="end">
                    <Stack alignItems="center">
                      {Boolean(displayedUnit.length) && <Box component="span">{displayedUnit}</Box>}
                      {!isEmptyCalculation && (
                        <Tooltip title={t('tooltip.clear')}>
                          <IconButton onClick={clearInput}>
                            <Clear />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </InputAdornment>
                ) : undefined
              }
              error={error}
              inputComponent={
                isEmptyCalculation
                  ? undefined
                  : // eslint-disable-next-line react/no-unstable-nested-components,react/display-name
                    forwardRef((props, ref) => (
                      <Box ref={ref} maxHeight={100} overflow="auto" p={editing ? 1 : undefined} width={1}>
                        <Box sx={{ width: 'max-content' }}>
                          <CalculationSlate calculation={value} />
                        </Box>
                      </Box>
                    ))
              }
              placeholder={
                OutlinedInputProps.placeholder ?? t('input.placeholder.input', { context: disableMaths ? 'disableMaths' : undefined })
              }
              value=""
              onClick={openDialog}
              {...OutlinedInputProps}
              sx={[
                {
                  position: 'relative',
                  width: 1,
                  zIndex: 1,
                  [`.${drawerClasses.paper} &`]: { zIndex: theme => theme.zIndex.drawer + 1 },
                },
                popperOpen && { opacity: 0 },
                ...(Array.isArray(OutlinedInputProps.sx) ? OutlinedInputProps.sx : [OutlinedInputProps.sx]),
              ]}
            />
          )}
          {!editing && !isEmptyCalculation && (
            <Box maxHeight={100} minWidth={150} overflow="auto" width={1}>
              <Box width="max-content">
                <CalculationSlate calculation={value} />
              </Box>
            </Box>
          )}
          {!editing && isEmptyCalculation && <Typography>{t('global:text.noValue')}</Typography>}
        </div>
        {editing && <HelperText enableNickname={enableNickname} helperText={helperText} value={value} onChange={onChange} />}
      </FormControl>
      <Popover
        disableRestoreFocus
        anchorEl={inputRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal,
        }}
        open={popperOpen}
        slotProps={{ paper: { sx: [{ borderRadius: 1 }, !editing && { m: -1, p: 1 }] } }}
        transformOrigin={{
          vertical: 'top',
          horizontal,
        }}
        TransitionProps={{ timeout: 0 }}
        onClose={closePopper}
      >
        {editing ? (
          <OutlinedInput
            error={error}
            inputComponent={
              isEmptyCalculation
                ? undefined
                : // eslint-disable-next-line react/display-name,react/no-unstable-nested-components
                  forwardRef((props, ref) => (
                    <Box ref={ref} minWidth={inputRef.current?.clientWidth ?? 150} overflow="auto" p={1} width={1}>
                      <Box maxWidth={1000}>
                        <CalculationSlate calculation={value} />
                      </Box>
                    </Box>
                  ))
            }
            placeholder={
              OutlinedInputProps.placeholder ?? t('input.placeholder.input', { context: disableMaths ? 'disableMaths' : undefined })
            }
            value=""
            onClick={openDialog}
            {...OutlinedInputProps}
            sx={[
              {
                position: 'relative',
                width: 1,
              },
            ]}
          />
        ) : (
          <Box minWidth={150} overflow="auto" width={1}>
            <Box maxWidth={1000}>
              <CalculationSlate calculation={value} />
            </Box>
          </Box>
        )}
      </Popover>
      <VncDialog {...vncDialogProps} open={dialogOpen} setOpen={setDialogOpen} />
    </>
  );
};

export default VncInput;
