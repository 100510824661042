import { Editor, Point, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { VariableElement } from '@dametis/core';

import { createEmptyText } from './text';

export const createVariableElement = (variable: VariableElement['variable']): VariableElement => ({
  type: 'variable',
  variable,
  children: [createEmptyText()],
});

export const insertVariableInSlate = (editor: Editor, variable: VariableElement['variable'], clearBeforeInsert: boolean) => {
  const variableElement = createVariableElement(variable);
  const { selection } = editor;
  if (clearBeforeInsert) {
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    });
  } else if (selection !== null && !Range.isCollapsed(selection)) {
    Transforms.delete(editor);
  } else if (selection !== null && !Point.equals(Editor.start(editor, selection.anchor.path), Editor.end(editor, selection.anchor.path))) {
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, selection.anchor.path),
        focus: Editor.end(editor, selection.anchor.path),
      },
    });
  }
  ReactEditor.focus(editor);
  Transforms.insertNodes(editor, variableElement);
  Transforms.move(editor);
};
