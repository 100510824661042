import { CreateFolderBody } from '@dametis/core';

export const createCreateFolderBody = ({
  parentUuid = '',
  name = '',
  shortcuts = [],
  ui = {},
}: Partial<CreateFolderBody> = {}): CreateFolderBody => ({
  parentUuid,
  name,
  shortcuts,
  ui,
});
