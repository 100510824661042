import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import { FC, useMemo } from 'react';

import { DamIconProp, DamIconType } from '../../../types/iconPicker';

interface Props {
  type: DamIconType;
  prop: DamIconProp;
  size?: number;
  color?: string;
  className?: string;
}

const DamIconComponent: FC<Props> = ({ type, prop, size = undefined, color = undefined, className = '' }) => {
  const style = useMemo(
    () => ({
      ...(size && { fontSize: size }),
      ...(color && { color }),
    }),
    [size, color],
  );

  if (type === DamIconType.MUI) return <SvgIcon className={className} component={prop as SvgIconComponent} style={style} />;
  if (type === DamIconType.STRING)
    return <img alt="damicon" className={className} height="24" src={prop as string} style={style} width="24" />;
  return null;
};

export default DamIconComponent;
