import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { Toast } from 'types/toast';

export interface ToastState {
  toastList: Toast[];
  // lastToast: Toast['id'];
}

const initialState: ToastState = {
  toastList: [],
  // lastToast: '',
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<Omit<Toast, 'id'>>) => {
      const newId = uuidv4();
      state.toastList = [...state.toastList, { ...action.payload, id: newId }];
      // state.lastToast = newId;
    },
    removeToast: (state, action: PayloadAction<Toast['id']>) => {
      state.toastList = state.toastList.filter(toast => toast.id !== action.payload);
    },
    clearToastStore: () => initialState,
  },
});

export const { addToast, removeToast, clearToastStore } = toastSlice.actions;

export default toastSlice.reducer;
