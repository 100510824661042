export default {
  title: {
    stats: 'Point insertion statistics',
  },
  label: {
    data: 'Data',
    previousData: 'Past',
    sync: 'Syncs',
    heartbeat: 'HeartBeats',
    unit: '/s',
    period: 'Period',
  },
};
