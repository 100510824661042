import { ThemeProvider, useTheme } from '@mui/material';
import type { TooltipOptions } from 'highcharts';
import { FC, useCallback, useEffect, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';

import { useDaChartContext } from '../DaChartContext';

import TooltipComponent from './TooltipComponent';
import { getTooltipOptions } from './tooltipOptions';

const tooltipOptionsDefaultProp: TooltipOptions = {};

export interface ChartTooltipProps {
  tooltipOptions?: TooltipOptions;
}

const ChartTooltip: FC<ChartTooltipProps> = ({ tooltipOptions = tooltipOptionsDefaultProp }) => {
  const theme = useTheme();

  const { highcharts } = useDaChartContext();

  const options = useMemo(() => getTooltipOptions(tooltipOptions), [tooltipOptions]);

  const initTooltip = useCallback(() => {
    if (!highcharts) return;
    highcharts.chart.update({
      tooltip: {
        enabled: true,
        outside: true,
        style: {
          zIndex: theme.zIndex.tooltip,
        },
        formatter() {
          return ReactDOMServer.renderToStaticMarkup(
            <ThemeProvider theme={theme}>
              <TooltipComponent {...this} />
            </ThemeProvider>,
          );
        },
        ...options,
      },
    });
  }, [highcharts, options, theme]);

  useEffect(() => {
    initTooltip();
  }, [initTooltip]);

  return <></>;
};

export default ChartTooltip;
