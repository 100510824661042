import { FiberManualRecord } from '@mui/icons-material';
import { SvgIconProps, keyframes, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';

export enum StateIconVariant {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  UNSET = 'unset',
}

export interface StateIconProps extends SvgIconProps {
  triggered?: boolean;
  variant?: `${StateIconVariant}`;
}

const StateIcon: FC<StateIconProps> = ({ triggered = false, variant = StateIconVariant.UNSET, sx, ...props }) => {
  const theme = useTheme();

  const color = useMemo(() => {
    switch (variant) {
      case StateIconVariant.SUCCESS:
        return theme.palette.picker.green;
      case StateIconVariant.WARNING:
        return theme.palette.picker.orange;
      case StateIconVariant.ERROR:
        return theme.palette.picker.red;
      case StateIconVariant.UNSET:
        return theme.palette.grey[500];
      default:
        return theme.palette.grey[500];
    }
  }, [theme, variant]);

  const pulse = useMemo(
    () =>
      keyframes({
        '0%': {
          boxShadow: `0 0 0 0 ${color}80`,
        },
        '70%': {
          boxShadow: `0 0 0 10px ${color}00`,
        },
        '100%': {
          boxShadow: `0 0 0 0 ${color}00`,
        },
      }),
    [color],
  );

  return (
    <FiberManualRecord
      {...props}
      sx={{
        width: 11,
        height: 11,
        borderRadius: '50%',
        color,
        ...(triggered && { animation: `${pulse} 2s infinite`, WebkitAnimation: `${pulse} 2s infinite` }),
        '& > *': {
          r: '12px',
        },
        ...sx,
      }}
    />
  );
};

export default StateIcon;
