export default {
  title: {
    backups: 'Backups',
    newBackup: 'New Backup',
    beforeRestoring: 'Are you sure?',
  },
  text: {
    createdAt: 'Created at',
    noBackup: 'No Backup',
  },
  input: {
    label: {
      name: 'name',
    },
  },
  button: {
    backup: 'Backup',
    cancel: 'Cancel',
    create: 'Create',
    restore: 'Yes, restore',
  },
  tooltip: {
    restore: 'Restore backup',
    delete: 'Delete backup',
  },
  toast: {
    createBackupSuccess: 'Successfully created backup.',
    createBackupError: 'An error occured creating a backup.',
    restoreBackupSuccess: 'Successfully restored backup.',
    restoreBackupError: 'An error occured restoring a backup.',
    deleteBackupSuccess: 'Successfully deleted backup',
    deleteBackupError: 'An error occured deleting a backup.',
    fetchBackupsError: 'An error occured fetching backups.',
  },
  columns: {
    name: 'Name',
    createdAt: 'Creation date',
    uuid: 'UUID',
  },
  search: 'Name, uuid ...',
};
