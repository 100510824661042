import { Chip, ChipProps, Tooltip, styled, svgIconClasses } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getColorFromValue, setColorLightness } from '../../../functions/color';
import { getFormattedValue } from '../UnitPicker/functions/getFormattedValue';

import { icons } from './icons';

interface Props extends ChipProps {
  covariance: number;
}

const CovarianceChip = styled(({ covariance, ...props }: Props) => {
  const { t } = useTranslation('global');
  const Icon = useMemo(() => icons[Math.min(Math.floor(covariance * (icons.length / 100)), icons.length - 1)], [covariance]);
  return (
    <Chip
      icon={
        <Tooltip title={t('covariance')}>
          <Icon />
        </Tooltip>
      }
      label={getFormattedValue({ value: covariance, baseUnit: '%' })}
      {...props}
    />
  );
})(({ covariance: value, disabled }) => {
  const color = setColorLightness(getColorFromValue({ value }), 35);
  return {
    backgroundColor: 'transparent',
    color,
    [`& .${svgIconClasses.root}`]: {
      color,
    },
    ...(disabled && { filter: 'grayscale(1)' }),
  };
});

export default CovarianceChip;
