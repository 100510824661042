import { RouteWithIndex } from 'types/drawer';

export interface NavbarState {
  isExtendedDrawer: boolean;
  hoveredRoute: RouteWithIndex | null;
  isTouch: boolean;
}

export const initialState: NavbarState = {
  isExtendedDrawer: false,
  hoveredRoute: null,
  isTouch: false,
};
