import { Chip, ChipProps, Tooltip, TooltipProps } from '@mui/material';
import { FC, useRef } from 'react';

import useIsEllipsed from 'hooks/useIsEllipsed';

export interface ChipEllipseProps extends ChipProps {
  label: string;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
}

const ChipEllipse: FC<ChipEllipseProps> = ({ label, tooltipProps = undefined, ...props }) => {
  const chipRef = useRef<HTMLDivElement>(null);

  const isEllipsed = useIsEllipsed(chipRef);

  return (
    <Tooltip title={isEllipsed ? label : ''} {...tooltipProps}>
      <Chip ref={chipRef} label={label} {...props} />
    </Tooltip>
  );
};

export default ChipEllipse;
