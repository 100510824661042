import { SearchOutlined } from '@mui/icons-material';
import { InputAdornment, InputBase, InputBaseProps, inputBaseClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const SearchInputBase = styled(
  ({
    startAdornment = (
      <InputAdornment position="start">
        <SearchOutlined color="disabled" />
      </InputAdornment>
    ),
    ...props
  }: InputBaseProps) => <InputBase startAdornment={startAdornment} {...props} />,
)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  alignSelf: 'center',
  transition: theme.transitions.create(['background-color']),
  [`&.${inputBaseClasses.focused}`]: {
    backgroundColor: theme.palette.grey[100],
  },
}));

export default SearchInputBase;
