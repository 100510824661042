import { Toolbar } from '@mui/material';
import { FC, MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RouteWithIndex } from 'types/drawer';
import { useLayoutStore } from 'zustand/stores/layout';

import { DrawerLabel } from './DrawerButton.styled';
import { SDCMarginTop, SDCTitle } from './SecondaryDrawerContents/styled';

export interface SecondaryDrawerContentProps {
  drawerButtonsContainerRef: MutableRefObject<HTMLDivElement | null>;
}

const SecondaryDrawerContent: FC<SecondaryDrawerContentProps> = ({ drawerButtonsContainerRef }) => {
  const { t } = useTranslation('navbar');

  const hoveredRoute = useLayoutStore(state => state.hoveredRoute);

  const [route, setRoute] = useState<RouteWithIndex | null>(null);
  const [drawerButtonsContainerScrollTop, setDrawerButtonsContainerScrollTop] = useState<number>(0);

  const handleDrawerButtonsContainerScroll = useCallback(() => {
    if (!drawerButtonsContainerRef.current) throw new Error();
    setDrawerButtonsContainerScrollTop(drawerButtonsContainerRef.current.scrollTop);
  }, [drawerButtonsContainerRef]);

  useEffect(() => {
    // Saving route in local state so there is always a route, and the component is never empty.
    if (!hoveredRoute) return;
    setRoute(hoveredRoute);
  }, [hoveredRoute]);

  useEffect(() => {
    const drawerButtonsContainer = drawerButtonsContainerRef.current;
    if (drawerButtonsContainer) {
      drawerButtonsContainer.addEventListener('scroll', handleDrawerButtonsContainerScroll);
    }
    return () => {
      if (drawerButtonsContainer) {
        drawerButtonsContainer.removeEventListener('scroll', handleDrawerButtonsContainerScroll);
      }
    };
  }, [drawerButtonsContainerRef, handleDrawerButtonsContainerScroll]);

  if (!route) return null;

  return (
    <>
      <Toolbar />
      <SDCMarginTop index={route.index} scrollTop={drawerButtonsContainerScrollTop}>
        <SDCTitle>
          <DrawerLabel>{t(`${route.name}.button`)}</DrawerLabel>
        </SDCTitle>
        {route.Secondary && <route.Secondary route={route} />}
      </SDCMarginTop>
    </>
  );
};

export default SecondaryDrawerContent;
