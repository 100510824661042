import { Components, Theme } from '@mui/material';

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    link: true;
  }
}

export default {
  styleOverrides: {
    root: ({ theme, ownerState: { color } }) => ({
      ...(color && color === 'link'
        ? {
            color: theme.palette.link.main,
            '&:hover': {
              backgroundColor: 'link.light',
            },
          }
        : {
            color: theme.palette.icon.main,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
            },
          }),
    }),
  },
} as Components<Theme>['MuiIconButton'];
