import { SvgIconComponent } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps, loadingButtonClasses } from '@mui/lab';
import { Box, SvgIcon, Tooltip, TooltipProps, buttonClasses, styled, svgIconClasses } from '@mui/material';
import { FC, forwardRef, useCallback, useState } from 'react';

import { setColorLightness } from 'functions/color';

import PromptBeforeDeleting, { PromptBeforeDeletingProps } from '../PromptBeforeDeleting/PromptBeforeDeleting';

interface Props extends Omit<LoadingButtonProps, 'children' | 'color' | 'size'> {
  text: string;
  icon: SvgIconComponent;
  size?: number;
  color?: 'default' | 'save' | 'delete' | 'warning';
  hidden?: boolean;
  deletePrompt?: boolean;
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
  stopPropagation?: boolean;
}

const LoadingButtonWithRef = forwardRef<HTMLButtonElement, Omit<LoadingButtonProps, 'color' | 'size'>>((props, ref) => (
  <LoadingButton {...props} ref={ref} />
));
LoadingButtonWithRef.displayName = 'LoadingButtonWithRef';

export const StyledLoadingButton = styled(LoadingButtonWithRef, {
  shouldForwardProp: propName => propName !== 'color' && propName !== 'size',
})<Pick<Props, 'color' | 'size'>>(({ theme, color = 'default', size = 36 }) => ({
  minWidth: 'unset',
  width: size,
  height: size,
  color: {
    default: theme.palette.primary.dark,
    save: theme.palette.secondary.dark,
    delete: theme.palette.error.dark,
    warning: theme.palette.warning.dark,
  }[color],
  borderRadius: theme.shape.borderRadius * 100,
  '&:hover': {
    backgroundColor: {
      default: setColorLightness(theme.palette.primary.main, 90),
      save: setColorLightness(theme.palette.secondary.main, 90),
      delete: setColorLightness(theme.palette.error.main, 90),
      warning: setColorLightness(theme.palette.warning.main, 90),
    }[color],
  },
  backgroundColor: {
    default: setColorLightness(theme.palette.primary.main, 95),
    save: setColorLightness(theme.palette.secondary.main, 95),
    delete: setColorLightness(theme.palette.error.main, 95),
    warning: setColorLightness(theme.palette.warning.main, 95),
  }[color],
  [`&.${buttonClasses.disabled}:not(.${loadingButtonClasses.loading})`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.disabled,
  },
  [`& > .${loadingButtonClasses.loadingIndicator}`]: {
    color: {
      default: theme.palette.primary.dark,
      save: theme.palette.secondary.dark,
      delete: theme.palette.error.dark,
      warning: theme.palette.warning.dark,
    }[color],
  },
  [`& .${svgIconClasses.root}`]: {
    fontSize: Math.round(size * 0.55),
  },
}));

const tooltipPropsDefaultProps = {};

const HeaderPrimaryRightAction: FC<Props> = ({
  text,
  color = 'default',
  icon,
  hidden = false,
  deletePrompt = false,
  onClick,
  tooltipProps = tooltipPropsDefaultProps,
  size = 36,
  stopPropagation = false,
  ...props
}) => {
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

  const handleMouseDown = useCallback<NonNullable<LoadingButtonProps['onMouseDown']>>(
    event => {
      if (stopPropagation) {
        event.stopPropagation();
      }
    },
    [stopPropagation],
  );

  const toggleDeleteDialog = useCallback(() => {
    setDeleteDialog(state => !state);
  }, []);

  return (
    <>
      {!hidden && (
        <>
          <Tooltip title={text} {...tooltipProps}>
            <Box display="inline-block" height="fit-content">
              <StyledLoadingButton
                aria-label={text}
                color={color}
                size={size}
                onClick={deletePrompt ? toggleDeleteDialog : onClick}
                onMouseDown={handleMouseDown}
                {...props}
              >
                <SvgIcon component={icon} />
              </StyledLoadingButton>
            </Box>
          </Tooltip>
          {deletePrompt && (
            <PromptBeforeDeleting
              open={deleteDialog}
              setOpen={toggleDeleteDialog}
              onDelete={onClick as PromptBeforeDeletingProps['onDelete']}
            />
          )}
        </>
      )}
    </>
  );
};

export default HeaderPrimaryRightAction;
