import { Theme } from '@mui/material';

import { USER_MENU_DIAMETER } from '../components/Layouts/OldHeader/UserMenu.styles';

export const RIGHT_PANEL_WIDTH = 288;
export const RIGHT_PANEL_LARGE_WIDTH = 368;
export const RIGHT_PANEL_MIN_WIDTH = 200;
export const RIGHT_PANEL_MAX_WIDTH = 600;
export const RIGHT_PANEL_EXTRA_LARGE_WIDTH = 600;

export const others: Theme['others'] = {
  headerHeight: '64px',
  footerHeight: '10rem',
  pagePadding: `16px calc(${USER_MENU_DIAMETER} + 1rem + 24px) 64px`,
  pagePaddingTop: '16px',
  pagePaddingRight: `calc(${USER_MENU_DIAMETER} + 1rem + 24px)`,
  pagePaddingBottom: '64px',
  pagePaddingLeft: `calc(${USER_MENU_DIAMETER} + 1rem + 24px)`,
  navBarWidthClosed: '88px',
  navBarWidthOpen: '240px',
  sidePanelWidth: '26rem',
  rightPanelWidth: `${RIGHT_PANEL_WIDTH}px`,
  largeRightPanelWidth: `${RIGHT_PANEL_LARGE_WIDTH}px`,
  minRightPanelWidth: `${RIGHT_PANEL_MIN_WIDTH}px`,
  maxRightPanelWidth: `${RIGHT_PANEL_MAX_WIDTH}px`,
  extraLargeRightPanelWidth: `${RIGHT_PANEL_EXTRA_LARGE_WIDTH}px`,
  pageSizeNormal: '1300px',
  pageSizeLarge: '1600px',
  paperBorderRadius: '8px',
};
