import { TFunction } from 'i18next';

import { Format, IsDametisFormat, IsEcma376Format } from '@dametis/core';

export const formatNameFromFormat = (format: Format, t: TFunction) => {
  if (IsEcma376Format(format)) {
    return format.code;
  }
  if (IsDametisFormat(format)) {
    return format.name;
  }
  return t('text.unknownFormat');
};
