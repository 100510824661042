import type {} from '@mui/system';

export * from './alarm';
export * from './data';
export * from './device';
export * from './playground';
export * from './user';
export * from './physicalQuantities';
export * from './variable';
export * from './phoneNumber';
export * from './toast';

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[] ? RecursivePartial<U>[] : T[P] extends object ? RecursivePartial<T[P]> : T[P];
};

export type RequiredKeys<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export type OnlyOptionalKeys<T> = Required<{
  [K in keyof T as undefined extends T[K] ? K : never]: T[K];
}>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const exhaustiveCheck = (_: never): void => {
  //
};
