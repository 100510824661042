import { FormatListBulletedOutlined, HistoryOutlined, SettingsOutlined } from '@mui/icons-material';
import { Stack, ToggleButtonGroup, Tooltip } from '@mui/material';
import { ChangeEventHandler, Dispatch, FC, MouseEvent, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormatPickerMenu } from 'types/format';

import SmallToggleButton from '../SmallToggleButton/SmallToggleButton';
import PopoverInput from '../UnitPicker/styled/PopoverInput';

import { useFormatPickerContext } from './FormatPickerContext';

export interface FormatInputProps {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

const FormatInput: FC<FormatInputProps> = ({ value, setValue }) => {
  const { t } = useTranslation('format');

  const { selectedMenu, setSelectedMenu } = useFormatPickerContext();

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setValue(event.target.value);
    },
    [setValue],
  );

  const handleSelectMenu = useCallback(
    (event: MouseEvent<HTMLElement>, newMenu: FormatPickerMenu | null) => {
      if (newMenu) {
        setSelectedMenu(newMenu);
      }
    },
    [setSelectedMenu],
  );

  return (
    <Stack alignItems="center" direction="row" p={1} spacing={1} sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}>
      <PopoverInput autoFocus fullWidth value={value} onChange={handleChange} />
      <ToggleButtonGroup exclusive size="small" value={selectedMenu} onChange={handleSelectMenu}>
        <SmallToggleButton value={FormatPickerMenu.HISTORY}>
          <Tooltip placement="top" title={t(`tooltip.menu.${FormatPickerMenu.HISTORY}`)}>
            <HistoryOutlined fontSize="small" />
          </Tooltip>
        </SmallToggleButton>
        <SmallToggleButton value={FormatPickerMenu.LISTS}>
          <Tooltip placement="top" title={t(`tooltip.menu.${FormatPickerMenu.LISTS}`)}>
            <FormatListBulletedOutlined fontSize="small" />
          </Tooltip>
        </SmallToggleButton>
        <SmallToggleButton value={FormatPickerMenu.SETTINGS}>
          <Tooltip placement="top" title={t(`tooltip.menu.${FormatPickerMenu.SETTINGS}`)}>
            <SettingsOutlined fontSize="small" />
          </Tooltip>
        </SmallToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default FormatInput;
