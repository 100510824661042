import { Popover, PopoverProps } from '@mui/material';
import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { FUNCTION_HOTKEY } from './FunctionsButton';
import FunctionsPaper from './FunctionsPaper';

interface Props {
  anchorEl: PopoverProps['anchorEl'];
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const FunctionsPopover: FC<Props> = ({ anchorEl, open, setOpen }) => {
  useHotkeys(
    FUNCTION_HOTKEY,
    () => {
      setOpen(true);
    },
    { preventDefault: true, enableOnContentEditable: true, enableOnFormTags: true },
    [setOpen],
  );

  const toggleOpen = useCallback(() => {
    setOpen(prev => !prev);
  }, [setOpen]);

  return (
    <Popover anchorEl={anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={open} onClose={toggleOpen}>
      <FunctionsPaper setOpen={setOpen} />
    </Popover>
  );
};

export default FunctionsPopover;
