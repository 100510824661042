import { LinearProgress, linearProgressClasses, styled } from '@mui/material';

import { setColorLightness } from 'functions/color';

const BlockElementLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: value && value < 100 ? setColorLightness(theme.palette.warning.main, 75) : theme.palette.success.light,
  [`& .${linearProgressClasses.bar1Determinate}`]: {
    backgroundColor: value && value < 100 ? setColorLightness(theme.palette.warning.main, 50) : theme.palette.success.light,
  },
}));

export default BlockElementLinearProgress;
