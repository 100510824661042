import { Box, styled } from '@mui/material';

const RightPanelFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  width: '100%',
  margin: `${theme.spacing(2)} 0`,
  padding: `0 ${theme.spacing(2)}`,
  flexShrink: 0,
  flexGrow: 0,
  justifyContent: 'flex-end',
}));

export default RightPanelFooter;
