import { Box, List } from '@mui/material';
import { FC, MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { NanoEntityInfo, UUID } from '@dametis/core';

import { useDispatch } from 'store';
import { LastEntityType, RouteScope, RouteWithIndex } from 'types/drawer';

import { removeLastEntity } from '../hooks';

import SubRoute, { RouteItem } from './SubRoute';

interface Props {
  route: RouteWithIndex;
  entities: NanoEntityInfo[];
  type: LastEntityType;
  baseUrl: string;
}

const LastEntities: FC<Props> = ({ route, entities, type, baseUrl }) => {
  const { t } = useTranslation('navbar');

  const dispatch = useDispatch();

  const getUrl = useCallback(
    (entityUuid: UUID) => {
      switch (type) {
        default:
          return `${baseUrl}/${entityUuid}`;
      }
    },
    [type, baseUrl],
  );

  const handleDeleteEntity = useCallback(
    (entityUuid: UUID): MouseEventHandler<HTMLButtonElement> =>
      event => {
        event.stopPropagation();
        event.preventDefault();
        dispatch(removeLastEntity(type, entityUuid));
      },
    [dispatch, type],
  );

  return (
    <Box sx={theme => ({ pt: theme.others.pagePaddingTop, pb: theme.others.pagePaddingBottom })}>
      <List>
        <RouteItem disabled to="">
          {t('menu.item.mostRecent', { count: entities.length })}
        </RouteItem>
        {entities.slice(0, 5).map(entity => (
          <SubRoute
            key={entity.uuid}
            route={route}
            subRoute={{ name: entity.name, url: getUrl(entity.uuid), scopes: Object.values(RouteScope) }}
            onDelete={handleDeleteEntity(entity.uuid)}
          />
        ))}
      </List>
    </Box>
  );
};

export default LastEntities;
