import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum EntityTitleCategory {
  PLAYGROUND = 'playground',
  PROJECT = 'project',
  REPORT = 'report',
  ALARM = 'alarm',
  SYNOPTIC = 'synoptic',
  MODEL = 'model',
}

export interface UseEntityTitleOptions {
  category?: `${EntityTitleCategory}`;
}

const DEFAULT_USE_TITLE_OPTIONS: UseEntityTitleOptions = {
  category: undefined,
};

const useEntityTitle = (name: string | undefined, options: UseEntityTitleOptions = DEFAULT_USE_TITLE_OPTIONS) => {
  const { t } = useTranslation('global');

  const composedTitle = useMemo(
    () => (name ? t('text.pageTitleEntity', { name, context: options?.category }) : null),
    [name, t, options?.category],
  );

  useEffect(() => {
    if (composedTitle && document.title !== composedTitle) {
      document.title = composedTitle;
    }
  }, [composedTitle]);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default typeof document !== 'undefined' ? useEntityTitle : (_title: string | undefined) => {};
