import { Box } from '@mui/material';
import { FC } from 'react';

import { useRainbowColor } from './config/rainbow';

interface Props {
  kind: 'X' | 'Y';
  index?: number;
}

const ModelVariableSpan: FC<Props> = ({ kind, index = undefined }) => {
  const color = useRainbowColor(index);
  return (
    <Box color={color} component="span" lineHeight="inherit">
      {kind}
      {index !== undefined && (
        <Box component="sub" display="inline-block" fontSize="0.6em" height={0} width="3px">
          {index + 1}
        </Box>
      )}
    </Box>
  );
};

export default ModelVariableSpan;
