import { Components, Theme } from '@mui/material';

export default {
  styleOverrides: {
    root: ({ theme, ownerState }) =>
      ownerState.name?.includes('outlined')
        ? {
            border: `2px solid ${ownerState.checked ? theme.palette.grey['200'] : 'transparent'}`,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(1),
            marginTop: theme.spacing(0.5),
            '&:first-of-type': {
              marginTop: 0,
            },
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            '&:hover': {
              border: `2px solid ${theme.palette.grey['100']}`,
            },
          }
        : undefined,
  },
} as Components<Theme>['MuiFormControlLabel'];
