import { Energies, Operator, PhysicalQuantity, ProtocolKind, Timestamp, VarCalcKinds } from '@dametis/core';

import { EntityTitleCategory } from 'hooks/useEntityTitle';

const energy: Record<Energies, string> = {
  [Energies.WATER]: 'Eau',
  [Energies.ELECTRICITY]: 'Électricité',
  [Energies.FUEL]: 'Fioul',
  [Energies.GAS]: 'Gaz',
  [Energies.PRODUCT]: 'Produit',
  [Energies.OTHER]: 'Autre',
  [Energies.AIR]: 'Air',
  [Energies.COOLANT_FLUID]: 'Fluide de refroidissement',
  [Energies.COLD]: 'Froid',
  [Energies.STEAM]: 'Vapeur',
  [Energies.COMPRESSED_AIR]: 'Air comprimé',
};

const variableType: Record<VarCalcKinds, string> = {
  dataVariable: 'Variable configurée',
  pointsVariable: 'Variable de points',
  batchVariable: 'Variable de batch',
  blockVariable: 'Variable de bloc',
  calculationVariable: 'Calcul',
  blockTypeMetricVariable: 'Variable de métrique de type de bloc',
  blockTypeParameterVariable: 'Variable de paramètre de type de bloc',
  modelVariable: 'Variable de modèle',
  alarmVariable: "Variable d'alarme",
  aliasVariable: "Variable d'alias",
};

const timestamp: Record<Timestamp, string> = {
  [Timestamp.START]: 'Début',
  [Timestamp.MIDDLE]: 'Milieu',
  [Timestamp.END]: 'Fin',
  [Timestamp.KEEP]: 'Garder',
};

export default {
  text: {
    pageTitleEntity: '{{name}} - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.ALARM}`]: '{{name}} - Alarme - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.PLAYGROUND}`]: '{{name}} - Playground - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.PROJECT}`]: '{{name}} - Projet - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.REPORT}`]: '{{name}} - Rapport - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.SYNOPTIC}`]: '{{name}} - Synoptique - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.MODEL}`]: '{{name}} - Modèle - Dametis',
    loading: 'Chargement',
    noValue: 'n/a',
    loadingValue: '...',
    noElement: 'Aucun élément',
    unknownVariable: 'Variable inconnue',
    unknownBlock: 'Bloc inconnu',
    unknownModel: 'Modèle inconnu',
    unknownBatch: 'Batch inconnu',
    subtitle: {
      createdAt: 'Créé {{date}}',
      createdAtFeminine: 'Créée {{date}}',
      createdAtBy: 'Créé {{date}} par {{owner}}',
      createdAtByFeminine: 'Créée {{date}} par {{owner}}',
      createdBy: 'Créé par {{owner}}',
      createdByFeminine: 'Créée par {{owner}}',
      updatedAt: 'Dernière modification {{date}}',
    },
    health: {
      title: 'État de santé',
      lastChecked: 'Dernier contrôle effectué {{duration}}.',
      systemsHealthy: 'Tous les systèmes sont opérationnels.',
      systemsUnhealthy: 'Certains systèmes ne sont pas opérationnels.',
      details: 'Détails',
    },
  },
  number: {
    toLocale: '{{value, localized}}',
  },
  unit: {
    input: {
      label: 'Unité',
      placeholder: 'Aucune',
    },
    time_millisecond_one: 'milliseconde',
    time_millisecond_other: 'millisecondes',
    time_second_one: 'seconde',
    time_second_other: 'secondes',
    time_minute_one: 'minute',
    time_minute_other: 'minutes',
    time_hour_one: 'heure',
    time_hour_other: 'heures',
    time_day_one: 'jour',
    time_day_other: 'jours',
    time_week_one: 'semaine',
    time_week_other: 'semaines',
    time_month_one: 'mois',
    time_month_other: 'mois',
    time_year_one: 'an',
    time_year_other: 'ans',
    time_millisecond_short_one: 'ms',
    time_millisecond_short_other: 'ms',
    time_second_short_one: 's',
    time_second_short_other: 's',
    time_minute_short_one: 'm',
    time_minute_short_other: 'm',
    time_hour_short_one: 'h',
    time_hour_short_other: 'h',
    time_day_short_one: 'j',
    time_day_short_other: 'j',
    time_week_short_one: 'sem',
    time_week_short_other: 'sem',
    time_month_short_one: 'mo',
    time_month_short_other: 'mo',
    time_year_short_one: 'a',
    time_year_short_other: 'a',
  },
  date: {
    month: {
      january: 'janvier',
      february: 'février',
      march: 'mars',
      april: 'avril',
      may: 'mai',
      june: 'juin',
      july: 'juillet',
      august: 'août',
      september: 'septembre',
      october: 'octobre',
      november: 'novembre',
      december: 'décembre',
      january_short: 'janv.',
      february_short: 'févr.',
      march_short: 'mars',
      april_short: 'avr.',
      may_short: 'mai',
      june_short: 'juin',
      july_short: 'juill.',
      august_short: 'août',
      september_short: 'sept.',
      october_short: 'oct.',
      november_short: 'nov.',
      december_short: 'déc.',
    },
    at: ' à ',
  },
  physicalQuantity: {
    pQ_none: 'aucune',
    pQ_other: 'autre',
    pQ_force: 'force',
    pQ_pressure: 'pression',
    pQ_energy: 'énergie',
    pQ_specificEnergy: 'énergie spécifique',
    pQ_power: 'puissance',
    pQ_temperature: 'température',
    pQ_speed: 'vitesse',
    pQ_acceleration: 'accélération',
    pQ_density: 'densité',
    pQ_massDensity: 'masse volumique',
    pQ_time: 'temps',
    pQ_mass: 'masse',
    pQ_volume: 'volume',
    pQ_flow: 'débit',
    pQ_dewPoint: 'point de rosée',
    pQ_humidity: 'humidité',
    pQ_thermalConductivity: 'conductivité thermale',
    pQ_voltage: 'tension',
    pQ_current: 'intensité',
    pQ_frequency: 'fréquence',
    pQ_length: 'longueur',
    pQ_surface: 'surface',
    pQ_currency: 'monnaie',
    pQ_fileSize: 'taille de fichier',
    pQ_viscosity: 'viscosité',
  } as Record<`pQ_${PhysicalQuantity}`, string>,
  operator: {
    op_MIN: 'min',
    op_MAX: 'max',
    op_FIRST: 'première valeur',
    op_LAST: 'dernière valeur',
    op_INDEX: 'index',
    op_HISTORY: 'valeur', // 'historique',
    op_MEAN: 'moyenne',
    op_ARITHMETIC_MEAN: 'moyenne arithmétique',
    op_DELTA: 'delta',
    op_SELECT: 'valeur',
    op_INTEGRAL: 'intégrale',
    op_TRAPEZOIDAL_INTEGRAL: 'intégrale trapèze',
    op_LEFT_RECTANGLE_INTEGRAL: 'intégrale rectangle à gauche',
    op_DERIVATIVE: 'dérivée',
    op_STDDEV: 'écart type',
    op_GAP: 'écart',
    op_VARIANCE: 'variance',
    op_MOVING_AVERAGE: 'moyenne mobile',
    op_SUM: 'somme',
    op_TIME_WEIGHTED_MEAN: 'moyenne pondérée temporelle',
    op_COUNT: 'compteur',
    op_DISTINCT: 'distinct',
    op_TIME: 'temps',
    op_GROUP_BY_DURATION: 'durée de période',
    op_LINEAR_CADENCE: 'cadence linéaire',
    opShort_MIN: 'min',
    opShort_MAX: 'max',
    opShort_FIRST: 'prem',
    opShort_LAST: 'der',
    opShort_INDEX: 'index',
    opShort_HISTORY: 'val',
    opShort_MEAN: 'moy',
    opShort_ARITHMETIC_MEAN: 'moyar',
    opShort_DELTA: 'delta',
    opShort_SELECT: 'val',
    opShort_INTEGRAL: 'intég',
    opShort_LEFT_RECTANGLE_INTEGRAL: 'rint',
    opShort_TRAPEZOIDAL_INTEGRAL: 'tint',
    opShort_DERIVATIVE: 'dériv',
    opShort_STDDEV: 'étype',
    opShort_GAP: 'écart',
    opShort_VARIANCE: 'var',
    opShort_MOVING_AVERAGE: 'moym',
    opShort_SUM: 'somme',
    opShort_TIME_WEIGHTED_MEAN: 'mpt',
    opShort_COUNT: 'compt',
    opShort_DISTINCT: 'dist',
    opShort_TIME: 'temps',
    opShort_GROUP_BY_DURATION: 'dur',
    opShort_LINEAR_CADENCE: 'linéaire',
  } as Record<`op_${Operator}` | `opShort_${Operator}`, string>,
  button: {
    add: 'Ajouter',
    edit: 'Modifier',
    delete: 'Supprimer',
    regenerate: 'Regénérer',
    save: 'Enregistrer',
    cancel: 'Annuler',
    disable: 'Désactiver',
    enable: 'Activer',
    duplicate: 'Dupliquer',
  },
  tooltip: {
    moreOptions: "Plus d'options",
    changeCountry: 'Changer de pays',
    edit: 'Modifier',
    delete: 'Supprimer',
    add: 'Ajouter',
    hide: 'Cacher',
    show: 'Afficher',
    return: 'Retour',
    noRight: 'Vous ne disposez pas des droits pour ',
    editMin: 'modifier ',
    deleteMin: 'supprimer ',
    addMin: 'ajouter ',
    duplicateMin: 'dupliquer ',
    disableMin: 'désactiver ',
    enableMin: 'activer ',
    regenerateMin: 'Regénérer ',
    back: 'Retour',
    close: 'Fermer',
    rename: 'renommer',
    cancel: 'annuler',
    submit: 'valider',
    details: 'Voir le détail',
    refresh: 'Rafraîchir',
    learnMore: 'En savoir plus',
  },
  protocol: {
    modbusTcp: 'Modbus TCP',
    s7: 'Siemens S7',
    opcUa: 'OPC UA',
    opcDa: 'OPC DA',
    sql: 'SQL',
    http: 'HTTP',
    file: 'Fichier',
    bacnet: 'BACnet',
    mqtt: 'MQTT',
    custom: 'Personnalisé',
  } as Record<ProtocolKind, string>,
  covariance: "Indice d'influence",
  energy,
  variableType,
  timestamp,
};
