import { Dexie, Table } from 'dexie';

import { HistoryItem } from '../../../types';

class VncDB extends Dexie {
  history!: Table<HistoryItem>;

  public constructor() {
    super('VncDB');
    this.version(1).stores({
      history: '&id, slate, [group+site], createdAt',
    });
  }
}

export const db = new VncDB();
