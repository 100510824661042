import { PhysicalQuantity } from '@dametis/core';
import { UnitName } from '@dametis/unit';

import { UnitPickerMenu } from 'components/UI/UnitPicker/types';

const unitNames: Record<UnitName, string> = {
  [UnitName.GRAM]: 'gram',
  [UnitName.TONNE]: 'tonne',
  [UnitName.POUND_WEIGHT]: 'pound',
  [UnitName.OUNCE]: 'ounce',
  [UnitName.GRAM_PER_CUBIC_METER]: 'gram per cubic meter',
  [UnitName.GRAM_PER_NORMAL_CUBIC_METER]: 'gram per normal cubic meter',
  [UnitName.METER]: 'meter',
  [UnitName.MILES]: 'mile',
  [UnitName.INCH]: 'inch',
  [UnitName.SQUARE_METER]: 'square meter',
  [UnitName.HECTAR]: 'hectar',
  [UnitName.CELSIUS]: 'celsius',
  [UnitName.KELVIN]: 'kelvin',
  [UnitName.FAHRENHEIT]: 'fahrenheit',
  [UnitName.CUBIC_METER]: 'cubic meter',
  [UnitName.LITRE]: 'liter',
  [UnitName.NORMAL_CUBIC_METER]: 'normal cubic meter',
  [UnitName.GALLON_US]: 'U.S. Gallon',
  [UnitName.GALLON_IMPERIAL]: 'imperial Gallon',
  [UnitName.BAR]: 'bar',
  [UnitName.PASCAL]: 'pascal',
  [UnitName.PSI]: 'psi',
  [UnitName.LITRE_PER_SECOND]: 'liter per second',
  [UnitName.LITRE_PER_MINUTE]: 'liter per minute',
  [UnitName.LITRE_PER_HOUR]: 'liter per hour',
  [UnitName.CUBIC_METER_PER_SECOND]: 'cubic meter per second',
  [UnitName.CUBIC_METER_PER_MINUTE]: 'cubic meter per minute',
  [UnitName.CUBIC_METER_PER_HOUR]: 'cubic meter per hour',
  [UnitName.NORMAL_CUBIC_METER_PER_HOUR]: 'normal cubic meter per hour',
  [UnitName.TON_PER_HOUR]: 'ton per hour',
  [UnitName.GRAM_PER_HOUR]: 'gram per hour',
  [UnitName.METER_PER_SECOND]: 'meter per hour',
  [UnitName.METER_PER_HOUR]: 'meter per hour',
  [UnitName.MILES_PER_HOUR]: 'mile per hour',
  [UnitName.SECOND]: 'second',
  [UnitName.MINUTE]: 'minute',
  [UnitName.HOUR]: 'hour',
  [UnitName.DAY]: 'day',
  [UnitName.WEEK]: 'week',
  [UnitName.AMPERE]: 'ampere',
  [UnitName.VOLT]: 'volt',
  [UnitName.VOLT_AMPERE]: 'volt ampere',
  [UnitName.HERTZ]: 'hertz',
  [UnitName.WATT]: 'watt',
  [UnitName.HOT_WATT]: 'hot-watt',
  [UnitName.COLD_WATT]: 'cold-watt',
  [UnitName.ELEC_WATT]: 'elec-watt',
  [UnitName.JOULE_PER_HOUR]: 'joule per hour',
  [UnitName.VOLT_AMPERE_REACTIVE]: 'volt amps reactive',
  [UnitName.VOLT_AMPERE_REACTIVE_HOUR_POSITIVE]: 'positive volt amps reactive',
  [UnitName.VOLT_AMPERE_REACTIVE_HOUR_NEGATIVE]: 'negative volt amps reactive',
  [UnitName.HORSE_POWER]: 'horse power',
  [UnitName.TON_OF_REFRIGERATION]: 'ton of refrigeration',
  [UnitName.BRITISH_THERMAL_UNIT_PER_HOUR]: 'british thermal unit per hour',
  [UnitName.WATT_HOUR]: 'watthour',
  [UnitName.HOT_WATT_HOUR]: 'hot-watthour',
  [UnitName.COLD_WATT_HOUR]: 'cold-watthour',
  [UnitName.ELEC_WATT_HOUR]: 'elec-watthour',
  [UnitName.VOLT_AMPERE_HOUR]: 'volt amp-hour',
  [UnitName.WATT_HOUR_PCI]: 'watthour PCI',
  [UnitName.WATT_HOUR_PCS]: 'watthour PCS',
  [UnitName.VOLT_AMPERE_REACTIVE_HOUR]: 'volt amps reactive hour',
  [UnitName.JOULE]: 'joule',
  [UnitName.BRITISH_THERMAL_UNIT]: 'british thermal unit',
  [UnitName.CALORIE]: 'calorie',
  [UnitName.JOULE_PER_KILOGRAM]: 'joule per kilogram',
  [UnitName.ROTATION_PER_MINUTE]: 'rotation per minute',
  [UnitName.PERCENT]: 'percent',
  [UnitName.EURO]: 'euro',
  [UnitName.DOLLAR]: 'dollar',
  [UnitName.LIVRE]: 'pound',
  [UnitName.ENERGY_PER_NORMAL_CUBIC_METER]: 'energy per normal cubic meter',
  [UnitName.NORMAL_CUBIC_METER_PER_ENERGY]: 'normal cubic meter per energy',
  [UnitName.KILO_GRAM_CO2]: 'kilogram of CO2',
  [UnitName.TONNE_CO2]: 'tonne of CO2',
  [UnitName.KILO_GRAM_CO2_PER_KILO_WATT_HOUR]: 'kilogram of CO2 per kilowatthour',
  [UnitName.KILO_GRAM_CO2_PER_KILO_HOT_WATT_HOUR]: 'kilogram of CO2 per kilohot-watthour',
  [UnitName.KILO_GRAM_CO2_PER_KILO_COLD_WATT_HOUR]: 'kilogram of CO2 per kilocold-watthour',
  [UnitName.KILO_GRAM_CO2_PER_KILO_ELEC_WATT_HOUR]: 'kilogram of CO2 per kiloelec-watthour',
  [UnitName.TONNE_CO2_PER_KILO_WATT_HOUR]: 'tonne of CO2 per kilowatthour',
  [UnitName.TONNE_CO2_PER_KILO_HOT_WATT_HOUR]: 'tonne of CO2 per kilohot-watthour',
  [UnitName.TONNE_CO2_PER_KILO_COLD_WATT_HOUR]: 'tonne of CO2 per kilocold-watthour',
  [UnitName.TONNE_CO2_PER_KILO_ELEC_WATT_HOUR]: 'tonne of CO2 per kiloelec-watthour',
  [UnitName.TONNE_OF_OIL_EQUIVALENT]: 'tonne of oil equivalent',
  [UnitName.STOKE]: 'stokes',
  [UnitName.EURO_PER_MEGA_WATT_HOUR]: 'euro per megawatthour',
  [UnitName.EURO_PER_MEGA_HOT_WATT_HOUR]: 'euro per megahot-watthour',
  [UnitName.EURO_PER_MEGA_COLD_WATT_HOUR]: 'euro per megacold-watthour',
  [UnitName.EURO_PER_MEGA_ELEC_WATT_HOUR]: 'euro per megaelec-watthour',
  [UnitName.EURO_PER_KILO_WATT_HOUR]: 'euro per kilowatthour',
  [UnitName.EURO_PER_KILO_HOT_WATT_HOUR]: 'euro per kilohot-watthour',
  [UnitName.EURO_PER_KILO_COLD_WATT_HOUR]: 'euro per kilocold-watthour',
  [UnitName.EURO_PER_KILO_ELEC_WATT_HOUR]: 'euro per kiloelec-watthour',
  [UnitName.DOLLAR_PER_MEGA_WATT_HOUR]: 'dollar per megawatthour',
  [UnitName.DOLLAR_PER_MEGA_HOT_WATT_HOUR]: 'dollar per megahot-watthour',
  [UnitName.DOLLAR_PER_MEGA_COLD_WATT_HOUR]: 'dollar per megacold-watthour',
  [UnitName.DOLLAR_PER_MEGA_ELEC_WATT_HOUR]: 'dollar per megaelec-watthour',
  [UnitName.DOLLAR_PER_KILO_WATT_HOUR]: 'dollar per kilowatthour',
  [UnitName.DOLLAR_PER_KILO_HOT_WATT_HOUR]: 'dollar per kilohot-watthour',
  [UnitName.DOLLAR_PER_KILO_COLD_WATT_HOUR]: 'dollar per kilocold-watthour',
  [UnitName.DOLLAR_PER_KILO_ELEC_WATT_HOUR]: 'dollar per kiloelec-watthour',
  [UnitName.BYTE_EN]: 'byte',
  [UnitName.BYTE_FR]: 'byte',
  [UnitName.GRAM_PER_LITRE]: 'gram per liter',
  [UnitName.SIEMENS]: 'siemens',
  [UnitName.SIEMENS_PER_CENTIMETER]: 'siemens per centimeter',
  [UnitName.NO_UNIT]: '',
  // [UnitName.NO_UNIT]: 'Without unit',
};

export default {
  text: {
    baseUnit: 'Base unit : {{unit}}',
    newOption: 'Custom unit : "{{value}}"',
    noUnit: 'No unit',
    noUnitElement: 'Without unit',
    unitUndefined: 'Unit undefined',
    unitUndefinedWithBase: '{{base}} (default)',
    inputLabel: 'Unit',
  },
  tooltip: {
    menu: {
      [UnitPickerMenu.LIST]: 'By unit',
      [UnitPickerMenu.COLUMNS]: 'By physical quantity',
    },
    clear: 'Clear',
  },
  unit: unitNames,
  physicalQuantity: {
    [PhysicalQuantity.NONE]: 'none',
    [PhysicalQuantity.OTHER]: 'other',
    [PhysicalQuantity.PRESSURE]: 'pressure',
    [PhysicalQuantity.ENERGY]: 'energy',
    [PhysicalQuantity.SPECIFIC_ENERGY]: 'specific energy',
    [PhysicalQuantity.POWER]: 'power',
    [PhysicalQuantity.TEMPERATURE]: 'temperature',
    [PhysicalQuantity.SPEED]: 'speed',
    [PhysicalQuantity.ACCELERATION]: 'acceleration',
    [PhysicalQuantity.DENSITY]: 'density',
    // [PhysicalQuantity.MASS_DENSITY]: '',
    [PhysicalQuantity.TIME]: 'time',
    [PhysicalQuantity.MASS]: 'mass',
    [PhysicalQuantity.VOLUME]: 'volume',
    [PhysicalQuantity.FLOW]: 'flow',
    [PhysicalQuantity.LENGTH]: 'length',
    [PhysicalQuantity.SURFACE]: 'surface',
    [PhysicalQuantity.FORCE]: 'force',
    [PhysicalQuantity.DEW_POINT]: 'dew point',
    [PhysicalQuantity.HUMIDITY]: 'humidity',
    [PhysicalQuantity.THERMAL_CONDUCTIVITY]: 'thermal conductivity',
    [PhysicalQuantity.ELECTRICAL_CONDUCTIVITY]: 'electrical conductivity',
    [PhysicalQuantity.ELECTRICAL_CONDUCTANCE]: 'electrical conductance',
    [PhysicalQuantity.VOLTAGE]: 'voltage',
    [PhysicalQuantity.CURRENT]: 'current',
    [PhysicalQuantity.FREQUENCY]: 'frequency',
    [PhysicalQuantity.CURRENCY]: 'currency',
    [PhysicalQuantity.FILE_SIZE]: 'file size',
    [PhysicalQuantity.VISCOSITY]: 'viscosity',
  } as Record<PhysicalQuantity, string>,
};
