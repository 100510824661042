export default {
  input: {
    label: {
      addFile: 'Nuevo Archivo',
      filename: 'Nombre del archivo',
      language: 'Lenguaje del archivo',
      insertVariable: 'Insertar una variable',
      addLib: 'Bibliotecas',
    },
  },
  title: {
    newFile: 'Nuevo Archivo',
  },
  button: {
    cancel: 'Deshacer',
    create: 'Crear',
  },
  tooltip: {
    fullScreen: 'Pantalla Completa',
    exitFullScreen: 'Modo Reducido',
    deleteFile: 'Vaciar archivo',
  },
  text: {
    unknownId: 'UUID Desconocido',
  },
};
