import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ReportInfo, Rights, SharingsInfo, ShortReportInfo, ShortTagInfo, UUID } from '@dametis/core';

import { WidgetInfoChart } from 'classes/DaCharts/types';
import { MacrosResults } from 'types/reports';

export interface WidgetsState {
  allIds: string[];
  byId: Record<string, WidgetInfoChart>;
}

export interface ReportsStateCurrent {
  report: ReportInfo | undefined;
  widgets: WidgetsState;
  loadedWidgets: Record<string, boolean>;
  macrosResults: MacrosResults[];
}

export interface ReportsStateGlobalSettings {
  editMode: boolean;
  editingWidget: string | null;
  editingRow: Record<string, string> | null;
  editingCell: Record<string, any> | null;
  editHeader: boolean;
  save: boolean | null;
}

export interface ReportsState {
  current: ReportsStateCurrent;
  globalSettings: ReportsStateGlobalSettings;
  reports: ShortReportInfo[];
  loading: boolean;
  printing: boolean;
  template: boolean;
  block: string | null;
}

const initialState: ReportsState = {
  current: {
    report: undefined,
    widgets: {
      allIds: [],
      byId: {},
    },
    loadedWidgets: {},
    macrosResults: [],
  },
  globalSettings: {
    editMode: false,
    editingWidget: null,
    editingRow: null,
    editingCell: null,
    editHeader: true,
    save: null,
  },
  reports: [],
  loading: false,
  printing: false,
  template: false,
  block: null,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReports: (state, action: PayloadAction<ShortReportInfo[]>) => {
      state.reports = action.payload;
    },
    setCurrentReport: (state, action: PayloadAction<Partial<ReportsStateCurrent>>) => {
      Object.assign(state.current, action.payload);
    },
    setCurrentReportName: (state, action: PayloadAction<string>) => {
      if (!state.current.report) throw new Error();
      state.current.report.name = action.payload;
    },
    setCurrentReportTags: (state, action: PayloadAction<ShortTagInfo[]>) => {
      if (!state.current.report) throw new Error();
      state.current.report.tags = action.payload;
    },
    updateReportSharing: (state, action: PayloadAction<{ uuid: string; data: SharingsInfo<Rights> }>) => {
      if (state.current.report) {
        state.current.report.sharings = action.payload.data;
      }
      state.reports = state.reports.map(report =>
        report.uuid === action.payload.uuid ? { ...report, sharings: action.payload.data } : report,
      );
    },
    setWidgetIsLoaded: (state, action: PayloadAction<UUID>) => {
      state.current.loadedWidgets[action.payload] = true;
    },
    setWidgetIsLoading: (state, action: PayloadAction<UUID>) => {
      state.current.loadedWidgets[action.payload] = false;
    },
    setGlobalSettings: (state, action: PayloadAction<Partial<ReportsStateGlobalSettings>>) => {
      state.globalSettings = { ...state.globalSettings, ...action.payload };
    },
    createWidget: (state, action: PayloadAction<WidgetInfoChart>) => {
      Object.assign(state.current.widgets.byId, { [action.payload.uuid]: action.payload });
      state.current.widgets.allIds.push(action.payload.uuid);
    },
    setWidget: (state, action: PayloadAction<WidgetInfoChart>) => {
      Object.assign(state.current.widgets.byId[action.payload.uuid], action.payload);
    },
    removeWidget: (state, action: PayloadAction<UUID>) => {
      delete state.current.widgets.byId[action.payload];
      state.current.widgets.allIds = state.current.widgets.allIds.filter(widgetId => widgetId !== action.payload);
      if (state.globalSettings && state.globalSettings.editingWidget === action.payload) {
        state.globalSettings.editingWidget = null;
        state.globalSettings.editingCell = null;
        state.globalSettings.editingRow = null;
      }
    },
    setMacrosResults: (state, action: PayloadAction<MacrosResults[]>) => {
      state.current.macrosResults = action.payload;
    },
    removeReport: (state, action: PayloadAction<UUID>) => ({
      ...initialState,
      globalSettings: {
        ...state.globalSettings,
        editingWidget: null,
        editingCell: null,
        editingRow: null,
      },
      reports: state.reports.filter(r => r.uuid !== action.payload),
    }),
    setSaving: (state, action: PayloadAction<boolean | null>) => {
      state.globalSettings.save = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPrinting: (state, action: PayloadAction<boolean>) => {
      state.printing = action.payload;
    },
    setTemplate: (state, action: PayloadAction<boolean>) => {
      state.template = action.payload;
    },
    setBlock: (state, action: PayloadAction<string | null>) => {
      state.block = action.payload;
    },
    clearReport: state => ({ ...initialState, reports: state.reports }),
    clearReportsStore: () => initialState,
  },
});

export const {
  setReports,
  setCurrentReport,
  setCurrentReportName,
  setCurrentReportTags,
  updateReportSharing,
  setWidgetIsLoaded,
  setWidgetIsLoading,
  setGlobalSettings,
  createWidget,
  setWidget,
  removeWidget,
  setMacrosResults,
  removeReport,
  setSaving,
  setLoading,
  setPrinting,
  setTemplate,
  setBlock,
  clearReport,
  clearReportsStore,
} = reportsSlice.actions;

export default reportsSlice.reducer;
