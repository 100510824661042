import { MoreHorizOutlined } from '@mui/icons-material';
import { BreadcrumbsProps, Tooltip } from '@mui/material';
import { FC, MouseEventHandler, useCallback } from 'react';

export type CustomCollapsedIconProps = Required<Required<BreadcrumbsProps>['slotProps']>['collapsedIcon'] & {
  path: string[];
};

const CustomCollapsedIcon: FC<CustomCollapsedIconProps> = ({ path, ...props }) => {
  const handleClick: MouseEventHandler<HTMLElement> = useCallback(event => {
    event.stopPropagation();
  }, []);

  return (
    <Tooltip placement="top" title={path.join(' › ')} onClick={handleClick}>
      <MoreHorizOutlined {...props} />
    </Tooltip>
  );
};

export default CustomCollapsedIcon;
