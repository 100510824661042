import deepmerge from 'deepmerge';
import type { SeriesLineOptions } from 'highcharts';

export const getLineSeriesOptions = (options: Partial<SeriesLineOptions>): Partial<SeriesLineOptions> =>
  deepmerge(
    {
      marker: {
        radius: 2,
        symbol: 'circle',
      },
      lineWidth: 1,
      states: {
        inactive: {
          opacity: 1,
        },
        hover: {
          lineWidth: 1,
        },
      },
    },
    options,
  );
