import { GridLocaleText } from '@mui/x-data-grid-premium';

import { AlarmPriority } from '@dametis/core';

import { SortOption } from 'types';

export default {
  button: {
    acknowledge: 'Acquitamiento',
    createAlarm: 'Alarma',
    advancedSearch: 'Búsqueda avanzada',
    deleteAlarmConfirmation_yes: 'Sí, vaciar',
    deleteAlarmConfirmation_cancel: 'Cancelar',
    muteAlarm: 'Desactivar esta alarma',
    unmuteAlarm: 'Reactivar esta alarma',
    close: 'Cerrar',
    create: 'Crear',
    previous: 'Anterior',
    next: 'Siguiente',
    submit: 'Validar',
  },
  title: {
    alarms: 'Alarmas',
    activeAlarms: 'Alarmas activas',
    alarmsHistory: 'Historial',
    alarmsSettings: 'Ajustes de alarmas',
    acknowledgeAlarmModal: 'Acquittement',
  },
  subtitle: {
    updatedAt: 'Modificada el {{date}}',
    updatedAtBy: 'Modificada el {{date}} por {{name}}',
    createdAtBy: 'Creada el {{date}} por {{name}}',
  },
  text: {
    when: 'Cuándo',
    do: 'Hacer',
    deleteAlarmConfirmation: '¿Confirma la eliminación de esta alarma?',
    events: 'Historial de eventos',
    for: 'durante',
    day: 'd',
    noResults: 'Sin resultados',
    noActiveAlarms: 'Sin alarmas activas',
    noHistoryIncidents: 'Sin incidentes',
    noAlarms: 'Sin alarmas',
    allSelected: 'Seleccionar todo',
    requiredComment: 'Se requiere dejar un comentario en esta alarma',
    projectFromAlarmName: 'Proyecto desde "{{name}}"',
    commentDateIsCurrentDate: 'La fecha del comentario será la de la confirmación',
  },
  condition: {
    'equation_>': '{{variable}} es mayor que {{value}}',
    'equation_>=': '{{variable}} es mayor o igual que {{value}}',
    'equation_<': '{{variable}} es menor que {{value}}',
    'equation_<=': '{{variable}} es menor o igual que {{value}}',
    'equation_==': '{{variable}} es igual a {{value}}',
    'equation_!=': '{{variable}} es diferente de {{value}}',
    forXUnitOfTime: 'durante {{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
  },
  action: {
    sendEmail: 'Enviar un correo electrónico a {{email}}',
    sendText: 'Enviar un mensaje al {{phone}}',
    sendEmail_notSet: '$t(action.sendNotification)',
    sendNotification: 'Notificar en la aplicación',
  },
  snackbar: {
    message: {
      acknowledge: {
        success: 'La alerta ha sido marcada como vista correctamente',
      },
      duplicate: 'Alarma duplicada',
    },
  },
  table: {
    cells: {
      state: 'Estado',
      name: 'Nombre',
      criticality: 'Criticidad',
      extremeValue: 'Extrema',
      triggerDate: 'Disparada el',
      activeDuration: 'Activa durante',
      actions: 'Acción',
      acknowledgementDate: 'Acquitée el',
      acknowledgementActor: 'Acquitée por',
      resolveDate: 'Resolución el',
      date: 'Fecha',
      event: 'Evento',
      comment: 'Comentario',
      commentLinkedProject: 'proyecto asociado',
      resOn: 'Acquitée el ',
      resBy: 'por ',
    },
    rows: {
      acknowledgedBy: '{{name}}',
      event_resolved: 'Resolución',
      event_triggered: 'Disparada',
      event_notify: 'Notificación enviada',
      event_ack: 'Acquitée',
      event_ackBy: 'Acquitée por {{name}}',
    },
  },
  criticality: {
    text: 'Criticidad',
    variant_notification: 'Notificación',
    variant_warning: 'Atención',
    variant_critical: 'Crítico',
  },
  priority: {
    [AlarmPriority.NOTIFICATION]: 'Notificación',
    [AlarmPriority.WARNING]: 'Atención',
    [AlarmPriority.CRITICAL]: 'Crítico',
  },
  sortBy: {
    [SortOption.ALPHA_ASC]: 'Orden alfabético ascendente',
    [SortOption.ALPHA_DESC]: 'Orden alfabético descendente',
    [SortOption.DATE_ASC]: 'Fecha ascendente',
    [SortOption.DATE_DESC]: 'Fecha descendente',
  },
  filters: {
    variablesFilter: 'Variables',
    criticalityFilter: 'Criticidad',
  },
  input: {
    timeRange: {
      label: 'Fecha',
      value: {
        lastXUnitOfTime: 'Desde {{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
        value_all: 'Todas',
      },
    },
    criticality: {
      label: 'Criticidad',
      value: {
        variant: '$t(criticality.variant)',
      },
    },
    alarms: {
      label: 'Nombre',
    },
    variable: {
      label: 'Variable',
      error: {
        empty: 'Requerido',
      },
    },
    name: {
      label: 'Nombre de la alarma',
      error: {
        empty: 'Requerido',
        over60characters: 'Demasiado largo',
      },
    },
    operand: {
      error: {
        empty: 'Requerido',
      },
    },
    notificationDelay: {
      error: {
        empty: 'Requerido',
        notPositive: 'No puede ser negativo',
      },
    },
    email: {
      placeholder: 'Enviar un correo electrónico a',
      error: {
        invalid: 'Inválido',
      },
    },
    phone: {
      placeholder: 'Enviar un mensaje al',
      error: {
        invalid: 'Inválido',
      },
    },
    operator: {
      label: 'Operador',
    },
    chip: {
      backgroundColor: 'rojo',
    },
    emptyAlarmFilter: 'Sin filtro por alarma',
  },
  xgrid: {
    noAlarmsLabel: 'Sin Alarmas',
    footerTotalAlarms: 'Total de alarmas :',
    footerPaginationAlarmsPerPage: 'Alarmas por página :',
    noIncidentsLabel: 'Sin incidentes',
    footerTotalIncidents: 'Total de incidentes :',
    footerPaginationIncidentsPerPage: 'Incidentes por página :',
  } as Partial<GridLocaleText>,
  stepper: {
    comment: 'Comentario',
    project: 'Proyecto',
  },
  placeholder: {
    acknowledgeAlarmComment: 'Escriba un comentario...',
  },
  label: {
    createProjectFromAlarm: 'Crear un proyecto a partir de esta alarma',
  },
  tooltip: {
    alarm: 'alarma',
    createAlarm: 'crear una alarma',
    acknowledgeAlarm: 'reconocer una alarma',
    acknowledgedActiveAlarm: 'Alarma activa confirmada',
    unacknowledgedActiveAlarm: 'Alarma activa no reconocida',
    unacknowledgedInactiveAlarm: 'Alarma inactiva no reconocida',
  },
};
