import { GroupTypes } from 'components/Synoptic/types';

import { GridView } from '../../types/grid';

export default {
  title: {
    synoptics: 'Sinópticos',
    allSynoptics: 'Todos los sinópticos',
    mySynoptics: 'Mis sinópticos',
    sharedSynoptics: 'Compartidos conmigo',
    privateSynoptics: 'Privados',
    synoptic: 'Sinóptico',
    newSynoptic: 'Nuevo sinóptico',
    deleteSynoptic: '¿Vaciar el sinóptico?',
    editSynoptic: 'Editar el sinóptico',
    duplicateSynoptic: 'Duplicar el sinóptico',
    cellPicker: 'Añadir un elemento',
    device: 'Aparato',
    value: 'Valor',
    light: 'Indicador de estado',
    gauge: 'Indicador',
    text: 'Cuadro de texto',
    link: 'Enlace',
    room: 'Zona',
    image: 'Imagen personalizada',
    linkTips: '¿Sabías que?',
    shortcut: 'Acceso directo',
    custom: 'Elemento personalizado',
    addLink: 'Añadir un enlace',
    imageVariantWithIndex: 'Variante {{index}}',
    createSynoptic: 'crear un sinóptico',
  },
  subtitle: {
    ports: 'Puertos',
    noMatchesFound: 'Ningún elemento correspondiente',
    imageVariants: 'Variantes',
    data: 'Datos',
    plotBands: 'Áreas',
    selection: 'Selección múltiple',
  },
  button: {
    newSynoptic: 'Sinóptico',
    cancel: 'Cancelar',
    create: 'Crear',
    createSynoptic: 'Crear un sinóptico',
    createSynopticFromModel: 'Crear un sinóptico desde una plantilla',
    save: 'Guardar',
    edit: 'Modificar',
    rename: 'Renombrar',
    delete: 'Vaciar',
    duplicate: 'Duplicar',
    no: 'No',
    yes: '¡Sí!',
    closeRightPanel: 'Cerrar',
    closeMenu: 'Cerrar',
    goToConfiguration: 'Configuración',
    goToPlayground: 'Playground',
    uploadImage: 'Enviar una imagen...',
    addCustomElement: 'Elemento personalizado',
    addPort: 'Añadir',
    addStyledRule: 'Añadir',
    addVariable: 'Añadir',
    addPlotBand: 'Añadir',
    share: 'Compartir',
    addImageVariant: 'Añadir',
    editTags: 'Editar tags',
    saveStyleConfiguration: 'Lista',
    addStyleConfiguration: 'Lista',
    select: 'Seleccionar',
  },
  input: {
    label: {
      global: 'Global',
      newSynoptic: 'Nombre',
      link: 'Enlaces',
      color: 'Color de la tubería',
      deviceName: 'Nombre del aparato',
      deviceColor: 'Color',
      valueLabel: 'Nombre del valor',
      valueLabelDisplay: 'Mostrar en el elemento',
      valueStroke: 'Color',
      colors: 'Colores',
      colorOn: 'Estado 1',
      colorOff: 'Estado 0',
      colorUndefined: 'Estado indefinido',
      valueIcon: 'Icono',
      invert: 'Invertir',
      lightLabel: 'Nombre del indicador de estado',
      gaugeLabel: 'Nombre del indicador',
      textLabel: 'Contenido',
      textFontSize: 'Tamaño de la fuente',
      linkType: 'Tipo de enlace',
      roomLabel: 'Nombre de la zona',
      customElementName: 'Nombre del elemento*',
      image: 'Imagen*',
      name: 'Nombre',
      shortcut: 'Acceso directo',
    },
    placeholder: {
      deviceName: 'Opcional',
      valueLabel: 'Opcional',
      valueIcon: 'Opcional',
      lightLabel: 'Opcional',
      gaugeLabel: 'Opcional',
      textLabel: 'Requerido',
      roomLabel: 'Opcional',
      customElementName: 'Requerido',
      search: 'Buscar...',
    },
    value: {
      noIcon: 'Ninguno',
    },
  },
  error: {
    imageUpload_DEFAULT: 'Se produjo un error al importar su imagen. Por favor, inténtelo de nuevo.',
    imageUpload_TOO_BIG: 'El tamaño de su imagen es superior a 5 MB. Por favor, inténtelo con una imagen más pequeña.',
    fetchingValueData: 'Error al recuperar los datos',
    portsError: 'El porcentaje debe estar entre 0 y 100',
    nameRequired: 'El nombre del elemento es obligatorio',
  },
  text: {
    [`group_${GroupTypes.ELEMENTS}`]: 'Elementos básicos',
    [`group_${GroupTypes.EQUIPMENT}`]: 'Equipos',
    [`group_${GroupTypes.GLOBAL}`]: 'Elementos globales',
    [`group_${GroupTypes.CUSTOM}`]: 'Mis elementos',
    updatedAt: 'Modificado {{date}}',
    me: 'yo',
    noSynoptics: 'Todavía no tienes ningún sinóptico',
    noPorts: 'Sin puerto',
    noImageVariants: 'Sin variante',
    noSelectedData: 'Ningún dato seleccionado',
    port: 'Puerto',
    name: 'Nombre',
    type: 'Tipo',
    tag: 'Tags',
    shortCreatedAt: 'Creado',
    shortUpdatedAt: 'Modificado',
    addCell: 'Elementos',
    sharedBy: 'Compartido por {{name}}',
    ownedBy: 'Pertenece a {{name}}',
    link_coldWaterPipe: 'Tubería de agua fría',
    link_hotWaterPipe: 'Tubería de agua caliente',
    link_veryHotWaterPipe: 'Tubería de agua muy caliente',
    link_coldAirPipe: 'Tubería de aire frío',
    link_electricCable: 'Cable eléctrico',
    link_gasPipe: 'Tubería de gas',
    link_customPipe: 'Tubería personalizada',
    device_boiler: 'Caldera',
    device_chiller: 'Refrigerador',
    device_counter: 'Contador',
    device_flowMeter: 'Caudalimetro',
    device_heatExchanger: 'Intercambiador de calor',
    device_pumpL: 'Bomba (izquierda a derecha)',
    device_pumpR: 'Bomba (derecha a izquierda)',
    device_tankV: 'Tanque',
    device_tankBigV: 'Tanque grande',
    device_valve: 'Válvula',
    device_valveT: 'Válvula de 3 vías',
    device_compressorAbc1: 'Compresor ABC (1)',
    device_compressorAbc2: 'Compresor ABC (2)',
    device_compressorAf1: 'Compresor AF (1)',
    device_compressorAf2: 'Compresor AF (2)',
    device_sullair: 'Sullair',
    device_airCompressor: 'Compresor de aire',
    device_coolingTowerPhoto: 'Torre de refrigeración (foto)',
    device_coolingTower: 'Torre de refrigeración',
    device_evaporativeCondenserPhoto: 'Condensador evaporativo (foto)',
    device_evaporativeCondenser: 'Condensador evaporativo',
    device_steamBoilerPhoto: 'Caldera de vapor (foto)',
    device_steamBoilerPhoto2: 'Caldera de vapor (foto 2)',
    device_steamBoiler: 'Caldera de vapor',
    device_tankH: 'Tanque de alimentos',
    device_tankH2: 'Botella BP',
    device_barrel: 'Barril',
    device_adsorptionDryer: 'Secador por adsorción',
    device_refrigerationDryer: 'Secador frigorífico',
    device_transformer: 'Transformador',
    device_coldCompressor: 'Compresor frío',
    device_CompressorHPInterior: 'Compresor HP Interior',
    device_CompressorHPInterior2: 'Compresor HP Interior 2',
    device_CompressorLP: 'Compresor LP',
    device_CentrifugalCompressorWithRecovery: 'Compresor Centrífugo con Recuperación',
    device_CentrifugalCompressorWithoutRecovery: 'Compresor Centrífugo Sin Recuperación',
    device_AbsorptionDryer: 'Secador de Absorción',
    device_RefrigerationDryer: 'Secador de Refrigeración',
    device_ColdBottleHorizontal: 'Botella Fría Horizontal',
    device_ColdBottleVertical: 'Botella Fría Vertical',
    device_PistonCompressorWithConsole: 'Compresor de Pistón con Consola',
    device_PistonCompressorWithoutConsole: 'Compresor de Pistón sin Consola',
    device_ScrewCompressor: 'Compresor de Tornillo',
    device_AirCooler: 'Enfriador de Aire',
    device_EvaporativeCondenser: 'Enfriador Evaporativo 1',
    device_EvaporativeCondenser2: 'Enfriador Evaporativo 2',
    device_ExpansionValve: 'Válvula de Expansión',
    device_AirCooledCondenser: 'Condensador Enfriado por Aire 1',
    device_AirCooledCondenser2: 'Condensador Enfriado por Aire 2',
    device_Chiller: 'Enfriadora 1',
    device_Chiller2: 'Enfriadora 2',
    device_Duct: 'Conducto',
    device_BarrelOutletOne: 'Salida Barril 1',
    device_BarrelOutletTwo: 'Salidas Barril 2',
    device_BarrelOutletThree: 'Salidas Barril 3',
    device_BarrelOutletFour: 'Salidas Barril 4',
    device_HotWaterBoiler: 'Caldera de Agua Caliente',
    device_StandardChimneyHalfFace: 'Chimenea Estándar Media Cara',
    device_StandardChimney: 'Chimenea Estándar',
    device_ChimneyWithEco: 'Chimenea con Eco',
    device_SteamBoilerWithChimney: 'Caldera de Vapor con Chimenea',
    device_SteamBoilerWithoutChimney: 'Caldera de Vapor sin Chimenea',
    device_Degasser: 'Desgasificador',
    device_HotWaterHeater: 'Calentador de Agua Caliente',
    device_MudPumpLeft: 'Bomba de Lodos Izquierda',
    device_MudPumpRight: 'Bomba de Lodos Derecha',
    device_DistributionPumpLeft: 'Bomba de Distribución Izquierda',
    device_DistributionPumpRight: 'Bomba de Distribución Derecha',
    device_PumpHorizontalLeft: 'Bomba Horizontal Izquierda',
    device_NH3Pump: 'Bomba NH3',
    device_PumpVertical: 'Bomba Vertical',
    device_TwoPortValve: 'Válvula de Dos Puertos',
    device_ThreePortValve: 'Válvula de Tres Puertos',
    device_ButterflyValve: 'Válvula de Mariposa',
    device_WaterSoftener: 'Ablandador de Agua',
    device_WaterMeter: 'Medidor de Agua',
    device_GasMeter: 'Medidor de Gas',
    device_OtherMeter: 'Otro Medidor',
    device_FlowMeter: 'Caudalímetro',
    device_TubularHeatExchangerLeft: 'Intercambiador de Calor Tubular Izquierdo',
    device_TubularHeatExchangerRight: 'Intercambiador de Calor Tubular Derecho',
    device_ReverseOsmosisUnit: 'Unidad de Osmosis Inversa',
    device_SolarPanelInclined: 'Panel Solar Inclinado',
    device_SolarPanel: 'Panel Solar',
    device_Tanks: 'Tanques 1',
    device_Tanks2: 'Tanques 2',
    device_MilkSilo: 'Silo de Leche',
    device_BiologicalTreatment: 'Tratamiento Biológico',
    device_NaturalDischarge: 'Descarga Natural',
    device_Drilling: 'Perforación',
    device_Desanding: 'Desarenado',
    device_Desanding2: 'Desarenado 2',
    device_Screening2: 'Cribado 2',
    device_Screening: 'Cribado',
    device_VerticalAirTank: 'Tanque de aire Vertical',
    device_HorizontalAirTank: 'Tanque de aire Horizontal',
    device_Centrifuge: 'Centrífugadora',
    device_Clarification: 'Clarificación',
    device_Settling: 'Decantación',
    device_BarrelDepartureAddition: 'Añadir salida Cabezal',
    device_StatusIndicatorLight: 'Indicador de estado',
    face_Front: '2D',
    face_Side: '3D',
    cell_value: 'Valor',
    cell_gauge: 'Indicador',
    cell_light: 'Indicador de estado',
    cell_text: 'Cuadro de texto',
    cell_room: 'Zona',
    cell_image: 'Imagen personalizada',
    cell_shortcut: 'Acceso directo a ...',
    linkTips: 'Puedes hacer clic en un enlace para agregar un punto de anclaje. Y puedes hacer doble clic en este punto para vaciarlo.',
    icon_water: 'Agua fría',
    icon_hotWater: 'Agua caliente',
    icon_veryHotWater: 'Agua muy caliente',
    icon_fire: 'Fuego',
    icon_electricity: 'Electricidad',
    icon_air: 'Aire',
    noStyledRules: 'Sin reglas de estilo',
    rules: 'Regla',
    copy: 'Copia',
    public: 'Público',
    private: 'Privado',
    shared: 'Compartido',
    view: {
      [GridView.TILE]: 'Vista de mosaico',
      [GridView.LIST]: 'Vista de lista',
    },
    cartHelper: 'Puedes añadir fácilmente variables a tu cesta haciendo clic derecho en el widget.',
    selectedElements: 'artículos seleccionados.',
    view_Front: '2D',
    view_Side: '3D',
  },
  form: {
    label: {
      confidentiality: 'Confidencialidad',
    },
    value: {
      confidentiality_private: 'Privado',
      confidentiality_shared: 'Compartido',
    },
    tooltip: {
      owner_shared: 'Si esta opción está marcada, este sinóptico será visible para otros usuarios',
      owner_private: 'Si esta opción está marcada, este sinóptico será visible solo para ti',
      not_owner: 'No puedes cambiar la confidencialidad de este sinóptico porque no eres el propietario',
    },
  },
  tooltip: {
    createSynoptic: 'crear un sinóptico',
    editSynoptic: 'modificar el sinóptico',
    saveSynoptic: 'guardar el sinóptico',
    renameSynoptic: 'renombrar el sinóptico',
    editTags: 'modificar las tags del sinóptico',
    duplicateSynoptic: 'duplicar el sinóptico',
    shareSynoptic: 'compartir el sinóptico',
    deleteSynoptic: 'vaciar el sinóptico',
    undo: 'Cancelar',
    redo: 'Rehacer',
    toggleGridOn: 'Activar la magnetización',
    toggleGridOff: 'Desactivar la magnetización',
    sortZIndexes: 'Restablecer el orden de los elementos',
    zoomOut: 'Disminuir zoom',
    zoomIn: 'Aumentar zoom',
    zoomToFit: 'Maximizar',
    toggleFullScreen: 'Modo de pantalla completa',
    deleteImageVariant: 'Vaciar',
    alreadyInCart: 'Esta variable ya está en la cesta',
    variableNotValid: 'Variable no válida',
    showPorts: 'Mostrar los puertos',
    hidePorts: 'Ocultar los puertos',
    cartButton_zero: 'Cesta',
    cartButton_one: 'Cesta ({{count}} variable)',
    cartButton_other: 'Cesta ({{count}} variables)',
    addStyledRule: 'Añadir una regla',
    saveStyleConfiguration: 'Guardar estas reglas en una lista',
    addStyleConfiguration: 'Usar una lista de reglas',
    setWidgetGlobal: 'hacer global un widget',
    cannotAddPlotBand: 'Debe introducir un mínimo y un máximo válidos para añadir una área.',
    rotateLeft: 'Girar a la izquierda',
    rotateRight: ' Girar a la derecha',
    flipVertically: 'Rotar verticalmente',
    flipHorizontally: 'Rotar horizontalmente',
    moveToBack: 'Mover al fondo',
    moveToBackByOne: 'Invertir',
    moveToFrontByOne: 'Avanzar',
    moveToFront: 'Mover al primer plano',
    clone: 'Clonar',
    delete: 'Borrar',
    returnLinks: 'Invertir la dirección',
  },
  alert: {
    cantEditWhenYet: 'Podrás modificar la condición una vez que el elemento esté colocado.',
    canNoLongerEditDo: 'No podrás modificar la imagen después de su creación.',
    reuseImage: 'Tu imagen se añadirá a la lista de tus elementos',
  },
  cart: {
    title: 'Cesta',
    variables: 'Variables',
    noVariables: 'Ninguna variable',
    button: {
      variable: 'Variable',
      clear: 'Vaciar la cesta',
      open: 'Abrir un playground',
    },
  },
  toast: {
    create: {
      success: 'El sinóptico se creó correctamente.',
      failure: 'Se produjo un error al crear este sinóptico.',
    },
    save: {
      success: 'El sinóptico se guardó correctamente.',
      failure: 'Se produjo un error al guardar este sinóptico.',
    },
    delete: {
      success: 'El sinóptico se eliminó correctamente.',
      failure: 'Se produjo un error al eliminar este sinóptico.',
    },
    duplicate: {
      success: 'El sinóptico se duplicó correctamente.',
      failure: 'Se produjo un error al duplicar este sinóptico.',
    },
  },
  select: {
    view: 'Vista',
    view_Front: '2D',
    view_Side: '3D',
  },
};
