import { makeStyles } from '@mui/styles';

export const USER_MENU_DIAMETER = '34px';

const useUserMenuStyles = makeStyles(theme => ({
  avatar_container: {
    position: 'relative',
    display: 'inline-flex',
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    width: USER_MENU_DIAMETER,
    height: USER_MENU_DIAMETER,
  },
}));

export default useUserMenuStyles;
