/* eslint-disable no-return-assign */

import { BatchInfo, UUID } from '@dametis/core';

type Tree = Node[];

interface Node {
  uuid: string;
  name: string;
  children: Tree;
}

const flattenTree = (batches: BatchInfo[]): Record<string, any> => {
  return batches.reduce<Record<UUID, { uuid: UUID; name: string }>>((accu, batch) => {
    accu[batch.uuid] = {
      uuid: batch.uuid,
      name: batch.name,
    };
    if (batch.children && batch.children.length > 0) {
      const children = flattenTree(batch.children);
      Object.values(children).forEach(
        c =>
          (accu[c.uuid] = {
            uuid: c.uuid,
            name: c.name,
          }),
      );
    }
    return accu;
  }, {});
};

export default flattenTree;
