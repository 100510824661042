export default {
  ac_debit: {
    name: 'AC_Debit',
    description: "Débit de l'air comprimé",
  },
  categories: {
    air_compressed: 'air comprimé',
  },
  inputs: {
    temperature: {
      name: 'Température',
      id: 'T',
    },
    pressure: {
      name: 'Pression',
      id: 'P',
    },
    oui: {
      name: 'Oui',
      id: 'O',
    },
    delta_pressure: {
      name: 'Delta pression',
      id: 'dP',
    },
    threshold_delta_pressure: {
      name: 'Seuil de delta pression',
      id: 'sdP',
    },
    diameter_in: {
      name: 'Diamètre intérieur',
      id: 'Dint',
    },
    diameter_out: {
      name: 'Diamètre extérieur',
      id: 'Dext',
    },
    reynolds: {
      name: 'Reynolds',
      id: 'Rd',
    },
    altitude: {
      name: 'Altitude',
      id: 'Altitude',
    },
  },
};
