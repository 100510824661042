import { MacroFieldType } from '@dametis/core';

export default {
  title: {
    newMacro: 'Nueva macro',
    macroSelector: 'Macros',
    macro: 'Macro',
  },
  input: {
    label: {
      name: 'Nombre',
      description: 'Descripción',
      fields: 'Salidas',
      packages: 'Paquetes',
      script: 'Script',
      result: 'Resultado',
      logs: 'Logs',
      tableFields: 'Campos',
      colLabel: 'Título de la columna',
      type: 'Tipo',
      unit: 'Unidad',
      pivot: 'Pivote',
      message: 'Mensaje',
      macroResults: 'Resultados',
    },
  },
  button: {
    createMacro: 'Crear',
    editMacro: 'Modificar',
    saveMacro: 'Guardar',
    executeMacro: 'Ejecutar',
    cantExecuteMacro: 'Guardar la macro para ejecutarla',
    cancel: 'Cancelar',
    noChanges: 'Sin modificaciones',
    deleteMacro: 'Vaciar',
    export: 'Exportar a CSV',
    close: 'Cerrar',
    selectMacro: 'Seleccionar una macro',
    macroMode: 'Modo macro',
    runMacro: 'Ejecutar',
    duplicateMacro: 'Duplicar',
    cantDuplicateMacro: 'Guardar la macro para duplicarla',
    forceDeploy: 'Desplegar',
    reset: 'Vaciar caché',
    addLib: 'Bibliotecas',
    confirm: 'Confirmar',
  },
  tooltip: {
    editMacro: 'Editar la macro',
    status_READY: 'Listo',
    status_FAILED: 'Error',
    status_RUNNING: 'En curso',
    status_UNKNOWN: '',
  },
  toast: {
    createMacroSuccess: 'Macro creada con éxito.',
    createMacroWarning: 'Macro creada pero no funcional.',
    createMacroError: 'Se produjo un error al crear la macro.',
    saveMacroSuccess: 'Cambios guardados.',
    saveMacroWarning: 'Cambios guardados pero con errores.',
    saveMacroError: 'Se produjo un error al guardar los cambios.',
    deleteMacroSuccess: 'Macro eliminada con éxito.',
    deleteMacroError: 'Se produjo un error al vaciar la macro.',
    duplicateMacroSuccess: 'Macro duplicada con éxito.',
    duplicateMacroError: 'Se produjo un error al duplicar la macro.',
    executeMacro: 'Ejecución de la macro',
    deployMacroSuccess: 'Macro desplegada con éxito',
    deployMacroWarning: 'Macro desplegada pero con errores.',
    deployMacroError: 'Se produjo un error al desplegar la macro.',
  },
  text: {
    copy: 'Copia',
    total: 'TOTAL',
  },
  tabs: {
    jsonMacroResult: 'JSON',
    tableMacroResult: 'TABLA',
  },
  placeholder: {
    emptyDescription: 'Sin descripción',
    noMacros: 'Ninguna macro añadida',
    noMacro: 'Ninguna macro seleccionada',
    selectMacroEdit: 'Seleccionar una macro',
    noMacroLibs: 'Ninguna biblioteca añadida',
  },
  type: {
    [MacroFieldType.BOOLEAN]: 'Booleano',
    [MacroFieldType.DATE]: 'Fecha',
    [MacroFieldType.DURATION]: 'Duración',
    [MacroFieldType.STRING]: 'Texto',
    [MacroFieldType.NUMBER]: 'Número',
  },
  dialog: {
    title: {
      addLib: 'Bibliotecas',
    },
  },
};
