import { Divider, FormLabel, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IsAliasVariable, IsBlockVariable, IsDataVariable, VarCalc } from '@dametis/core';

import { useVariableKind } from 'hooks/useVariableProp';

import AliasVariableDetails from './AliasVariableDetails';
import BlockVariableDetails from './BlockVariableDetails';
import DataVariableDetails from './DataVariableDetails';

interface Props {
  variable: Partial<VarCalc>;
}

const VariableDetails: FC<Props> = ({ variable }) => {
  const { t } = useTranslation('vnc');

  const variableKind = useVariableKind(variable);

  return (
    <>
      <Divider />
      <Typography variant="h6">{t('title.variableDetails')}</Typography>
      <div>
        <FormLabel>{t('input.label.varCalcKind')}</FormLabel>
        <Typography>{t(`global:variableType.${variableKind}`)}</Typography>
      </div>
      {IsAliasVariable(variable) && <AliasVariableDetails variable={variable} />}
      {IsDataVariable(variable) && <DataVariableDetails variable={variable} />}
      {IsBlockVariable(variable) && <BlockVariableDetails variable={variable} />}
    </>
  );
};

export default VariableDetails;
