import { Close } from '@mui/icons-material';
import { Alert, IconButton, AlertProps as MuiAlertProps, Tooltip } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { append } from 'functions/localStorage';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useSelector } from 'store';
import { UserHelperName } from 'types/userHelpers';

interface Props extends MuiAlertProps {
  name: UserHelperName;
}

const Helper: FC<Props> = ({ name, children = undefined, ...AlertProps }) => {
  const { t } = useTranslation('global');
  const userId = useSelector(state => state.auth.user!.uuid);

  const [open, setOpen] = useLocalStorage(append('userHelper', name), true, { userId });

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <AnimatePresence initial={false}>
      {open && (
        <motion.span animate={{ scale: 1, height: 'auto' }} exit={{ scale: 0, height: 0 }} initial={{ scale: 0, height: 0 }}>
          <Alert
            action={
              <Tooltip title={t('tooltip.close')}>
                <IconButton color="inherit" size="small" onClick={close}>
                  <Close fontSize="inherit" />
                </IconButton>
              </Tooltip>
            }
            severity="info"
            {...AlertProps}
          >
            {children}
          </Alert>
        </motion.span>
      )}
    </AnimatePresence>
  );
};

export default Helper;
