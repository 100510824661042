import { Box, Chip, Tooltip } from '@mui/material';
import { FC } from 'react';
import { RenderElementProps, useReadOnly, useSelected } from 'slate-react';

import { BatchElement } from '@dametis/core';

import { getBatchPath } from 'components/UI/BatchFilter/functions/getBatchPath';
import { setColorLightness } from 'functions/color';
import { useVariableName } from 'hooks/useVariableProp';

interface Props extends RenderElementProps {
  element: BatchElement;
}

const SlateBatch: FC<Props> = ({ element, attributes, children = undefined }) => {
  const readOnly = useReadOnly();
  const selected = useSelected() && !readOnly;
  const batchName = useVariableName(element.batch);

  return (
    <Box component="span" contentEditable={false} {...attributes}>
      <Tooltip placement="top" title={getBatchPath(element.batch.batchUuid)}>
        <Chip
          key={element.batch.batchUuid}
          color="secondary"
          label={batchName}
          size="small"
          sx={{
            m: theme => theme.spacing(0.25),
            fontWeight: 500,
            backgroundColor: theme => setColorLightness(theme.palette.daGreen[400], selected ? 45 : 55),
          }}
        />
      </Tooltip>
      {children}
    </Box>
  );
};

export default SlateBatch;
