import { FindFolder, FindFolderPath, FolderInfo, ShortcutCategory, UUID } from '@dametis/core';

export const getBlockTypePath = (globalRootFolder: FolderInfo | null, blockTypeId: UUID): FolderInfo[] => {
  if (!globalRootFolder) {
    return [];
  }
  const parentFolder = FindFolder(
    globalRootFolder,
    folder =>
      folder.shortcuts.find(shortcut => shortcut.category === ShortcutCategory.BLOCK_TYPE && shortcut.uuid === blockTypeId) !== undefined,
  );
  if (!parentFolder) {
    return [];
  }
  try {
    return FindFolderPath(globalRootFolder, parentFolder);
  } catch (err) {
    return [];
  }
};
