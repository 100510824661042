export default {
  title: {
    dragAndDrop: 'Arrastrar y soltar',
    or: 'o',
  },
  button: {
    browse: 'Explorar archivos',
  },
  text: {
    selectedFile: 'Archivo seleccionado: ',
    formatsAllowed: 'Formatos permitidos: ',
    file: 'Archivo',
    browse: 'Soltar un archivo o hacer clic para seleccionarlo',
  },
  tooltip: {
    upload: 'Descargar un archivo',
  },
};
