import { Components } from '@mui/material';

export default {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    grouped: {
      '&:not(:last-of-type)': {
        borderRight: 'none',
      },
    },
  },
} as Components['MuiButtonGroup'];
