import { WorkOutlineOutlined } from '@mui/icons-material';
import { Button, Stack, Tooltip as Theoltip, Typography } from '@mui/material';
import { useAnimate } from 'framer-motion';
import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useCartStorage from 'components/Layouts/Cart/useCartStorage';
import { useDispatch } from 'store';
import { setIsOpen } from 'store/slices/cart';

const CartButton: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('synoptics');

  const [scope, animate] = useAnimate();

  const { items: cartItems } = useCartStorage();

  const nbItems = useMemo(() => cartItems.length, [cartItems]);

  const [nbItemsCopy, setNbItemsCopy] = useState<number>(nbItems);

  const handleClickButton: MouseEventHandler = useCallback(() => {
    dispatch(setIsOpen(true));
  }, [dispatch]);

  useEffect(() => {
    if (nbItems > nbItemsCopy) {
      void animate(scope.current, { scale: [1, 1.2, 1] }, { duration: 0.25 });
    }
    setNbItemsCopy(nbItems);
  }, [animate, nbItems, scope, nbItemsCopy]);

  return (
    <Theoltip placement="bottom" title={t('tooltip.cartButton', { count: nbItems })}>
      <Button ref={scope} color="icon" sx={{ minWidth: 'unset', p: 1, position: 'relative' }} onClick={handleClickButton}>
        {nbItems > 0 && (
          <Typography
            align="center"
            alignItems="center"
            color="icon"
            component={Stack}
            fontSize={11}
            height="100%"
            justifyContent="center"
            mt="3px"
            position="absolute"
            width="100%"
          >
            {nbItems > 9 ? '+9' : nbItems}
          </Typography>
        )}
        <WorkOutlineOutlined
        // sx={{ transform: `scale(${nbItems > 0 ? 1 : 0.75})`, transition: theme => theme.transitions.create(['transform']) }}
        />
      </Button>
    </Theoltip>
  );
};

export default CartButton;
