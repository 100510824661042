import { ActivityVerb, ProjectTemplateCategoryType, ProjectType, StandardProjectStatus, StandardTaskStatus } from '@dametis/core';

export default {
  title: {
    projects: 'Mes projets',
    createForm: 'Nouveau projet',
    editForm: 'Modifier le projet',
    task: 'Tâche',
    editTask: 'Modifier la tâche',
    newProject: 'Nouveau projet',
    newSaving: 'Ajouter un gain',
    newForecastedSaving: 'Ajouter un gain prévisionnel',
    editSaving: 'Modifier le gain',
    editForecastedSaving: 'Modifier le gain prévisionnel',
    newConsumption: 'Saisir une consommation de référence',
    editConsumption: 'Modifier la consommation de référence',
    editInformation: 'Modifier les informations',
    newExpense: 'Ajouter une dépense',
    newSubsidy: 'Ajouter une subvention',
    duplicateProject: 'Dupliquer le projet',
    redirection: 'Redirection',
    relativeSavings: 'Gains relatifs à la période de référence',
    createdAtBy: 'Créé le {{date}} par {{name}}',
    deletedUser: 'Utilisateur supprimé',
    editValues: 'Modifier les valeurs',
    editRating: 'Modifier les critères de notation',
    caroussel: 'Image : {{imageName}}',
  },
  button: {
    newProject: 'Projet',
    addCost: 'Ajouter un coût',
    create: 'Créer',
    save: 'Enregistrer',
    share: 'Partager',
    edit: 'Modifier...',
    cancel: 'Annuler',
    continue: 'Continuer',
    delete: 'Supprimer',
    no: 'Non',
    yes: 'Oui',
    addSubpart: 'Ajouter une catégorie',
    addTask: 'Ajouter une tâche',
    removeTask: 'Supprimer une tâche',
    deleteTask: 'Supprimer la tâche',
    editTask: 'Modifier la tâche',
    add: 'Ajouter',
    upload: 'Importer',
    newAsset: 'Ajouter un document',
    newTask: 'Ajouter une tâche',
    newSaving: 'Ajouter un gain',
    newForecastedSaving: 'Ajouter un gain prévisionnel',
    newConsumption: 'Saisir une consommation',
    changeStatus: 'Changer le statut',
    newExpense: 'Ajouter',
    newSubsidy: 'Ajouter',
    editShortcuts: 'Modifier les raccourcis',
    editCustom: 'Modifier',
    duplicate: 'Dupliquer',
    order: {
      newest: 'Des récents aux anciens',
      oldest: 'Des anciens aux récents',
    },
    newImage: 'Ajouter une  image',
    close: 'Fermer',
    editImage: 'Modifier',
    deleteImage: 'Supprimer',
  },
  tooltip: {
    createProject: 'créer un projet',
    duplicateProject: 'dupliquer le projet',
    shareProject: 'partager un projet',
    editProject: 'modifier le projet',
    deleteProject: 'supprimer',
    addDate: 'Ajouter une date',
    deleteDate: 'Supprimer la date',
    editDates: 'Modifier la période de référence',
    savingByYear: 'Gains par an',
    consumptionByPeriod: 'Consommation sur la période',
    delete: 'Supprimer',
    send: 'Envoyer',
    edit: 'Modifier',
    taskAfterDeadline: 'La date limite a été dépassée',
    previousImage: 'Précédent',
    nextImage: 'Suivant',
    noAccessUser: "Cet utilisateur n'a pas accès au projet",
  },
  toggle: {
    byYear: 'Par an',
    byPeriod: 'Par période',
  },
  input: {
    label: {
      name: 'Nom',
      description: 'Description',
      currency: 'Devise',
      costLabel: 'Poste de dépense',
      costValue: 'Montant',
      budget: 'Enveloppe budgétaire',
      orderBy: 'Trier par',
      search: 'Rechercher...',
      subpart: 'Catégorie',
      task: 'Tâche',
      in: 'Dans la catégorie',
      dependency: 'Dépendances',
      noDependency: 'Aucune dépendance',
      from: 'Date de début',
      auto: 'Automatique',
      duration: 'Durée',
      milestone: 'Étape clé',
      co2: 'CO2',
      finance: 'Économies',
      electricity: 'Électricité',
      water: 'Eau',
      gaz: 'Gaz',
      assignee: 'Utilisateur assigné',
      deadline: 'Échéance',
      type: 'Type',
      priority: 'Priorité',
      assets: 'Documents',
      carbon: 'Carbone',
      financial: 'Financier',
      energyType: 'Nature',
      energy: 'Quantité',
      // trackingMethod: 'Méthode de suivi',
      // trackingFrequency: 'Fréquence de suivi',
      referencePeriod: 'Période de référence',
      enableReferencePeriod: 'Activer la période de référence',
      label: 'Nom de la dépense',
      value: 'Valeur',
      note: 'Note',
      chief: 'Responsable',
      tags: 'Tags',
      tasksTemplate: 'Modèle de tâches',
      keepTasks: 'Dupliquer les tâches existantes',
      startDate: 'Début',
      endDate: 'Fin',
      currentStatus: 'Statut courant',
      statusDates: 'Date des statuts',
      score: 'Note globale',
      subsidyLabel: 'Nom de la subvention',
      template: 'Modèle de projets',
      energiesUnit: 'Unité',
      impact: 'Impact',
    },
    placeholder: {
      emptyType: 'Aucun type sélectionné',
      emptyPriority: 'Aucune priorité sélectionnée',
      emptyStatus: 'Aucun statut sélectionné',
      emptyTag: 'Aucun tag sélectionné',
      emptySite: 'Tous les sites',
      noSelect: 'Aucune selection',
      renameFile: "Vous devez d'abord choisir un document",
    },
  },
  timeUnit: {
    hours: 'heure(s)',
    days: 'jour(s)',
    weeks: 'semaine(s)',
    months: 'mois',
  },
  text: {
    name: 'Nom',
    chief: 'Responsable',
    type: 'Type',
    priority: 'Priorité',
    state: 'Statut',
    currency: 'Devise',
    cost: 'Coût',
    cost_plural: 'Coûts',
    ongoingProjects: 'Projets en cours',
    finishedProjects: 'Projets clôturés',
    carbon: "d'émissions de CO2",
    energy: 'de dépense énergétique',
    electricity: 'de gain en électricité',
    fuel: 'de gain en fioul',
    gas: 'de gain en gaz',
    water: 'de gain en eau',
    product: 'de gain en produit',
    finance: "d'économies",
    deleteProject: 'Supprimer le projet {{name}} ?',
    ganttChart: 'Planning',
    site: 'Site',
    noExpense: 'Aucune dépense',
    noSubsidy: 'Aucune subvention',
    noAsset: 'Aucun document',
    noImages: 'Aucune image',
    noCommentLinked: 'Aucun commentaire associé',
    noShortcut: 'Aucun raccourci défini',
    noPlayground: 'Aucun playground associé.',
    noSynoptic: 'Aucun synoptique associé.',
    noReport: 'Aucun rapport associé.',
    reports: 'Rapports',
    synoptics: 'Synoptiques',
    playgrounds: 'Playgrounds',
    unassigned: 'Non assignée',
    noUser: 'Utilisateur inconnu',
    collaborators: 'Collaborateurs',
    noTemplate: 'Sans modèle',
    commentNumber_zero: 'Aucun commentaire',
    commentNumber_one: '{{count}} commentaire',
    commentNumber_other: '{{count}} commentaires',
    unknownSite: 'Site inconnu',
    redirection: 'Vous allez être redirigé vers le site',
    allSelected: 'Tout sélectionner',
    byYear: '/an',
    byPeriod: '/période',
    years_one: 'année',
    years_other: 'années',
    paybackPeriod: {
      short: 'TRI réel',
      full: 'Temps de retour sur investissement réel',
      value: 'avec subventions',
      valueWithoutSubsidies: 'sans subventions',
      noData: 'Impossible de calculer le TRI',
    },
    forecastedPaybackPeriod: {
      short: 'TRI prévisionnel',
      full: 'Temps de retour sur investissement prévisionnel',
      value: 'avec subventions',
      valueWithoutSubsidies: 'sans subventions',
      noData: 'Impossible de calculer le TRI prévisionnel',
    },
    categorySelectItemDisabled: 'Vous devez sélectionner une catégorie Gains et la catégorie Dépenses',
    display: 'Affichage',
    period: 'Période',
    total: 'Total',
    noConsumption: {
      title: 'Pas de consommation de référence',
      description: 'Ajoutez une consommation de référence depuis la partie "Période de référence" pour voir vos gains relatifs',
    },
    comment: '“{{comment}}”',
    noComment: 'Aucun commentaire',
    alarm: 'alarme',
    playground: 'playground',
    report: 'rapport',
    variable: 'variable',
    noValue: '-',
    template: 'Modèle',
    withoutTemplate: 'Sans modèle',
    customStatus: 'Autres statuts',
    completionRate: 'Avancement',
    [ProjectTemplateCategoryType.STATUS]: 'Statut',
    [ProjectTemplateCategoryType.RATING]: 'Notation',
    [ProjectTemplateCategoryType.REFPERIOD]: 'Période de référence',
    [ProjectTemplateCategoryType.FORECASTED_SAVINGS]: 'Gains prévisionnels',
    [ProjectTemplateCategoryType.SAVINGS]: 'Gains réels',
    [ProjectTemplateCategoryType.EXPENSES]: 'Dépenses',
    [ProjectTemplateCategoryType.SUBSIDIES]: 'Subventions',
    [ProjectTemplateCategoryType.PAYBACK]: 'TRI',
    [ProjectTemplateCategoryType.TASKS]: 'Tâches',
    [ProjectTemplateCategoryType.ASSETS]: 'Documents',
    [ProjectTemplateCategoryType.SHORTCUTS]: 'Raccourcis',
    [ProjectTemplateCategoryType.LINKS]: 'Liens',
    [ProjectTemplateCategoryType.COMMENTS]: 'Mentions',
    [ProjectTemplateCategoryType.ACTIVITIES]: 'Activités',
    [ProjectTemplateCategoryType.CUSTOM]: 'Personnalisé',
    [ProjectTemplateCategoryType.IMAGES]: 'Images',
    noTask: 'Aucune tâche',
    customCategoryNotFound: 'Impossible de charger la catégorie',
  },
  priority: {
    variant_improvement: 'Amélioration',
    variant_necessary: 'Nécessaire',
    variant_critical: 'Critique',
  },
  type: {
    [`variant_${ProjectType.ANOMALY}`]: 'Anomalie',
    [`variant_${ProjectType.OPTIMISATION}`]: 'Optimisation',
    [`variant_${ProjectType.GLOBALPROJECT}`]: 'Projet global',
    [`variant_${ProjectType.PROJECT}`]: 'Projet',
  },
  state: {
    variant_PENDING_VALIDATION_CAPEX: 'En validation CAPEX',
    variant_DEFINITION: 'Définition du projet',
    variant_PENDING_SIGNATURE: 'En attente de signature',
    variant_VALIDATED: 'Project validé',
    variant_FINISH: 'Project clôturé',
  },
  status: {
    [`variant_${StandardProjectStatus.PENDING_VALIDATION}`]: 'En validation',
    [`variant_${StandardProjectStatus.VALIDATED}`]: 'Validé',
    [`variant_${StandardProjectStatus.IN_PROGRESS}`]: 'En réalisation',
    [`variant_${StandardProjectStatus.COMPLETED}`]: 'Finalisé',
    [`variant_${StandardProjectStatus.PERFORMANCE_VALIDATION}`]: 'Validation de performance',
    [`variant_${StandardProjectStatus.CLOSED}`]: 'Terminé',
  },
  tasks: {
    status: {
      [`variant_${StandardTaskStatus.TODO}`]: 'À faire',
      [`variant_${StandardTaskStatus.ONGOING}`]: 'En cours',
      [`variant_${StandardTaskStatus.TESTING}`]: 'À tester',
      [`variant_${StandardTaskStatus.DONE}`]: 'Terminée',
    },
  },
  activities: {
    type: {
      [`variant_${ActivityVerb.CREATE}`]: 'Création',
      [`variant_${ActivityVerb.UPDATE}`]: 'Modification',
      [`variant_${ActivityVerb.DELETE}`]: 'Suppression',
    },
    data: {
      variant_projects: "d'un project",
      variant_sites: "d'un site",
      variant_assets: "d'un document",
      variant_synoptics: "d'un synoptique",
      variant_alarms: "d'une alarme",
      variant_reports: "d'un rapport",
      variant_playgrounds: "d'un playground",
      variant_tasks: "d'une tâche",
    },
    label: `$t(projects:activities.type.variant, { "context": "{{type}}" }) $t(projects:activities.data.variant, { "context": "{{data}}" })`,
  },
  template: {
    name: {
      optimisationCip: 'Optimisation des temps de NEP',
      optimisationEnvironment: 'Optimisation environnementale',
    },
    tasks: {
      traceCleanProfile: '1. Tracer les profils de lavage',
      analyse: '2. Analyser',
      defineNewTime: '3. Définition des nouveaux temps',
      preValidationQuality: '4. Pré-validation Qualité',
      editAutomatism: '5. Recalage temps: action automatisme',
      postValidationQuality: '6. Post-validation Qualité',
      technicalValidation: 'Validation technique',
      financialValidation: 'Validation financière',
      environmentalValidation: 'Validation environnementale',
    },
  },
  grid: {
    noConsumption: 'Aucune consommation saisie',
    noSavings: 'Aucun gain',
    noForecastedSavings: 'Aucun gain prévisionnel',
    savings: 'Gains',
    carbon: 'Carbone',
    electricity: 'Électricité',
    gas: 'Gaz',
    fuel: 'Fioul',
    water: 'Eau',
    product: 'Produit',
    financial: 'Économie',
  },
  unit: {
    PCS: 'pcs',
  },
  toast: {
    delete: {
      success: 'Le projet a été supprimé avec succès',
      error: 'Une erreur est survenue lors de la suppression du projet',
    },
  },
};
