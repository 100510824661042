import { FC, useContext, useMemo } from 'react';

import { PropsContext } from 'components/VNC/context';
import { useVncStore } from 'zustand/stores/vnc';

import BlockTypeElementList from './BlockTypeElementList';
import FiltersView from './FiltersView';
import FoldersView from './FoldersView';

const BlockDiagramList: FC = () => {
  const { standardBlockMetricMode } = useContext(PropsContext);

  const standardBlocksFilter = useVncStore(state => state.filters.standardBlocks);
  const tagsFilter = useVncStore(state => state.filters.tags);

  const isFiltersView = useMemo(
    () => Object.values(standardBlocksFilter).some(value => value) || Object.values(tagsFilter).some(value => value),
    [standardBlocksFilter, tagsFilter],
  );

  return (
    <>
      {standardBlockMetricMode && <BlockTypeElementList />}
      {!standardBlockMetricMode && (
        <>
          {!isFiltersView && <FoldersView />}
          {isFiltersView && <FiltersView />}
        </>
      )}
    </>
  );
};

export default BlockDiagramList;
