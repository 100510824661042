import { ArrowRight } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
import { useMatch, useParams } from 'react-router-dom';

import { BlockTypeInfo, UUID } from '@dametis/core';

import BlockTypeIcon from 'components/Lego/UI/BlockType/BlockTypeIcon';

import ContentItemContainer, { ACTIONS_CLASSNAME } from './ContentItemContainer';

export interface BlockTypeItemProps {
  blockType: BlockTypeInfo;
  selected?: boolean;
}

const BlockTypeItem: FC<BlockTypeItemProps> = ({ blockType, selected = false }) => {
  // const { t } = useTranslation('lego');

  const { folderId } = useParams<{ folderId?: UUID }>();

  const isNoFolderTypesRoute = Boolean(useMatch(`/blockTypes/noFolder/*`));

  const itemTo = useMemo(
    () => (!isNoFolderTypesRoute ? `/blockTypes/${folderId}/${blockType.uuid}` : `/blockTypes/noFolder/${blockType.uuid}`),
    [isNoFolderTypesRoute, folderId, blockType],
  );

  return (
    <ContentItemContainer selected={selected} state={{ noScrollTop: true }} to={itemTo}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={false}>
          <Stack alignItems="center" justifyContent="center" position="relative" width={35}>
            <BlockTypeIcon primaryIconColor={blockType.content.color} sx={{ fontSize: 26 }} />
          </Stack>
        </Grid>
        <Grid item xs>
          <Typography variant="h6">{blockType.name}</Typography>
        </Grid>
        {/* SLE: uncomment that : <Grid item xs>
          <Typography variant="caption">{t('label.parametersLength', { count: blockType.parameters.length })}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="caption">{t('label.metricsLength', { count: blockType.metrics.length })}</Typography>
        </Grid> */}
        <Grid item xs={false}>
          <Stack alignItems="center" className={ACTIONS_CLASSNAME} justifyContent="center">
            <ArrowRight />
          </Stack>
        </Grid>
      </Grid>
    </ContentItemContainer>
  );
};

export default BlockTypeItem;
