import { makeStyles } from '@mui/styles';

export const useErrorStyles = makeStyles(theme => ({
  errorPaper: {
    margin: '0 auto',
    maxWidth: '30rem',
    paddingBottom: 36.5,
  },
  errorPaper__image: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  errorPaper__text: {
    '& h1': {
      marginBottom: theme.spacing(2),
    },
    '& h6': {
      lineHeight: '22px',
      marginBottom: theme.spacing(2),
    },
  },
  errorPaper__button: {
    float: 'right',
  },
  errorPaper__children: {
    marginBottom: theme.spacing(4),
    wordBreak: 'break-word',
  },
}));
