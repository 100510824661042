import { v4 as uuidv4 } from 'uuid';

import { ConnectionType, StandardBlockConnection, StandardBlockConnectionBody } from '@dametis/core';

export const createStandardBlockConnection = ({
  uuid = uuidv4(),
  blockKey = '',
  blockTypeId = '',
  type = ConnectionType.MULTIPLE,
}: Partial<StandardBlockConnection>): StandardBlockConnection => ({
  uuid,
  blockKey,
  blockTypeId,
  type,
});

export const createStandardBlockConnectionBody = ({
  blockKey = '',
  blockTypeId = '',
  type = ConnectionType.MULTIPLE,
}: Partial<StandardBlockConnectionBody>): StandardBlockConnectionBody => ({
  blockKey,
  blockTypeId,
  type,
});
