import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { PhysicalQuantity } from '@dametis/core';
import { MultipleName, UnitName } from '@dametis/unit';

import { CommonProps, IsListElementItem, IsListElementNewOption, ListElement, UnitPickerMenu } from './types';

export type UnitPickerProviderProps = CommonProps;

export interface UnitPickerContextState extends UnitPickerProviderProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  focusIndex: number;
  setFocusIndex: Dispatch<SetStateAction<number>>;
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  listElements: ListElement[];
  setListElements: Dispatch<SetStateAction<ListElement[]>>;
  selectedMenu: UnitPickerMenu;
  setSelectedMenu: Dispatch<SetStateAction<UnitPickerMenu>>;
  columnsPhysicalQuantities: string[];
  setColumnsPhysicalQuantities: Dispatch<SetStateAction<string[]>>;
  columnsUnits: string[];
  setColumnsUnits: Dispatch<SetStateAction<string[]>>;
  columnsMultiples: MultipleName[];
  setColumnsMultiples: Dispatch<SetStateAction<MultipleName[]>>;
  columnsSelectedPhysicalQuantity: PhysicalQuantity | null;
  setColumnsSelectedPhysicalQuantity: Dispatch<SetStateAction<PhysicalQuantity | null>>;
  columnsSelectedUnit: string | null;
  setColumnsSelectedUnit: Dispatch<SetStateAction<string | null>>;
  columnsSelectedMultiple: MultipleName | null;
  setColumnsSelectedMultiple: Dispatch<SetStateAction<MultipleName | null>>;
  pickerSubmit: (element: ListElement) => void;
}

export const UnitPickerContext = createContext<UnitPickerContextState | undefined>(undefined);

const UnitPickerProvider: FC<PropsWithChildren<UnitPickerProviderProps>> = ({
  value = null,
  onChange,
  baseUnit,
  freeMode = true,
  defaultMenu = UnitPickerMenu.LIST,
  children = undefined,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [focusIndex, setFocusIndex] = useState<number>(-1);
  const [inputValue, setInputValue] = useState<string>('');
  const [listElements, setListElements] = useState<ListElement[]>([]);
  const [selectedMenu, setSelectedMenu] = useState<UnitPickerMenu>(UnitPickerMenu.LIST);
  const [columnsPhysicalQuantities, setColumnsPhysicalQuantities] = useState<string[]>([]);
  const [columnsUnits, setColumnsUnits] = useState<string[]>([]);
  const [columnsMultiples, setColumnsMultiples] = useState<MultipleName[]>([]);
  const [columnsSelectedPhysicalQuantity, setColumnsSelectedPhysicalQuantity] = useState<PhysicalQuantity | null>(null);
  const [columnsSelectedUnit, setColumnsSelectedUnit] = useState<string | null>(null);
  const [columnsSelectedMultiple, setColumnsSelectedMultiple] = useState<MultipleName | null>(null);

  const pickerSubmit = useCallback(
    (element: ListElement) => {
      if (IsListElementItem(element)) {
        onChange(element.data.unit !== UnitName.NO_UNIT ? element.data.unitWithMultiple : '');
        setOpen(false);
      }
      if (IsListElementNewOption(element)) {
        onChange(element.data.value);
        setOpen(false);
      }
    },
    [onChange, setOpen],
  );

  const contextValues: UnitPickerContextState = useMemo(
    () => ({
      value,
      onChange,
      baseUnit,
      freeMode,
      defaultMenu,
      open,
      setOpen,
      focusIndex,
      setFocusIndex,
      inputValue,
      setInputValue,
      listElements,
      setListElements,
      selectedMenu,
      setSelectedMenu,
      columnsPhysicalQuantities,
      setColumnsPhysicalQuantities,
      columnsUnits,
      setColumnsUnits,
      columnsMultiples,
      setColumnsMultiples,
      columnsSelectedPhysicalQuantity,
      setColumnsSelectedPhysicalQuantity,
      columnsSelectedUnit,
      setColumnsSelectedUnit,
      columnsSelectedMultiple,
      setColumnsSelectedMultiple,
      pickerSubmit,
    }),
    [
      value,
      onChange,
      baseUnit,
      freeMode,
      defaultMenu,
      open,
      focusIndex,
      inputValue,
      listElements,
      selectedMenu,
      columnsPhysicalQuantities,
      columnsUnits,
      columnsMultiples,
      columnsSelectedPhysicalQuantity,
      columnsSelectedUnit,
      columnsSelectedMultiple,
      pickerSubmit,
    ],
  );

  useEffect(() => {
    setSelectedMenu(defaultMenu);
  }, [defaultMenu]);

  return <UnitPickerContext.Provider value={contextValues}>{children}</UnitPickerContext.Provider>;
};

export const useUnitPickerContext = () => {
  const context = useContext(UnitPickerContext);

  if (context === undefined) {
    throw Error('useUnitPickerContext must be used inside an UnitPickerProvider');
  }

  return context;
};

export default UnitPickerProvider;
