import { createSlice } from '@reduxjs/toolkit';

import { DataOperationInfo } from '@dametis/core';

export interface AdvancedEntryState {
  operations: DataOperationInfo[];
}

const initialState: AdvancedEntryState = {
  operations: [],
};

export const dataHistory = createSlice({
  name: 'dataHistory',
  initialState,
  reducers: {
    setDataHistoryOperations: (state, action) => {
      state.operations = action.payload;
    },
    resetDataHistoryStore: () => initialState,
  },
});

export const { setDataHistoryOperations, resetDataHistoryStore } = dataHistory.actions;

export default dataHistory.reducer;
