import { Multiple, MultipleName, RelevantMultiplesByUnitNames, UnitName } from '@dametis/unit';

import { getMultipleList } from './getMultipleList';

export const getUnitRelevantMultiples = (unit: string): Multiple[] => {
  const relevantMultipleNames: MultipleName[] = [
    ...(RelevantMultiplesByUnitNames[unit as UnitName] ?? Object.values(MultipleName)),
    MultipleName.NONE,
  ];
  const multiples = getMultipleList();
  return multiples.filter(multiple => relevantMultipleNames.includes(multiple.name));
};
