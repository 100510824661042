import { Stack, StackProps } from '@mui/material';
import { FC } from 'react';

const HeaderPrimaryRight: FC<StackProps> = ({ children = null, ...props }) => (
  <Stack alignItems="center" direction="row" justifyContent="flex-end" ml="auto" spacing={1.5} {...props}>
    {children}
  </Stack>
);

export default HeaderPrimaryRight;
