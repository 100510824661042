import Fuse, { IFuseOptions } from 'fuse.js';
import { useEffect, useMemo, useRef, useState } from 'react';

const useFuse = <Type>(list: Type[], search: string, options: IFuseOptions<Type>, debounceDelay: number = 200) => {
  const debounceTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  const [debouncedSearch, setDebouncedSearch] = useState(search);

  useEffect(() => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      setDebouncedSearch(search);
    }, debounceDelay);

    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [debounceDelay, search]);

  const fuse = useMemo(() => new Fuse(list, { ignoreLocation: true, threshold: 0.2, ...options }), [list, options]);
  return useMemo(
    () => (debouncedSearch.trim() ? fuse.search(debouncedSearch).map(({ item }) => item) : list),
    [fuse, list, debouncedSearch],
  );
};

export default useFuse;
