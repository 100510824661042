import { Stack, SvgIconProps, useTheme } from '@mui/material';
import { FC, useMemo, useRef } from 'react';
import { useResizeObserver } from 'usehooks-ts';

import { DeployedCode } from 'assets/icons/DeployedCode';
import DamIconComponent from 'components/UI/IconPicker/DamIconComponent';
import { availableIcons } from 'config/iconPicker';
import { DamIconName } from 'types/iconPicker';

export interface BlockTypeIconProps extends Omit<SvgIconProps, 'color'> {
  primaryIconColor?: string;
  // secondaryIconName?: `${DamIconName}` | string;
}

const BlockTypeIcon: FC<BlockTypeIconProps> = ({ primaryIconColor = undefined, sx, ...props }) => {
  const theme = useTheme();

  const containerRef = useRef<HTMLDivElement>(null);

  const { width: containerWidth = 0 } = useResizeObserver({
    ref: containerRef,
  });

  const parsedColor = useMemo(() => primaryIconColor ?? theme.palette.gradients.greens[2], [theme, primaryIconColor]);
  const contrastText = useMemo(() => theme.palette.white, [theme]);
  // const contrastText = useMemo(() => theme.palette.getContrastText(parsedColor), [theme, parsedColor]);
  const secondaryIconSize = useMemo(
    () => Math.round(containerWidth / 1.5) + (Math.round(containerWidth / 1.5) % 2 !== containerWidth % 2 ? 1 : 0),
    [containerWidth],
  );

  return (
    <Stack ref={containerRef} alignItems="center" justifyContent="center" position="relative">
      <DeployedCode sx={{ color: parsedColor, ...sx }} {...props} />
      <Stack alignItems="center" height={1} justifyContent="center" left={0} position="absolute" top={0} width={1}>
        <DamIconComponent
          color={contrastText}
          prop={availableIcons[DamIconName.BLOCKTYPE].prop}
          size={secondaryIconSize}
          type={availableIcons[DamIconName.BLOCKTYPE].type}
        />
      </Stack>
    </Stack>
  );
};

export default BlockTypeIcon;
