import { TooltipProps as MuiTooltipProps, SvgIconProps, Tooltip, styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Energies, PrimaryEnergies } from '@dametis/core';

import { energies } from 'config';

const TooltipPropsDefaultProp: Partial<MuiTooltipProps> = {};

export interface EnergyIconProps extends Omit<SvgIconProps, 'color'> {
  energy: Energies | PrimaryEnergies;
  color?: 'primary' | 'secondary';
  tooltip?: boolean;
  TooltipProps?: Partial<MuiTooltipProps>;
  disabled?: boolean;
}

const EnergyIcon: FC<EnergyIconProps> = ({
  energy,
  color = 'primary',
  tooltip = false,
  TooltipProps = TooltipPropsDefaultProp,
  disabled = false,
  ...props
}) => {
  const { t } = useTranslation('global');

  if (!energy || !energies[energy]) return null;
  const iconColor = energies[energy][`${color}Color`];
  const Icon = styled(energies[energy].icon)({
    color: iconColor,
    ...(disabled && { filter: 'grayscale(100%)', opacity: 0.6 }),
  });
  return (
    <Tooltip title={tooltip ? t(`energy.${energy}`) : ''} {...TooltipProps}>
      <Icon {...props} />
    </Tooltip>
  );
};

export default EnergyIcon;
