import { addSeconds, isValid, min } from 'date-fns';

import { CacheVariableKind, CalculationVariable, GetCalculationVariableStats, Period } from '@dametis/core';

import { subDays, subHours, subWeeks } from 'localization/localizedDateFns';
import { TypedThunk } from 'store';
import { FromPeriod, ToPeriod, setLastPointIsFetching } from 'store/slices/calculationMenu';

import { sdk } from '../../../sdk';

export const fetchCalculationLastPointDate =
  (groupId: string, calculation: CalculationVariable): TypedThunk<Promise<Date | null>> =>
  async dispatch => {
    dispatch(setLastPointIsFetching(true));
    const { variableIds } = GetCalculationVariableStats(calculation);
    if (variableIds.length === 0) {
      return null;
    }
    try {
      const { data: lastPoints } = await sdk.tada.Last(
        groupId,
        variableIds.map(uuid => ({ kind: CacheVariableKind.VARIABLES, uuid })),
      );
      const earliestLastDate = min(lastPoints.filter(lastPoint => lastPoint !== null).map(lastPoint => new Date(lastPoint.time)));
      if (!isValid(earliestLastDate)) {
        return null;
      }
      return earliestLastDate;
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      dispatch(setLastPointIsFetching(false));
    }
  };

export const getPeriod = (selectedFrom: FromPeriod, selectedTo: ToPeriod, lastPointDate: Date | null): Period | null => {
  const selectedToDate = selectedTo === ToPeriod.LAST_POINT && lastPointDate !== null ? lastPointDate : new Date();
  if (!isValid(selectedToDate)) {
    return null;
  }
  try {
    switch (selectedFrom) {
      case FromPeriod.LAST_HOUR:
        return new Period({
          from: subHours(selectedToDate, 1),
          to: addSeconds(selectedToDate, 1),
        });
      case FromPeriod.LAST_DAY:
        return new Period({
          from: subDays(selectedToDate, 1),
          to: addSeconds(selectedToDate, 1),
        });
      case FromPeriod.LAST_WEEK:
        return new Period({
          from: subWeeks(selectedToDate, 1),
          to: addSeconds(selectedToDate, 1),
        });
      default:
        return new Period({
          from: subHours(selectedToDate, 1),
          to: addSeconds(selectedToDate, 1),
        });
    }
  } catch (err) {
    console.error(err);
    return null;
  }
};
