export default {
  title: 'Invite via link',
  text: {
    expiration: 'This invitation link will expire',
  },
  select: {
    one_day: 'in 24 hours',
    two_days: 'in 48 hours',
    one_week: 'in 7 days',
    one_month: 'in 30 days',
    never: 'never',
  },
  button: {
    generate: 'GENERATE',
    close: 'OK',
    invite: 'Invite via link',
  },
  alert: 'Everyone with this link can create an account.',
  table: {
    kind: 'Redirects to',
    owner: 'Created by',
    link: 'Invitation link',
    invitation: 'Invitation',
    expiration: 'Expiration',
    site: 'MyDametis',
    noInvitation: 'No invitation',
    unknown: 'User deleted',
  },
  toast: {
    copy: 'Invitation link copied to clipboard',
    revoke: 'Invitation successfully revoked',
  },
};
