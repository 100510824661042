export enum BoxStatusLabel {
  NORMAL = 'normal',
  WARNING = 'warning',
  CRITICAL = 'critical',
}

export const enum BoxVersionStatus {
  WARNING = 'warning',
  ERROR = 'error',
  DEFAULT = 'default',
  SUCCESS = 'success',
}
