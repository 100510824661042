import { ShortcutCategory, WidgetContentShortcutsAppearance } from '@dametis/core';

const name: Record<ShortcutCategory, string> = {
  [ShortcutCategory.SYNOPTIC]: 'Synoptics',
  [ShortcutCategory.ALARM]: 'Alarms',
  [ShortcutCategory.REPORT]: 'Reports',
  [ShortcutCategory.PLAYGROUND]: 'Playgrounds',
  [ShortcutCategory.PROJECT]: 'Projects',
  [ShortcutCategory.TASK]: 'Tasks',
  [ShortcutCategory.COMMENT]: 'Comments',
  [ShortcutCategory.SITE]: 'Sites',
  [ShortcutCategory.ASSET]: 'Documents',
  [ShortcutCategory.VARIABLE]: 'Variables',
  [ShortcutCategory.HREF]: 'URL',
  [ShortcutCategory.BLOCK]: 'Blocks',
  [ShortcutCategory.BLOCK_TYPE]: 'Block types',
  [ShortcutCategory.STANDARD_BLOCK]: 'Standard block',
  [ShortcutCategory.MACRO]: 'Macros',
  [ShortcutCategory.CART]: 'Cart',
  [ShortcutCategory.CONTRACT]: 'Contracts',
  [ShortcutCategory.BILL]: 'Bills',
  [ShortcutCategory.BATCH]: 'Batches',
  [ShortcutCategory.MODEL]: 'Models',
};

const nameOne: Record<`${ShortcutCategory}_one`, string> = {
  synoptics_one: 'synoptic',
  alarms_one: 'alarm',
  reports_one: 'report',
  playgrounds_one: 'playground',
  projects_one: 'project',
  tasks_one: 'task',
  comments_one: 'comment',
  sites_one: 'site',
  assets_one: 'document',
  variable_one: 'variable',
  href_one: 'URL',
  blocks_one: 'block',
  blockTypes_one: 'block type',
  standardBlocks_one: 'standard block',
  macros_one: 'macro',
  cart_one: 'cart',
  contracts_one: 'contract',
  bills_one: 'bill',
  batches_one: 'batch',
  models_one: 'model',
};

const nameAOne: Record<`${ShortcutCategory}_a_one`, string> = {
  synoptics_a_one: 'a $t(shortcut:name.synoptics_one)',
  alarms_a_one: 'an $t(shortcut:name.alarms_one)',
  reports_a_one: 'a $t(shortcut:name.reports_one)',
  playgrounds_a_one: 'a $t(shortcut:name.playgrounds_one)',
  projects_a_one: 'a $t(shortcut:name.projects_one)',
  tasks_a_one: 'a $t(shortcut:name.tasks_one)',
  comments_a_one: 'a $t(shortcut:name.comments_one)',
  sites_a_one: 'a $t(shortcut:name.sites_one)',
  assets_a_one: 'an $t(shortcut:name.assets_one)',
  variable_a_one: 'a $t(shortcut:name.variable_one)',
  href_a_one: 'an $t(shortcut:name.href_one)',
  blocks_a_one: 'a $t(shortcut:name.blocks_one)',
  blockTypes_a_one: 'a $t(shortcut:name.blockTypes_one)',
  standardBlocks_a_one: 'a $t(shortcut:name.standardBlocks_one)',
  macros_a_one: 'a $t(shortcut:name.macros_one)',
  cart_a_one: 'the $t(shortcut:name.cart_one)',
  contracts_a_one: 'a $t(shortcut:name.contracts_one)',
  bills_a_one: 'a $t(shortcut:name.bills_one)',
  batches_a_one: 'a $t(shortcut:name.batches_one)',
  models_a_one: 'a $t(shortcut:name.models_one)',
};

const nameThisOne: Record<`${ShortcutCategory}_this_one`, string> = {
  synoptics_this_one: 'this $t(shortcut:name.synoptics_one)',
  alarms_this_one: 'this $t(shortcut:name.alarms_one)',
  reports_this_one: 'this $t(shortcut:name.reports_one)',
  playgrounds_this_one: 'this $t(shortcut:name.playgrounds_one)',
  projects_this_one: 'this $t(shortcut:name.projects_one)',
  tasks_this_one: 'this $t(shortcut:name.tasks_one)',
  comments_this_one: 'this $t(shortcut:name.comments_one)',
  sites_this_one: 'this $t(shortcut:name.sites_one)',
  assets_this_one: 'this $t(shortcut:name.assets_one)',
  variable_this_one: 'this $t(shortcut:name.variable_one)',
  href_this_one: 'this $t(shortcut:name.href_one)',
  blocks_this_one: 'this $t(shortcut:name.blocks_one)',
  blockTypes_this_one: 'this $t(shortcut:name.blockTypes_one)',
  standardBlocks_this_one: 'this $t(shortcut:name.standardBlocks_one)',
  macros_this_one: 'this $t(shortcut:name.macros_one)',
  cart_this_one: 'the $t(shortcut:name.cart_one)',
  contracts_this_one: 'this $t(shortcut:name.contracts_one)',
  bills_this_one: 'this $t(shortcut:name.bills_one)',
  batches_this_one: 'this $t(shortcut:name.batches_one)',
  models_this_one: 'this $t(shortcut:name.models_one)',
};

export default {
  name: {
    ...name,
    ...nameOne,
    ...nameAOne,
    ...nameThisOne,
  },
  text: {
    shortcut_one: 'Shortcut',
    shortcut_other: 'Shortcuts',
    noShortcut: 'Undefined shortcut',
    brokenLink: 'Broken link',
    noElement: 'No element',
    shortcutCategory: 'Shortcut category',
    notFound: 'Not found',
  },
  tooltip: {
    synoptics: 'Synoptics',
    alarms: 'Alarms',
    reports: 'Reports',
    playgrounds: 'Playgrounds',
    projects: 'Projects',
    selectShortcut: 'Select shortcut',
    removeShortcut: 'Remove shortcut',
    [`shortcutCategory_${ShortcutCategory.ALARM}`]: 'Alarm',
    [`shortcutCategory_${ShortcutCategory.HREF}`]: 'Custom',
    [`shortcutCategory_${ShortcutCategory.PLAYGROUND}`]: 'Playground',
    [`shortcutCategory_${ShortcutCategory.PROJECT}`]: 'Project',
    [`shortcutCategory_${ShortcutCategory.REPORT}`]: 'Report',
    [`shortcutCategory_${ShortcutCategory.SYNOPTIC}`]: 'Synoptic',
  },
  label: {
    link: 'Link',
    customize: 'Customize',
    tabs: 'Shortcut tabs',
    text: 'Text formatting',
    bold: 'Bold',
    italic: 'Italic',
    underlined: 'Underlined',
    delete: 'Delete',
    save: 'save',
    cancel: 'cancel',
    selectPeriod: 'Select a period',
    noPeriod: 'No period selected',
    setPeriod: 'Set a period',
    absolute: 'Absolute',
    relative: 'Relative',
    date: 'Date',
    startDate: 'Start date',
    endDate: 'End date',
    presets: 'Presets',
    periodType: 'Period type',
    invalidPeriod: 'Invalid period',
    label: 'Label',
    url: 'Url',
  },
  placeholder: {
    label: 'Label',
    url: 'https://...',
    search: 'Search a shortcut',
  },
  period: {
    relativeWarning: 'The selected period is relative to the current date.',
    none: 'None',
    custom: 'Customized',
    reset: 'Reset',
    relative: 'Relative',
    lastMonth: 'Last month',
    lastWeek: 'Last week',
    thisMonth: 'This month',
    thisWeek: 'This week',
    since: 'Since',
    last_month_start: 'Start of last month',
    last_month_end: 'End of last month',
    last_week_start: 'Start of last week',
    last_week_end: 'End of last week',
    this_week: 'Start of week',
    this_month: 'Start of month',
    now: 'Now',
    fullnow: 'Now (full)',
  },
  unit: {
    m_one: 'minute',
    m_other: 'minutes',
    h_one: 'hour',
    h_other: 'hours',
    d_one: 'day',
    d_other: 'days',
    w_one: 'week',
    w_other: 'weeks',
    mo_one: 'month',
    mo_other: 'months',
    y_one: 'year',
    y_other: 'years',
  },
  alert: {
    selectRelativePeriod: 'This period will be relative to now',
    nonePeriod: 'No period selected',
  },
  fullPeriod: {
    format: 'Format',
    label: 'Full periods',
    helper: 'Example: the full periods of a week always run from Monday to Sunday.',
  },
  toast: {
    periodReset: 'Period was reset because it was invalid.',
  },
  button: {
    cancel: 'Cancel',
    validate: 'Validate',
    addShortcut: 'Add',
    addEntityShortcut: 'Add standard shortcut',
    addHrefShortcut: 'Add custom shortcut',
    selectShortcut: 'Select',
    selectEntityShortcut: 'Select standard shortcut',
    selectHrefShortcut: 'Select custom shortcut',
  },
  title: {
    customShortcut: 'Custom shortcut',
  },
  select: {
    [`appearance_${WidgetContentShortcutsAppearance.CHIP}`]: 'Compact',
    [`appearance_${WidgetContentShortcutsAppearance.EXPANDED_TILE}`]: 'Expanded',
    [`appearance_${WidgetContentShortcutsAppearance.COLLAPSED_TILE}`]: 'Collapsed',
  },
};
