import { InputBase, inputBaseClasses, styled } from '@mui/material';

const PopoverInput = styled(InputBase)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  alignSelf: 'center',
  padding: `0 ${theme.spacing(1)}`,
  transition: theme.transitions.create(['background-color']),
  [`&.${inputBaseClasses.focused}`]: {
    backgroundColor: theme.palette.grey[100],
  },
}));

export default PopoverInput;
