import { ArrowRight, Folder, MoreVertOutlined } from '@mui/icons-material';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
// import { useTranslation } from 'react-i18next';

import { FolderInfo } from '@dametis/core';

import { getFolderColor } from 'components/Lego/helpers/getFolderColor';

import ContentItemContainer, { ACTIONS_CLASSNAME } from '../ContentItemContainer';

import FolderItemMenu from './FolderItemMenu';

export interface FolderItemProps {
  folder: FolderInfo;
}

const FolderItem: FC<FolderItemProps> = ({ folder }) => {
  // const { t } = useTranslation('lego');

  const [folderMenuAnchorEl, setFolderMenuAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isFolderMenuOpen = useMemo(() => Boolean(folderMenuAnchorEl), [folderMenuAnchorEl]);
  // const types = useMemo(() => folder.shortcuts.filter(shortcut => shortcut.category === ShortcutCategory.BLOCK_TYPE), [folder.shortcuts]);
  // const folders = useMemo(() => folder.folders, [folder.folders]);

  const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setFolderMenuAnchorEl(event.currentTarget);
  }, []);

  const handleMouseDownMenu: MouseEventHandler = useCallback(event => {
    event.stopPropagation();
  }, []);

  const handleCloseMenu = useCallback(() => {
    setFolderMenuAnchorEl(null);
  }, []);

  return (
    <>
      <ContentItemContainer selected={isFolderMenuOpen} state={{ noScrollTop: true }} to={`/blockTypes/${folder.uuid}`}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={false}>
            <Stack alignItems="center" justifyContent="center" width={35}>
              <Folder sx={{ color: theme => getFolderColor(folder, theme), fontSize: 35 }} />
            </Stack>
          </Grid>
          <Grid item xs>
            <Typography variant="h6">{folder.name}</Typography>
          </Grid>
          {/* SLE: uncomment that : <Grid item xs>
            <Typography variant="caption">{t('label.blockTypesLength', { count: types.length })}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="caption">{t('label.foldersLength', { count: folders.length })}</Typography>
          </Grid> */}
          <Grid item xs={false}>
            <Stack alignItems="center" className={ACTIONS_CLASSNAME} direction="row" justifyContent="center">
              <IconButton size="small" onClick={handleOpenMenu} onMouseDown={handleMouseDownMenu}>
                <MoreVertOutlined fontSize="small" />
              </IconButton>
              <ArrowRight />
            </Stack>
          </Grid>
        </Grid>
      </ContentItemContainer>
      <FolderItemMenu anchorEl={folderMenuAnchorEl} folder={folder} isOpen={isFolderMenuOpen} onClose={handleCloseMenu} />
    </>
  );
};

export default FolderItem;
