import type { AxisOptions } from 'highcharts';
import Axis from 'highcharts/es-modules/Core/Axis/Axis';
import { v4 as uuid } from 'uuid';

import { getFormattedValue } from 'components/UI/UnitPicker/functions/getFormattedValue';
import { UnitResult } from 'components/UI/UnitPicker/types';
import { theme } from 'theme';

import { DaChart } from './DaChart';

export interface DaAxisProps {
  color?: string;
  id?: string;
  unit?: string;
  apiUnit?: string;
  pretty?: boolean;
  hidden?: boolean;
  min?: number;
  max?: number;
}

export class DaAxis extends Axis {
  color: string;

  declare options: AxisOptions;

  pretty: boolean;

  unit: string | null;

  userMin: number | null;

  userMax: number | null;

  public constructor(
    daChart: DaChart,
    { color = '#000', unit = null, apiUnit = null, pretty = false, hidden = false, min = null, max = null }: DaAxisProps,
  ) {
    const options: AxisOptions = {
      id: uuid(),
      title: {
        text: null,
      },
      labels: {
        formatter() {
          return getFormattedValue({ value: this.value as number, baseUnit: apiUnit, userUnit: unit });
        },
        style: {
          color,
          fontSize: '11px',
        },
        x: -6,
        y: 4,
      },
      gridLineColor: pretty ? null : theme.palette.grey[200],
      tickWidth: 1,
      tickLength: 1,
      tickColor: `${color}80`,
      margin: 6,
      gridLineWidth: 1,
      lineColor: pretty ? 'white' : `${color}80`,
      visible: !hidden,
    };
    super(daChart, options, 'yAxis');
    this.userMin = min;
    this.userMax = max;
    this.color = color;
    this.pretty = pretty;
    this.unit = unit ?? '';
    if (this.chart.yAxis.length === 2 && !this.chart.yAxis[0].series.length) this.chart.yAxis[0].remove();
  }

  setColor(color: string): void {
    this.update(
      {
        labels: {
          style: {
            color,
          },
        },
        tickColor: `${color}80`,
        lineColor: `${color}80`,
      },
      false,
    );
    this.color = color;
  }

  setUnit(unit: string, apiUnit: UnitResult = null): void {
    this.update(
      {
        labels: {
          formatter() {
            return getFormattedValue({ value: this.value as number, baseUnit: apiUnit, userUnit: unit });
          },
        },
      },
      false,
    );
  }

  setExtremes(min: number | null | undefined, max: number | null | undefined): void {
    if (min !== undefined) this.userMin = min;
    if (max !== undefined) this.userMax = max;
    super.setExtremes(this.userMin, this.userMax, false, false);
  }
}
