import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { formatDuration, intervalToDuration } from 'date-fns';
import i18next from 'i18next';
import { FC } from 'react';

import { BOX_STATUS_LIMIT_DATE, BoxStatus, ExtendedBoxInfo } from './Boxes';

const BoxStatusIcon: FC<{ status: BoxStatus; lastSeen: Date }> = ({ status, lastSeen }) => {
  if (status === BoxStatus.UNKNOWN) {
    return <Chip label={i18next.t(`admin:boxes.boxStatus.${BoxStatus.UNKNOWN}`)} size="small" />;
  }
  if (lastSeen > BOX_STATUS_LIMIT_DATE) {
    return (
      <Stack direction="row" spacing={1}>
        <WifiIcon color="success" />
      </Stack>
    );
  }
  return (
    <Stack>
      <Stack direction="row" spacing={1}>
        <WifiOffIcon color="error" />
      </Stack>
    </Stack>
  );
};
const BoxLastSeen: FC<{ status: BoxStatus; lastSeen: Date }> = ({ status, lastSeen }) => {
  if (status === BoxStatus.UNKNOWN) {
    return <Chip label={i18next.t(`admin:boxes.boxStatus.${BoxStatus.UNKNOWN}`)} size="small" />;
  }
  if (lastSeen > BOX_STATUS_LIMIT_DATE) {
    return <></>;
  }
  return (
    <Tooltip title={new Date(lastSeen).toString()}>
      <Typography variant="caption">
        {formatDuration(intervalToDuration({ start: lastSeen, end: new Date() }), {
          format: ['years', 'months', 'days', 'hours', 'minutes'],
        })}
      </Typography>
    </Tooltip>
  );
};

export const getGridColDefObj = (): Record<string, GridColDef<ExtendedBoxInfo>> => ({
  status: {
    maxWidth: 2,
    aggregable: false,
    groupable: false,
    field: 'statusIcon',
    headerName: i18next.t('admin:boxes.columns.status'),
    align: 'left',
    valueGetter: (_, row) => row.status,
    renderCell: ({ row }) => <BoxStatusIcon lastSeen={row.lastSeen} status={row.status} />,
  },
  name: {
    flex: 4,
    field: 'name',
    headerName: i18next.t('admin:boxes.columns.name'),
    aggregable: false,
    groupable: false,
    align: 'left',
    headerAlign: 'left',
    resizable: true,
    sortable: true,
    valueGetter: (_, row) => row.name,
    renderCell: ({ value }) => <Typography>{value} </Typography>,
  },

  reference: {
    flex: 2,
    field: 'reference',
    headerName: i18next.t('admin:boxes.columns.reference'),
    aggregable: false,
    groupable: false,
    align: 'left',
    headerAlign: 'left',
    resizable: true,
    sortable: true,
    valueGetter: (_, row) => row.reference,
  },

  lastSeen: {
    flex: 5,
    field: 'lastSeen',
    headerName: i18next.t('admin:boxes.columns.lastSeen'),
    aggregable: false,
    groupable: false,
    align: 'left',
    renderCell: ({ row }) => <BoxLastSeen lastSeen={row.lastSeen} status={row.status} />,
  },

  machineId: {
    flex: 2,
    field: 'machineId',
    headerName: i18next.t('admin:boxes.columns.machineId'),
    aggregable: false,
    groupable: false,
    align: 'left',
    headerAlign: 'left',
    resizable: true,
    valueGetter: (_, row) => row.machineId,
  },

  nbDevice: {
    flex: 3,
    headerName: i18next.t('admin:boxes.columns.nbDevice'),
    aggregable: false,
    groupable: false,
    field: 'nbDevice',
    align: 'center',
    headerAlign: 'center',
    resizable: true,
    valueGetter: (_, row) => row.devices.length,
  },
});
