export default {
  button: {
    close: 'Close',
    previous: 'Previous',
    next: 'Next',
    create: 'Create',
  },
  input: {
    fromModel: 'Use a model',
  },
  stepper: {
    general: 'General',
    model: 'Model',
  },
};
