import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UUID } from '@dametis/core';

export interface MacrosState {
  progresses: Record<UUID, { name: string; logs: string; progress: string } | undefined>;
}

const initialState: MacrosState = {
  progresses: {},
};

export const macrosSlice = createSlice({
  name: 'macros',
  initialState,
  reducers: {
    setProgresses: (state, action: PayloadAction<Record<UUID, { name: string; logs: string; progress: string } | undefined>>) => {
      state.progresses = action.payload;
    },
  },
});

export const { setProgresses } = macrosSlice.actions;

export default macrosSlice.reducer;
