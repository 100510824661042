import { Components, touchRippleClasses } from '@mui/material';

export default {
  styleOverrides: {
    root: {
      [`& .${touchRippleClasses.root}`]: {
        zIndex: 20,
      },
    },
  },
} as Components['MuiButtonBase'];
