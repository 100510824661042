import { FC, useCallback, useContext } from 'react';

import { AUTO_GROUPBY, VarCalc } from '@dametis/core';

import { getDefaultOperatorForGroupBy } from 'functions/tada/getOperator';

import GroupByInput, { GroupByInputProps as IGroupByInputProps } from '../../../../UI/GroupByInput/GroupByInput';
import { PropsContext } from '../../../context';

interface Props {
  variable: Partial<VarCalc>;
  onVariableChange: (variable: Partial<VarCalc>) => void;
}

const GroupBySelect: FC<Props> = ({ variable, onVariableChange }) => {
  const { calculatedVariableMode } = useContext(PropsContext);

  const changeGroupBy = useCallback<IGroupByInputProps<true, true, false, true>['onGroupByChange']>(
    groupBy => {
      onVariableChange({ groupBy, operator: getDefaultOperatorForGroupBy(groupBy, variable.operator) });
    },
    [onVariableChange, variable.operator],
  );

  return (
    <div>
      {calculatedVariableMode ? (
        <GroupByInput enableCheckbox enablePointToPoint groupBy={variable.groupBy} onGroupByChange={changeGroupBy} />
      ) : (
        <GroupByInput
          enableAuto
          enableCheckbox
          enablePointToPoint
          defaultGroupBy={AUTO_GROUPBY}
          groupBy={variable.groupBy}
          onGroupByChange={changeGroupBy}
        />
      )}
    </div>
  );
};

export default GroupBySelect;
