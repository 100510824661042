import { v4 as uuidv4 } from 'uuid';

import { OperationInfo, OperationStates, OperationStatus } from '@dametis/core';

import { getOperationStorage, setOperationStorage } from '../../functions/operations';
import { TypedThunk } from '../index';
import { setOperations } from '../slices/operations';

export const getOperations = (): TypedThunk<void> => (dispatch, getState) => {
  const state = getState();
  const siteId = state.auth.selectedSite?.uuid;
  if (!siteId) throw new Error();
  const test = getOperationStorage(siteId);
  dispatch(setOperations(test));
};

export const updateStates =
  (newStates: OperationStates): TypedThunk<void> =>
  (dispatch, getState) => {
    const { selectedSite } = getState().auth;
    const { list: operations } = getState().operations;
    const newOperations = operations.map(operation => ({
      ...operation,
      ...(newStates[operation.uuid] && {
        status: newStates[operation.uuid].status,
        progress: newStates[operation.uuid].progress ?? null,
        notChanged: newStates[operation.uuid].progress === operation.progress ? Math.min(operation.notChanged + 1, 10) : 0,
      }),
    }));
    dispatch(setOperations(newOperations));
    setOperationStorage(selectedSite?.uuid, newOperations);
  };

export const addOperation =
  (operation: Omit<OperationInfo, 'uuid' | 'progress' | 'notChanged'>): TypedThunk<void> =>
  (dispatch, getState) => {
    const { selectedSite } = getState().auth;
    const { list: operations } = getState().operations;
    const item = { ...operation, uuid: uuidv4(), progress: null, notChanged: 0 };
    const newOperations = operations.map(existingOperation => {
      if (existingOperation.entityUuid === item.entityUuid && existingOperation.status === OperationStatus.LOADING) {
        // same variable already loading
        return {
          ...existingOperation,
          status: OperationStatus.CANCELLED,
        };
      }
      return existingOperation;
    });
    newOperations.push(item);
    dispatch(setOperations(newOperations));
    setOperationStorage(selectedSite?.uuid, newOperations);
  };

export const deleteOperation =
  (operationUuid: OperationInfo['uuid']): TypedThunk<void> =>
  (dispatch, getState) => {
    const { selectedSite } = getState().auth;
    const { list: operations } = getState().operations;
    const i = operations.findIndex(operation => operation.uuid === operationUuid);
    if (i !== -1) {
      const newOperations = [...operations];
      newOperations.splice(i, 1);
      dispatch(setOperations(newOperations));
      setOperationStorage(selectedSite?.uuid, newOperations);
    }
  };
