import { Folder } from '@mui/icons-material';
import { ListItemButton, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { FindFolderPath, FolderInfo } from '@dametis/core';

import { getFolderColor } from 'components/Lego/helpers/getFolderColor';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';

import { MenuTab } from '../LegoCatalog';

import SuggestionBreadcrumbs from './SuggestionBreadcrumbs/SuggestionBreadcrumbs';
// import { getFolderUrl } from 'components/Lego/helpers/getFolderUrl';

export interface FolderSuggestionProps {
  folder: FolderInfo;
}

const FolderSuggestion: FC<FolderSuggestionProps> = ({ folder }) => {
  const { t } = useTranslation('lego');

  const { data: globalRootFolder = null } = useReadGlobalRootFolderQuery();

  const parsedGlobalRootFolder: FolderInfo | null = useMemo(
    () => (globalRootFolder ? { ...globalRootFolder, name: t('lego:text.factory') } : null),
    [globalRootFolder, t],
  );

  const path = useMemo(() => {
    if (!parsedGlobalRootFolder) {
      return [];
    }
    try {
      return FindFolderPath(parsedGlobalRootFolder, folder);
    } catch (err) {
      return [];
    }
  }, [folder, parsedGlobalRootFolder]);

  return (
    <ListItemButton component={NavLink} to={`/blockTypes/${folder.uuid}`}>
      {/* <ListItemButton component={NavLink} to={getFolderUrl(parsedGlobalRootFolder, folder.uuid)}> */}
      <Stack>
        {path.length > 0 && <SuggestionBreadcrumbs path={path} />}
        {path.length === 0 && (
          <Typography py={0.25} variant="caption">
            {t(`menuTab.${MenuTab.NO_FOLDER_CONTENT}`)}
          </Typography>
        )}
        <Stack alignItems="center" direction="row" gap={1}>
          <Stack alignItems="center" justifyContent="center">
            <Folder sx={{ fontSize: 22, color: theme => getFolderColor(folder, theme) }} />
          </Stack>
          <Stack>
            <Typography variant="h6">{folder.name}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </ListItemButton>
  );
};

export default FolderSuggestion;
