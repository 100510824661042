import { Components, alpha, tooltipClasses } from '@mui/material';
import { lightBlue } from '@mui/material/colors';

export default {
  defaultProps: {
    underline: 'hover',
  },
  styleOverrides: {
    root: {
      color: `${lightBlue[600]} !important`,
      backgroundColor: lightBlue[50],
      padding: '2px 5px',
      marginTop: -2,
      marginBottom: -2,
      borderRadius: 4,
      cursor: 'pointer',
      '&:hover': {
        color: `${lightBlue[400]} !important`,
      },
      [`.${tooltipClasses.tooltip} &`]: {
        backgroundColor: alpha(lightBlue[200], 0.25),
        color: `${lightBlue[200]} !important`,
      },
    },
  },
} as Components['MuiLink'];
