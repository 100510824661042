import { Format, IsDametisFormat, IsEcma376Format } from '@dametis/core';

export const areSameFormats = (formatA: Format, formatB: Format): boolean => {
  if (formatA.hideUnit !== formatB.hideUnit) {
    return false;
  }
  if (formatA.dateSystem !== formatB.dateSystem) {
    return false;
  }
  if (formatA.withTimezoneOffset !== formatB.withTimezoneOffset) {
    return false;
  }
  if (formatA.type !== formatB.type) {
    return false;
  }
  if (IsEcma376Format(formatA) && IsEcma376Format(formatB) && formatA.code !== formatB.code) {
    return false;
  }
  if (IsDametisFormat(formatA) && IsDametisFormat(formatB) && formatA.name !== formatB.name) {
    return false;
  }
  return true;
};
