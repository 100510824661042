import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react';

import { CommentInfo, CreateCommentInfo } from '@dametis/core';

import { EntityFromComment, getEntityFromComment } from 'components/UI/Comments/fonction/getEntityFromComment';

export interface CommentContextState {
  currentComment: CommentInfo | CreateCommentInfo | null;
  setCurrentComment: Dispatch<SetStateAction<CommentInfo | CreateCommentInfo | null>>;
  entity: EntityFromComment | null;
  setEntity: Dispatch<SetStateAction<EntityFromComment | null>>;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
}

export const CommentContext = createContext<CommentContextState | undefined>(undefined);

const CommentProvider: FC<PropsWithChildren> = ({ children = undefined }) => {
  const [entity, setEntity] = useState<EntityFromComment | null>(null);
  const [currentComment, setCurrentComment] = useState<CommentInfo | CreateCommentInfo | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (currentComment !== null) {
      setEntity(getEntityFromComment(currentComment));
    }
  }, [currentComment]);

  const contextValues: CommentContextState = useMemo(
    () => ({
      currentComment,
      setCurrentComment,
      entity,
      setEntity,
      submitting,
      setSubmitting,
    }),
    [currentComment, entity, submitting],
  );

  return <CommentContext.Provider value={contextValues}>{children}</CommentContext.Provider>;
};

export const useCommentContext = () => {
  const context = useContext(CommentContext);

  if (context === undefined) {
    throw Error('useCommentContext must be used inside an CommentProvider');
  }

  return context;
};

export default CommentProvider;
