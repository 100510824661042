import { Format, UUID } from '@dametis/core';

export type FormatResult = Format | null;

export enum FormatPickerMenu {
  HISTORY = 'history',
  LISTS = 'lists',
  SETTINGS = 'settings',
}

export interface StorageFormat {
  uuid: UUID;
  createdAt: Date;
  format: Format;
}
