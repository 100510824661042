export default {
  alertMessage: {
    wrongPasswordNotMatch: 'Les deux mots de passe ne sont pas les identiques',
    emptyPassword: 'Vous devez entrer un mot de passe!',
    invalidStrength: 'Veuillez choisir un mot de passe plus sûr. Essayez un mélange de lettres, chiffres et symboles.',
    emptyField: 'Veuillez remplir tous les champs',
    successInit: 'Compte activé, vous pouvez maintenant vous connecter!',
    failInit: "Échec de l'initialisation de votre compte",
    invalidInitToken: 'Ce lien ne fonctionne pas. Veuillez contacter le ',
    logDeadApi: 'Une erreur est survenue, veuillez réessayez plus tard',
  },
  title: {
    titleSection: 'Activation de votre compte',
  },
  input: {
    firstname: 'Prénom',
    lastname: 'Nom',
    language: 'Langage',
    email: 'Email',
    langue: {
      label: 'Choisir une langue',
      fr: 'Français',
      en: 'Anglais',
      es: 'Espagnol',
    },
    password: 'Mot de passe',
    passwordConfirm: 'Confirmation',
  },
  button: {
    initialize: 'Activer votre compte',
  },
};
