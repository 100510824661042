import { ModifierType } from '@dametis/core';

import { AdvancedEntryMode, AdvancedEntryPreviewMode } from 'store/slices/advancedEntry';
import { ADVANCED_ENTRY_VARIABLES_MAX_COUNT } from 'types/variables';

export default {
  title: {
    advancedEntry: 'Correction de données',
    steps: {
      variables: 'Variables',
      selectors: 'Conditions',
      transformers: 'Modificateurs',
      preview: 'Aperçu des modifications',
    },
  },
  button: {
    save: 'Appliquer',
    addVariable: 'Ajouter une variable',
    addDeviceVariables: 'Ajouter depuis un équipement',
    editMode: {
      [AdvancedEntryMode.DELETE]: 'Suppression',
      [AdvancedEntryMode.EDIT]: 'Édition',
    },
    transformerMode: {
      [ModifierType.SET_VALUE]: 'Valeur fixe',
      [ModifierType.MULTIPLIER]: 'Multiplicateur',
      [ModifierType.LINEAR_INTERPOLATION]: 'Interpolation linéaire',
      [ModifierType.OFFSET]: 'Décalage',
    },
    previewMode: {
      [AdvancedEntryPreviewMode.CHART]: 'Chart',
      [AdvancedEntryPreviewMode.GRID]: 'Grid',
    },
    removeVariable: 'Retirer la variable',
    clearVariables: 'Retirer toutes les variables',
  },
  table: {
    column: {
      date: 'Date',
      data: 'Données actuelles',
      patch: 'Données modifiées',
    },
    row: {
      noRows: 'Aucune donnée',
    },
    deleted: '(supprimé)',
  },
  text: {
    selectPatchMode: 'Sélectionnez si vous souhaitez modifier ou supprimer des données',
    selectVariables: 'Sélectionnez des variables à corriger',
    // fetchLimitWarning: 'Vous ne voyez qu’une partie des données impactées',
    fetchLimitWarning: 'Vous ne voyez que les 10 000 dernières lignes',
    patch: {
      noSelection: 'Aucune donnée sélectionnée',
      init: 'Toutes les données',
      dateBetween: ' entre le {{after}} et le {{before}}',
      after: ' après le {{after}}',
      before: ' avant le {{before}}',
      valueBetween: ' comprises entre {{min}} et {{max}}',
      min: ' supérieures à {{min}}',
      max: ' inférieures à {{max}}',
      edit: ' seront modifiées',
      delete: ' seront supprimées',
    },
    legend: {
      base: 'Données actuelles',
      edited: 'Données modifiées',
      deleted: 'Données supprimées',
    },
  },
  input: {
    after: 'Après (inclus)',
    before: 'Avant (exclus)',
    min: 'Supérieure à',
    max: 'Inférieure à',
    offset: 'Décalage',
    multiplier: 'Multiplicateur',
    setValue: 'Valeur fixe',
    linearInterpolation: {
      in1: 'In1',
      out1: 'Out1',
      in2: 'In2',
      out2: 'Out2',
    },
    error: {
      minDate: 'La date doit être après',
      maxDate: 'La date doit être avant',
      invalidDate: 'Date invalide',
    },
  },
  label: {
    delete: 'Supprimé',
  },
  toast: {
    advancedEntryEditSuccess: 'Les modifications sont en cours de traitement',
    // advancedEntryEditSuccess: 'Modifications appliquées avec succès',
    advancedEntryDeleteSuccess: 'Les données sont en cours de suppression',
    // advancedEntryDeleteSuccess: 'Données supprimées avec succès',
  },
  tooltip: {
    prevChart: 'Graphique précédent',
    nextChart: 'Graphique suivant',
    performanceIssue: `Seulement les ${ADVANCED_ENTRY_VARIABLES_MAX_COUNT} premières variables sont affichées mais les modifications seront appliquées à l'intégralité des variables`,
  },
};
