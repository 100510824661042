import { Descendant } from 'slate';

import { CustomText, EmptyText } from '@dametis/core';

export const createText = (text: CustomText['text']): CustomText => ({
  text,
});

export const createEmptyText = (): EmptyText => ({ text: '' });

export const startWithEmptyText = (descendants: Descendant[]): Descendant[] => [createEmptyText(), ...descendants];
