import { Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockInfo, BlockTypeInfo } from '@dametis/core';

import { getBlockPath } from 'components/Lego/helpers/getBlockPath';
import { getBlockUrl } from 'components/Lego/helpers/getBlockUrl';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import { useFolders } from 'store/api/folders';

import BlockElementLinearProgress from '../BlockTile/BlockElementLinearProgress';

import BlockPreviewPaper from './BlockPreviewPaper';

export interface BlockPreviewProps {
  block: BlockInfo;
  blockType?: BlockTypeInfo;
  redirect?: boolean;
}

const BlockPreview: FC<BlockPreviewProps> = ({ block, blockType = undefined, redirect = false }) => {
  const { t } = useTranslation('lego');

  const { data: rootFolder = null } = useFolders();

  const url = useMemo(() => getBlockUrl(rootFolder, block.uuid) ?? '#', [rootFolder, block.uuid]);
  const path = useMemo(() => getBlockPath(rootFolder, block.uuid, t), [rootFolder, block.uuid, t]);

  const elementsProgress = useMemo(() => {
    if (!blockType) {
      return 0;
    }
    const selectedParameters = block.parameters.filter(parameter => parameter.isSelected);
    const definedParameters = selectedParameters.filter(parameter => !!parameter.calculation);

    const availableMetrics = block.metrics.filter(metric => metric.isSelected);
    const definedMetrics = availableMetrics.filter(metric => !!metric.templatedCalculation);

    const totalElements = selectedParameters.length + availableMetrics.length;
    if (totalElements === 0) {
      return 0;
    }

    return ((definedParameters.length + definedMetrics.length) / totalElements) * 100;
  }, [block, blockType]);

  return (
    <BlockPreviewPaper
      redirect={redirect}
      sx={{ textDecoration: 'inherit', background: theme => theme.palette.background.default, p: 1 }}
      url={url}
    >
      <Stack>
        <TypographyEllipse variant="caption">{path}</TypographyEllipse>
        <Typography>{block.name}</Typography>
        {blockType && <BlockElementLinearProgress sx={{ mt: 0.5 }} value={elementsProgress} variant="determinate" />}
      </Stack>
    </BlockPreviewPaper>
  );
};

export default BlockPreview;
