import { sdk } from 'sdk';
import { TypedThunk } from 'store';
import { setSiteHealth, setSiteHealthLoading } from 'store/slices/auth';

import { displaySdkErrorToast } from './toasts';

export const fetchHealth =
  (forceRefresh: boolean = false): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const siteId = state.auth.selectedSite?.uuid;
    if (!siteId) throw new Error();
    dispatch(setSiteHealthLoading(true));
    try {
      const { data } = await sdk.site.Health(siteId, forceRefresh === true ? { params: { refresh: true } } : undefined);
      data.lastChecked = new Date(data.lastChecked);
      dispatch(setSiteHealth(data));
    } catch (err) {
      dispatch(displaySdkErrorToast(err));
    }
    dispatch(setSiteHealthLoading(false));
  };
