export default {
  title: {
    beforeLeaving: 'Are you sure?',
    beforeDeleting: 'Are you sure?',
    beforeApplying: 'Are you sure?',
    beforeRegen: 'Are you sure?',
  },
  text: {
    beforeLeaving: 'You have unsaved changes.',
  },
  button: {
    yesLeave: 'Leave without saving',
    noLeave: 'Cancel',
    yesDelete: 'Yes, delete',
    noRegen: 'Cancel',
    yesRegen: 'Yes, regenerate',
    noDelete: 'Cancel',
    yesDisable: 'Yes, disable',
    noDisable: 'Cancel',
    yesApply: 'Yes, apply',
    noApply: 'Cancel',
  },
};
