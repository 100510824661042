import { useCallback } from 'react';
import { Descendant } from 'slate';
import { useSlateStatic } from 'slate-react';

import { CalculationVariable, IsCalculationVariable, VariableElement } from '@dametis/core';

import { getDefaultGroupByForOperator } from 'functions/tada/getGroupBy';
import { useVncStore } from 'zustand/stores/vnc';

import { insertCalculationInSlate } from '../slate/paragraph';
import { slateToTada } from '../slate/tada';
import { insertVariableInSlate } from '../slate/variable';

export const useInsertVariable = () => {
  const slateEditor = useSlateStatic();
  const monacoEditor = useVncStore(state => state.monacoEditor);
  const textareaMode = useVncStore(state => state.textareaMode);

  return useCallback(
    (
      variable: VariableElement['variable'],
      clearBeforeInsert: boolean,
      operator: CalculationVariable['operator'],
      calculatedVariableMode: boolean,
    ) => {
      const groupBy = getDefaultGroupByForOperator(operator, { isCalculatedVariable: calculatedVariableMode });
      const variableToInsert: VariableElement['variable'] = { ...(groupBy && { groupBy }), ...variable, operator };
      if (textareaMode === 'slate') {
        insertVariableInSlate(slateEditor, variableToInsert, clearBeforeInsert);
      } else if (textareaMode === 'json' && monacoEditor !== null) {
        const range = monacoEditor.getSelection();
        if (range === null) return;
        monacoEditor.executeEdits('my-source', [
          {
            range,
            text: JSON.stringify(variableToInsert, undefined, 2),
            forceMoveMarkers: true,
          },
        ]);
      }
    },
    [monacoEditor, slateEditor, textareaMode],
  );
};

export const useInsertCalculation = () => {
  const slateEditor = useSlateStatic();
  const monacoEditor = useVncStore(state => state.monacoEditor);
  const textareaMode = useVncStore(state => state.textareaMode);

  return useCallback(
    (calcVar: CalculationVariable | Descendant[]) => {
      if (textareaMode === 'slate') {
        insertCalculationInSlate(slateEditor, calcVar);
      } else if (textareaMode === 'json' && monacoEditor !== null) {
        const range = monacoEditor.getSelection();
        if (range === null) return;
        monacoEditor.executeEdits('my-source', [
          {
            range,
            text: JSON.stringify(IsCalculationVariable(calcVar) ? calcVar : slateToTada(calcVar, { withSlate: false }), undefined, 2),
            forceMoveMarkers: true,
          },
        ]);
      }
    },
    [monacoEditor, slateEditor, textareaMode],
  );
};
