import { GridLocaleText } from '@mui/x-data-grid-premium';

export default {
  button: {
    acknowledge: 'Acknowledge',
    advancedSearch: 'Advanced search',
    createBatch: 'Batch',
    deleteAlarm: 'Delete this alarm',
    editAlarm: 'Edit this alarm',
    deleteAlarmConfirmation_yes: 'Yes, delete',
    deleteAlarmConfirmation_cancel: 'Cancel',
    muteAlarm: 'Mute this alarm',
    unmuteAlarm: 'Unmute this alarm',
  },
  title: {
    alarms: 'Alarms',
    // activeAlarms: 'Active alarms',
    // alarmsHistory: 'History',
    batchesList: 'All batches',
  },
  subtitle: {
    updatedAt: 'Updated {{date}}',
  },
  text: {
    when: 'When',
    do: 'Do',
    deleteAlarmConfirmation: 'Do you confirm this alarm deletion?',
    events: 'Events history',
    for: 'for',
    day: 'd',
    noResults: 'No results',
    noActiveAlarms: 'No active alarms',
    noHistoryIncidents: 'No incidents',
    noBatches: 'No batches',
  },
  condition: {
    'equation_>': '{{variable}} is greater than {{value}}',
    'equation_>=': '{{variable}} is greater or equal to {{value}}',
    'equation_<': '{{variable}} is inferior to {{value}}',
    'equation_<=': '{{variable}} is inferior or equal to {{value}}',
    'equation_==': '{{variable}} is equal to {{value}}',
    'equation_!=': '{{variable}} is different than {{value}}',
    forXUnitOfTime: 'for {{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
  },
  action: {
    sendEmail: 'Send an email to {{email}}',
    sendText: 'Send a text to {{phone}}',
    sendEmail_notSet: '$t(action.sendNotification)',
    sendNotification: 'Notify in-app',
  },
  snackbar: {
    message: {
      acknowledge: {
        success: 'Alert was successfully acknowledged',
      },
    },
  },
  table: {
    cells: {
      state: 'State',
      name: 'Name',
      criticality: 'Criticality',
      extremeValue: 'Extreme',
      triggerDate: 'Triggered at',
      activeDuration: 'Active for',
      actions: 'Action',
      acknowledgementDate: 'Acknowledged at',
      acknowledgementActor: 'Acknowledged by',
      resolveDate: 'Resolved at',
      date: 'Date',
      event: 'Event',
      comment: 'Comment',
      res: 'Acknowledged on ... by',
    },
    rows: {
      acknowledgedOn: '{{date, dd/MM/yyyy HH:mm:ss}}',
      acknowledgedBy: '{{name}}',
      event_resolved: 'Resolved',
      event_triggered: 'Triggered',
      event_notify: 'Notification sent',
      event_ack: 'Acknowledged',
      event_ackBy: 'Acknowledged by {{name}}',
    },
  },
  criticality: {
    text: 'Criticality',
    variant_notification: 'Notification',
    variant_warning: 'Warning',
    variant_critical: 'Critical',
  },
  filters: {
    variablesFilter: 'Variables',
    superBatchFilter: 'Super batch',
  },
  input: {
    timeRange: {
      label: 'Date',
      value: {
        lastXUnitOfTime: 'Last {{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
        value_all: 'All',
      },
    },
    criticality: {
      label: 'Criticality',
      value: {
        variant: '$t(criticality.variant)',
      },
    },
    alarms: {
      label: 'Name',
    },
    variable: {
      label: 'Variable',
      error: {
        empty: 'Required',
      },
    },
    name: {
      label: 'Alarm name',
      error: {
        empty: 'Required',
        over60characters: 'Too long',
      },
    },
    operand: {
      error: {
        empty: 'Required',
      },
    },
    notificationDelay: {
      error: {
        empty: 'Required',
        notPositive: 'Cannot be negative',
      },
    },
    email: {
      placeholder: 'Send an email to',
      error: {
        invalid: 'Invalid',
      },
    },
    phone: {
      placeholder: 'Send a message to',
      error: {
        invalid: 'Invalid',
      },
    },
    operator: {
      label: 'Operator',
    },
  },
  xgrid: {
    noBatchesLabel: 'No batches',
    footerTotalAlarms: 'Total alarms :',
    footerPaginationAlarmsPerPage: 'Alarms per page :',
    noIncidentsLabel: 'No incident',
    footerTotalIncidents: 'Total incidents :',
    footerPaginationIncidentsPerPage: 'Incidents per page :',
  } as Partial<GridLocaleText>,
  tooltip: {
    createBatch: 'create a batch',
  },
};
