import { FormControl, FormControlProps, InputLabel, Stack } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface Props {
  title: string;
}

const ChipSelect: FC<PropsWithChildren<Props & FormControlProps>> = ({ children = undefined, title, ...props }) => (
  <FormControl fullWidth margin="dense" {...props}>
    <InputLabel sx={{ position: 'relative' }}>{title}</InputLabel>
    <Stack direction="row" flexWrap="wrap" marginTop={0.5} spacing={1}>
      {children}
    </Stack>
  </FormControl>
);

export default ChipSelect;
