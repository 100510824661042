import { LabComponents } from '@mui/lab/themeAugmentation';
import { Components, Theme } from '@mui/material';
import { DataGridPremiumComponents } from '@mui/x-data-grid-premium/themeAugmentation';
import { TreeViewComponents } from '@mui/x-tree-view/themeAugmentation';

import MuiAccordionSummary from './MuiAccordionSummary';
import MuiAppBar from './MuiAppBar';
import MuiAutocomplete from './MuiAutocomplete';
import MuiAvatar from './MuiAvatar';
import MuiButton from './MuiButton';
import MuiButtonBase from './MuiButtonBase';
import MuiButtonGroup from './MuiButtonGroup';
import MuiChip from './MuiChip';
import MuiDataGrid from './MuiDataGrid';
import MuiDialog from './MuiDialog';
import MuiFab from './MuiFab';
import MuiFilledInput from './MuiFilledInput';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiFormGroup from './MuiFormGroup';
import MuiFormLabel from './MuiFormLabel';
import MuiIconButton from './MuiIconButton';
import MuiInputAdornment from './MuiInputAdornment';
import MuiInputLabel from './MuiInputLabel';
import MuiLink from './MuiLink';
import MuiList from './MuiList';
import MuiListItem from './MuiListItem';
import MuiListItemButton from './MuiListItemButton';
import MuiListItemText from './MuiListItemText';
import MuiListSubheader from './MuiListSubheader';
import MuiMenu from './MuiMenu';
import MuiMenuItem from './MuiMenuItem';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaper from './MuiPaper';
import MuiPopover from './MuiPopover';
import MuiSkeleton from './MuiSkeleton';
import MuiStack from './MuiStack';
import MuiSwitch from './MuiSwitch';
import MuiTab from './MuiTab';
import MuiTableRow from './MuiTableRow';
import MuiTabs from './MuiTabs';
import MuiToggleButtonGroup from './MuiToggleButtonGroup';
import MuiToolbar from './MuiToolbar';
import MuiTooltip from './MuiTooltip';
import MuiTreeItem from './MuiTreeItem';
import MuiTreeView from './MuiTreeView';
import MuiTypography from './MuiTypography';

export const components: Components<Theme> & LabComponents & DataGridPremiumComponents<Theme> & TreeViewComponents<Theme> = {
  MuiAccordionSummary,
  MuiAppBar,
  MuiAutocomplete,
  MuiAvatar,
  MuiButton,
  MuiButtonBase,
  MuiButtonGroup,
  MuiChip,
  MuiDataGrid,
  MuiDialog,
  MuiFab,
  MuiFilledInput,
  MuiFormControlLabel,
  MuiFormGroup,
  MuiFormLabel,
  MuiIconButton,
  MuiInputAdornment,
  MuiInputLabel,
  MuiLink,
  MuiList,
  MuiListItem,
  MuiListItemButton,
  MuiListItemText,
  MuiListSubheader,
  MuiMenu,
  MuiMenuItem,
  MuiOutlinedInput,
  MuiPaper,
  MuiPopover,
  MuiSkeleton,
  MuiStack,
  MuiSwitch,
  MuiTab,
  MuiTableRow,
  MuiTabs,
  MuiToggleButtonGroup,
  MuiToolbar,
  MuiTooltip,
  MuiTreeItem,
  MuiTreeView,
  MuiTypography,
};
