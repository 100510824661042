import { SourceColumn } from '@dametis/core';

import { FormFixedVariable } from '../Home/CreateFileImportModal/MappingSettings/MappingSettingsContext';

import { areSameSourceColumns } from './areSameSourceColumns';

export const isSourceColumnInList = (list: SourceColumn[], source: SourceColumn | null): boolean =>
  list.some(listSource => areSameSourceColumns(listSource, source));

export const isSourceColumnSkipped = (skippedColumns: (SourceColumn | null)[], source: SourceColumn | null): boolean =>
  skippedColumns.some(skippedColumn => areSameSourceColumns(skippedColumn, source));

export const isSourceColumnFixedVariable = (fixedVariables: FormFixedVariable[], source: SourceColumn | null): boolean =>
  fixedVariables.some(fixedVariable => areSameSourceColumns(fixedVariable.source, source));
