import Fuse from 'fuse.js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TinyUserInfo } from '@dametis/core';

export const useMentionLookupService = (mentionString: string | null, users: TinyUserInfo[]) => {
  const [results, setResults] = useState<Array<TinyUserInfo>>([]);
  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const fuse = useMemo(() => {
    const keys = ['firstName', 'lastName'];
    const index = Fuse.createIndex(keys, users);
    return new Fuse(users, { keys, shouldSort: true, threshold: 0.3 }, index);
  }, [users]);

  const searchUser = useCallback(
    (search: string): void => {
      if (search === '') setResults(users);
      else {
        const res = fuse.search(search).map(result => result.item);
        setResults(res);
      }
    },
    [fuse, users],
  );

  useEffect(() => {
    if (debounceTimeout.current !== null) {
      clearTimeout(debounceTimeout.current);
    }
    if (mentionString !== null) {
      debounceTimeout.current = setTimeout(() => searchUser(mentionString), 100);
    }
  }, [mentionString, searchUser, users]);

  return results;
};
