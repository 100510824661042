import { Box, Checkbox, Divider, ListItemText, MenuItem, SelectChangeEvent } from '@mui/material';
import { Dispatch, FC, MouseEventHandler, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ShortUserInfo, UUID } from '@dametis/core';

import SelectFilter from 'components/UI/SelectFilter/SelectFilter';

export interface SelectOwnersProps {
  selectedUsersUuid: UUID[];
  setSelectedUsersUuid: Dispatch<SetStateAction<UUID[]>>;
  isOpen: boolean;
  users: ShortUserInfo[];
}

const SelectOwners: FC<SelectOwnersProps> = ({ selectedUsersUuid, setSelectedUsersUuid, isOpen, users }) => {
  const { t } = useTranslation('lego');

  const handleUsers = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      setSelectedUsersUuid(event.target.value as UUID[]);
    },
    [setSelectedUsersUuid],
  );

  const handleAllUsers: MouseEventHandler<HTMLLIElement> = useCallback(
    event => {
      event.stopPropagation();
      if (!selectedUsersUuid.length) {
        setSelectedUsersUuid(users.map(user => user.uuid));
      } else {
        setSelectedUsersUuid([]);
      }
    },
    [selectedUsersUuid?.length, users, setSelectedUsersUuid],
  );

  return (
    <SelectFilter multiple disabled={!isOpen} placeholder={t('label.owners')} size="small" value={selectedUsersUuid} onChange={handleUsers}>
      <Box>
        <MenuItem onClick={handleAllUsers}>
          <Checkbox
            checked={selectedUsersUuid.length === users.length}
            indeterminate={selectedUsersUuid.length !== 0 && selectedUsersUuid.length !== users.length}
          />
          {t('text.allSelected')}
        </MenuItem>
      </Box>
      <Divider />
      {users.map(user => (
        <MenuItem key={user.uuid} value={user.uuid}>
          <Checkbox checked={selectedUsersUuid.includes(user.uuid)} />
          <ListItemText primary={`${user.firstName} ${user.lastName}`} />
        </MenuItem>
      ))}
      {users.length === 0 && (
        <MenuItem disabled value="">
          {t('text.noAvailableOption')}
        </MenuItem>
      )}
    </SelectFilter>
  );
};

export default SelectOwners;
