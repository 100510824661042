import { makeStyles } from '@mui/styles';

const usePopperPanelStyles = makeStyles(() => ({
  popper: {
    // pointerEvents: 'none',
  },
  popper__paper: {
    overflow: 'unset',
    pointerEvents: 'all',
    '& ul': {
      outline: 'none',
    },
  },
  'popper__paper--leftPadding': {
    marginLeft: '0.3rem',
  },
  'popper__paper--rightPadding': {
    marginRight: '0.3rem',
  },
  'popper__paper--topPadding': {
    marginTop: '0.3rem',
  },
  'popper__paper--bottomPadding': {
    marginBottom: '0.3rem',
  },
  menu__item__icon: {
    minWidth: 36,
  },
  menu__item__text: {
    margin: 0,
  },
}));

export default usePopperPanelStyles;
