import { NotifyType } from '@dametis/core';

import { NotifyItemType } from 'types/share';

export default {
  title: {
    notify_one: 'Voulez-vous notifier cet utilisateur ?',
    notify_other: 'Voulez-vous notifier ces utilisateurs ?',
  },
  text: {
    [NotifyItemType.PLAYGROUND]: 'ce playground.',
    [NotifyItemType.REPORT]: 'ce rapport.',
    [NotifyItemType.SYNOPTIC]: 'ce synoptique.',
    [NotifyItemType.PROJECT]: 'ce projet.',
    [NotifyItemType.ALARM]: 'cette alarme.',
    [`${NotifyType.SHARINGS}_one`]: "Cet utilisateur sera informé qu'il a désormais accès à $t(notify:text.{{itemType}})",
    [`${NotifyType.SHARINGS}_other`]: "Ces utilisateurs seront informés qu'ils ont désormais accès à $t(notify:text.{{itemType}})",
    [`${NotifyType.ALARM_ACTION}_one`]: "Cet utilisateur sera informé qu'il recevra désormais les alertes de cette alarme.",
    [`${NotifyType.ALARM_ACTION}_other`]: "Ces utilisateurs seront informés qu'ils recevront désormais les alertes de cette alarme.",
    [`${NotifyType.PROJECT_TASK_ASSIGNEE}`]: "Cet utilisateur sera informé que vous l'avez assigné à une tâche sur ce projet.",
    commentPlaceholder: 'Ajouter un message personnalisé...',
  },
  input: {
    addComment: 'Ajouter un message',
    sendNotify: 'Envoyer une notification',
  },
  button: {
    skip: 'Passer',
    send: 'Envoyer',
  },
  toast: {
    success_one: "L'utilisateur a été notifié.",
    success_other: 'Les utilisateurs ont été notifiés.',
    error: "Une erreur s'est produite lors de la notification aux utilisateurs.",
  },
};
