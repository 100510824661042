import { Tooltip, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityInfo } from '@dametis/core';

import { isEqual, localizedFormat, localizedFormatDistanceToNowStrict } from 'localization/localizedDateFns';

interface Props {
  entity: Pick<Partial<EntityInfo>, 'createdAt' | 'updatedAt'>;
}

const EntityPanelSubtitle: FC<Props> = ({ entity }) => {
  const { t } = useTranslation('global');

  const createdAt: Date | undefined = useMemo(
    () => (entity.createdAt !== undefined ? new Date(entity.createdAt) : undefined),
    [entity.createdAt],
  );
  const updatedAt: Date | undefined = useMemo(() => {
    if (entity.updatedAt === undefined && entity.createdAt === undefined) {
      return undefined;
    }
    if (entity.updatedAt !== undefined) {
      return new Date(entity.updatedAt);
    }
    return entity.createdAt !== undefined ? new Date(entity.createdAt) : undefined;
  }, [entity.updatedAt, entity.createdAt]);

  return (
    <>
      {createdAt && (!updatedAt || (updatedAt && isEqual(createdAt, updatedAt))) && (
        <Tooltip title={localizedFormat(createdAt, 'Pp')}>
          <Typography variant="caption">
            {t('text.subtitle.createdAt', { date: localizedFormatDistanceToNowStrict(createdAt, { addSuffix: true }) })}
          </Typography>
        </Tooltip>
      )}
      {updatedAt && (!createdAt || (createdAt && !isEqual(createdAt, updatedAt))) && (
        <Tooltip title={localizedFormat(updatedAt, 'Pp')}>
          <Typography variant="caption">
            {t('text.subtitle.updatedAt', { date: localizedFormatDistanceToNowStrict(updatedAt, { addSuffix: true }) })}
          </Typography>
        </Tooltip>
      )}
    </>
  );
};

export default EntityPanelSubtitle;
