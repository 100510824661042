import { BlockConnection, ConnectionType } from '@dametis/core';

export const isBlockConnectionValid = (connection: BlockConnection): boolean => {
  if (connection.type === ConnectionType.MULTIPLE) {
    return connection.blockIds.length > 0;
  }
  if (connection.type === ConnectionType.UNIQUE) {
    return connection.blockIds.length === 1;
  }
  return false;
};
