import { DialogTitle, Stack } from '@mui/material';
import { FC } from 'react';

import { useVncStore } from 'zustand/stores/vnc';

import Preview from './Preview';
import Search from './Search';
import Textarea from './Textarea/Textarea';
import { TitlePreviewBox, TitleTextareaBox } from './styled';

const Title: FC = () => {
  const someSelected = useVncStore(state => Boolean(state.selection.length));

  return (
    <DialogTitle sx={{ p: 0 }}>
      <Stack direction="row">
        <TitleTextareaBox fullWidth={someSelected}>{someSelected ? <Search /> : <Textarea />}</TitleTextareaBox>
        <TitlePreviewBox hidden={someSelected}>
          <Preview />
        </TitlePreviewBox>
      </Stack>
    </DialogTitle>
  );
};

export default Title;
