import { Box, Chip, Stack } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Resizable, ResizableProps } from 'react-resizable';
import { Editor, Transforms } from 'slate';
import { useSlate } from 'slate-react';

import { CalculationVariable, Symbols } from '@dametis/core';
import { ValidateCalculation, mathJs } from '@dametis/mathjs';

import { StyledEditable } from 'components/VNC/components/Title/styled';
import { resetNodes } from 'components/VNC/slate';
import { slateToTada, tadaToSlate } from 'components/VNC/slate/tada';
import { setColorLightness } from 'functions/color';
import { getScrollBarWidth } from 'functions/getScrollbarWidth';

import ResizeHandler from '../ResizeHandler/ResizeHandler';

import { RenderElement } from './slate/RenderElement';
import { insertOperatorInSlate } from './slate/insertOperatorInSlate';

const scrollbarWidth = getScrollBarWidth();

interface BatchFilterSlateProps {
  batch: CalculationVariable | null;
  isCalcValid: boolean;
  setIsCalcValid: (valid: boolean) => void;
  setError: (valid: string) => void;
}

const BatchFilterSlate: FC<BatchFilterSlateProps> = ({ batch, isCalcValid, setIsCalcValid, setError }) => {
  const editor = useSlate();

  const [height, setHeight] = useState(75);
  const [isResizing, setIsResizing] = useState<boolean>(false);

  const calcVar = useMemo(() => slateToTada(editor.children), [editor.children]);

  const checkValidity = useCallback(() => {
    try {
      ValidateCalculation(mathJs, calcVar);
      setIsCalcValid(true);
    } catch (e) {
      setError(e.message);
      setIsCalcValid(false);
    }
  }, [calcVar, setError, setIsCalcValid]);

  const handleResizeStart = useCallback(() => {
    setIsResizing(true);
  }, []);

  const handleResizeStop = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback<NonNullable<ResizableProps['onResize']>>((_, { size }) => setHeight(size.height), []);

  useEffect(() => {
    void checkValidity();
  }, [checkValidity]);

  useEffect(() => {
    editor.children = tadaToSlate(batch);
    editor.onChange();
    Transforms.select(editor, Editor.end(editor, []));
    return () => {
      resetNodes(editor);
    };
  }, [batch, editor]);

  return (
    <Box position="relative">
      <Resizable
        handle={<ResizeHandler isResizing={isResizing} sx={{ position: 'absolute', zIndex: 1, mt: '-4px', borderRadius: `0 0 4px 4px` }} />}
        height={height}
        maxConstraints={[9999, window.innerHeight - 300]}
        minConstraints={[9999, 75]}
        resizeHandles={['s']}
        width={9999}
        onResize={resize}
        onResizeStart={handleResizeStart}
        onResizeStop={handleResizeStop}
      >
        <Box height={height} minHeight="100%">
          <StyledEditable
            autoFocus
            focused
            renderElement={RenderElement}
            sx={{
              ...(!isCalcValid && {
                borderColor: theme => theme.palette.error.main,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }),
            }}
          />
        </Box>
      </Resizable>
      <Stack bottom={2} direction="row" p={0.5} position="absolute" right={scrollbarWidth + 2} spacing={1}>
        <Chip
          clickable
          color="secondary"
          label=" + AND"
          size="small"
          sx={{
            color: theme => theme.palette.text.primary,
            fontSize: 12,
            fontWeight: 600,
            backgroundColor: theme => setColorLightness(theme.palette.picker.cyan, 85),
            '&:hover, &.Mui-focusVisible': {
              backgroundColor: theme => setColorLightness(theme.palette.picker.cyan, 75),
            },
          }}
          onClick={() => insertOperatorInSlate(editor, Symbols.AND)}
        />
        <Chip
          clickable
          color="secondary"
          label=" + OR"
          size="small"
          sx={{
            color: theme => theme.palette.text.primary,
            fontSize: 12,
            fontWeight: 600,
            backgroundColor: theme => setColorLightness(theme.palette.picker.cyan, 85),
            '&:hover, &.Mui-focusVisible': {
              backgroundColor: theme => setColorLightness(theme.palette.picker.cyan, 75),
            },
          }}
          onClick={() => insertOperatorInSlate(editor, Symbols.OR)}
        />
      </Stack>
    </Box>
  );
};

export default BatchFilterSlate;
