import { DataOperationContextName, DataOperationStatus, EditDataAction, ModifierType } from '@dametis/core';

export default {
  title: {
    dataHistory: 'Historial de operaciones',
    history: 'Historial',
    details: 'Detalles',
  },
  column: {
    owner: 'Autor',
    action: 'Acción',
    context: 'Contexto',
    createdAt: 'Iniciado el',
    updatedAt: 'Última actualización el',
    operationId: 'ID de operación',
    variableUuids: 'Variables afectadas',
    conditions: 'Condiciones',
    modifiers: 'Modificadores',
    details: '',
    revert: '',
    retry: '',
    status: 'Estado',
  },
  input: {
    label: {
      message: 'Carga útil',
      [ModifierType.LINEAR_INTERPOLATION]: 'Interpolación lineal',
      [ModifierType.MULTIPLIER]: 'Multiplicador',
      [ModifierType.OFFSET]: 'Desplazamiento',
      [ModifierType.SET_VALUE]: 'Valor fijo',
    },
  },
  button: {
    close: 'Cerrar',
    copy: 'Copiar',
    details: 'Detalles',
    revert: 'Revertir',
    retry: 'Reintentar',
    refresh: 'Refrescar',
  },
  text: {
    [EditDataAction.IMPORT]: 'Importar',
    [EditDataAction.EDIT]: 'Editar',
    [EditDataAction.DELETE]: 'Eliminar',
    [DataOperationContextName.DEFAULT]: 'Por defecto',
    [DataOperationContextName.MANUAL_ENTRY]: 'Entrada manual',
    [DataOperationContextName.CONSTANT]: 'Constante',
    [DataOperationContextName.ADVANCED_ENTRY]: 'Corrección de datos',
    [DataOperationContextName.ADMIN]: 'Administrador',
    [DataOperationContextName.RESTORE]: 'Restaurar',
    [DataOperationContextName.FILE_IMPORT]: 'Importación de datos',
    [DataOperationContextName.TOOLS]: 'Herramientas',
    noOperation: 'Sin operación',
    patch: {
      init: 'todos los datos',
      edit: 'Edición de ',
      delete: 'Eliminación de ',
    },
  },
  toast: {
    revertOperationSuccess: 'Operación revertida correctamente',
    revertOperationError: 'Error al revertir la operación',
    retryOperationSuccess: 'Operación reintentada correctamente',
    retryOperationError: 'Error al reintentar la operación',
    copySuccess: 'Copiado al portapapeles',
  },
  tooltip: {
    variableConflict: 'Ya hay una operación en curso en esta variable. ',
    viewHistory: 'Ver historial',
    [`${DataOperationStatus.DONE}`]: 'Éxito',
    [`${DataOperationStatus.FAILED}`]: 'Fallo',
    [`${DataOperationStatus.PENDING}`]: 'Pendiente',
  },
};
