import { DateSystem, FormatCategory } from '@dametis/core';

import { FormatPickerMenu } from 'types/format';

export default {
  text: {
    newOption: 'Custom format : "{{value}}"',
    newOptionIncorrect: 'Incorrect format : "{{value}}"',
    unknownFormat: 'Unknown format',
    noFormatting: 'No formatting',
    inputLabel: 'Format',
    hideUnit: 'Hide unit',
    timezoneOffset: 'Adapt to timezone',
    dateSystem: 'Date system',
    withUnit: 'with unit',
    withoutUnit: 'without unit',
    withTimezoneOffset: 'with timezone offset',
    withoutTimezoneOffset: 'without timezone offset',
    noElement: 'No element',
  },
  subheader: {
    [FormatCategory.NUMBER]: 'Numbers',
    [FormatCategory.DATE]: 'Dates',
    history: 'History',
  },
  dateSystem: {
    [DateSystem.EXCEL_SERIAL_TIME]: 'Excel',
    [DateSystem.TIMESTAMP_UNIX]: 'Unix',
  },
  button: {
    ok: 'ok',
    clearHistory: 'clear',
  },
  tooltip: {
    menu: {
      [FormatPickerMenu.HISTORY]: 'History',
      [FormatPickerMenu.LISTS]: 'Formats',
      [FormatPickerMenu.SETTINGS]: 'Settings',
    },
    clear: 'Clear',
  },
  helper: {
    description: 'The formatting is done according to the ECMA-376 standard, similar to other spreadsheet software.',
    redirection: 'More examples here.',
  },
};
