import { createContext } from 'react';

export interface IFunctionArgContext {
  focused: number | null;
  setFocused: (arg: number | null) => void;
}

export const FunctionArgContext = createContext<IFunctionArgContext>({
  focused: null,
  setFocused: () => {},
});
