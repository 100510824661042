import clsx from 'clsx';
import { Children, FC, ReactElement, ReactNode, cloneElement } from 'react';

import useBigButtonStyles from './BigButton.styles';

interface Props {
  children: ReactNode;
  className?: string;
}

const BigButtonIcon: FC<Props> = ({ children, className = '' }) => {
  const classes = useBigButtonStyles();
  const childrenWithClassName = Children.map(
    children,
    child => child && cloneElement(child as ReactElement, { className: clsx(classes.bigButton__icon, className) }),
  );
  return <>{childrenWithClassName}</>;
};

export default BigButtonIcon;
