import { ArrowRight } from '@mui/icons-material';
import { Stack, Toolbar, drawerClasses, styled } from '@mui/material';

export const DrawerContentStack = styled(Stack)(() => ({
  overflow: 'hidden',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '& ::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const DrawerContentToolbar = styled(Toolbar)(({ theme }) => ({
  color: theme.palette.white,
  // padding: `0 ${theme.spacing(1.5)} !important`,
  // margin: '0 auto',
  justifyContent: 'center',
}));

export const DametisButtonArrow = styled(ArrowRight, {
  shouldForwardProp: propName => propName !== 'isExtendedDrawer',
})<{ isExtendedDrawer: boolean }>(({ theme, isExtendedDrawer }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: isExtendedDrawer ? 'translate(calc(-50% - 24px), -50%) rotate(180deg)' : 'translate(calc(-50% + 24px), -50%)',
  transition: `${theme.transitions.create('transform')}, opacity 100ms`,
  opacity: 0,
  [`.${drawerClasses.paper}:hover &`]: {
    opacity: 1,
  },
}));
