import { BlockTypeInfo, FolderInfo, ShortStandardBlockInfo } from '@dametis/core';

export enum SearchResultType {
  FOLDER = 'folder',
  BLOCK_TYPE = 'blockType',
  STANDARD_BLOCK = 'standardBlock',
}

export interface SearchResultContent {
  [SearchResultType.FOLDER]: FolderInfo;
  [SearchResultType.BLOCK_TYPE]: BlockTypeInfo;
  [SearchResultType.STANDARD_BLOCK]: ShortStandardBlockInfo;
}

export interface SearchResult<T extends SearchResultType = SearchResultType> {
  type: T;
  content: SearchResultContent[T];
}

export type SearchResultFolder = SearchResult<SearchResultType.FOLDER>;

export const IsSearchResultFolder = (result: SearchResult): result is SearchResultFolder => result.type === SearchResultType.FOLDER;

export type SearchResultBlockType = SearchResult<SearchResultType.BLOCK_TYPE>;

export const IsSearchResultBlockType = (result: SearchResult): result is SearchResultBlockType =>
  result.type === SearchResultType.BLOCK_TYPE;

export type SearchResultStandardBlock = SearchResult<SearchResultType.STANDARD_BLOCK>;

export const IsSearchResultStandardBlock = (result: SearchResult): result is SearchResultStandardBlock =>
  result.type === SearchResultType.STANDARD_BLOCK;

export const entitiesToSearchResults = <T extends SearchResultType>(entities: SearchResultContent[T][], type: T): SearchResult<T>[] =>
  entities.map(entity => ({ type, content: entity }));
