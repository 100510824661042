import { Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, HTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface DateTimeFormatOptionProps extends HTMLAttributes<HTMLLIElement> {
  option: string;
}

const DateTimeFormatOption: FC<DateTimeFormatOptionProps> = ({ option, ...props }) => {
  const { t } = useTranslation('fileImport');

  const example = useMemo(() => DateTime.fromISO('2023-01-22T13:12:00.057').toFormat(option), [option]);

  return (
    <li {...props}>
      <Stack>
        <Typography>{option}</Typography>
        <Typography variant="subtitle2">{t('text.dateTimeFormatExample', { example })}</Typography>
      </Stack>
    </li>
  );
};

export default DateTimeFormatOption;
