export default {
  label: {
    default: 'Por defecto',
    live: 'En directo',
    muted: 'En silencio',
    custom: 'Personalizado',
    invalid: 'Inválido',
  },
  select: {
    default: 'Por defecto',
    live: 'En directo',
    muted: 'En silencio',
    custom: 'Personalizado',
  },
  text: {
    noValue: 'n/a',
  },
  tooltip: {
    default: 'Por defecto',
    live: 'En directo',
    muted: 'En silencio (Ignorado)',
    custom: 'Personalizado',
  },
};
