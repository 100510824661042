import { TFunction } from 'i18next';

import { ReferencedBy } from '@dametis/core';

import { SourceValue } from '../UI/SourceValuePicker/SourceValuePicker';

export const getSourceValueLabel = (sourceValue: SourceValue, referencedBy: ReferencedBy, useHeader: boolean, t: TFunction): string =>
  t(`sourceValue.${sourceValue}${sourceValue === SourceValue.DEFAULT && useHeader ? '_useHeader' : ''}`, {
    context: referencedBy,
  });
