import { ListItemNode, ListNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode } from '@lexical/rich-text';
import { Box, Divider, Stack, useTheme } from '@mui/material';
import {
  $getRoot,
  BLUR_COMMAND,
  COMMAND_PRIORITY_HIGH,
  CommandListener,
  EditorState,
  FOCUS_COMMAND,
  LexicalEditor,
  RootNode,
  SerializedEditorState,
  SerializedElementNode,
} from 'lexical';
import { FC, MutableRefObject, ReactNode, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Config, lexicalTheme, onError } from 'config/lexical';
import { isLexicalFormat } from 'functions/isLexicalFormat';

import { EditorWrapper, Placeholder, StyledContentEditable, StyledContentNonEditable } from '../StyledCommentElements.styled';

import KeyEventsPlugin, { SAVE_COMMAND } from './plugins/KeyEventsPlugin/KeyEventsPlugin';
import { MentionNode } from './plugins/MentionPlugin/MentionNode';
import MentionsPlugin from './plugins/MentionPlugin/MentionPlugin';
import ToolbarPlugin from './plugins/ToolBarPlugin/ToolbarPlugin';

export interface CommentEditorProps {
  setIsEmpty: (text: boolean) => void;
  editorRef: MutableRefObject<LexicalEditor | null>;
  inlineSubmitButton?: ReactNode;
  joinProject?: boolean;
  editorState?: SerializedEditorState<SerializedElementNode>;
  fixedHeight?: boolean;
  onCmdEnter?: () => void;
  editing?: boolean;
  commentFooter?: ReactNode | null;
  backgroundColor?: string;
}

const CommentEditor: FC<CommentEditorProps> = ({
  setIsEmpty,
  editorRef,
  inlineSubmitButton = undefined,
  joinProject = false,
  editorState = undefined,
  fixedHeight = false,
  onCmdEnter = undefined,
  editing = false,
  commentFooter = null,
  backgroundColor = undefined,
}) => {
  const { t } = useTranslation('comment');
  const themeMui = useTheme();
  const [focus, setFocus] = useState<boolean>(false);

  const initialConfig: Config = useMemo(
    () => ({
      editable: editing,
      namespace: 'MyEditor',
      theme: lexicalTheme,
      onError,
      nodes: [HeadingNode, ListNode, ListItemNode, MentionNode],
      editorState: isLexicalFormat(editorState) ? JSON.stringify(editorState) : undefined,
    }),
    [editing, editorState],
  );

  const handlerOnChange = useCallback(
    (currentEditorState: EditorState) => {
      currentEditorState?.read(() => {
        const root = $getRoot();
        const isEmpty = !!root.getFirstChild<RootNode>()?.isEmpty() && root.getChildrenSize() === 1;
        setIsEmpty(isEmpty);
      });
      // eslint-disable-next-line no-underscore-dangle
      if (editorRef.current) editorRef.current._editorState = currentEditorState;
      return editorRef.current;
    },
    [editorRef, setIsEmpty],
  );

  const handleOnSave = useCallback<CommandListener<KeyboardEvent>>(() => {
    onCmdEnter?.();
    setFocus(false);
    return true;
  }, [onCmdEnter]);

  const handleFocus = useCallback<CommandListener<FocusEvent>>(() => {
    setFocus(true);
    return false;
  }, []);

  const handleBlur = useCallback<CommandListener<FocusEvent>>(() => {
    setFocus(false);
    return false;
  }, []);

  useEffect(() => {
    if (editing && onCmdEnter && editorRef.current) {
      const removeListener = editorRef.current.registerCommand(SAVE_COMMAND, handleOnSave, COMMAND_PRIORITY_HIGH);
      return () => removeListener();
    }
    return () => undefined;
  }, [editing, editorRef, handleOnSave, onCmdEnter]);

  useEffect(() => {
    if (editing && editorRef.current) {
      editorRef.current.setEditable(true);
      editorRef.current.focus();
      const removeFocusListener = editorRef.current.registerCommand(FOCUS_COMMAND, handleFocus, COMMAND_PRIORITY_HIGH);
      const removeBlurListener = editorRef.current.registerCommand(BLUR_COMMAND, handleBlur, COMMAND_PRIORITY_HIGH);
      return () => {
        removeFocusListener();
        removeBlurListener();
      };
    }
    return () => undefined;
  }, [editing, editorRef, handleBlur, handleFocus]);

  return (
    <EditorWrapper backgroundColor={backgroundColor} editing={editing} focused={focus}>
      <LexicalComposer initialConfig={initialConfig}>
        {editing && (
          <>
            <KeyEventsPlugin />
            <AutoFocusPlugin />
            <MentionsPlugin />
          </>
        )}
        <EditorRefPlugin editorRef={editorRef} />
        {editing && (
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <ToolbarPlugin joinProject={joinProject} />
          </Box>
        )}
        <Box sx={{ position: 'relative' }}>
          <RichTextPlugin
            contentEditable={editing ? <StyledContentEditable fixedHeight={fixedHeight} /> : <StyledContentNonEditable />}
            ErrorBoundary={LexicalErrorBoundary}
            placeholder={
              editing ? (
                <Placeholder color={themeMui.palette.text.secondary} variant="subtitle2">
                  {t('placeholder.leaveAComment')}
                </Placeholder>
              ) : null
            }
          />
        </Box>
        {(commentFooter || (editing && inlineSubmitButton)) && (
          <>
            <Divider sx={{ mt: theme => theme.spacing(1) }} />
            <Stack
              direction="row"
              flexWrap="wrap"
              justifyContent="space-between"
              spacing={1}
              sx={{ padding: theme => `${theme.spacing(1)} 0` }}
            >
              {commentFooter}
              {editing && <Box sx={{ m: theme => `auto ${theme.spacing(1)}`, marginLeft: 'auto' }}>{inlineSubmitButton}</Box>}
            </Stack>
          </>
        )}
        <OnChangePlugin onChange={handlerOnChange} />
        <ClearEditorPlugin />
        <ListPlugin />
      </LexicalComposer>
    </EditorWrapper>
  );
};

export default memo(CommentEditor);
