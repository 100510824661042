export default {
  toast: {
    errorUploadingPreview: "Erreur pendant le téléchargement de l'image.",
    errorReadingFilePreview: 'Impossible de lire le fichier.',
    errorFileTypePreview: 'Mauvais type de fichier.',
    errorDeletingPreview: "Erreur pendant la suppression de l'image.",
  },
  tooltip: {
    editPhoto: `Changer d'image`,
    deletePhoto: `Supprimer l'image`,
  },
};
