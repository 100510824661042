export default {
  required: 'Requerido',
  integer: 'Debe ser un número entero',
  minArray: 'Debe contener al menos {{value}} valor(es)',
  minNumber: 'Debe ser >= {{value}}',
  maxNumber: 'Debe ser <= {{value}}',
  minString: 'Mínimo de {{value}} caracteres',
  maxString: 'Máximo de {{value}} caracteres',
  exactString: 'Debe tener {{value}} caracteres',
  step: 'Debe ser un múltiplo de {{value}}',
  ipv4: 'Formato IPv4 incorrecto',
  ipv4Range: 'Formato de rango IPv4 incorrecto, debe ser ip/mask',
  type: {
    numberMinMax: 'Debe ser un número entre {{min}} y {{max}}',
    number: 'Debe ser un número válido',
    string: 'Debe ser una cadena válida',
    boolean: 'Debe ser un booleano',
    object: 'Debe ser un objeto',
    email: 'Debe ser una dirección de correo electrónico válida',
    uuid: 'Debe ser un UUID válido',
  },
  oneOf: 'Debe ser una opción disponible',
  isNot: 'No puede ser "{{value}}"',
  positive: 'Debe ser positivo',
  mustBeDifferents: 'Debe ser diferente',
  oneOfMultipleFormat: 'Debe coincidir con al menos un formato',
  invalidDelay: 'Tiempo de espera no válido',
};
