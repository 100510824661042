import { FC, MouseEventHandler, useCallback, useContext, useMemo, useRef } from 'react';
import { useEventListener } from 'usehooks-ts';

import {
  RIGHT_PANEL_EXTRA_LARGE_WIDTH,
  RIGHT_PANEL_LARGE_WIDTH,
  RIGHT_PANEL_MAX_WIDTH,
  RIGHT_PANEL_MIN_WIDTH,
  RIGHT_PANEL_WIDTH,
} from 'theme/others';

import ResizeHandler from '../ResizeHandler/ResizeHandler';

import { RightPanelDrawerContext } from './RightPanelDrawerContainer';

export const MAGNETISM_GAP = 10;

const RightPanelDrawerResizer: FC = () => {
  const { setPanelWidth, isResizing, setIsResizing, size } = useContext(RightPanelDrawerContext);

  const defaultWidth = useMemo(
    () => ({ md: RIGHT_PANEL_WIDTH, lg: RIGHT_PANEL_LARGE_WIDTH, xl: RIGHT_PANEL_EXTRA_LARGE_WIDTH })[size],
    [size],
  );
  const minWidth = useMemo(() => Math.min(defaultWidth, RIGHT_PANEL_MIN_WIDTH), [defaultWidth]);
  const maxWidth = useMemo(() => Math.max(defaultWidth, RIGHT_PANEL_MAX_WIDTH), [defaultWidth]);

  const documentRef = useRef<Document>(document);

  const handleMouseDownResizer: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    setIsResizing?.(true);
  }, [setIsResizing]);

  const handleMouseUpDocument = useCallback(() => {
    setIsResizing?.(false);
  }, [setIsResizing]);

  const handleMouseMoveDocument = useCallback(
    (event: MouseEvent) => {
      if (!isResizing || !documentRef.current) {
        return;
      }
      event.preventDefault();
      const offsetRight = documentRef.current.body.offsetWidth - (event.clientX - documentRef.current.body.offsetLeft);
      if (offsetRight > minWidth && offsetRight < maxWidth) {
        const magnetizedWidth =
          offsetRight > defaultWidth - MAGNETISM_GAP && offsetRight < defaultWidth + MAGNETISM_GAP ? defaultWidth : offsetRight;
        setPanelWidth?.(`${magnetizedWidth}px`);
      }
    },
    [isResizing, setPanelWidth, minWidth, maxWidth, defaultWidth],
  );

  const handleSelectStartDocument = useCallback(
    (event: Event) => {
      if (isResizing) {
        event.preventDefault();
      }
    },
    [isResizing],
  );

  const handleClickResizer: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (event.detail === 2) {
        setPanelWidth?.(`${defaultWidth}px`);
      }
    },
    [setPanelWidth, defaultWidth],
  );

  // const handleCloseTooltip = useCallback(() => {
  //   setIsTooltipOpen(false);
  // }, []);

  // const handleOpenTooltip = useCallback(() => {
  //   setIsTooltipOpen(true);
  // }, []);

  useEventListener('mouseup', handleMouseUpDocument, documentRef);

  useEventListener('mousemove', handleMouseMoveDocument, documentRef);

  useEventListener('selectstart', handleSelectStartDocument, documentRef);

  return (
    <>
      {/* <Tooltip
        title="Double clique pour restaurer la taille par défaut"
        enterDelay={1000}
        followCursor
        open={isTooltipOpen && !isResizing}
        onClose={handleCloseTooltip}
        onOpen={handleOpenTooltip}
        disableInteractive
      > */}
      <ResizeHandler
        isResizing={isResizing}
        orientation="vertical"
        sx={{ position: 'absolute', top: 0, left: 0 }}
        onClick={handleClickResizer}
        onMouseDown={handleMouseDownResizer}
      />
      {/* </Tooltip> */}
    </>
  );
};

export default RightPanelDrawerResizer;
