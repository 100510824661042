import { Paper, PaperProps } from '@mui/material';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

export interface BlockPreviewPaperProps extends PaperProps {
  redirect: boolean;
  url: string;
}

const BlockPreviewPaper: FC<BlockPreviewPaperProps> = ({ redirect, url, ...props }) => (
  <>
    {!redirect && <Paper {...props} />}
    {redirect && <Paper component={NavLink} to={url} {...props} />}
  </>
);

export default BlockPreviewPaper;
