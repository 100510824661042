import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Dispatch, FC, FormEventHandler, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FolderInfo, UpdateFolderBody } from '@dametis/core';

import { createUpdateFolderBody } from 'components/Lego/helpers/createUpdateFolderBody';
import { useDispatch } from 'store';
import { useUpdateFolderMutation } from 'store/api/folders';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import StepInformations from '../CreateFolderModal/StepInformations';

export interface UpdateFolderModalProps {
  isOpen: boolean;
  folder: FolderInfo;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const UpdateFolderModal: FC<UpdateFolderModalProps> = ({ isOpen, folder, setIsOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('lego');

  const [updateFolder, { isLoading: isUpdating }] = useUpdateFolderMutation();

  const [updateFolderBody, setUpdateFolderBody] = useState<UpdateFolderBody>(createUpdateFolderBody);

  const isFolderValid = useMemo(() => (updateFolderBody.name ?? '').trim().length > 0, [updateFolderBody]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async event => {
      event.preventDefault();
      try {
        await updateFolder({ uuid: folder.uuid, body: updateFolderBody }).unwrap();
        setIsOpen(false);
        dispatch(addToast({ message: t('toast.updateFolderSuccess'), severity: ToastSeverity.SUCCESS }));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, folder, setIsOpen, t, updateFolder, updateFolderBody],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      setUpdateFolderBody(createUpdateFolderBody(folder));
    }
  }, [isOpen, folder]);

  return (
    <>
      <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={handleClose}>
        <DialogTitle>{t('title.updateFolderModal')}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <StepInformations<UpdateFolderBody> folderBody={updateFolderBody} setFolderBody={setUpdateFolderBody} />
          </DialogContent>
          <DialogActions>
            <Button color="primary" disabled={isUpdating} variant="text" onClick={handleClose}>
              {t('button.cancel')}
            </Button>
            <LoadingButton color="secondary" disabled={!isFolderValid} loading={isUpdating} type="submit" variant="contained">
              {t('button.update')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UpdateFolderModal;
