import { QueryStatsOutlined, SettingsOutlined, ShowChartOutlined } from '@mui/icons-material';
import { Stack, ToggleButtonGroup, Tooltip } from '@mui/material';
import { FC, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import SmallToggleButton from 'components/UI/SmallToggleButton/SmallToggleButton';

import { CalculationChartMenu } from './CalculationChart';

interface CalculationNavBarProps {
  selectedMenu: CalculationChartMenu;
  setSelectedMenu: (newMenu: CalculationChartMenu) => void;
}

const CalculationNavBar: FC<CalculationNavBarProps> = ({ selectedMenu, setSelectedMenu }) => {
  const { t } = useTranslation('calculationMenu');

  const handleSelectMenu = useCallback(
    (event: MouseEvent<HTMLElement>, newMenu: CalculationChartMenu | null) => {
      if (newMenu) {
        setSelectedMenu(newMenu);
      }
    },
    [setSelectedMenu],
  );

  return (
    <Stack direction="row" justifyContent="center">
      <ToggleButtonGroup exclusive size="small" sx={{ mb: 1 }} value={selectedMenu} onChange={handleSelectMenu}>
        <SmallToggleButton sx={{ gap: 1, px: theme => `${theme.spacing(1)}!important` }} value={CalculationChartMenu.GRAPH}>
          <ShowChartOutlined fontSize="small" />
          {t(`tooltip.menu.${CalculationChartMenu.GRAPH}`)}
        </SmallToggleButton>
        <SmallToggleButton sx={{ gap: 1, mb: 1, px: theme => `${theme.spacing(1)}!important` }} value={CalculationChartMenu.STATISTICS}>
          <QueryStatsOutlined fontSize="small" />
          {t(`tooltip.menu.${CalculationChartMenu.STATISTICS}`)}
        </SmallToggleButton>
        <Tooltip placement="right" title={t(`tooltip.menu.${CalculationChartMenu.SETTINGS}`)}>
          <SmallToggleButton sx={{ gap: 1, mb: 1, px: theme => `${theme.spacing(1)}!important` }} value={CalculationChartMenu.SETTINGS}>
            <SettingsOutlined fontSize="small" />
          </SmallToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default CalculationNavBar;
