import { ClickAwayListener, Stack, Typography } from '@mui/material';
import {
  DateTimePicker,
  DateTimePickerProps,
  LocalizationProvider,
  dateCalendarClasses,
  multiSectionDigitalClockClasses,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { FC, useCallback, useMemo, useState } from 'react';

import { CommentInfo, CreateCommentInfo, IsCommentInfo, RawPeriod, Shortcut } from '@dametis/core';

import DateTimeRangeSetter from 'components/UI/DateTimeRangePanel/DateTimeRangeSetter';
import ShortcutChip, { ShortcutChipProps } from 'components/UI/Shortcut/ShortcutChip';
import { getLocale } from 'localization';
import { localizedFormat } from 'localization/localizedDateFns';

import { useCommentContext } from './context/CommentContext';

export interface CommentProps {
  comment?: CommentInfo | CreateCommentInfo;
  onDateClick?: (date: Date) => void;
  onPeriodClick?: (period: { from: Date; to: Date }) => void;
  loading?: boolean;
  datePicker?: boolean;
}

const CommentOptionsFooter: FC<CommentProps> = ({
  comment = undefined,
  loading = false,
  onDateClick = undefined,
  onPeriodClick = undefined,
  datePicker = false,
}) => {
  const { currentComment, setCurrentComment } = useCommentContext();
  const [dateTimePickerOpen, setDateTimePickerOpen] = useState<boolean>(false);

  const editing = useMemo(() => Boolean(currentComment), [currentComment]);

  const shortcut: Shortcut | null = useMemo(() => {
    if (currentComment !== null && 'shortcut' in currentComment) {
      return currentComment.shortcut ?? null;
    }
    if (comment !== undefined && IsCommentInfo(comment)) {
      return comment.shortcut ?? null;
    }
    return null;
  }, [comment, currentComment]);

  const period: RawPeriod | null = useMemo(() => {
    if (currentComment !== null && 'period' in currentComment) {
      return currentComment.period;
    }
    if (comment !== undefined && IsCommentInfo(comment)) {
      return comment.period;
    }
    return null;
  }, [comment, currentComment]);

  const date: Date | null = useMemo(() => {
    if (currentComment !== null && 'date' in currentComment) {
      return currentComment.date;
    }
    if (comment !== undefined && IsCommentInfo(comment)) {
      return comment.date;
    }
    return null;
  }, [comment, currentComment]);

  const handleDateClick = useCallback<NonNullable<DateTimePickerProps<Date>['onChange']>>(
    newDate => {
      if (editing && currentComment !== null) {
        setCurrentComment({ ...currentComment, date: newDate });
      } else if (onDateClick && date) {
        onDateClick(new Date(date));
      }
    },
    [editing, onDateClick, date, setCurrentComment, currentComment],
  );

  const handlePeriodClick = useCallback(
    ([from, to]: Date[]) => {
      if (period) {
        if (editing && currentComment !== null) {
          setCurrentComment({ ...currentComment, period: { from: from.toISOString(), to: to.toISOString() } });
        } else if (onPeriodClick) {
          onPeriodClick({ from: new Date(period.from), to: new Date(period.to) });
        }
      }
    },
    [currentComment, editing, onPeriodClick, period, setCurrentComment],
  );

  const handleDeleteShortcut = useCallback<NonNullable<ShortcutChipProps['onDelete']>>(
    event => {
      event.preventDefault();
      if (setCurrentComment && currentComment !== null) {
        setCurrentComment({ ...currentComment, shortcut: null });
      }
    },
    [currentComment, setCurrentComment],
  );

  const toggleDatePicker = useCallback(() => setDateTimePickerOpen(oldValue => !oldValue), []);

  const handleClickAway = (e: MouseEvent | TouchEvent) => {
    const check = (elem: any) =>
      [...Object.values(dateCalendarClasses), ...Object.values(multiSectionDigitalClockClasses)].some(className =>
        elem?.classList?.contains(className),
      );
    const chromeCheck = (evt: any) => evt?.path?.some(check);
    const firefoxCheck = (target: any) => {
      if (check(target)) {
        return true;
      }
      if (target?.parentElement) {
        return firefoxCheck(target.parentElement);
      }
      return false;
    };
    if (chromeCheck(e) || firefoxCheck(e.target)) return;
    setDateTimePickerOpen(false);
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      flexWrap="wrap"
      spacing={1}
      sx={{
        p: theme => `0 ${theme.spacing(1)}`,
        maxWidth: '100%',
      }}
    >
      {period ? (
        <DateTimeRangeSetter
          disabled={loading}
          editing={editing}
          from={new Date(period.from)}
          to={new Date(period.to)}
          onChange={handlePeriodClick}
        />
      ) : (
        date && (
          <LocalizationProvider adapterLocale={getLocale()} dateAdapter={AdapterDateFns}>
            {editing && datePicker ? (
              <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
                <DateTimePicker
                  open={dateTimePickerOpen}
                  value={new Date(date)}
                  onChange={handleDateClick}
                  onClose={toggleDatePicker}
                  onOpen={toggleDatePicker}
                />
              </ClickAwayListener>
            ) : (
              <Typography
                sx={{
                  fontSize: '0.9em',
                  fontStyle: 'italic',
                }}
                variant="body1"
              >
                {localizedFormat(new Date(date), 'PPpp')}
              </Typography>
            )}
          </LocalizationProvider>
        )
      )}
      {shortcut && (
        <ShortcutChip clickable={editing} shortcut={shortcut} size="small" onDelete={editing ? handleDeleteShortcut : undefined} />
      )}
    </Stack>
  );
};

export default CommentOptionsFooter;
