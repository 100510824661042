import { StandardBlockParameterBody } from '@dametis/core';

import { BlockBase, ShortMetric, getMetricDependencies } from './getMetricDependencies';

export const isMetricWorking = (metric: ShortMetric, base: BlockBase, parameters: StandardBlockParameterBody[]) => {
  const dependencies = getMetricDependencies(metric, base);
  return parameters
    .filter(parameter => dependencies[parameter.blockKey])
    .every(parameter => parameter.isSelected || parameter.defaultValue !== null);
};
