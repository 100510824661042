import { FC, useCallback, useContext } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useReadOnly, useSelected, useSlate } from 'slate-react';

import { VariableElement } from '@dametis/core';

import { PropsContext } from '../../../context';
import { VariableMenuProps } from '../VariableMenu/VariableMenu';

import Variable from './Variable';

interface Props extends RenderElementProps {
  element: VariableElement;
}

const SlateVariable: FC<Props> = ({ element, attributes, children = undefined }) => {
  const editor = useSlate();
  const readOnly = useReadOnly();
  const selected = useSelected() && !readOnly;

  const { disableMaths, butKeepVariableMenu } = useContext(PropsContext);

  const changeVariable = useCallback<VariableMenuProps['onVariableChange']>(
    variableProps => {
      const path = ReactEditor.findPath(editor, element);
      const variable: Partial<VariableElement> = {
        variable: { ...element.variable, ...variableProps },
      };
      Transforms.setNodes(editor, variable, { at: path });
    },
    [editor, element],
  );

  return (
    <Variable
      disableVariableMenu={readOnly || (disableMaths && !butKeepVariableMenu)}
      selected={selected}
      variable={element.variable}
      VariableSpanProps={attributes}
      onVariableChange={changeVariable}
    >
      {children}
    </Variable>
  );
};

export default SlateVariable;
