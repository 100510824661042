import type { Series } from 'highcharts';
import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';

export interface LineSeriesContextState {
  series: Series | null;
}

export const initialState = {
  series: null,
};

export const LineSeriesContext = createContext<LineSeriesContextState>(initialState);

interface LineSeriesProviderProps {
  series: Series;
}

const LineSeriesProvider: FC<PropsWithChildren<LineSeriesProviderProps>> = ({ series, children = undefined }) => {
  const contextValues: LineSeriesContextState = useMemo(() => ({ series }), [series]);

  return <LineSeriesContext.Provider value={contextValues}>{children}</LineSeriesContext.Provider>;
};

export const useLineSeriesContext = () => {
  const context = useContext(LineSeriesContext);

  if (!context) {
    throw Error('useLineSeriesContext must be used inside an LineSeriesProvider');
  }

  return context;
};

export default LineSeriesProvider;
