import { AccordionProps, AccordionSummary, ListItem, ListItemButton, ListItemText, Radio } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'store';
import { VncFilters } from 'zustand/states/vnc';
import { useVncStore } from 'zustand/stores/vnc';

import { FilterElementProps } from './Filters';
import { FilterAccordion, FilterAccordionDetails, FilterAccordionSummaryStack, FilterList, FilterListSubheader } from './styled';

export enum InfluentVariablesState {
  HIDDEN = 'hidden',
  VISIBLE = 'visible',
}

interface SortedInfluentVariablesState {
  key: InfluentVariablesState;
  name: string;
  enabled: boolean;
}

const InfluentVariablesFilters: FC<FilterElementProps> = ({ expanded, setExpanded, accordionsNumber }) => {
  const { t } = useTranslation('vnc');
  const dispatch = useDispatch();

  const influentVariables = useVncStore(state => state.filters.influentVariables);
  const setFiltersValues = useVncStore(state => state.setFiltersValues);
  const getResults = useVncStore(state => state.getResults);

  const sortedInfluentVariables = useMemo<SortedInfluentVariablesState[]>(
    () =>
      Object.entries(influentVariables)
        .map(([key, enabled]) => ({ key: key as InfluentVariablesState, name: t(`influentVariablesState.${key}`), enabled }))
        .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())),
    [influentVariables, t],
  );

  const trueInfluentVariablesCount = useMemo(() => Object.values(influentVariables).filter(Boolean).length, [influentVariables]);

  const handleChangeExpanded = useCallback<NonNullable<AccordionProps['onChange']>>(
    (event, isExpanded) => {
      setExpanded(isExpanded ? 'influentVariables' : false);
    },
    [setExpanded],
  );

  const handleSelectState = useCallback(
    (newState: InfluentVariablesState) => async () => {
      const newFilters = Object.values(InfluentVariablesState).reduce<VncFilters['influentVariables']>((acc, state) => {
        acc[state] = state === newState;
        return acc;
      }, {});
      setFiltersValues({ influentVariables: newFilters });
      await dispatch(getResults());
    },
    [dispatch, getResults, setFiltersValues],
  );

  const handleSelectAll = useCallback(async () => {
    const newFilters = Object.values(InfluentVariablesState).reduce<VncFilters['influentVariables']>((acc, state) => {
      acc[state] = false;
      return acc;
    }, {});
    setFiltersValues({ influentVariables: newFilters });
    await dispatch(getResults());
  }, [dispatch, getResults, setFiltersValues]);

  return (
    <FilterAccordion expanded={expanded} filterAccordionsNumber={accordionsNumber} onChange={handleChangeExpanded}>
      <AccordionSummary>
        <FilterAccordionSummaryStack>
          <FilterListSubheader>{t('accordion.influentVariables')}</FilterListSubheader>
        </FilterAccordionSummaryStack>
      </AccordionSummary>
      <FilterAccordionDetails>
        <FilterList>
          <ListItem
            disablePadding
            secondaryAction={<Radio checked={trueInfluentVariablesCount === 0} edge="end" tabIndex={-1} onClick={handleSelectAll} />}
          >
            <ListItemButton selected={trueInfluentVariablesCount === 0} onClick={handleSelectAll}>
              <ListItemText>{t('list.text.influentVariablesAll')}</ListItemText>
            </ListItemButton>
          </ListItem>
          {sortedInfluentVariables.map(state => (
            <ListItem
              key={state.key}
              disablePadding
              secondaryAction={<Radio checked={state.enabled} edge="end" tabIndex={-1} onClick={handleSelectState(state.key)} />}
            >
              <ListItemButton selected={state.enabled} onClick={handleSelectState(state.key)}>
                <ListItemText primary={state.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </FilterList>
      </FilterAccordionDetails>
    </FilterAccordion>
  );
};

export default InfluentVariablesFilters;
