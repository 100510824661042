import { Box, Typography } from '@mui/material';
import { EditableProps } from 'slate-react/dist/components/editable';

import { BatchElement, OperatorElement, Symbols } from '@dametis/core';

import { SlateSymbol } from 'components/VNC/components/Title/Textarea/SlateSymbol';
import { ONE_CHARACTER_WIDTH } from 'components/VNC/components/Title/styled';

import SlateBatch from './SlateBatch';
import SlateOperator from './SlateOperator';
import { createBatchElement } from './insertBatchInSlate';
import { createOperatorElement } from './insertOperatorInSlate';

export const RenderElement: EditableProps['renderElement'] = ({ element, attributes, children }) => {
  let batch: BatchElement | null = null;
  let operator: OperatorElement | null = null;

  // for retrocompatibility
  if (element.type === 'variable') {
    batch = createBatchElement(element.variable as BatchElement['batch']);
  }
  if (element.type === 'symbol') {
    operator = createOperatorElement(element.symbol as OperatorElement['operator']);
  }

  switch (element.type) {
    case 'operator':
      return (
        <SlateOperator attributes={attributes} element={element}>
          {children}
        </SlateOperator>
      );
    case 'batch':
      return (
        <SlateBatch attributes={attributes} element={element}>
          {children}
        </SlateBatch>
      );
    case 'variable':
      return batch !== null ? (
        <SlateBatch attributes={attributes} element={batch}>
          {children}
        </SlateBatch>
      ) : (
        <Typography variant="caption">nullBatch{children}</Typography>
      );
    case 'symbol':
      if (operator !== null && (operator.operator === Symbols.AND || operator.operator === Symbols.OR)) {
        return (
          <SlateOperator attributes={attributes} element={operator}>
            {children}
          </SlateOperator>
        );
      }
      return (
        <SlateSymbol attributes={attributes} element={element}>
          {children}
        </SlateSymbol>
      );
    default:
      return (
        <Box component="span" display="block" sx={{ m: 0, '&:not(:first-of-type)': { ml: `${ONE_CHARACTER_WIDTH}px` } }} {...attributes}>
          {children}
        </Box>
      );
  }
};
