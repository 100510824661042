import { AlarmPriority } from '@dametis/core';

export default {
  title: {
    alarm: 'Alarma "{{name}}"',
    newAlarm: 'Nueva alarma',
    when: 'Condiciones',
    do: 'Acciones',
    logs: 'Registro',
    leave: 'Salir sin guardar',
  },
  button: {
    createAlarm: 'Crear',
    addRule: 'Añadir',
    addAction: 'Añadir',
    cancel: 'Cancelar',
    add: 'Añadir',
    validate: 'Validar',
  },
  input: {
    label: {
      name: 'Nombre',
      criticality: 'Criticidad',
      durationValue: 'Duración mínima antes de la activación',
      calendar: 'Período de activación',
      condition: 'Regla de activación',
      email: 'Dirección de correo electrónico',
      phone: 'Teléfono',
      autoAck: 'AutoAck',
      tamatPoste: 'Puesto Tamat',
      ackCommentRequired: 'Comentario de acuse de recibo',
    },
    helperText: {
      advanced: 'Comparar con un cálculo',
      simple: 'Comparar con un valor',
    },
    adornment: {
      durationValue: 'durante',
    },
    value: {
      joinWith_and: 'Y',
      joinWith_or: 'O',
      required: 'Obligatorio',
      optional: 'Opcional',
    },
  },
  actions: {
    app: {
      showInActiveAlarms: 'Recibir una notificación en Alarmas activas',
    },
    emails: {
      title: 'Correo electrónico',
      sendEmail: 'Recibir un correo electrónico en...',
    },
    phone: {
      title: 'Teléfono',
      sendText: 'Recibir un SMS en...',
    },
    whatsapp: {
      title: 'WhatsApp',
      sendMessage: 'Recibir un mensaje de WhatsApp en...',
    },
    tamat: {
      title: 'Tamat',
      sendAlarm: 'Enviar la alarma a Tamat...',
    },
  },
  criticality: {
    [`variant_${AlarmPriority.NOTIFICATION}`]: 'Notificación',
    [`variant_${AlarmPriority.WARNING}`]: 'Atención',
    [`variant_${AlarmPriority.CRITICAL}`]: 'Crítico',
  },
  toast: {
    createAlarmSuccess: 'Alarma creada con éxito.',
    createAlarmError: 'Se produjo un error al crear la alarma.',
    saveAlarmSuccess: 'Cambios guardados.',
    saveAlarmError: 'Se produjo un error al guardar los cambios.',
    deleteAlarmSuccess: 'Alarma eliminada con éxito.',
    deleteAlarmError: 'Se produjo un error al vaciar la alarma.',
  },
  tooltip: {
    saveAlarm: 'guardar la alarma',
    acknowledgeAlarm: 'acuse de recibo de la alarma',
    shareAlarm: 'compartir la alarma',
    cancelAlarm: 'cancelar la alarma',
    deleteAlarm: 'Vaciar la alarma',
    muteAlarm: 'poner en silencio la alarma',
    unmuteAlarm: 'hacer activa la alarma',
    addCondition: 'Añadir una alarma',
    deleteCondition: 'vaciar la alarma',
    addAction: 'Añadir una acción',
    noChangeYet: 'Sin cambios todavía',
    previousChart: 'Gráfico anterior',
    nextChart: 'Siguiente gráfico',
    autoAck:
      'Cuando la opción AutoAck está activada, la alarma se acusa automáticamente cuando se resuelve el incidente. Siempre recibirá una notificación cuando se active la alarma.',
  },
};
