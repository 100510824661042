import {
  AddOutlined,
  CheckOutlined,
  MedicalServicesOutlined,
  PlaylistAddCheckOutlined,
  PlaylistAddOutlined,
  WorkOutline,
} from '@mui/icons-material';
import { List, Popover, paperClasses } from '@mui/material';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockVariable, CalculationVariable, DataVariable, GetCalculationVariableStats, ModelVariable } from '@dametis/core';

import { checkParsedCalculationInCart } from 'components/Layouts/Cart/checkParsedCalculationInCart';
import useCartStorage from 'components/Layouts/Cart/useCartStorage';
import { createCalculationVariable } from 'functions/createCalculationVariable';
import { useSelector } from 'store';

import CalculationMenuItem from '../../CalculationMenuItem';

const emptyCalc = createCalculationVariable();

const AddToCartOption: FC = () => {
  const { t } = useTranslation('calculationMenu');
  const { items: cartItems, addItem, addItems } = useCartStorage();

  const calculation = useSelector(state => state.calculationMenu.calculation);

  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<HTMLLIElement | null>(null);

  const subMenuAnchorRef = useRef<HTMLLIElement | null>(null);

  const isSubMenuOpen = useMemo(() => Boolean(subMenuAnchorEl), [subMenuAnchorEl]);
  const statistics = useMemo(() => GetCalculationVariableStats(calculation ?? emptyCalc), [calculation]);
  const variables = useMemo(() => [...statistics.dataVariables, ...statistics.blockVariables, ...statistics.modelVariables], [statistics]);
  const isSingleVariable = useMemo(() => variables.length === 1, [variables]);

  const calculations = useMemo(
    () =>
      variables.map<CalculationVariable<DataVariable | BlockVariable | ModelVariable>>(variable => ({
        exp: 'var_0',
        vars: { var_0: variable },
      })),
    [variables],
  );

  const isCalculationAlreadyInCart = useMemo(
    () => checkParsedCalculationInCart(cartItems, calculation ?? emptyCalc),
    [cartItems, calculation],
  );

  const areVariablesAlreadyInCart = useMemo(
    () => calculations.every(variableCalculation => checkParsedCalculationInCart(cartItems, variableCalculation), false),
    [cartItems, calculations],
  );

  const handleAddCalculationToCart = useCallback(() => {
    if (calculation === null) {
      return;
    }
    addItem(calculation);
  }, [addItem, calculation]);

  const handleAddVariablesToCart = useCallback(() => {
    addItems(calculations);
  }, [addItems, calculations]);

  const handleOpenSubMenu = useCallback(() => {
    if (subMenuAnchorRef.current) {
      setSubMenuAnchorEl(subMenuAnchorRef.current);
    }
  }, []);

  const handleCloseSubMenu = useCallback(() => {
    setSubMenuAnchorEl(null);
  }, []);

  return (
    <>
      <CalculationMenuItem
        ref={subMenuAnchorRef}
        arrow={!isSingleVariable}
        disabled={isSingleVariable && isCalculationAlreadyInCart}
        icon={isSingleVariable && isCalculationAlreadyInCart ? WorkOutline : MedicalServicesOutlined}
        label={isSingleVariable && isCalculationAlreadyInCart ? t('label.alreadyInCart') : t('label.addToCart')}
        onClick={isSingleVariable ? handleAddCalculationToCart : undefined}
        onMouseOut={!isSingleVariable ? handleCloseSubMenu : undefined}
        onMouseOver={!isSingleVariable ? handleOpenSubMenu : undefined}
      />
      {!isSingleVariable && (
        <Popover
          anchorEl={subMenuAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isSubMenuOpen}
          sx={{
            pointerEvents: 'none',
            [`& .${paperClasses.root}`]: { pointerEvents: 'all' },
            transform: `translateY(-6px) translateX(6px)`,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List sx={{ py: 1 }} onMouseOut={handleCloseSubMenu} onMouseOver={handleOpenSubMenu}>
            <CalculationMenuItem
              disabled={isCalculationAlreadyInCart}
              icon={isCalculationAlreadyInCart ? CheckOutlined : AddOutlined}
              label={isCalculationAlreadyInCart ? t('label.alreadyInCart') : t('label.addCalculation')}
              onClick={handleAddCalculationToCart}
            />
            <CalculationMenuItem
              disabled={areVariablesAlreadyInCart}
              icon={areVariablesAlreadyInCart ? PlaylistAddCheckOutlined : PlaylistAddOutlined}
              label={areVariablesAlreadyInCart ? t('label.alreadyInCart') : t('label.addVariables', { count: variables.length })}
              onClick={handleAddVariablesToCart}
            />
          </List>
        </Popover>
      )}
    </>
  );
};

export default AddToCartOption;
