import { Button, ButtonProps } from '@mui/material';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import useBigButtonStyles from './BigButton.styles';

export interface BigButtonProps extends Partial<ButtonProps & Omit<NavLinkProps, 'to'>> {
  to?: string;
  className?: string;
}

const BigButton = forwardRef(({ className, children, to, ...props }: BigButtonProps, ref) => {
  const classes = useBigButtonStyles();
  return (
    <Button ref={ref} classes={{ root: clsx(classes.bigButton, className) }} component={to ? NavLink : 'button'} to={to} {...props}>
      {children}
    </Button>
  );
});

BigButton.defaultProps = {
  className: '',
  to: undefined,
};

BigButton.displayName = 'BigButton';

export default BigButton;
