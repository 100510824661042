import { Components, Theme, alpha } from '@mui/material';

export default {
  styleOverrides: {
    colorTransparent: ({ theme }) => ({
      backgroundColor: alpha(theme.palette.background.paper, 0.95),
      backgroundBlendMode: 'overlay',
      '@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
        backgroundColor: theme.palette.background.defaultTransparent,
        backdropFilter: 'blur(20px) saturate(180%)',
      },
    }),
  },
} as Components<Theme>['MuiAppBar'];
