import { Link, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { BlockTypeInfo, ShortStandardBlockInfo } from '@dametis/core';

import { getStandardBlockUrl } from 'components/Lego/helpers/getStandardBlockUrl';
import { RightPanelBody, RightPanelSection, RightPanelSectionContent } from 'components/UI/RightPanel';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';
import { useStandardBlocks } from 'store/api/standardBlocks';

const standardBlocksEmptyArray: ShortStandardBlockInfo[] = [];

export interface StandardBlocksTabProps {
  blockType: BlockTypeInfo;
}

const StandardBlocksTab: FC<StandardBlocksTabProps> = ({ blockType }) => {
  const { t } = useTranslation('lego');

  const { data: globalRootFolder = null } = useReadGlobalRootFolderQuery();
  const { data: standardBlocks = standardBlocksEmptyArray } = useStandardBlocks();

  const filteredStandardBlocks = useMemo(
    () =>
      standardBlocks
        .filter(standardBlock => standardBlock.blockTypeId === blockType.uuid)
        .sort((standardBlockA, standardBlockB) => standardBlockA.name.localeCompare(standardBlockB.name)),
    [standardBlocks, blockType],
  );

  return (
    <RightPanelBody>
      <RightPanelSection>
        <RightPanelSectionContent>
          {filteredStandardBlocks.length > 0 && (
            <Stack alignItems="flex-start" gap={1}>
              {filteredStandardBlocks.map(standardBlock => (
                <Link key={standardBlock.uuid} component={NavLink} to={getStandardBlockUrl(globalRootFolder, standardBlock.uuid)}>
                  {standardBlock.name}
                </Link>
              ))}
            </Stack>
          )}
          {filteredStandardBlocks.length === 0 && (
            <Typography align="center" p={2} variant="subtitle2">
              {t('text.noStandardBlock')}
            </Typography>
          )}
        </RightPanelSectionContent>
      </RightPanelSection>
    </RightPanelBody>
  );
};

export default StandardBlocksTab;
