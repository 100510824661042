import { UpdateBlockTypeBody } from '@dametis/core';

import { palette } from 'theme/palette';
import { DamIconName } from 'types/iconPicker';

export const createUpdateBlockTypeBody = ({
  name = '',
  parameters = [],
  metrics = [],
  connections = [],
  description = '',
  content = { icon: DamIconName.BLOCKTYPE, color: palette.gradients.greens[2] },
  folderId = undefined,
}: Partial<UpdateBlockTypeBody> = {}): UpdateBlockTypeBody => ({
  name,
  parameters,
  metrics,
  connections,
  description,
  content,
  folderId,
});
