import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogTitle, Paper, Stack } from '@mui/material';
import { Dispatch, FC, MouseEventHandler, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ShortStandardBlockInfo, StandardBlockInfo, UUID, UpdateStandardBlockBody } from '@dametis/core';

import { createUpdateStandardBlockBody } from 'components/Lego/helpers/standardBlock/createUpdateStandardBlockBody';
import { useDispatch } from 'store';
import { useUpdateStandardBlockMutation } from 'store/api/standardBlocks';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import GeneralStep from '../CreateStandardBlockModal/GeneralStep';
import MetadataListStep from '../CreateStandardBlockModal/MetadataListStep/MetadataListStep';
import ModalStepper from '../CreateStandardBlockModal/ModalStepper';
import ParametersAndMetricsStep from '../CreateStandardBlockModal/ParametersAndMetricsStep/ParametersAndMetricsStep';
import { getIsGeneralStepValid } from '../CreateStandardBlockModal/helpers/getIsGeneralStepValid';
import { getIsMetadataListStepValid } from '../CreateStandardBlockModal/helpers/getIsMetadataListStepValid';
import { getIsParametersAndMetricsStepValid } from '../CreateStandardBlockModal/helpers/getIsParametersAndMetricsStepValid';

export interface EditStandardBlockModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  standardBlock: ShortStandardBlockInfo;
  folderId?: UUID;
  onUpdate?: (updatedStandardBlock: StandardBlockInfo) => Promise<void> | void;
}

const EditStandardBlockModal: FC<EditStandardBlockModalProps> = ({
  isOpen,
  setIsOpen,
  standardBlock,
  folderId = undefined,
  onUpdate = undefined,
}) => {
  const { t } = useTranslation('lego');
  const dispatch = useDispatch();

  const [updateStandardBlock, { isLoading: isUpdating }] = useUpdateStandardBlockMutation();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [updateStandardBlockBody, setUpdateStandardBlockBody] = useState<UpdateStandardBlockBody>(createUpdateStandardBlockBody);

  const isGeneralStepValid = useMemo(() => getIsGeneralStepValid(updateStandardBlockBody), [updateStandardBlockBody]);

  const isMetadataListStepValid = useMemo(() => getIsMetadataListStepValid(updateStandardBlockBody), [updateStandardBlockBody]);

  const isParametersAndMetricsStepValid = useMemo(() => getIsParametersAndMetricsStepValid(), []);

  const isFormValid = useMemo(
    () => isGeneralStepValid && isMetadataListStepValid && isParametersAndMetricsStepValid,
    [isMetadataListStepValid, isParametersAndMetricsStepValid, isGeneralStepValid],
  );

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handlePreviousStep: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setActiveStep(state => state - 1);
  }, []);

  const handleNextStep: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setActiveStep(state => state + 1);
  }, []);

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(async () => {
    try {
      const data = await updateStandardBlock({ uuid: standardBlock.uuid, body: updateStandardBlockBody }).unwrap();
      if (onUpdate) {
        await onUpdate(data);
      }
      setIsOpen(false);
      dispatch(addToast({ message: t('toast.updateStandardBlockSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [updateStandardBlockBody, standardBlock, updateStandardBlock, dispatch, setIsOpen, t, onUpdate]);

  useEffect(() => {
    if (isOpen) {
      setUpdateStandardBlockBody(createUpdateStandardBlockBody({ ...standardBlock, folderId }));
      setActiveStep(0);
    }
  }, [isOpen, folderId, standardBlock]);

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleCloseModal}>
      <DialogTitle sx={{ pb: 1 }}>{t('title.editStandardBlockModal')}</DialogTitle>
      <Stack direction="row" gap={1} overflow="hidden" px={3}>
        <Box flexShrink={0} width={250}>
          <ModalStepper activeStep={activeStep} standardBlockBody={updateStandardBlockBody} />
        </Box>
        <Paper sx={{ p: 2, flexGrow: 1, display: 'flex', alignItems: 'stretch', overflow: 'hidden' }}>
          {activeStep === 0 && (
            <GeneralStep<UpdateStandardBlockBody>
              isCreating={false}
              setStandardBlockBody={setUpdateStandardBlockBody}
              standardBlockBody={updateStandardBlockBody}
            />
          )}
          {activeStep === 1 && (
            <MetadataListStep<UpdateStandardBlockBody>
              setStandardBlockBody={setUpdateStandardBlockBody}
              standardBlockBody={updateStandardBlockBody}
            />
          )}
          {activeStep === 2 && (
            <ParametersAndMetricsStep<UpdateStandardBlockBody>
              setStandardBlockBody={setUpdateStandardBlockBody}
              standardBlockBody={updateStandardBlockBody}
            />
          )}
        </Paper>
      </Stack>
      <DialogActions>
        {activeStep === 0 && (
          <>
            <Button color="primary" variant="text" onClick={handleCloseModal}>
              {t('button.close')}
            </Button>
            <Button
              color="secondary"
              disabled={!isGeneralStepValid}
              endIcon={<ArrowForwardIos />}
              variant="contained"
              onClick={handleNextStep}
            >
              {t('button.next')}
            </Button>
          </>
        )}
        {activeStep === 1 && (
          <>
            <Button color="primary" startIcon={<ArrowBackIosNew />} variant="text" onClick={handlePreviousStep}>
              {t('button.previous')}
            </Button>
            <Button
              color="secondary"
              disabled={!isMetadataListStepValid}
              endIcon={<ArrowForwardIos />}
              variant="contained"
              onClick={handleNextStep}
            >
              {t('button.next')}
            </Button>
          </>
        )}
        {activeStep === 2 && (
          <>
            <Button color="primary" disabled={isUpdating} startIcon={<ArrowBackIosNew />} variant="text" onClick={handlePreviousStep}>
              {t('button.previous')}
            </Button>
            <LoadingButton color="secondary" disabled={!isFormValid} loading={isUpdating} variant="contained" onClick={handleSubmit}>
              {t('button.edit')}
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditStandardBlockModal;
