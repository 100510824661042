export default {
  title: {
    smoothing: 'Lissage',
  },
  input: {
    label: {
      operator: 'Opérateur',
    },
  },
};
