import i18next from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AUTO_GROUPBY, AnyGroupBy, GroupByUnits, IsTimeGroupBy, POINT_TO_POINT_GROUPBY, timeIntervalRegex } from '@dametis/core';

export const shortUnitToLongUnit: Record<GroupByUnits, string> = {
  ms: 'millisecond',
  s: 'second',
  m: 'minute',
  h: 'hour',
  d: 'day',
  w: 'week',
  mo: 'month',
  y: 'year',
};

export const getLocalizedGroupBy = (groupBy: AnyGroupBy, t = i18next.t, short = false, displayNone = false) => {
  const count = IsTimeGroupBy(groupBy) ? parseInt(groupBy, 10) : undefined;
  if (groupBy === null || groupBy === undefined) {
    return displayNone ? t('groupBy:none') : '';
  }
  if (groupBy === AUTO_GROUPBY) {
    return t('groupBy:auto');
  }
  if (groupBy === POINT_TO_POINT_GROUPBY) {
    return t('groupBy:pointToPoint', short ? { context: 'short' } : undefined);
  }
  if (IsTimeGroupBy(groupBy)) {
    const shortUnit = groupBy.match(timeIntervalRegex)?.[2];
    const unit = shortUnit ? shortUnitToLongUnit[shortUnit as GroupByUnits] : undefined;
    return `${count} ${t('global:unit.time', {
      count,
      context: short ? `${unit}_short` : unit,
    })}`;
  }
  return t('groupBy:batch');
};

export const useLocalizedGroupBy = (groupBy: AnyGroupBy, short = false, displayNone = false): string => {
  const { t } = useTranslation(['groupBy', 'global']);
  return useMemo(() => getLocalizedGroupBy(groupBy, t, short, displayNone), [groupBy, t, short, displayNone]);
};
