import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

import { CalculationVariable, Period } from '@dametis/core';

import { FormatResult } from 'types/format';

export type MenuPosition = {
  mouseX: number;
  mouseY: number;
};

export enum FromPeriod {
  LAST_HOUR = 'lastHour',
  LAST_DAY = 'lastDay',
  LAST_WEEK = 'lastWeek',
}

export enum ToPeriod {
  NOW = 'now',
  LAST_POINT = 'lastPoint',
}

export interface VariableLastPoint {
  date: Date | null;
  isFetching: boolean;
}

export interface CalculationMenuState {
  menuPosition: MenuPosition | null;
  calculation: CalculationVariable | null;
  apiCalculation: CalculationVariable | null;
  period: Period | null;
  selectedFrom: FromPeriod;
  selectedTo: ToPeriod;
  lastPoint: VariableLastPoint;
  format: FormatResult;
  items: ReactNode[];
  chartDisabled: boolean;
}

const initialState: CalculationMenuState = {
  menuPosition: null,
  calculation: null,
  apiCalculation: null,
  period: null,
  selectedFrom: FromPeriod.LAST_HOUR,
  selectedTo: ToPeriod.NOW,
  lastPoint: {
    date: null,
    isFetching: false,
  },
  format: null,
  items: [],
  chartDisabled: false,
};

export const calculationMenuSlice = createSlice({
  name: 'calculationMenu',
  initialState,
  reducers: {
    setMenuPosition: (state, action: PayloadAction<MenuPosition | null>) => {
      state.menuPosition = action.payload;
    },
    setCalculation: (state, action: PayloadAction<CalculationVariable | null>) => {
      state.calculation = action.payload;
    },
    setApiCalculation: (state, action: PayloadAction<CalculationVariable | null>) => {
      state.apiCalculation = action.payload;
    },
    setPeriod: (state, action: PayloadAction<Period | null>) => {
      if (state.period === null || !state.period.AlmostEquals(action.payload)) {
        state.period = action.payload;
      }
    },
    setSelectedFrom: (state, action: PayloadAction<FromPeriod>) => {
      state.selectedFrom = action.payload;
    },
    setSelectedTo: (state, action: PayloadAction<ToPeriod>) => {
      state.selectedTo = action.payload;
    },
    setLastPointDate: (state, action: PayloadAction<Date | null>) => {
      state.lastPoint.date = action.payload;
    },
    setLastPointIsFetching: (state, action: PayloadAction<boolean>) => {
      state.lastPoint.isFetching = action.payload;
    },
    setFormat: (state, action: PayloadAction<FormatResult>) => {
      state.format = action.payload;
    },
    setItems: (state, action: PayloadAction<ReactNode[]>) => {
      state.items = action.payload;
    },
    setChartDisabled: (state, action: PayloadAction<boolean>) => {
      state.chartDisabled = action.payload;
    },
    clearCalculationMenuStore: () => initialState,
  },
});

export const {
  setMenuPosition,
  setCalculation,
  setApiCalculation,
  setPeriod,
  setSelectedFrom,
  setSelectedTo,
  setLastPointDate,
  setLastPointIsFetching,
  setFormat,
  setItems,
  setChartDisabled,
  clearCalculationMenuStore,
} = calculationMenuSlice.actions;

export default calculationMenuSlice.reducer;
