import { Menu, MenuItem, MenuProps } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FromPeriod } from 'store/slices/calculationMenu';

export interface FromMenuProps extends Omit<MenuProps, 'value' | 'onChange'> {
  value: FromPeriod;
  onChange: (newFrom: FromPeriod) => void;
}

const FromMenu: FC<FromMenuProps> = ({ value, onChange, ...props }) => {
  const { t } = useTranslation('calculationMenu');

  const handleSelectValue = useCallback(
    (from: FromPeriod) => () => {
      onChange(from);
    },
    [onChange],
  );

  return (
    <Menu {...props}>
      {Object.values(FromPeriod).map(from => (
        <MenuItem key={from} selected={from === value} onClick={handleSelectValue(from)}>
          {t(`fromPeriod.${from}`)}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default FromMenu;
