export default {
  button: {
    close: 'Cerrar',
    previous: 'Anterior',
    next: 'Siguiente',
    create: 'Crear',
  },
  input: {
    fromModel: 'Usar un modelo',
  },
  stepper: {
    general: 'General',
    model: 'Modelo',
  },
};
