import { FormControlProps, OutlinedInputProps } from '@mui/material';
import { FC, useMemo, useRef } from 'react';

import { PhysicalQuantity } from '@dametis/core';
import { UnitConverter } from '@dametis/unit';

import UnitPickerButton from './UnitPickerButton';
import { useUnitPickerContext } from './UnitPickerContext';
import UnitPopoverBase from './UnitPopoverBase';

export interface UnitPickerBaseProps {
  label: boolean | string;
  editing: boolean;
  clearable: boolean;
  formControlProps: FormControlProps;
  inputProps: OutlinedInputProps;
  physicalQuantity?: PhysicalQuantity;
}

const UnitPickerBase: FC<UnitPickerBaseProps> = ({
  label,
  editing,
  clearable,
  formControlProps,
  inputProps,
  physicalQuantity = undefined,
}) => {
  const { baseUnit } = useUnitPickerContext();

  const parsedBaseUnit = useMemo(() => UnitConverter.Parse(baseUnit ?? null), [baseUnit]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <UnitPickerButton
        clearable={clearable}
        editing={editing}
        formControlProps={formControlProps}
        inputProps={inputProps}
        inputRef={inputRef}
        label={label}
        parsedBaseUnit={parsedBaseUnit}
        physicalQuantity={physicalQuantity}
      />
      <UnitPopoverBase anchorEl={inputRef.current ?? undefined} open={undefined} onClose={undefined} />
    </>
  );
};

export default UnitPickerBase;
