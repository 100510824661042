export default {
  label: {
    noData: 'Aucune donnée à afficher',
    loading: 'Chargement...',
    smartScale: 'Échelles adaptées',
    regularScale: 'Échelles dynamiques',
    resetZoom: 'Dézoomer',
    start: 'Début :',
    end: 'Fin :',
    date: 'Date :',
    dependency: 'Dépendances :',
    week: 'Semaine',
    duration: 'Durée',
    delta: 'Delta',
  },
  button: {
    edit: 'Modifier',
    delete: 'Supprimer',
    cancel: 'Annuler',
    add: 'Ajouter',
    save: 'Enregistrer',
  },
  input: {
    comment: 'Commentaire',
  },
  tooltip: {
    expandAdvancedMode: 'Mode avancé',
    insertComment: 'Nouveau commentaire',
    insertCommentDisabled: 'Vous ne pouvez pas commenter les variables calculées',
    drawSegment: 'Mesure à main levée',
    statistics: 'Statistiques',
    annotationOff: 'Desactiver les annotations',
    annotationOn: 'Activer les annotations',
    commentOff: 'Masquer les commentaires',
    commentOn: 'Afficher les commentaires',
    clearAll: 'Supprimer toutes les mesures',
    deleteMeasure: 'Supprimer la mesure',
  },
};
