import { Grid, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockInfo, BlockTypeInfo, IsEmptyCalculation, ShortStandardBlockInfo } from '@dametis/core';

import { BlocksOutlined } from 'assets/icons/BlocksOutlined';
import { isBlockConnectionValid } from 'components/Lego/helpers/block/isBlockConnectionValid';
import { isBlockMetadataValid } from 'components/Lego/helpers/block/isBlockMetadataValid';

import BlockTileContainer, { BlockTileContainerProps } from './BlockTileContainer';
import BlockTileSubtitle from './BlockTileSubtitle';
import ElementProgress from './ElementProgress';

export interface BlockTileProps extends BlockTileContainerProps {
  block: BlockInfo;
  standardBlock?: ShortStandardBlockInfo;
  blockType?: BlockTypeInfo;
}

const BlockTile: FC<BlockTileProps> = ({ block, standardBlock = undefined, blockType = undefined, ...props }) => {
  const { t } = useTranslation('lego');

  const totalParameters = useMemo(() => block.parameters.filter(parameter => parameter.isSelected), [block.parameters]);

  const availableParameters = useMemo(
    () =>
      totalParameters.filter(
        parameter => (!!parameter.calculation && !IsEmptyCalculation(parameter.calculation)) || !!parameter.defaultValue,
      ),
    [totalParameters],
  );

  const totalMetrics = useMemo(() => block.metrics.filter(metric => metric.isSelected), [block.metrics]);
  const availableMetrics = useMemo(() => totalMetrics.filter(metric => !!metric.templatedCalculation), [totalMetrics]);

  const availableMetadataList = useMemo(
    () => block.blockMetadataList.filter(metadata => isBlockMetadataValid(metadata)),
    [block.blockMetadataList],
  );

  const availableConnections = useMemo(
    () => block.connections.filter(connection => isBlockConnectionValid(connection)),
    [block.connections],
  );

  return (
    <BlockTileContainer {...props}>
      <Stack gap={1}>
        <Stack alignItems="center" direction="row" gap={1}>
          <BlocksOutlined sx={{ color: block.ui.color }} />
          <Stack gap={0.5}>
            <BlockTileSubtitle blockType={blockType} standardBlock={standardBlock} />
            <Typography lineHeight={1} variant="h6">
              {block.name}
            </Typography>
          </Stack>
        </Stack>
        <Grid container alignItems="flex-end" columnSpacing={2} rowSpacing={1.5}>
          <Grid item xs={6}>
            <ElementProgress available={availableParameters.length} label={t('label.parameters')} total={totalParameters.length} />
          </Grid>
          <Grid item xs={6}>
            <ElementProgress available={availableMetrics.length} label={t('label.metrics')} total={totalMetrics.length} />
          </Grid>
          <Grid item xs={6}>
            <ElementProgress available={availableMetadataList.length} label={t('label.metadata')} total={block.blockMetadataList.length} />
          </Grid>
          <Grid item xs={6}>
            <ElementProgress available={availableConnections.length} label={t('label.connections')} total={block.connections.length} />
          </Grid>
        </Grid>
      </Stack>
    </BlockTileContainer>
  );
};

export default BlockTile;
