export default {
  text: {
    createdBy: 'Created by {{name}}',
    updatedAt: 'Updated {{date}}',
    me: 'me',
    notFound: 'Element not found',
  },
  tooltip: {
    notFound: 'The element you are looking for does not exist, or you are not allowed to access it',
  },
};
