import i18next from 'i18next';

import { DuplicatePlaygroundBody, PlaygroundInfo, ShortPlaygroundInfo, ShortTagInfo, UUID, UpdatePlaygroundBody } from '@dametis/core';

import { removeLastEntity } from 'components/Layouts/Drawer/hooks';
import { exportPlayground } from 'components/Playground/functions/Playground';
import { setSaving } from 'store/slices/playground';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';
import { LastEntityType } from 'types/drawer';

import { sdk } from '../../sdk';
import { RootState, useSelector } from '../index';

import { api } from './index';

const playgroundsApi = api.injectEndpoints({
  endpoints: build => ({
    readPlaygrounds: build.query<ShortPlaygroundInfo[] | undefined, { groupId?: UUID; siteId?: UUID } | void>({
      providesTags: ['Playgrounds'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const groupId = (arg && arg.groupId) ?? state.auth.selectedGroup!.uuid;
        const site = state.auth.selectedSite;
        const siteId = (arg && arg.siteId) ?? site?.uuid;
        const user = state.auth.user!;
        // TODO Plutôt skip dans les endroits qui n'ont pas la permission
        if (!user.acl.HasPermission('canAccessPlayground', groupId, site)) {
          return { data: undefined };
        }
        try {
          const { data } = await (siteId
            ? sdk.playground.List(siteId)
            : sdk.corporate.ListPlaygrounds(groupId, { params: { isCorporate: 'true' } }));
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    readPlayground: build.query<PlaygroundInfo, UUID>({
      providesTags: ['Playgrounds'],
      queryFn: async uuid => {
        try {
          const { data } = await sdk.playground.Read(uuid);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    readFullPlaygrounds: build.query<PlaygroundInfo[] | undefined, void>({
      providesTags: ['Playgrounds'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const groupId = state.auth.selectedGroup?.uuid;
        const site = state.auth.selectedSite!;
        const siteId = site.uuid;
        const user = state.auth.user!;
        if (!siteId || !user.acl.HasPermission('canAccessPlayground', groupId, site)) {
          return { data: undefined };
        }
        try {
          const { data } = await sdk.playground.ListFull(siteId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),

    createPlayground: build.mutation<PlaygroundInfo, { name: string; tags: ShortTagInfo[] }>({
      invalidatesTags: ['Playgrounds', 'TagsDependencies'],
      queryFn: async ({ name, tags }, { dispatch, getState }) => {
        const state = getState() as RootState;
        const groupId = state.auth.selectedGroup!.uuid;
        const siteId = state.auth.selectedSite?.uuid;
        const playground = state.playground!;
        dispatch(setSaving(true));
        const newPlayground = await exportPlayground({ ...playground, name });
        try {
          const tagIds = tags.map(tag => tag.uuid);
          const { data } = await (siteId
            ? sdk.playground.Create(siteId, { ...newPlayground, tagIds })
            : sdk.corporate.CreatePlayground(groupId, { ...newPlayground, tagIds }));
          dispatch(setSaving(false));
          dispatch(
            addToast({
              severity: ToastSeverity.SUCCESS,
              message: i18next.t('toast:successCreatePlayground'),
            }),
          );
          return { data };
        } catch (error) {
          dispatch(setSaving(false));
          return { error };
        }
      },
    }),
    duplicatePlayground: build.mutation<PlaygroundInfo, { uuid: UUID; body: DuplicatePlaygroundBody }>({
      invalidatesTags: ['Playgrounds', 'TagsDependencies'],
      queryFn: async ({ uuid, body }, { dispatch }) => {
        dispatch(setSaving(true));
        try {
          const { data } = await sdk.playground.Duplicate(uuid, body);
          dispatch(setSaving(false));
          dispatch(
            addToast({
              severity: ToastSeverity.SUCCESS,
              message: i18next.t('toast:successDuplicatePlayground'),
            }),
          );
          return { data };
        } catch (error) {
          dispatch(setSaving(false));
          return { error };
        }
      },
    }),
    updatePlayground: build.mutation<PlaygroundInfo, { uuid: UUID; body: UpdatePlaygroundBody }>({
      invalidatesTags: ['Playgrounds', 'TagsDependencies'],
      queryFn: async ({ uuid, body }, { dispatch }) => {
        dispatch(setSaving(true));
        try {
          const { data } = await sdk.playground.Update(uuid, body);
          dispatch(setSaving(false));
          return { data };
        } catch (error) {
          dispatch(setSaving(false));
          return { error };
        }
      },
    }),
    deletePlayground: build.mutation<void, UUID>({
      invalidatesTags: ['Playgrounds', 'TagsDependencies'],
      queryFn: async (uuid, { dispatch, getState }) => {
        try {
          const {
            playground: { uuid: currentUuid },
          } = getState() as RootState;
          const { data } = await sdk.playground.Delete(uuid ?? currentUuid);
          dispatch(removeLastEntity(LastEntityType.PLAYGROUNDS, uuid ?? currentUuid));
          dispatch(
            addToast({
              severity: ToastSeverity.SUCCESS,
              message: i18next.t('toast:successDeletePlayground'),
            }),
          );
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useReadPlaygroundsQuery,
  useLazyReadPlaygroundsQuery,
  useReadPlaygroundQuery,
  useLazyReadPlaygroundQuery,
  useReadFullPlaygroundsQuery,
  useCreatePlaygroundMutation,
  useDuplicatePlaygroundMutation,
  useUpdatePlaygroundMutation,
  useDeletePlaygroundMutation,
} = playgroundsApi;

export const usePlaygrounds: typeof useReadPlaygroundsQuery = (arg, options) => {
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);
  return useReadPlaygroundsQuery(arg ?? { groupId, siteId }, { skip: !siteId && !groupId, ...options });
};
