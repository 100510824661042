import { Grid } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { FindFolder, FindFolderPath, ShortcutCategory, UUID } from '@dametis/core';

import CenteredPage from 'components/UI/CenteredPage/CenteredPage';
import RightPanelDrawerContainer from 'components/UI/RightPanel/RightPanelDrawerContainer';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';

import CatalogContent from './CatalogContent/CatalogContent';
import CatalogHeader from './CatalogHeader/CatalogHeader';
import CatalogMenu from './CatalogMenu/CatalogMenu';
import CatalogRightPanel from './CatalogRightPanel/CatalogRightPanel';

export const ARROW_CLASSNAME = 'arrow';

export enum MenuTab {
  FOLDER_CONTENT = 'folderContent',
  NO_FOLDER_CONTENT = 'noFolderContent',
}

const LegoCatalog: FC = () => {
  const navigate = useNavigate();

  const { folderId, entityId } = useParams<{ folderId?: UUID; entityId?: UUID }>();

  const isNoFolderTypesRoute = Boolean(useMatch(`/blockTypes/noFolder/*`));

  const {
    data: globalRootFolder = null,
    isLoading: isGlobalRootFolderLoading,
    isUninitialized: isGlobalRootFolderUninitialized,
  } = useReadGlobalRootFolderQuery();

  const [selectedMenuTab, setSelectedMenuTab] = useState<MenuTab>(MenuTab.FOLDER_CONTENT);

  const isAllTypesRoute = useMemo(() => !isNoFolderTypesRoute, [isNoFolderTypesRoute]);

  const isGlobalRootFolderFetched = useMemo(
    () => !isGlobalRootFolderLoading && !isGlobalRootFolderUninitialized,
    [isGlobalRootFolderLoading, isGlobalRootFolderUninitialized],
  );

  const isRightPanelOpen = useMemo(
    () => Boolean(isGlobalRootFolderFetched && globalRootFolder && !!entityId),
    [isGlobalRootFolderFetched, globalRootFolder, entityId],
  );

  const currentFolder = useMemo(
    () => (globalRootFolder ? (FindFolder(globalRootFolder, folder => folder.uuid === folderId) ?? globalRootFolder) : undefined),
    [globalRootFolder, folderId],
  );

  const currentPath = useMemo(() => {
    if (!globalRootFolder || !currentFolder) {
      return [];
    }
    try {
      return FindFolderPath(globalRootFolder, currentFolder);
    } catch (err) {
      return [];
    }
  }, [currentFolder, globalRootFolder]);

  useEffect(() => {
    /**
     * Launch de la route All Types
     */
    if (isAllTypesRoute) {
      setSelectedMenuTab(MenuTab.FOLDER_CONTENT);
      /**
       * Si les dossiers ont chargé et que nous avons bien un dossier root, on lance le check
       */
      if (isGlobalRootFolderFetched && !!globalRootFolder) {
        /**
         * Si l'utilisateur avait un URLParam folderId on vérifie si il existe
         */
        if (folderId) {
          const folder = FindFolder(globalRootFolder, folder => folder.uuid === folderId);
          /**
           * Si il n'existe pas ou qu'il ne correspond pas a la route des noFolderBlockTypes, on redirige vers le dossier root
           */
          if (!folder) {
            navigate(`/blockTypes/${globalRootFolder.uuid}`, { replace: true });
            /**
             * Si il existe, on verifie si l'utilisateur avait un URLParam typeId et si il existe
             */
          } else if (entityId) {
            const entity = folder.shortcuts.find(
              shortcut =>
                (shortcut.category === ShortcutCategory.BLOCK_TYPE || shortcut.category === ShortcutCategory.STANDARD_BLOCK) &&
                shortcut.uuid === entityId,
            );
            /**
             * Si il n'existe pas, on retire le parametre de l'URL
             */
            if (!entity) {
              navigate(`/blockTypes/${folder.uuid}`, { replace: true });
            }
          }
        } else {
          /**
           * Si il n'existe pas, on redirige vers le dossier root
           */
          navigate(`/blockTypes/${globalRootFolder.uuid}`, { replace: true });
        }
      }
    } else if (isNoFolderTypesRoute) {
      /**
       * Launch de la route No Folder Types
       */
      setSelectedMenuTab(MenuTab.NO_FOLDER_CONTENT);
    }
  }, [isGlobalRootFolderFetched, folderId, globalRootFolder, entityId, navigate, isAllTypesRoute, isNoFolderTypesRoute]);

  return (
    <>
      <RightPanelDrawerContainer
        resizable
        open={isRightPanelOpen}
        sx={{ minHeight: theme => `calc(100vh - ${theme.others.headerHeight})` }}
      >
        <CenteredPage size="large">
          <CatalogHeader currentPath={currentPath} selectedMenuTab={selectedMenuTab} />
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <CatalogMenu currentPath={currentPath} selectedMenuTab={selectedMenuTab} />
            </Grid>
            <Grid item xs={9}>
              <CatalogContent currentFolder={currentFolder} selectedMenuTab={selectedMenuTab} />
            </Grid>
          </Grid>
        </CenteredPage>
        <CatalogRightPanel />
      </RightPanelDrawerContainer>
    </>
  );
};

export default LegoCatalog;
