import { DataOperationContextName, DataOperationStatus, EditDataAction, ModifierType } from '@dametis/core';

export default {
  title: {
    dataHistory: 'Operations history',
    history: 'History',
    details: 'Details',
  },
  column: {
    owner: 'Author',
    action: 'Action',
    context: 'Context',
    createdAt: 'Initiated on',
    updatedAt: 'Last updated on',
    operationId: 'Operation ID',
    variableUuids: 'Affected variables',
    conditions: 'Conditions',
    modifiers: 'Modifiers',
    details: '',
    revert: '',
    retry: '',
    status: 'Status',
  },
  input: {
    label: {
      message: 'Payload',
      [ModifierType.LINEAR_INTERPOLATION]: 'Linear interpolation',
      [ModifierType.MULTIPLIER]: 'Multiplier',
      [ModifierType.OFFSET]: 'Offset',
      [ModifierType.SET_VALUE]: 'Fixed value',
    },
  },
  button: {
    close: 'Close',
    copy: 'Copy',
    details: 'Details',
    revert: 'Revert',
    retry: 'Retry',
    refresh: 'Refresh',
  },
  text: {
    [EditDataAction.IMPORT]: 'Import',
    [EditDataAction.EDIT]: 'Edit',
    [EditDataAction.DELETE]: 'Delete',
    [DataOperationContextName.DEFAULT]: 'Default',
    [DataOperationContextName.MANUAL_ENTRY]: 'Manual entry',
    [DataOperationContextName.CONSTANT]: 'Constant',
    [DataOperationContextName.ADVANCED_ENTRY]: 'Data correction',
    [DataOperationContextName.ADMIN]: 'Admin',
    [DataOperationContextName.RESTORE]: 'Restore',
    [DataOperationContextName.FILE_IMPORT]: 'Data import',
    [DataOperationContextName.TOOLS]: 'Tools',
    noOperation: 'No operation',
    patch: {
      init: 'all data',
      edit: 'Edition of ',
      delete: 'Deletion of ',
    },
  },
  toast: {
    revertOperationSuccess: 'Operation reverted successfully',
    revertOperationError: 'Error reverting operation',
    retryOperationSuccess: 'Operation retried successfully',
    retryOperationError: 'Error retrying operation',
    copySuccess: 'Copied to clipboard',
  },
  tooltip: {
    variableConflict: 'An operation is already underway on this variable. ',
    viewHistory: 'View history',
    [`${DataOperationStatus.DONE}`]: 'Success',
    [`${DataOperationStatus.FAILED}`]: 'Failed',
    [`${DataOperationStatus.PENDING}`]: 'Pending',
  },
};
