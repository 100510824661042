import { FC, useCallback, useEffect, useMemo } from 'react';
import { Descendant, Editor, Transforms, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, withReact } from 'slate-react';

import { openReplayEvent } from 'functions/openReplay';
import { getDefaultGroupByForOperator } from 'functions/tada/getGroupBy';
import { useDispatch } from 'store';
import { VncStoreContext, createVncStore, useVncStore } from 'zustand/stores/vnc';

import Actions from './components/Actions/Actions';
import Content from './components/Content';
import Title from './components/Title/Title';
import { StyledDialog } from './components/styled';
import { IPropsContext, PropsContext } from './context';
import { vncDefaultProps } from './props';
import { resetNodes, withCustomElements } from './slate';
import { createParagraph } from './slate/paragraph';
import { keepProps, tadaToSlate } from './slate/tada';
import { OpenReplayEvent, VncProps } from './types';

const initialValue: Descendant[] = [createParagraph()];

interface Props extends VncProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const VncDialogWithContext: FC<Props> = ({
  open,
  setOpen,
  value,
  onChange,
  sourceCategory,
  sourceUuid = vncDefaultProps.sourceUuid,
  multiple = vncDefaultProps.multiple,
  selection = vncDefaultProps.selection,
  onSelectionChange = vncDefaultProps.onSelectionChange,
  listTab = vncDefaultProps.listTab,
  defaultVariableOperator = vncDefaultProps.defaultVariableOperator,
  variableOperatorOptions = vncDefaultProps.variableOperatorOptions,
  excludeVariableOperatorOptions = vncDefaultProps.excludeVariableOperatorOptions,
  defaultGlobalOperator = vncDefaultProps.defaultGlobalOperator,
  globalOperatorOptions = vncDefaultProps.globalOperatorOptions,
  excludeGlobalOperatorOptions = vncDefaultProps.excludeGlobalOperatorOptions,
  disableMaths = vncDefaultProps.disableMaths,
  butKeepVariableMenu = vncDefaultProps.butKeepVariableMenu,
  availableFilters = vncDefaultProps.availableFilters,
  defaultFilters = vncDefaultProps.defaultFilters,
  disableFilters = vncDefaultProps.disableFilters,
  calculatedVariableMode = vncDefaultProps.calculatedVariableMode,
  standardBlockMetricMode = vncDefaultProps.standardBlockMetricMode,
  unPostedBlockType = vncDefaultProps.unPostedBlockType,
  editingBlockTypeMetricUuid = vncDefaultProps.editingBlockTypeMetricUuid,
  disableLego = vncDefaultProps.disableLego,
  disableModels = vncDefaultProps.disableModels,
  covarianceVariable = vncDefaultProps.covarianceVariable,
  unitPicker = vncDefaultProps.unitPicker,
  output = vncDefaultProps.output,
  GroupByInputProps = vncDefaultProps.GroupByInputProps,
  disableCreateAlias = vncDefaultProps.disableCreateAlias,
}) => {
  const dispatch = useDispatch();

  const setCovarianceVariable = useVncStore(state => state.setCovarianceVariable);
  const setCalcVarProps = useVncStore(state => state.setCalcVarProps);
  const setSelection = useVncStore(state => state.setSelection);
  const clearStore = useVncStore(state => state.clearStore);
  const setUnPostedBlockType = useVncStore(state => state.setUnPostedBlockType);

  const editor = useMemo(() => withCustomElements(withHistory(withReact(createEditor())), disableMaths), [disableMaths]);
  const propsContext = useMemo<IPropsContext>(
    () => ({
      onChange,
      multiple,
      selection,
      onSelectionChange,
      sourceCategory,
      sourceUuid,
      listTab,
      defaultVariableOperator,
      variableOperatorOptions,
      excludeVariableOperatorOptions,
      defaultGlobalOperator,
      globalOperatorOptions,
      excludeGlobalOperatorOptions,
      disableMaths,
      butKeepVariableMenu,
      availableFilters,
      defaultFilters,
      disableFilters,
      calculatedVariableMode,
      standardBlockMetricMode,
      editingBlockTypeMetricUuid,
      disableLego,
      disableModels,
      covarianceVariable,
      unitPicker,
      output,
      GroupByInputProps,
      disableCreateAlias,
    }),
    [
      onChange,
      multiple,
      selection,
      onSelectionChange,
      sourceCategory,
      sourceUuid,
      listTab,
      defaultVariableOperator,
      variableOperatorOptions,
      excludeVariableOperatorOptions,
      defaultGlobalOperator,
      globalOperatorOptions,
      excludeGlobalOperatorOptions,
      disableMaths,
      butKeepVariableMenu,
      availableFilters,
      defaultFilters,
      disableFilters,
      calculatedVariableMode,
      standardBlockMetricMode,
      editingBlockTypeMetricUuid,
      disableLego,
      disableModels,
      covarianceVariable,
      unitPicker,
      output,
      GroupByInputProps,
      disableCreateAlias,
    ],
  );

  useEffect(() => {
    if (!open || !editor) return undefined;
    setCovarianceVariable(covarianceVariable);
    setCalcVarProps({
      ...(!value.operator && {
        operator: defaultGlobalOperator ?? undefined,
        groupBy: getDefaultGroupByForOperator(defaultGlobalOperator ?? undefined, { isCalculatedVariable: calculatedVariableMode }),
      }),
      ...keepProps(value),
    });
    setUnPostedBlockType(unPostedBlockType);
    setSelection(selection);
    editor.children = tadaToSlate(value);
    editor.onChange();
    Transforms.select(editor, Editor.end(editor, []));
    return () => {
      resetNodes(editor);
      clearStore();
    };
  }, [
    open,
    editor,
    value,
    dispatch,
    covarianceVariable,
    selection,
    defaultGlobalOperator,
    defaultVariableOperator,
    calculatedVariableMode,
    unPostedBlockType,
    setUnPostedBlockType,
    setCovarianceVariable,
    setCalcVarProps,
    setSelection,
    clearStore,
  ]);

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (open) {
      openReplayEvent(OpenReplayEvent.VNC_OPEN);
    }
  }, [open]);

  return (
    <Slate editor={editor} initialValue={initialValue}>
      <PropsContext.Provider value={propsContext}>
        <StyledDialog fullWidth maxWidth={false} open={open} onClose={close}>
          <Title />
          <Content />
          <Actions setOpen={setOpen} />
        </StyledDialog>
      </PropsContext.Provider>
    </Slate>
  );
};

const VncDialog: FC<Props> = ({
  open,
  setOpen,
  value,
  onChange,
  sourceCategory,
  sourceUuid = vncDefaultProps.sourceUuid,
  multiple = vncDefaultProps.multiple,
  selection = vncDefaultProps.selection,
  onSelectionChange = vncDefaultProps.onSelectionChange,
  listTab = vncDefaultProps.listTab,
  defaultVariableOperator = vncDefaultProps.defaultVariableOperator,
  variableOperatorOptions = vncDefaultProps.variableOperatorOptions,
  excludeVariableOperatorOptions = vncDefaultProps.excludeVariableOperatorOptions,
  defaultGlobalOperator = vncDefaultProps.defaultGlobalOperator,
  globalOperatorOptions = vncDefaultProps.globalOperatorOptions,
  excludeGlobalOperatorOptions = vncDefaultProps.excludeGlobalOperatorOptions,
  disableMaths = vncDefaultProps.disableMaths,
  butKeepVariableMenu = vncDefaultProps.butKeepVariableMenu,
  availableFilters = vncDefaultProps.availableFilters,
  defaultFilters = vncDefaultProps.defaultFilters,
  disableFilters = vncDefaultProps.disableFilters,
  calculatedVariableMode = vncDefaultProps.calculatedVariableMode,
  standardBlockMetricMode = vncDefaultProps.standardBlockMetricMode,
  unPostedBlockType = vncDefaultProps.unPostedBlockType,
  editingBlockTypeMetricUuid = vncDefaultProps.editingBlockTypeMetricUuid,
  disableLego = vncDefaultProps.disableLego,
  disableModels = vncDefaultProps.disableModels,
  covarianceVariable = vncDefaultProps.covarianceVariable,
  unitPicker = vncDefaultProps.unitPicker,
  output = vncDefaultProps.output,
  GroupByInputProps = vncDefaultProps.GroupByInputProps,
  disableCreateAlias = vncDefaultProps.disableCreateAlias,
}) => {
  const store = useMemo(createVncStore, []);

  return (
    <VncStoreContext.Provider value={store}>
      <VncDialogWithContext
        availableFilters={availableFilters}
        butKeepVariableMenu={butKeepVariableMenu}
        calculatedVariableMode={calculatedVariableMode}
        covarianceVariable={covarianceVariable}
        defaultFilters={defaultFilters}
        defaultGlobalOperator={defaultGlobalOperator}
        defaultVariableOperator={defaultVariableOperator}
        disableCreateAlias={disableCreateAlias}
        disableFilters={disableFilters}
        disableLego={disableLego}
        disableMaths={disableMaths}
        disableModels={disableModels}
        editingBlockTypeMetricUuid={editingBlockTypeMetricUuid}
        excludeGlobalOperatorOptions={excludeGlobalOperatorOptions}
        excludeVariableOperatorOptions={excludeVariableOperatorOptions}
        globalOperatorOptions={globalOperatorOptions}
        GroupByInputProps={GroupByInputProps}
        listTab={listTab}
        multiple={multiple}
        open={open}
        output={output}
        selection={selection}
        setOpen={setOpen}
        sourceCategory={sourceCategory}
        sourceUuid={sourceUuid}
        standardBlockMetricMode={standardBlockMetricMode}
        unitPicker={unitPicker}
        unPostedBlockType={unPostedBlockType}
        value={value}
        variableOperatorOptions={variableOperatorOptions}
        onChange={onChange}
        onSelectionChange={onSelectionChange}
      />
    </VncStoreContext.Provider>
  );
};

export default VncDialog;
