import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material';
import { Formik, FormikProps } from 'formik';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CreateBoxBody, ShortSiteInfo, UUID } from '@dametis/core';

import CircularLoader from 'components/UI/CircularLoader/CircularLoader';
import { sdk } from 'sdk';
import { useDispatch } from 'store';
import { syncBox } from 'store/actions/currentBox';
import { displaySdkErrorToast } from 'store/actions/toasts';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';
import { createBoxSchema } from 'validations/Admin/box';

interface CreateBoxForm extends CreateBoxBody {
  siteId: UUID;
}

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const NewBox: FC<Props> = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('admin');
  const history = useNavigate();

  const formikRef = useRef<FormikProps<CreateBoxForm>>(null);

  const [allSites, setAllSites] = useState<ShortSiteInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const initialValues = useMemo(
    (): CreateBoxForm => ({
      name: '',
      reference: '',
      machineId: '',
      siteId: '',
    }),
    [],
  );

  const handleClose = useCallback(() => {
    if (formikRef.current) {
      formikRef.current.handleReset();
    }
    setOpen(false);
  }, [setOpen]);

  const handleSaveButtonClicked = useCallback(() => {
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
  }, []);

  const handleSave = useCallback(
    async (values: CreateBoxForm) => {
      setSaving(true);
      const { siteId, ...boxBody } = values;
      try {
        const { data } = await sdk.box.Create(siteId, boxBody);
        dispatch(addToast({ message: t('toast:successBoxCreate'), severity: ToastSeverity.SUCCESS }));
        if (data?.uuid) {
          history(`/admin/boxes/${data.uuid}`);
          void dispatch(syncBox(data.uuid));
        }
      } catch (err) {
        console.error(err);
        dispatch(displaySdkErrorToast(err));
      }
      setSaving(false);
    },
    [dispatch, history, t],
  );

  const fetchSites = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await sdk.site.List();
      setAllSites(data);
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }

    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    void fetchSites();
  }, [fetchSites]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('boxes.newBox')}</DialogTitle>

      <CircularLoader loading={loading}>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            innerRef={formikRef}
            validationSchema={createBoxSchema}
            onSubmit={(values: CreateBoxForm) => handleSave(values)}
          >
            {formik => (
              <Stack spacing={3}>
                <TextField
                  select
                  error={formik.touched.siteId && Boolean(formik.errors.siteId)}
                  helperText={formik.touched.siteId && formik.errors.siteId}
                  label={t('boxes.columns.site')}
                  name="siteId"
                  sx={{ width: 300 }}
                  value={formik.values.siteId}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                >
                  {allSites.map(site => (
                    <MenuItem key={site.uuid} value={site.uuid}>
                      {site.name}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  label={t('boxes.columns.name')}
                  name="name"
                  sx={{ width: 300 }}
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />

                <TextField
                  error={formik.touched.reference && Boolean(formik.errors.reference)}
                  helperText={formik.touched.reference && formik.errors.reference}
                  label={t('boxes.columns.reference')}
                  name="reference"
                  sx={{ width: 300 }}
                  value={formik.values.reference}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />

                <TextField
                  error={formik.touched.machineId && Boolean(formik.errors.machineId)}
                  helperText={formik.touched.machineId && formik.errors.machineId}
                  label={t('boxes.columns.machineId')}
                  name="machineId"
                  sx={{ width: 300 }}
                  value={formik.values.machineId}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Stack>
            )}
          </Formik>
        </DialogContent>
      </CircularLoader>
      <DialogActions>
        <Button color="primary" variant="text" onClick={handleClose}>
          {t('boxes.cancel')}
        </Button>
        <LoadingButton color="secondary" loading={saving} variant="contained" onClick={handleSaveButtonClicked}>
          {t('boxes.create')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewBox;
