import { useTheme } from '@mui/material';
import type { Series, SeriesLineOptions, TooltipFormatterContextObject } from 'highcharts';
import { FC, useCallback, useMemo } from 'react';

import { localizedFormat } from 'localization/localizedDateFns';

import BarSeriesTooltip from './TooltipSerieItems/BarSeriesTooltip';
import LineSeriesTooltip from './TooltipSerieItems/LineSeriesTooltip';
import NetworkgraphSeriesTooltip from './TooltipSerieItems/NetworkgraphSeriesTooltip';
import PieSeriesTooltip from './TooltipSerieItems/PieSeriesTooltip';
import RangeSeriesTooltip from './TooltipSerieItems/RangeSeriesTooltip';
import SankeySeriesTooltip from './TooltipSerieItems/SankeySeriesTooltip';
import ScatterSeriesTooltip from './TooltipSerieItems/ScatterSeriesTooltip';

export type TooltipComponentProps = TooltipFormatterContextObject;

// other props can be used here, like y or total, just spread ...props to see them
const TooltipComponent: FC<TooltipComponentProps> = ({ series: currentSeries, x, ...props }) => {
  const theme = useTheme();

  const date = useMemo(() => (x ? new Date(x) : null), [x]);
  const displayedSeries = useMemo(() => currentSeries.chart.series.filter(element => element.visible), [currentSeries.chart.series]);

  const getSeriesTooltip = useCallback(
    (seriesItem: Series) => {
      if ((seriesItem.userOptions as SeriesLineOptions).enableMouseTracking === false) return null;
      switch (seriesItem.userOptions.type) {
        case 'line':
          return <LineSeriesTooltip key={seriesItem.userOptions.id} series={currentSeries} seriesItem={seriesItem} {...props} />;
        case 'arearange':
          return <RangeSeriesTooltip key={seriesItem.userOptions.id} series={currentSeries} seriesItem={seriesItem} {...props} />;
        case 'bar':
          return <BarSeriesTooltip key={seriesItem.userOptions.id} series={currentSeries} seriesItem={seriesItem} {...props} />;
        case 'scatter':
          return <ScatterSeriesTooltip key={seriesItem.userOptions.id} series={currentSeries} seriesItem={seriesItem} {...props} />;
        case 'pie':
          return <PieSeriesTooltip key={seriesItem.userOptions.id} series={currentSeries} seriesItem={seriesItem} {...props} />;
        case 'sankey':
          return <SankeySeriesTooltip key={seriesItem.userOptions.id} series={currentSeries} seriesItem={seriesItem} {...props} />;
        case 'networkgraph':
          return <NetworkgraphSeriesTooltip key={seriesItem.userOptions.id} series={currentSeries} seriesItem={seriesItem} {...props} />;
        default:
          return null;
      }
    },
    [props, currentSeries],
  );

  return (
    <div
      style={{
        fontFamily: 'Work Sans, sans-serif',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(0.75),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[8],
        maxWidth: 400,
        overflow: 'hidden',
      }}
    >
      {date && (
        <div style={{ color: theme.palette.text.secondary, fontSize: 12, paddingLeft: theme.spacing(2) }}>
          {localizedFormat(date, 'eee PPpp')}
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(0.25), marginTop: theme.spacing(0.5) }}>
        {displayedSeries.map(seriesItem => getSeriesTooltip(seriesItem))}
      </div>
    </div>
  );
};

export default TooltipComponent;
