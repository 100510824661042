export default {
  strength: {
    name: 'Complexité du mot de passe',
    empty: '',
    weak: 'Faible',
    medium: 'Moyenne',
    strong: 'Forte',
  },
  strengthReason: {
    userInputs: 'Le mot de passe semble comporter des informations sur votre identité',
    lowComplexity: "Le mot de passe n'est pas assez complexe, essayez un mélange de lettres, chiffres et symboles",
    empty: 'Le mot de passe est vide',
  },
};
