import { StoreApi } from 'zustand';

import { RouteWithIndex } from 'types/drawer';
import { LayoutStore } from 'zustand/stores/layout';

export interface NavbarActions {
  setExtendedDrawer: (bool: boolean) => void;
  setHoveredRoute: (route: RouteWithIndex | null) => void;
  setIsTouch: (bool: boolean) => void;
}

export const createActions = (set: StoreApi<LayoutStore>['setState']): NavbarActions => ({
  setExtendedDrawer: bool => set(() => ({ isExtendedDrawer: bool })),
  setHoveredRoute: route => set(() => ({ hoveredRoute: route })),
  setIsTouch: bool => set(() => ({ isTouch: bool })),
});
