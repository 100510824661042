import { GridLocaleText } from '@mui/x-data-grid-premium';

export default {
  title: {
    macros: 'Macros',
  },
  text: {
    noMacros: 'Aucune macro',
    noMacroLibs: 'Aucune bibliothèque',
  },
  button: {
    addMacro: 'Macro',
    addMacroLib: 'Bibliothèque',
    resetCaches: 'Vider caches',
  },
  table: {
    cells: {
      name: 'Nom',
      description: 'Description',
      status: 'État',
    },
  },
  tabs: {
    tableMacroList: 'Macros',
    tableMacroLibList: 'Bibliothèques',
  },
  xgrid: {
    noMacrosLabel: 'Aucune macro',
    footerTotalMacros: 'Total macros :',
    noMacroLibsLabel: 'Aucune bibliothèque',
    footerTotalMacroLibs: 'Total bibliothèques :',
  } as Partial<GridLocaleText>,
};
