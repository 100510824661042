import { AlarmInfo, AlarmKind, CreateAlarmBody, CreateEventBody, IncidentInfo, ShortAlarmInfo, UUID, UpdateAlarmBody } from '@dametis/core';

import { sdk } from 'sdk';
import store, { RootState, useSelector } from 'store';

import { api } from './index';

const alarmsApi = api.injectEndpoints({
  endpoints: build => ({
    readAlarms: build.query<ShortAlarmInfo[], { siteId?: UUID } | void>({
      providesTags: ['Alarms'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const { siteId } = arg || { siteId: state.auth.selectedSite?.uuid };
        try {
          if (!siteId) {
            throw new Error('No site ID');
          }
          const { data } = await sdk.alarm.List(siteId, { params: { kind: AlarmKind.DEFAULT } });
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
    }),
    readAlarm: build.query<AlarmInfo, UUID>({
      providesTags: ['Alarms'],
      queryFn: async uuid => {
        try {
          const { data } = await sdk.alarm.Read(uuid);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    createAlarm: build.mutation<AlarmInfo, CreateAlarmBody>({
      invalidatesTags: ['Alarms'],
      queryFn: async (body, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite?.uuid;
        try {
          if (!siteId) {
            throw new Error('No site ID');
          }
          const { data } = await sdk.alarm.Create(siteId, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateAlarm: build.mutation<AlarmInfo, { updates: UpdateAlarmBody; uuid: UUID }>({
      invalidatesTags: ['Alarms'],
      queryFn: async ({ updates: body, uuid }) => {
        try {
          const { data } = await sdk.alarm.Update(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    acknowledgeAlarm: build.mutation<IncidentInfo, { updates: CreateEventBody; uuid: UUID }>({
      invalidatesTags: ['Alarms'],
      queryFn: async ({ updates: body, uuid }) => {
        try {
          const { data } = await sdk.incident.CreateEvent(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    duplicateAlarm: build.mutation<AlarmInfo | null, { uuid: UUID; name: string }>({
      invalidatesTags: ['Alarms'],
      queryFn: async ({ uuid, name }) => {
        try {
          const { data } = await sdk.alarm.Duplicate(uuid, { name });
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteAlarm: build.mutation<UUID, UUID>({
      invalidatesTags: ['Alarms'],
      queryFn: async alarmId => {
        try {
          await sdk.alarm.Delete(alarmId);
          return { data: alarmId };
        } catch (error) {
          return { error };
        }
      },
    }),
    restoreAlarm: build.mutation<UUID, { activityId: UUID; alarmId: UUID }>({
      invalidatesTags: ['Alarms'],
      queryFn: async ({ activityId, alarmId }) => {
        try {
          await sdk.alarm.Restore(alarmId, activityId);
          return { data: alarmId };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useReadAlarmQuery,
  useLazyReadAlarmsQuery,
  useAcknowledgeAlarmMutation,
  useCreateAlarmMutation,
  useUpdateAlarmMutation,
  useDuplicateAlarmMutation,
  useDeleteAlarmMutation,
  useRestoreAlarmMutation,
} = alarmsApi;

export const selectAlarms: typeof alarmsApi.endpoints.readAlarms.select = arg =>
  alarmsApi.endpoints.readAlarms.select(arg ?? { siteId: store.getState().auth.selectedSite?.uuid });

export const useAlarms: typeof alarmsApi.useReadAlarmsQuery = (arg, options) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return alarmsApi.useReadAlarmsQuery(arg ?? { siteId }, { skip: !siteId, ...options });
};
