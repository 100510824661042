import { Box, Stack, TextField } from '@mui/material';
import { ChangeEventHandler, Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FindFolder, FolderInfo, ShortcutCategory } from '@dametis/core';

import { StandardBlockBody } from 'components/Lego/types';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';

import FolderExplorerPicker from '../../FolderExplorer/FolderExplorerPicker';
import { ExplorerItem, ExplorerItemType, IsExplorerFolder, IsExplorerShortcut } from '../../FolderExplorer/types';

export interface GeneralStepProps<T extends StandardBlockBody> {
  standardBlockBody: T;
  setStandardBlockBody: Dispatch<SetStateAction<T>>;
  isCreating?: boolean;
}

const GeneralStep = <T extends StandardBlockBody = StandardBlockBody>({
  standardBlockBody,
  setStandardBlockBody,
  isCreating = true,
}: GeneralStepProps<T>) => {
  const { t } = useTranslation('lego');

  const { data: globalRootFolder = null } = useReadGlobalRootFolderQuery();

  const parsedGlobalRootFolder: FolderInfo | null = useMemo(
    () => (globalRootFolder ? { ...globalRootFolder, name: t('lego:text.factory') } : null),
    [globalRootFolder, t],
  );

  const selectedExplorerFolder: ExplorerItem<ExplorerItemType.FOLDER> | null = useMemo(() => {
    if (!parsedGlobalRootFolder) {
      return null;
    }
    const selectedFolder = FindFolder(parsedGlobalRootFolder, folder => folder.uuid === standardBlockBody.folderId);
    if (!selectedFolder) {
      return null;
    }
    return { type: ExplorerItemType.FOLDER, content: selectedFolder };
  }, [parsedGlobalRootFolder, standardBlockBody.folderId]);

  const selectedExplorerBlockType: ExplorerItem<ExplorerItemType.SHORTCUT> | null = useMemo(
    () =>
      standardBlockBody.blockTypeId
        ? { type: ExplorerItemType.SHORTCUT, content: { uuid: standardBlockBody.blockTypeId, category: ShortcutCategory.BLOCK_TYPE } }
        : null,
    [standardBlockBody.blockTypeId],
  );

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setStandardBlockBody(state => ({ ...state, name: event.target.value }));
    },
    [setStandardBlockBody],
  );

  const isSelectedExplorerFolderItemDisabled = useCallback((item: ExplorerItem) => !IsExplorerFolder(item), []);

  const isSelectedExplorerBlockTypeValid = useCallback(
    (selectedItem: ExplorerItem | null) => selectedItem !== null && !IsExplorerFolder(selectedItem),
    [],
  );

  const handleChangeDescription: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    event => {
      setStandardBlockBody(state => ({ ...state, description: event.target.value }));
    },
    [setStandardBlockBody],
  );

  const handleChangeSelectedExplorerFolder = useCallback(
    (newSelectedFolder: ExplorerItem | null) => {
      if (!newSelectedFolder) {
        return;
      }
      setStandardBlockBody(state => ({ ...state, folderId: IsExplorerFolder(newSelectedFolder) ? newSelectedFolder.content.uuid : null }));
    },
    [setStandardBlockBody],
  );

  const handleChangeSelectedExplorerBlockType = useCallback(
    (newSelectedBlockType: ExplorerItem | null) => {
      if (!newSelectedBlockType) {
        return;
      }
      setStandardBlockBody(state => ({
        ...state,
        blockTypeId: IsExplorerShortcut(newSelectedBlockType) ? newSelectedBlockType.content.uuid : null,
      }));
    },
    [setStandardBlockBody],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Stack gap={1.5}>
        <TextField autoFocus fullWidth label={t('label.name')} value={standardBlockBody.name} onChange={handleChangeName} />
        {parsedGlobalRootFolder && (
          <FolderExplorerPicker
            displayedShortcut={[ShortcutCategory.BLOCK_TYPE]}
            isItemDisabled={isSelectedExplorerFolderItemDisabled}
            label={t('label.folder')}
            rootFolder={parsedGlobalRootFolder}
            value={selectedExplorerFolder}
            onChange={handleChangeSelectedExplorerFolder}
          />
        )}
        {parsedGlobalRootFolder && (
          <FolderExplorerPicker
            displayedShortcut={[ShortcutCategory.BLOCK_TYPE]}
            isEditing={isCreating}
            isValid={isSelectedExplorerBlockTypeValid}
            label={t('label.blockType')}
            rootFolder={parsedGlobalRootFolder}
            value={selectedExplorerBlockType}
            onChange={handleChangeSelectedExplorerBlockType}
          />
        )}
        <TextField
          fullWidth
          multiline
          label={t('label.description')}
          minRows={3}
          size="small"
          sx={{ mb: 2 }}
          value={standardBlockBody.description}
          variant="outlined"
          onChange={handleChangeDescription}
        />
      </Stack>
    </Box>
  );
};

export default GeneralStep;
