import { Box, Stack, TextField } from '@mui/material';
import { ChangeEventHandler, Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FindFolder, FolderInfo, ShortcutCategory } from '@dametis/core';

import { BlockTypeBody } from 'components/Lego/types';
import ColorPicker, { ColorPickerProps } from 'components/UI/ColorPicker/ColorPicker';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';

import FolderExplorerPicker from '../../FolderExplorer/FolderExplorerPicker';
import { ExplorerItem, ExplorerItemType, IsExplorerFolder } from '../../FolderExplorer/types';

export interface GeneralStepProps<T extends BlockTypeBody> {
  blockTypeBody: T;
  setBlockTypeBody: Dispatch<SetStateAction<T>>;
}

const GeneralStep = <T extends BlockTypeBody = BlockTypeBody>({ blockTypeBody, setBlockTypeBody }: GeneralStepProps<T>) => {
  const { t } = useTranslation('lego');

  const { data: globalRootFolder = null } = useReadGlobalRootFolderQuery();

  const parsedGlobalRootFolder: FolderInfo | null = useMemo(
    () => (globalRootFolder ? { ...globalRootFolder, name: t('lego:text.factory') } : null),
    [globalRootFolder, t],
  );

  const selectedExplorerFolder: ExplorerItem<ExplorerItemType.FOLDER> | null = useMemo(() => {
    if (!parsedGlobalRootFolder) {
      return null;
    }
    const selectedFolder = FindFolder(parsedGlobalRootFolder, folder => folder.uuid === blockTypeBody.folderId);
    if (!selectedFolder) {
      return null;
    }
    return { type: ExplorerItemType.FOLDER, content: selectedFolder };
  }, [parsedGlobalRootFolder, blockTypeBody.folderId]);

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setBlockTypeBody(state => ({ ...state, name: event.target.value }));
    },
    [setBlockTypeBody],
  );

  const handleChangeColor = useCallback<NonNullable<ColorPickerProps['onColorChange']>>(
    newColor => {
      setBlockTypeBody(state => ({ ...state, content: { ...state.content, color: newColor.hex } }));
    },
    [setBlockTypeBody],
  );

  const handleChangeDescription: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    event => {
      setBlockTypeBody(state => ({ ...state, description: event.target.value }));
    },
    [setBlockTypeBody],
  );

  const isItemDisabled = useCallback((item: ExplorerItem) => !IsExplorerFolder(item), []);

  const handleChangeSelectedExplorerFolder = useCallback(
    (newSelectedFolder: ExplorerItem | null) => {
      if (!newSelectedFolder) {
        return;
      }
      setBlockTypeBody(state => ({ ...state, folderId: IsExplorerFolder(newSelectedFolder) ? newSelectedFolder.content.uuid : null }));
    },
    [setBlockTypeBody],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Stack gap={1.5}>
        <Stack alignItems="flex-end" direction="row" spacing={1}>
          <TextField autoFocus fullWidth label={t('label.name')} value={blockTypeBody.name} onChange={handleChangeName} />
          <ColorPicker size="small" value={blockTypeBody.content?.color ?? ''} variant="toggle" onColorChange={handleChangeColor} />
        </Stack>
        {parsedGlobalRootFolder && (
          <FolderExplorerPicker
            displayedShortcut={[ShortcutCategory.BLOCK_TYPE]}
            isItemDisabled={isItemDisabled}
            rootFolder={parsedGlobalRootFolder}
            value={selectedExplorerFolder}
            onChange={handleChangeSelectedExplorerFolder}
          />
        )}
        <TextField
          fullWidth
          multiline
          label={t('label.description')}
          minRows={3}
          size="small"
          sx={{ mb: 2 }}
          value={blockTypeBody.description}
          variant="outlined"
          onChange={handleChangeDescription}
        />
      </Stack>
    </Box>
  );
};

export default GeneralStep;
