import { SvgIconProps } from '@mui/material';
import { FC, useMemo } from 'react';

import { BlockTypeInfo, Shortcut, UUID } from '@dametis/core';

import BlockTypeIcon from '../../BlockType/BlockTypeIcon';
import { AvailableShortcutCategory, EntitiesByCategory } from '../types';

export interface ListItemBlockTypeIconProps extends Omit<SvgIconProps, 'color'> {
  shortcut: Shortcut;
  entitiesByCategory: EntitiesByCategory;
}

const ListItemBlockTypeIcon: FC<ListItemBlockTypeIconProps> = ({ shortcut, entitiesByCategory, ...props }) => {
  const blockTypesById: Record<UUID, BlockTypeInfo> = useMemo(
    () => entitiesByCategory[shortcut.category as AvailableShortcutCategory] as Record<UUID, BlockTypeInfo>,
    [entitiesByCategory, shortcut.category],
  );

  const blockType = useMemo(() => (shortcut.uuid && blockTypesById ? blockTypesById[shortcut.uuid] : null), [shortcut, blockTypesById]);

  return <BlockTypeIcon primaryIconColor={blockType?.content?.color} {...props} />;
};

export default ListItemBlockTypeIcon;
