import {
  GridAggregationModel,
  GridAlignment,
  GridColDef,
  GridColType,
  GridRowModel,
  GridValidRowModel,
  gridClasses,
} from '@mui/x-data-grid-premium';
import { getUnixTime, isValid } from 'date-fns';
import html2canvas from 'html2canvas';
import i18next from 'i18next';
import { cloneDeep } from 'lodash-es';

import {
  AnyGroupBy,
  CalculationVariable,
  GetCalculationVariableStats,
  IsDataVariable,
  MacroFieldType,
  Operator,
  Period,
  PeriodWithDashStyle,
  TabType,
  TadaApiResponse,
  UUID,
} from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import HTMLDivRef from 'classes/HTMLDivRef/HTMLDivRef';
import MuiApiRef from 'classes/MuiApiRef/MuiApiRef';
import { getFormattedValue } from 'components/UI/UnitPicker/functions/getFormattedValue';
import { parseTadaErrors } from 'errors/parseErrors';
import { calculationToString } from 'functions/calculationToString';
import { durationDisplay, shortDurationDisplay } from 'functions/durationDisplay';
import { getCalculationUnit } from 'functions/getCalculationUnit';
import { getNextDashStyle } from 'functions/getNextDashStyle';
import { getContent, getStyledRules } from 'functions/styledRule';
import { Tada } from 'functions/tada/tada';
import { localizedFormat } from 'localization/localizedDateFns';
import { sdk } from 'sdk';
import store, { TypedThunk } from 'store';
import { addAndSelectTab } from 'store/actions/playground';
import { displaySdkErrorToast, displayTadaErrorToasts, hasTadaErrors } from 'store/actions/toasts';
import { selectStyleConfigurations } from 'store/api/styleConfigurations';
import { setProgresses } from 'store/slices/macros';
import {
  chartSetTable,
  tableAddVariable,
  tableDeleteVariable,
  tableSetLoading,
  tableSetMacro,
  tableUpdateBatchtarget,
  tableUpdateGroupby,
  updateVariableStats,
} from 'store/slices/playground';
import { FormatResult } from 'types/format';
import { MacroLogLevel } from 'types/macro';

import { getNextColor } from '../../../../functions/color';
import TableChartStyledCell from '../../Charts/Table/TableChartStyledCell';
import {
  ICellType,
  IMacroColsSettings,
  ITable,
  ITableMacroSettings,
  ITableProps,
  ITableVariable,
  IsBatchGroupBy,
  IsTableTab,
} from '../../types';
import { createTyChart } from '../TY_CHART/TyChart';
import { createTyGroup } from '../TY_CHART/TyGroup';
import { createTyVariable } from '../TY_CHART/TyVariable';
import { createTab } from '../Tab';
import { getStoreTab, getStoreVariable } from '../shared';

import { createTableVariable, exportTableVariable } from './TableVariable';

export const createTableChart = ({
  variables = [],
  groupBy = '1h',
  gridState = undefined,
  batchTarget = undefined,
  macro = undefined,
}: ITableProps = {}): ITable => {
  const chart = {} as ITable;
  return { ...chart, variables, groupBy, gridState, batchTarget, macro };
};

const saveTablePreview = async (chart: ITable) => {
  try {
    const main = chart.ref.getCurrent().querySelector<HTMLElement>(`.${gridClasses.main}`);
    const canvas = await html2canvas(main);
    const isBlank = !canvas
      .getContext('2d')
      .getImageData(0, 0, canvas.width, canvas.height)
      .data.some(channel => channel !== 0);
    return isBlank ? undefined : canvas.toDataURL();
  } catch {
    return undefined;
  }
};

export const exportTable = async (chart: ITable): Promise<ITable> => {
  if (!chart) return null;
  const newChart = cloneDeep(chart);
  const { variables } = newChart;
  if (newChart.apiRef) {
    newChart.gridState = newChart.apiRef.getCurrent().exportState();
    newChart.gridState.preferencePanel = undefined;
    delete newChart.apiRef;
  }
  if (newChart.ref) delete newChart.ref;
  const preview = (await saveTablePreview(chart)) ?? '';
  return {
    ...newChart,
    preview,
    variables: variables.map(exportTableVariable),
  };
};

export const fetchVariableData = async (
  variables: ITableVariable[],
  period: Period,
  groupBy: AnyGroupBy,
): Promise<TadaApiResponse[] | null> => {
  const payload: CalculationVariable[] = [];
  variables.forEach(v => {
    const variable = cloneDeep({ ...v.expression, period: period.Raw(), stats: true });
    payload.push(variable);
  });
  try {
    const { data } = await Tada(payload, undefined, { groupBy, fill: 'AUTO' });
    if (hasTadaErrors(data)) {
      console.error(data[0].errors);
      store.dispatch(displayTadaErrorToasts(parseTadaErrors(data)));
      return null;
    }
    return data;
  } catch (err) {
    console.error(err);
    store.dispatch(displaySdkErrorToast(err));
    return null;
  }
};

export const fetchBatchData = async (period: Period, groupBy: AnyGroupBy): Promise<TadaApiResponse[] | null> => {
  const payload: CalculationVariable = {
    exp: '',
    operator: Operator.MEAN,
    vars: {},
    period: period.Raw(),
    groupBy,
  };
  try {
    const { data } = await Tada([payload]);
    if (hasTadaErrors(data)) {
      console.error(data[0].errors);
      store.dispatch(displayTadaErrorToasts(parseTadaErrors(data)));
      return null;
    }
    return data;
  } catch (err) {
    console.error(err);
    store.dispatch(displaySdkErrorToast(err));
    return null;
  }
};

export const fetchAllData = (chart: ITable): Promise<TadaApiResponse[]> => {
  const { period } = store.getState().period.present;
  if (chart.variables.length) {
    return fetchVariableData(chart.variables, period, chart.groupBy);
  }
  return fetchBatchData(period, chart.groupBy);
};

export const getTableData =
  (tabUuid: UUID): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTableTab(tab)) return;
    const { chart } = tab;
    const { apiRef, macro } = chart;
    if (!apiRef || macro || !chart.variables.length) return;
    let hasBatch = false;
    dispatch(tableSetLoading({ tabUuid, isLoading: true }));
    const rows: Record<string, GridValidRowModel> = {};
    try {
      const data = await fetchAllData(chart);
      chart.variables.forEach((variable, index) => {
        dispatch(updateVariableStats({ uuid: variable.uuid, statistics: data.at(index)?.stats ?? null }));
      });
      data?.forEach((varData, index) => {
        const subPeriods = varData.batchResults?.periods;
        if (!IsBatchGroupBy(chart.groupBy) || !subPeriods) {
          varData.results.forEach(result => {
            const rowId = result.time;
            if (!rows[rowId]) {
              rows[rowId] = {
                from: result.time,
                id: rowId,
              };
            }
            rows[rowId][chart.variables[index]?.uuid] = result?.value;
          });
        } else {
          hasBatch = true;
          varData.results.forEach((result, j) => {
            const subPeriod = subPeriods[j];
            const duration = getUnixTime(new Date(subPeriod.to)) - getUnixTime(new Date(subPeriod.from));
            const rowId = `${subPeriod.from}-${subPeriod.to}`;
            if (!rows[rowId]) {
              rows[rowId] = {
                from: subPeriod.from,
                to: subPeriod.to,
                duration,
                id: rowId,
              };
            }
            rows[rowId][chart.variables[index]?.uuid] = result?.value;
          });
        }
      });
    } catch (err) {
      console.error(err);
    }
    // should change from headerName depending on hasBatch here
    apiRef.getCurrent().setColumnVisibility('to', hasBatch);
    apiRef.getCurrent().setColumnVisibility('duration', hasBatch);
    apiRef.getCurrent().setRows(Object.values(rows).sort((a, b) => new Date(b.from).getTime() - new Date(a.from).getTime()));
    dispatch(tableSetLoading({ tabUuid, isLoading: false }));
  };

// ACTIONS

export const updateTableVariableData =
  (tabUuid: UUID, variable: ITableVariable): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    dispatch(tableSetLoading({ tabUuid, isLoading: true }));
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTableTab(tab)) return;
    const { apiRef } = tab.chart;
    const { period } = getState().period.present;
    let hasBatch = false;
    const rows: Record<string, GridValidRowModel> = {};
    const data = await fetchVariableData([variable], period, tab.chart.groupBy);
    dispatch(updateVariableStats({ uuid: variable.uuid, statistics: data.at(0)?.stats ?? null }));
    data.forEach(varData => {
      const subPeriods = varData.batchResults?.periods;
      if (!IsBatchGroupBy(tab.chart.groupBy) || !subPeriods) {
        varData.results.forEach(result => {
          const rowId = result.time;
          if (!rows[rowId]) {
            rows[rowId] = {
              from: result.time,
              id: rowId,
            };
          }
          rows[rowId][variable.uuid] = result?.value;
        });
      } else {
        hasBatch = true;
        varData.results.forEach((result, j) => {
          const subPeriod = subPeriods[j];
          const duration = getUnixTime(new Date(subPeriod.to)) - getUnixTime(new Date(subPeriod.from));
          const rowId = `${subPeriod.from}-${subPeriod.to}`;
          if (!rows[rowId]) {
            rows[rowId] = {
              from: subPeriod.from,
              to: subPeriod.to,
              duration,
              id: rowId,
            };
          }
          rows[rowId][variable.uuid] = result.value;
        });
      }
    });
    apiRef.getCurrent().setColumnVisibility('to', hasBatch);
    apiRef.getCurrent().setColumnVisibility('duration', hasBatch);
    apiRef.getCurrent().updateRows(Object.values(rows));
    dispatch(tableSetLoading({ tabUuid, isLoading: false }));
  };

export const updateTableVariableUnitAndFormat =
  (tabUuid: UUID, variableUuid: UUID, unit?: string, format?: FormatResult): TypedThunk<void> =>
  dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    const variable = dispatch(getStoreVariable<ITableVariable>(tabUuid, variableUuid));
    if (!IsTableTab(tab)) return;
    const { apiRef } = tab.chart;
    const column = apiRef.getCurrent().getColumn(variableUuid);
    apiRef.getCurrent().updateColumns([
      {
        ...column,
        valueFormatter: (value: number | undefined) => {
          const baseUnit = getCalculationUnit(variable.expression);
          const parsedFormat = format ?? variable.format;
          const parsedUserUnit = unit ?? variable.unit;
          return Number.isFinite(value) ? getFormattedValue({ value, baseUnit, userUnit: parsedUserUnit, format: parsedFormat }) : '-';
        },
        renderCell: ({ value, formattedValue }) => {
          return (
            <TableChartStyledCell styledRules={variable.styledRules} type={ICellType.VAR} unformattedValue={value} value={formattedValue} />
          );
        },
      },
    ]);
    apiRef.getCurrent().forceUpdate();
  };

export const updateTableVariableExp =
  (tabUuid: UUID, variable: ITableVariable): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTableTab(tab)) return;
    const { apiRef } = tab.chart;
    const column = apiRef.getCurrent().getColumn(variable.uuid);
    apiRef.getCurrent().updateColumns([
      {
        ...column,
        renderHeader: ({ colDef: { headerName } }) => (
          <TableChartStyledCell
            className={`cell__${variable.color}`}
            type={ICellType.HEADER}
            unformattedValue={headerName}
            value={headerName}
          />
        ),
        valueFormatter: (value: number | undefined) => {
          const baseUnit = getCalculationUnit(variable.expression);
          const { format } = variable;
          return getFormattedValue({ value, baseUnit, userUnit: variable.unit, format });
        },
        renderCell: ({ value, formattedValue }) => {
          return (
            <TableChartStyledCell styledRules={variable.styledRules} type={ICellType.VAR} unformattedValue={value} value={formattedValue} />
          );
        },
      },
    ]);
    await dispatch(updateTableVariableData(tabUuid, variable));
  };

export const addTableVariableToTable =
  (tabUuid: UUID, variable: ITableVariable, colDef: GridColDef): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTableTab(tab)) return;
    const { apiRef } = tab.chart;
    apiRef.getCurrent().updateColumns([colDef]);
    dispatch(tableAddVariable({ tabUuid, variable }));
    await dispatch(updateTableVariableData(tabUuid, variable));
  };

export const deleteTableVariableFromTable =
  (tabUuid: UUID, variableUuid: UUID): TypedThunk<void> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTableTab(tab)) return;
    const { apiRef } = tab.chart;
    const exportState = apiRef.getCurrent().exportState();
    const newState = { ...exportState };
    delete newState.columns.dimensions[variableUuid];
    newState.columns.orderedFields.splice(newState.columns.orderedFields.indexOf(variableUuid), 1);
    apiRef.getCurrent().restoreState(newState);
    dispatch(tableDeleteVariable({ tabUuid, variableUuid }));
    await dispatch(getTableData(tabUuid));
  };

const setVarsColumns = (tabUuid: UUID): void => {
  const tab = store.dispatch(getStoreTab(tabUuid));
  if (!IsTableTab(tab)) return;
  const { apiRef, variables, gridState } = tab.chart;
  const columns: GridColDef[] = [];
  const orderedVariables = gridState
    ? [...variables].sort((a, b) => {
        if (gridState.columns.orderedFields.indexOf(a.uuid) < gridState.columns.orderedFields.indexOf(b.uuid)) {
          return -1;
        }
        if (gridState.columns.orderedFields.indexOf(a.uuid) > gridState.columns.orderedFields.indexOf(b.uuid)) {
          return 1;
        }
        return 0;
      })
    : variables;
  orderedVariables.filter(v =>
    gridState && gridState.columns.columnVisibilityModel ? !gridState.columns.columnVisibilityModel[v.uuid] : false,
  );

  orderedVariables.forEach(v => {
    const name = v.expression?.nickname?.length ? v.expression.nickname : calculationToString(v.expression, true, true);
    const tableVar = createTableVariable({
      expression: v.expression,
      name,
      color: v.color,
      uuid: v.uuid,
      unit: v.unit,
      format: v.format,
      styledRules: v.styledRules ?? [],
    });
    columns.push({
      field: tableVar.uuid,
      headerName: name,
      headerClassName: `cell__${tableVar.color.substring(1)}`,
      width: gridState ? (gridState.columns.dimensions[v.uuid]?.width ?? 200) : 200,
      align: 'right',
      type: 'number',
      valueFormatter: (value: number | undefined) => {
        const styleConfigurations = selectStyleConfigurations()(store.getState()).data;
        const styledRules = getStyledRules(v.styledRules, styleConfigurations ?? []);
        const content = getContent(styledRules, value);
        const realValue = Number.isFinite(value)
          ? getFormattedValue({ value, baseUnit: getCalculationUnit(v.expression), userUnit: tableVar.unit, format: tableVar.format })
          : '-';
        return content.text?.length > 0 ? content.text : realValue;
      },
      renderCell: ({ aggregation, value, formattedValue }) => {
        if (aggregation && !aggregation.hasCellUnit) {
          return formattedValue;
        }
        return (
          <TableChartStyledCell styledRules={tableVar.styledRules} type={ICellType.VAR} unformattedValue={value} value={formattedValue} />
        );
      },
    });
  });
  if (gridState) {
    apiRef.getCurrent()?.setColumnWidth('to', gridState.columns.dimensions.to?.width ?? 200);
    apiRef.getCurrent()?.setColumnWidth('from', gridState.columns.dimensions.from?.width ?? 200);
    apiRef.getCurrent()?.setColumnWidth('duration', gridState.columns.dimensions.duration?.width ?? 200);
  }
  apiRef.getCurrent()?.updateColumns(columns);
};

export const setDateColumns = (tabUuid: UUID): void => {
  const tab = store.dispatch(getStoreTab(tabUuid));
  if (!IsTableTab(tab)) return;
  const { apiRef } = tab.chart;
  const columns: GridColDef[] = cloneDeep(tab.chart.apiRef?.getCurrent()?.getAllColumns() ?? []);
  const { batchTarget } = tab.chart;
  const tableCols: GridColDef[] = [
    {
      field: 'from',
      headerAlign: 'center',
      align: 'center',
      headerName: i18next.t('playground:text.from'),
      width: 200,
      type: 'date',
      disableReorder: true,
      aggregable: false,
      groupable: false,
      valueFormatter: (value: number | undefined) => (value ? localizedFormat(new Date(value), 'Ppp') : ''),
      sortComparator: (v1, v2, cp1, cp2) => {
        const d1 = new Date(tab.chart.apiRef.getCurrent().getRow(cp1.id).from).getTime() ?? 0;
        const d2 = new Date(tab.chart.apiRef.getCurrent().getRow(cp2.id).from).getTime() ?? 0;
        return d2 - d1;
      },
    },
    {
      field: 'to',
      headerAlign: 'center',
      align: 'center',
      headerName: i18next.t('playground:text.to'),
      width: 200,
      type: 'date',
      disableReorder: true,
      aggregable: false,
      groupable: false,
      valueFormatter: (value: number | undefined) => (value ? localizedFormat(new Date(value), 'Ppp') : '-'),
      sortComparator: (v1, v2, cp1, cp2) => {
        const d1 = new Date(tab.chart.apiRef.getCurrent().getRow(cp1.id).to).getTime() ?? 0;
        const d2 = new Date(tab.chart.apiRef.getCurrent().getRow(cp2.id).to).getTime() ?? 0;
        return d2 - d1;
      },
      sortable: false,
    },
    {
      field: 'duration',
      headerAlign: 'center',
      align: 'center',
      headerName: `${i18next.t('playground:text.duration')} ${batchTarget ? `(${shortDurationDisplay(batchTarget)})` : ''}`,
      width: 200,
      sortable: false,
      type: 'number',
      disableReorder: true,
      aggregable: false,
      groupable: false,
      renderCell: ({ value }) =>
        value ? (
          <TableChartStyledCell
            batchTarget={batchTarget}
            type={ICellType.DURATION}
            unformattedValue={value}
            value={value}
            valueFormatter={durationDisplay}
          />
        ) : (
          '-'
        ),
    },
  ];
  tableCols.forEach(col => {
    if (!columns.find(c => c.field === col.field)) {
      columns.push(col);
    }
  });
  apiRef.getCurrent()?.updateColumns(columns);
  apiRef.getCurrent().setState(state => ({ ...state, columns: { ...state.columns, orderedFields: columns.map(i => i.field) } }));
};

const macroFieldToGridColDef = (macroCol: IMacroColsSettings, tabUuid: UUID): GridColDef => {
  const tab = store.dispatch(getStoreTab(tabUuid));
  if (IsTableTab(tab)) {
    const { gridState } = tab.chart;
    const width = macroCol.type === MacroFieldType.DATE ? 170 : 100;
    return {
      field: macroCol.name,
      headerName: macroCol.label || macroCol.name,
      headerClassName: `cell__${macroCol.color.substring(1)}`,
      width: gridState?.columns?.dimensions?.[macroCol.name]?.width ?? width,
      type: macroCol.type as GridColType,
      align: {
        [MacroFieldType.BOOLEAN]: 'center',
        [MacroFieldType.DATE]: 'right',
        [MacroFieldType.DURATION]: 'right',
        [MacroFieldType.NUMBER]: 'right',
        [MacroFieldType.STRING]: 'left',
      }[macroCol.type] as GridAlignment,
      valueGetter: (value: string) =>
        ({
          [MacroFieldType.BOOLEAN]: Boolean(value),
          [MacroFieldType.DATE]: new Date(value),
          [MacroFieldType.DURATION]: value,
          [MacroFieldType.NUMBER]: value,
          [MacroFieldType.STRING]: value,
        })[macroCol.type],
      renderCell: ({ value }) => (
        <TableChartStyledCell
          styledRules={macroCol.styledRules}
          type={ICellType.VAR}
          unformattedValue={value}
          value={
            {
              [MacroFieldType.NUMBER]: getFormattedValue({ value, baseUnit: macroCol.unit }),
              [MacroFieldType.DATE]: isValid(value) ? localizedFormat(value, 'Ppp') : '-',
              [MacroFieldType.DURATION]: durationDisplay(value) ?? value,
              [MacroFieldType.STRING]: value,
              [MacroFieldType.BOOLEAN]: value,
            }[macroCol.type] ?? value
          }
        />
      ),
    };
  }
  return null;
};

// TODO: JCE clean & factorize
export const fetchAllMacroData = async (tabUuid: UUID): Promise<void> => {
  const { period } = store.getState().period.present;
  const { macros } = store.getState().configuration;
  const {
    auth: {
      selectedGroup: { sites },
      selectedSite,
    },
  } = store.getState();
  const tab = store.dispatch(getStoreTab(tabUuid));
  if (!IsTableTab(tab)) return;
  const { apiRef, macro, gridState } = tab.chart;
  if (!apiRef || !apiRef.getCurrent() || !macro.config.length) return;
  const timeZone = selectedSite ? selectedSite.timeZone : (sites?.at(0).timeZone ?? undefined);
  const from = period.from.toISOString();
  const to = period.to.toISOString();
  const columns: GridColDef[] = macro.config.map(field => macroFieldToGridColDef(field, tabUuid));
  store.dispatch(
    setProgresses({
      [macro.uuid]: {
        name: `${i18next.t('macro:toast.executeMacro')} ${macros.find(m => m.uuid === macro.uuid)?.name ?? ''}`,
        logs: '',
        progress: '',
      },
    }),
  );
  apiRef.getCurrent().updateColumns(columns);
  try {
    const { data: job } = await sdk.data.ExecMacro({
      uuid: macro.uuid,
      period: new Period({ from, to }).Raw(),
      timezone: timeZone,
    });
    const fetchData = (jobUuid): Promise<GridRowModel[]> =>
      new Promise(resolve => {
        const fetchStart = (id: UUID) => {
          setTimeout(async () => {
            const { data } = await sdk.data.MacroProgress(selectedSite.uuid, id);
            if (data.logs?.length) {
              const currentLog = data.logs.reduce((previousValue, currentValue) => {
                const val = JSON.parse(currentValue);
                if (val.level === MacroLogLevel.INFO) return `${val.message}...`;
                return previousValue;
              }, '');
              store.dispatch(
                setProgresses({
                  [macro.uuid]: {
                    name: `${i18next.t('macro:toast.executeMacro')} ${macros.find(m => m.uuid === macro.uuid)?.name ?? ''}`,
                    logs: currentLog,
                    progress: data.progress,
                  },
                }),
              );
            }
            if (JSON.stringify([{}]) === JSON.stringify(data.returnValue)) {
              fetchStart(id);
            } else {
              const returnValue = Array.isArray(data.returnValue) ? data.returnValue : data.returnValue[macro?.selectedResultKey];
              resolve(returnValue.map((row, index) => ({ ...row, id: index })));
            }
          }, 1000);
        };
        fetchStart(jobUuid);
      });
    const results = await fetchData(job);
    setTimeout(() => {
      apiRef.getCurrent().setRows(results);
      apiRef.getCurrent().setAggregationModel(
        macro.config.reduce<GridAggregationModel>((acc, field) => {
          if (gridState && gridState.aggregation.model[field.name]) acc[field.name] = gridState.aggregation.model[field.name];
          else if ([MacroFieldType.DATE, MacroFieldType.BOOLEAN, MacroFieldType.STRING, MacroFieldType.DURATION].includes(field.type))
            return acc;
          else acc[field.name] = 'sum';
          return acc;
        }, {}),
      );
      apiRef.getCurrent().forceUpdate();
    }, 200);
  } catch (err) {
    console.error(err);
    store.dispatch(displaySdkErrorToast(err));
  }
  store.dispatch(setProgresses({ [macro.uuid]: undefined }));
};

export const setMacroToTable =
  (tabUuid: UUID, macro?: ITableMacroSettings, selectedResultKey?: string): TypedThunk<Promise<ITableMacroSettings>> =>
  async dispatch => {
    let newMacro: ITableMacroSettings = null;
    if (macro?.uuid) {
      newMacro = await getMacro(macro.uuid, selectedResultKey);
    } else if (macro !== null) {
      newMacro = {
        uuid: '',
        config: [],
      };
    }
    dispatch(tableSetMacro({ tabUuid, macro: newMacro }));
    return newMacro;
  };

const getMacro = async (uuid: UUID, selectedResultKey?: string): Promise<ITableMacroSettings> => {
  try {
    const { data } = await sdk.macro.Read(uuid);
    const fields = Array.isArray(data.fields) ? data.fields : data.fields[selectedResultKey];

    const res = {
      uuid: data.uuid,
      config: fields?.map(f => ({
        ...f,
        label: undefined,
        visible: true,
        color: '',
      })),
      selectedResultKey,
    };

    // eslint-disable-next-line no-return-assign
    res.config?.forEach(f => (f.color = getNextColor(res.config)));
    return res;
  } catch (err) {
    console.error(err);
    store.dispatch(displaySdkErrorToast(err));
  }
  return null;
};

export const updateMacroConfig =
  (tabUuid: UUID, macro: ITableMacroSettings): TypedThunk<void> =>
  dispatch =>
    dispatch(tableSetMacro({ tabUuid, macro }));

export const setGroupByOnTable =
  (tabUuid: UUID, groupBy: ITable['groupBy']): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTableTab(tab)) return;
    const { apiRef } = tab.chart;
    dispatch(tableUpdateGroupby({ tabUuid, groupBy }));
    if (apiRef) apiRef.getCurrent().setRows([]);
    await dispatch(getTableData(tab.uuid));
  };

export const setBatchTargetOnTable =
  (tabUuid: UUID, time: number): TypedThunk<void> =>
  dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsTableTab(tab)) return;
    const { apiRef } = tab.chart;
    apiRef.getCurrent().updateColumns([
      {
        field: 'duration',
        headerName: `${i18next.t('playground:text.duration')} ${time ? `(${shortDurationDisplay(time)})` : ''}`,
        width: 200,
        sortable: false,
        align: 'right',
        renderCell: ({ value }) =>
          value ? (
            <TableChartStyledCell
              batchTarget={time}
              type={ICellType.DURATION}
              unformattedValue={value}
              value={value}
              valueFormatter={durationDisplay}
            />
          ) : (
            ''
          ),
      },
    ]);
    dispatch(tableUpdateBatchtarget({ tabUuid, batchTarget: time }));
  };

const initTable =
  (tabUuid: UUID): TypedThunk<void> =>
  dispatch => {
    const storeTab = dispatch(getStoreTab(tabUuid));
    const tab = cloneDeep(storeTab);
    if (!IsTableTab(tab)) return;
    if (!tab.chart?.macro) {
      setDateColumns(tabUuid);
      setVarsColumns(tabUuid);
    } else {
      tab.chart.macro.config.forEach(field => {
        if (!field.color) {
          const color = getNextColor(tab.chart.macro.config);
          field.color = color;
        }
      });
      dispatch(tableSetMacro({ tabUuid, macro: tab.chart.macro }));
    }
  };

export const setChartToTable =
  (tabUuid: UUID, apiRef: MuiApiRef, ref: HTMLDivRef): TypedThunk<void> =>
  dispatch => {
    dispatch(chartSetTable({ tabUuid, apiRef, ref }));
    dispatch(initTable(tabUuid));
  };

export const createTyTabFromSubPeriod =
  (tabUuid: UUID, rows: Record<string, any>[]): TypedThunk<void> =>
  dispatch => {
    const storeTab = dispatch(getStoreTab(tabUuid));
    const tab = cloneDeep(storeTab);
    if (!IsTableTab(tab)) return;
    const variables = tab.chart.variables.map(v =>
      createTyVariable({ ...v, hidden: v.tyVisibility !== undefined ? !v.tyVisibility : false }),
    );
    const groups = [];
    variables.forEach(v => {
      const group = groups.find(g => g.unit === v.unit);
      const singleVariable = getSingleVariableCalculation(v.expression);
      if (group) {
        if (
          !singleVariable ||
          (IsDataVariable(singleVariable) &&
            !group.variables
              .map(variable => GetCalculationVariableStats(variable.expression).variableIds)
              .includes(singleVariable.variableUuid))
        )
          group.variables.push(v);
      } else groups.push(createTyGroup({ variables: [v], unit: v.unit }));
    });

    const tyChart = createTyChart({
      groups,
      isBatchChart: tab.chart.batchTarget ?? true,
    });
    const customPeriod = [];
    rows.forEach(row =>
      customPeriod.push({
        ...new Period({ from: row.from, to: row.to }),
        dashStyle: getNextDashStyle(customPeriod),
      } as PeriodWithDashStyle),
    );
    const newTab = createTab({
      type: TabType.TY_CHART,
      name:
        rows.length > 1
          ? i18next.t('playground:text.multipleBatchTY')
          : `${localizedFormat(new Date(rows[0].from), 'Ppp')} - ${localizedFormat(new Date(rows[0].to), 'Ppp')}`,
      chart: tyChart,
      customPeriod,
    });
    dispatch(addAndSelectTab(newTab));
  };
