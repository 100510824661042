import {
  AccountTreeOutlined,
  DashboardOutlined,
  LinkOutlined,
  NotificationsNoneOutlined,
  ReceiptOutlined,
  SettingsOutlined,
  ShuffleOutlined,
  WorkOutlineOutlined,
} from '@mui/icons-material';
import { SvgIcon, SvgIconProps, Tooltip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ShortcutCategory } from '@dametis/core';

import { BlocksOutlined } from 'assets/icons/BlocksOutlined';
import { DeployedCodeOutlined } from 'assets/icons/DeployedCodeOutlined';
import { PlaygroundOutlined } from 'assets/icons/PlaygroundOutlined';

export const shortcutCategoryToIcon = {
  [ShortcutCategory.SYNOPTIC]: AccountTreeOutlined,
  [ShortcutCategory.ALARM]: NotificationsNoneOutlined,
  [ShortcutCategory.REPORT]: DashboardOutlined,
  [ShortcutCategory.PLAYGROUND]: PlaygroundOutlined,
  [ShortcutCategory.PROJECT]: WorkOutlineOutlined,
  [ShortcutCategory.TASK]: WorkOutlineOutlined,
  [ShortcutCategory.COMMENT]: WorkOutlineOutlined,
  [ShortcutCategory.SITE]: WorkOutlineOutlined,
  [ShortcutCategory.ASSET]: WorkOutlineOutlined,
  [ShortcutCategory.VARIABLE]: WorkOutlineOutlined,
  [ShortcutCategory.BLOCK]: BlocksOutlined,
  [ShortcutCategory.BLOCK_TYPE]: DeployedCodeOutlined,
  [ShortcutCategory.STANDARD_BLOCK]: DeployedCodeOutlined,
  [ShortcutCategory.HREF]: LinkOutlined,
  [ShortcutCategory.MACRO]: SettingsOutlined,
  [ShortcutCategory.CART]: WorkOutlineOutlined,
  [ShortcutCategory.CONTRACT]: ReceiptOutlined,
  [ShortcutCategory.BILL]: ReceiptOutlined,
  [ShortcutCategory.BATCH]: SettingsOutlined,
  [ShortcutCategory.MODEL]: ShuffleOutlined,
};

export interface ShortcutIconProps extends SvgIconProps {
  category: ShortcutCategory;
  withTooltip?: boolean;
}

const ShortcutIcon: FC<ShortcutIconProps> = ({ category, withTooltip = false, ...props }) => {
  const { t } = useTranslation('shortcut');

  // Only here in case of a unknown or not defined category but should not happen and be deleted
  if (category === undefined || shortcutCategoryToIcon[category] === undefined) {
    return null;
  }

  return (
    <Tooltip title={withTooltip ? t('tooltip.shortcutCategory', { context: category }) : undefined}>
      <SvgIcon component={shortcutCategoryToIcon[category]} fontSize="small" {...props} />
    </Tooltip>
  );
};

export default ShortcutIcon;
