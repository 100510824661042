import { ActivityType, ActivityVerb } from '@dametis/core';

export default {
  title: {
    activityDetails: 'Detalles de la actividad',
  },
  type: {
    [ActivityVerb.CREATE]: 'ha creado',
    [ActivityVerb.RESTORE]: 'ha restaurado',
    [ActivityVerb.DUPLICATE]: 'ha duplicado',
    [ActivityVerb.TRANSFER]: 'ha transferido',
    [ActivityVerb.UPDATE]: 'ha modificado',
    [ActivityVerb.DELETE]: 'ha eliminado',
    [ActivityVerb.UPLOAD]: 'ha publicado',
    [ActivityVerb.SIGNIN_PASSWORD]: 'ha iniciado sesión con una contraseña',
    [ActivityVerb.SIGNIN_INVITATION]: 'ha iniciado sesión con una invitación',
    [ActivityVerb.SIGNIN_SSO]: 'ha iniciado sesión con SSO',
    [ActivityVerb.LOGOUT]: 'ha cerrado sesión',
    [ActivityVerb.CREATE_INVITATION]: 'ha creado una invitación',
    [ActivityVerb.FINALIZE_INVITATION]: 'ha finalizado su invitación',
    [ActivityVerb.LOST_PASSWORD]: 'ha perdido su contraseña',
    [ActivityVerb.RESET_PASSWORD]: 'ha restablecido su contraseña',
  },
  object: {
    [ActivityType.GROUP]: 'el grupo',
    [ActivityType.SITE]: 'el sitio',
    [ActivityType.BOX]: 'la caja',
    [ActivityType.DEVICE]: 'el equipo',
    [ActivityType.PLAYGROUND]: 'el playground',
    [ActivityType.REPORT]: 'el informe',
    [ActivityType.SYNOPTIC]: 'la vista general',
    [ActivityType.VARIABLE]: 'la variable',
    [ActivityType.USER]: 'el usuario',
    [ActivityType.ALARM]: 'la alarma',
    [ActivityType.PROJECT]: 'el proyecto',
    [ActivityType.ASSET]: 'un documento',
    [ActivityType.TASK]: 'una tarea',
    [ActivityType.BATCH]: 'un batch',
    [ActivityType.MODEL]: 'un modelo',
    [ActivityType.COMMENT]: 'un comentario',
    [ActivityType.BLOCK]: 'un bloque',
    [ActivityType.BLOCK_TYPE]: 'un tipo de bloque',
    [ActivityType.STYLE_CONFIGURATION]: 'una configuración de estilo',
    [ActivityType.ALIAS]: 'un alias',
    [ActivityType.IMAGE]: 'una imagen',
    [ActivityType.TAG]: 'un tag',
    [ActivityType.OPCO]: 'un mezcla de vapor',
    [ActivityType.CALENDAR]: 'un calendario',
  },
  text: {
    activities: 'Actividades',
    label: '{{firstName}} {{lastName}} $t(type.{{type}}) $t(object.{{object}})',
    noActivities: 'Ninguna actividad registrada',
    displayOnlyChangedFields: 'Solo mostrar los campos modificados.',
    variable: {
      noUpdatedVariableFields: 'Ninguna modificación',
      updatedVariableFields_zero: ' y $t(configuration:input.{{field}})',
      updatedVariableFields_one: ', $t(configuration:input.{{field}}) y otro campo',
      updatedVariableFields_other: ', $t(configuration:input.{{field}}) y {{count}} otros campos',
    },
  },
  button: {
    close: 'Cerrar',
    restore: 'Restaurar',
    order: {
      newest: 'De más reciente a más antiguo',
      oldest: 'De más antiguo a más reciente',
    },
  },
  toast: {
    restoreVariableSuccess: 'Variable restaurada.',
  },
};
