import { StyleFormat, TextAlign } from '@dametis/core';

import { EntityType } from 'types/comment';

export default {
  placeholder: {
    leaveAComment: 'Write a comment...',
    placeholderGeneral: 'Write something...',
  },
  text: {
    fontSize: {
      small: 'Small',
      medium: 'Medium',
      large: 'Large',
      auto: 'Auto',
      mixed: 'Mixed',
    },
  },
  label: {
    [StyleFormat.BOLD]: 'Bold',
    [StyleFormat.ITALIC]: 'Italic',
    [StyleFormat.UNDERLINE]: 'Underline',
    [TextAlign.LEFT]: 'Left alignment',
    [TextAlign.CENTER]: 'Center alignment',
    [TextAlign.RIGHT]: 'Right alignment',
    entity: {
      [EntityType.ALARM]: 'alarm',
      [EntityType.PLAYGROUND]: 'playground',
      [EntityType.REPORT]: 'report',
      [EntityType.PROJECT]: 'project',
      [EntityType.SYNOPTIC]: 'synoptic',
      [EntityType.VARIABLE]: 'variable',
      [EntityType.TASK]: 'task',
    },
  },
  button: {
    submit: 'Submit',
    send: 'Send',
    cancel: 'Cancel',
  },
  tooltip: {
    fontColor: 'Text color',
  },
};
