import { Person } from '@mui/icons-material';
import { Avatar, ListItem, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  search: string;
}

const EmptyCollaboratorListItem: FC<Props> = ({ search }) => {
  const { t } = useTranslation('comment');
  return (
    <ListItem sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '400px' }}>
      <Stack alignItems="center" direction="row" spacing={1} sx={{ width: '100%' }}>
        <Avatar sx={{ width: 25, height: 25, fontSize: 13 }}>
          <Person />
        </Avatar>
        <Typography noWrap mr={1} variant="body1">
          {t('text.noUserFound', { search })}
        </Typography>
      </Stack>
    </ListItem>
  );
};

export default EmptyCollaboratorListItem;
