export default {
  button: {
    reset: 'Restablecer',
  },
  label: {
    noValue: 'n/a',
  },
  option: {
    all: 'Seleccionar todo',
  },
  text: {
    noTagSelected: 'Ningún etiqueta seleccionada',
  },
  toast: {
    tooMuchActiveFilters: 'Exceso de filtros activos',
  },
  tooltip: {
    unassigned: 'Sin asignar',
  },
};
