export default {
  name: {
    name: 'Name',
    nameTooltip: 'Name',
  },
  unit: {
    name: 'Unit',
    nameTooltip: 'Unit',
  },
  sensorName: {
    name: 'Sensor',
    nameTooltip: 'Sensor Name',
  },
  reference: {
    name: 'Ref',
    nameTooltip: 'Reference',
  },
  sensorDescription: {
    name: 'Desc',
    nameTooltip: 'Description',
  },
  enabled: {
    name: 'Enbl',
    nameTooltip: 'Enabled',
  },
  modbusTCPUid: {
    name: 'Uid',
    nameTooltip: 'Uid',
  },
  modbusTCPFunction: {
    name: 'Func',
    nameTooltip: 'Function',
  },
  modbusTCPAddress: {
    name: 'Addr',
    nameTooltip: 'Address',
  },
  modbusTCPInvertsWords: {
    name: 'Invert',
    nameTooltip: 'Inverts Words',
  },
  modbusTCPTransformer: {
    name: 'Mult',
    nameTooltip: 'Multiplier',
  },
  modbusTCPDataType: {
    name: 'Type',
    nameTooltip: 'Data Type',
  },
  modbusTCPBit: {
    name: 'Bit',
    nameTooltip: 'Bit Number',
  },
  groupNameSize: {
    modbus: 'Modbus TCP',
    setting: 'General Setting',
  },
  functionName: {
    _0: 'Read Coil',
    _1: 'Read Discrete Input',
    _2: 'Read Holding Register',
    _3: 'Read Input Registers',
  },
  typeName: {
    int16: 'Signed Integer',
    uint16: 'Unsigned Integer',
    int32: 'Signed Long',
    uint32: 'Unsigned Long',
    float32: 'Float',
    boolean: 'Bool',
  },
};
