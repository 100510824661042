import { Select, SelectProps, Stack, Typography, outlinedInputClasses, selectClasses, styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import Counter, { CounterProps } from '../Counter/Counter';

interface WithoutMultiple<Value> {
  multiple?: false;
  displayValue: (value: Value | '') => string;
  emptyShown?: boolean;
}

interface WithMultiple {
  multiple: true;
}

type MultipleProps<Value> = WithoutMultiple<Value> | WithMultiple;

export type SelectFilterProps<Value = unknown> = Omit<SelectProps<Value>, 'multiple' | 'variant'> &
  MultipleProps<Value> & { variant?: SelectProps['variant'] };

export const FilterCounter: FC<CounterProps> = styled(Counter)(({ theme }) => ({
  boxSizing: 'border-box',
  marginLeft: theme.spacing(1),
  minWidth: '16px',
  padding: `${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
  textAlign: 'center',
}));

const remove = ({ displayValue, emptyShown, ...obj }: Record<string, unknown>) => obj;

const SelectFilter: FC<PropsWithChildren<SelectFilterProps>> = styled(
  <Value = unknown,>({ children, placeholder, ...props }: PropsWithChildren<SelectFilterProps<Value>>) => (
    <Select<Value>
      displayEmpty
      renderValue={selected =>
        Array.isArray(selected) ? (
          <Stack alignItems="center" direction="row">
            <Typography variant="h6">{placeholder}</Typography>
            {selected.length > 0 && <FilterCounter count={selected.length} />}
          </Stack>
        ) : (
          <Stack alignItems="center" direction="row">
            <Typography variant="h6">{`${placeholder}`}</Typography>
            {props.multiple === false && props.value !== undefined && (props.emptyShown || props.value !== '') && (
              <Typography sx={{ ml: theme => theme.spacing(1) }} variant="subtitle2">
                {props.displayValue(props.value)}
              </Typography>
            )}
          </Stack>
        )
      }
      {...remove(props)}
    >
      {children}
    </Select>
  ),
)(({ theme, size }) => ({
  width: 'auto',
  paddingInline: theme.spacing(0.5),
  height: size === 'small' ? '32px' : '40px',
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderRadius: 20,
    border: `1.5px solid ${theme.palette.daBlue.light}!important`,
  },
  [`& .${selectClasses.select}`]: {
    paddingTop: theme.spacing(size === 'small' ? 0.75 : 1),
    paddingBottom: theme.spacing(size === 'small' ? 0.75 : 1),
  },
  [`& .${selectClasses.icon}`]: {
    color: theme.palette.daBlue.light,
  },
}));

SelectFilter.defaultProps = {
  emptyShown: false,
  multiple: false,
  variant: 'outlined',
};

export default SelectFilter;
