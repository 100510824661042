import { StackProps } from '@mui/material';
import { FC } from 'react';

import { CommentInfo, UpdateCommentBody } from '@dametis/core';

import CommentBase from './CommentBase';
import CommentProvider from './context/CommentContext';

const stackPropsDefaultProp: StackProps = {};

export interface CommentProps {
  comment: CommentInfo;
  stackProps?: StackProps;
  onDateClick?: (date: Date) => void;
  onPeriodClick?: (period: { from: Date; to: Date }) => void;
  onUpdate?: (value: Record<string, any>) => void;
  onDeleteCb?: () => void;
  onUpdateCb?: (comment: UpdateCommentBody) => void;
  dateFormat?: 'absolute' | 'relative';
  displayActions?: boolean;
  date?: Date | string;
  period?: {
    from: Date | string;
    to: Date | string;
  };
  backgroundColor?: string;
  datePicker?: boolean;
}

const Comment: FC<CommentProps> = ({
  stackProps = stackPropsDefaultProp,
  onDateClick = undefined,
  onPeriodClick = undefined,
  dateFormat = 'relative',
  onUpdate = undefined,
  onDeleteCb = undefined,
  onUpdateCb = undefined,
  displayActions = false,
  date = undefined,
  period = undefined,
  backgroundColor = undefined,
  datePicker = false,
  ...props
}) => (
  <CommentProvider>
    <CommentBase
      backgroundColor={backgroundColor}
      date={date}
      dateFormat={dateFormat}
      datePicker={datePicker}
      displayActions={displayActions}
      period={period}
      stackProps={stackProps}
      onDateClick={onDateClick}
      onDeleteCb={onDeleteCb}
      onPeriodClick={onPeriodClick}
      onUpdate={onUpdate}
      onUpdateCb={onUpdateCb}
      {...props}
    />
  </CommentProvider>
);

export default Comment;
