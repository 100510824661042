import { ArrowBackIosOutlined, SvgIconComponent } from '@mui/icons-material';
import { IconButton, IconButtonProps, SvgIcon, SvgIconProps, styled } from '@mui/material';
import { FC } from 'react';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  flexShrink: 0,
  padding: theme.spacing(1.5),
  marginLeft: `-${theme.spacing(1.5)}`,
}));

const iconPropsDefaultProp: SvgIconProps = {};

export interface RightPanelHeaderButtonProps extends Omit<IconButtonProps, 'children'> {
  icon?: SvgIconComponent;
  iconProps?: SvgIconProps;
}

const RightPanelHeaderButton: FC<RightPanelHeaderButtonProps> = ({
  icon = ArrowBackIosOutlined,
  iconProps = iconPropsDefaultProp,
  ...props
}) => (
  <StyledIconButton color="inherit" id="return" size="large" {...props}>
    <SvgIcon component={icon} {...iconProps} />
  </StyledIconButton>
);

export default RightPanelHeaderButton;
