import { Editor, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { BlockElement, CalculationVariableProps, Operator } from '@dametis/core';

import { createText } from './text';

export const createBlockElement = (
  props: CalculationVariableProps = {},
  children: BlockElement['children'] = [createText('')],
): BlockElement => ({
  type: 'block',
  children,
  ...(Boolean(props) && props),
});

const wrapBlock = (editor: Editor) => {
  const isCollapsed = editor.selection && Range.isCollapsed(editor.selection);
  const block = createBlockElement({ operator: Operator.DELTA }, isCollapsed ? [{ type: 'paragraph', children: [{ text: '' }] }] : []);
  if (isCollapsed) {
    Transforms.insertNodes(editor, block);
  } else {
    Transforms.wrapNodes(editor, block, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const insertBlock = (editor: Editor) => {
  if (!editor.selection) return;
  wrapBlock(editor);
  ReactEditor.focus(editor);
  Transforms.move(editor, {
    unit: 'offset',
  });
};
