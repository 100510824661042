import { Box } from '@mui/material';
import { FC } from 'react';

import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';

export interface CalculationNameProps {
  name: string;
}

const CalculationName: FC<CalculationNameProps> = ({ name }) => (
  <Box m={1}>
    <TypographyEllipse variant="h6">{name}</TypographyEllipse>
  </Box>
);
export default CalculationName;
