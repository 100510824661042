import { useTheme } from '@mui/material';
import { ComponentPropsWithoutRef, FC } from 'react';

export type SeriesLabelProps = ComponentPropsWithoutRef<'div'>;

const SeriesLabel: FC<SeriesLabelProps> = ({ style, ...props }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        fontSize: 13,
        ...style,
      }}
      {...props}
    />
  );
};

export default SeriesLabel;
