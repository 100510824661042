export default {
  label: {
    noData: 'Ningún dato para mostrar',
    loading: 'Cargando...',
    smartScale: 'Escala ajustada',
    regularScale: 'Escala dinámica',
    resetZoom: 'Deszoom',
    start: 'Inicio:',
    end: 'Fin:',
    date: 'Fecha:',
    dependency: 'Dependencias:',
    week: 'Semana',
    duration: 'Duración',
    delta: 'Delta',
  },
  button: {
    edit: 'Modificar',
    delete: 'Vaciar',
    cancel: 'Cancelar',
    add: 'Añadir',
    save: 'Guardar',
  },
  input: {
    comment: 'Comentario',
  },
  tooltip: {
    expandAdvancedMode: 'Modo avanzado',
    insertComment: 'Nuevo comentario',
    insertCommentDisabled: 'No se pueden comentar las variables calculadas',
    drawSegment: 'Medición manual',
    statistics: 'Estadísticas',
    annotationOff: 'Desactivar las anotaciones',
    annotationOn: 'Activar las anotaciones',
    commentOff: 'Ocultar comentarios',
    commentOn: 'Mostrar comentarios',
    clearAll: 'Vaciar todas las medidas',
    deleteMeasure: 'Vaciar la medida',
  },
};
