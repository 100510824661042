import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { subHours } from 'date-fns';

import { CalculationVariable } from '@dametis/core';

export interface PopupState {
  chart: {
    element: Element | null;
    calculation: CalculationVariable | null;
    period: {
      from: Date;
      to: Date;
    };
  };
}

const initialState: PopupState = {
  chart: {
    element: null,
    calculation: null,
    period: {
      from: subHours(new Date(), 1),
      to: new Date(),
    },
  },
};

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    setChartCalculation: (state, action: PayloadAction<CalculationVariable | null>) => {
      state.chart.calculation = action.payload;
    },
    setChartPeriod: (state, action: PayloadAction<{ from: Date; to: Date }>) => {
      state.chart.period = action.payload;
    },
    setChartElement: (state, action: PayloadAction<Element | null>) => {
      Object.assign(state.chart, { element: action.payload });
    },
    clearChartPopup: state => {
      Object.assign(state, { chart: initialState.chart });
    },
    clearPopupStore: () => initialState,
  },
});

export const { setChartCalculation, setChartPeriod, setChartElement, clearChartPopup, clearPopupStore } = popupSlice.actions;

export default popupSlice.reducer;
