import muiShadows, { Shadows } from '@mui/material/styles/shadows';

import { palette } from './palette';

export const shadows: Shadows = [
  'none',
  `0 1px 2px 1px ${palette.grey[800]}30`,
  muiShadows[2],
  muiShadows[3],
  muiShadows[4],
  muiShadows[5],
  muiShadows[6],
  muiShadows[7],
  `0 10px 15px -3px ${palette.grey[800]}40, 0 4px 6px -2px ${palette.grey[800]}60`,
  muiShadows[9],
  muiShadows[10],
  muiShadows[11],
  muiShadows[12],
  muiShadows[13],
  muiShadows[14],
  muiShadows[15],
  muiShadows[16],
  muiShadows[17],
  muiShadows[18],
  muiShadows[19],
  muiShadows[20],
  muiShadows[21],
  muiShadows[22],
  muiShadows[23],
  muiShadows[24],
];
