import { styled } from '@mui/material';
import { HTMLMotionProps, motion } from 'framer-motion';

export const MotionDiv = styled((props: HTMLMotionProps<'div'>) => (
  <motion.div
    animate={{ opacity: 1, translateY: 0 }}
    exit={{ opacity: 0, translateY: -10 }}
    initial={{ opacity: 0, translateY: -10 }}
    transition={{ duration: 0.2 }}
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));
