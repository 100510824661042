import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IncidentInfo, UUID } from '@dametis/core';

import { ClientAlarmInfo } from 'types';

export interface AlarmsState {
  currentAlarm: ClientAlarmInfo | null;
}

const initialState: AlarmsState = {
  currentAlarm: null,
};

export const alarmsSlice = createSlice({
  name: 'alarms',
  initialState,
  reducers: {
    setCurrentAlarm: (state, action: PayloadAction<ClientAlarmInfo | null>) => {
      state.currentAlarm = action.payload;
    },
    setCurrentAlarmIncident: (state, action: PayloadAction<{ alarmUuid: UUID; incidentUuid: UUID; incidentData: IncidentInfo }>) => {
      const { alarmUuid, incidentUuid, incidentData } = action.payload;
      if (state.currentAlarm?.uuid === alarmUuid) {
        state.currentAlarm.incidents = state.currentAlarm.incidents.map(incident =>
          incident.uuid === incidentUuid ? incidentData : incident,
        );
      }
    },
    clearAlarmsStore: () => initialState,
  },
});

export const { setCurrentAlarm, clearAlarmsStore, setCurrentAlarmIncident } = alarmsSlice.actions;

export default alarmsSlice.reducer;
