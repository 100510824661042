import { FC } from 'react';

import UnitPickerBase, { UnitPickerBaseProps } from './UnitPickerBase';
import UnitPickerProvider from './UnitPickerContext';
import { CommonProps } from './types';

export type UnitPickerProps = CommonProps & Partial<UnitPickerBaseProps>;

const emptyObject = {};

const UnitPicker: FC<UnitPickerProps> = ({
  label = false,
  clearable = true,
  editing = true,
  formControlProps = emptyObject,
  inputProps = emptyObject,
  physicalQuantity = undefined,
  ...props
}) => (
  <UnitPickerProvider {...props}>
    <UnitPickerBase
      clearable={clearable}
      editing={editing}
      formControlProps={formControlProps}
      inputProps={inputProps}
      label={label}
      physicalQuantity={physicalQuantity}
    />
  </UnitPickerProvider>
);

export default UnitPicker;
