import { FC, useMemo } from 'react';

import {
  IsStandardBlockMetadataNumber,
  IsStandardBlockMetadataOption,
  IsStandardBlockMetadataString,
  StandardBlockMetadata,
} from '@dametis/core';

import { getFormattedValue } from 'components/UI/UnitPicker/functions/getFormattedValue';
import VariableValue, { VariableValueProps } from 'components/UI/VariableValue/VariableValue';

export interface StandardBlockMetadataValueProps extends VariableValueProps {
  metadata: StandardBlockMetadata;
}

const StandardBlockMetadataValue: FC<StandardBlockMetadataValueProps> = ({ metadata, ...props }) => {
  const value = useMemo(() => {
    if (IsStandardBlockMetadataString(metadata)) {
      return metadata.content.value;
    }
    if (IsStandardBlockMetadataNumber(metadata)) {
      return getFormattedValue({ userUnit: metadata.content.unit ?? undefined, value: metadata.content.value });
    }
    if (IsStandardBlockMetadataOption(metadata)) {
      const selectedOption = metadata.content.availableOptions.find(
        availableOption => availableOption.uuid === metadata.content.selectedOptionId,
      );
      return selectedOption?.value ?? '';
    }
    return '';
  }, [metadata]);

  return <VariableValue {...props}>{value}</VariableValue>;
};

export default StandardBlockMetadataValue;
