import { Grid } from '@mui/material';
import { useCallback } from 'react';

import {
  MetadataType,
  StandardBlockMetadataNumberContent,
  StandardBlockMetadataOptionContent,
  StandardBlockMetadataStringContent,
} from '@dametis/core';

import MetadataHeader from 'components/Lego/UI/BlockType/BlockTypeMetadataPreview/MetadataHeader';
import { MetadataBody } from 'components/Lego/types';

import MetadataNumberContentForm from './MetadataNumberContentForm';
import MetadataOptionContentForm from './MetadataOptionContentForm';
import MetadataStringContentForm from './MetadataStringContentForm';

export interface MetadataFormProps<T extends MetadataBody> {
  value: T;
  onChange: (newValue: T) => void;
}

const MetadataForm = <T extends MetadataBody = MetadataBody>({ value, onChange }: MetadataFormProps<T>) => {
  const handleChangeContent = useCallback(
    (newContent: StandardBlockMetadataStringContent | StandardBlockMetadataNumberContent | StandardBlockMetadataOptionContent) => {
      onChange({ ...value, content: newContent });
    },
    [value, onChange],
  );

  return (
    <>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={7}>
          <MetadataHeader name={value.name} type={value.type} />
        </Grid>
        <Grid item xs={5}>
          {value.type === MetadataType.STRING && (
            <MetadataStringContentForm content={value.content as StandardBlockMetadataStringContent} onChange={handleChangeContent} />
          )}
          {value.type === MetadataType.OPTION && (
            <MetadataOptionContentForm content={value.content as StandardBlockMetadataOptionContent} onChange={handleChangeContent} />
          )}
          {value.type === MetadataType.NUMBER && (
            <MetadataNumberContentForm content={value.content as StandardBlockMetadataNumberContent} onChange={handleChangeContent} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default MetadataForm;
