import { DEFAULT_ERROR } from './errors';

export default {
  button: {
    ok: 'Ok',
    closeAll: 'Tout fermer',
  },
  error: DEFAULT_ERROR,
  errorNoSite: 'Aucun site sélectionné.',
  errorNoGroup: 'Aucun groupe sélectionné.',

  successCreateVariable: 'La variable a bien été créée.',
  successUpdateVariable: 'La variable a bien été modifiée.',
  successUpdateVariables: 'Les variables ont bien été modifiées.',
  successCreateModel: 'Le modèle a bien été créé.',
  successUpdateModel: 'Le modèle a bien été modifié.',
  successDeleteModel: 'Le modèle a bien été supprimé.',
  successCreateVariables: 'Les variables ont bien été créées.',
  successDeleteVariable_one: '{{ count }} variable a bien été supprimée.',
  successDeleteVariable_other: '{{ count }} variables ont bien été supprimées.',
  successCreateVariableList: 'La liste a bien été créée.',
  successEditVariableList: 'La liste a bien été modifiée.',
  successDeleteVariableList: 'La liste a bien été supprimée.',
  successImportData: 'Les données ont bien été importées.',
  successSaveChanges: 'Les modifications ont bien été enregistrées.',
  successCreateNewDevice: "L'équipement a bien été créé.",
  successEditDevice: "L'équipement a bien été modifié.",
  successDeleteDevice: "L'équipement a bien été supprimé.",
  successDisableDevice: "L'équipement a bien été désactivé.",
  successEnableDevice: "L'équipement a bien été réactivé.",
  successCreateSynopticWidget: "L'élément personnalisé a bien été créé.",
  successDeleteSynopticWidget: "L'élément personnalisé a bien été supprimé.",
  successCreateSynoptic: 'Le synoptique a bien été enregistré.',
  successDeleteSynoptic: 'Le synoptique a bien été supprimé.',
  successAddCart: 'La variable a bien été ajoutée au panier',
  successAddCartItems: 'Les variables ont bien été ajoutées au panier',
  successRemoveCart: 'La variable a bien été supprimée du panier',

  errorNotAllowed: 'Opération interdite.',
  errorExport: "Une erreur est survenue lors de l'export des données.",
  errorExportData: "Trop de données à traiter, veuillez réduire l'intervalle de temps et/ou augmenter la sous-période.",
  errorGroupBy: "Trop de données à afficher, veuillez réduire l'intervalle de temps et/ou augmenter la sous-période.",
  errorBoxVersion: 'Liaison avec la box impossible.',
  errorVariableDependants_one: '{{ count }} variable a des dépendances.',
  errorVariableDependants_other: '{{ count }} variables ont des dépendances.',

  warningAddCart: 'La variable a déjà été ajoutée au panier.',
  alreadyInCartSomeItems: 'Certaines variables sont déjà dans le panier.',
  alreadyInCartAllItems: 'Toutes les variables sont déjà dans le panier',

  successCreateBatch: 'Le batch a bien été créé.',
  successDeleteBatch: 'Le batch a bien été supprimé.',

  successCreateProject: 'Le projet a bien été créé.',
  successDeleteProject: 'Le projet a bien été supprimé.',
  successCreateTask: 'La tâche a bien été créée.',
  successUploadAsset: 'Le document a bien été importé.',
  successDownloadAsset: 'Le document a bien été téléchargé.',

  successCreatePlayground: 'Le playground a bien été enregistré.',
  successDuplicatePlayground: 'Le playground a bien été dupliqué.',
  successDeletePlayground: 'Le playground a bien été supprimé.',

  errorTime: "L'horloge de votre système ne semble pas être à l'heure.\nCela peut avoir un impact sur l'affichage des données.",
  errorBrowser: 'La version de votre navigateur est trop ancienne.\nVotre experience de navigation peut être sévèrement impactée.',

  successCreateContract: 'Le contrat a bien été créé.',
  successDeleteContract: 'Le contrat a bien été supprimé.',
  successCreateBill: 'La facture a bien été créée.',
  successDeleteBill: 'La facture a bien été supprimée.',
  defaultGridViewChanged: 'La vue par défaut a changé.',

  newVersion: 'Une nouvelle version est disponible !',
  reload: 'Rafraîchir',

  successUserUpdate: "L'utilisateur a bien été mis à jour.",
  successUserCreate: "L'utilisateur a bien été créé.",
  errorUserUpdate: "L'utilisateur n'a pas pu être mis à jour.",
  errorUserCreate: "L'utilisateur n'a pas pu être créé.",
  userAlreadyExists: 'Cette adresse email est déjà associée à un compte.',

  successUserDelete: "L'utilisateur a bien été supprimé.",
  errorUserDelete: "L'utilisateur n'a pas pu être supprimé.",

  successBoxUpdate: 'La box a bien été mis à jour.',
  successBoxCreate: 'La box a bien été créé.',
  successBoxSetup: 'La box a bien été configurée.',
  errorBoxUpdate: "La box n'a pas pu être mis à jour.",
  errorBoxCreate: "La box n'a pas pu être créé.",
  errorBoxSetup: "La box n'a pas pu être configurée.",

  successGroupUpdate: 'Le groupe a bien été mis à jour.',
  successGroupCreate: 'Le groupe a bien été créé.',
  errorGroupUpdate: "Le groupe n'a pas pu être mis à jour.",
  errorGroupCreate: "Le groupe n'a pas pu être créé.",

  successSiteUpdate: 'Le site a bien été mis à jour.',
  successSiteCreate: 'Le site a bien été créé.',
  errorSiteUpdate: "Le site n'a pas pu être mis à jour.",
  errorSiteCreate: "Le site n'a pas pu être créé.",

  successSendInvitationEmailAgain: "Un email d'invitation vient d'être envoyé.",
  errorSendInvitationEmailAgain: 'Une erreur est survenue.',

  successCreateComment: 'Le commentaire a bien été créé.',
  successUpdateComment: 'Le commentaire a bien été modifié.',
  successDeleteComment: 'Le commentaire a bien été supprimé.',
  writeChartComment: 'Cliquez sur le graphique pour écrire un commentaire.',
  noCommentForCalculation: 'Vous ne pouvez pas laisser de commentaire sur un calcul.',

  successDeploy: "L'API est en cours de déploiement.",
  errorDeploy: 'Une erreur est survenue.',

  successClearCache: 'Le cache API a été vidé.',
  errorClearCache: 'Une erreur est survenue.',

  successSyncConsul: 'Les groupes ont été synchronisés dans Consul.',
  errorSyncConsul: 'Une erreur est survenue.',

  successPing: 'Pong !',
  errorPing: 'Une erreur est survenue.',

  successCleanFileImportFiles: '{{ fileCount }} fichiers supprimés avec succès.',
  errorCleanFileImportFiles: 'Une erreur est survenue.',

  successCreateProjectTemplate: 'Le modèle de projets a bien été créé.',
  successDeleteProjectTemplate: 'Le modèle de projets a bien été supprimé.',

  successArmageddon: 'Optimisation et recalcul en cours... Soyez patient',
  successDamcoArmageddon: 'Les données de monitoring ont été supprimées',
  errorArmageddon: 'Une erreur est survenue.',

  successSyncConfigBox: 'La box a bien été synchronisée.',
  waitingSyncConfigBox: 'Tentative de synchronisation de la box...',

  entityKind: {
    playground: 'le playground',
    variable: 'la variable',
    report: 'le rapport',
    synoptic: 'le synoptique',
    alarm: "l'alarme",
    project: 'le project',
  },
  sharedWithYou: '{{firstName}} {{lastName}} vous a partagé $t(toast:entityKind.{{kind}}) « {{name}} »',
  assignedYouTask: '{{firstName}} {{lastName}} vous a assigné une tâche dans $t(toast:entityKind.{{kind}}) « {{name}} »',
  assignedYouAlarm: '{{firstName}} {{lastName}} vous a assigné à $t(toast:entityKind.{{kind}}) « {{name}} »',
  acknowledgedAlarmAndMentionnedYou: '{{firstName}} {{lastName}} vous a mentionné en acquittant $t(toast:entityKind.{{kind}}) « {{name}} »',
  mentionnedYou: '{{firstName}} {{lastName}} vous a mentionné dans un commentaire sur $t(toast:entityKind.{{kind}}) « {{name}} »',
  openToast: 'Ouvrir',
  seeToast: 'Voir',

  accountCreated: 'Votre compte a été créé avec succès, bienvenue sur MyDametis !',
  warningMustConfigureTwoFA: 'La double authentification est requise sur ce site. Vous pouvez la paramétrer dans Mon compte.',

  successDeleteTask: 'La tâche a bien été supprimée.',
  notExistingTemplate: 'Une erreur est survenue.',
  successUploadImage: "L'image' a bien été importée.",
  successDeleteBlock: 'Le bloc a bien été supprimé',

  successCreateUserList: "La liste d'utilisateurs a bien été créée.",
  successUpdateUserList: "La liste d'utilisateurs a bien été mise à jour.",
  successDeleteUserList: "La liste d'utilisateurs a bien été supprimée.",
};
