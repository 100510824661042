import { ShortcutCategory, WidgetContentShortcutsAppearance } from '@dametis/core';

const name: Record<ShortcutCategory, string> = {
  [ShortcutCategory.SYNOPTIC]: 'Synoptiques',
  [ShortcutCategory.ALARM]: 'Alarmes',
  [ShortcutCategory.REPORT]: 'Rapports',
  [ShortcutCategory.PLAYGROUND]: 'Playgrounds',
  [ShortcutCategory.PROJECT]: 'Projets',
  [ShortcutCategory.TASK]: 'Tâches',
  [ShortcutCategory.COMMENT]: 'Commentaires',
  [ShortcutCategory.SITE]: 'Sites',
  [ShortcutCategory.ASSET]: 'Documents',
  [ShortcutCategory.VARIABLE]: 'Variables',
  [ShortcutCategory.HREF]: 'URL',
  [ShortcutCategory.BLOCK]: 'Blocs',
  [ShortcutCategory.BLOCK_TYPE]: 'Types de blocs',
  [ShortcutCategory.STANDARD_BLOCK]: 'Blocs standards',
  [ShortcutCategory.MACRO]: 'Macros',
  [ShortcutCategory.CART]: 'Cart',
  [ShortcutCategory.CONTRACT]: 'Contrats',
  [ShortcutCategory.BILL]: 'Factures',
  [ShortcutCategory.BATCH]: 'Batchs',
  [ShortcutCategory.MODEL]: 'Modèles',
};

const nameOne: Record<`${ShortcutCategory}_one`, string> = {
  synoptics_one: 'synoptique',
  alarms_one: 'alarme',
  reports_one: 'rapport',
  playgrounds_one: 'playground',
  projects_one: 'projet',
  tasks_one: 'tâche',
  comments_one: 'commentaire',
  sites_one: 'site',
  assets_one: 'document',
  variable_one: 'variable',
  href_one: 'URL',
  blocks_one: 'bloc',
  blockTypes_one: 'type de bloc',
  standardBlocks_one: 'bloc standard',
  macros_one: 'macro',
  cart_one: 'panier',
  contracts_one: 'contrat',
  bills_one: 'facture',
  batches_one: 'batch',
  models_one: 'modèle',
};

const nameAOne: Record<`${ShortcutCategory}_a_one`, string> = {
  synoptics_a_one: 'un $t(shortcut:name.synoptics_one)',
  alarms_a_one: 'une $t(shortcut:name.alarms_one)',
  reports_a_one: 'un $t(shortcut:name.reports_one)',
  playgrounds_a_one: 'un $t(shortcut:name.playgrounds_one)',
  projects_a_one: 'un $t(shortcut:name.projects_one)',
  tasks_a_one: 'une $t(shortcut:name.tasks_one)',
  comments_a_one: 'un $t(shortcut:name.comments_one)',
  sites_a_one: 'un $t(shortcut:name.sites_one)',
  assets_a_one: 'un $t(shortcut:name.assets_one)',
  variable_a_one: 'une $t(shortcut:name.variable_one)',
  href_a_one: 'une $t(shortcut:name.href_one)',
  blocks_a_one: 'un $t(shortcut:name.blocks_one)',
  blockTypes_a_one: 'un $t(shortcut:name.blockTypes_one)',
  standardBlocks_a_one: 'un $t(shortcut:name.standardBlocks_one)',
  macros_a_one: 'une $t(shortcut:name.macros_one)',
  cart_a_one: 'le $t(shortcut:name.cart_one)',
  contracts_a_one: 'un $t(shortcut:name.contracts_one)',
  bills_a_one: 'une $t(shortcut:name.bills_one)',
  batches_a_one: 'un $t(shortcut:name.batches_one)',
  models_a_one: 'un $t(shortcut:name.models_one)',
};

const nameThisOne: Record<`${ShortcutCategory}_this_one`, string> = {
  synoptics_this_one: 'ce $t(shortcut:name.synoptics_one)',
  alarms_this_one: 'cette $t(shortcut:name.alarms_one)',
  reports_this_one: 'ce $t(shortcut:name.reports_one)',
  playgrounds_this_one: 'ce $t(shortcut:name.playgrounds_one)',
  projects_this_one: 'ce $t(shortcut:name.projects_one)',
  tasks_this_one: 'cette $t(shortcut:name.tasks_one)',
  comments_this_one: 'ce $t(shortcut:name.comments_one)',
  sites_this_one: 'ce $t(shortcut:name.sites_one)',
  assets_this_one: 'ce $t(shortcut:name.assets_one)',
  variable_this_one: 'cette $t(shortcut:name.variable_one)',
  href_this_one: 'cette $t(shortcut:name.href_one)',
  blocks_this_one: 'ce $t(shortcut:name.blocks_one)',
  blockTypes_this_one: 'ce $t(shortcut:name.blockTypes_one)',
  standardBlocks_this_one: 'ce $t(shortcut:name.standardBlocks_one)',
  macros_this_one: 'cette $t(shortcut:name.macros_one)',
  cart_this_one: 'le $t(shortcut:name.cart_one)',
  contracts_this_one: 'ce $t(shortcut:name.contracts_one)',
  bills_this_one: 'ce $t(shortcut:name.bills_one)',
  batches_this_one: 'ce $t(shortcut:name.batches_one)',
  models_this_one: 'ce $t(shortcut:name.models_one)',
};

export default {
  name: {
    ...name,
    ...nameOne,
    ...nameAOne,
    ...nameThisOne,
  },
  text: {
    shortcut_one: 'Raccourci',
    shortcut_other: 'Raccourcis',
    noShortcut: 'Aucun raccourci défini',
    brokenLink: 'Lien non-fonctionnel',
    noElement: 'Aucun élément',
    shortcutCategory: 'Catégorie du raccourci',
    notFound: 'Élément introuvable',
  },
  tooltip: {
    synoptics: 'Synoptiques',
    alarms: 'Alarmes',
    reports: 'Rapports',
    playgrounds: 'Playgrounds',
    projects: 'Projets',
    selectShortcut: 'Sélectionner un raccourci',
    removeShortcut: 'Retirer le raccourci',
    [`shortcutCategory_${ShortcutCategory.ALARM}`]: 'Alarme',
    [`shortcutCategory_${ShortcutCategory.HREF}`]: 'Personnalisé',
    [`shortcutCategory_${ShortcutCategory.PLAYGROUND}`]: 'Playground',
    [`shortcutCategory_${ShortcutCategory.PROJECT}`]: 'Projet',
    [`shortcutCategory_${ShortcutCategory.REPORT}`]: 'Rapport',
    [`shortcutCategory_${ShortcutCategory.SYNOPTIC}`]: 'Synoptique',
  },
  label: {
    link: 'Lien',
    customize: 'Customiser',
    tabs: 'Onglets raccourci',
    text: 'Format du texte',
    bold: 'Gras',
    italic: 'Italique',
    underlined: 'Souligné',
    delete: 'Supprimer',
    save: 'sauvegarder',
    cancel: 'annuler',
    selectPeriod: 'Sélectionnez une période',
    noPeriod: 'Aucune période sélectionnée',
    setPeriod: 'Définir une période',
    absolute: 'Absolue',
    relative: 'Relative',
    date: 'Date',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    presets: 'Présélections',
    periodType: 'Type de période',
    invalidPeriod: 'Période invalide',
    label: 'Titre',
    url: 'Url',
  },
  placeholder: {
    label: 'Nom',
    url: 'https://...',
    search: 'Rechercher un raccourci',
  },
  period: {
    relativeWarning: 'La période sélectionnée est relative à la date du jour',
    none: 'Aucune',
    custom: 'Personnalisée',
    reset: 'Réinitialiser',
    relative: 'Relative',
    lastMonth: 'Dernier mois',
    lastWeek: 'Dernière semaine',
    thisMonth: 'Mois en cours',
    thisWeek: 'Semaine en cours',
    since: 'Depuis',
    last_month_start: 'Début du mois dernier',
    last_month_end: 'Fin du mois dernier',
    last_week_start: 'Début de la semaine dernière',
    last_week_end: 'Fin de la semaine dernière',
    this_week: 'Début de la semaine',
    this_month: 'Début du mois',
    now: 'Maintenant',
    fullnow: 'Maintenant (plein)',
  },
  unit: {
    m_one: 'minute',
    m_other: 'minutes',
    h_one: 'heure',
    h_other: 'heures',
    d_one: 'jour',
    d_other: 'jours',
    w_one: 'semaine',
    w_other: 'semaines',
    mo_one: 'mois',
    mo_other: 'mois',
    y_one: 'année',
    y_other: 'années',
  },
  alert: {
    selectRelativePeriod: 'Cette période sera relative à maintenant',
    nonePeriod: 'Aucune période sélectionnée',
  },
  fullPeriod: {
    format: 'Format',
    label: 'Périodes pleines',
    helper: 'Exemple : les périodes pleines d’une semaine seront toujours du lundi au dimanche.',
  },
  toast: {
    periodReset: 'La période a été réinitialisée car elle était invalide.',
  },
  button: {
    cancel: 'Annuler',
    validate: 'Valider',
    addShortcut: 'Ajouter',
    addEntityShortcut: 'Ajouter un raccourci standard',
    addHrefShortcut: 'Ajouter un raccourci personnalisé',
    selectShortcut: 'Sélectionner',
    selectEntityShortcut: 'Sélectionner un raccourci standard',
    selectHrefShortcut: 'Sélectionner un raccourci personnalisé',
  },
  title: {
    customShortcut: 'Raccourci personnalisé',
  },
  select: {
    [`appearance_${WidgetContentShortcutsAppearance.CHIP}`]: 'Compact',
    [`appearance_${WidgetContentShortcutsAppearance.EXPANDED_TILE}`]: 'Étendu',
    [`appearance_${WidgetContentShortcutsAppearance.COLLAPSED_TILE}`]: 'Réduit',
  },
};
