import { TFunction } from 'i18next';

import { FindFolder, FindFolderPath, FolderInfo, ShortcutCategory, UUID } from '@dametis/core';

export const getBlockPath = (rootBlockFolder: FolderInfo | null, blockId: UUID, t: TFunction): string => {
  if (!rootBlockFolder) {
    return '';
  }
  const rootFolderWithName = { ...rootBlockFolder, name: t('lego:text.home') };
  const parentFolder = FindFolder(
    rootFolderWithName,
    folder => folder.shortcuts.find(shortcut => shortcut.category === ShortcutCategory.BLOCK && shortcut.uuid === blockId) !== undefined,
  );
  if (!parentFolder) {
    return '';
  }
  try {
    const path = FindFolderPath(rootFolderWithName, parentFolder);
    return path.map(folder => folder.name).join(' › ');
  } catch (err) {
    return '';
  }
};
