import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { setColorLightness } from './color';

const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const getHighlightedText = (text: string, search: string): ReactNode =>
  text.split(new RegExp(`(${escapeRegExp(search)})`, 'gi')).map(part => (
    <Box
      key={uuidv4()}
      component="span"
      sx={theme =>
        part.toLowerCase() === search.trim().toLowerCase() ? { backgroundColor: setColorLightness(theme.palette.picker.yellow, 80) } : {}
      }
    >
      {part}
    </Box>
  ));

export default getHighlightedText;
