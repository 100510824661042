import i18next from 'i18next';
import { AnyAction } from 'redux';

import { BatchVariable, CalculationVariable, VarCalc } from '@dametis/core';
import { mathJs, mathJsTypes } from '@dametis/mathjs';

import store from 'store';
import { fetchBatches } from 'store/actions/batch';
import { BatchFilterQuery } from 'store/slices/batch';

export const apiQueryToBatch = (query: CalculationVariable): BatchFilterQuery[] => {
  const { flattenBatches } = store.getState().batch;
  if (!flattenBatches) store.dispatch(fetchBatches() as unknown as AnyAction);
  const parseExpression = (exp: mathJsTypes.MathNode, vars: Record<string, BatchVariable>, arr: BatchFilterQuery[]) => {
    if (mathJs.isOperatorNode(exp)) {
      parseExpression(exp.args[0], vars, arr);
      arr.push({ type: 'operator', content: exp.op });
      parseExpression(exp.args[1], vars, arr);
    } else if (mathJs.isSymbolNode(exp)) {
      const batch = flattenBatches?.find(b => b.uuid === vars[exp.name]?.batchUuid);
      if (batch) {
        arr.push({ type: 'batch', content: batch });
      }
    }
  };
  const newQuery: BatchFilterQuery[] = [];
  if (query.exp !== undefined) {
    parseExpression(mathJs.parse(query.exp), query.vars as Record<string, BatchVariable>, newQuery);
  }
  return newQuery;
};

export const apiQueryToString = (query: CalculationVariable): string => {
  const { flattenBatches } = store.getState().batch;
  if (!flattenBatches) store.dispatch(fetchBatches() as unknown as AnyAction);
  const parseExpression = (exp: mathJsTypes.MathNode, vars: Record<string, VarCalc>, arr: string[]) => {
    if (mathJs.isOperatorNode(exp)) {
      parseExpression(exp.args[0], vars, arr);
      arr.push(exp.op);
      parseExpression(exp.args[1], vars, arr);
    } else if (mathJs.isSymbolNode(exp)) {
      const batch = flattenBatches?.find(b => b.uuid === (vars[exp.name] as BatchVariable).batchUuid);
      if (batch) {
        arr.push(batch.name);
      }
    }
  };
  const newQuery: string[] = [];
  if (query.exp && query.vars) {
    parseExpression(mathJs.parse(query.exp), query.vars, newQuery);
  }
  return newQuery.join(' ') || i18next.t('global:value.unknownBatch');
};
