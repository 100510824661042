import { Editor, Node, Transforms } from 'slate';

import { FunctionElement, Symbols } from '@dametis/core';

import { mathFunctions } from 'config/functions';

import { createSymbolElement } from './symbol';
import { createEmptyText, createText } from './text';

export const createFunctionElement = (fn: FunctionElement['fn']): FunctionElement => ({
  type: 'function',
  fn,
  children: [createEmptyText()],
});

export const insertFunction = (editor: Editor, fn: FunctionElement['fn']) => {
  const mathFunction = mathFunctions[fn];
  const functionElement = createFunctionElement(fn);
  const commas = mathFunction.inputs.reduce<Node[]>((acc, cur, index) => {
    acc.push(createText(''));
    if (index < mathFunction.inputs.length - 1) {
      acc.push(createSymbolElement(Symbols.COMMA));
    }
    return acc;
  }, []);
  Transforms.insertNodes(editor, [functionElement, ...commas, createText(''), createSymbolElement(Symbols.RIGHT_PARENTHESIS)]);
};
