import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BoxInfo, ShortDeviceInfo } from '@dametis/core';

export interface BoxesState {
  list: BoxInfo[];
  allIds: string[];
  devices: ShortDeviceInfo[];
  maxLoopTime: number;
}

export const initialBoxesState: BoxesState = {
  list: [],
  allIds: [],
  devices: [],
  maxLoopTime: 30000,
};

export const boxesSlice = createSlice({
  name: 'boxes',
  initialState: initialBoxesState,
  reducers: {
    setBoxesData: (state, action: PayloadAction<BoxesState>) => action.payload,
    setBoxesList: (state, action: PayloadAction<BoxInfo[]>) => {
      state.list = action.payload;
    },
    clearStore: () => initialBoxesState,
  },
});

export const { setBoxesData, setBoxesList, clearStore } = boxesSlice.actions;

export default boxesSlice.reducer;
