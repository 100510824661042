import { CloseFullscreenOutlined, OpenInFullOutlined } from '@mui/icons-material';
import { FormLabel, IconButton, OutlinedInputProps, Stack, Tooltip } from '@mui/material';
import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  label: OutlinedInputProps['label'];
  popperOpen: boolean;
  setPopperOpen: Dispatch<SetStateAction<boolean>>;
  horizontal: 'left' | 'right';
  isEmptyCalculation: boolean;
}

const Label: FC<Props> = ({ label, popperOpen, setPopperOpen, horizontal, isEmptyCalculation }) => {
  const { t } = useTranslation('vnc');

  const togglePopper = useCallback(() => {
    setPopperOpen(o => !o);
  }, [setPopperOpen]);

  return (
    <Stack direction="row" height={theme => theme.spacing(3)} justifyContent="space-between" spacing={1}>
      <FormLabel>{label ?? t('input.label.data')}</FormLabel>
      {!isEmptyCalculation && (
        <div>
          <Tooltip placement="top" title={t(popperOpen ? 'tooltip.closePopper' : 'tooltip.openPopper')}>
            <IconButton className="popperButton" size="small" sx={{ visibility: popperOpen ? 'visible' : 'hidden' }} onClick={togglePopper}>
              {popperOpen ? (
                <CloseFullscreenOutlined fontSize="small" sx={[horizontal === 'left' && { transform: 'rotate(90deg)' }]} />
              ) : (
                <OpenInFullOutlined fontSize="small" sx={[horizontal === 'left' && { transform: 'rotate(90deg)' }]} />
              )}
            </IconButton>
          </Tooltip>
        </div>
      )}
    </Stack>
  );
};

export default Label;
