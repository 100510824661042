import { StyleFormat, TextAlign } from '@dametis/core';

import { EntityType } from 'types/comment';

export default {
  placeholder: {
    leaveAComment: 'Écrivez un commentaire...',
    placeholderGeneral: 'Écrivez quelque chose...',
  },
  text: {
    fontSize: {
      small: 'Petite',
      medium: 'Moyenne',
      large: 'Grande',
      auto: 'Auto',
      mixed: 'Mixte',
    },
  },
  label: {
    [StyleFormat.BOLD]: 'Gras',
    [StyleFormat.ITALIC]: 'Italique',
    [StyleFormat.UNDERLINE]: 'Souligné',
    [TextAlign.LEFT]: 'Alignement à gauche',
    [TextAlign.CENTER]: 'Alignement au centre',
    [TextAlign.RIGHT]: 'Alignement à droite',
    entity: {
      [EntityType.ALARM]: 'alarme',
      [EntityType.PLAYGROUND]: 'playground',
      [EntityType.REPORT]: 'rapport',
      [EntityType.PROJECT]: 'projet',
      [EntityType.SYNOPTIC]: 'synoptique',
      [EntityType.VARIABLE]: 'variable',
      [EntityType.TASK]: 'tâche',
    },
  },
  button: {
    submit: 'Valider',
    send: 'Envoyer',
    cancel: 'Annuler',
  },
  tooltip: {
    fontColor: 'Couleur texte',
  },
};
