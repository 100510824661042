import { useTabContext } from '@mui/lab';
import { Tab, TabProps, styled } from '@mui/material';
import { FC, useMemo } from 'react';

export const StyledIconTab = styled(Tab, {
  shouldForwardProp: propName => propName !== 'isExtendedDrawer' && propName !== 'isActive',
})<{ isActive: boolean }>(({ isActive, theme }) => ({
  padding: `0 ${theme.spacing(1)}`,
  minWidth: 0,
  fontSize: 11,
  lineHeight: 1,
  fontWeight: isActive ? 700 : 500,
  letterSpacing: 0.5,
  textTransform: 'capitalize',
}));

export interface IconTabProps extends TabProps {
  icon: Required<TabProps['icon']>;
  activeIcon: TabProps['icon'];
  isActive?: boolean;
}

const IconTab: FC<IconTabProps> = ({ value, icon, activeIcon, isActive: userIsActive = undefined, ...props }) => {
  const tabContext = useTabContext();

  const isActive = useMemo(
    () => (userIsActive !== undefined ? userIsActive : tabContext?.value === value),
    [userIsActive, tabContext?.value, value],
  );

  return <StyledIconTab icon={isActive ? activeIcon : icon} isActive={isActive} value={value} {...props} />;
};

export default IconTab;
