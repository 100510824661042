export default {
  title: {
    editData: 'EditData',
  },
  button: {
    refresh: 'Refresh',
    send: 'Send',
    details: 'Details',
    history: 'History',
    poiontHistory: 'Point History',
    revert: 'Revert',
    retry: 'Retry',
    close: 'Close',
  },
  label: {
    totalSize: 'Total Size',
    editMode: 'Edit Mode',
    variableKind: 'Variable Kind',
    minValue: 'Minimum Value',
    maxValue: 'Maximum Value',
    limit: 'Limit',
    source: 'Source',
  },
  toast: {
    RevertOperationSuccess: 'Revert Operation Success',
    RevertOperationError: 'Revert Operation Error',
    RetryOperationSuccess: 'Retry Operation Success',
    RetryOperationError: 'Retry Operation Error',
    EditDataSuccess: 'Edit Data Success',
    EditDataError: 'Edit Data Error',
    DeleteDataSuccess: 'Delete Data Success',
    DeleteDataError: 'Delete Data Error',
  },
};
