import { LicenseInfo } from '@mui/x-license-pro';
import DOMPurify from 'dompurify';
import { createRoot } from 'react-dom/client';

import { i18nInit } from 'localization';

import App from './App';
import { matomoInit } from './functions/matomo';

import './modules';

if ((module as any).hot) {
  (module as any).hot.accept('./App.tsx');
  (module as any).hot.accept('./localization');
}

matomoInit();
LicenseInfo.setLicenseKey(process.env.XGRID_LICENSE_KEY!);
DOMPurify.setConfig({ ALLOWED_TAGS: [] });

await i18nInit;

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<App />);
