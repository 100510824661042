import { Components, Theme, buttonClasses, darken, svgIconClasses } from '@mui/material';
import { lightBlue } from '@mui/material/colors';

import { setColorLightness, setColorSaturation } from '../../functions/color';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true;
    icon: true;
    link: true;
  }
}

export default {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    contained: ({ theme, ownerState: { color } }) => ({
      ...(color === 'link'
        ? {
            color: lightBlue[600],
            backgroundColor: `${lightBlue[50]} !important`,
            '&:hover': {
              backgroundColor: `${darken(lightBlue[50], 0.025)} !important`,
            },
          }
        : {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey[300],
            '&:hover': {
              backgroundColor: theme.palette.grey[400],
            },
          }),
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.grey[500],
        backgroundColor: theme.palette.grey[100],
      },
    }),
    containedPrimary: ({ theme }) => ({
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.white,
        backgroundColor: setColorLightness(setColorSaturation(theme.palette.primary.main, 40), 85),
      },
    }),
    containedSecondary: ({ theme }) => ({
      color: theme.palette.white,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.white,
        backgroundColor: setColorLightness(setColorSaturation(theme.palette.secondary.main, 50), 85),
      },
    }),
    containedSuccess: ({ theme }) => ({
      color: theme.palette.white,
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.light,
      },
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.white,
        backgroundColor: setColorLightness(setColorSaturation(theme.palette.success.main, 50), 85),
      },
    }),
    containedError: ({ theme }) => ({
      color: theme.palette.white,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.light,
      },
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.white,
        backgroundColor: setColorLightness(setColorSaturation(theme.palette.error.main, 50), 85),
      },
    }),
    text: ({ theme, ownerState: { color } }) => ({
      ...(color === 'link'
        ? {
            color: lightBlue[600],
            backgroundColor: `${lightBlue[50]} !important`,
            '&:hover': {
              backgroundColor: `${darken(lightBlue[50], 0.025)} !important`,
            },
          }
        : {}),
      ...(color === 'icon'
        ? {
            color: theme.palette.icon.main,
          }
        : {}),
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.grey[500],
      },
    }),
    textSecondary: ({ theme }) => ({
      color: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: `${theme.palette.secondary.main}11`,
      },
    }),
    outlined: ({ theme }) => ({
      color: theme.palette.primary.main,
      borderWidth: 2,
      borderColor: theme.palette.grey[300],
      '&:hover': {
        borderColor: theme.palette.grey[400],
        '&:hover': {
          backgroundColor: `${theme.palette.grey[200]}80`,
        },
      },
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.grey[500],
        borderColor: `${theme.palette.grey[500]}80`,
      },
    }),
    outlinedPrimary: {
      borderWidth: 2,
      '&:hover': {
        borderWidth: 2,
      },
    },
    outlinedSecondary: ({ theme }) => ({
      color: theme.palette.secondary.main,
      borderWidth: 2,
      borderColor: `${theme.palette.secondary.main}80`,
      '&:hover': {
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        backgroundColor: `${theme.palette.secondary.main}11`,
      },
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.grey[500],
        borderColor: `${theme.palette.grey[500]}80`,
      },
    }),
    sizeSmall: {
      [`& .${svgIconClasses.root}`]: {
        fontSize: '1.25rem',
      },
    },
    sizeLarge: {
      [`& .${svgIconClasses.root}`]: {
        fontSize: '2.1875rem',
      },
    },
  },
} as Components<Theme>['MuiButton'];
