import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FindFolder, FolderInfo, ShortStandardBlockInfo, Shortcut, ShortcutCategory } from '@dametis/core';

import FolderExplorer from 'components/Lego/UI/FolderExplorer/FolderExplorer';
import { ExplorerItem, IsExplorerFolder } from 'components/Lego/UI/FolderExplorer/types';
import { useDispatch } from 'store';
import { useReadGlobalRootFolderQuery, useUpdateGlobalFolderMutation } from 'store/api/globalFolders';
import { useMoveStandardBlockMutation } from 'store/api/standardBlocks';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

export interface MoveToStandardBlockModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  standardBlock: ShortStandardBlockInfo;
}

const MoveToStandardBlockModal: FC<MoveToStandardBlockModalProps> = ({ isOpen, setIsOpen, standardBlock }) => {
  const { t } = useTranslation('lego');
  const dispatch = useDispatch();

  const { data: globalRootFolder = null } = useReadGlobalRootFolderQuery();
  const [moveStandardBlock, { isLoading: isMovingStandardBlock }] = useMoveStandardBlockMutation();
  const [updateGlobalFolder, { isLoading: isUpdatingFolder }] = useUpdateGlobalFolderMutation();

  const [targetFolder, setTargetFolder] = useState<FolderInfo | null>(null);

  const isLoading = useMemo(() => isMovingStandardBlock || isUpdatingFolder, [isMovingStandardBlock, isUpdatingFolder]);

  const parsedGlobalRootFolder: FolderInfo | null = useMemo(
    () => (globalRootFolder ? { ...globalRootFolder, name: t('lego:text.factory') } : null),
    [globalRootFolder, t],
  );

  const sourceFolder = useMemo(
    () =>
      globalRootFolder
        ? FindFolder(
            globalRootFolder,
            folder =>
              folder.shortcuts.find(
                shortcut => shortcut.category === ShortcutCategory.STANDARD_BLOCK && shortcut.uuid === standardBlock?.uuid,
              ) !== undefined,
          )
        : undefined,
    [globalRootFolder, standardBlock],
  );

  const isValidTarget = useMemo(
    () => targetFolder !== null && targetFolder.uuid !== sourceFolder?.uuid,
    [sourceFolder?.uuid, targetFolder],
  );

  const isItemDisabled = useCallback((item: ExplorerItem) => !IsExplorerFolder(item), []);

  const handleSelectFolder = useCallback((newTarget: ExplorerItem) => {
    if (IsExplorerFolder(newTarget)) {
      setTargetFolder(newTarget.content);
    }
  }, []);

  const handleMoveTo = useCallback(async () => {
    if (!targetFolder) {
      return;
    }
    try {
      if (sourceFolder) {
        await moveStandardBlock({
          standardBlockId: standardBlock.uuid,
          sourceId: sourceFolder.uuid,
          targetId: targetFolder.uuid,
        }).unwrap();
      } else {
        const newShortcuts: Shortcut[] = [
          ...targetFolder.shortcuts,
          { category: ShortcutCategory.STANDARD_BLOCK, uuid: standardBlock.uuid },
        ];
        await updateGlobalFolder({ uuid: targetFolder.uuid, body: { shortcuts: newShortcuts } });
      }
      setIsOpen(false);
      dispatch(addToast({ message: t('toast.moveStandardBlockSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, moveStandardBlock, updateGlobalFolder, setIsOpen, sourceFolder, t, targetFolder, standardBlock?.uuid]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <DialogTitle>{t('title.moveTo')}</DialogTitle>
      <DialogContent sx={{ pl: 0, pr: 0, pb: 0 }}>
        {parsedGlobalRootFolder && (
          <FolderExplorer
            displayedShortcut={[ShortcutCategory.STANDARD_BLOCK]}
            isItemDisabled={isItemDisabled}
            rootFolder={parsedGlobalRootFolder}
            onChange={handleSelectFolder}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={isLoading} variant="text" onClick={handleClose}>
          {t('button.cancel')}
        </Button>
        <LoadingButton
          color="secondary"
          disabled={isLoading || !isValidTarget}
          loading={isLoading}
          variant="contained"
          onClick={handleMoveTo}
        >
          {t('button.move')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default MoveToStandardBlockModal;
