export default {
  toast: {
    errorUploadingPreview: 'Error while file upload.',
    errorReadingFilePreview: 'Unable to read file.',
    errorFileTypePreview: 'Bad file type.',
    errorDeletingPreview: 'Error while file deletion.',
  },
  tooltip: {
    editPhoto: 'Change image',
    deletePhoto: 'Delete image',
  },
};
