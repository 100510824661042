import type { SeriesColumnOptions, SeriesOptionsType } from 'highcharts';
import ColumnSeries from 'highcharts/es-modules/Series/Column/ColumnSeries';
import { v4 as uuidv4 } from 'uuid';

import { AnyGroupBy, Period, TadaApiResponse, UUID } from '@dametis/core';

import { theme } from 'theme';

import { DaAxis } from './DaAxis';
import { DaChart } from './DaChart';
import { ISeriesType } from './types';

interface Props {
  color?: string;
  name?: string;
  unit?: string;
  style?: {
    type?: ISeriesType;
    width?: number;
  };
  pretty?: boolean;
  yAxis?: DaAxis;
  uuid?: UUID;
  hidden?: boolean;
  yAxisHidden?: boolean;
}

export default class DaBarSeries extends ColumnSeries {
  period: Period | undefined;

  groupBy: AnyGroupBy | undefined;

  declare chart: DaChart;

  declare yAxis: DaAxis;

  uuid: UUID;

  public constructor(
    chart: DaChart,
    { color, name, unit, pretty = false, yAxis, uuid, hidden = false, yAxisHidden = false, ...props }: Props = {},
  ) {
    const options: SeriesColumnOptions = {
      ...props,
      name,
      id: uuidv4(),
      visible: !hidden,
      color,
      type: 'column',
      tooltip: {
        valueSuffix: unit,
      },
      borderRadius: 2,
      states: {
        inactive: {
          opacity: 1,
        },
      },
    };
    let yAxis2 = yAxis;
    if (yAxis2 === undefined) {
      yAxis2 = new DaAxis(chart, { color, unit, pretty, hidden: yAxisHidden });
    }
    options.yAxis = yAxis2.options.id;
    super();
    super.init(chart, options);
    this.uuid = uuid;
  }

  setColor(color: string, changeAxis = true): void {
    this.update({ color } as SeriesOptionsType);
    if (changeAxis) {
      this.yAxis.setColor(color);
    }
  }

  // setStyle({ type, width: lineWidth }: { type: string; width: number } = { type: 'line', width: 1 }): void {
  //   if (type === 'line') {
  //     this.update({ type, dashStyle: 'Solid', fillOpacity: 1 } as SeriesOptionsType);
  //   } else if (type === 'areaspline') {
  //     this.update({ type, dashStyle: 'Solid', fillOpacity: 0.2 } as SeriesOptionsType);
  //   } else if (type === 'dots') {
  //     this.update({ type: 'line', dashStyle: 'Dash' } as SeriesOptionsType);
  //   } else if (type === 'bar') {
  //     this.update({ type, dashStyle: 'Solid', fillOpacity: 1 } as SeriesOptionsType);
  //   }
  //   this.update({ lineWidth, marker: { radius: lineWidth + 1 } } as SeriesOptionsType);
  // }

  update(options: SeriesOptionsType): void {
    const { uuid } = this;
    super.update(options, false);
    this.uuid = uuid;
  }

  setUnit(unit = '', apiUnit = this.options.tooltip.valueSuffix): void {
    this.yAxis.setUnit(unit, apiUnit);
    this.update({
      tooltip: {
        valuePrefix: unit ?? apiUnit,
        valueSuffix: apiUnit ?? '',
      },
    } as SeriesOptionsType);
  }

  setVisibility(visibility: boolean): void {
    this.setVisible(visibility, false);
  }

  setName(name): void {
    this.update({ name } as SeriesOptionsType);
  }

  addData(data: number[][], period?: Period, groupBy?: AnyGroupBy): void {
    super.setData([], false, false, false); // TODO: JCE
    this.chart.redraw();
    this.period = period;
    this.groupBy = groupBy;
    if (data.length) super.setData(data, false, false, false);
    // this.stats = findMinMax(data);
  }

  addPoint(point: number[]): void {
    super.addPoint(point, true, true, true);
  }

  removeData(): void {
    super.setData([]);
  }

  // remove(): void {
  //   if (this.graph !== undefined) this.graph.destroy();
  //   super.remove(false, false);
  // }

  static convertDataFromApiToHighcharts(data: TadaApiResponse['results']): number[][] {
    return data.map(({ value, time }) => [Date.parse(time), value]);
  }

  setThreshold({
    value = 0,
    operator = '=',
    thresholdColor = theme.palette.error.main,
    trueColor = theme.palette.secondary.light,
    falseColor = theme.palette.error.main,
  }): void {
    if (!Number.isFinite(value)) return;
    this.yAxis.update(
      {
        plotLines: [],
      },
      true, // TODO: pourquoi on est obligé de redraw ici ?
    );
    if (this.graph !== undefined) this.graph.destroy();
    this.yAxis.addPlotLine({
      value,
      color: thresholdColor,
      width: 1,
      zIndex: 1,
    });
    if (['<', '>', '<=', '>='].includes(operator)) {
      this.update({
        threshold: value,
        color: ['<', '<='].includes(operator) ? trueColor : falseColor,
        negativeColor: ['<', '<='].includes(operator) ? falseColor : trueColor,
      } as SeriesOptionsType);
    }
  }
}
