import { FC } from 'react';

import FormatPickerProvider, { FormatPickerProviderProps } from './FormatPickerContext';
import FormatPopoverBase, { FormatPopoverBaseProps } from './FormatPopoverBase';

export type FormatPopoverProps = FormatPopoverBaseProps & FormatPickerProviderProps;

const FormatPopover: FC<FormatPopoverProps> = ({ value, onChange, unit, ...props }) => (
  <FormatPickerProvider unit={unit} value={value} onChange={onChange}>
    <FormatPopoverBase {...props} />
  </FormatPickerProvider>
);

export default FormatPopover;
