import { ArrowRight } from '@mui/icons-material';
import { List, ListItem, ListItemText, ListSubheader, Stack, Typography } from '@mui/material';
import { FC, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ModelInfo, UUID } from '@dametis/core';

import { localizedFormat } from 'localization/localizedDateFns';
import localizedGroupBy from 'localization/localizedGroupBy';
import { useModels } from 'store/api/models';
import { useVncStore } from 'zustand/stores/vnc';

import { useCategorizedModels } from '../../../../Models/useCategorizedModels';
import { ListItemButtonWithArrow } from '../BlockDiagramList/ListItemButtonWithArrow';

const emptyModels: ModelInfo[] = [];

const ModelsColumn: FC = () => {
  const { t } = useTranslation('vnc');

  const { data: allModels = emptyModels } = useModels();

  const categories = useCategorizedModels(allModels);

  const selectedModelUuid = useVncStore(state => state.models.selectedModelUuid);
  const setSelectedModelUuid = useVncStore(state => state.setSelectedModelUuid);

  const handleSelectModel = useCallback(
    (modelUuid: UUID) => () => {
      setSelectedModelUuid(modelUuid);
    },
    [setSelectedModelUuid],
  );

  return (
    <List>
      {categories.map(([category, models]) => (
        <Fragment key={category}>
          <ListSubheader disableGutters>{category === 'others' ? t('list.subheader.otherModels') : category}</ListSubheader>
          {models.map(model => (
            <ListItemButtonWithArrow key={model.uuid} selected={model.uuid === selectedModelUuid} onClick={handleSelectModel(model.uuid)}>
              <ListItemText
                primary={
                  <Typography noWrap variant="h6">
                    {model.name}
                  </Typography>
                }
                secondary={
                  <Stack alignItems="flex-start" direction="column">
                    <Typography mt={0.5} variant="body2">
                      {t('text.begin', { date: localizedFormat(new Date(model.period.from), 'eee PPpp') })}
                    </Typography>
                    <Typography variant="body2">
                      {t('text.end', { date: localizedFormat(new Date(model.period.to), 'eee PPpp') })}
                    </Typography>
                    <Typography mt={0.5} variant="body2">
                      {t('text.groupBy', { value: localizedGroupBy(model.groupBy) })}
                    </Typography>
                  </Stack>
                }
              />
              <ArrowRight />
            </ListItemButtonWithArrow>
          ))}
        </Fragment>
      ))}
      {categories.length === 0 && (
        <ListItem>
          <Typography variant="subtitle2">{t('text.noModel')}</Typography>
        </ListItem>
      )}
    </List>
  );
};

export default ModelsColumn;
