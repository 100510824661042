import { CreateFolderBody, FolderInfo, UUID, UpdateFolderBody } from '@dametis/core';

import { sdk } from '../../sdk';

import { api } from './index';

export const globalFoldersApi = api.injectEndpoints({
  endpoints: build => ({
    readGlobalRootFolder: build.query<FolderInfo, void>({
      providesTags: ['GlobalFolders'],
      queryFn: async () => {
        try {
          const { data } = await sdk.folder.TreeGlobal();
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    createGlobalFolder: build.mutation<FolderInfo, CreateFolderBody>({
      invalidatesTags: ['GlobalFolders'],
      queryFn: async block => {
        try {
          const { data } = await sdk.folder.CreateInGlobal(block);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateGlobalFolder: build.mutation<FolderInfo, { uuid: UUID; body: UpdateFolderBody }>({
      invalidatesTags: ['GlobalFolders'],
      queryFn: async ({ uuid, body }) => {
        try {
          const { data } = await sdk.folder.UpdateInGlobal(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteGlobalFolder: build.mutation<void, UUID>({
      invalidatesTags: ['GlobalFolders'],
      queryFn: async blockId => {
        try {
          const { data } = await sdk.folder.DeleteInGlobal(blockId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const { useCreateGlobalFolderMutation, useReadGlobalRootFolderQuery, useDeleteGlobalFolderMutation, useUpdateGlobalFolderMutation } =
  globalFoldersApi;

export const useGlobalFolder: typeof useReadGlobalRootFolderQuery = (arg, options) => {
  return useReadGlobalRootFolderQuery(arg, options);
};
