import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ShortUserWithAclInfo, UserListInfo } from '@dametis/core';

import { SharedUser, SharedUserList } from 'types/share';

export interface SharingState {
  users: ShortUserWithAclInfo[];
  userLists: UserListInfo[];
  updating: boolean;
  sharings: { public: boolean; users: SharedUser[]; lists: SharedUserList[] };
}

const initialState: SharingState = {
  users: [],
  userLists: [],
  updating: false,
  sharings: { public: false, users: [], lists: [] },
};

export const sharingSlice = createSlice({
  name: 'sharing',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<ShortUserWithAclInfo[]>) => {
      state.users = action.payload;
    },
    setUserLists: (state, action: PayloadAction<UserListInfo[]>) => {
      state.userLists = action.payload;
    },
    setUpdating: (state, action: PayloadAction<boolean>) => {
      state.updating = action.payload;
    },
  },
});

export const { setUsers, setUserLists, setUpdating } = sharingSlice.actions;

export default sharingSlice.reducer;
