import { ShortcutCategory, WidgetContentShortcutsAppearance } from '@dametis/core';

const name: Record<ShortcutCategory, string> = {
  [ShortcutCategory.SYNOPTIC]: 'Sinópticos',
  [ShortcutCategory.ALARM]: 'Alarmas',
  [ShortcutCategory.REPORT]: 'Informes',
  [ShortcutCategory.PLAYGROUND]: 'Playgrounds',
  [ShortcutCategory.MODEL]: 'Modelo',
  [ShortcutCategory.PROJECT]: 'Proyectos',
  [ShortcutCategory.TASK]: 'Tareas',
  [ShortcutCategory.COMMENT]: 'Comentarios',
  [ShortcutCategory.SITE]: 'Sitios',
  [ShortcutCategory.ASSET]: 'Documentos',
  [ShortcutCategory.VARIABLE]: 'Variables',
  [ShortcutCategory.HREF]: 'URL',
  [ShortcutCategory.BLOCK]: 'Bloques',
  [ShortcutCategory.BLOCK_TYPE]: 'Tipos de bloque',
  [ShortcutCategory.STANDARD_BLOCK]: 'Bloques estándar',
  [ShortcutCategory.MACRO]: 'Macros',
  [ShortcutCategory.CART]: 'Carrito',
  [ShortcutCategory.CONTRACT]: 'Contratos',
  [ShortcutCategory.BILL]: 'Facturas',
  [ShortcutCategory.BATCH]: 'Batchs',
};

const nameOne: Record<`${ShortcutCategory}_one`, string> = {
  synoptics_one: 'sinóptico',
  alarms_one: 'alarma',
  reports_one: 'informe',
  playgrounds_one: 'playground',
  projects_one: 'proyecto',
  tasks_one: 'tarea',
  comments_one: 'comentario',
  sites_one: 'sitio',
  assets_one: 'documento',
  variable_one: 'variable',
  href_one: 'URL',
  blocks_one: 'bloque',
  blockTypes_one: 'tipo de bloque',
  standardBlocks_one: 'bloque estándar',
  macros_one: 'macro',
  cart_one: 'carrito',
  contracts_one: 'contrato',
  bills_one: 'factura',
  batches_one: 'batch',
  models_one: 'modelo',
};

const nameAOne: Record<`${ShortcutCategory}_a_one`, string> = {
  synoptics_a_one: 'un $t(shortcut:name.synoptics_one)',
  alarms_a_one: 'una $t(shortcut:name.alarms_one)',
  reports_a_one: 'un $t(shortcut:name.reports_one)',
  playgrounds_a_one: 'un $t(shortcut:name.playgrounds_one)',
  projects_a_one: 'un $t(shortcut:name.projects_one)',
  tasks_a_one: 'una $t(shortcut:name.tasks_one)',
  comments_a_one: 'un $t(shortcut:name.comments_one)',
  sites_a_one: 'un $t(shortcut:name.sites_one)',
  assets_a_one: 'un $t(shortcut:name.assets_one)',
  variable_a_one: 'una $t(shortcut:name.variable_one)',
  href_a_one: 'una $t(shortcut:name.href_one)',
  blocks_a_one: 'un $t(shortcut:name.blocks_one)',
  blockTypes_a_one: 'un $t(shortcut:name.blockTypes_one)',
  standardBlocks_a_one: 'un $t(shortcut:name.standardBlocks_one)',
  macros_a_one: 'una $t(shortcut:name.macros_one)',
  cart_a_one: 'el $t(shortcut:name.cart_one)',
  contracts_a_one: 'un $t(shortcut:name.contracts_one)',
  bills_a_one: 'una $t(shortcut:name.bills_one)',
  batches_a_one: 'un $t(shortcut:name.batches_one)',
  models_a_one: 'un $t(shortcut:name.models_one)',
};

const nameThisOne: Record<`${ShortcutCategory}_this_one`, string> = {
  synoptics_this_one: 'este $t(shortcut:name.synoptics_one)',
  alarms_this_one: 'esta $t(shortcut:name.alarms_one)',
  reports_this_one: 'este $t(shortcut:name.reports_one)',
  playgrounds_this_one: 'este $t(shortcut:name.playgrounds_one)',
  projects_this_one: 'este $t(shortcut:name.projects_one)',
  tasks_this_one: 'esta $t(shortcut:name.tasks_one)',
  comments_this_one: 'este $t(shortcut:name.comments_one)',
  sites_this_one: 'este $t(shortcut:name.sites_one)',
  assets_this_one: 'este $t(shortcut:name.assets_one)',
  variable_this_one: 'esta $t(shortcut:name.variable_one)',
  href_this_one: 'esta $t(shortcut:name.href_one)',
  blocks_this_one: 'este $t(shortcut:name.blocks_one)',
  blockTypes_this_one: 'este $t(shortcut:name.blockTypes_one)',
  standardBlocks_this_one: 'este $t(shortcut:name.standardBlocks_one)',
  macros_this_one: 'esta $t(shortcut:name.macros_one)',
  cart_this_one: 'el $t(shortcut:name.cart_one)',
  contracts_this_one: 'este $t(shortcut:name.contracts_one)',
  bills_this_one: 'esta $t(shortcut:name.bills_one)',
  batches_this_one: 'este $t(shortcut:name.batches_one)',
  models_this_one: 'este $t(shortcut:name.models_one)',
};

export default {
  name: {
    ...name,
    ...nameOne,
    ...nameAOne,
    ...nameThisOne,
  },
  text: {
    shortcut_one: 'Acceso directo',
    shortcut_other: 'Accesos directos',
    noShortcut: 'Ningún acceso directo definido',
    brokenLink: 'Enlace no funcional',
    noElement: 'Ningún elemento',
    shortcutCategory: 'Categoría del acceso directo',
    notFound: 'Elemento no encontrado',
  },
  tooltip: {
    synoptics: 'Sinópticos',
    alarms: 'Alarmas',
    reports: 'Informes',
    playgrounds: 'Playgrounds',
    projects: 'Proyectos',
    selectShortcut: 'Seleccionar un acceso directo',
    removeShortcut: 'Eliminar acceso directo',
    [`shortcutCategory_${ShortcutCategory.ALARM}`]: 'Alarma',
    [`shortcutCategory_${ShortcutCategory.HREF}`]: 'Personalizado',
    [`shortcutCategory_${ShortcutCategory.PLAYGROUND}`]: 'Playgrounds',
    [`shortcutCategory_${ShortcutCategory.PROJECT}`]: 'Proyecto',
    [`shortcutCategory_${ShortcutCategory.REPORT}`]: 'Informe',
    [`shortcutCategory_${ShortcutCategory.SYNOPTIC}`]: 'Sinóptico',
  },
  label: {
    link: 'Enlace',
    customize: 'Personalizar',
    tabs: 'Pestañas de acceso directo',
    text: 'Formato del texto',
    bold: 'Negrita',
    italic: 'Cursiva',
    underlined: 'Subrayado',
    delete: 'Tachado',
    save: 'Guardar',
    cancel: 'Cancelar',
    selectPeriod: 'Seleccione un período',
    noPeriod: 'Ningún período seleccionado',
    setPeriod: 'Definir un período',
    absolute: 'Absoluto',
    relative: 'Relativo',
    date: 'Fecha',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de fin',
    presets: 'Preajustes',
    periodType: 'Tipo de período',
    invalidPeriod: 'Período no válido',
    label: 'Título',
    url: 'Url',
  },
  placeholder: {
    label: 'Nombre',
    url: 'https://...',
    search: 'Buscar un acceso directo',
  },
  period: {
    relativeWarning: 'El período seleccionado es relativo a la fecha actual',
    none: 'Ninguno',
    absolute: 'Personalizado',
    reset: 'Reiniciar',
    custom: 'Relativo',
    lastMonth: 'Último mes',
    lastWeek: 'Última semana',
    thisMonth: 'Mes actual',
    thisWeek: 'Semana actual',
    since: 'Desde',
    last_month_start: 'Principio del mes pasado',
    last_month_end: 'Fin del mes pasado',
    last_week_start: 'Principio de la semana pasada',
    last_week_end: 'Fin de la semana pasada',
    this_week: 'Inicio de la semana',
    this_month: 'Inicio del mes',
    now: 'Ahora',
  },
  unit: {
    m_one: 'minuto',
    m_other: 'minutos',
    h_one: 'hora',
    h_other: 'horas',
    d_one: 'día',
    d_other: 'días',
    w_one: 'semana',
    w_other: 'semanas',
    mo_one: 'mes',
    mo_other: 'mes',
    y_one: 'año',
    y_other: 'años',
  },
  alert: {
    selectRelativePeriod: 'Este período será relativo a ahora',
    nonePeriod: 'Ningún período seleccionado',
  },
  toast: {
    periodReset: 'El período se restableció porque la fecha de inicio era posterior a la fecha de fin.',
  },
  button: {
    cancel: 'Cancelar',
    validate: 'Validar',
    addShortcut: 'Agregar',
    addEntityShortcut: 'Agregar acceso directo estándar',
    addHrefShortcut: 'Agregar acceso directo personalizado',
    selectShortcut: 'Seleccionar',
    selectEntityShortcut: 'Seleccionar acceso directo estándar',
    selectHrefShortcut: 'Seleccionar acceso directo personalizado',
  },
  title: {
    customShortcut: 'Acceso directo personalizado',
  },
  select: {
    [`appearance_${WidgetContentShortcutsAppearance.CHIP}`]: 'Compacto',
    [`appearance_${WidgetContentShortcutsAppearance.EXPANDED_TILE}`]: 'Expandido',
    [`appearance_${WidgetContentShortcutsAppearance.COLLAPSED_TILE}`]: 'Reducido',
  },
};
