import { BlockTypeInfo, CreateBlockTypeBody, FolderInfo, ShortcutCategory, UUID, UpdateBlockTypeBody } from '@dametis/core';

import { setBlockTypesById } from 'store/slices/lego';

import { sdk } from '../../sdk';
import { RootState, useSelector } from '../index';

import { api } from './index';

export const blocksApi = api.injectEndpoints({
  endpoints: build => ({
    readBlockTypes: build.query<BlockTypeInfo[], { siteId?: string } | void>({
      providesTags: ['BlockTypes'],
      queryFn: async (arg, { getState, dispatch }) => {
        const state = getState() as RootState;
        const { siteId } = arg || { siteId: state.auth.selectedSite?.uuid };
        try {
          const { data } = await sdk.blockType.List(siteId);
          dispatch(
            setBlockTypesById(
              data.reduce<Record<UUID, BlockTypeInfo>>((acc, blockType) => {
                if (!acc[blockType.uuid]) {
                  acc[blockType.uuid] = blockType;
                }
                return acc;
              }, {}),
            ),
          );
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    createBlockType: build.mutation<BlockTypeInfo, CreateBlockTypeBody>({
      invalidatesTags: ['BlockTypes', 'GlobalFolders'],
      queryFn: async block => {
        try {
          const { data } = await sdk.blockType.Create(block);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    duplicateBlockType: build.mutation<BlockTypeInfo, { uuid: UUID; name: string; folderId?: UUID }>({
      invalidatesTags: ['BlockTypes', 'GlobalFolders'],
      queryFn: async ({ uuid, name, folderId }) => {
        try {
          const { data } = await sdk.blockType.Duplicate(uuid, { name, folderId });
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateBlockType: build.mutation<BlockTypeInfo, { uuid: UUID; body: UpdateBlockTypeBody }>({
      invalidatesTags: ['BlockTypes', 'StandardBlocks', 'Blocks'],
      queryFn: async ({ uuid, body }) => {
        try {
          const { data } = await sdk.blockType.Update(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    moveBlockType: build.mutation<FolderInfo, { blockTypeId: UUID; sourceId: UUID; targetId: UUID }>({
      invalidatesTags: ['BlockTypes', 'GlobalFolders'],
      queryFn: async ({ blockTypeId, sourceId, targetId }) => {
        try {
          const { data } = await sdk.folder.UpdateInGlobal(sourceId, {
            operation: {
              name: 'move',
              params: {
                sourceFolderId: sourceId,
                targetFolderId: targetId,
                shortcut: { category: ShortcutCategory.BLOCK_TYPE, uuid: blockTypeId },
              },
            },
          });
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteBlockType: build.mutation<void, UUID>({
      invalidatesTags: ['BlockTypes', 'GlobalFolders'],
      queryFn: async blockId => {
        try {
          const { data } = await sdk.blockType.Delete(blockId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    restoreBlockType: build.mutation<UUID, { activityId: UUID; blockTypeId: UUID }>({
      invalidatesTags: ['BlockTypes'],
      queryFn: async ({ activityId, blockTypeId }) => {
        try {
          await sdk.blockType.Restore(blockTypeId, activityId);
          return { data: blockTypeId };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
    }),
  }),
});

export const {
  useCreateBlockTypeMutation,
  useReadBlockTypesQuery,
  useMoveBlockTypeMutation,
  useDeleteBlockTypeMutation,
  useDuplicateBlockTypeMutation,
  useUpdateBlockTypeMutation,
  useRestoreBlockTypeMutation,
} = blocksApi;

export const useBlockTypes: typeof useReadBlockTypesQuery = (arg, options) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return useReadBlockTypesQuery(arg ?? { siteId }, { skip: !siteId, ...options });
};
