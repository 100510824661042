import { Accordion, AccordionDetails, AccordionSummary, Link, Typography } from '@mui/material';
import { withTheme } from '@mui/styles';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Component } from 'react';

import errorImg from 'assets/images/errors/error-crash.svg';

import { openReplayTracker } from '../../functions/openReplay';

import ErrorPageTemplate from './ErrorPageTemplate';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: {
        message: '',
        stack: '',
      },
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState(oldState => ({ ...oldState, error }));
    openReplayTracker.handleError(error);
    console.error({ error, errorInfo });
  }

  render() {
    const { children, theme } = this.props;
    const { hasError, error } = this.state;
    if (hasError) {
      return (
        <ErrorPageTemplate
          title={i18next.t('errors:title.crash')}
          text={
            <>
              {i18next.t('errors:text.crash1')}{' '}
              <Link href="https://dametis.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noopener noreferrer">
                {i18next.t('errors:text.crash2')}
              </Link>
            </>
          }
          image={errorImg}
        >
          <Accordion
            style={{
              backgroundColor: theme.palette.grey[100],
              boxShadow: 'none',
            }}
          >
            <AccordionSummary>
              <Typography variant="overline">{i18next.t('errors:button.learnMore')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Typography variant="body1">{error.message}</Typography>
                <Typography variant="body2">{error.stack}</Typography>
              </div>
            </AccordionDetails>
          </Accordion>
        </ErrorPageTemplate>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      grey: PropTypes.shape({ 100: PropTypes.string }),
    }),
  }).isRequired,
};

export default withTheme(ErrorBoundary);
