import { Unstable_Grid2 as Grid2, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, Typography } from '@mui/material';
import { FC } from 'react';

interface Props {
  index: number;
  dataLength: number;
}

const ListItemSkeleton: FC<Props> = ({ index, dataLength }) => (
  <ListItem divider={index < dataLength - 1} sx={[{ pl: 0.5, py: 0.5 }]}>
    <ListItemAvatar>
      <Stack alignItems="center" spacing={0.5} sx={{ width: 40 }}>
        <Skeleton height={40} variant="circular" width={40} />
      </Stack>
    </ListItemAvatar>
    <ListItemText
      primary={
        <Grid2 container alignItems="center" spacing={0.5}>
          <Grid2 xs={6}>
            <Typography variant="h6">
              <Skeleton width="60%" />
            </Typography>
            <Typography variant="body2">
              <Skeleton width="40%" />
            </Typography>
          </Grid2>
          <Grid2 xs={2}>
            <Skeleton variant="rounded" width="80%" />
          </Grid2>
          <Grid2 xs={3}>
            <Skeleton variant="rounded" width="60%" />
          </Grid2>
          <Grid2 xs={1}>
            <Skeleton />
          </Grid2>
        </Grid2>
      }
      primaryTypographyProps={{ component: 'div' }}
      secondary={
        <Grid2 container spacing={0.5}>
          <Grid2 sx={{ minWidth: '40%', maxWidth: '100%' }}>
            <Skeleton />
          </Grid2>
        </Grid2>
      }
      secondaryTypographyProps={{ component: 'div' }}
    />
  </ListItem>
);

export default ListItemSkeleton;
