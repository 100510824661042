import { Stack, Step, StepConnector, StepContent, StepLabel, Stepper, Typography, stepConnectorClasses, styled } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateBlockTypeBody, UUID, UpdateBlockTypeBody } from '@dametis/core';

import { MetadataListName } from './MetadataListsStep';

export const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: { transition: theme.transitions.create(['min-height'], { duration: 172 }) },
  [`&.${stepConnectorClasses.active} .${stepConnectorClasses.line}, &.${stepConnectorClasses.completed} .${stepConnectorClasses.line}`]: {
    minHeight: 0,
  },
}));

export interface ModalStepperProps {
  activeStep: number;
  blockTypeBody: CreateBlockTypeBody | UpdateBlockTypeBody;
  listNameByMetadataId: Record<UUID, MetadataListName>;
}

const ModalStepper: FC<ModalStepperProps> = ({ activeStep, blockTypeBody, listNameByMetadataId }) => {
  const { t } = useTranslation('lego');

  const standardMetadataListLength = useMemo(
    () => Object.values(listNameByMetadataId).filter(listName => listName === MetadataListName.STANDARD).length,
    [listNameByMetadataId],
  );

  const blockMetadataListLength = useMemo(
    () => Object.values(listNameByMetadataId).filter(listName => listName === MetadataListName.BLOCK).length,
    [listNameByMetadataId],
  );

  return (
    <Stepper activeStep={activeStep} connector={<StyledStepConnector />} orientation="vertical">
      <Step>
        <StepLabel>{t('stepper.general')}</StepLabel>
        <StepContent TransitionProps={{ in: activeStep > 0 }}>
          <Typography variant="caption">{t('stepperContent.name', { name: blockTypeBody.name })}</Typography>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>{t('stepper.metadataLists')}</StepLabel>
        <StepContent TransitionProps={{ in: activeStep > 1 }}>
          <Stack>
            <Typography variant="caption">
              {t('stepperContent.standardMetadataListLength', { count: standardMetadataListLength })}
            </Typography>
            <Typography variant="caption">{t('stepperContent.blockMetadataListLength', { count: blockMetadataListLength })}</Typography>
          </Stack>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>{t('stepper.parameters')}</StepLabel>
        <StepContent TransitionProps={{ in: activeStep > 2 }}>
          <Typography variant="caption">
            {t('stepperContent.parametersLength', { count: (blockTypeBody.parameters ?? []).length })}
          </Typography>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>{t('stepper.connections')}</StepLabel>
        <StepContent TransitionProps={{ in: activeStep > 3 }}>
          <Typography variant="caption">
            {t('stepperContent.connectionsLength', { count: (blockTypeBody.connections ?? []).length })}
          </Typography>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>{t('stepper.metrics')}</StepLabel>
      </Step>
    </Stepper>
  );
};

export default ModalStepper;
