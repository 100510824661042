export default {
  required: 'Required',
  integer: 'Must be an integer',
  minArray: 'Should contain at least {{value}} value(s)',
  minNumber: 'Must be >= {{value}}',
  maxNumber: 'Must be <= {{value}}',
  minString: 'Minimum {{value}} characters',
  maxString: 'Maximum {{value}} characters',
  minDate: 'Date must be after {{value}}',
  maxDate: 'Date must be before {{value}}',
  exactString: 'Must be {{value}} characters',
  step: 'Must be a multiple of {{value}}',
  ipv4: 'Incorrect IPv4 format',
  ipv4Range: 'Incorrect IPv4 range format, must be ip/mask',
  type: {
    numberMinMax: 'Must be a number between {{min}} and {{max}}',
    number: 'Must be a valid number',
    string: 'must be a valid string',
    boolean: 'Must be a boolean',
    object: 'Must be an object',
    email: 'Must be a valid email address',
    uuid: 'Must be a valid UUID',
  },
  oneOf: 'Must be an available option',
  isNot: 'Cannot be "{{value}}"',
  positive: 'Must be positive',
  mustBeDifferent: 'Must be different',
  oneOfMultipleFormat: 'Mut match at least one format',
  invalidDelay: 'Invalid delay',
};
