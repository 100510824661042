import { StyleFormat, TextAlign } from '@dametis/core';

import { CommentFilter, CommentSortBy, CommentView, EntityType, LinkedProjectType } from 'types/comment';

export default {
  title: {
    comments: 'Commentaires',
    modal: 'Ajouter un commentaire',
  },
  stepper: {
    comment: 'Commentaire',
    project: 'Projet',
  },
  placeholder: {
    leaveAComment: 'Écrivez un commentaire...',
  },
  text: {
    requiredComment: 'Laisser un commentaire est requis',
    sort: 'Tri',
    filter: 'Filtre',
    noComment: 'Aucun commentaire',
    owner: 'Utilisateur',
    allSelected: 'Tout sélectionner',
    from: 'du {{from}}',
    to: 'au {{to}}',
    noEntity: 'Aucune entité',
    title: {
      variable: 'variable',
      report: 'rapport',
      alarm: 'alarme',
      project: 'projet',
      task: 'tâche',
    },
    fontSize: {
      small: 'Petite',
      medium: 'Moyenne',
      large: 'Grande',
      auto: 'Auto',
    },
    periodFilterWarning:
      "À l'enregistrement, votre commentaire disparaitra de la liste car la période associée n'est plus comprise dans la période en cours. Pour le retrouver, désactivez le filtre sur la période en cours.",
    noUserFound: 'Aucun collaborateur trouvé pour "{{search}}"',
  },
  label: {
    withProject: 'Lier ce commentaire à un projet',
    linkedProjectType: {
      [LinkedProjectType.NEW]: 'Nouveau projet',
      [LinkedProjectType.EXISTING]: 'Projet existant',
    },
    date: 'Date',
    displayOnlyOnCurrentPeriod: 'Afficher les commentaires de la période en cours',
    [StyleFormat.BOLD]: 'Gras',
    [StyleFormat.ITALIC]: 'Italique',
    [StyleFormat.UNDERLINE]: 'Souligné',
    [TextAlign.LEFT]: 'Alignement à gauche',
    [TextAlign.CENTER]: 'Alignement au centre',
    [TextAlign.RIGHT]: 'Alignement à droite',
    entity: {
      [EntityType.ALARM]: 'alarme',
      [EntityType.PLAYGROUND]: 'playground',
      [EntityType.REPORT]: 'rapport',
      [EntityType.PROJECT]: 'projet',
      [EntityType.SYNOPTIC]: 'synoptique',
      [EntityType.VARIABLE]: 'variable',
      [EntityType.TASK]: 'tâche',
    },
  },
  button: {
    close: 'Fermer',
    next: 'Suivant',
    submit: 'Valider',
    previous: 'Précédent',
    fullscreen: 'Plein écran',
    exitFullscreen: 'Quitter le mode plein écran',
    send: 'Envoyer',
    joinProject: 'Lier un projet',
    cancel: 'Annuler',
    insertMention: 'Mentionner un collaborateur',
  },
  sortBy: {
    [CommentSortBy.DATE]: 'Date associée',
    [CommentSortBy.DATE_REVERSE]: 'Date associée (décroissant)',
    [CommentSortBy.CREATION_DATE]: 'Date du commentaire',
    [CommentSortBy.CREATION_DATE_REVERSE]: 'Date du commentaire (décroissant)',
    [CommentSortBy.PERIOD_FROM]: 'Début de période',
    [CommentSortBy.PERIOD_FROM_REVERSE]: 'Début de période (décroissant)',
    [CommentSortBy.PERIOD_TO]: 'Fin de période',
    [CommentSortBy.PERIOD_TO_REVERSE]: 'Fin de période (décroissant)',
  },
  view: {
    [CommentView.LIST]: 'Vue liste',
    [CommentView.BY_PROJECT]: 'Vue par projet',
  },
  filter: {
    [CommentFilter.ALL]: 'Afficher tous les commentaires',
    [CommentFilter.CURRENT_PERIOD]: 'Afficher les commentaires de la période en cours',
  },
  tooltip: {
    notAllowedDeleteComment: 'Vous ne pouvez pas supprimer ce commentaire',
    notAlloweEditComment: 'Vous ne pouvez pas modifier ce commentaire',
    goToPeriod: 'Définir cette période sur le {{entity}}',
    samePeriod: 'Cette période est déjà définie sur le {{entity}}',
  },
};
