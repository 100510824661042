export default {
  title: {
    batch: 'Batch « {{name}} »',
    newBatch: 'Nouveau batch',
    when: 'Conditions',
    do: 'Actions',
    logs: 'Journal des runs',
    newElem: 'Nouvel élément',
    batchFilters: 'Filtre par batch',
  },
  button: {
    editBatch: 'Modifier',
    deleteBatch: 'Supprimer',
    saveBatch: 'Enregistrer',
    cantSaveBatch: 'Ajoutez un élément pour pouvoir enregistrer',
    createBatch: 'Créer',
    addRule: 'Ajouter une règle',
    addAction: 'Ajouter une action',
    ok: 'Ok',
    cancel: 'Annuler',
    add: 'Enregistrer',
    addBatch: 'Ajouter un batch',
    addElem: 'Ajouter un élément',
    addFirstElem: 'Ajouter un premier élément',
  },
  input: {
    label: {
      name: 'Nom',
      criticality: 'Criticité',
      durationValue: 'Durée minimale avant activation',
      calendar: "Période d'activation",
      condition: "Règle d'activation",
      conditionMode: "L'état de cet élément est fonction de :",
      operator: 'Opérateur',
    },
    helperText: {
      advanced: 'Comparer à un calcul',
      simple: 'Comparer à une valeur',
    },
    adornment: {
      durationValue: 'pendant',
    },
    value: {
      joinWith_and: 'ET',
      joinWith_or: 'OU',
      own: 'une ou plusieurs conditions',
      children: 'ses enfants',
      tooltipToogleStateError: "Impossible de changer d'état car ce batch a encore des enfants",
    },
    placeholder: 'Sélectionner...',
    noBatchSelected: 'Aucun batch sélectionné',
    logicalConditionOperator_and: 'et',
    logicalConditionOperator_or: 'ou',
    target: 'Durée cible',
  },
  results: {
    count: 'Occurrences',
    cumulatedDuration: 'Durée cumulée',
    meanDuration: 'Durée moyenne',
  },
  tooltip: {
    createBatch: 'Créer le batch',
    editBatch: 'Modifier le batch',
    deleteBatch: 'Supprimer le batch',
    addBatch: 'Ajouter un batch',
    addFirstElem: 'Ajouter un premier élément',
    deleteBatchChild: "Supprimer l'enfant",
    deleteRule: 'Supprimer la règle',
    duplicateBatchChild: 'Dupliquer',
    recomputeBatchVariable: 'Recalculer la variable du batch',
  },
  text: {
    copy: 'Copie',
  },
  helper: {
    howToUse: 'Insérez des opérateurs et des parenthèses pour créer des conditions complexes',
  },
  toast: {
    successRecompute: 'Demande prise en compte, recalcul en cours...',
  },
};
