import { Box, Chip } from '@mui/material';
import { FC } from 'react';
import { RenderElementProps, useReadOnly, useSelected } from 'slate-react';

import { SymbolElement } from '@dametis/core';

import { setColorLightness } from 'functions/color';

import InlineChromiumBugfix from './InlineChromiumBugfix';

interface Props extends RenderElementProps {
  element: SymbolElement;
  isBatchExpression?: boolean;
}

export const SlateSymbol: FC<Props> = ({ attributes, children = undefined, element, isBatchExpression = false }) => {
  const readOnly = useReadOnly();
  const selected = useSelected() && !readOnly;

  if (isBatchExpression && ['and', 'or'].includes(element.symbol)) {
    return (
      <Box
        component="span"
        contentEditable={false}
        sx={[
          { color: 'primary.main', borderRadius: '10px', px: 0.375, mx: 0.375 },
          selected && (theme => ({ backgroundColor: setColorLightness(theme.palette.primary.main, 90) })),
        ]}
        {...attributes}
      >
        <Chip
          color="secondary"
          label={element.symbol.toUpperCase()}
          size="small"
          sx={{
            color: theme => theme.palette.text.primary,
            fontSize: 12,
            fontWeight: 600,
            backgroundColor: theme => setColorLightness(theme.palette.picker.cyan, selected ? 75 : 85),
          }}
        />
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="span"
      contentEditable={false}
      sx={[
        { color: 'primary.main', borderRadius: '10px', px: 0.375, mx: 0.375 },
        selected && (theme => ({ backgroundColor: setColorLightness(theme.palette.primary.main, 90) })),
      ]}
      {...attributes}
      data-slate-symbol={element.symbol}
    >
      <InlineChromiumBugfix />
      {element.symbol}
      {children}
      <InlineChromiumBugfix />
    </Box>
  );
};
