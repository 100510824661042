import { Box, ListSubheader, Stack, styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const SDCMarginTop: FC<PropsWithChildren<{ index: number; scrollTop?: number }>> = ({
  index,
  scrollTop = 0,
  children = undefined,
}) => (
  <Stack sx={{ height: '100%' }}>
    <Box sx={{ flexGrow: 1, maxHeight: `calc(16px + ${index} * (56px + 8px) - 20px - 6px - ${scrollTop}px)` }} />
    {children}
  </Stack>
);

export const SDCTitle = styled(ListSubheader)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  background: 'none !important',
}));
