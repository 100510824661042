import { FormatListBulletedOutlined, ViewColumnOutlined } from '@mui/icons-material';
import { Stack, ToggleButtonGroup, Tooltip } from '@mui/material';
import { ChangeEventHandler, FC, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { WipFeatures } from 'config/featureFlags';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import SmallToggleButton from '../SmallToggleButton/SmallToggleButton';

import { useUnitPickerContext } from './UnitPickerContext';
import PopoverInput from './styled/PopoverInput';
import { UnitPickerMenu } from './types';

const UnitInput: FC = () => {
  const { t } = useTranslation('unit');

  const { setFocusIndex, inputValue, setInputValue, selectedMenu, setSelectedMenu } = useUnitPickerContext();

  const enablePhysicalParameter = useFeatureFlags(WipFeatures.ENABLE_PHYSICAL_PARAMETER);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setInputValue(event.target.value);
      setFocusIndex(-1);
    },
    [setInputValue, setFocusIndex],
  );

  const handleSelectMenu = useCallback(
    (event: MouseEvent<HTMLElement>, newMenu: UnitPickerMenu | null) => {
      if (newMenu) {
        setSelectedMenu(newMenu);
      }
    },
    [setSelectedMenu],
  );

  return (
    <Stack alignItems="center" direction="row" p={1} spacing={1} sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}>
      <PopoverInput autoFocus fullWidth value={inputValue} onChange={handleInputChange} />
      {enablePhysicalParameter && (
        <ToggleButtonGroup exclusive size="small" value={selectedMenu} onChange={handleSelectMenu}>
          <SmallToggleButton value={UnitPickerMenu.LIST}>
            <Tooltip placement="top" title={t(`tooltip.menu.${UnitPickerMenu.LIST}`)}>
              <FormatListBulletedOutlined fontSize="small" />
            </Tooltip>
          </SmallToggleButton>
          <SmallToggleButton value={UnitPickerMenu.COLUMNS}>
            <Tooltip placement="top" title={t(`tooltip.menu.${UnitPickerMenu.COLUMNS}`)}>
              <ViewColumnOutlined fontSize="small" />
            </Tooltip>
          </SmallToggleButton>
        </ToggleButtonGroup>
      )}
    </Stack>
  );
};

export default UnitInput;
