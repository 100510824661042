import { SvgIconProps } from '@mui/material';
import { FC, useMemo } from 'react';

import { BlockInfo, Shortcut, UUID } from '@dametis/core';

import { BlocksOutlined } from 'assets/icons/BlocksOutlined';

import { AvailableShortcutCategory, EntitiesByCategory } from '../types';

export interface ListItemBlockIconProps extends Omit<SvgIconProps, 'color'> {
  shortcut: Shortcut;
  entitiesByCategory: EntitiesByCategory;
}

const ListItemBlockIcon: FC<ListItemBlockIconProps> = ({ shortcut, entitiesByCategory, ...props }) => {
  const blocksById: Record<UUID, BlockInfo> = useMemo(
    () => entitiesByCategory[shortcut.category as AvailableShortcutCategory] as Record<UUID, BlockInfo>,
    [entitiesByCategory, shortcut.category],
  );

  const block: BlockInfo | null = useMemo(() => (shortcut.uuid && blocksById ? blocksById[shortcut.uuid] : null), [shortcut, blocksById]);

  return <BlocksOutlined sx={{ color: block?.ui?.color }} {...props} />;
};

export default ListItemBlockIcon;
