import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UUID } from '@dametis/core';

export interface MacroState {
  uuid: string | null;
  inlayInit: boolean;
}

const initialState: MacroState = {
  uuid: null,
  inlayInit: false,
};

export const macroSlice = createSlice({
  name: 'macro',
  initialState,
  reducers: {
    setLastVisited: (state, action: PayloadAction<UUID | null>) => {
      state.uuid = action.payload;
    },
    setInlayVisit: (state, action: PayloadAction<boolean>) => {
      state.inlayInit = action.payload;
    },
    clearMacroStore: () => initialState,
  },
});

export const { setLastVisited, setInlayVisit, clearMacroStore } = macroSlice.actions;

export default macroSlice.reducer;
