import { EventRepeatOutlined } from '@mui/icons-material';
import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import type { PositionObject, Tooltip, TooltipPositionerPointObject } from 'highcharts';
import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'usehooks-ts';

import { ApplicationError, CalculationStats, RawPoint } from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import ChartTooltip from 'components/DaChart/ChartTooltip/ChartTooltip';
import DaChart from 'components/DaChart/DaChart';
import LineSeries from 'components/DaChart/LineSeries/LineSeries';
import XAxis from 'components/DaChart/XAxis/XAxis';
import YAxis from 'components/DaChart/YAxis/YAxis';
import { rawPointsToSingleDateTimeData } from 'components/DaChart/helpers/rawPointsToSingleDateTimeData';
import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import { createCalculationVariable } from 'functions/createCalculationVariable';
import { getCalculationUnit } from 'functions/getCalculationUnit';
import { Tada } from 'functions/tada/tada';
import { useVariableColor, useVariableName } from 'hooks/useVariableProp';
import { localizedFormat } from 'localization/localizedDateFns';
import { useDispatch, useSelector } from 'store';
import { hasTadaErrors } from 'store/actions/toasts';
import { ToPeriod, setSelectedTo } from 'store/slices/calculationMenu';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import CircularLoader from '../../CircularLoader/CircularLoader';

import CalculationName from './CalculationName';
import ChartSettings from './ChartSettings';
import ChartStatistics from './ChartStatistics';
import PeriodLabel from './PeriodLabel';

const MenuContainer = styled(Box, { shouldForwardProp: propName => propName !== 'isVisible' })<{ isVisible: boolean }>(
  ({ theme, isVisible }) => ({
    position: 'absolute',
    width: '100%',
    transition: theme.transitions.create(['opacity']),
    opacity: isVisible ? 1 : 0,
    pointerEvents: isVisible ? 'all' : 'none',
  }),
);

export enum CalculationChartMenu {
  GRAPH = 'graph',
  STATISTICS = 'statistics',
  SETTINGS = 'settings',
}

interface CalculationChartProps {
  selectedMenu: CalculationChartMenu;
}

export const DACHART_HEIGHT = 200;
export const DACHART_CONTAINER_WIDTH = 300;
export const LABEL_HEIGHT = 25;

const emptyCalculation = createCalculationVariable();

const CalculationChart: FC<CalculationChartProps> = ({ selectedMenu }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('calculationMenu');
  const theme = useTheme();

  const { width: windowWidth = 0 } = useWindowSize();

  const menuPosition = useSelector(state => state.calculationMenu.menuPosition);
  const apiCalculation = useSelector(state => state.calculationMenu.apiCalculation);
  const period = useSelector(state => state.calculationMenu.period);
  const chartDisabled = useSelector(state => state.calculationMenu.chartDisabled);
  const isFetchingLastPoint = useSelector(state => state.calculationMenu.lastPoint.isFetching);
  const lastPointDate = useSelector(state => state.calculationMenu.lastPoint.date);
  const selectedFrom = useSelector(state => state.calculationMenu.selectedFrom);
  const selectedTo = useSelector(state => state.calculationMenu.selectedTo);
  const format = useSelector(state => state.calculationMenu.format);

  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [chartData, setChartData] = useState<RawPoint[]>([]);
  const [stats, setStats] = useState<CalculationStats | null>(null);

  const graphMenuRef = useRef<HTMLDivElement>();
  const containerRef = useRef<HTMLDivElement>();
  const statisticsMenuRef = useRef<HTMLDivElement>();
  const settingsMenuRef = useRef<HTMLDivElement>();

  const isOpen = useMemo(() => menuPosition !== null, [menuPosition]);
  const singleVariable = useMemo(() => (apiCalculation !== null ? getSingleVariableCalculation(apiCalculation) : false), [apiCalculation]);
  const unit = useMemo(() => (apiCalculation ? (getCalculationUnit(apiCalculation) ?? null) : null), [apiCalculation]);
  const isFetching = useMemo(
    () => isFetchingData || (selectedTo === ToPeriod.LAST_POINT && isFetchingLastPoint),
    [isFetchingData, selectedTo, isFetchingLastPoint],
  );

  const hasData = useMemo(() => period && chartData.length > 0, [chartData, period]);

  const color = useVariableColor(
    singleVariable !== false ? singleVariable : apiCalculation || emptyCalculation,
    theme.palette.secondary.main,
  );
  const name = useVariableName(singleVariable !== false ? singleVariable : apiCalculation || emptyCalculation);

  const fetchData = useCallback(
    async (controller: AbortController) => {
      if (apiCalculation === null || period === null) {
        return;
      }
      setIsFetchingData(true);
      try {
        const { data } = await Tada(
          [
            {
              ...apiCalculation,
              stats: true,
              period: period?.Raw(),
            },
          ],
          { signal: controller.signal },
          { rawMode: true },
        );
        setStats(data[0].stats ?? null);
        if (hasTadaErrors(data)) {
          console.error(data[0].errors);
        } else {
          setChartData(data[0].results);
        }
      } catch (err) {
        console.error(err);
        if (err.name !== 'CanceledError') {
          if (err instanceof ApplicationError) {
            dispatch(addToast({ message: t(`errors:alerts.${err.errorName}`), severity: ToastSeverity.ERROR }));
          } else {
            dispatch(addToast({ message: t(`toast:error`), severity: ToastSeverity.ERROR }));
          }
        }
      } finally {
        setIsFetchingData(false);
      }
    },
    [apiCalculation, dispatch, period, t],
  );

  const handleGoToLastPointDate = useCallback(() => {
    dispatch(setSelectedTo(ToPeriod.LAST_POINT));
  }, [dispatch]);

  const tooltipPositioner = useCallback(
    function tooltipPositionerFn(
      this: Tooltip,
      labelWidth: number,
      _labelHeight: number,
      point: TooltipPositionerPointObject,
    ): PositionObject {
      const defaultPosition: PositionObject = { x: 0 - (labelWidth - DACHART_CONTAINER_WIDTH) / 2, y: DACHART_HEIGHT };
      if (containerRef.current === undefined) {
        return defaultPosition;
      }
      const { right, left } = containerRef.current.getBoundingClientRect();
      if (windowWidth - right >= labelWidth) {
        return { x: DACHART_CONTAINER_WIDTH, y: point.plotY };
      }
      if (left >= labelWidth) {
        return { x: -labelWidth, y: point.plotY };
      }
      return defaultPosition;
    },
    [windowWidth, containerRef],
  );

  useEffect(() => {
    const controller = new AbortController();
    void fetchData(controller);
    return () => {
      controller.abort();
    };
  }, [fetchData]);

  useLayoutEffect(() => {
    if (!isOpen) {
      setIsFetchingData(true);
    }
  }, [isOpen]);

  return (
    <>
      <Box ref={containerRef} width={DACHART_CONTAINER_WIDTH}>
        <CalculationName name={name} />
        {!chartDisabled && (
          <PeriodLabel
            fromLabel={t('label.from')}
            fromValue={t(`fromPeriod.${selectedFrom}`)}
            toLabel={selectedTo === ToPeriod.NOW ? t('label.to') : t('label.to_other')}
            toValue={t(`toPeriod.${selectedTo}`)}
          />
        )}
        {!chartDisabled && (
          <Box
            sx={{
              height: DACHART_HEIGHT,
              position: 'relative',
              overflow: 'hidden',
              transition: theme.transitions.create(['height']),
            }}
          >
            <MenuContainer
              ref={graphMenuRef}
              isVisible={selectedMenu === CalculationChartMenu.GRAPH}
              sx={{ width: 1, height: DACHART_HEIGHT }}
            >
              <CircularLoader loading={isFetching}>
                {hasData && (
                  <DaChart
                    daChartOptions={{
                      chart: { animation: false, zooming: { type: 'x' }, width: DACHART_CONTAINER_WIDTH, height: DACHART_HEIGHT },
                      legend: { enabled: false },
                      exporting: {
                        enabled: false,
                      },
                    }}
                  >
                    <ChartTooltip
                      tooltipOptions={{
                        positioner: tooltipPositioner,
                      }}
                    />
                    <XAxis>
                      <YAxis unit={unit}>
                        {apiCalculation && (
                          <LineSeries
                            calculation={apiCalculation}
                            color={color}
                            data={rawPointsToSingleDateTimeData(chartData)}
                            format={format ?? undefined}
                          />
                        )}
                      </YAxis>
                    </XAxis>
                  </DaChart>
                )}
                {!hasData && !isFetching && (
                  <Stack alignItems="center" height={1} justifyContent="center" spacing={1}>
                    <Typography variant="subtitle2">{t('text.noData')}</Typography>
                    {lastPointDate && selectedTo !== ToPeriod.LAST_POINT && (
                      <>
                        <Stack alignItems="center">
                          <Typography variant="subtitle2">{t('text.lastPointDate')}</Typography>
                          <Typography variant="subtitle2">{localizedFormat(lastPointDate, 'PPPpp')}</Typography>
                        </Stack>
                        <ActionButton startIcon={<EventRepeatOutlined />} onClick={handleGoToLastPointDate}>
                          {t('button.goToLastPointDate')}
                        </ActionButton>
                      </>
                    )}
                  </Stack>
                )}
              </CircularLoader>
            </MenuContainer>
            <MenuContainer ref={statisticsMenuRef} isVisible={selectedMenu === CalculationChartMenu.STATISTICS}>
              <ChartStatistics isFetching={isFetching} stats={stats} unit={unit} />
            </MenuContainer>
            <MenuContainer ref={settingsMenuRef} isVisible={selectedMenu === CalculationChartMenu.SETTINGS}>
              <ChartSettings />
            </MenuContainer>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CalculationChart;
