import { ACL, UserInfo } from '@dametis/core';

import timeZones from 'assets/data/timezones-gmt.json';

export interface User extends Omit<UserInfo, 'acl'> {
  acl: ACL;
}

export enum AuthStatus {
  LOADING = 'LOADING',
  LOGGED = 'LOGGED',

  // TODO: utiliser @dametis/core.ErrorName
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_BAD_SIGNIN = 'USER_BAD_SIGNIN',
  USER_BAD_PASSWORD = 'USER_BAD_PASSWORD',
  USER_ACCOUNT_NOT_ACTIVE = 'USER_ACCOUNT_NOT_ACTIVE',
  TOKEN_INVALID = 'TOKEN_INVALID',

  USER_NO_SITES = 'USER_NO_SITES',
  USER_CORPORATE = 'USER_CORPORATE',
  VALIDATION = 'VALIDATION',
  UNKNOWN = 'UNKNOWN',
  ERROR = 'ERROR',
}

export enum TwoFAError {
  REQUIRED = '2FA_REQUIRED',
  REQUIRED_OUTDATED = '2FA_REQUIRED_OUTDATED',
}

export const CONFIGURE_ENROLLMENT_LATER = 'configureEnrollmentLater';

export type TimeZone = keyof typeof timeZones;
export const TimeZones = Object.keys(timeZones) as TimeZone[];
export const defaultTimeZone = 'Europe/Paris';

export const IsTimeZone = (value: string | undefined): value is TimeZone => TimeZones.includes(value as TimeZone);
