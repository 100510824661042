import { NotifyType } from '@dametis/core';

import { ShareType } from 'types/share';

export default {
  title: {
    notify_one: 'Do you want to notify this user?',
    notify_other: 'Do you want to notify these users?',
  },
  text: {
    [ShareType.PLAYGROUND]: 'this playground.',
    [ShareType.REPORT]: 'this report.',
    [ShareType.SYNOPTIC]: 'this synoptic.',
    [ShareType.PROJECT]: 'this project.',
    [ShareType.ALARM]: 'this alarm.',
    [`${NotifyType.SHARINGS}_one`]: 'This user will be informed that they now have access to $t(notify:text.{{itemType}})',
    [`${NotifyType.SHARINGS}_other`]: 'These users will be informed that they now have access to $t(notify:text.{{itemType}})',
    [`${NotifyType.ALARM_ACTION}_one`]: 'This user will be notified that he/she will now receive alerts from this alarm.',
    [`${NotifyType.ALARM_ACTION}_other`]: 'These users will be notified that they will now receive alerts from this alarm.',
    [`${NotifyType.PROJECT_TASK_ASSIGNEE}`]: 'This user will be notified you assigned him to a task.',
    commentPlaceholder: 'Add a custom message...',
  },
  input: {
    addComment: 'Add a message',
    sendNotify: 'Send a notification',
  },
  button: {
    skip: 'Skip',
    send: 'Send',
  },
  toast: {
    success_one: 'The user has been notified',
    success_other: 'Users have been notified.',
    error: 'An error occurred while notifying users.',
  },
};
