import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AssetInfo, BillInfo, ContractInfo, ShortBillInfo, ShortContractInfo, UUID } from '@dametis/core';

export interface BillingState {
  bills: ShortBillInfo[];
  currentBill: BillInfo | null;
  billAsset: AssetInfo | null;

  contracts: ShortContractInfo[];
  currentContract: ContractInfo | null;
  contractAsset: AssetInfo | null;
}

const initialState: BillingState = {
  bills: [],
  currentBill: null,
  billAsset: null,

  contracts: [],
  currentContract: null,
  contractAsset: null,
};

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setContracts: (state, action: PayloadAction<ShortContractInfo[]>) => {
      state.contracts = action.payload;
    },
    setCurrentContract: (state, action: PayloadAction<ContractInfo | null>) => {
      state.currentContract = action.payload;
    },
    addContract: (state, action: PayloadAction<ContractInfo>) => {
      if (!state.contracts.some(contract => contract.uuid === action.payload.uuid)) {
        state.contracts.unshift(action.payload);
      }
    },
    editContract: (state, action: PayloadAction<ContractInfo>) => {
      state.contracts = state.contracts.map(contract => (contract.uuid === action.payload.uuid ? action.payload : contract));
    },
    removeContract: (state, action: PayloadAction<UUID>) => {
      state.contracts = state.contracts.filter(contract => contract.uuid !== action.payload);
    },
    setCurrentContractAsset: (state, action: PayloadAction<AssetInfo | null>) => {
      state.contractAsset = action.payload;
    },
    removeCurrentContractAsset: state => {
      state.contractAsset = null;
    },
    setBills: (state, action: PayloadAction<ShortBillInfo[]>) => {
      state.bills = action.payload;
    },
    setCurrentBill: (state, action: PayloadAction<BillInfo | null>) => {
      state.currentBill = action.payload;
    },
    addBill: (state, action: PayloadAction<BillInfo>) => {
      if (!state.bills.some(bill => bill.uuid === action.payload.uuid)) {
        state.bills.unshift(action.payload);
      }
    },
    editBill: (state, action: PayloadAction<BillInfo>) => {
      state.bills = state.bills.map(bill => (bill.uuid === action.payload.uuid ? action.payload : bill));
    },
    removeBill: (state, action: PayloadAction<UUID>) => {
      state.bills = state.bills.filter(bill => bill.uuid !== action.payload);
    },
    setCurrentBillAsset: (state, action: PayloadAction<AssetInfo | null>) => {
      state.billAsset = action.payload;
    },
    removeCurrentBillAsset: state => {
      state.billAsset = null;
    },
  },
});

export const {
  setContracts,
  setCurrentContract,
  addContract,
  editContract,
  removeContract,
  setCurrentContractAsset,
  removeCurrentContractAsset,
  setBills,
  setCurrentBill,
  addBill,
  editBill,
  removeBill,
  setCurrentBillAsset,
  removeCurrentBillAsset,
} = billingSlice.actions;

export default billingSlice.reducer;
