import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { ChangeEventHandler, FC, useCallback, useEffect, useState } from 'react';

import { BlockMetadataNumberContent, StandardBlockMetadataNumberContent } from '@dametis/core';

export interface MetadataNumberContentFormProps {
  content: StandardBlockMetadataNumberContent | BlockMetadataNumberContent;
  onChange: (newContent: StandardBlockMetadataNumberContent | BlockMetadataNumberContent) => void;
}

const MetadataNumberContentForm: FC<MetadataNumberContentFormProps> = ({ content, onChange }) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleChangeValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setInputValue(event.target.value);
      const parsedValue = parseFloat(event.target.value);
      if (!Number.isNaN(parsedValue)) {
        const newContent: StandardBlockMetadataNumberContent | BlockMetadataNumberContent = { ...content, value: parsedValue };
        onChange(newContent);
      }
    },
    [content, onChange],
  );

  useEffect(() => {
    setInputValue(`${content.value}`);
  }, [content.value]);

  return (
    <FormControl
      fullWidth
      sx={{
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '& input[type=number]': {
          MozAppearance: 'textfield',
        },
      }}
      variant="outlined"
    >
      <OutlinedInput
        endAdornment={<InputAdornment position="end">{content.unit}</InputAdornment>}
        type="number"
        value={inputValue}
        onChange={handleChangeValue}
      />
    </FormControl>
  );
};

export default MetadataNumberContentForm;
