import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Helper from 'components/UI/Helper/Helper';
import { UserHelperName } from 'types/userHelpers';

import { Hotkeys } from '../UI/Hotkeys/Hotkeys';

const GroupSiteMenuHelper: FC = () => {
  const { t } = useTranslation('header');

  return (
    <Helper name={UserHelperName.GROUP_SITE_MENU_HELPER} sx={{ mx: 1 }}>
      <Hotkeys hotkeys="mod+K" /> {t('helper.menu')}
    </Helper>
  );
};

export default GroupSiteMenuHelper;
