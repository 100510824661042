import { Components, Theme } from '@mui/material';

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.text.secondary,
      fontWeight: 500,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    }),
  },
} as Components<Theme>['MuiFormLabel'];
