import { CalculationChartMenu } from 'components/UI/CalculationMenu/CalculationChart/CalculationChart';
import { FromPeriod, ToPeriod } from 'store/slices/calculationMenu';

export default {
  label: {
    from: 'Desde',
    fromPeriod: 'Desde...',
    to: 'hasta',
    to_other: 'hasta el',
    toPeriod: 'Hasta...',
    format: 'Formato',
    playground: 'Crear un área de pruebas',
    addToCart: 'Añadir al carrito',
    alreadyInCart: 'Ya en el carrito',
    configuration: 'Ver en Configuración',
    dataVariableConfiguration: 'Ver en Configuración',
    blockConfiguration: 'Ver en Diagrama de bloques',
    addComment: 'Añadir un comentario',
    addCommentToVar: 'Añadir un comentario a la variable "{{var}}"',
    copyVariableName: 'Copiar el nombre',
    copyCalculation: 'Copiar la ecuación',
    calculation: 'Cálculo',
    variables: 'Variables ({{count}})',
    addCalculation: 'Añadir el cálculo',
    addVariables: 'Añadir las variables ({{count}})',
  },
  tooltip: {
    menu: {
      [CalculationChartMenu.GRAPH]: 'Curva',
      [CalculationChartMenu.STATISTICS]: 'Estadísticas',
      [CalculationChartMenu.SETTINGS]: 'Ajustes',
    },
    statisticsDisabled: 'Las estadísticas no están disponibles para un cálculo',
    disableConfiguration: 'El configurador solo está disponible para variables adquiridas y diagramas de bloques',
    disableAddComment: 'Añadir un comentario solo está disponible para variables adquiridas',
  },
  fromPeriod: {
    [FromPeriod.LAST_HOUR]: '1 hora',
    [FromPeriod.LAST_DAY]: '1 día',
    [FromPeriod.LAST_WEEK]: '7 días',
  },
  toPeriod: {
    [ToPeriod.NOW]: 'ahora',
    [ToPeriod.LAST_POINT]: 'último punto',
  },
  text: {
    errorStatistics: 'No se pueden mostrar las estadísticas',
    noFormatting: 'Sin formato disponible',
    mean: 'Media',
    min: 'Mín',
    max: 'Máx',
    integral: 'Integral',
    stddev: 'Desviación estándar',
    gap: 'Diferencia',
    noData: 'No hay datos para mostrar.',
    lastPointDate: 'Fecha del último punto:',
    noLastPointDate: 'Sin valor',
  },
  button: {
    goToLastPointDate: 'Ir al último punto',
  },
  toast: {
    copyVariableName: 'Nombre de la variable copiado en el portapapeles',
    commentPostedWithSuccess: 'Comentario creado con éxito',
    commentPostedError: 'No se pudo crear el comentario',
  },
};
