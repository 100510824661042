export enum ModbusFonctions {
  READCOILS_1 = '1 - Read Coils',
  READDISCRETEINPUTS_2 = '2 - Read Discrete Inputs',
  READHOLDINGREGISTERS_3 = '3 - Read Holding Registers',
  READINPUTREGISTERS_4 = '4 - Read Input Registers',
}

export enum ModbusTcpFunction {
  READCOILS_1 = 1,
  READDISCRETEINPUTS_2 = 2,
  READHOLDINGREGISTERS_3 = 3,
  READINPUTREGISTERS_4 = 4,
}
