import { CalculateOutlined, DesignServicesOutlined, NotificationsOutlined, RouterOutlined } from '@mui/icons-material';
import { SvgIconProps, styled } from '@mui/material';

import { VariableKind } from '@dametis/core';

import { BatchOutlined } from 'assets/icons/BatchOutlined';
import { Pi } from 'assets/icons/Pi';
import { SteamMixOutlined } from 'assets/icons/SteamMixOutlined';
import { AllVariableKind, FakeVariableKind } from 'types/variables';

export interface VariableKindIconProps extends SvgIconProps {
  kind: AllVariableKind;
  disabled?: boolean;
}

const VariableKindIcon = styled(({ kind, disabled, ...props }: VariableKindIconProps) => {
  switch (kind) {
    case FakeVariableKind.ALARM:
      return <NotificationsOutlined {...props} />;
    case FakeVariableKind.BATCH:
      return <BatchOutlined {...props} />;
    case FakeVariableKind.VAPOR_MIX:
      return <SteamMixOutlined {...props} />;
    case VariableKind.REAL:
      return <RouterOutlined {...props} />;
    case VariableKind.CALCULATED:
      return <CalculateOutlined {...props} />;
    case VariableKind.MANUAL:
      return <DesignServicesOutlined {...props} />;
    case VariableKind.CONSTANT:
      return <Pi {...props} />;
    default:
      return null;
  }
})(({ disabled = false, theme }) => ({
  color: disabled ? theme.palette.text.disabled : undefined,
}));

export default VariableKindIcon;
