import { $isListNode, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, ListNode, REMOVE_LIST_COMMAND } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getSelectionStyleValueForProperty, $patchStyleText } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { FormatBold, FormatItalic, FormatUnderlined } from '@mui/icons-material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { Box, Divider, MenuItem, TextField, ToggleButtonGroup, Tooltip } from '@mui/material';
import { useUserAgent } from '@oieduardorabelo/use-user-agent';
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND, SELECTION_CHANGE_COMMAND } from 'lexical';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyleFormat } from '@dametis/core';

import { KBM } from '../../../../../Hotkeys/KBM';
import MentionPluginButton from '../MentionPlugin/MentionPluginButton';
import ProjectPlugin from '../ProjectPlugin/ProjectPlugin';

import { ToolbarButton, ToolbarWrapper } from './ToolbarPlugin.styled';

const LowPriority = 1;

const sizes: { value: string; px: string }[] = [
  {
    value: 'small',
    px: '12px',
  },
  {
    value: 'medium',
    px: '14px',
  },
  {
    value: 'large',
    px: '18px',
  },
];

interface ToolbarPluginProps {
  joinProject?: boolean;
}

const ToolbarPlugin: FC<ToolbarPluginProps> = ({ joinProject = false }) => {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  // const anchor = useRef<HTMLButtonElement>(null);
  const [blockType, setBlockType] = useState('paragraph');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isOrdered, setIsOrdered] = useState(false);
  const [isUnordered, setIsUnordered] = useState(false);
  const [size, setSize] = useState<string>('14px');
  // const [fontOpen, setFontOpen] = useState<boolean>(false);

  const { t } = useTranslation('comment');
  const os = useUserAgent()?.os.name;

  const formatNumberedList = useCallback(() => {
    if (blockType !== 'ol') {
      if (blockType === 'ul') setIsUnordered(false);
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      setIsOrdered(true);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setIsOrdered(false);
    }
  }, [blockType, editor, setIsOrdered]);

  const formatBulletList = useCallback(() => {
    if (blockType !== 'ul') {
      if (blockType === 'ol') setIsOrdered(false);
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      setIsUnordered(true);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setIsUnordered(false);
      setIsOrdered(false);
    }
  }, [blockType, editor, setIsUnordered]);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
          setIsUnordered(false);
          setIsOrdered(false);
        }
      }
      setSize($getSelectionStyleValueForProperty(selection, 'font-size', '14px'));
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
    }
  }, [editor]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateToolbar();
            return false;
          },
          LowPriority,
        ),
      ),
    [editor, updateToolbar],
  );

  const handleClickFontSize = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            'font-size': `${option}`,
          });
          setSize(option);
        }
      });
    },
    [editor],
  );

  const displayKey = useCallback(() => {
    const symbol = os === 'Mac OS' ? '⌘' : 'Ctrl';
    return symbol;
  }, [os]);

  const getValueSize = useCallback((px: string): string => {
    const foundSizes = sizes.filter(e => e.px === px);
    const returnedSize = foundSizes.length !== 1 ? '' : foundSizes[0].value;
    return returnedSize;
  }, []);

  const handleInsertMention = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();
      if (selection && $isRangeSelection(selection)) {
        const anchorNode = selection.anchor.getNode();
        const text = anchorNode.getTextContent();
        if (!text.length || text.substring(text.length - 1) === ' ') {
          selection.insertText('@');
        } else {
          selection.insertText(' @');
        }
      }
    });
  }, [editor]);

  // const toggleFontPanel = useCallback(() => setFontOpen(!fontOpen), [fontOpen]);

  return (
    <ToolbarWrapper ref={toolbarRef}>
      <Box sx={{ marginRight: theme => theme.spacing(0.5) }}>
        <ToggleButtonGroup sx={{ background: theme => theme.palette.white }}>
          <ToolbarButton
            aria-label="Ordered List"
            className={`toolbar-item spaced ${isOrdered ? 'active' : ''}`}
            value="Ordered"
            onClick={formatNumberedList}
          >
            <FormatListNumberedIcon fontSize="small" sx={{ color: theme => theme.palette.icon.main }} />
          </ToolbarButton>
          <ToolbarButton
            aria-label="Ordered List"
            className={`toolbar-item spaced ${isUnordered ? 'active' : ''}`}
            value="Unordered"
            onClick={formatBulletList}
          >
            <FormatListBulletedIcon fontSize="small" sx={{ color: theme => theme.palette.icon.main }} />
          </ToolbarButton>
        </ToggleButtonGroup>
      </Box>
      <Divider orientation="vertical" sx={{ height: '30px' }} />
      <Box sx={{ marginRight: theme => theme.spacing(0.5) }}>
        <ToggleButtonGroup sx={{ background: theme => theme.palette.white }}>
          <Tooltip
            title={
              <>
                <KBM size="medium">{displayKey()}</KBM>
                {' + '}
                <KBM size="medium">B</KBM>
              </>
            }
          >
            <ToolbarButton
              aria-label={t(`label.${StyleFormat.BOLD}`)}
              className={`toolbar-item spaced ${isBold ? 'active' : ''}`}
              size="small"
              value={StyleFormat.BOLD}
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
              }}
            >
              <FormatBold fontSize="small" sx={{ color: theme => theme.palette.icon.main }} />
            </ToolbarButton>
          </Tooltip>

          <Tooltip
            title={
              <>
                <KBM size="medium">{displayKey()}</KBM>
                {' + '}
                <KBM size="medium">I</KBM>
              </>
            }
          >
            <ToolbarButton
              aria-label={t(`label.${StyleFormat.ITALIC}`)}
              className={`toolbar-item spaced ${isItalic ? 'active' : ''}`}
              size="small"
              value={StyleFormat.ITALIC}
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
              }}
            >
              <FormatItalic fontSize="small" sx={{ color: theme => theme.palette.icon.main }} />
            </ToolbarButton>
          </Tooltip>
          <Tooltip
            title={
              <>
                <KBM size="medium">{displayKey()}</KBM>
                {' + '}
                <KBM size="medium">U</KBM>
              </>
            }
          >
            <ToolbarButton
              aria-label={t(`label.${StyleFormat.UNDERLINE}`)}
              className={`toolbar-item spaced ${isUnderline ? 'active' : ''}`}
              size="small"
              value={StyleFormat.UNDERLINE}
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
              }}
            >
              <FormatUnderlined fontSize="small" sx={{ color: theme => theme.palette.icon.main }} />
            </ToolbarButton>
          </Tooltip>
        </ToggleButtonGroup>
      </Box>
      <Divider orientation="vertical" sx={{ height: '30px', marginRight: '4px' }} />
      {/* <Box sx={{ marginRight: theme => theme.spacing(0.5) }}>
        <IconButton ref={anchor} onClick={toggleFontPanel} size="small">
          <FormatSizeOutlined fontSize="small" />
        </IconButton>
        <Menu anchorEl={anchor.current} open={fontOpen} onClose={toggleFontPanel}>
          {sizes.map(({ value, px }) => (
            <MenuItem
              key={value}
              onClick={() => {
                handleClickFontSize(px);
                toggleFontPanel();
              }}
              sx={{ color: theme => theme.palette.text.secondary, fontSize: px }}
              selected={size === px}
            >
              {t(`text.fontSize.${value}`)}
            </MenuItem>
          ))}
        </Menu>
      </Box> */}
      <div>
        <TextField
          select
          size="small"
          sx={{ minWidth: '60px', marginLeft: theme => theme.spacing(0.5) }}
          value={getValueSize(size)}
          variant="outlined"
        >
          {sizes.map(({ value, px }) => (
            <MenuItem
              key={value}
              selected={size === px}
              sx={{ color: theme => theme.palette.text.secondary }}
              value={value}
              onClick={() => handleClickFontSize(px)}
            >
              {t(`text.fontSize.${value}`)}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {joinProject && (
        <>
          <Divider orientation="vertical" sx={{ height: '30px', marginRight: '4px' }} />
          <ProjectPlugin />
        </>
      )}
      <Divider orientation="vertical" sx={{ height: '30px', marginRight: '4px' }} />
      <MentionPluginButton onClick={handleInsertMention} />
    </ToolbarWrapper>
  );
};

export default ToolbarPlugin;
