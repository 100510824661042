import { CircularProgress, Stack, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntersectionObserver } from 'usehooks-ts';

import { SourceColumnRealByHeader, SourceColumnRealByIndex, SourceColumnVirtual } from '@dametis/core';

import SourceColumnForm from './SourceColumnForm';

export interface ColumnsViewSettingsProps {
  availableRealByIndexColumns: SourceColumnRealByIndex[];
  availableRealByHeaderColumns: SourceColumnRealByHeader[];
  availableVirtualColumnSources: SourceColumnVirtual[];
}

const ColumnsViewSettings: FC<ColumnsViewSettingsProps> = ({
  availableRealByIndexColumns,
  availableRealByHeaderColumns,
  availableVirtualColumnSources,
}) => {
  const { t } = useTranslation('fileImport');

  const [displayedListSize, setDisplayedListSize] = useState<number>(20);

  const availableColumns = useMemo(
    () => [...availableRealByIndexColumns, ...availableRealByHeaderColumns, ...availableVirtualColumnSources],
    [availableRealByIndexColumns, availableRealByHeaderColumns, availableVirtualColumnSources],
  );

  const handleChangeIntersection = useCallback(
    (isIntersecting: boolean) => {
      if (isIntersecting) {
        setDisplayedListSize(state => (availableColumns.length > state ? state + 10 : state));
      }
    },
    [availableColumns],
  );

  const { ref } = useIntersectionObserver({ threshold: 0.5, onChange: handleChangeIntersection });

  return (
    <Stack gap={2}>
      {availableColumns.slice(0, displayedListSize).map((availableColumn, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SourceColumnForm key={index} column={availableColumn} />
      ))}
      {availableColumns.length > displayedListSize && (
        <Stack ref={ref} alignItems="center" direction="row" gap={1} justifyContent="center" p={1}>
          <CircularProgress color="secondary" size={20} variant="indeterminate" />
          <Typography fontStyle="italic" variant="subtitle2">
            {t('text.loadingForms')}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default ColumnsViewSettings;
