import { Theme } from '@mui/material';
import { CSSProperties, makeStyles } from '@mui/styles';

const genCellClasses = (theme: Theme): CSSProperties => {
  const cellClass: CSSProperties = {};
  Object.values(theme.palette.picker).forEach(c => {
    cellClass[`& .cell__${c.substring(1)}`] = {
      backgroundColor: `${c}30`,
    };
  });
  return cellClass;
};

export const usePlaygroundStyles = makeStyles(theme => ({
  playground: {
    width: '100%',
    overflow: 'hidden',
    height: `calc(100vh - ${theme.others.headerHeight})`,
    display: 'flex',
    flexDirection: 'column',
  },
  'playground--closedPanel': {
    gridTemplateColumns: 'auto 0',
  },
  chart: {
    background: theme.palette.white,
    position: 'relative',
    overflow: 'hidden',
    flexGrow: 1,
    height: 0,
  },
  refresher: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  'chart--hidden': {
    display: 'none !important',
  },
  chart__quickActions: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 10,
  },
  chart__quickActions__withTools: {
    marginRight: '40px',
  },
  xyChart: {
    height: '100%',
  },
  xyChart__addVariable__button: {
    margin: '2px 0 2px 5px',
    backgroundColor: `${theme.palette.primary.light}11`,
  },
  liveChart__playButton: {
    display: 'flex',
    margin: '-5rem auto 0 auto',
  },
  multilinregChart: {
    height: 'calc(100% - 3rem)',
  },
  panel: {
    gridArea: 'panel',
  },
  panel__body: {
    overflowX: 'hidden',
  },
  panel__tabs: {
    marginBottom: '1.8rem',
    paddingTop: `${theme.others.pagePaddingTop}!important`,
    width: '100%',
  },
  panel__tabs__tab: {},
  panel__tabPanel: {
    padding: '0px !important',
    '& > h5': {
      marginBottom: 15,
      marginTop: 25,
    },
    '& > ul': {
      paddingTop: 0,
    },
  },
  panel__navigationBar: {
    display: 'flex',
    alignItems: 'center',
    height: 20,
    marginBottom: 15,
    marginTop: 25,
    color: theme.typography.h6.color,
  },
  panel__navigationBar__icon: {
    marginRight: 5,
    color: theme.palette.text.secondary,
  },
  panel__title__formControlLabel: {
    height: 20,
  },
  panel__title__select: {
    paddingBottom: 4,
  },
  panel__mathButton: {
    height: 60,
    marginBottom: '0.7rem',
    textTransform: 'unset',
    fontSize: 14,
  },
  panel__toolButton: {
    display: 'flex',
    margin: '1rem 0 0 auto',
  },
  panel__lastSection: {
    marginBottom: theme.spacing(4),
  },
  panel__actions: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    pointerEvents: 'none',
    transition: theme.transitions.create(['background-color']),
    '& > div': {
      pointerEvents: 'auto',
    },
  },
  detailsPanel: {
    padding: '0px !important',
    marginTop: '1.8rem',
  },
  varPreview: {
    display: 'block',
    backgroundColor: theme.palette.background.default,
    // borderRadius: theme.shape.borderRadius,
    padding: 4,
    // margin: 0,
    '&:not(:last-child)': {
      // marginBottom: 10,
    },
  },
  varPreview__top: {
    height: 30,
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  varPreview__bottom: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  varPreview__title: {
    marginLeft: 5,
  },
  varPreview__title__icon: {
    marginBottom: -1,
    marginLeft: 2,
    marginRight: 2,
    fontSize: 14,
    display: 'none',
    '$varPreview:hover &': {
      display: 'initial',
    },
  },
  varPreview__inputs: {
    marginTop: '0px !important',
    marginRight: 3,
  },
  varPreview__inputs__input: {
    padding: '4px 5px !important',
  },
  'varPreview__inputs__input--adorned': {
    paddingLeft: 5,
  },
  varPreview__inputs__adornment: {
    marginRight: 0,
  },
  varPreview__tabPanel: {
    padding: 'unset',
  },
  footer: {
    gridArea: 'footer',
    display: 'flex',
    padding: 5,
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden',
  },
  footer__addButton: {
    padding: '0 8px',
    minWidth: 'unset',
    minHeight: 40,
  },
  footer__tabs: {
    minHeight: 40,
  },
  footer__tabs__indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
  footer__tabs__tab: {
    minHeight: 40,
    padding: '0 12px',
    marginLeft: 5,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.grey[200],
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    textTransform: 'unset',
    fontSize: '1em',
  },
  'footer__tabs__tab--selected': {
    backgroundColor: theme.palette.background.paper,
  },
  footer__tabs__tab__wrapper: {
    flexDirection: 'unset',
  },
  footer__tabs__tab__name: {
    marginLeft: 5,
    backgroundColor: 'transparent',
    color: 'inherit',
  },
  footer__tabs__tab__name__input: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)} !important`,
    color: 'inherit',
    fontWeight: 500,
    lineHeight: 1.25,
  },
  footer__tabs__tab__buttons: {
    marginLeft: 5,
    marginRight: -4,
  },
  playground__tabs__tab: {
    minWidth: 'unset',
    maxWidth: 'unset',
  },
  home: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: theme.spacing(4),
  },
  home__title: {
    marginBottom: theme.spacing(4),
  },
  home__button: {
    width: '100%',
    textTransform: 'unset',
    height: 80,
  },
  home__subtitle: {
    marginTop: theme.spacing(2),
    '& > span': {
      margin: '0 8px',
    },
  },
  grid: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    ...genCellClasses(theme),
  },
  groups: {},
  'groups--dragging': {
    '& input': {
      pointerEvents: 'none',
    },
  },
  group__card: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: `${theme.palette.grey[200]}90`,
  },
  'group__card--disabled': {
    opacity: 0.5,
    filter: 'grayscale(1)',
  },
  group__new: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'relative',
  },
  'group__new--visible': {
    opacity: 1,
    pointerEvents: 'unset',
  },
  group__info: {
    background: 'none',
  },
  group__info__summary: {
    '& > .MuiAccordionSummary-content': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    '& > .MuiAccordionSummary-content.Mui-expanded': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  group__info__details: {
    padding: 0,
    paddingBottom: theme.spacing(1),
  },
  group__info__subtitle: {
    lineHeight: 16,
    fontSize: 13,
  },
  'group__info__button--hidden': {
    '$group__card:not(:hover) &': {
      display: 'none',
    },
  },
  group__scale__inputs: {
    '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
  group__scale__inputs__input: {
    padding: '4px 5px !important',
  },
  group__scale__unit__input: {
    '& input, .MuiSelect-root': {
      padding: '4px 5px !important',
    },
  },
  groupVar: {
    backdropFilter: 'blur(20px)',
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
  },
  groupVar__name: {
    wordBreak: 'break-word',
    textAlign: 'left',
    margin: `0 ${theme.spacing(1)}`,
    padding: '1px 0',
  },
  groupVar__btns: {
    position: 'absolute',
    right: 0,
    height: '100%',
    backgroundColor: theme.palette.background.default,
    marginLeft: 'auto',
    flexShrink: 0,
  },
  groupVar__btns__wrapper: {
    height: '100%',
    alignItems: 'center',
    display: 'none',
    '$groupVar:hover &': {
      display: 'flex',
    },
  },
  'groupVar__btns__wrapper--variable-hidden': {
    display: 'flex',
  },
  groupVar__btns__btn: {
    opacity: 0,
    pointerEvents: 'none',
    '$groupVar:hover &': {
      opacity: 1,
      pointerEvents: 'auto',
    },
  },
  groupVarPlaceholder: {
    position: 'absolute',
    backgroundColor: 'white',
    outlineWidth: '2px',
    outlineStyle: 'dashed',
    outlineColor: `${theme.palette.secondary.main}`,
    borderRadius: theme.shape.borderRadius,
  },
  'styledRule__cell--bold': {
    fontWeight: 'bold',
  },
  'styledRule__cell--italic': {
    fontStyle: 'italic',
  },
  'styledRule__cell--underline': {
    textDecoration: 'underline',
  },
  'styledRule__cell--icon': {
    marginRight: theme.spacing(1),
  },
  'styledRule__cell--time': {
    fontWeight: 'bold',
    color: theme.palette.picker.red,
  },
  advancedPanel: {},
  'advancedPanel__icon--closed': {
    transition: theme.transitions.create(['transform']),
    // transform: 'rotate(-90deg)',
  },
  'advancedPanel__icon--expanded': {
    transition: theme.transitions.create(['transform']),
    transform: 'rotate(-180deg)',
  },
  advancedPanel__icon__segment: {
    transform: 'rotate(45deg)',
  },
  hc__toolbar: {
    position: 'absolute',
    right: 0,
  },
  hc__toolbar__iconButton: {
    backgroundColor: `${theme.palette.white}20`,
  },
  hc__toolbar__divider: {
    borderColor: theme.palette.grey[500],
    borderBottomStyle: 'dashed',
    width: '60%',
    margin: '2px auto',
  },
  section__marginTop: {
    marginTop: theme.spacing(1),
  },
  styledRule: {
    background: theme.palette.white,
  },
}));
