import {
  MetadataType,
  StandardBlockMetadataBody,
  StandardBlockMetadataNumberContent,
  StandardBlockMetadataOptionContent,
  StandardBlockMetadataStringContent,
} from '@dametis/core';

export const isStandardBlockMetadataStringContentValid = (content: StandardBlockMetadataStringContent): boolean =>
  content?.value?.trim().length > 0;

export const isStandardBlockMetadataNumberContentValid = (content: StandardBlockMetadataNumberContent): boolean =>
  !Number.isNaN(parseFloat(`${content?.value}`));

export const isStandardBlockMetadataOptionContentValid = (content: StandardBlockMetadataOptionContent): boolean =>
  content.selectedOptionId !== null;

export const isStandardBlockMetadataValid = (metadata: StandardBlockMetadataBody): boolean => {
  if (metadata.type === MetadataType.STRING) {
    return isStandardBlockMetadataStringContentValid(metadata.content as StandardBlockMetadataStringContent);
  }
  if (metadata.type === MetadataType.NUMBER) {
    return isStandardBlockMetadataNumberContentValid(metadata.content as StandardBlockMetadataNumberContent);
  }
  if (metadata.type === MetadataType.OPTION) {
    return isStandardBlockMetadataOptionContentValid(metadata.content as StandardBlockMetadataOptionContent);
  }
  return false;
};
