import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogTitle, Paper, Stack } from '@mui/material';
import { Dispatch, FC, MouseEventHandler, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTypeInfo, CreateStandardBlockBody, StandardBlockInfo, UUID } from '@dametis/core';

import { getStandardBlockBodyDataFromBlockType } from 'components/Lego/helpers/getStandardBlockBodyDataFromBlockType';
import { createCreateStandardBlockBody } from 'components/Lego/helpers/standardBlock/createCreateStandardBlockBody';
import { useDispatch } from 'store';
import { useBlockTypes } from 'store/api/blockTypes';
import { useCreateStandardBlockMutation } from 'store/api/standardBlocks';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import GeneralStep from './GeneralStep';
import MetadataListStep from './MetadataListStep/MetadataListStep';
import ModalStepper from './ModalStepper';
import ParametersAndMetricsStep from './ParametersAndMetricsStep/ParametersAndMetricsStep';
import { getIsGeneralStepValid } from './helpers/getIsGeneralStepValid';
import { getIsMetadataListStepValid } from './helpers/getIsMetadataListStepValid';
import { getIsParametersAndMetricsStepValid } from './helpers/getIsParametersAndMetricsStepValid';

const blockTypesEmptyArray: BlockTypeInfo[] = [];

export interface CreateStandardBlockModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  blockTypeId?: UUID;
  folderId?: UUID;
  onCreate?: (createdStandardBlock: StandardBlockInfo, folderId: UUID) => Promise<void> | void;
}

const CreateStandardBlockModal: FC<CreateStandardBlockModalProps> = ({
  isOpen,
  setIsOpen,
  folderId = undefined,
  blockTypeId = undefined,
  onCreate = undefined,
}) => {
  const { t } = useTranslation('lego');
  const dispatch = useDispatch();

  const { data: blockTypes = blockTypesEmptyArray } = useBlockTypes();

  const [createStandardBlock, { isLoading: isCreating }] = useCreateStandardBlockMutation();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [createStandardBlockBody, setCreateStandardBlockBody] = useState<CreateStandardBlockBody>(createCreateStandardBlockBody);

  const isGeneralStepValid = useMemo(() => getIsGeneralStepValid(createStandardBlockBody), [createStandardBlockBody]);

  const isMetadataListStepValid = useMemo(() => getIsMetadataListStepValid(createStandardBlockBody), [createStandardBlockBody]);

  const isParametersAndMetricsStepValid = useMemo(() => getIsParametersAndMetricsStepValid(), []);

  const isFormValid = useMemo(
    () => isGeneralStepValid && isMetadataListStepValid && isParametersAndMetricsStepValid,
    [isMetadataListStepValid, isParametersAndMetricsStepValid, isGeneralStepValid],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handlePreviousStep: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setActiveStep(state => state - 1);
  }, []);

  const handleNextStep: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    if (activeStep === 0) {
      const selectedBlockType = blockTypes.find(blockType => blockType.uuid === createStandardBlockBody.blockTypeId);
      if (selectedBlockType) {
        setCreateStandardBlockBody(state => ({ ...state, ...getStandardBlockBodyDataFromBlockType(selectedBlockType) }));
        setActiveStep(1);
      }
    } else {
      setActiveStep(state => state + 1);
    }
  }, [activeStep, createStandardBlockBody, blockTypes]);

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(async () => {
    try {
      const data = await createStandardBlock(createStandardBlockBody).unwrap();
      if (onCreate && createStandardBlockBody.folderId) {
        await onCreate(data, createStandardBlockBody.folderId);
      }
      setIsOpen(false);
      dispatch(addToast({ message: t('toast.createStandardBlockSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [createStandardBlockBody, createStandardBlock, dispatch, setIsOpen, t, onCreate]);

  useEffect(() => {
    if (isOpen) {
      setCreateStandardBlockBody(createCreateStandardBlockBody({ folderId, blockTypeId }));
      setActiveStep(0);
    }
  }, [isOpen, folderId, blockTypeId]);

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 1 }}>{t('title.createStandardBlockModal')}</DialogTitle>
      <Stack direction="row" gap={1} overflow="hidden" px={3}>
        <Box flexShrink={0} width={250}>
          <ModalStepper activeStep={activeStep} standardBlockBody={createStandardBlockBody} />
        </Box>
        <Paper sx={{ p: 2, flexGrow: 1, display: 'flex', alignItems: 'stretch', overflow: 'hidden' }}>
          {activeStep === 0 && (
            <GeneralStep<CreateStandardBlockBody>
              setStandardBlockBody={setCreateStandardBlockBody}
              standardBlockBody={createStandardBlockBody}
            />
          )}
          {activeStep === 1 && (
            <MetadataListStep<CreateStandardBlockBody>
              setStandardBlockBody={setCreateStandardBlockBody}
              standardBlockBody={createStandardBlockBody}
            />
          )}
          {activeStep === 2 && (
            <ParametersAndMetricsStep<CreateStandardBlockBody>
              setStandardBlockBody={setCreateStandardBlockBody}
              standardBlockBody={createStandardBlockBody}
            />
          )}
        </Paper>
      </Stack>
      <DialogActions>
        {activeStep === 0 && (
          <>
            <Button color="primary" variant="text" onClick={handleClose}>
              {t('button.close')}
            </Button>
            <Button
              color="secondary"
              disabled={!isGeneralStepValid}
              endIcon={<ArrowForwardIos />}
              variant="contained"
              onClick={handleNextStep}
            >
              {t('button.next')}
            </Button>
          </>
        )}
        {activeStep === 1 && (
          <>
            <Button color="primary" startIcon={<ArrowBackIosNew />} variant="text" onClick={handlePreviousStep}>
              {t('button.previous')}
            </Button>
            <Button
              color="secondary"
              disabled={!isMetadataListStepValid}
              endIcon={<ArrowForwardIos />}
              variant="contained"
              onClick={handleNextStep}
            >
              {t('button.next')}
            </Button>
          </>
        )}
        {activeStep === 2 && (
          <>
            <Button color="primary" disabled={isCreating} startIcon={<ArrowBackIosNew />} variant="text" onClick={handlePreviousStep}>
              {t('button.previous')}
            </Button>
            <LoadingButton color="secondary" disabled={!isFormValid} loading={isCreating} variant="contained" onClick={handleSubmit}>
              {t('button.create')}
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateStandardBlockModal;
