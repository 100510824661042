import { Box } from '@mui/material';
import { FC } from 'react';

import ChipEllipse, { ChipEllipseProps } from 'components/UI/ChipEllipse/ChipEllipse';
import { SelectableHrefShortcut } from 'types/shortcut';

import ShortcutIcon from './ShortcutIcon';

export interface ShortcutHrefChipProps extends Omit<ChipEllipseProps, 'label'> {
  clickable?: boolean;
  shortcut: SelectableHrefShortcut;
  willOpenTab?: boolean;
}

const ShortcutHrefChip: FC<ShortcutHrefChipProps> = ({ clickable = true, shortcut, willOpenTab = false, ...props }) => {
  return (
    <Box
      component="a"
      href={shortcut.url}
      sx={{ ...(!clickable && { pointerEvents: 'none' }) }}
      {...(willOpenTab && { target: '_blank', rel: 'noopener noreferrer' })}
    >
      <ChipEllipse
        clickable={clickable}
        icon={<ShortcutIcon withTooltip category={shortcut.category} />}
        label={shortcut.label}
        variant="link"
        {...props}
      />
    </Box>
  );
};

export default ShortcutHrefChip;
