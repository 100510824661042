export default {
  title: {
    batch: 'Batch « {{name}} »',
    newBatch: 'Nuevo batch',
    when: 'Condiciones',
    do: 'Acciones',
    logs: 'Registro de ejecuciones',
    newElem: 'Nuevo elemento',
    batchFilters: 'Filtros por batch',
  },
  button: {
    editBatch: 'Modificar',
    deleteBatch: 'Vaciar',
    saveBatch: 'Guardar',
    cantSaveBatch: 'Agregue un elemento para poder guardar',
    createBatch: 'Crear',
    addRule: 'Agregar una regla',
    addAction: 'Agregar una acción',
    ok: 'Ok',
    cancel: 'Cancelar',
    add: 'Guardar',
    addBatch: 'Agregar un batch',
    addElem: 'Agregar un elemento',
    addFirstElem: 'Agregar un primer elemento',
  },
  input: {
    label: {
      name: 'Nombre',
      criticality: 'Criticidad',
      durationValue: 'Duración mínima antes de la activación',
      calendar: 'Periodo de activación',
      condition: 'Regla de activación',
      conditionMode: 'El estado de este elemento depende de:',
      operator: 'Operador',
    },
    helperText: {
      advanced: 'Comparar con un cálculo',
      simple: 'Comparar con un valor',
    },
    adornment: {
      durationValue: 'durante',
    },
    value: {
      joinWith_and: 'Y',
      joinWith_or: 'O',
      own: 'una o varias condiciones',
      children: 'sus hijos',
      tooltipToogleStateError: 'No se puede cambiar el estado poxrque este batch todavía tiene hijos',
    },
    placeholder: 'Seleccionar...',
    noBatchSelected: 'Ningún batch seleccionado',
    logicalConditionOperator_and: 'y',
    logicalConditionOperator_or: 'o',
    target: 'Duración objetivo',
  },
  results: {
    count: 'Ocurrencias',
    cumulatedDuration: 'Duración acumulada',
    meanDuration: 'Duración media',
  },
  tooltip: {
    createBatch: 'Crear el batch',
    editBatch: 'Modificar el batch',
    deleteBatch: 'Vaciar el batch',
    addBatch: 'Agregar un batch',
    addFirstElem: 'Agregar un primer elemento',
    deleteBatchChild: 'Vaciar el hijo',
    deleteRule: 'Vaciar la regla',
    duplicateBatchChild: 'Duplicar',
    recomputeBatchVariable: 'Recalcular el batch',
  },
  text: {
    copy: 'Copia',
  },
  helper: {
    howToUse: 'Inserte operadores y paréntesis para crear condiciones complejas',
  },
  toast: {
    successRecompute: 'Solicitud tomada en cuenta, nuevo cálculo en curso...',
  },
};
