export default {
  button: {
    reset: 'Reset',
  },
  label: {
    noValue: 'n/a',
  },
  option: {
    all: 'Select all',
  },
  text: {
    noTagSelected: 'No tag selected',
  },
  toast: {
    tooMuchActiveFilters: 'Too much active filters',
  },
  tooltip: {
    unassigned: 'Unassigned',
  },
};
