export default {
  input: {
    label: {
      addFile: 'Nouveau Fichier',
      filename: 'Nom du fichier',
      language: 'Langage du fichier',
      insertVariable: 'Insérer une variable',
      addLib: 'Bibliothèques',
    },
  },
  title: {
    newFile: 'Nouveau Fichier',
  },
  button: {
    cancel: 'Annuler',
    create: 'Créer',
  },
  tooltip: {
    fullScreen: 'Plein Ecran',
    exitFullScreen: 'Mode Réduit',
    deleteFile: 'Supprimer le fichier',
  },
  text: {
    unknownId: 'UUID Inconnu',
  },
};
