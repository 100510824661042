import { Components, Theme, toggleButtonGroupClasses } from '@mui/material';

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.shape.borderRadius,
      [`&.${toggleButtonGroupClasses.disabled}`]: {
        border: 0,
      },
    }),
    grouped: ({ theme }) => ({
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      border: 0,
      '&:first-of-type, &:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:last-of-type': {
        marginRight: theme.spacing(0.5),
      },
    }),
  },
} as Components<Theme>['MuiToggleButtonGroup'];
