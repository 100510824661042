export default {
  user: 'user',
  button: {
    validate: 'Validate',
    cancel: 'Cancel',
    previous: 'Previous',
    next: 'Next',
    configureTwoFA: 'Activate',
    copy: 'Copy',
    generate: 'Generate',
    regenerate: 'Regenerate',
    save: 'Save',
    addPasskey: 'Create a passkey',
  },
  label: {
    site: 'Site',
  },
  personalInformation: {
    title: 'Personal information',
    firstname: 'First name',
    lastname: 'Last name',
    phone: 'Phone',
    email: 'Email',
  },
  settings: {
    title: 'Settings',
    vnc: 'Variables selector',
    searchHighlight: 'Highlight words that match searched terms',
  },
  security: {
    title: 'Security',
    password: 'Password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmation: 'Confirmation',
  },
  preference: {
    title: 'Preferences',
    language: 'Language',
    languageDico: {
      es: 'Español',
      fr: 'Français',
      en: 'English',
    },
    timezone: {
      title: 'Timezone',
      local: 'Local hour',
      site: 'Industrial site time',
      configure: 'Configure',
    },
  },
  twofa: {
    title: 'Two-factor authentication',
    phone: 'Phone',
    mail: 'Email',
    totp: 'Authenticator app',
    passkey: 'Passkeys',
    configure: 'Configure',
    modal: {
      phoneTitle: 'Enter your phone number',
      phoneError: 'Invalid phone number',
      emailTitle: 'Enter your email',
      email: 'Email',
      emailError: 'Invalid email',
      OTPTitle: 'Configure your authenticator app',
      OTPHelp: 'Scan the QR Code in your favorite authenticator application and enter the verification code',
      OTPKey: 'Key',
      OTPTimeBased: 'Time-Based',
      OTPBackToQRCode: 'Scan QR code',
      webauthnTitle: 'Passkey settings',
      type: 'Type',
      cantScanHelper: 'To add the entry manually, provide the following details to the application on your phone',
      cantScan: "Can't scan the code ?",
      codeTitle: 'Enter the verification code',
      code: 'Verification code',
      codeError: 'Invalid code',
      codeSentToEmail1: 'Code sent!',
      codeSentToEmail2: 'Please confirm the two-factor activation by entering the code we sent you at {{email}}.',
      codeSentToEmail2_noEmail: 'Please confirm the two-factor activation by entering the code we sent you by <strong>email</strong>.',
      codeSentToPhone1: 'Code sent!',
      codeSentToPhone2: 'Please confirm the two-factor activation by entering the code we sent you to {{phone}}.',
      codeSentToPhone2_noPhone:
        'Please confirm the two-factor activation by entering the code we sent you by <strong>text message</strong>.',
      pleaseWaitForCode: 'The reception of your code might take a minute. Your code stays valid for 5 minutes.',
      sendCodeAgain: 'Receive a new code.',
      enrollError: 'An error occurred while sending the code to {{email}}. Please try again later.',
    },
    createdAt: 'Creation date',
    lastUsed: 'Last used',
    notYetUsed: 'Not yet used',
    modify: 'Edit',
    delete: 'Delete',
    active: 'Activated',
    inactive: 'Disabled',
    editForbidden: 'You cannot edit your last double authentication method',
    deleteForbidden: 'You cannot delete your last double authentication method',
    webauthnHelper:
      'Passkeys enable you to securely sign in to your account passwordless or with two factor authentication, using your fingerprint, face, or hardware security key.',
  },
  token: {
    title: 'API',
    key: 'Private API Tokens',
    noUserToken: 'No API token',
    noSelectedSite: 'No selected site',
  },
  sessions: {
    title: 'Your devices',
    nbByDeviceType: '{{value}} device(s) on {{deviceType}}',
    current: 'Your current device',
    unknownBrowser: 'Unknown',
    unknownOS: 'Others',
    browser: 'Web browser',
    addressIp: 'Address IP',
    buttonDelete: 'Disconnect',
    device_one: '{{value}} device on {{deviceType}}',
    device_other: '{{value}} devices on {{deviceType}}',
  },
  toast: {
    copyToClipboard: "Token's UUID copied to clipboard",
  },

  error: {
    notSamePassword: 'The passwords do not match',
    incorrectPassword: 'Wrong password',
    invalidStrength: 'Please choose a stronger password. Use a mix of letters, numbers and symbols.',
    emptyField: 'Please, fill all required fields',
    apiProblem: 'Connexion problem',
    authenticator: 'Your authenticator could not generate a passkey',
    webauthnUnsupported: 'Your browser does not support WebAuthn',
  },
  tooltip: {
    showPassword: 'Show password',
    hidePassword: 'Hide password',
    token: 'Token',
  },
};
