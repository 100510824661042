export default {
  title: {
    settings: 'Ajustes',
    columns: 'Columnas',
  },
  label: {
    field: 'Columna nº{{index}}',
    gridUseHeader: 'Este archivo contiene un encabezado',
    valuesStart: 'Inicio de los valores',
    valuesEnd: 'Fin de los valores',
    notSelected: 'No seleccionado',
  },
  text: {
    lineNumber: 'línea nº',
  },
  button: {
    clear: 'Vaciar',
  },
};
