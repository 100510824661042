import { ListItem, ListItemButton, ListSubheader, Stack, Typography } from '@mui/material';
import { MouseEventHandler, ReactNode } from 'react';

import { StandardBlockMetricBody, StandardBlockParameterBody } from '@dametis/core';

export interface ElementsListProps<T extends StandardBlockParameterBody | StandardBlockMetricBody> {
  title: string;
  elements: T[];
  noElementLabel: string;
  onClickElement: (blockKey: string) => MouseEventHandler<HTMLDivElement>;
  renderElement: (element: T) => ReactNode;
}

const ElementsList = <T extends StandardBlockParameterBody | StandardBlockMetricBody>({
  title,
  elements,
  noElementLabel,
  onClickElement,
  renderElement,
}: ElementsListProps<T>) => {
  return (
    <li>
      <ul>
        <ListSubheader
          disableGutters
          sx={{ lineHeight: '21px', px: 0, py: 1, backgroundColor: theme => `${theme.palette.background.paper}!important` }}
        >
          {title}
        </ListSubheader>
        {elements.map((element, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ p: 0 }} onClick={onClickElement(element.blockKey)}>
              <Stack alignItems="center" direction="row" gap={1} width={1}>
                {renderElement(element)}
              </Stack>
            </ListItemButton>
          </ListItem>
        ))}
        {elements.length === 0 && (
          <ListItem disablePadding>
            <Typography align="center" p={2} variant="subtitle2" width={1}>
              {noElementLabel}
            </Typography>
          </ListItem>
        )}
      </ul>
    </li>
  );
};

export default ElementsList;
