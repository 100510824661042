import { FiberManualRecord } from '@mui/icons-material';
import { Badge, Box, Chip, Collapse, Stack, SvgIcon, Typography } from '@mui/material';
import { FC, MouseEventHandler, TouchEventHandler, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { AlarmInfo } from '@dametis/core';

import { useActiveAlarms } from 'store/api/activeAlarms';
import { RouteWithIndex } from 'types/drawer';
import { useLayoutStore } from 'zustand/stores/layout';

import { DrawerButtonButtonBase, DrawerButtonStack, DrawerLabel, DrawerNavLink, S_DBB_PX } from './DrawerButton.styled';

const activeAlarmsEmptyArray: AlarmInfo[] = [];

interface Props {
  route: RouteWithIndex;
}

const DrawerButton: FC<Props> = ({ route }) => {
  const { t, i18n } = useTranslation('navbar');
  const isActive = Boolean(useMatch(`${route.url}/*`));

  const { data: { length: activeAlarmsLength } = activeAlarmsEmptyArray } = useActiveAlarms();

  const setHoveredRoute = useLayoutStore(state => state.setHoveredRoute);
  const isExtendedDrawer = useLayoutStore(state => state.isExtendedDrawer);
  const setIsTouch = useLayoutStore(state => state.setIsTouch);

  const hasTouched = useRef(false);

  const setRoute = useCallback(() => {
    if (!route.Secondary) {
      setHoveredRoute(null);
      return;
    }
    setHoveredRoute(route);
  }, [route, setHoveredRoute]);

  const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    event => {
      if (hasTouched.current) {
        if (route.Secondary) {
          event.preventDefault();
        }
        hasTouched.current = false;
      }
      setRoute();
    },
    [route.Secondary, setRoute],
  );

  const handleTouch = useCallback<TouchEventHandler<HTMLAnchorElement>>(() => {
    hasTouched.current = true;
    setIsTouch(true);
  }, [setIsTouch]);

  return (
    <DrawerNavLink to={route.url} onClick={handleClick} onMouseEnter={setRoute} onTouchStart={handleTouch}>
      <DrawerButtonStack className="drawerButtonStack">
        <DrawerButtonButtonBase isActive={isActive} isExtendedDrawer={isExtendedDrawer}>
          <Badge badgeContent={route.name === 'alarms' ? activeAlarmsLength : 0} color="error">
            <SvgIcon component={isActive ? route.activeIcon : route.icon} />
          </Badge>
          <Collapse in={isExtendedDrawer} orientation="horizontal">
            <Stack alignItems="center" direction="row" spacing={1} sx={{ pl: 1.5, flexGrow: 1 }}>
              <DrawerLabel color="inherit">{t(`${route.name}.button`)}</DrawerLabel>
              {route.name === 'monitoring' && (
                <Chip
                  label={
                    <Typography color="white" textTransform="uppercase" variant="caption">
                      {t('monitoring.beta').toUpperCase()}
                    </Typography>
                  }
                  size="small"
                  sx={{ color: 'white', borderColor: 'white' }}
                  variant="outlined"
                />
              )}
            </Stack>
          </Collapse>
        </DrawerButtonButtonBase>
        {!isExtendedDrawer && (
          <Box sx={{ ml: S_DBB_PX, width: 24, position: 'relative' }}>
            <DrawerLabel
              color="inherit"
              isActive={isActive}
              sx={{ fontSize: 11, position: 'absolute', left: '50%', transform: 'translateX(-50%)', mt: 0.5, lineHeight: 1 }}
            >
              {t(
                `${route.name}.button`,
                i18n.exists(`navbar:${route.name}.button`, { context: 'short' }) ? { context: 'short' } : undefined,
              )}
              {route.name === 'monitoring' && (
                <FiberManualRecord
                  sx={{
                    color: 'white',
                    width: '10px',
                    height: '10px',
                    position: 'absolute',
                    top: -10,
                    right: -10,
                  }}
                />
              )}
            </DrawerLabel>
          </Box>
        )}
      </DrawerButtonStack>
    </DrawerNavLink>
  );
};

export default DrawerButton;
