export default {
  input: {
    label: {
      addFile: 'New File',
      filename: 'Filename',
      language: 'Language',
      insertVariable: 'Insert Variable',
      addLib: 'Libraries',
    },
  },
  title: {
    newFile: 'New File',
  },
  button: {
    cancel: 'Cancel',
    create: 'Create',
  },
  tooltip: {
    fullScreen: 'Fullscreen',
    exitFullScreen: 'Exit Fullscreen',
    deleteFile: 'Delete File',
  },
  text: {
    unknownId: 'Unknown UUID',
  },
};
