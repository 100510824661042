import { Components } from '@mui/material';

export default {
  styleOverrides: {
    paper: {
      '& > div': {
        maxHeight: '80vh',
        overflow: 'auto',
      },
    },
  },
} as Components['MuiPopover'];
