export default {
  title: {
    newMacroLib: 'New library',
  },
  button: {
    createMacroLib: 'Create',
    deleteMacroLib: 'Delete',
    editMacroLib: 'Edit',
    saveMacroLib: 'Save',
    cancel: 'Cancel',
  },
  toast: {
    createMacroLibSuccess: 'Library created successfully.',
    createMacroLibError: 'An error occurred while creating the library.',
    saveMacroLibSuccess: 'Changes saved.',
    saveMacroLibError: 'An error occurred while saving this library changes.',
    deleteMacroLibSuccess: 'Library deleted successfully.',
    deleteMacroLibError: 'An error occurred while deleting the library.',
    duplicateMacroLibSuccess: 'Library duplicated successfully.',
    duplicateMacroLibError: 'An error occurred while duplicating the library.',
  },
  input: {
    label: {
      name: 'Name',
      description: 'Description',
    },
  },
};
