import {
  Autocomplete,
  AutocompleteProps,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  TextFieldProps,
  UseAutocompleteProps,
  createFilterOptions,
} from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Normalize, ShortTagInfo, TagInfo } from '@dametis/core';

import { useCreateTagMutation, useTags } from 'store/api/tags';
import { useSelector } from 'store/index';

const tagsEmptyArray: ShortTagInfo[] = [];

export interface TagsAutoCompleteProps
  extends Omit<
    AutocompleteProps<ShortTagInfo, true, false, false>,
    'value' | 'onChange' | 'options' | 'renderTags' | 'getOptionLabel' | 'renderInput' | 'filterOptions' | 'open' | 'multiple'
  > {
  /**
   * Array of selected tags.
   */
  value: ShortTagInfo[];

  /**
   * Callback fired when the value is changed.
   */
  onChange: (newValue: ShortTagInfo[]) => void;

  /**
   * Array of availables tags.
   * "default" value fill the array with all site's tags
   * @default 'default'
   */
  options?: 'default' | TagInfo[];

  /**
   * Allow tag creation.
   * @default true
   */
  canAddTag?: boolean;

  /**
   * The label content.
   */
  label?: TextFieldProps['label'];

  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  placeholder?: TextFieldProps['placeholder'];

  /**
   * maximum number of tags
   * @default 'none'
   */
  max?: 'none' | number;

  /**
   * Enable autoFocus on TextField
   */
  autoFocus?: boolean;
}

const TagAutocomplete: FC<TagsAutoCompleteProps> = ({
  value,
  onChange,
  options = 'default',
  canAddTag = true,
  label = undefined,
  placeholder = '',
  max = 'none',
  autoFocus = false,
  ...props
}) => {
  const { t } = useTranslation('tags');

  const { data: siteTags = tagsEmptyArray } = useTags();
  const [createTag] = useCreateTagMutation();

  const corporate = useSelector(state => !state.auth.selectedSite);

  const [maxError, setMaxError] = useState<boolean>(false);

  const availableTags = useMemo(() => (options === 'default' ? siteTags : options), [options, siteTags]);

  const filterOptions = useMemo(() => createFilterOptions<ShortTagInfo>({ trim: true }), []);

  const tagNameExists = useCallback(
    (tag: ShortTagInfo) => value.some(existingTag => Normalize(existingTag.name) === Normalize(tag.name)),
    [value],
  );

  const isTagSaved = useCallback((tag: ShortTagInfo) => tag.uuid !== '', []);

  const handleNewTag = useCallback(
    async (tags: ShortTagInfo[], newTag: ShortTagInfo) => {
      if (max === 'none' || tags.length < max) {
        if (!isTagSaved(newTag)) {
          const newTagSaved = await createTag({ name: newTag.name }).unwrap();
          onChange([...tags, newTagSaved]);
        } else {
          onChange([...tags, newTag]);
        }
      } else {
        setMaxError(true);
      }
    },
    [isTagSaved, max, onChange, createTag],
  );

  const isOptionEqualToValue = useCallback<NonNullable<UseAutocompleteProps<ShortTagInfo, true, false, false>['isOptionEqualToValue']>>(
    (option, selectedValue) => option.uuid === selectedValue.uuid,
    [],
  );

  const handleChange = useCallback<NonNullable<UseAutocompleteProps<ShortTagInfo, true, false, false>['onChange']>>(
    async (e, v, reason, details) => {
      if (reason === 'selectOption' && details !== undefined) {
        await handleNewTag(value, details.option);
      }
      if (reason === 'removeOption' && details !== undefined && details.option.uuid !== '') {
        onChange(value.filter(tag => tag.uuid !== details.option.uuid));
      }
      if (reason === 'clear') {
        onChange([]);
      }
    },
    [value, handleNewTag, onChange],
  );

  if (corporate) return null;
  return (
    <>
      <Autocomplete<ShortTagInfo, true, false, false>
        clearOnBlur
        filterSelectedOptions
        handleHomeEndKeys
        multiple
        selectOnFocus
        filterOptions={(opts, params) => {
          const filtered = filterOptions(opts, params).filter(tag => value.every(v => v.uuid !== tag.uuid));
          const { inputValue } = params;
          if (canAddTag && Normalize(inputValue) !== '' && opts.every(option => Normalize(inputValue) !== Normalize(option.name))) {
            filtered.push({
              name: inputValue,
              normalizedName: Normalize(inputValue),
              uuid: '',
              parentId: '',
              // canEdit: true,
              // createdAt: new Date(),
              // updatedAt: new Date(),
            });
          }
          return filtered;
        }}
        getOptionLabel={option => {
          return !tagNameExists(option) && isTagSaved(option) ? option.name : `${option.name} (${t('tagAutocomplete.newTag')})`;
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        noOptionsText={!canAddTag && t('text.noOptions')}
        options={availableTags}
        renderInput={params => <TextField {...params} fullWidth autoFocus={autoFocus} label={label} placeholder={placeholder} />}
        renderTags={(selectedTags, getTagProps) =>
          selectedTags.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return <Chip key={option.uuid ?? `newTag${index}`} label={option.name} size="small" {...tagProps} />;
          })
        }
        value={value}
        onChange={handleChange}
        {...props}
      />
      <Dialog open={maxError}>
        <DialogContent>{`${max} ${t('error.maxTagAllowed')}`}</DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" onClick={() => setMaxError(false)}>
            {t('button.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TagAutocomplete;
