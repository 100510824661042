import { Stack, TextField, useTheme } from '@mui/material';
import { ChangeEventHandler, Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getFolderColor } from 'components/Lego/helpers/getFolderColor';
import { FolderBody } from 'components/Lego/types';
import ColorPicker, { ColorPickerProps } from 'components/UI/ColorPicker/ColorPicker';

export interface StepInformationsProps<T extends FolderBody> {
  folderBody: T;
  setFolderBody: Dispatch<SetStateAction<T>>;
}

const StepInformations = <T extends FolderBody = FolderBody>({ folderBody, setFolderBody }: StepInformationsProps<T>) => {
  const { t } = useTranslation('lego');
  const theme = useTheme();

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setFolderBody(state => ({ ...state, name: event.target.value }));
    },
    [setFolderBody],
  );

  const handleChangeColor = useCallback<NonNullable<ColorPickerProps['onColorChange']>>(
    newColor => {
      setFolderBody(state => ({ ...state, ui: { ...(state.ui ?? {}), color: newColor.hex } }));
    },
    [setFolderBody],
  );

  const handleChangeComment: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setFolderBody(state => ({ ...state, ui: { ...(state.ui ?? {}), comment: event.target.value } }));
    },
    [setFolderBody],
  );

  return (
    <>
      <Stack alignItems="flex-end" direction="row" mb={2} spacing={1}>
        <TextField autoFocus fullWidth label={t('label.name')} value={folderBody.name} onChange={handleChangeName} />
        <ColorPicker size="small" value={getFolderColor(folderBody, theme)} variant="toggle" onColorChange={handleChangeColor} />
      </Stack>
      <TextField
        fullWidth
        multiline
        label={t('label.comment')}
        minRows={2}
        size="small"
        value={folderBody.ui?.comment ?? ''}
        variant="outlined"
        onChange={handleChangeComment}
      />
    </>
  );
};

export default StepInformations;
