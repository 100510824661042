export default {
  alertMessage: {
    wrongPasswordNotMatch: 'The passwords do not match',
    emptyPassword: 'You must enter a password',
    invalidStrength: 'Please choose a stronger password. Use a mix of letters, numbers and symbols.',
    emptyField: 'Please, fill all required fields',
    successInit: 'Success initialization',
    failInit: 'Fail initialization',
    invalidInitToken: 'An error has occurred, please contact ',
    logDeadApi: 'An error occured, please try again later',
  },
  title: {
    titleSection: 'Account Activation',
  },
  input: {
    firstname: 'First name',
    lastname: 'Last name',
    language: 'Language',
    email: 'Email',
    langue: {
      label: 'Choose a language',
      fr: 'French',
      en: 'English',
      es: 'Spanish',
    },
    password: 'Password',
    passwordConfirm: 'Confirmation',
  },
  button: {
    initialize: 'Active your account',
  },
};
