import { Box, styled } from '@mui/material';

const RightPanelHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  width: '100%',
  height: 24,
  margin: `${theme.others.pagePaddingTop} 0 ${theme.spacing(4)} 0`,
  padding: `0 ${theme.spacing(2)}`,
  flexShrink: 0,
  flexGrow: 0,
  justifyContent: 'flex-start',
}));

export default RightPanelHeader;
