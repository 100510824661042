import { RefreshOutlined } from '@mui/icons-material';
import { Alert, CircularProgress, Grid, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CSSTransition from 'react-transition-group/CSSTransition';

import logo from 'assets/images/dametis/logo-reconnect.svg';
import dametis from 'assets/images/dametis/logotext-reconnect.svg';
import { useDispatch, useSelector } from 'store';
import { getCurrentUser } from 'store/actions/auth';
import { AuthStatus, TwoFAError } from 'types/auth';

import useReconnectStyles from './Reconnect.styles';

const Reconnect: FC<PropsWithChildren> = ({ children = undefined }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation('login');

  const user = useSelector(state => state.auth.user);
  const status = useSelector(state => state.auth.status);

  const reload = useCallback(async () => {
    try {
      await dispatch(getCurrentUser(true));
    } catch (err) {
      console.error(err);
      if (Object.values(TwoFAError).includes(err.message)) {
        navigate('/login/enrollment', { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (user !== null) {
      return;
    }
    void reload();
  }, [user, reload]);

  const classes = useReconnectStyles();
  return (
    <div>
      {children}
      <CSSTransition
        unmountOnExit
        classNames={{
          exit: classes['reconnect--exit'],
          exitActive: classes['reconnect--exitActive'],
        }}
        in={[AuthStatus.LOADING, AuthStatus.ERROR].includes(status)}
        timeout={theme.transitions.duration.standard}
      >
        <Grid container alignItems="center" className={classes.reconnect} direction="column" justifyContent="center">
          <img alt="logo" className={classes.logo} src={logo} />
          <img alt="dametis" className={classes.dametis} src={dametis} />
          {status === AuthStatus.ERROR ? (
            <Stack alignItems="center" spacing={4}>
              <Alert severity="error">{t('reconnect.error')}</Alert>
              <Tooltip title={t('tooltip.reload')}>
                <IconButton onClick={reload}>
                  <RefreshOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          ) : (
            <CircularProgress color="inherit" size="2rem" />
          )}
        </Grid>
      </CSSTransition>
    </div>
  );
};

export default Reconnect;
