import { Box, styled } from '@mui/material';

export interface UserAvatarBorderProps {
  clickable: boolean;
  checked: boolean;
}

export const UserAvatarBorder = styled(Box, {
  shouldForwardProp: propName => propName !== 'clickable' && propName !== 'checked',
})<UserAvatarBorderProps>(({ clickable, checked, theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  borderRadius: '100%',
  outlineStyle: 'solid',
  outlineWidth: 0,
  ...(clickable && {
    cursor: 'pointer',
    '&:hover': {
      outlineColor: theme.palette.secondary.light,
      outlineWidth: '3px',
    },
  }),
  ...(checked && {
    outlineColor: theme.palette.secondary.light,
    outlineWidth: '3px',
  }),
}));
