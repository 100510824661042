import { DeleteOutlined } from '@mui/icons-material';
import { FormLabel, IconButton, Stack } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';

import { SourceColumn, SourceColumnRealByHeader, SourceColumnRealByIndex, VarCalc } from '@dametis/core';

import SourceColumnPicker from 'components/Data/FileImport/UI/SourceColumnPicker/SourceColumnPicker';
import { useVariableName } from 'hooks/useVariableProp';

import { FormFixedVariable } from './MappingSettingsContext';

export interface FixedVariablePickerProps {
  value: FormFixedVariable;
  onChange: (newValue: FormFixedVariable) => void;
  onDelete?: () => void;
  availableRealByIndexColumns: SourceColumnRealByIndex[];
  availableRealByHeaderColumns: SourceColumnRealByHeader[];
  availableVirtualColumnNames: string[];
  usedSources: SourceColumn[];
}

const FixedVariablePicker: FC<FixedVariablePickerProps> = ({
  value,
  onChange,
  onDelete = undefined,
  availableRealByIndexColumns,
  availableRealByHeaderColumns,
  availableVirtualColumnNames,
  usedSources,
}) => {
  const varCalc: VarCalc = useMemo(() => ({ variableUuid: value.variableId ?? '' }), [value.variableId]);

  const variableName = useVariableName(varCalc);

  const handleChangeSourceColumn = useCallback(
    (newSource: SourceColumn) => {
      onChange({ ...value, source: newSource });
    },
    [value, onChange],
  );

  return (
    <Stack gap={0.25}>
      <FormLabel>{variableName}</FormLabel>
      <Stack alignItems="center" direction="row" gap={0.5}>
        <SourceColumnPicker
          fullWidth
          availableRealByHeaderColumns={availableRealByHeaderColumns}
          availableRealByIndexColumns={availableRealByIndexColumns}
          availableVirtualColumnNames={availableVirtualColumnNames}
          usedSources={usedSources}
          value={value.source}
          onChange={handleChangeSourceColumn}
        />

        {onDelete && (
          <IconButton size="small" onClick={onDelete}>
            <DeleteOutlined fontSize="small" />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default FixedVariablePicker;
