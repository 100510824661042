import { ModelPreviewType } from 'components/Model/config/models';

export default {
  title: {
    newModel: 'New model',
    responseVariable: 'Response variable',
    explanatoryVar: 'Explanatory variables',
    period: 'Reference period',
    params: 'Settings',
    equation: 'Equation',
    variables: 'Variables',
    vncEquation: 'Equation',
    newModelDialog: 'New model',
  },
  subtitle: {
    noMode: 'This model is not yet configured',
  },
  text: {
    noVariables: 'No variables',
    noYVariable: 'No response variable',
    noXVariables: 'No explanatory variables',
  },
  typography: {
    formula: 'Equation',
    r2: 'R²',
    mape: 'MAPE',
    rmse: 'RMSE',
    min: 'Min',
    max: 'Max',
    coefficient: 'Coefficient',
    pValue: 'P-value',
    standardDeviation: 'Std. dev.',
    covariance: 'Covariance',
  },
  button: {
    create: 'Create',
    cancel: 'Cancel',
    yVarMode: 'Model a variable',
    yVarMode_short: 'Variable',
    equationMode: 'Write the equation directly',
    equationMode_short: 'Equation',
    editModel: 'Edit this model',
    saveModel: 'Save this model',
    createModel: 'Create this model',
    deleteModel: 'Delete this model',
    duplicateModel: 'Duplicate this model',
    cancelChanges: 'Cancel the changes',
    modifyYVariable: 'Choose',
    addXVariable: 'Add',
    chooseCalculationVariable: 'Choose an existing variable',
    chooseCalculationVariable_short: 'Variable',
    choosePointsVariable: 'Input manual data',
    choosePointsVariable_short: 'Data',
  },
  input: {
    label: {
      groupBy: 'Sub-periods',
      name: 'Name',
      from: 'From',
      to: 'To',
    },
  },
  tooltip: {
    createModel: 'create a model',
    createInstanceModel: 'create an instance of this model',
    duplicateModel: 'duplicate this model',
    editModel: 'edit this model',
    deleteModel: 'delete this model',
    kindRequired: 'You cannot save a model without choosing its kind first',
    yVarRequired: 'You cannot save a model without a response variable',
    hideXVar: 'Variable displayed on the chart',
    showXVar: 'Variable hidden on the chart',
    enableXVar: 'Variable not taken into account in the model',
    disableXVar: 'Variable taken into account in the model',
    deleteXVar: 'Delete this variable',
    preview: {
      [ModelPreviewType.LINE]: 'Line chart',
      [ModelPreviewType.BAR]: 'Bar chart',
    },
    r2: 'Coefficient of determination. The R² measures the variance explained by the model. It varies between 0 and 1. An R² = 1 indicates that the model explains all the variability in the data, while an R² = 0 indicates that it explains no variability. The closer the R² is to 1, the more the model is able to capture relationships between variables.',
    mape: 'Mean absolute percentage error. The MAPE gives an idea of the error in percentage. The lower the percentage, the better the model.',
    rmse: 'Root mean square error. RMSE measures the accuracy of the model. It indicates how much, on average, the predictions deviate from the actual values. The lower the RMSE, the better the model performance.',
    pValue:
      'The p-value measures the probability that the impact of this variable is linked to chance. It is between 0 and 1, and must be as low as possible. The threshold of 0.05 is often used as a confidence threshold.',
    standardDeviation:
      'Standard deviation. It measures the dispersion of the data around the mean. The lower the standard deviation, the more the data is concentrated around the mean.',
  },
  highcharts: {
    modelSeries: 'Model',
  },
  dataGrid: {
    model: 'Model',
  },
};
