import { FindFolder, FolderInfo, ShortcutCategory, UUID } from '@dametis/core';

export const getBlockTypeUrl = (globalRootFolder: FolderInfo | null, blockTypeId: UUID): string => {
  if (!globalRootFolder) {
    return '#';
  }
  const parentFolder = FindFolder(
    globalRootFolder,
    child =>
      child.shortcuts.find(shortcut => shortcut.category === ShortcutCategory.BLOCK_TYPE && shortcut.uuid === blockTypeId) !== undefined,
  );
  if (!parentFolder) {
    return `/blockTypes/noFolder/${blockTypeId}`;
  }
  return `/blockTypes/${parentFolder.uuid}/${blockTypeId}`;
};
