import { Box, List, ListSubheader, Stack, Typography } from '@mui/material';
import Fuse from 'fuse.js';
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { useSlateStatic } from 'slate-react';
import { Key } from 'ts-key-enum';

import { Energies, MathFunctionHelper, MathFunctionName } from '@dametis/core';

import { mathFunctions } from 'config/functions';

import EnergyIcon from '../../../../UI/EnergyIcon/EnergyIcon';
import SearchInput from '../../../../UI/SearchInput/SearchInput';
import { insertFunction } from '../../../slate/function';

import FunctionListItem from './FunctionListItem';
import { LocaleMathFunctionHelper } from './types';

const getCurrentFunction = (
  functions: [Energies, LocaleMathFunctionHelper[]][],
  selected: MathFunctionName,
): { categoryIndex: number; functionIndex: number } => {
  let categoryIndex = 0;
  let functionIndex = 0;
  for (let i = 0; i < functions.length; i += 1) {
    for (let j = 0; j < functions[i][1].length; j += 1) {
      if (functions[i][1][j].name === selected) {
        categoryIndex = i;
        functionIndex = j;
        break;
      }
    }
  }
  return { categoryIndex, functionIndex };
};

const functionsArr = Object.values(mathFunctions);

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const FunctionsPaper: FC<Props> = ({ setOpen }) => {
  const editor = useSlateStatic();
  const { t } = useTranslation(['vnc', 'global']);

  const [search, setSearch] = useState('');
  const [selectedFunction, setSelectedFunction] = useState<MathFunctionName | null>(null);

  const localeFunctions = useMemo(
    () =>
      functionsArr.map(func => ({
        ...func,
        localeName: t(`functions.name.${func.name}`),
        localeCategory: t(`global:energy.${func.category}`),
        localeDescription: t(`functions.description.${func.name}`),
        output: {
          ...func.output,
          localeType: t(`functions.type.${func.output.type}`),
        },
      })),
    [t],
  );
  const fuse = useMemo(
    () =>
      new Fuse(localeFunctions, {
        keys: ['localeName', 'localeCategory', 'localeDescription', 'output.localeType', 'output.unit'],
      }),
    [localeFunctions],
  );
  const functions = useMemo(
    () =>
      Object.entries(
        (search ? fuse.search(search).map(res => res.item) : localeFunctions).reduce<
          Record<MathFunctionHelper['category'], LocaleMathFunctionHelper[]>
        >(
          (acc, func) => {
            if (!acc[func.category]) acc[func.category] = [];
            acc[func.category].push(func);
            return acc;
          },
          {} as Record<MathFunctionHelper['category'], LocaleMathFunctionHelper[]>,
        ),
      )
        .map(([category, funcs]): [MathFunctionHelper['category'], Array<LocaleMathFunctionHelper>] => [
          category as MathFunctionHelper['category'],
          funcs.sort((a, b) => a.localeName.localeCompare(b.localeName)),
        ])
        .sort(([a], [b]) => t(`global:energy.${a}`).localeCompare(t(`global:energy.${b}`))),
    [fuse, localeFunctions, search, t],
  );

  useEffect(() => {
    setSelectedFunction(functions[0]?.[1][0]?.name ?? null);
  }, [functions]);

  useHotkeys(
    Key.ArrowDown,
    () => {
      if (!selectedFunction) return;
      const { categoryIndex, functionIndex } = getCurrentFunction(functions, selectedFunction);
      const nextFunction =
        functions[categoryIndex][1][functionIndex + 1]?.name ??
        functions[categoryIndex + 1]?.[1][0]?.name ??
        functions[0][1][0]?.name ??
        null;
      setSelectedFunction(nextFunction);
    },
    { enableOnContentEditable: true, enableOnFormTags: true, preventDefault: true },
    [functions, selectedFunction],
  );

  useHotkeys(
    Key.ArrowUp,
    () => {
      if (!selectedFunction) return;
      const { categoryIndex, functionIndex } = getCurrentFunction(functions, selectedFunction);
      const previousFunction =
        functions[categoryIndex][1][functionIndex - 1]?.name ??
        functions[categoryIndex - 1]?.[1][functions[categoryIndex - 1][1].length - 1]?.name ??
        functions[functions.length - 1][1][functions[functions.length - 1][1].length - 1]?.name ??
        null;
      setSelectedFunction(previousFunction);
    },
    { enableOnContentEditable: true, enableOnFormTags: true, preventDefault: true },
    [functions, selectedFunction],
  );

  useHotkeys(
    Key.Enter,
    () => {
      if (!selectedFunction) return;
      setOpen(false);
      setSearch('');
      insertFunction(editor, selectedFunction);
    },
    { enableOnContentEditable: true, enableOnFormTags: true, preventDefault: true },
    [editor, selectedFunction, setOpen],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: 400 }}>
      <Box sx={{ px: 0.75, pt: 2, pb: 0.5, textAlign: 'right' }}>
        <SearchInput autoFocus value={search} onChange={setSearch} />
      </Box>
      <List sx={{ position: 'relative', overflowY: 'auto', pb: 0.75, '& ul': { p: 0 } }}>
        {functions.length === 0 && (
          <Typography align="center" sx={{ py: 1 }} variant="subtitle2">
            {t('subtitle.noFunctions')}
          </Typography>
        )}
        {functions.map(([category, funcs]) => (
          <li key={category}>
            <ul>
              <ListSubheader>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <EnergyIcon energy={category} />
                  <span>{t(`global:energy.${category}`)}</span>
                </Stack>
              </ListSubheader>
              {funcs.map(func => (
                <FunctionListItem
                  key={func.name}
                  func={func}
                  selected={func.name === selectedFunction}
                  setOpen={setOpen}
                  setSearch={setSearch}
                />
              ))}
            </ul>
          </li>
        ))}
      </List>
    </Box>
  );
};

export default FunctionsPaper;
