import { Box, Theme, styled, useMediaQuery } from '@mui/material';
import { FC, PropsWithChildren, useEffect } from 'react';

import { useLocalStorage } from 'hooks/useLocalStorage';
import { useSelector } from 'store';
import { useLayoutStore } from 'zustand/stores/layout';

import AppBar from './AppBar/AppBar';
import Drawer from './Drawer/Drawer';

const Center = styled(Box, {
  shouldForwardProp: propName => propName !== 'isExtendedDrawer',
})<{ isExtendedDrawer: boolean }>(({ theme, isExtendedDrawer }) => ({
  position: 'relative',
  minHeight: '100vh',
  paddingLeft: isExtendedDrawer ? theme.others.navBarWidthOpen : theme.others.navBarWidthClosed,
  transition: theme.transitions.create('padding-left'),
}));

const DefaultLayout: FC<PropsWithChildren> = ({ children = undefined }) => {
  const isWide = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
  const userId = useSelector(state => state.auth.user?.uuid);
  const [isExtendedDrawerStorage] = useLocalStorage('isExtendedDrawer', undefined, { userId });
  const setExtendedDrawer = useLayoutStore(state => state.setExtendedDrawer);
  const isExtendedDrawer = useLayoutStore(state => state.isExtendedDrawer);

  useEffect(() => {
    if (isExtendedDrawerStorage === undefined) {
      setExtendedDrawer(isWide);
    } else {
      setExtendedDrawer(isExtendedDrawerStorage);
    }
  }, [isWide, isExtendedDrawerStorage, setExtendedDrawer]);

  return (
    <>
      <Drawer />
      <Center isExtendedDrawer={isExtendedDrawer}>
        <AppBar />
        <main>{children}</main>
      </Center>
    </>
  );
};

export default DefaultLayout;
