import { CommentInfo, CreateCommentBody, RawPeriod, UUID, UpdateCommentBody } from '@dametis/core';

export const enum LinkedProjectType {
  NEW = 'new',
  EXISTING = 'existing',
}

export const enum CommentModalMode {
  CREATE = 'create',
  UPDATE = 'update',
}

export const enum ProjectModalMode {
  CREATE = 'create',
  UPDATE = 'update',
}

export enum EntityType {
  PROJECT = 'project',
  PLAYGROUND = 'playground',
  REPORT = 'report',
  SYNOPTIC = 'synoptic',
  VARIABLE = 'variable',
  TASK = 'task',
  ALARM = 'alarm',
}

export enum CommentSortBy {
  PERIOD_FROM = 'periodFrom',
  PERIOD_FROM_REVERSE = 'periodFromReverse',
  PERIOD_TO = 'periodTo',
  PERIOD_TO_REVERSE = 'periodToReverse',
  CREATION_DATE = 'creationDate',
  CREATION_DATE_REVERSE = 'creationDateReverse',
  DATE = 'date',
  DATE_REVERSE = 'dateReverse',
}

export enum CommentView {
  LIST = 'list',
  BY_PROJECT = 'byProject',
}

export enum CommentFilter {
  ALL = 'all',
  CURRENT_PERIOD = 'currentPeriod',
}

export interface CommentsProps {
  entity: EntityType;
  entityUuid: UUID | null;
  onCreateCb?: (comment: CreateCommentBody) => Promise<void>;
  onUpdateCb?: (comment: UpdateCommentBody) => Promise<void>;
}

export type CommentInfoWithPeriod = CommentInfo & { period: RawPeriod };
export const IsCommentInfoWithPeriod = (comment: CommentInfo): comment is CommentInfoWithPeriod => !!comment.period;
