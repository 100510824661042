import { CreateStandardBlockBody, StandardBlockUI } from '@dametis/core';

export const createCreateStandardBlockBody = ({
  name = '',
  description = '',
  blockTypeId = '',
  ui = createStandardBlockUI(),
  parameters = [],
  metrics = [],
  connections = [],
  standardMetadataList = [],
  folderId = '',
}: Partial<CreateStandardBlockBody> = {}): CreateStandardBlockBody => ({
  name,
  description,
  blockTypeId,
  ui,
  parameters,
  metrics,
  connections,
  standardMetadataList,
  folderId,
});

export const createStandardBlockUI = ({ color = null }: Partial<StandardBlockUI> = {}): StandardBlockUI => ({ color });
