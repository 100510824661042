import { DateSystem, FormatCategory } from '@dametis/core';

import { FormatPickerMenu } from 'types/format';

export default {
  text: {
    newOption: 'Formato personalizado: "{{value}}"',
    newOptionIncorrect: 'Formato incorrecto: "{{value}}"',
    unknownFormat: 'Formato desconocido',
    noFormatting: 'Sin formato',
    inputLabel: 'Formato',
    hideUnit: 'Ocultar la unidad',
    timezoneOffset: 'Adaptar a la zona horaria',
    dateSystem: 'Sistema de fechas',
    withUnit: 'con unidad',
    withoutUnit: 'sin unidad',
    withTimezoneOffset: 'Con desplazamiento horario',
    withoutTimezoneOffset: 'Sin desplazamiento horario',
    noElement: 'Ningún elemento',
  },
  subheader: {
    [FormatCategory.NUMBER]: 'Números',
    [FormatCategory.DATE]: 'Fechas',
    history: 'Histórico',
  },
  dateSystem: {
    [DateSystem.EXCEL_SERIAL_TIME]: 'Excel',
    [DateSystem.TIMESTAMP_UNIX]: 'Unix',
  },
  button: {
    ok: 'ok',
    clearHistory: 'vaciar',
  },
  tooltip: {
    menu: {
      [FormatPickerMenu.HISTORY]: 'Histórico',
      [FormatPickerMenu.LISTS]: 'Formatos',
      [FormatPickerMenu.SETTINGS]: 'Ajustes',
    },
    clear: 'Suprimir',
  },
  helper: {
    description: 'El formato se realiza según el estándar ECMA-376, similar a otros programas de hojas de cálculo.',
    redirection: 'Más ejemplos aquí.',
  },
};
