import { GridLocaleText } from '@mui/x-data-grid-premium';

export default {
  title: {
    macros: 'Macros',
  },
  text: {
    noMacros: 'Ninguna macro',
    noMacroLibs: 'Ninguna biblioteca',
  },
  button: {
    addMacro: 'Macro',
    addMacroLib: 'Biblioteca',
    resetCaches: 'Vaciar cachés',
  },
  table: {
    cells: {
      name: 'Nombre',
      description: 'Descripción',
      status: 'Estado',
    },
  },
  tabs: {
    tableMacroList: 'Macros',
    tableMacroLibList: 'Bibliotecas',
  },
  xgrid: {
    noMacrosLabel: 'Ninguna macro',
    footerTotalMacros: 'Total de macros:',
    noMacroLibsLabel: 'Ninguna biblioteca',
    footerTotalMacroLibs: 'Total de bibliotecas:',
  } as Partial<GridLocaleText>,
};
