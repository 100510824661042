import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RenderElementProps, useReadOnly, useSelected } from 'slate-react';

import { BlockElement, Operator } from '@dametis/core';

import { useLocalizedGroupBy } from 'localization/useLocalizedGroupBy';

import { BlockAggregator, BlockSpan } from '../styled';

import InlineChromiumBugfix from './InlineChromiumBugfix';

interface Props extends RenderElementProps {
  element: BlockElement;
}

const SlateBlock: FC<Props> = ({ element, attributes, children = undefined }) => {
  const { t } = useTranslation('global');
  const readOnly = useReadOnly();
  const selected = useSelected() && !readOnly;

  const groupBy = useLocalizedGroupBy(element.groupBy, true);

  return (
    <BlockSpan selected={selected} {...attributes}>
      {Boolean(element.operator) && element.operator !== Operator.HISTORY && (
        <BlockAggregator sx={theme => ({ position: 'absolute', top: theme.spacing(0.25), left: theme.spacing(0.25) })}>
          {t('operator.op', { context: element.operator })}
          {groupBy ? ` ${groupBy}` : ''}
        </BlockAggregator>
      )}
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </BlockSpan>
  );
};

export default SlateBlock;
