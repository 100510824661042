import { Divider, FormControlLabel, Stack, Switch } from '@mui/material';
import { FC, SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'store';
import { setNavigationGroupByType } from 'store/slices/lego';

const NavigationTools: FC = () => {
  const { t } = useTranslation('lego');
  const dispatch = useDispatch();

  const groupByType = useSelector(state => state.lego.navigationTools.groupByType);

  const handleChangeGroupByType = useCallback(
    (_event: SyntheticEvent<Element, Event>, checked: boolean) => {
      dispatch(setNavigationGroupByType(checked));
    },
    [dispatch],
  );

  return (
    <>
      <Divider />
      <Stack gap={2} p={2}>
        {/* <Typography variant="h6">{t('title.tools')}</Typography> */}
        <FormControlLabel
          checked={groupByType}
          control={<Switch color="secondary" />}
          label={t('label.groupByType')}
          labelPlacement="start"
          sx={{ justifyContent: 'space-between', ml: 0 }}
          onChange={handleChangeGroupByType}
        />
      </Stack>
    </>
  );
};

export default NavigationTools;
