import { AddBoxOutlined, DeleteOutlined, DriveFileMoveOutlined, EditOutlined, FileCopyOutlined } from '@mui/icons-material';
import { DialogContentText, FormLabel, Grid, Paper, Stack, Typography } from '@mui/material';
import { FC, MouseEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useResizeObserver } from 'usehooks-ts';

import { BlockTypeInfo, BlockTypeMetric, BlockTypeParameter, StandardBlockInfo, UUID } from '@dametis/core';

import BlockTypeConnectionPreview from 'components/Lego/UI/BlockType/BlockTypeConnectionPreview';
import BlockTypeIcon from 'components/Lego/UI/BlockType/BlockTypeIcon';
import BlockTypeMetadataPreview from 'components/Lego/UI/BlockType/BlockTypeMetadataPreview/BlockTypeMetadataPreview';
import EditBlockTypeModal from 'components/Lego/UI/BlockType/EditBlockTypeModal/EditBlockTypeModal';
import LegoMetricPreview from 'components/Lego/UI/LegoMetricPreview/LegoMetricPreview';
import LegoParameterPreview from 'components/Lego/UI/LegoParameterPreview/LegoParameterPreview';
import CreateStandardBlockModal from 'components/Lego/UI/StandardBlock/CreateStandardBlockModal/CreateStandardBlockModal';
import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import PromptBeforeDeleting from 'components/UI/PromptBeforeDeleting/PromptBeforeDeleting';
import { RightPanelActions, RightPanelBody, RightPanelSection, RightPanelSectionContent } from 'components/UI/RightPanel';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import { useDispatch } from 'store';
import { useBlockTypes, useDeleteBlockTypeMutation, useDuplicateBlockTypeMutation } from 'store/api/blockTypes';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import MoveToBlockTypeModal from '../../MoveToBlockTypeModal/MoveToBlockTypeModal';

export interface InformationsTabProps {
  blockType: BlockTypeInfo;
}

const blockTypesEmptyArray: BlockTypeInfo[] = [];

const InformationsTab: FC<InformationsTabProps> = ({ blockType }) => {
  const { t } = useTranslation('lego');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { folderId } = useParams<{ folderId?: UUID }>();

  const { data: blockTypes = blockTypesEmptyArray } = useBlockTypes();
  const [deleteBlockType, { isLoading: isDeleting }] = useDeleteBlockTypeMutation();
  const [duplicateBlockType, { isLoading: isDuplicating }] = useDuplicateBlockTypeMutation();

  const [isPromptBeforeDeletingOpen, setIsPromptBeforeDeletingOpen] = useState<boolean>(false);
  const [isCreateStandardBlockModalOpen, setIsCreateStandardBlockModalOpen] = useState<boolean>(false);
  const [isEditBlockTypeModalOpen, setIsEditBlockTypeModalOpen] = useState<boolean>(false);
  const [isMoveToBlockTypeModalOpen, setIsMoveToBlockTypeModalOpen] = useState<boolean>(false);

  const panelRef = useRef<HTMLDivElement>(null);

  const { width: panelWidth = 0 } = useResizeObserver({
    ref: panelRef,
  });

  const connectedToBlockTypes = useMemo(
    () =>
      blockTypes.filter(storeBlockType =>
        storeBlockType.connections.some(storeBlockTypeConnection => storeBlockTypeConnection.blockTypeId === blockType.uuid),
      ),
    [blockTypes, blockType.uuid],
  );

  const handleDuplicate: MouseEventHandler<HTMLButtonElement> = useCallback(async () => {
    try {
      const data = await duplicateBlockType({ uuid: blockType.uuid, name: `${blockType.name} - ${t('text.copy')}`, folderId }).unwrap();
      navigate(`/blockTypes/${folderId}/${data.uuid}`, { state: { noScrollTop: true } });
      dispatch(addToast({ message: t('toast.duplicateBlockTypeSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [blockType, dispatch, duplicateBlockType, folderId, navigate, t]);

  const handleEdit: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setIsEditBlockTypeModalOpen(true);
  }, []);

  const handleDelete: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setIsPromptBeforeDeletingOpen(true);
  }, []);

  const handleDeleteConfirm = useCallback(async () => {
    try {
      await deleteBlockType(blockType.uuid).unwrap();
      navigate(`/blockTypes/${folderId}`, { state: { noScrollTop: true } });
      dispatch(addToast({ message: t('toast.deleteBlockTypeSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [blockType.uuid, deleteBlockType, dispatch, folderId, navigate, t]);

  const handleUpdateBlockType = useCallback(
    (updatedBlockType: BlockTypeInfo) => {
      navigate(`/blockTypes/${folderId}/${updatedBlockType.uuid}`, { state: { noScrollTop: true } });
    },
    [navigate, folderId],
  );

  const handleCreateStandardBlock = useCallback(
    (createdStandardBlock: StandardBlockInfo, parentFolderId: UUID) => {
      navigate(`/blockTypes/${parentFolderId}/${createdStandardBlock.uuid}`, { state: { noScrollTop: true } });
    },
    [navigate],
  );

  const handleMoveTo = useCallback(() => {
    setIsMoveToBlockTypeModalOpen(true);
  }, []);

  const handleOpenCreateStandardBlockModal = useCallback(() => {
    setIsCreateStandardBlockModalOpen(true);
  }, []);

  return (
    <>
      <RightPanelActions>
        <ActionButton disabled={isDeleting || isDuplicating} startIcon={<FileCopyOutlined />} onClick={handleDuplicate}>
          {t('button.duplicate')}
        </ActionButton>
        <ActionButton disabled={isDeleting || isDuplicating} startIcon={<EditOutlined />} onClick={handleEdit}>
          {t('button.edit')}
        </ActionButton>
        <ActionButton disabled={isDeleting || isDuplicating} startIcon={<DeleteOutlined />} onClick={handleDelete}>
          {t('button.delete')}
        </ActionButton>
        <ActionButton disabled={isDeleting || isDuplicating} startIcon={<DriveFileMoveOutlined />} onClick={handleMoveTo}>
          {t('button.moveTo')}
        </ActionButton>
        <ActionButton disabled={isDeleting || isDuplicating} startIcon={<AddBoxOutlined />} onClick={handleOpenCreateStandardBlockModal}>
          {t('button.newStandardBlock')}
        </ActionButton>
        <PromptBeforeDeleting open={isPromptBeforeDeletingOpen} setOpen={setIsPromptBeforeDeletingOpen} onDelete={handleDeleteConfirm}>
          {connectedToBlockTypes.length > 0 && (
            <>
              <DialogContentText>{t('text.deleteBlockTypeWarning', { count: connectedToBlockTypes.length })}</DialogContentText>
              <Grid container mt={2} spacing={1}>
                {connectedToBlockTypes.map(connectedToBlockType => (
                  <Grid key={connectedToBlockType.uuid} item xs={6}>
                    <Paper sx={{ p: 1 }}>
                      <Stack alignItems="center" direction="row" gap={1} justifyContent="flex-start">
                        <BlockTypeIcon primaryIconColor={connectedToBlockType.content.color} sx={{ fontSize: 18 }} />
                        <TypographyEllipse variant="h6">{connectedToBlockType.name}</TypographyEllipse>
                      </Stack>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </PromptBeforeDeleting>
      </RightPanelActions>
      <RightPanelBody>
        <RightPanelSection>
          <RightPanelSectionContent>
            <FormLabel>{t('label.name')}</FormLabel>
            <Typography>{blockType.name}</Typography>
          </RightPanelSectionContent>
        </RightPanelSection>
        <RightPanelSection>
          <RightPanelSectionContent>
            <FormLabel>{t('label.description')}</FormLabel>
            <Typography>{blockType.description.trim().length > 0 ? blockType.description.trim() : t('global:text.noValue')}</Typography>
          </RightPanelSectionContent>
        </RightPanelSection>
        <RightPanelSection>
          <RightPanelSectionContent>
            <FormLabel>{t('label.standardMetadataList')}</FormLabel>
            <Grid container mt={0.5} spacing={1}>
              {blockType.standardMetadataList.map(metadata => (
                <Grid key={metadata.uuid} item xs={panelWidth > 400 ? 6 : 12}>
                  <BlockTypeMetadataPreview metadata={metadata} />
                </Grid>
              ))}
            </Grid>
            {blockType.standardMetadataList.length === 0 && (
              <Typography align="center" variant="subtitle2">
                {t('text.noMetadata')}
              </Typography>
            )}
          </RightPanelSectionContent>
        </RightPanelSection>
        <RightPanelSection>
          <RightPanelSectionContent>
            <FormLabel>{t('label.blockMetadataList')}</FormLabel>
            <Grid container mt={0.5} spacing={1}>
              {blockType.blockMetadataList.map(metadata => (
                <Grid key={metadata.uuid} item xs={panelWidth > 400 ? 6 : 12}>
                  <BlockTypeMetadataPreview metadata={metadata} />
                </Grid>
              ))}
            </Grid>
            {blockType.blockMetadataList.length === 0 && (
              <Typography align="center" variant="subtitle2">
                {t('text.noMetadata')}
              </Typography>
            )}
          </RightPanelSectionContent>
        </RightPanelSection>
        <RightPanelSection ref={panelRef}>
          <RightPanelSectionContent>
            <FormLabel>{t('label.parameters')}</FormLabel>
            <Grid container mt={0.5} spacing={1}>
              {blockType.parameters.map(parameter => (
                <Grid key={parameter.uuid} item xs={panelWidth > 400 ? 6 : 12}>
                  <LegoParameterPreview<BlockTypeParameter> parameter={parameter} />
                </Grid>
              ))}
            </Grid>
            {blockType.parameters.length === 0 && (
              <Typography align="center" variant="subtitle2">
                {t('text.noParameter')}
              </Typography>
            )}
          </RightPanelSectionContent>
        </RightPanelSection>
        <RightPanelSection>
          <RightPanelSectionContent>
            <FormLabel>{t('label.connections')}</FormLabel>
            <Grid container mt={0.5} spacing={1}>
              {blockType.connections.map(connection => (
                <Grid key={connection.uuid} item xs={panelWidth > 400 ? 6 : 12}>
                  <BlockTypeConnectionPreview connection={connection} />
                </Grid>
              ))}
            </Grid>
            {blockType.connections.length === 0 && (
              <Typography align="center" variant="subtitle2">
                {t('text.noConnection')}
              </Typography>
            )}
          </RightPanelSectionContent>
        </RightPanelSection>
        <RightPanelSection>
          <RightPanelSectionContent>
            <FormLabel>{t('label.metrics')}</FormLabel>
            <Grid container mt={0.5} spacing={1}>
              {blockType.metrics.map(metric => (
                <Grid key={metric.uuid} item xs={panelWidth > 400 ? 6 : 12}>
                  <LegoMetricPreview<BlockTypeMetric> metric={metric} />
                </Grid>
              ))}
            </Grid>
            {blockType.metrics.length === 0 && (
              <Typography align="center" variant="subtitle2">
                {t('text.noMetric')}
              </Typography>
            )}
          </RightPanelSectionContent>
        </RightPanelSection>
      </RightPanelBody>
      <EditBlockTypeModal
        blockType={blockType}
        folderId={folderId}
        isOpen={isEditBlockTypeModalOpen}
        setIsOpen={setIsEditBlockTypeModalOpen}
        onUpdate={handleUpdateBlockType}
      />
      <MoveToBlockTypeModal blockType={blockType} isOpen={isMoveToBlockTypeModalOpen} setIsOpen={setIsMoveToBlockTypeModalOpen} />
      <CreateStandardBlockModal
        blockTypeId={blockType.uuid}
        folderId={folderId}
        isOpen={isCreateStandardBlockModalOpen}
        setIsOpen={setIsCreateStandardBlockModalOpen}
        onCreate={handleCreateStandardBlock}
      />
    </>
  );
};

export default InformationsTab;
