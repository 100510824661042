export default {
  title: {
    settings: 'Settings',
    columns: 'Columns',
  },
  label: {
    field: 'Column nº{{index}}',
    gridUseHeader: 'This file contains header',
    valuesStart: 'Values start',
    valuesEnd: 'Values end',
    notSelected: 'Not selected',
  },
  text: {
    lineNumber: 'line nº',
  },
  button: {
    clear: 'Clear',
  },
};
