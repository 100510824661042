import { Components, Theme, checkboxClasses, dividerClasses, listItemIconClasses, switchClasses } from '@mui/material';

import { LIST_PADDING } from './MuiList';

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      '&[role="option"]:not(:last-of-type)': {
        marginBottom: LIST_PADDING,
      },
      [`& > .${checkboxClasses.root}, & > .${switchClasses.root}`]: {
        marginLeft: -9,
        marginTop: -9,
        marginBottom: -9,
      },
      [`& + .${dividerClasses.root}`]: {
        marginTop: LIST_PADDING,
        marginBottom: LIST_PADDING,
      },
      [`& > .${listItemIconClasses.root}`]: {
        color: theme.palette.primary.main,
      },
    }),
    gutters: {
      marginLeft: LIST_PADDING,
      marginRight: LIST_PADDING,
      width: `calc(100% - 2 * ${LIST_PADDING}px)`,
    },
    divider: {
      marginTop: LIST_PADDING,
      marginBottom: LIST_PADDING,
    },
  },
} as Components<Theme>['MuiMenuItem'];
