import { useMemo } from 'react';

import { UUID } from '@dametis/core';

import { useSelector } from 'store';
import { useAlarms } from 'store/api/alarms';
import { usePlaygrounds } from 'store/api/playgrounds';
import { useProjects } from 'store/api/projects';
import { useReports } from 'store/api/reports';
import { useSynoptics } from 'store/api/synoptics';
import { Entity, EntityType } from 'types/entity';

const useEntity = ({
  entityId,
  type,
  groupId: rawGroupId = undefined,
  siteId: rawSiteId = undefined,
}: {
  entityId: string;
  type: EntityType;
  groupId?: UUID;
  siteId?: UUID;
}) => {
  const currentGroupId = useSelector(state => state.auth.selectedGroup!.uuid);
  const currentSiteId = useSelector(state => state.auth.selectedSite?.uuid);

  const groupId = useMemo(() => rawGroupId ?? currentGroupId, [rawGroupId, currentGroupId]);
  const siteId = useMemo(() => (rawGroupId ? rawSiteId : currentSiteId), [currentSiteId, rawGroupId, rawSiteId]);

  const { data: alarms, isLoading: isAlarmsLoading } = useAlarms({ siteId }, { skip: type !== EntityType.ALARM });
  const { data: playgrounds, isLoading: isPlaygroundsLoading } = usePlaygrounds(
    { groupId, siteId },
    { skip: type !== EntityType.PLAYGROUND },
  );
  const { data: projects, isLoading: isProjectsLoading } = useProjects({ groupId, siteId }, { skip: type !== EntityType.PROJECT });
  const { data: reports, isLoading: isReportsLoading } = useReports({ groupId, siteId }, { skip: type !== EntityType.REPORT });
  const { data: synoptics, isLoading: isSynopticsLoading } = useSynoptics({ siteId }, { skip: type !== EntityType.SYNOPTIC });

  return useMemo<[Entity | undefined, boolean]>(() => {
    switch (type) {
      case EntityType.ALARM:
        return [alarms?.find(alarm => alarm.uuid === entityId), isAlarmsLoading];
      case EntityType.PLAYGROUND:
        return [playgrounds?.find(playground => playground.uuid === entityId), isPlaygroundsLoading];
      case EntityType.PROJECT:
        return [projects?.find(project => project.uuid === entityId), isProjectsLoading];
      case EntityType.REPORT:
        return [reports?.find(report => report.uuid === entityId), isReportsLoading];
      case EntityType.SYNOPTIC:
        return [synoptics?.find(synoptic => synoptic.uuid === entityId), isSynopticsLoading];
      default:
        return [undefined, false];
    }
  }, [
    alarms,
    entityId,
    isAlarmsLoading,
    isPlaygroundsLoading,
    isProjectsLoading,
    isReportsLoading,
    isSynopticsLoading,
    playgrounds,
    projects,
    reports,
    synoptics,
    type,
  ]);
};

export default useEntity;
