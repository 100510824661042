import { makeStyles } from '@mui/styles';

const useBigButtonStyles = makeStyles(theme => ({
  bigButton: {
    padding: '0.2rem 0.3rem 0 0.15rem',
    textTransform: 'none',
    color: 'inherit',
    textAlign: 'inherit',
    display: 'grid',
    gridTemplateColumns: '2rem auto',
    gridTemplateAreas: '"icon title" "icon description"',
  },
  bigButton__icon: {
    gridArea: 'icon',
  },
  bigButton__title: {
    gridArea: 'title',
    fontSize: 10,
  },
  bigButton__description: {
    gridArea: 'description',
    color: theme.palette.black,
    fontWeight: 600,
  },
}));

export default useBigButtonStyles;
