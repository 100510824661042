import i18next from 'i18next';

const localizedGroupBy = (groupBy: string) => {
  const number = parseInt(groupBy, 10);
  const timeUnit = groupBy.slice(number.toString().length);

  return `${number} ${i18next.t('global:unit.time', {
    count: number,
    context: {
      s: 'second',
      m: 'minute',
      h: 'hour',
      d: 'day',
      w: 'week',
      mo: 'month',
      yr: 'year',
    }[timeUnit],
  })}`;
};

export default localizedGroupBy;
