import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

import { PeriodWithDashStyle, TabInfo, TabType } from '@dametis/core';

import { getTabsConfig } from '../config/tabs.config';
import { IChart, ITab, WithDaChart } from '../types';

import { createChartFromType, exportChartFromType } from './shared';

export const createTab = ({
  uuid = uuidv4(),
  name = '',
  type = TabType.NONE,
  chart = undefined,
  createdAt = new Date(),
  updatedAt = new Date(),
  customPeriod = undefined,
  forcedGroupBy = undefined,
}: Partial<TabInfo> = {}): ITab => ({
  uuid,
  name: name.length ? name : (getTabsConfig(t)[type]?.name ?? ''),
  type,
  chart: createChartFromType(type, chart),
  createdAt,
  updatedAt,
  customPeriod,
  forcedGroupBy,
});

export const createGeneratedTab = ({
  uuid = uuidv4(),
  name = '',
  type = TabType.NONE,
  chart = undefined,
  createdAt = new Date(),
  updatedAt = new Date(),
}: Partial<ITab> = {}): ITab => ({
  uuid,
  name: name.length ? name : (getTabsConfig(t)[type]?.name ?? ''),
  type,
  chart: createChartFromType(type, 'daChart' in chart ? ({ ...chart, daChart: null } as WithDaChart<IChart>) : undefined),
  createdAt,
  updatedAt,
});

export const exportTab = async ({ chart, customPeriod, ...rest }: ITab): Promise<ITab> => ({
  ...rest,
  chart: await exportChartFromType(rest.type, chart),
  customPeriod: customPeriod ? customPeriod.map(({ xAxis, ...period }) => period as PeriodWithDashStyle) : undefined,
});
