import { StandardTaskStatus } from '@dametis/core';

export default {
  title: {
    tasks: 'Tareas',
    redirection: 'Redirección',
  },
  button: {
    cancel: 'Cancelar',
    continue: 'Continuar',
    reset: 'Reiniciar',
  },
  label: {
    template: 'Modelo',
    project: 'Proyecto',
  },
  select: {
    withoutTemplate: 'Proyectos sin plantilla',
  },
  text: {
    redirection: 'Va a ser redirigido al sitio',
    unassigned: 'No asignado',
    noTasks: 'Ninguna tarea',
    noOptions: 'Ninguna opción',
    projectsLength_zero: 'Ningún proyecto',
    projectsLength_one: '1 proyecto',
    projectsLength_other: '{{count}} proyectos',
    noTemplate: 'Proyectos sin plantilla',
    noProject: 'Ningún proyecto',
  },
  tasks: {
    status: {
      [`variant_${StandardTaskStatus.TODO}`]: 'Por hacer',
      [`variant_${StandardTaskStatus.ONGOING}`]: 'En progreso',
      [`variant_${StandardTaskStatus.TESTING}`]: 'Por probar',
      [`variant_${StandardTaskStatus.DONE}`]: 'Completada',
    },
  },
};
