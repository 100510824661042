import { PermissionKey } from '@dametis/core';

import { useSelector } from 'store';

export const usePermission = (permission?: PermissionKey): boolean =>
  useSelector(
    state =>
      !!permission &&
      !!state.auth.selectedGroup &&
      !!state.auth.user?.acl.HasPermission(permission, state.auth.selectedGroup.uuid, state.auth.selectedSite),
  );
