import { ListItemButton, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { BlockTypeInfo, FolderInfo } from '@dametis/core';

import BlockTypeIcon from 'components/Lego/UI/BlockType/BlockTypeIcon';
import { getBlockTypePath } from 'components/Lego/helpers/getBlockTypePath';
import { getBlockTypeUrl } from 'components/Lego/helpers/getBlockTypeUrl';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';

import { MenuTab } from '../LegoCatalog';

import SuggestionBreadcrumbs from './SuggestionBreadcrumbs/SuggestionBreadcrumbs';

export interface BlockTypeSuggestionProps {
  blockType: BlockTypeInfo;
}

const BlockTypeSuggestion: FC<BlockTypeSuggestionProps> = ({ blockType }) => {
  const { t } = useTranslation('lego');

  const { data: globalRootFolder = null } = useReadGlobalRootFolderQuery();

  const parsedGlobalRootFolder: FolderInfo | null = useMemo(
    () => (globalRootFolder ? { ...globalRootFolder, name: t('lego:text.factory') } : null),
    [globalRootFolder, t],
  );

  const path = useMemo(
    () => (parsedGlobalRootFolder ? getBlockTypePath(parsedGlobalRootFolder, blockType.uuid) : []),
    [parsedGlobalRootFolder, blockType.uuid],
  );

  return (
    <ListItemButton component={NavLink} to={getBlockTypeUrl(parsedGlobalRootFolder, blockType.uuid)}>
      <Stack>
        {path.length > 0 && <SuggestionBreadcrumbs path={path} />}
        {path.length === 0 && (
          <Typography py={0.25} variant="caption">
            {t(`menuTab.${MenuTab.NO_FOLDER_CONTENT}`)}
          </Typography>
        )}
        <Stack alignItems="center" direction="row" gap={1}>
          <Stack alignItems="center" justifyContent="center">
            <BlockTypeIcon primaryIconColor={blockType.content.color} sx={{ fontSize: 22 }} />
          </Stack>
          <Stack>
            <Typography variant="h6">{blockType.name}</Typography>
            {/* <Typography variant="subtitle2">{type.description}</Typography> */}
          </Stack>
        </Stack>
      </Stack>
    </ListItemButton>
  );
};

export default BlockTypeSuggestion;
