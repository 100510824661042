import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogTitle, Paper, Stack } from '@mui/material';
import { Dispatch, FC, MouseEventHandler, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTypeInfo, BlockTypeMetadata, CreateBlockTypeBody, UUID } from '@dametis/core';

import { createCreateBlockTypeBody } from 'components/Lego/helpers/blockType/createCreateBlockTypeBody';
import { useDispatch } from 'store';
import { useCreateBlockTypeMutation } from 'store/api/blockTypes';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import ConnectionsStep from './ConnectionsStep';
import GeneralStep from './GeneralStep';
import MetadataListsStep, { MetadataListName } from './MetadataListsStep';
import MetricsStep from './MetricsStep';
import ModalStepper from './ModalStepper';
import ParametersStep from './ParametersStep';
import { getIsConnectionsStepValid } from './helpers/getIsConnectionsStepValid';
import { getIsGeneralStepValid } from './helpers/getIsGeneralStepValid';
import { getIsMetadataListsStepValid } from './helpers/getIsMetadataListsStepValid';
import { getIsMetricsStepValid } from './helpers/getIsMetricsStepValid';
import { getIsParametersStepValid } from './helpers/getIsParametersStepValid';

export interface CreateBlockTypeModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  folderId?: UUID;
  onCreate?: (createdBlockType: BlockTypeInfo, folderId?: UUID) => Promise<void> | void;
}

const CreateBlockTypeModal: FC<CreateBlockTypeModalProps> = ({ isOpen, setIsOpen, folderId = undefined, onCreate = undefined }) => {
  const { t } = useTranslation('lego');
  const dispatch = useDispatch();

  const [createBlockType, { isLoading: isCreating }] = useCreateBlockTypeMutation();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [createBlockTypeBody, setCreateBlockTypeBody] = useState<CreateBlockTypeBody>(createCreateBlockTypeBody);
  const [metadataList, setMetadataList] = useState<BlockTypeMetadata[]>([]);
  const [listNameByMetadataId, setListNameByMetadataId] = useState<Record<UUID, MetadataListName>>({});

  const isGeneralStepValid = useMemo(() => getIsGeneralStepValid(createBlockTypeBody), [createBlockTypeBody]);

  const isMetadataListsStepValid = useMemo(() => getIsMetadataListsStepValid(metadataList), [metadataList]);

  const isParametersStepValid = useMemo(() => getIsParametersStepValid(createBlockTypeBody), [createBlockTypeBody]);

  const isConnectionsStepValid = useMemo(() => getIsConnectionsStepValid(createBlockTypeBody), [createBlockTypeBody]);

  const isMetricsStepValid = useMemo(() => getIsMetricsStepValid(createBlockTypeBody), [createBlockTypeBody]);

  const isFormValid = useMemo(
    () => isGeneralStepValid && isMetadataListsStepValid && isParametersStepValid && isConnectionsStepValid && isMetricsStepValid,
    [isConnectionsStepValid, isMetadataListsStepValid, isParametersStepValid, isGeneralStepValid, isMetricsStepValid],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handlePreviousStep: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setActiveStep(state => state - 1);
  }, []);

  const handleNextStep: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setActiveStep(state => state + 1);
  }, []);

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(async () => {
    try {
      const blockTypeBody: CreateBlockTypeBody = {
        ...createBlockTypeBody,
        blockMetadataList: metadataList.filter(metadata => listNameByMetadataId[metadata.uuid] === MetadataListName.BLOCK),
        standardMetadataList: metadataList.filter(metadata => listNameByMetadataId[metadata.uuid] === MetadataListName.STANDARD),
      };

      const data = await createBlockType(blockTypeBody).unwrap();
      if (onCreate) {
        await onCreate(data, blockTypeBody.folderId);
      }
      setIsOpen(false);
      dispatch(addToast({ message: t('toast.createBlockTypeSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [createBlockTypeBody, dispatch, setIsOpen, t, createBlockType, onCreate, metadataList, listNameByMetadataId]);

  useEffect(() => {
    if (isOpen) {
      setCreateBlockTypeBody(createCreateBlockTypeBody({ folderId }));
      setMetadataList([]);
      setListNameByMetadataId({});
      setActiveStep(0);
    }
  }, [isOpen, folderId]);

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 1 }}>{t('title.createBlockTypeModal')}</DialogTitle>
      <Stack direction="row" gap={1} overflow="hidden" px={3}>
        <Box flexShrink={0} width={250}>
          <ModalStepper activeStep={activeStep} blockTypeBody={createBlockTypeBody} listNameByMetadataId={listNameByMetadataId} />
        </Box>
        <Paper sx={{ p: 2, flexGrow: 1, display: 'flex', alignItems: 'stretch', overflow: 'hidden' }}>
          {activeStep === 0 && (
            <GeneralStep<CreateBlockTypeBody> blockTypeBody={createBlockTypeBody} setBlockTypeBody={setCreateBlockTypeBody} />
          )}
          {activeStep === 1 && (
            <MetadataListsStep
              listNameByMetadataId={listNameByMetadataId}
              metadataList={metadataList}
              setListNameByMetadataId={setListNameByMetadataId}
              setMetadataList={setMetadataList}
            />
          )}
          {activeStep === 2 && (
            <ParametersStep<CreateBlockTypeBody> blockTypeBody={createBlockTypeBody} setBlockTypeBody={setCreateBlockTypeBody} />
          )}
          {activeStep === 3 && (
            <ConnectionsStep<CreateBlockTypeBody> blockTypeBody={createBlockTypeBody} setBlockTypeBody={setCreateBlockTypeBody} />
          )}
          {activeStep === 4 && (
            <MetricsStep<CreateBlockTypeBody> blockTypeBody={createBlockTypeBody} setBlockTypeBody={setCreateBlockTypeBody} />
          )}
        </Paper>
      </Stack>
      <DialogActions>
        {activeStep === 0 && (
          <>
            <Button color="primary" variant="text" onClick={handleClose}>
              {t('button.close')}
            </Button>
            <Button
              color="secondary"
              disabled={!isGeneralStepValid}
              endIcon={<ArrowForwardIos />}
              variant="contained"
              onClick={handleNextStep}
            >
              {t('button.next')}
            </Button>
          </>
        )}
        {activeStep === 1 && (
          <>
            <Button color="primary" startIcon={<ArrowBackIosNew />} variant="text" onClick={handlePreviousStep}>
              {t('button.previous')}
            </Button>
            <Button
              color="secondary"
              disabled={!isMetadataListsStepValid}
              endIcon={<ArrowForwardIos />}
              variant="contained"
              onClick={handleNextStep}
            >
              {t('button.next')}
            </Button>
          </>
        )}
        {activeStep === 2 && (
          <>
            <Button color="primary" startIcon={<ArrowBackIosNew />} variant="text" onClick={handlePreviousStep}>
              {t('button.previous')}
            </Button>
            <Button
              color="secondary"
              disabled={!isParametersStepValid}
              endIcon={<ArrowForwardIos />}
              variant="contained"
              onClick={handleNextStep}
            >
              {t('button.next')}
            </Button>
          </>
        )}
        {activeStep === 3 && (
          <>
            <Button color="primary" startIcon={<ArrowBackIosNew />} variant="text" onClick={handlePreviousStep}>
              {t('button.previous')}
            </Button>
            <Button
              color="secondary"
              disabled={!isConnectionsStepValid}
              endIcon={<ArrowForwardIos />}
              variant="contained"
              onClick={handleNextStep}
            >
              {t('button.next')}
            </Button>
          </>
        )}
        {activeStep === 4 && (
          <>
            <Button color="primary" disabled={isCreating} startIcon={<ArrowBackIosNew />} variant="text" onClick={handlePreviousStep}>
              {t('button.previous')}
            </Button>
            <LoadingButton color="secondary" disabled={!isFormValid} loading={isCreating} variant="contained" onClick={handleSubmit}>
              {t('button.create')}
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateBlockTypeModal;
