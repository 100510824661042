export enum UserHelperName {
  SYNOPTIC_CART_RIGHT_CLICK = 'synopticCartRightClick',
  DATA_GRID_COLUMN_DOUBLE_CLICK = 'dataGridColumnDoubleClick',
  FORMAT_PICKER_HELPER = 'formatPickerHelper',
  LEGO_NO_METRIC = 'legoNoMetric',
  VNC_VARIABLE_MENU_OPERATOR = 'vncVariableMenuOperator',
  STYLED_RULES_DESCRIPTION_SITE_SETTINGS = 'styledRulesDescriptionSiteSettings',
  STYLED_RULES_DESCRIPTION_VARIABLE_WIDGET = 'styledRulesDescriptionVariableWidget',
  STYLED_RULES_DESCRIPTION_TABLE_PANEL = 'styledRulesDescriptionTablePanel',
  STYLED_RULES_DESCRIPTION_VALUE_INSPECTOR = 'styledRulesDescriptionValueInspector',
  STYLED_RULES_SHORT_DESCRIPTION_SITE_SETTINGS = 'styledRulesShortDescriptionSiteSettings',
  SANKEY_FLOW_DESCRIPTION = 'sankeyFlowDescription',
  SANKEY_NODE_DESCRIPTION = 'sankeyNodeDescription',
  GROUP_SITE_MENU_HELPER = 'groupSiteMenuHelper',
  PLAYGROUND_NO_SAVE = 'playgroundNoSave',
  MOVED_CSV_IMPORT = 'movedCsvImport',
  BATCH_FILTER_HELPER = 'batchFilterHelper',
  RIGHT_CLICK_OPTIONS = 'rightClickOptions',
  // MANUAL_ENTRY_LEGEND = 'manualEntryLegend',
  FILE_IMPORT_TYPICAL_FILE = 'fileImportTypicalFile',
  FILE_IMPORT_VIRTUAL_COLUMNS = 'fileImportVirtualColumns',
  FILE_IMPORT_DATE_TIME_FORMAT = 'fileImportDateTimeFormat',
  FILE_IMPORT_FIXED_VARIABLES = 'fileImportFixedVariables',
  FILE_IMPORT_JQ_TRANSFORMER = 'fileImportJqTransformer',
}

export type UserHelpers = Partial<Record<UserHelperName, boolean>>;
