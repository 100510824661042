import { DEFAULT_ERROR } from './errors';

export default {
  button: {
    ok: 'Ok',
    closeAll: 'Close all',
  },
  error: DEFAULT_ERROR,
  errorNoSite: 'No selected site.',
  errorNoGroup: 'No selected group.',

  successCreateVariable: 'The variable was successfully created.',
  successUpdateVariable: 'The variable was successfully updated.',
  successUpdateVariables: 'Variables were successfully updated.',
  successCreateModel: 'The model was successfully created.',
  successUpdateModel: 'The model was successfully edited.',
  successDeleteModel: 'The model was successfully deleted.',
  successCreateVariables: 'The variables were successfully created.',
  successDeleteVariable_one: '{{ count }} variable was successfully deleted.',
  successDeleteVariable_other: '{{ count }} variables were successfully deleted.',
  successCreateVariableList: 'The list was successfully created.',
  successEditVariableList: 'The list was successfully edited.',
  successDeleteVariableList: 'The list was successfully deleted.',
  successImportData: 'Data was successfully imported.',
  successSaveChanges: 'Your changes have been successfully saved.',
  successCreateNewDevice: 'The device was successfully created.',
  successEditDevice: 'The device was successfully edited.',
  successDeleteDevice: 'The device was successfully deleted.',
  successDisableDevice: 'The device was successfully disabled.',
  successEnableDevice: 'The device was successfully enabled.',
  successCreateSynopticWidget: 'The custom element was successfully created.',
  successDeleteSynopticWidget: 'The custom element was successfully deleted.',
  successCreateSynoptic: 'The synoptic was successfully saved.',
  successDeleteSynoptic: 'The synoptic was successfully deleted.',
  successAddCart: 'The variable was successfully added to the cart.',
  successAddCartItems: 'Variables were successfully added to the cart.',
  successRemoveCart: 'The variable was successfully removed from the cart.',

  errorNotAllowed: 'Forbidden operation.',
  errorExport: 'An error occurred while exporting data',
  errorExportData: 'Too much data, please reduce time range and/or increase sub-period.',
  errorGroupBy: 'Too much data to display, please reduce time range and/or increase sub-period.',
  errorBoxVersion: 'Unable to get box data.',
  errorVariableDependants_one: '{{ count } variable has dependants.',
  errorVariableDependants_other: '{{ count }} variables have dependants.',

  warningAddCart: 'The variable has already been added to the cart.',
  alreadyInCartSomeItems: 'Some variables have already been added to the cart.',
  alreadyInCartAllItems: 'All variables have already been added to the cart.',

  successCreateBatch: 'The batch was successfully created.',
  successDeleteBatch: 'The batch was successfully deleted.',

  successCreateProject: 'The project was successfully created.',
  successDeleteProject: 'The project was successfully deleted.',
  successCreateTask: 'The task was successfully created.',
  successUploadAsset: 'The asset was successfully uploaded.',
  successDownloadAsset: 'The asset was successfully downloaded.',

  successCreatePlayground: 'The playground was successfully saved.',
  successDuplicatePlayground: 'The playground was successfully duplicated.',
  successDeletePlayground: 'The playground was successfully deleted.',

  errorTime: 'Your system clock appears not to be accurate.\nData display may not work properly.',
  errorBrowser: 'You may have an outdated brower version.\nYour browsing experience may be severely degraded or dysfunctional.',

  successCreateContract: 'The contract was successfully created.',
  successDeleteContract: 'The contract was successfully deleted.',
  successCreateBill: 'The bill was successfully created.',
  successDeleteBill: 'The bill was successfully deleted.',
  defaultGridViewChanged: 'Default view changed.',

  newVersion: 'A new version is available !',
  reload: 'Reload',

  successUserUpdate: 'The user has been updated.',
  successUserCreate: 'The user has been created',
  errorUserUpdate: "The user couldn't be updated.",
  errorUserCreate: "The user couldn't be created.",
  userAlreadyExists: 'This email address is already associated with an account.',

  successUserDelete: 'The user has been deleted.',
  errorUserDelete: "The user couldn't be deleted.",

  successBoxUpdate: 'The box has been updated.',
  successBoxCreate: 'The box has been created.',
  successBoxSetup: 'The box has been setup.',
  errorBoxUpdate: "The box couldn't be updated.",
  errorBoxCreate: "The box couldn't be created.",
  errorBoxSetup: "The box couldn't be setup.",

  successGroupUpdate: 'The group has been updated.',
  successGroupCreate: 'The group has been created',
  errorGroupUpdate: "The group couldn't be updated.",
  errorGroupCreate: "The group couldn't be created.",

  successSiteUpdate: 'The site has been updated.',
  successSiteCreate: 'The site has been created',
  errorSiteUpdate: "The site couldn't be updated.",
  errorSiteCreate: "The site couldn't be created.",

  successSendInvitationEmailAgain: 'An invitation email has just been sent.',
  errorSendInvitationEmailAgain: 'An error has occurred.',

  successCreateComment: 'The comment has been created.',
  successUpdateComment: 'The comment has been updated.',
  successDeleteComment: 'The comment has been deleted.',
  writeChartComment: 'Click on the chart to write a comment.',
  noCommentForCalculation: 'You cannot leave a comment on a calculation.',

  successDeploy: 'API is deploying.',
  errorDeploy: 'An error has occurred.',

  successClearCache: 'API cache has been cleared.',
  errorClearCache: 'An error has occurred.',

  successSyncConsul: 'Groups were synchronized in Consul.',
  errorSyncConsul: 'An error has occurred.',

  successPing: 'Pong!',
  errorPing: 'An error has occurred.',

  successCleanFileImportFiles: '{{ fileCount }} files deleted with success.',
  errorCleanFileImportFiles: 'An error has occurred.',

  successCreateProjectTemplate: 'The project template has been created.',
  successDeleteProjectTemplate: 'The project template has been deleted.',

  successArmageddon: 'Optimization and recalculation in progress... Please wait',
  errorArmageddon: 'An error has occurred.',

  successDamcoArmageddon: 'Monitoring data have been deleted.',

  successSyncConfigBox: 'The box has been synced',
  waitingSyncConfigBox: 'Attempting to synchronize box...',

  entityKind: {
    playground: 'the playground',
    report: 'the report',
    variable: 'the variable',
    synoptic: 'the synoptic',
    alarm: 'the alarm',
    project: 'the project',
  },
  sharedWithYou: '{{firstName}} {{lastName}} shared $t(toast:entityKind.{{kind}}) “{{name}}” with you',
  assignedYouTask: '{{firstName}} {{lastName}} has assigned you a task $t(toast:entityKind.{{kind}}) “{{name}}”',
  assignedYouAlarm: '{{firstName}} {{lastName}} has assigned you the $t(toast:entityKind.{{kind}}) “{{name}}”',
  acknowledgedAlarmAndMentionnedYou:
    '{{firstName}} {{lastName}} has mentioned you while acknowledging $t(toast:entityKind.{{kind}}) “{{name}}”',
  mentionnedYou: '{{firstName}} {{lastName}} has mentioned you in a comment on $t(toast:entityKind.{{kind}}) “{{name}}” with you',
  openToast: 'Open',
  accountCreated: 'Your account has been successfully created, welcome to MyDametis!',
  seeToast: 'View',
  warningMustConfigureTwoFA: 'Two-factor authentication is required on this site. You can set it up in My account.',
  successDeleteTask: 'The task has been deleted.',
  notExistingTemplate: 'An error has occurred.',
  successUploadImage: 'The image was successfully uploaded.',
  successDeleteBlock: 'The block has been deleted.',

  successCreateUserList: 'Users list has been created.',
  successUpdateUserList: 'Users list has been updated.',
  successDeleteUserList: 'Users list has been deleted',
};
