import {
  Content,
  EntityStyleConfiguration,
  IsEntityStyleConfiguration,
  IsStyledRules,
  Rule,
  RuleOperator,
  Style,
  StyleConfigurationInfo,
  StyledRule,
} from '@dametis/core';

import { createStyledRuleContent, createStyledRuleStyle } from './createStyledRule';

export const isRuleTrue = (rule: Rule, value: string | number | null): boolean => {
  if (rule.value === undefined || rule.value === null || value === undefined || value === null) return false;
  const ruleValueNumber = typeof rule.value === 'number' ? rule.value : parseFloat(rule.value.replaceAll(',', '.'));
  const valueNumber = typeof value === 'number' ? value : parseFloat(value);
  const parsedRuleValue = Number.isNaN(ruleValueNumber) ? rule.value : ruleValueNumber;
  const parsedValue = Number.isNaN(valueNumber) ? value : valueNumber;
  switch (rule.operator) {
    case RuleOperator.EQUAL_TO:
      return parsedValue === parsedRuleValue;
    case RuleOperator.NOT_EQUAL_TO:
      return parsedValue !== parsedRuleValue;
    case RuleOperator.GREATER_THAN:
      return parsedValue > parsedRuleValue;
    case RuleOperator.GREATER_THAN_OR_EQUAL_TO:
      return parsedValue >= parsedRuleValue;
    case RuleOperator.LESS_THAN:
      return parsedValue < parsedRuleValue;
    case RuleOperator.LESS_THAN_OR_EQUAL_TO:
      return parsedValue <= parsedRuleValue;
    case RuleOperator.BIT_IS_SET:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-bitwise
      return (parsedValue & (1 << parsedRuleValue)) !== 0;
    case RuleOperator.BIT_IS_UNSET:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-bitwise
      return (parsedValue & (1 << parsedRuleValue)) === 0;
    default:
      return false;
  }
};

export const getStyle = (styledRules: StyledRule[], value: number | null): Style => {
  let style = createStyledRuleStyle();
  if (Array.isArray(styledRules)) {
    const reversed = [...styledRules].reverse();
    reversed.forEach(styledRule => {
      if (isRuleTrue(styledRule.rule, value)) {
        style = { ...style, ...styledRule.style };
      }
    });
  }
  return style;
};

export const getContent = (styledRules: StyledRule[], value: number | null): Content => {
  let content = createStyledRuleContent();
  if (Array.isArray(styledRules)) {
    const reversed = [...styledRules].reverse();
    reversed.forEach(styledRule => {
      if (isRuleTrue(styledRule.rule, value)) {
        content = {
          ...content,
          ...styledRule.content,
          text: content.text && content.concat ? `${content.text},${styledRule.content.text}` : styledRule.content.text,
        };
      }
    });
  }
  return content;
};

export const getStyleConfiguration = (
  entityStyleConfiguration: EntityStyleConfiguration,
  styleConfigurations: StyleConfigurationInfo[],
): StyleConfigurationInfo | undefined => {
  const foundStyleConfiguration = styleConfigurations.find(
    styleConfiguration => styleConfiguration.uuid === entityStyleConfiguration.configurationUuid,
  );
  if (!foundStyleConfiguration) {
    return undefined;
  }
  if (entityStyleConfiguration.withContent && entityStyleConfiguration.withStyle) {
    return foundStyleConfiguration;
  }
  const parsedRules = foundStyleConfiguration.rules.map(rule => ({
    ...rule,
    style: entityStyleConfiguration.withStyle ? rule.style : createStyledRuleStyle(),
    content: entityStyleConfiguration.withContent ? rule.content : createStyledRuleContent(),
  }));
  return { ...foundStyleConfiguration, rules: parsedRules };
};

export const getStyledRules = (
  styledRules: StyledRule[] | EntityStyleConfiguration | undefined,
  styleConfigurations: StyleConfigurationInfo[],
): StyledRule[] => {
  if (IsStyledRules(styledRules)) {
    return styledRules;
  }
  if (IsEntityStyleConfiguration(styledRules)) {
    return getStyleConfiguration(styledRules, styleConfigurations)?.rules ?? [];
  }
  return [];
};
