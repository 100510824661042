import type { DashStyleValue, SeriesAreasplineOptions, SeriesOptionsType } from 'highcharts';
import AreasplineSeries from 'highcharts/es-modules/Series/AreaSpline/AreaSplineSeries';
import { v4 as uuidv4 } from 'uuid';

import { AnyGroupBy, Period, TadaApiResponse, UUID } from '@dametis/core';

import { DaAxis } from './DaAxis';
import { DaChart } from './DaChart';

interface Props {
  color?: string;
  name?: string;
  unit?: string;
  selectedUnit?: string;
  style?: {
    width?: number;
    dashStyle?: DashStyleValue;
  };
  area?: boolean;
  pretty?: boolean;
  yAxis?: DaAxis;
  uuid?: UUID;
  hidden?: boolean;
  yAxisHidden?: boolean;
}

export default class DaAreasplineSeries extends AreasplineSeries {
  period: Period | undefined;

  groupBy: AnyGroupBy | undefined;

  declare chart: DaChart;

  declare yAxis: DaAxis;

  declare options: SeriesAreasplineOptions;

  uuid: UUID;

  public constructor(
    chart: DaChart,
    {
      color,
      name,
      unit,
      selectedUnit,
      pretty = false,
      yAxis,
      uuid,
      hidden = false,
      yAxisHidden = false,
      area = false,
      style = {},
      ...props
    }: Props = {},
  ) {
    const options: SeriesAreasplineOptions = {
      ...props,
      connectNulls: true,
      name,
      id: uuidv4(),
      visible: !hidden,
      lineWidth: pretty ? 4 : style.width || 1,
      color,
      type: 'areaspline',
      fillOpacity: area ? 0.2 : 0,
      tooltip: {
        valueSuffix: unit,
        valuePrefix: selectedUnit,
      },
      dashStyle: style.dashStyle ?? 'Solid',
      marker: {
        radius: style.width + 1 || 2,
        symbol: 'circle',
      },
      states: {
        inactive: {
          opacity: 1,
        },
        hover: {
          lineWidth: pretty ? 4 : style.width || 1,
          lineWidthPlus: 0,
        },
      },
    };
    let yAxis2 = yAxis;
    if (yAxis2 === undefined) {
      yAxis2 = new DaAxis(chart, { color, unit, pretty, hidden: yAxisHidden });
    }
    options.yAxis = yAxis2.options.id;
    super();
    super.init(chart, options);
    this.uuid = uuid;
  }

  setColor(color: string, changeAxis = true): void {
    this.update({ color } as SeriesOptionsType);
    if (changeAxis) {
      this.yAxis.setColor(color);
    }
  }

  update(options: SeriesOptionsType): void {
    super.update(options, false);
  }

  setUnit(unit = null, apiUnit = this.options.tooltip.valueSuffix): void {
    this.yAxis.setUnit(unit, apiUnit);
    this.update({
      tooltip: {
        valuePrefix: unit ?? apiUnit,
        valueSuffix: apiUnit ?? '',
      },
    } as SeriesOptionsType);
  }

  setVisibility(visibility: boolean): void {
    this.setVisible(visibility, false);
  }

  setName(name): void {
    this.update({ name } as SeriesOptionsType);
  }

  addData(data: number[][], period?: Period, groupBy?: AnyGroupBy): void {
    super.setData([], false, false, false); // TODO: JCE
    this.chart.redraw();
    this.period = period;
    this.groupBy = groupBy;
    if (data.length) super.setData(data, false, false, false);
    // this.stats = findMinMax(data);
  }

  addPoint(point: number[]): void {
    super.addPoint(point, true, true, true);
  }

  removeData(): void {
    super.setData([]);
  }

  // remove(): void {
  //   if (this.graph !== undefined) this.graph.destroy();
  //   super.remove(false, false);
  // }

  static convertDataFromApiToHighcharts(data: TadaApiResponse['results']): number[][] {
    return data.map(({ value, time }) => [Date.parse(time), value]);
  }
}
