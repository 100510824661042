import i18next from 'i18next';

import { CreateCalculatedVariableBody, CreateModelBody, PlaygroundInfo, PromiseLimit, TabType, UUID, UpdateModelBody } from '@dametis/core';

import { createPlayground } from 'components/Playground/functions/Playground';
import { createTab } from 'components/Playground/functions/Tab';
import { getStoreTab } from 'components/Playground/functions/shared';
import { ILiveChart, ITab, IsLiveChartTab } from 'components/Playground/types';
import { sdk } from 'sdk';
import switchGroupSite from 'store/helpers/switchGroupSite';
import {
  addTab,
  clearPlaygroundStore,
  editTab,
  removeTab,
  setEdit,
  setEditingMode,
  setPlayground,
  setSelectedTab,
} from 'store/slices/playground';

import { unsubscribeFromDataRooms } from '../../functions/socketIo';
import { ToastSeverity } from '../../types';
import { TypedThunk } from '../index';
import { addToast } from '../slices/toast';

import { displaySdkErrorToast } from './toasts';

export const fetchPlayground =
  (playgroundId: string): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      const { data } = await sdk.playground.Read(playgroundId);
      await dispatch(switchGroupSite(data));
      const playground = createPlayground(data);
      dispatch(setPlayground(playground));
      dispatch(updateSelectedTab(playground.tabs[0]));
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
      throw err;
    }
  };

export const createNewPlayground =
  (newPlayground?: Partial<PlaygroundInfo>): TypedThunk<void> =>
  dispatch => {
    const playground = createPlayground(newPlayground);
    dispatch(updateSelectedTab(playground.tabs[0]));
    dispatch(setPlayground(playground));
    dispatch(setEdit(playground.canEdit));
  };

export const clearPlayground = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const { tabs } = getState().playground;
  await PromiseLimit.do(
    tabs,
    async t => {
      if (t.type === TabType.LIVE_CHART) {
        await unsubscribeFromDataRooms(
          (t.chart as ILiveChart).variables.map(variable => ({ id: variable.socketRoomId, listener: variable.socketHandler })),
        );
      }
    },
    { settle: true },
  );
  dispatch(clearPlaygroundStore());
};

export const updateSelectedTab =
  (newTab: ITab = undefined): TypedThunk<void> =>
  (dispatch, getState) => {
    const {
      playground: { tabs, selectedTab },
    } = getState();
    const tab = newTab ?? tabs.find(t => t.uuid === selectedTab);
    dispatch(setSelectedTab(tab));
    // await refreshData(tab, period);
  };

export const addAndSelectTab =
  (tab: Partial<ITab>): TypedThunk<void> =>
  dispatch => {
    const newTab = createTab(tab);
    dispatch(addTab(newTab));
    dispatch(updateSelectedTab(newTab));
  };

export const deleteTab =
  (tab: ITab): TypedThunk<void> =>
  dispatch => {
    if (IsLiveChartTab(tab)) {
      void unsubscribeFromDataRooms(tab.chart.variables.map(variable => ({ id: variable.socketRoomId })));
    }
    dispatch(removeTab(tab.uuid));
  };

export const updateTab =
  (tabUuid: UUID, options: Partial<ITab>): TypedThunk<void> =>
  dispatch => {
    dispatch(getStoreTab(tabUuid));
    dispatch(editTab({ tabUuid, options }));
  };

export const updatePanelOpen =
  (open: boolean): TypedThunk<void> =>
  dispatch => {
    dispatch(updatePanelOpen(open));
  };

export const createCalculatedVariable =
  (variable: CreateCalculatedVariableBody): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const siteId = getState().auth.selectedSite?.uuid;
    try {
      await sdk.site.CreateVariable(siteId, variable);
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successCreateVariable'),
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const createModel =
  (modelBody: CreateModelBody): TypedThunk<Promise<UUID>> =>
  async (dispatch, getState) => {
    const siteId = getState().auth.selectedSite?.uuid;
    try {
      const { data } = await sdk.model.Create(siteId, modelBody);
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successCreateModel'),
        }),
      );
      return data.uuid;
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
      return null;
    }
  };

export const updateModel =
  (modelBody: UpdateModelBody, uuid: UUID): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      await sdk.model.Update(uuid, modelBody);
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successUpdateModel'),
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const changeEditingMode =
  (mode: boolean): TypedThunk<void> =>
  dispatch => {
    dispatch(setEditingMode(mode));
    dispatch(updateSelectedTab());
  };
