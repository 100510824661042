import { CalculateOutlined, ClearAllOutlined, CloseOutlined, PublishOutlined, TableChartOutlined, Visibility } from '@mui/icons-material';
import { Badge, IconButton, ListItem, Typography } from '@mui/material';
import clsx from 'clsx';
import { FC, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { OperationStatus, OperationType } from '@dametis/core';

import LinearProgressWithLabel from '../../../UI/LinearProgressWithLabel/LinearProgressWithLabel';

import useOperationMenuStyles from './OperationMenu.styles';
import OperationStatusIcon from './OperationStatusIcon';

interface OperationItemProps {
  name: string;
  status: OperationStatus;
  type: OperationType;
  progress?: number;
  description?: string;
  goTo?: string;
  deleteItem?: () => void;
}

const OperationItem: FC<OperationItemProps> = ({
  name,
  status,
  type,
  progress = undefined,
  description = null,
  goTo = null,
  deleteItem = undefined,
}) => {
  const classes = useOperationMenuStyles();

  const icon = useMemo(() => {
    switch (type) {
      case OperationType.CREATE_CALC_VAR:
        return <CalculateOutlined />;
      case OperationType.EDIT_CALC_VAR:
        return <CalculateOutlined />;
      case OperationType.DELETE_DATA:
        return <ClearAllOutlined />;
      case OperationType.CREATE_BATCH:
        return <ClearAllOutlined />;
      case OperationType.EDIT_BATCH:
        return <ClearAllOutlined />;
      case OperationType.EDIT_DATA:
        return <TableChartOutlined />;
      case OperationType.IMPORT_DATA:
        return <PublishOutlined />;
      default:
        return null;
    }
  }, [type]);

  return (
    <ListItem className={classes.list__item}>
      <div className={clsx(classes.list__item__icon)}>
        {status !== OperationStatus.LOADING ? (
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={<OperationStatusIcon className={classes.list__item__iconText} status={status} />}
            classes={{ badge: clsx(classes.list__item__iconBadge, classes[`list__item__iconBadge--${status}`]) }}
          >
            {icon}
          </Badge>
        ) : (
          icon
        )}
      </div>
      <div className={classes.list__item__content}>
        <Typography noWrap className={classes.list__item__content__title} variant="h6">
          {name}
        </Typography>
        {status === OperationStatus.LOADING && (
          <LinearProgressWithLabel
            className={classes.list__item__content__progress}
            color="primary"
            value={progress}
            variant={progress ? 'determinate' : 'indeterminate'}
          />
        )}
        {status !== OperationStatus.LOADING && description !== null && (
          <Typography className={classes.list__item__content__subtitle} variant="subtitle2">
            {description}
          </Typography>
        )}
      </div>
      {status !== OperationStatus.LOADING && (
        <>
          {goTo !== null && (
            <IconButton color="default" component={NavLink} size="small" to={goTo}>
              <Visibility className={classes.list__item__button} />
            </IconButton>
          )}
          {deleteItem && (
            <IconButton color="default" size="small" onClick={deleteItem}>
              <CloseOutlined className={classes.list__item__button} />
            </IconButton>
          )}
        </>
      )}
    </ListItem>
  );
};

export default OperationItem;
