import { makeStyles } from '@mui/styles';

export const useColorPickerStyles = makeStyles(theme => ({
  container: {
    overflow: 'unset!important',
    maxHeight: 'unset!important',
    '& div': {
      overflowY: 'hidden!important',
    },
    '& > div': {
      borderRadius: theme.shape.borderRadius,
    },
    [`& div[title="${theme.palette.gradients.charcoals[4]}"], & div[title="${theme.palette.gradients.charcoals[3]}"]`]: {
      border: `solid 1px ${theme.palette.divider}`,
    },
  },
}));
