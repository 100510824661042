import { DateRange } from '@mui/x-date-pickers-pro';
import {
  endOfDay,
  endOfHour,
  endOfMinute,
  endOfMonth,
  endOfWeek,
  endOfYear,
  parseISO,
  startOfDay,
  startOfHour,
  startOfMinute,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

import { IsOneOfEnum, IsTimeGroupBy, timeGroupBy } from '@dametis/core';

export enum RelativePeriod {
  LAST_MONTH_START = 'last_month_start',
  LAST_MONTH_END = 'last_month_end',
  LAST_WEEK_START = 'last_week_start',
  LAST_WEEK_END = 'last_week_end',
  THIS_WEEK = 'this_week',
  THIS_MONTH = 'this_month',
  NOW = 'now',
  FULL_NOW = 'fullnow',
}

export type RelativeFromNow = `${RelativePeriod.NOW}-${timeGroupBy}`;

export type RelativeDate = RelativePeriod | RelativeFromNow;

export type ShortcutDate = string | null;

export const defaultShortcutPeriod: ShortcutPeriod = [null, null];

export type ShortcutRelativeFromNowPeriod = [RelativeFromNow, RelativePeriod.FULL_NOW | RelativePeriod.NOW];

export type ShortcutPersonnalisedPeriod = [ShortcutDate, ShortcutDate];

export type ShortcutPeriod = ShortcutPersonnalisedPeriod | ShortcutRelativeFromNowPeriod;

export const isRelativeFromNow = (date: string | null): date is RelativeFromNow => {
  if (typeof date !== 'string') {
    return false;
  }
  const splitDate = date.split('-');
  return splitDate.length === 2 && splitDate[0] === RelativePeriod.NOW && IsTimeGroupBy(splitDate[1]);
};

export const isRelativeFromNowPeriod = (dates: ShortcutPeriod): dates is ShortcutRelativeFromNowPeriod => {
  return isRelativeFromNow(dates[0]) && [RelativePeriod.FULL_NOW, RelativePeriod.NOW].some(rel => rel === dates[1]);
};

export const isRelativePeriod = (date: string | null): date is RelativePeriod => {
  return typeof date === 'string' && IsOneOfEnum(date, RelativePeriod);
};

export const isRelativeDate = (date: string | null): date is RelativeDate => {
  return isRelativePeriod(date) || isRelativeFromNow(date);
};

export const parseTime = (date: ShortcutDate): RegExpMatchArray | null => {
  if (date && typeof date === 'string') {
    const timeRegex = /(\d+)(mo|m|h|d|w|y)/;
    const match = date.match(timeRegex);
    if (match) {
      return match;
    }
  }
  return null;
};

export const groupByToDate = (value: number, unit: string, compareDate: RelativePeriod | undefined, format: 1 | 0): Date => {
  const now = new Date();
  switch (unit) {
    case 'm':
      return compareDate === RelativePeriod.FULL_NOW ? startOfMinute(subMinutes(now, value)) : subMinutes(now, value);
    case 'h':
      return compareDate === RelativePeriod.FULL_NOW ? startOfHour(subHours(now, value)) : subHours(now, value);
    case 'd':
      return compareDate === RelativePeriod.FULL_NOW ? startOfDay(subDays(now, value)) : subDays(now, value);
    case 'w':
      return compareDate === RelativePeriod.FULL_NOW ? startOfWeek(subWeeks(now, value), { weekStartsOn: format }) : subWeeks(now, value);
    case 'mo':
      return compareDate === RelativePeriod.FULL_NOW ? startOfMonth(subMonths(now, value)) : subMonths(now, value);
    case 'y':
      return compareDate === RelativePeriod.FULL_NOW ? startOfYear(subYears(now, value)) : subYears(now, value);
    default:
      throw new Error('Unit not supported');
  }
};

const getRoundNow = (date: Date, compareDate: string | null, format: 1 | 0): Date => {
  if (isRelativeFromNow(compareDate)) {
    const parsedTime = parseTime(compareDate);
    if (!parsedTime) throw new Error();
    const [, , unit] = parsedTime;
    // const value = Number(val);
    // if (compareDate === RelativePeriod.THIS_WEEK || [TimeUnit.DAY, TimeUnit.WEEK].includes(unit)) {
    //   return startOfHour(date);
    // }
    // if (compareDate === RelativePeriod.THIS_MONTH || [TimeUnit.MONTH, TimeUnit.YEAR].includes(unit)) {
    //   return startOfDay(date);
    // }
    switch (unit) {
      case 'm':
        return endOfMinute(subMinutes(date, 1));
      case 'h':
        return endOfHour(subHours(date, 1));
      case 'd':
        return endOfDay(subDays(date, 1));
      case 'w':
        return endOfWeek(subWeeks(date, 1), { weekStartsOn: format });
      case 'mo':
        return endOfMonth(subMonths(date, 1));
      case 'y':
        return endOfYear(subYears(date, 1));
      default:
        throw new Error('Unit not supported');
    }
  }
  return date;
};

export const relativeToDate = (dates: ShortcutPeriod, language: string): Date | null => {
  const date = dates[0];
  const compareDate = dates[1];
  if (date === null) {
    return null;
  }
  const format = language === 'fr' ? 1 : 0;
  const returnDate = new Date();

  if (isRelativeFromNowPeriod(dates)) {
    const parsedTime = parseTime(date);
    if (!parsedTime) throw new Error();
    const [, value, unit] = parsedTime;
    return groupByToDate(Number(value), unit, dates[1], format);
  }

  switch (date) {
    case RelativePeriod.LAST_MONTH_START:
      return startOfMonth(returnDate.setMonth(returnDate.getMonth() - 1));
    case RelativePeriod.LAST_MONTH_END:
      return endOfMonth(returnDate.setMonth(returnDate.getMonth() - 1));
    case RelativePeriod.LAST_WEEK_START:
      return startOfDay(startOfWeek(subWeeks(returnDate, 1), { weekStartsOn: format }));
    case RelativePeriod.LAST_WEEK_END:
      return endOfDay(endOfWeek(subWeeks(returnDate, 1), { weekStartsOn: format }));
    case RelativePeriod.THIS_MONTH:
      return startOfMonth(returnDate);
    case RelativePeriod.THIS_WEEK:
      return startOfWeek(returnDate, { weekStartsOn: format });
    case RelativePeriod.NOW:
      return new Date();
    case RelativePeriod.FULL_NOW:
      return getRoundNow(returnDate, compareDate, format);
    default:
  }
  return parseISO(date);
};

export const relativesToDateRange = (dates: ShortcutPeriod, language: string): DateRange<Date> => {
  return [relativeToDate(dates, language), relativeToDate([dates[1], dates[0]], language)];
};

export const urlParamToDateRange = (dates: ShortcutPersonnalisedPeriod, language: string): DateRange<Date> => {
  if (isRelativeDate(dates[0]) || isRelativeDate(dates[1])) {
    return relativesToDateRange(dates, language);
  }
  return [dates[0] !== null ? parseISO(decodeURI(dates[0])) : null, dates[1] !== null ? parseISO(decodeURI(dates[1])) : null];
};

export const DateToUrlParam = (date: Date | RelativeDate): string => {
  if (typeof date === 'string' && isRelativeDate(date)) {
    return date;
  }
  return date ? date.toISOString() : '';
};

export const removeEmptyUrlParams = (searchParams: URLSearchParams): URLSearchParams => {
  // param[1] is the value of a search param
  const params = Array.from(searchParams.entries()).filter(param => param[1].length > 0);
  return new URLSearchParams(params);
};

export const updateUrlPeriod = (from: Date, to: Date) => {
  const currentSearchParams = new URLSearchParams(window.location.search);
  currentSearchParams.set('from', DateToUrlParam(from));
  currentSearchParams.set('to', DateToUrlParam(to));

  const updatedSearchParams = removeEmptyUrlParams(currentSearchParams);
  const newUrl = `${window.location.pathname}?${updatedSearchParams.toString()}`;

  window.history.pushState({ path: newUrl }, '', newUrl);
};
