export default {
  name: {
    name: 'Nom',
    nameTooltip: 'Nom',
  },
  unit: {
    name: 'Unité',
    nameTooltip: 'Unité',
  },
  sensorName: {
    name: 'Capteur',
    nameTooltip: 'Nom du capteur',
  },
  reference: {
    name: 'Réfèrence',
    nameTooltip: 'Réfèrence',
  },
  sensorDescription: {
    name: 'Description',
    nameTooltip: 'Description',
  },
  enabled: {
    name: 'Act',
    nameTooltip: 'Activé',
  },
  modbusTCPUid: {
    name: 'Uid',
    nameTooltip: 'Uid',
  },
  modbusTCPFunction: {
    name: 'Func',
    nameTooltip: 'Function',
  },
  modbusTCPAddress: {
    name: 'Adresse',
    nameTooltip: 'Adresse',
  },
  modbusTCPInvertsWords: {
    name: 'Inversé',
    nameTooltip: 'Mot inversé',
  },
  modbusTCPTransformer: {
    name: 'Mult',
    nameTooltip: 'Multiplier',
  },
  modbusTCPDataType: {
    name: 'Type',
    nameTooltip: 'Data Type',
  },
  modbusTCPBit: {
    name: 'Bit',
    nameTooltip: 'Numéro de bit',
  },
  check: {
    name: 'Check',
    nameTooltip: 'Check',
  },
  delete: {
    name: 'Del',
    nameTooltip: 'Delete',
  },

  groupNameSize: {
    modbus: 'Modbus TCP',
    setting: 'Paramètre Général',
    action: 'Action',
  },
  /*
  functionName: {
    _0: 'Lire la bobine',
    _1: "Lire l'entrée discrète",
    _2: 'Lire le registre de maintien',
    _3: "Lire les registres d'entrée",
  }, */
  functionName: {
    _0: 'Read Coil',
    _1: 'Read Discrete Input',
    _2: 'Read Holding Register',
    _3: 'Read Input Registers',
  },
  typeName: {
    int16: 'Entier signé',
    uint16: 'Entier non signé',
    int32: 'Signé Long',
    uint32: 'Non signé',
    float32: 'Flottant',
    boolean: 'Booléen',
  },
};
