export const areTimeDataEqual = (dataA: [number, number][], dataB: [number, number][]): boolean => {
  // if (!dataA || !dataB)
  if (dataA.length !== dataB.length) {
    return false;
  }
  for (let index = 0; index < dataA.length; index += 1) {
    const elementA = dataA[index];
    const elementB = dataB[index];
    if (elementA[0] !== elementB[0] || elementA[1] !== elementB[1]) {
      return false;
    }
  }
  return true;
};
