export default {
  title: 'Paramètres du groupe',
  groupMenu: {
    informations: {
      name: 'Informations',
      description: '',
    },
    users: {
      name: 'Utilisateurs',
      description: '',
    },
  },
  informations: {
    form: {
      siteName: 'Nom du site',
      groupName: 'Nom du groupe',
      defaultReport: "Rapport d'accueil par défaut",
      selectReport: 'Sélectionner un rapport',
      noReport: 'Aucun rapport par défaut',
      address: 'Adresse',
      language: 'Langue du site',
      timeZone: 'Fuseau horaire',
      noTimeZoneFound: 'Aucun fuseau horaire correspondant',
    },
  },
  toast: {
    successSaveChanges: 'Les modifications ont bien été enregistrées.',
    errorSaveChanges: "Impossible d'enregistrer les modifications.",
    infoUserInvitation: "Un émail d'invitation a été envoyé.",
    errorUserInvitation: "Impossible d'envoyer l'invitation.",
    userAlreadyExists: 'Cette adresse email est déjà associée à un compte.',
    successNewPeriod: 'La nouvelle période est enregistrée.',
    successRemovePeriod: 'La période a bien été supprimée.',
  },
  tooltip: {
    editGroupInfo: 'Modifier les informations du groupe',
  },
  modal: {
    title: "Modifier l'utilisateur",
    addTitle: 'Ajouter un utilisateur',
    personalInformation: 'Informations personnelles',
    permissions: 'Permissions',
    firstName: 'Prénom',
    lastName: 'Nom',
    phone: 'Téléphone',
    email: 'Email',
    corporateLabel: 'Accès au groupe',
    corporate: 'Corporate',
    userSiteList: "Sites de l'utilisateur",
    warningEmptySites: "L'utilisateur doit avoir au moins un site",
    select: {
      groupRole: 'Rôle du groupe',
      siteRole: 'Rôle du site ',
      roleSITE_ADMINPrimary: 'Administrateur',
      roleSITE_ADMINSecondary: 'Administration, lecture et écriture',
      roleCLIENTPrimary: 'Utilisateur standard',
      roleCLIENTSecondary: 'Lecture et écriture',
      roleReadOnlyPrimary: 'Utilisateur limité',
      roleReadOnlySecondary: 'Lecture seule',
    },
    button: {
      next: 'Suivant',
      previous: 'Précédent',
    },
  },
  stepper: {
    informations: 'Informations',
    permissions: 'Permissions',
  },
};
