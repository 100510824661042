import { ContentCopyOutlined } from '@mui/icons-material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getSingleVariableCalculation } from '@dametis/mathjs';

import { calculationToString } from 'functions/calculationToString';
import { useDispatch, useSelector } from 'store';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import CalculationMenuItem from '../CalculationMenuItem';

const CopyVariableNameOption: FC = () => {
  const { t } = useTranslation('calculationMenu');
  const dispatch = useDispatch();

  const calculation = useSelector(state => state.calculationMenu.calculation);

  const singleVariable = useMemo(() => (calculation ? getSingleVariableCalculation(calculation) : false), [calculation]);
  const variableName = useMemo(() => calculationToString(calculation, true, false), [calculation]);

  const handleCopyVariableName = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(variableName);
      dispatch(
        addToast({
          message: t('toast.copyVariableName'),
          severity: ToastSeverity.INFO,
        }),
      );
    } catch (err) {
      console.error(err);
    }
  }, [variableName, t, dispatch]);

  return (
    <CalculationMenuItem
      icon={ContentCopyOutlined}
      label={singleVariable ? t('label.copyVariableName') : t('label.copyCalculation')}
      onClick={handleCopyVariableName}
    />
  );
};

export default CopyVariableNameOption;
