import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname, state } = useLocation();

  useEffect(() => {
    if (!state?.noScrollTop) {
      window.scrollTo(0, 0);
    }
  }, [pathname, state?.noScrollTop]);

  return null;
};

export default ScrollToTop;
