import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface CartState {
  isOpen: boolean;
}

const initialState: CartState = {
  isOpen: false,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setIsOpen } = cartSlice.actions;

export default cartSlice.reducer;
