import { Components } from '@mui/material';

export default {
  styleOverrides: {
    fullWidth: {
      minWidth: 'unset',
      flexGrow: 1,
    },
  },
} as Components['MuiTab'];
