import { CircularProgress, Grid, Stack, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';

import useCircLoaderStyles from './CircularLoader.styles';

interface Props {
  loading?: boolean;
  overlay?: boolean;
  className?: string;
  progress?: { name: string; logs: string; progress: string };
}

const CircularLoader: FC<PropsWithChildren<Props>> = ({
  loading = false,
  overlay = true,
  children = null,
  className = '',
  progress = undefined,
}) => {
  const theme = useTheme();
  const classes = useCircLoaderStyles();
  return (
    <div className={classes.circularLoader}>
      <div className={classes.circularLoader__container} style={{ visibility: loading && overlay ? 'hidden' : 'visible' }}>
        {children}
      </div>
      <CSSTransition
        unmountOnExit
        classNames={{
          exit: classes['circularLoader__overlay--exit'],
          exitActive: classes['circularLoader__overlay--exitActive'],
        }}
        in={loading}
        timeout={theme.transitions.duration.standard}
      >
        <Grid
          container
          alignItems="center"
          className={overlay ? clsx(classes.circularLoader__overlay, className) : undefined}
          justifyContent="center"
        >
          <Stack alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100%', p: theme.spacing(1) }}>
            <CircularProgress className={classes.circularLoader__overlay__icon} color="secondary" />
            {progress && (
              <>
                <Typography
                  noWrap
                  sx={{
                    mt: theme.spacing(2),
                    width: '100%',
                    textAlign: 'center',
                  }}
                  variant="subtitle2"
                >
                  {progress.name}
                </Typography>
                <Typography
                  noWrap
                  sx={{
                    mt: theme.spacing(2),
                    width: '100%',
                    textAlign: 'center',
                  }}
                  variant="subtitle2"
                >
                  {progress.logs}
                </Typography>
                <Typography
                  noWrap
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                  }}
                  variant="subtitle2"
                >
                  {progress.progress}
                </Typography>
              </>
            )}
          </Stack>
        </Grid>
      </CSSTransition>
    </div>
  );
};

export default CircularLoader;
