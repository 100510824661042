export default {
  title: {
    backups: 'Backups',
    newBackup: 'Nouveau Backup',
    beforeRestoring: 'Êtes-vous sûr ?',
  },
  text: {
    createdAt: 'Créé le',
    noBackup: 'Aucun Backup',
  },
  input: {
    label: {
      name: 'Nom',
    },
  },
  button: {
    backup: 'Backup',
    cancel: 'Annuler',
    create: 'Créer',
    restore: 'Oui, restaurer',
  },
  tooltip: {
    restore: 'Restaurer le backup',
    delete: 'Supprimer le backup',
  },
  toast: {
    createBackupSuccess: 'Backup créé avec succès.',
    createBackupError: 'Erreur lors de la création du backup.',
    restoreBackupSuccess: 'Backup restauré avec succès.',
    restoreBackupError: 'Erreur lors de la restauration du backup.',
    deleteBackupSuccess: 'Backup supprimé avec succès.',
    deleteBackupError: 'Erreur lors de la suppression du backup.',
    fetchBackupsError: 'Erreur lors de la récupération des backups.',
  },
  columns: {
    name: 'Nom',
    createdAt: 'Date de création',
    uuid: 'UUID',
  },
};
