import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MetadataType } from '@dametis/core';

export interface MetadataHeaderProps {
  name: string;
  type: MetadataType;
}

const MetadataHeader: FC<MetadataHeaderProps> = ({ name, type }) => {
  const { t } = useTranslation('lego');

  return (
    <Stack gap={0.5}>
      <Typography lineHeight={1} variant="caption">
        {t(`metadataType.${type}`)}
      </Typography>
      <Typography lineHeight={1}>{name}</Typography>
    </Stack>
  );
};

export default MetadataHeader;
