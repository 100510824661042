import { Components, Theme, dividerClasses } from '@mui/material';

import { LIST_PADDING } from './MuiList';

export default {
  styleOverrides: {
    list: {
      paddingTop: LIST_PADDING,
      paddingBottom: LIST_PADDING,
      [`& .${dividerClasses.root}`]: {
        marginTop: LIST_PADDING,
        marginBottom: LIST_PADDING,
      },
    },
  },
} as Components<Theme>['MuiMenu'];
