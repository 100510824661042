export default {
  title: {
    newMacroLib: 'Nouvelle bibliothèque',
  },
  button: {
    createMacroLib: 'Créer',
    deleteMacroLib: 'Supprimer',
    editMacroLib: 'Modifier',
    saveMacroLib: 'Sauvegarder',
    cancel: 'Annuler',
  },
  toast: {
    createMacroLibSuccess: 'Bibliothèque créée avec succès.',
    createMacroLibError: 'Une erreur est survenue lors de la création de la bibliothèque.',
    saveMacroLibSuccess: 'Changements enregistrés.',
    saveMacroLibError: 'Une erreur est survenue lors de la sauvegarde des changements.',
    deleteMacroLibSuccess: 'Bibliothèque supprimée avec succès.',
    deleteMacroLibError: 'Une erreur est survenue lors de la suppression de la bibliothèque.',
    duplicateMacroLibSuccess: 'Bibliothèque dupliquée avec succès.',
    duplicateMacroLibError: 'Une erreur est survenue lors de la duplication de la bibliothèque.',
  },
  input: {
    label: {
      name: 'Nom',
      description: 'Description',
    },
  },
};
