export default {
  title: {
    period: 'Período',
  },
  input: {
    label: {
      name: 'Nombre',
      months: 'Mes',
      weeklySchedule: 'Semanal',
    },
    value: {
      noPeriods: 'Ningún período creado',
      always: 'Todo el tiempo (por defecto)',
    },
  },
  button: {
    cancel: 'Cancelar',
    save: 'Guardar',
  },
  list: {
    item: {
      addPeriod: 'Crear un nuevo período',
      openPeriodsPage: 'Ir a la página de Períodos',
    },
  },
  text: {
    everyMonth: 'Cualquier mes',
    allDayEveryday: 'Cualquier día u hora',
  },
  alert: {
    mismatchedTimeZonesTitle: '¡Tus zonas horarias son diferentes!',
    mismatchedTimeZones:
      'Estás en "{{currTZ}}" mientras que el sitio está en "{{siteTZ}}". Ten en cuenta que los horarios a continuación están en "{{currTZ}}".',
  },
  tooltip: {
    addPeriod: 'Añadir un período',
    openPeriodsPage: 'Abrir la página de períodos',
  },
};
