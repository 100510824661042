import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { CommentInfo, CreateCommentInfo, PartialKey, TinyUserInfo, UUID, UpdateCommentBody } from '@dametis/core';

import { isAfter, isBefore } from 'localization/localizedDateFns';
import { sdk } from 'sdk';
import { useSelector } from 'store';
import { useComments, useCreateCommentMutation, useDeleteCommentMutation, useUpdateCommentMutation } from 'store/api/comments';
import { CommentFilter, CommentSortBy, CommentView, CommentsProps, EntityType } from 'types/comment';

export type CommentsProviderProps = CommentsProps;

export interface CommentsContextState extends PartialKey<Required<CommentsProviderProps>, 'onCreateCb' | 'onUpdateCb'> {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  fullscreen: boolean;
  setFullscreen: Dispatch<SetStateAction<boolean>>;
  filteredComments: CommentInfo[];
  view: CommentView;
  setView: Dispatch<SetStateAction<CommentView>>;
  displayOnlyOnCurrentPeriod: boolean;
  setDisplayOnlyOnCurrentPeriod: Dispatch<SetStateAction<boolean>>;
  sortBy: CommentSortBy;
  setSortBy: Dispatch<SetStateAction<CommentSortBy>>;
  periodFilter: CommentFilter;
  setPeriodFilter: Dispatch<SetStateAction<CommentFilter>>;
  ownersFilter: UUID[];
  setOwnersFilter: Dispatch<SetStateAction<UUID[]>>;
  commentsOwners: TinyUserInfo[];
  createComment: (comment: CreateCommentInfo) => Promise<void>;
  editComment: (comment: UpdateCommentBody, commentId: UUID) => Promise<void>;
  removeComment: (commentId: UUID) => Promise<void>;
  collaborators: TinyUserInfo[];
}

export const CommentsContext = createContext<CommentsContextState | undefined>(undefined);

const emptyArray: CommentInfo[] = [];

const CommentsProvider: FC<PropsWithChildren<CommentsProviderProps>> = ({
  entity,
  entityUuid,
  onCreateCb,
  onUpdateCb,
  children = undefined,
  ...props
}) => {
  const period = useSelector(state => state.period.present.period);
  const groupId = useSelector(state => state.auth.selectedGroup!.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);
  const queryParams = useMemo(
    () => ({ groupId, siteId, entity, entityUuid: entityUuid ?? undefined }),
    [entity, entityUuid, groupId, siteId],
  );
  const { data: comments = emptyArray, isFetching, isSuccess } = useComments(queryParams);

  const [createCommentQuery] = useCreateCommentMutation();
  const [updateComment] = useUpdateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();

  const [open, setOpen] = useState<boolean>(false);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [view, setView] = useState<CommentView>(CommentView.LIST);
  const [displayOnlyOnCurrentPeriod, setDisplayOnlyOnCurrentPeriod] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<CommentSortBy>(() => {
    if (entity === EntityType.REPORT) {
      return CommentSortBy.PERIOD_FROM_REVERSE;
    }
    if (entity === EntityType.PLAYGROUND) {
      return CommentSortBy.PERIOD_FROM_REVERSE;
    }
    return CommentSortBy.CREATION_DATE_REVERSE;
  });
  const [periodFilter, setPeriodFilter] = useState<CommentFilter>(CommentFilter.ALL);

  const commentsOwners = useMemo(
    () =>
      Object.values(
        comments.reduce<Record<UUID, TinyUserInfo>>((acc, comment) => {
          if (comment.owner !== undefined && acc[comment.owner.uuid] === undefined) {
            acc[comment.owner.uuid] = comment.owner;
          }
          return acc;
        }, {}),
      ),
    [comments],
  );

  const [ownersFilter, setOwnersFilter] = useState<UUID[]>([]);
  const [collaborators, setCollaborators] = useState<TinyUserInfo[]>([]);
  const corporate = useSelector(state => !state.auth.selectedSite);

  const createComment = useCallback(
    async (comment: CreateCommentInfo) => {
      if (entityUuid === null) {
        return;
      }
      await createCommentQuery({ comment, entity, entityUuid });
    },
    [entity, entityUuid, createCommentQuery],
  );

  const editComment = useCallback(
    async (comment: UpdateCommentBody, commentId: UUID) => {
      if (entityUuid === null) {
        return;
      }
      await updateComment({ comment, commentId, entityUuid });
    },
    [entityUuid, updateComment],
  );

  const removeComment = useCallback(
    async (commentId: UUID) => {
      if (entityUuid === null) {
        return;
      }
      await deleteComment({ commentId, entityUuid });
    },
    [deleteComment, entityUuid],
  );

  const localFilterComments = useMemo(
    () =>
      comments.filter(comment => {
        const isCommentOwnerFiltered = comment.owner !== undefined && ownersFilter.includes(comment.owner.uuid);
        if (isCommentOwnerFiltered === false) {
          return false;
        }
        if (comment.period !== null && periodFilter === CommentFilter.CURRENT_PERIOD) {
          return (
            isAfter(new Date(comment.period.from), new Date(period.to)) || isBefore(new Date(comment.period.to), new Date(period.from))
          );
        }
        return periodFilter !== CommentFilter.CURRENT_PERIOD;
      }),
    [comments, ownersFilter, period.from, period.to, periodFilter],
  );

  const filteredComments = useMemo(
    () =>
      localFilterComments.sort((commentA, commentB) => {
        if (commentA.date === null || commentB.date === null) {
          return 0;
        }
        if (sortBy === CommentSortBy.DATE) {
          return isBefore(new Date(commentB.date), new Date(commentA.date)) ? 1 : -1;
        }
        if (sortBy === CommentSortBy.DATE_REVERSE) {
          return isBefore(new Date(commentA.date), new Date(commentB.date)) ? 1 : -1;
        }
        if (commentA.createdAt === undefined || commentB.createdAt === undefined) {
          return 0;
        }
        if (sortBy === CommentSortBy.CREATION_DATE) {
          return isBefore(new Date(commentB.createdAt), new Date(commentA.createdAt)) ? 1 : -1;
        }
        if (sortBy === CommentSortBy.CREATION_DATE_REVERSE) {
          return isBefore(new Date(commentA.createdAt), new Date(commentB.createdAt)) ? 1 : -1;
        }
        if (commentA.period === null || commentB.period === null) {
          return 0;
        }
        if (sortBy === CommentSortBy.PERIOD_TO) {
          return isBefore(new Date(commentB.period.to), new Date(commentA.period.to)) ? 1 : -1;
        }
        if (sortBy === CommentSortBy.PERIOD_TO_REVERSE) {
          return isBefore(new Date(commentA.period.to), new Date(commentB.period.to)) ? 1 : -1;
        }
        if (sortBy === CommentSortBy.PERIOD_FROM) {
          return isBefore(new Date(commentB.period.from), new Date(commentA.period.from)) ? 1 : -1;
        }
        if (sortBy === CommentSortBy.PERIOD_FROM_REVERSE) {
          return isBefore(new Date(commentA.period.from), new Date(commentB.period.from)) ? 1 : -1;
        }
        return 0;
      }),
    [localFilterComments, sortBy],
  );

  const fetchCollaborators = useCallback(async () => {
    try {
      if (groupId === undefined && siteId === undefined) {
        return;
      }
      const { data } =
        corporate && groupId !== undefined ? await sdk.corporate.ListUsers(groupId) : await sdk.user.ListUsersOfSite(siteId!);
      setCollaborators(data);
    } catch (err) {
      console.error(err);
    }
  }, [siteId, corporate, groupId]);

  useEffect(() => {
    void fetchCollaborators();
  }, [fetchCollaborators]);

  useEffect(() => {
    if (isSuccess) {
      setOwnersFilter(commentsOwners.map(user => user.uuid));
    }
  }, [commentsOwners, isSuccess]);

  const contextValues: CommentsContextState = useMemo(
    () => ({
      entity,
      entityUuid,
      onCreateCb,
      onUpdateCb,
      open,
      setOpen,
      fullscreen,
      setFullscreen,
      loading: isFetching,
      filteredComments,
      view,
      setView,
      displayOnlyOnCurrentPeriod,
      setDisplayOnlyOnCurrentPeriod,
      sortBy,
      setSortBy,
      periodFilter,
      setPeriodFilter,
      ownersFilter,
      setOwnersFilter,
      commentsOwners,
      createComment,
      editComment,
      removeComment,
      collaborators,
      ...props,
    }),
    [
      entity,
      entityUuid,
      onCreateCb,
      onUpdateCb,
      open,
      fullscreen,
      isFetching,
      filteredComments,
      view,
      displayOnlyOnCurrentPeriod,
      sortBy,
      periodFilter,
      ownersFilter,
      setOwnersFilter,
      commentsOwners,
      createComment,
      editComment,
      removeComment,
      collaborators,
      props,
    ],
  );

  return <CommentsContext.Provider value={contextValues}>{children}</CommentsContext.Provider>;
};

export const useCommentsContext = () => {
  const context = useContext(CommentsContext);

  if (context === undefined) {
    throw Error('useCommentsContext must be used inside an CommentsProvider');
  }

  return context;
};

export default CommentsProvider;
