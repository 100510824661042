import { Box } from '@mui/material';
import { EditableProps } from 'slate-react/dist/components/editable';

import { ONE_CHARACTER_WIDTH } from '../styled';

import SlateBlock from './SlateBlock';
import SlateFunction from './SlateFunction';
import { SlateSymbol } from './SlateSymbol';
import SlateVariable from './SlateVariable';

export const RenderElement: EditableProps['renderElement'] = ({ element, attributes, children }) => {
  switch (element.type) {
    case 'variable':
      return (
        <SlateVariable attributes={attributes} element={element}>
          {children}
        </SlateVariable>
      );
    case 'block':
      return (
        <SlateBlock attributes={attributes} element={element}>
          {children}
        </SlateBlock>
      );
    case 'symbol':
      return (
        <SlateSymbol attributes={attributes} element={element}>
          {children}
        </SlateSymbol>
      );
    case 'function':
      return (
        <SlateFunction attributes={attributes} element={element}>
          {children}
        </SlateFunction>
      );
    default:
      return (
        <Box component="span" display="block" sx={{ m: 0, '&:not(:first-of-type)': { ml: `${ONE_CHARACTER_WIDTH}px` } }} {...attributes}>
          {children}
        </Box>
      );
  }
};
