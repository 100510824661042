import {
  BlockTypeMetric,
  CalculationVariable,
  CreateStandardBlockBody,
  IsBlockTypeParameterOrMetricVariable,
  StandardBlockParameterBody,
  TraverseCalculation,
} from '@dametis/core';

export type MetricDependencies = Record<StandardBlockParameterBody['blockKey'], StandardBlockParameterBody>;

export type ShortMetric = Pick<BlockTypeMetric, 'calculation'>;

export type BlockBase = Partial<Pick<CreateStandardBlockBody, 'parameters' | 'metrics'>>;

export const getMetricDependencies = (currentMetric: ShortMetric, base: BlockBase, result: MetricDependencies = {}): MetricDependencies => {
  TraverseCalculation(currentMetric.calculation as CalculationVariable, node => {
    if (IsBlockTypeParameterOrMetricVariable(node)) {
      const baseParameter = (base?.parameters ?? []).find(parameter => parameter.blockKey === node.blockKey);
      const otherBaseMetric = (base?.metrics ?? []).find(metric => metric.blockKey === node.blockKey);
      if (baseParameter && !result[baseParameter.blockKey]) {
        result[baseParameter.blockKey] = baseParameter;
      } else if (otherBaseMetric) {
        getMetricDependencies(otherBaseMetric, base, result);
      }
    }
  });
  return result;
};
