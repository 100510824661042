import { AlarmInfo, AlarmPriority, ShortAlarmInfo, VariableInfo } from '@dametis/core';

export interface AlarmsHistoryFilters {
  date: string;
  priorities: AlarmPriority[];
  alarms: ShortAlarmInfo[];
}

export enum SortOption {
  ALPHA_ASC = 'alpha_asc',
  ALPHA_DESC = 'alpha_desc',
  DATE_ASC = 'date_asc',
  DATE_DESC = 'date_decs',
}

export interface AlarmsSettingsFilters {
  priorities: AlarmPriority[];
  variables: Partial<VariableInfo>[];
  sortBy: SortOption;
}

export type ClientAlarmInfo = Omit<AlarmInfo, 'condition'>;
