import { ListItemButton, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { FolderInfo, ShortStandardBlockInfo } from '@dametis/core';

import { DeployedCodeOutlined } from 'assets/icons/DeployedCodeOutlined';
import { getStandardBlockPath } from 'components/Lego/helpers/getStandardBlockPath';
import { getStandardBlockUrl } from 'components/Lego/helpers/getStandardBlockUrl';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';

import { MenuTab } from '../LegoCatalog';

import SuggestionBreadcrumbs from './SuggestionBreadcrumbs/SuggestionBreadcrumbs';

export interface StandardBlockSuggestionProps {
  standardBlock: ShortStandardBlockInfo;
}

const StandardBlockSuggestion: FC<StandardBlockSuggestionProps> = ({ standardBlock }) => {
  const { t } = useTranslation('lego');

  const { data: globalRootFolder = null } = useReadGlobalRootFolderQuery();

  const parsedGlobalRootFolder: FolderInfo | null = useMemo(
    () => (globalRootFolder ? { ...globalRootFolder, name: t('lego:text.factory') } : null),
    [globalRootFolder, t],
  );

  const path = useMemo(
    () => (parsedGlobalRootFolder ? getStandardBlockPath(parsedGlobalRootFolder, standardBlock.uuid) : []),
    [parsedGlobalRootFolder, standardBlock.uuid],
  );

  return (
    <ListItemButton component={NavLink} to={getStandardBlockUrl(parsedGlobalRootFolder, standardBlock.uuid)}>
      <Stack>
        {path.length > 0 && <SuggestionBreadcrumbs path={path} />}
        {path.length === 0 && (
          <Typography py={0.25} variant="caption">
            {t(`menuTab.${MenuTab.NO_FOLDER_CONTENT}`)}
          </Typography>
        )}
        <Stack alignItems="center" direction="row" gap={1}>
          <Stack alignItems="center" justifyContent="center">
            <DeployedCodeOutlined sx={{ fontSize: 22, color: standardBlock.ui.color }} />
          </Stack>
          <Stack>
            <Typography variant="h6">{standardBlock.name}</Typography>
            {/* <Typography variant="subtitle2">{type.description}</Typography> */}
          </Stack>
        </Stack>
      </Stack>
    </ListItemButton>
  );
};

export default StandardBlockSuggestion;
