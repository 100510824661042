import { FormLabel } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AliasVariable, ShortAliasInfo } from '@dametis/core';

import { useAliases } from 'store/api/aliases';

import CalculationSlate from '../../../../UI/CalculationSlate/CalculationSlate';

const emptyArray: ShortAliasInfo[] = [];

interface Props {
  variable: AliasVariable;
}

const AliasVariableDetails: FC<Props> = ({ variable }) => {
  const { t } = useTranslation('vnc');

  const { data: aliases = emptyArray } = useAliases();

  const alias = useMemo(() => aliases?.find(al => al.uuid === variable.aliasUuid), [aliases, variable.aliasUuid]);

  if (!alias) return null;
  return (
    <>
      <div>
        <FormLabel>{t('input.label.aliasSlate')}</FormLabel>
        <CalculationSlate calculation={alias.calculation} />
      </div>
    </>
  );
};

export default AliasVariableDetails;
