import { alpha, useTheme } from '@mui/material';
import { ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';

export interface SeriesContainerProps extends ComponentPropsWithoutRef<'div'> {
  color?: string;
}

const SeriesContainer: FC<PropsWithChildren<SeriesContainerProps>> = ({ color = undefined, style, ...props }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: color ? alpha(color, 0.3) : 'transparent',
        padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
        borderRadius: theme.shape.borderRadius,
        flexGrow: 1,
        ...style,
      }}
      {...props}
    />
  );
};

export default SeriesContainer;
