import { SvgIconComponent } from '@mui/icons-material';
import { FC } from 'react';
import { NavigateOptions, To } from 'react-router-dom';

import { NanoEntityInfo, PermissionKey, UUID } from '@dametis/core';

import navbar from 'localization/en/navbar';

import { User } from './auth';

export enum RouteScope {
  SITE = 'site',
  GROUP = 'group',
  ADMIN = 'admin',
}

export interface Route {
  name: keyof typeof navbar;
  url: string;
  icon: SvgIconComponent;
  activeIcon: SvgIconComponent;
  permission?: PermissionKey;
  Secondary?: FC<{ route: RouteWithIndex }>;
  scopes: RouteScope[];
}

export interface RouteWithIndex extends Route {
  index: number;
}

export interface SubRouteBase {
  name: NestedKeyOf<typeof navbar> | string;
  permission?: PermissionKey;
  scopes: RouteScope[];
  touchOnly?: boolean;
  check?: (user: User, selectedGroupId: UUID) => boolean;
}

export interface SubRouteRedirect extends SubRouteBase {
  url: string;
}

export interface SubRouteAction extends SubRouteBase {
  action: () => void;
  icon?: SvgIconComponent;
}

export type SubRoute = SubRouteRedirect | SubRouteAction;

export const IsSubRouteRedirect = (subRoute: any): subRoute is SubRouteRedirect => subRoute?.url !== undefined;

export const IsSubRouteAction = (subRoute: any): subRoute is SubRouteAction => subRoute?.action !== undefined;

type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object ? `${Key}` | NestedKeyOf<ObjectType[Key]> : `${Key}`;
}[keyof ObjectType & (string | number)];

export enum LastEntityType {
  PROJECTS = 'projects',
  PLAYGROUNDS = 'playgrounds',
  REPORTS = 'reports',
  SYNOPTICS = 'synoptics',
  LEGO_FOLDERS = 'legoFolders',
}

export type LastEntityValues = NanoEntityInfo[];

export type LastEntities = Record<LastEntityType, LastEntityValues>;

export type LastEntitiesStorage = Record<UUID, LastEntities>;

export enum DrawerActionType {
  PLAYGROUNDS = 'playgrounds',
  REPORTS = 'reports',
}

export enum DrawerActionName {
  NEW_PLAYGROUND_WITH_TY_CHART = 'newPlaygroundWithTyChart',
  NEW_REPORT_CUSTOM_PERIOD = 'newReportCustomPeriod',
}

export interface DrawerAction {
  name: DrawerActionName;
  scopes: RouteScope[];
  navigate?: {
    to: To;
    options?: NavigateOptions;
  };
  action?: () => void;
  icon?: SvgIconComponent;
}
