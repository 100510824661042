import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { CalculationVariable, UUID, VarCalc } from '@dametis/core';

import { useLocalStorage } from 'hooks/useLocalStorage';
import { useDispatch, useSelector } from 'store';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';
import { CartItem } from 'types/cart';
import { CART_ITEMS_STORAGE_KEY } from 'types/variables';

import { checkParsedCalculationInCart } from './checkParsedCalculationInCart';

const INITIAL_VALUE: { uuid: string; variable: CalculationVariable<VarCalc> }[] = [];

export interface CartStorageValue {
  items: CartItem[];
  setItems: Dispatch<SetStateAction<CartItem[]>>;
  addItem: (variable: CalculationVariable) => UUID | null;
  addItems: (variables: CalculationVariable[]) => void;
  removeItem: (itemId: UUID) => void;
  clearCart: () => void;
}

const useCartStorage = (): CartStorageValue => {
  const { t } = useTranslation('toast');
  const dispatch = useDispatch();

  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const [items, setItems] = useLocalStorage(CART_ITEMS_STORAGE_KEY, INITIAL_VALUE, { groupId, siteId });

  const addItem = useCallback(
    (variable: CalculationVariable) => {
      const alreadyInCart = checkParsedCalculationInCart(items, variable);
      if (alreadyInCart) {
        dispatch(addToast({ severity: ToastSeverity.WARNING, message: t('warningAddCart') }));
        return null;
      }
      const uuid = uuidv4();
      setItems([...items, { uuid, variable }]);
      dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: t('successAddCart') }));
      return uuid;
    },
    [items, setItems, dispatch, t],
  );

  const addItems = useCallback(
    (variables: CalculationVariable[]) => {
      let alreadyInCartItems = 0;
      const newItems = [...items];
      variables.forEach(variable => {
        if (checkParsedCalculationInCart(items, variable)) {
          alreadyInCartItems += 1;
        } else {
          newItems.push({ uuid: uuidv4(), variable });
        }
      });

      if (alreadyInCartItems === variables.length) {
        dispatch(addToast({ severity: ToastSeverity.WARNING, message: t('alreadyInCartAllItems') }));
      } else {
        setItems(newItems);
        if (alreadyInCartItems > 0) {
          dispatch(addToast({ severity: ToastSeverity.WARNING, message: t('alreadyInCartSomeItems') }));
        } else {
          dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: t('successAddCartItems') }));
        }
      }
    },
    [items, setItems, dispatch, t],
  );

  const removeItem = useCallback(
    (itemId: UUID) => {
      setItems(items.filter(item => item.uuid !== itemId));
      dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: t('successRemoveCart') }));
    },
    [items, setItems, dispatch, t],
  );

  const clearCart = useCallback(() => {
    setItems([]);
  }, [setItems]);

  return {
    items,
    setItems,
    addItem,
    addItems,
    removeItem,
    clearCart,
  };
};

export default useCartStorage;
