export default {
  button: {
    close: 'Fermer',
    previous: 'Précédent',
    next: 'Suivant',
    create: 'Créer',
  },
  input: {
    fromModel: 'Utiliser un modèle',
  },
  stepper: {
    general: 'Général',
    model: 'Modèle',
  },
};
