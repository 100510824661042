import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TagInfo, UUID } from '@dametis/core';

import TagAutocompleteFilter from 'components/UI/TagAutocompleteFilter/TagAutocompleteFilter';
import { useTags } from 'store/api/tags';

export interface SelectTagsProps {
  selectedTagsUuid: UUID[];
  setSelectedTagsUuid: Dispatch<SetStateAction<UUID[]>>;
  isOpen: boolean;
}

const emptyArray: TagInfo[] = [];

const SelectTags: FC<SelectTagsProps> = ({ selectedTagsUuid, setSelectedTagsUuid, isOpen }) => {
  const { t } = useTranslation('lego');

  const { data: tags = emptyArray } = useTags();

  const selectedTags = useMemo(() => tags.filter(tag => selectedTagsUuid.includes(tag.uuid)), [tags, selectedTagsUuid]);

  const handleTagsChange = useCallback(
    (newTags: TagInfo[]) => {
      setSelectedTagsUuid(newTags.map(newTag => newTag.uuid));
    },
    [setSelectedTagsUuid],
  );

  return (
    <TagAutocompleteFilter
      canAddTag={false}
      disabled={!isOpen}
      options={tags}
      placeholder={selectedTags.length === 0 ? t('tags:tagAutocomplete.emptyTag') : ''}
      size="xs"
      sx={{ minWidth: selectedTags.length === 0 ? 250 : 0 }}
      value={selectedTags}
      onChange={handleTagsChange}
    />
  );
};

export default SelectTags;
