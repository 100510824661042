import { Button, Collapse, IconButton, Stack, SvgIcon, Theme, collapseClasses, useMediaQuery } from '@mui/material';
import { FC, MutableRefObject, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Dametis } from 'assets/icons/Dametis';
import { Damientis } from 'assets/icons/Damientis';
import damientisTextImg from 'assets/images/dametis/logotext-navbar-damientis.svg';
import dametisTextImg from 'assets/images/dametis/logotext-navbar.svg';
import { useDispatch, useSelector } from 'store';
import { addToast } from 'store/slices/toast';
import { Route, RouteScope } from 'types/drawer';
import { ToastSeverity } from 'types/toast';
import { useLayoutStore } from 'zustand/stores/layout';

import { removeLocalStorageItem, setLocalStorageItem } from '../../../functions/localStorage';

import DrawerButton from './DrawerButton';
import { DametisButtonArrow, DrawerContentStack, DrawerContentToolbar } from './DrawerContent.styled';

interface Props {
  routes: Route[];
  drawerButtonsContainerRef: MutableRefObject<HTMLDivElement | null>;
}

const DrawerContent: FC<Props> = ({ routes, drawerButtonsContainerRef }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('navbar');
  const isWide = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));

  const setExtendedDrawer = useLayoutStore(state => state.setExtendedDrawer);
  const isExtendedDrawer = useLayoutStore(state => state.isExtendedDrawer);

  const acl = useSelector(state => state.auth.user?.acl);
  const selectedGroupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  const userId = useSelector(state => state.auth.user?.uuid);

  const displayDamientis = useMemo(() => process.env.NODE_ENV === 'development', []);

  const resetResponsiveDrawer = useCallback(() => {
    removeLocalStorageItem('isExtendedDrawer', { userId });
    setExtendedDrawer(isWide);
  }, [isWide, setExtendedDrawer, userId]);

  const toggleDrawer = useCallback(() => {
    setLocalStorageItem('isExtendedDrawer', !isExtendedDrawer, { userId });
    requestAnimationFrame(() => {
      setExtendedDrawer(!isExtendedDrawer);
    });
    dispatch(
      addToast({
        message: t('toast.responsiveDrawerDisabled'),
        severity: ToastSeverity.INFO,
        action: (
          <Button color="inherit" size="small" onClick={resetResponsiveDrawer}>
            {t('toast.resetResponsiveDrawer')}
          </Button>
        ),
      }),
    );
  }, [dispatch, isExtendedDrawer, resetResponsiveDrawer, setExtendedDrawer, t, userId]);

  const filteredRoutes = useMemo(
    () =>
      routes.filter(route => {
        if (selectedSite) {
          const isScoped = route.scopes.includes(RouteScope.SITE) || route.scopes.includes(RouteScope.ADMIN);
          const hasPermission =
            !route.permission || (selectedGroupId && acl?.HasPermission(route.permission, selectedGroupId, selectedSite));
          return isScoped && hasPermission;
        }
        if (!selectedGroupId) {
          return false;
        }
        const isScoped = route.scopes.includes(RouteScope.GROUP) || route.scopes.includes(RouteScope.ADMIN);
        const hasPermission = acl?.HasPermissionOnGroup('canAccessCorporate', selectedGroupId);
        return isScoped && hasPermission;
      }),
    [routes, acl, selectedGroupId, selectedSite],
  );

  return (
    <DrawerContentStack>
      <DrawerContentToolbar>
        <IconButton
          size="large"
          sx={{ mx: -1.5, position: 'relative', color: theme => `${theme.palette.white} !important` }}
          onClick={toggleDrawer}
        >
          <SvgIcon color="inherit" component={displayDamientis ? Damientis : Dametis} fontSize="large" />
          <DametisButtonArrow color="inherit" fontSize="small" isExtendedDrawer={isExtendedDrawer} />
        </IconButton>
        <Collapse in={isExtendedDrawer} orientation="horizontal" sx={{ height: 30, [`& .${collapseClasses.wrapperInner}`]: { pl: 1 } }}>
          <NavLink to="/">
            <img alt="dametis" height={30} src={displayDamientis ? damientisTextImg : dametisTextImg} />
          </NavLink>
        </Collapse>
      </DrawerContentToolbar>
      <Stack
        ref={drawerButtonsContainerRef}
        sx={theme => ({
          pt: theme.others.pagePaddingTop,
          pb: theme.others.pagePaddingBottom,
          flexGrow: 1,
          overflowY: 'auto',
          scrollbarWidth: 'none',
        })}
      >
        {filteredRoutes.map((route, index) => (
          <DrawerButton key={route.url} route={{ ...route, index }} />
        ))}
      </Stack>
    </DrawerContentStack>
  );
};

export default DrawerContent;
