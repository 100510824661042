export default {
  title: {
    smoothing: 'Smoothing',
  },
  input: {
    label: {
      operator: 'Operator',
    },
  },
};
