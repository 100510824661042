import { DateSystem, FormatCategory } from '@dametis/core';

import { FormatPickerMenu } from 'types/format';

export default {
  text: {
    newOption: 'Format personnalisé : "{{value}}"',
    newOptionIncorrect: 'Format incorrect : "{{value}}"',
    unknownFormat: 'Format inconnu',
    noFormatting: 'Sans format',
    inputLabel: 'Format',
    hideUnit: "Cacher l'unité",
    timezoneOffset: 'Adapter au fuseau horaire',
    dateSystem: 'Système de date',
    withUnit: 'avec unité',
    withoutUnit: 'sans unité',
    withTimezoneOffset: 'Avec décalage horaire',
    withoutTimezoneOffset: 'Sans décalage horaire',
    noElement: 'Aucun élément',
  },
  subheader: {
    [FormatCategory.NUMBER]: 'Nombres',
    [FormatCategory.DATE]: 'Dates',
    history: 'Historique',
  },
  dateSystem: {
    [DateSystem.EXCEL_SERIAL_TIME]: 'Excel',
    [DateSystem.TIMESTAMP_UNIX]: 'Unix',
  },
  button: {
    ok: 'ok',
    clearHistory: 'vider',
  },
  tooltip: {
    menu: {
      [FormatPickerMenu.HISTORY]: 'Historique',
      [FormatPickerMenu.LISTS]: 'Formats',
      [FormatPickerMenu.SETTINGS]: 'Réglages',
    },
    clear: 'Effacer',
  },
  helper: {
    description: "Le format est fait selon le standard ECMA-376, similaire à d'autres logiciels tableurs.",
    redirection: "Plus d'exemples ici.",
  },
};
