import { Box, Divider, InputLabel, Stack, Typography } from '@mui/material';
import { Dispatch, FC, MouseEventHandler, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderBy, ShortUserInfo, UUID } from '@dametis/core';

import EntityAccordionSummary from 'components/UI/EntityAccordion/EntityAccordionSummary';

import { SortedBy } from '../BlockList';

import OrderAndSortMenu from './OrderAndSortMenu';
import SelectOwners from './SelectOwners';
import SelectStandardBlocks from './SelectStandardBlocks';
import SelectTags from './SelectTags';

export interface BlockListToolbarProps {
  orderBy: OrderBy;
  setOrderBy: Dispatch<SetStateAction<OrderBy>>;
  sortedBy: SortedBy;
  setSortedBy: Dispatch<SetStateAction<SortedBy>>;
  selectedStandardBlockIds: UUID[];
  setSelectedStandardBlockIds: Dispatch<SetStateAction<UUID[]>>;
  users: ShortUserInfo[];
  selectedUsersUuid: UUID[];
  setSelectedUsersUuid: Dispatch<SetStateAction<UUID[]>>;
  selectedTagsUuid: UUID[];
  setSelectedTagsUuid: Dispatch<SetStateAction<UUID[]>>;
  isOpen: boolean;
}

const BlockListToolbar: FC<BlockListToolbarProps> = ({
  orderBy,
  setOrderBy,
  sortedBy,
  setSortedBy,
  selectedStandardBlockIds,
  setSelectedStandardBlockIds,
  users,
  selectedUsersUuid,
  setSelectedUsersUuid,
  selectedTagsUuid,
  setSelectedTagsUuid,
  isOpen,
}) => {
  const { t } = useTranslation('lego');

  const handleClickFilterContainer: MouseEventHandler<HTMLDivElement> = useCallback(event => {
    event.stopPropagation();
  }, []);

  return (
    <>
      <EntityAccordionSummary
        content={
          <Stack alignItems="center" direction="row" flexGrow={1} justifyContent="flex-start">
            <Stack alignItems="center" direction="row" justifyContent="flex-start" spacing={1} onClick={handleClickFilterContainer}>
              <SelectStandardBlocks
                isOpen={isOpen}
                selectedStandardBlockIds={selectedStandardBlockIds}
                setSelectedStandardBlockIds={setSelectedStandardBlockIds}
              />
              <SelectOwners
                isOpen={isOpen}
                selectedUsersUuid={selectedUsersUuid}
                setSelectedUsersUuid={setSelectedUsersUuid}
                users={users}
              />
              <SelectTags isOpen={isOpen} selectedTagsUuid={selectedTagsUuid} setSelectedTagsUuid={setSelectedTagsUuid} />
            </Stack>
            <Box marginLeft="auto" onClick={handleClickFilterContainer}>
              <OrderAndSortMenu isOpen={isOpen} orderBy={orderBy} setOrderBy={setOrderBy} setSortedBy={setSortedBy} sortedBy={sortedBy} />
            </Box>
          </Stack>
        }
        contentMenu={
          <>
            <InputLabel sx={{ mb: 2 }}>{t('label.filtersAndSort')}</InputLabel>
            <Stack spacing={1}>
              <SelectStandardBlocks
                isOpen={isOpen}
                selectedStandardBlockIds={selectedStandardBlockIds}
                setSelectedStandardBlockIds={setSelectedStandardBlockIds}
              />
              <SelectOwners
                isOpen={isOpen}
                selectedUsersUuid={selectedUsersUuid}
                setSelectedUsersUuid={setSelectedUsersUuid}
                users={users}
              />
              <SelectTags isOpen={isOpen} selectedTagsUuid={selectedTagsUuid} setSelectedTagsUuid={setSelectedTagsUuid} />
              <Divider />
              <OrderAndSortMenu isOpen={isOpen} orderBy={orderBy} setOrderBy={setOrderBy} setSortedBy={setSortedBy} sortedBy={sortedBy} />
            </Stack>
          </>
        }
        title={<Typography variant="h4">{t('title.blocks')}</Typography>}
      />
    </>
  );
};

export default BlockListToolbar;
