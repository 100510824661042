export default {
  text: {
    createdBy: 'Creado por {{name}}',
    updatedAt: 'Actualizado {{date}}',
    me: 'mí',
    notFound: 'Elemento no encontrado',
  },
  tooltip: {
    notFound: 'El elemento que busca no existe o no tiene acceso a él',
  },
};
