import { styled } from '@mui/material';

/**
  @private Use Hotkeys instead
 */
export const KBM = styled('span', { shouldForwardProp: propName => propName !== 'size' })<{ size?: 'small' | 'medium' }>(
  ({ theme, size }) => ({
    background: `linear-gradient(180deg, ${theme.palette.grey[100]} 0%, ${theme.palette.grey[50]} 75%)`,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: `inset 0 1px 2px 1px ${theme.palette.white}, 0 1px 0 0 ${theme.palette.grey[600]}`,
    fontFamily: "'Ubuntu Mono', monospace",
    fontWeight: 500,
    color: theme.palette.text.primary,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: size === 'small' ? '0 2px' : '0 0.25em',
    height: size === 'small' ? '20px' : '1.5em',
    minWidth: size === 'small' ? '20px' : '1.5em',
    fontSize: size === 'small' ? '14px' : '1em',
  }),
);
