import { Period } from '@dametis/core';

import { setState } from 'store/slices/period';

import { getGroupBy, getRawGroupBy } from '../../functions/tada/getGroupBy';
import { TypedThunk } from '../index';

export const updatePeriod =
  (period: Period): TypedThunk<void> =>
  (dispatch, getState) => {
    const { maxLoopTime } = getState().boxes;
    dispatch(
      setState({
        period,
        lowGroupBy: getGroupBy(period.from, period.to, null, maxLoopTime, 50),
        rawGroupBy: getRawGroupBy(period.from, period.to, null, maxLoopTime, 5000),
        groupBy: getGroupBy(period.from, period.to, null, maxLoopTime, 5000),
      }),
    );
  };
