import { isSameDay, isSameMonth, isSameYear } from 'date-fns';

import { localizedFormat } from './localizedDateFns';

export const localizedPeriod = (from: Date, to: Date): string => {
  const now = new Date();
  const isThisDay = isSameDay(from, now);
  const isThisYear = isSameYear(from, now);
  const day = localizedFormat(from, 'd');
  const month = localizedFormat(from, 'MMM');
  const year = localizedFormat(from, 'yyyy');
  if (isSameDay(from, to)) {
    return `${isThisDay ? '' : `${day} ${month}`}${isThisYear ? '' : ` ${year}`}${isThisYear && isThisDay ? '' : ', '}${localizedFormat(
      from,
      'p',
    )} - ${localizedFormat(to, 'p')}`;
  }
  if (isSameMonth(from, to)) {
    return `${localizedFormat(from, 'd')} - ${localizedFormat(to, 'd')} ${month}${isThisYear ? '' : ` ${year}`}`;
  }
  if (isSameYear(from, to)) {
    return `${localizedFormat(from, 'MMM')} - ${localizedFormat(to, 'MMM')} ${year}`;
  }
  return `${localizedFormat(from, 'yyyy')} - ${localizedFormat(to, 'yyyy')}`;
};
