import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  Slider,
  SliderProps,
  Stack,
  SxProps,
  Typography,
  sliderClasses,
} from '@mui/material';
import { addDays, addHours, addMinutes, addMonths, addSeconds, addWeeks, getUnixTime } from 'date-fns';
import { ChangeEventHandler, useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AUTO_GROUPBY,
  AnyGroupBy,
  GroupByUnion,
  GroupByUnits,
  IsCalculationVariable,
  IsTimeGroupBy,
  IsVarCalc,
  POINT_TO_POINT_GROUPBY,
  timeGroupBy,
  timeIntervalRegex,
} from '@dametis/core';

import addUnit from 'functions/addUnit';
import { createCalculationVariable } from 'functions/createCalculationVariable';
import { shortUnitToLongUnit, useLocalizedGroupBy } from 'localization/useLocalizedGroupBy';
import { useSelector } from 'store';
import { exhaustiveCheck } from 'types';

import BatchFilterInput, { BatchFilterInputProps } from '../BatchFilter/BatchFilterInput';

const allGroupByUnits = Object.keys(shortUnitToLongUnit) as GroupByUnits[];

const getTimeGroupBy = (groupBy: AnyGroupBy | undefined, defaultGroupBy: AnyGroupBy | undefined = '1h'): timeGroupBy | undefined =>
  IsTimeGroupBy(groupBy) ? groupBy : getTimeGroupBy(defaultGroupBy, '1h');

const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const SECONDS_IN_HOUR = MINUTES_IN_HOUR * SECONDS_IN_MINUTE;
const HOURS_IN_DAY = 24;
const SECONDS_IN_DAY = HOURS_IN_DAY * SECONDS_IN_HOUR;
const DAYS_IN_WEEK = 7;
const SECONDS_IN_WEEK = DAYS_IN_WEEK * SECONDS_IN_DAY;
const WEEKS_IN_MONTH = 4;

enum SelectValues {
  AUTO = 'auto',
  TIME = 'time',
  POINT_TO_POINT = 'pointToPoint',
  BATCH = 'batch',
}

interface SliderMark {
  value: number;
  label: string;
  groupBy: GroupByUnion<true, false, false, false, false>;
  hidden: boolean;
}

export interface GroupByInputProps<
  EnableAuto extends boolean = false,
  EnablePointToPoint extends boolean = false,
  DisableBatch extends boolean = false,
  EnableUndefined extends boolean = false,
> {
  groupBy: GroupByUnion<true, EnableAuto, EnablePointToPoint, DisableBatch extends true ? false : true, EnableUndefined>;
  onGroupByChange: (
    groupBy: GroupByUnion<true, EnableAuto, EnablePointToPoint, DisableBatch extends true ? false : true, EnableUndefined>,
  ) => void;
  defaultGroupBy?: GroupByUnion<true, EnableAuto, EnablePointToPoint, DisableBatch extends true ? false : true, EnableUndefined>;
  editing?: boolean;
  label?: string;
  disableSlider?: boolean;
  enableCheckbox?: EnableUndefined;
  enableAuto?: EnableAuto;
  enablePointToPoint?: EnablePointToPoint;
  disableBatch?: DisableBatch;
  timeUnits?: GroupByUnits[];
  sx?: SxProps;
}

const GroupByInput = <
  EnableAuto extends boolean = false,
  EnablePointToPoint extends boolean = false,
  DisableBatch extends boolean = false,
  EnableUndefined extends boolean = false,
>({
  groupBy,
  onGroupByChange,
  defaultGroupBy = '1h',
  editing = true,
  label = undefined,
  disableSlider = false,
  enableCheckbox = false as EnableUndefined,
  enableAuto = false as EnableAuto,
  enablePointToPoint = false as EnablePointToPoint,
  disableBatch = false as DisableBatch,
  timeUnits = allGroupByUnits,
  sx = undefined,
}: GroupByInputProps<EnableAuto, EnablePointToPoint, DisableBatch, EnableUndefined>) => {
  type GroupBy = GroupByUnion<true, EnableAuto, EnablePointToPoint, DisableBatch extends true ? false : true, EnableUndefined>;

  const { t } = useTranslation(['groupBy', 'global']);

  const corporate = useSelector(state => !state.auth.selectedSite);

  const [localGroupBy, setLocalGroupBy] = useState<GroupBy>(groupBy);

  const sliderMarks = useMemo<SliderMark[]>(
    () => [
      {
        value: 0.2,
        label: `5${t('global:unit.time', { count: 5, context: 'second_short' })}`,
        groupBy: '5s',
        hidden: !timeUnits.includes('s'),
      },
      {
        value: 1.5,
        label: `1${t('global:unit.time', { count: 1, context: 'minute_short' })}`,
        groupBy: '1m',
        hidden: !timeUnits.includes('m'),
      },
      {
        value: 3,
        label: `1${t('global:unit.time', { count: 1, context: 'hour_short' })}`,
        groupBy: '1h',
        hidden: !timeUnits.includes('h'),
      },
      {
        value: 5,
        label: `1${t('global:unit.time', { count: 1, context: 'day_short' })}`,
        groupBy: '1d',
        hidden: !timeUnits.includes('d'),
      },
      {
        value: 7,
        label: `1${t('global:unit.time', { count: 1, context: 'week_short' })}`,
        groupBy: '1w',
        hidden: !timeUnits.includes('w'),
      },
      {
        value: 10,
        label: `1${t('global:unit.time', { count: 1, context: 'month_short' })}`,
        groupBy: '1mo',
        hidden: !timeUnits.includes('mo'),
      },
    ],
    [t, timeUnits],
  );
  const displayedSliderMarks = useMemo<SliderMark[]>(() => sliderMarks.filter(mark => !mark.hidden), [sliderMarks]);

  const selectValue = useMemo<SelectValues>(() => {
    if (localGroupBy === null || localGroupBy === undefined) {
      return SelectValues.TIME;
    }
    if (IsVarCalc(localGroupBy)) {
      return SelectValues.BATCH;
    }
    if (localGroupBy === AUTO_GROUPBY) {
      return SelectValues.AUTO;
    }
    if (localGroupBy === POINT_TO_POINT_GROUPBY) {
      return SelectValues.POINT_TO_POINT;
    }
    if (IsTimeGroupBy(localGroupBy)) {
      return SelectValues.TIME;
    }
    exhaustiveCheck(localGroupBy);
    return SelectValues.TIME;
  }, [localGroupBy]);

  const value = useMemo<number>(() => {
    if (!IsTimeGroupBy(localGroupBy)) {
      return 1;
    }
    return parseInt(localGroupBy, 10);
  }, [localGroupBy]);
  const unit = useMemo<GroupByUnits>(() => {
    if (!IsTimeGroupBy(localGroupBy)) {
      return 'h';
    }
    return localGroupBy.match(timeIntervalRegex)?.[2] as GroupByUnits;
  }, [localGroupBy]);
  const sliderPosition = useMemo<number>(() => {
    const date0 = new Date(0);
    const duration = getUnixTime(addUnit(date0, value, unit));
    const fiveSeconds = getUnixTime(addSeconds(date0, 5));
    const oneMinute = getUnixTime(addMinutes(date0, 1));
    const oneHour = getUnixTime(addHours(date0, 1));
    const oneDay = getUnixTime(addDays(date0, 1));
    const oneWeek = getUnixTime(addWeeks(date0, 1));
    const oneMonth = getUnixTime(addMonths(date0, 1));
    let newSliderPosition = 0;
    if (duration <= fiveSeconds) {
      newSliderPosition = ((sliderMarks[0].value - 0) * (duration - 0)) / (5 - 0) + 0;
    } else if (duration > fiveSeconds && duration <= oneMinute) {
      newSliderPosition = ((sliderMarks[1].value - sliderMarks[0].value) * (duration - 5)) / (SECONDS_IN_MINUTE - 5) + sliderMarks[0].value;
    } else if (duration > oneMinute && duration <= oneHour) {
      newSliderPosition =
        ((sliderMarks[2].value - sliderMarks[1].value) * (duration / SECONDS_IN_MINUTE - 1)) / (MINUTES_IN_HOUR - 1) + sliderMarks[1].value;
    } else if (duration > oneHour && duration <= oneDay) {
      newSliderPosition =
        ((sliderMarks[3].value - sliderMarks[2].value) * (duration / SECONDS_IN_HOUR - 1)) / (HOURS_IN_DAY - 1) + sliderMarks[2].value;
    } else if (duration > oneDay && duration <= oneWeek) {
      newSliderPosition =
        ((sliderMarks[4].value - sliderMarks[3].value) * (duration / SECONDS_IN_DAY - 1)) / (DAYS_IN_WEEK - 1) + sliderMarks[3].value;
    } else if (duration > oneWeek && duration <= oneMonth) {
      newSliderPosition =
        ((sliderMarks[5].value - sliderMarks[4].value) * (duration / SECONDS_IN_WEEK - 1)) / (WEEKS_IN_MONTH - 1) + sliderMarks[4].value;
    } else {
      newSliderPosition = sliderMarks[5].value;
    }
    return newSliderPosition;
  }, [sliderMarks, unit, value]);

  const [valueInput, setValueInput] = useState<string>(value.toString());

  const lastTimeGroupBy = useRef<timeGroupBy | undefined>(getTimeGroupBy(groupBy, defaultGroupBy));
  const lastDefinedGroupBy = useRef<GroupBy>(groupBy);

  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const groupByText = useLocalizedGroupBy(localGroupBy, false, true);

  const checkboxId = useId();

  useEffect(() => {
    setLocalGroupBy(groupBy);
  }, [groupBy]);

  useEffect(() => {
    setValueInput(value.toString());
  }, [value]);

  useEffect(() => {
    if (!IsTimeGroupBy(localGroupBy)) return undefined;
    return () => {
      lastTimeGroupBy.current = getTimeGroupBy(localGroupBy, defaultGroupBy);
    };
  }, [defaultGroupBy, localGroupBy]);

  useEffect(() => {
    if (localGroupBy === null || localGroupBy === undefined) {
      return undefined;
    }
    return () => {
      lastDefinedGroupBy.current = localGroupBy;
    };
  }, [defaultGroupBy, localGroupBy]);

  const toggleGroupBy = useCallback<NonNullable<CheckboxProps['onChange']>>(
    (event, checked) => {
      const newGroupBy = checked ? (lastDefinedGroupBy.current ?? defaultGroupBy) : undefined;
      setLocalGroupBy(newGroupBy as GroupBy);
      onGroupByChange(newGroupBy as GroupBy);
    },
    [defaultGroupBy, onGroupByChange],
  );

  const handleGroupByAsNumberChange = useCallback<NonNullable<SliderProps['onChange']>>(
    (e, newValue) => {
      const newGroupBy = sliderMarks.find(gB => gB.value === newValue)?.groupBy;
      if (newGroupBy === undefined) {
        return;
      }
      setLocalGroupBy(newGroupBy);
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        onGroupByChange(newGroupBy);
      }, 250);
    },
    [sliderMarks, onGroupByChange],
  );

  const handleValueChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    e => {
      e.persist();
      setValueInput(e.target.value);
      if (!Number.isFinite(e.target.valueAsNumber)) {
        return;
      }
      const newGroupBy: timeGroupBy = `${e.target.valueAsNumber}${unit}`;
      setLocalGroupBy(newGroupBy);
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        onGroupByChange(newGroupBy);
      }, 350);
    },
    [onGroupByChange, unit],
  );

  const handleUnitChange = useCallback<NonNullable<SelectProps<GroupByUnits>['onChange']>>(
    event => {
      const newGroupBy: timeGroupBy = `${value}${event.target.value as GroupByUnits}`;
      setLocalGroupBy(newGroupBy);
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        onGroupByChange(newGroupBy);
      }, 100);
    },
    [onGroupByChange, value],
  );

  const handleSelectChange = useCallback<NonNullable<SelectProps<SelectValues>['onChange']>>(
    event => {
      if (event.target.value === SelectValues.AUTO) {
        setLocalGroupBy(AUTO_GROUPBY as GroupBy);
        onGroupByChange(AUTO_GROUPBY as GroupBy);
      }
      if (event.target.value === SelectValues.POINT_TO_POINT) {
        setLocalGroupBy(POINT_TO_POINT_GROUPBY as GroupBy);
        onGroupByChange(POINT_TO_POINT_GROUPBY as GroupBy);
      }
      if (event.target.value === SelectValues.BATCH) {
        const calc = createCalculationVariable();
        setLocalGroupBy(calc as GroupBy);
        onGroupByChange(calc as GroupBy);
      }
      if (event.target.value === SelectValues.TIME) {
        const newGroupBy = getTimeGroupBy(lastTimeGroupBy.current, defaultGroupBy);
        setLocalGroupBy(newGroupBy as GroupBy);
        onGroupByChange(newGroupBy as GroupBy);
      }
    },
    [defaultGroupBy, onGroupByChange],
  );

  const handleBatchChange = useCallback<BatchFilterInputProps['setBatch']>(
    batch => {
      setLocalGroupBy(batch as GroupBy);
      onGroupByChange(batch as GroupBy);
    },
    [onGroupByChange],
  );

  return (
    <Box sx={sx}>
      <Stack alignItems="center" direction="row" flexWrap="nowrap" height={theme => theme.spacing(2.5)} justifyContent="space-between">
        {enableCheckbox && editing ? (
          <FormControlLabel
            control={<Checkbox checked={Boolean(localGroupBy)} id={checkboxId} onChange={toggleGroupBy} />}
            label={
              <FormLabel htmlFor={checkboxId} sx={{ whiteSpace: 'nowrap' }}>
                {label ?? t('input.label.groupBy')}
              </FormLabel>
            }
          />
        ) : (
          <FormLabel sx={{ whiteSpace: 'nowrap' }}>{label ?? t('input.label.groupBy')}</FormLabel>
        )}
        {editing && localGroupBy && !(!enableAuto && !enablePointToPoint && disableBatch) && (
          <Select<SelectValues> disableUnderline value={selectValue} variant="standard" onChange={handleSelectChange}>
            {enableAuto && <MenuItem value={SelectValues.AUTO}>{t('input.value.auto')}</MenuItem>}
            <MenuItem value={SelectValues.TIME}>{t('input.value.time')}</MenuItem>
            {enablePointToPoint && <MenuItem value={SelectValues.POINT_TO_POINT}>{t('input.value.pointToPoint')}</MenuItem>}
            {!corporate && !disableBatch && <MenuItem value={SelectValues.BATCH}>{t('input.value.batch')}</MenuItem>}
          </Select>
        )}
      </Stack>
      {!editing || !localGroupBy ? (
        <Typography>{groupByText}</Typography>
      ) : (
        <Box mt={0.5}>
          {selectValue === SelectValues.TIME && (
            <>
              {!disableSlider && (
                <Slider
                  color="secondary"
                  marks={displayedSliderMarks}
                  max={10}
                  min={0}
                  step={null}
                  sx={{
                    display: 'block',
                    [`& .${sliderClasses.markLabel}[data-index="0"]`]: {
                      transform: 'translateX(-30%)',
                    },
                    [`& .${sliderClasses.markLabel}[data-index="${sliderMarks.length - 1}"]`]: {
                      transform: 'translateX(-95%)',
                    },
                  }}
                  value={sliderPosition}
                  onChange={handleGroupByAsNumberChange}
                />
              )}
              <Stack direction="row" spacing={0.5} sx={{ pt: 0.25 }}>
                <OutlinedInput
                  inputProps={{ min: 1, max: 60 }}
                  margin="dense"
                  sx={{ width: 0.33 }}
                  type="number"
                  value={valueInput}
                  onChange={handleValueChange}
                />
                <Select<GroupByUnits> margin="dense" sx={{ flexGrow: 1 }} value={unit} variant="outlined" onChange={handleUnitChange}>
                  {timeUnits.map(u => (
                    <MenuItem key={u} value={u}>
                      {t('global:unit.time', { count: value, context: shortUnitToLongUnit[u] })}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </>
          )}
          {selectValue === SelectValues.BATCH && IsCalculationVariable(localGroupBy) && (
            <BatchFilterInput noOperator batch={localGroupBy} label="" setBatch={handleBatchChange} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default GroupByInput;
