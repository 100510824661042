import { alpha, useTheme } from '@mui/material';
import type { Series, TooltipFormatterContextObject } from 'highcharts';
import { FC } from 'react';

import SeriesBullet from '../UI/SeriesBullet';
import SeriesContainer from '../UI/SeriesContainer';
import SeriesLabel from '../UI/SeriesLabel';
import SeriesValue from '../UI/SeriesValue';

type Props = Omit<TooltipFormatterContextObject, 'x'> & {
  seriesItem: Series;
};

const RangeSeriesTooltip: FC<Props> = ({ seriesItem, series, point }) => {
  const theme = useTheme();

  return (
    <SeriesContainer color={seriesItem.userOptions.id === series.userOptions.id ? alpha(seriesItem.color.toString(), 0.3) : undefined}>
      <SeriesBullet color={seriesItem.color.toString()} />
      <SeriesLabel style={{ marginRight: theme.spacing(3) }}>{seriesItem.userOptions.name}</SeriesLabel>
      <SeriesValue point={point} series={seriesItem} />
    </SeriesContainer>
  );
};

export default RangeSeriesTooltip;
