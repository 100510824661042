import { makeStyles } from '@mui/styles';

import { theme } from 'theme';

const useChartCommentStyles = makeStyles(() => ({
  container__popover: {
    position: 'relative',
    maxWidth: '40vw',
  },
  container__comment: {
    width: 'max-content',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(11),
    height: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
  },
  'container__comment--hidden': {
    display: 'none',
  },
}));

export default useChartCommentStyles;
