import { Tabs, styled, tabsClasses } from '@mui/material';

const IconTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  flexShrink: 0,
  // paddingLeft: theme.spacing(1),
  // paddingRight: theme.spacing(1),
  [`& .${tabsClasses.flexContainer}`]: {
    // gap: theme.spacing(1.5),
  },
}));

export default IconTabs;
