import { Autocomplete, Box, InputLabel, Link, Stack, TextField, alertClasses } from '@mui/material';
import { differenceInMilliseconds } from 'date-fns';
import { FC, HTMLAttributes, SyntheticEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import timezonesGmt from 'assets/data/timezones-gmt.json';
import { getAvailableFormats } from 'components/Data/FileImport/UI/DateTimeFormatPicker/availableFormats';
import Helper from 'components/UI/Helper/Helper';
import { TimeZone } from 'types/auth';
import { UserHelperName } from 'types/userHelpers';

import DateTimeFormatOption from './DateTimeFormatOption';
import { useMappingSettingsContext } from './MappingSettingsContext';

const DateTimeSourceForm: FC = () => {
  const { t } = useTranslation('fileImport');

  const { dateTimeFormat, setDateTimeFormat, dateTimeZone, setDateTimeZone } = useMappingSettingsContext();

  const availableTimeZones: TimeZone[] = useMemo(() => Object.keys(timezonesGmt) as TimeZone[], []);
  const availableFormats = useMemo(() => getAvailableFormats(), []);

  const sortedAvailableTimeZones = useMemo(
    () =>
      [...availableTimeZones].sort((availableTimeZoneA, availableTimeZoneB) => {
        const offsetA = timezonesGmt[availableTimeZoneA].substring(1, 10);
        const offsetB = timezonesGmt[availableTimeZoneB].substring(1, 10);
        const dateA = new Date(`January 22, 1996 21:30:00 ${offsetA}`);
        const dateB = new Date(`January 22, 1996 21:30:00 ${offsetB}`);
        return differenceInMilliseconds(dateB, dateA);
      }),
    [availableTimeZones],
  );

  const handleChangeDateTimeFormat = useCallback(
    (_event: SyntheticEvent, newDateTimeFormat: string | null) => {
      setDateTimeFormat(!newDateTimeFormat ? '' : newDateTimeFormat);
    },
    [setDateTimeFormat],
  );

  const handleChangeDateTimeFormatInput = useCallback(
    (_event: SyntheticEvent, value: string) => {
      setDateTimeFormat(value);
    },
    [setDateTimeFormat],
  );

  const handleChangeDateTimeZone = useCallback(
    (_event: SyntheticEvent, value: TimeZone | null) => {
      setDateTimeZone(value);
    },
    [setDateTimeZone],
  );

  const getRenderOptionComponent = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: string) => <DateTimeFormatOption {...props} option={option} />,
    [],
  );

  return (
    <Stack gap={1}>
      {/* <DateTimeFormatPicker fullWidth value={dateTimeFormat} onChange={handleChangeDateTimeFormat} /> */}
      <Autocomplete
        freeSolo
        inputValue={dateTimeFormat ?? undefined}
        options={availableFormats.map(option => option.value)}
        renderInput={params => (
          <Stack gap={0.5}>
            <InputLabel>{t('label.dateTimeFormat')}</InputLabel>
            <Helper name={UserHelperName.FILE_IMPORT_DATE_TIME_FORMAT} sx={{ [`& .${alertClasses.action}`]: { pl: 0 } }}>
              <Box component="span">{t('helper.dateTimeFormatDescription')}</Box>
              <Link href="https://moment.github.io/luxon/#/formatting?id=table-of-tokens" rel="noopener noreferrer" target="_blank">
                {t('helper.dateTimeFormatRedirection')}
              </Link>
            </Helper>
            <TextField {...params} />
          </Stack>
        )}
        renderOption={getRenderOptionComponent}
        size="small"
        value={dateTimeFormat}
        onChange={handleChangeDateTimeFormat}
        onInputChange={handleChangeDateTimeFormatInput}
      />
      <Autocomplete
        fullWidth
        getOptionLabel={option => timezonesGmt[option]}
        multiple={false}
        noOptionsText={t('text.noDateTimeZoneFound')}
        options={sortedAvailableTimeZones}
        renderInput={params => <TextField {...params} label={t('label.dateTimeZone')} />}
        size="small"
        value={dateTimeZone ?? undefined}
        onChange={handleChangeDateTimeZone}
      />
    </Stack>
  );
};

export default DateTimeSourceForm;
