import {
  AutocompleteProps,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  TextFieldProps,
  createFilterOptions,
} from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Normalize, TagInfo } from '@dametis/core';

import { useCreateTagMutation, useTags } from 'store/api/tags';
import { useSelector } from 'store/index';

import AutocompleteFilter from '../AutocompleteFilter/AutocompleteFilter';

const tagsEmptyArray: TagInfo[] = [];

interface Props
  extends Omit<
    AutocompleteProps<TagInfo, true, false, false>,
    'onChange' | 'options' | 'renderTags' | 'getOptionLabel' | 'renderInput' | 'filterOptions' | 'open' | 'multiple'
  > {
  /**
   * Callback fired when the value is changed.
   */
  onChange: (newValue: TagInfo[]) => void;

  /**
   * Array of availables tags.
   * "default" value fill the array with all site's tags
   * @default 'default'
   */
  options?: 'default' | TagInfo[];

  /**
   * Allow tag creation.
   * @default true
   */
  canAddTag?: boolean;

  /**
   * The label content.
   */
  label?: TextFieldProps['label'];

  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  placeholder?: TextFieldProps['placeholder'];

  /**
   * maximum number of tags
   * @default 'none'
   */
  max?: 'none' | number;
}

const TagAutocompleteFilter: FC<Props> = ({
  value,
  onChange,
  options = 'default',
  canAddTag = true,
  label = undefined,
  placeholder = '',
  max = 'none',
  ...props
}) => {
  const { t } = useTranslation('tags');

  const { data: siteTags = tagsEmptyArray } = useTags();
  const [createTag] = useCreateTagMutation();

  const corporate = useSelector(state => !state.auth.selectedSite);

  const [maxError, setMaxError] = useState<boolean>(false);

  const availableTags = useMemo(() => (options === 'default' ? siteTags : options), [options, siteTags]);

  const filterOptions = useMemo(() => createFilterOptions<TagInfo>({ trim: true }), []);

  const tagNameExists = useCallback(
    (tag: TagInfo) => (value ?? []).some(existingTag => Normalize(existingTag.name) === Normalize(tag.name)),
    [value],
  );

  const isTagSaved = useCallback((tag: TagInfo) => tag.uuid !== '', []);

  const handleNewTag = useCallback(
    async (tags: TagInfo[], newTag: TagInfo) => {
      if (max === 'none' || tags.length < max) {
        if (!isTagSaved(newTag)) {
          const newTagSaved = await createTag({ name: newTag.name }).unwrap();
          onChange([...tags, newTagSaved]);
        } else {
          onChange([...tags, newTag]);
        }
      } else {
        setMaxError(true);
      }
    },
    [isTagSaved, max, onChange, createTag],
  );

  const handleChange = useCallback<NonNullable<AutocompleteProps<TagInfo, true, false, false>['onChange']>>(
    async (e, v, reason, details) => {
      if (reason === 'selectOption' && details !== undefined) {
        await handleNewTag(value ?? [], details.option);
      }
      if (reason === 'removeOption' && details !== undefined && details.option.uuid !== '') {
        onChange((value ?? []).filter(tag => tag.uuid !== details.option.uuid));
      }
      if (reason === 'clear') {
        onChange([]);
      }
    },
    [value, handleNewTag, onChange],
  );

  const getFilteredOptions = useCallback<NonNullable<AutocompleteProps<TagInfo, true, false, false>['filterOptions']>>(
    (opts, params) => {
      const filtered = filterOptions(opts, params).filter(tag => (value ?? []).every(v => v.uuid !== tag.uuid));
      const { inputValue } = params;
      if (canAddTag && Normalize(inputValue) !== '' && opts.every(option => Normalize(inputValue) !== Normalize(option.name))) {
        filtered.push({
          name: inputValue,
          normalizedName: Normalize(inputValue),
          uuid: '',
          parentId: '',
          canEdit: true,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }
      return filtered;
    },
    [canAddTag, filterOptions, value],
  );

  const getOptionLabel = useCallback<NonNullable<AutocompleteProps<TagInfo, true, false, false>['getOptionLabel']>>(
    option => (!tagNameExists(option) && isTagSaved(option) ? option.name : `${option.name} (${t('tagAutocomplete.newTag')})`),
    [tagNameExists, isTagSaved, t],
  );

  const renderTags = useCallback<NonNullable<AutocompleteProps<TagInfo, true, false, false>['renderTags']>>(
    (selectedTags, getTagProps) =>
      selectedTags.map((option, index) => {
        const { key, ...tagProps } = getTagProps({ index });
        return (
          <Chip
            key={option?.uuid ?? `${t('tagAutocomplete.newTag')}${index}`}
            label={option?.name ?? t('tagAutocomplete.unknownTag')}
            size="small"
            sx={{ m: theme => `${theme.spacing(0.25)}!important` }}
            {...tagProps}
          />
        );
      }),
    [t],
  );

  const renderInput = useCallback<AutocompleteProps<TagInfo, true, false, false>['renderInput']>(
    params => <TextField {...params} fullWidth label={label} placeholder={placeholder} />,
    [label, placeholder],
  );

  if (corporate) return null;
  return (
    <>
      <AutocompleteFilter<TagInfo, true, false, false>
        clearOnBlur
        filterSelectedOptions
        handleHomeEndKeys
        multiple
        selectOnFocus
        filterOptions={getFilteredOptions}
        getOptionLabel={getOptionLabel}
        noOptionsText={!canAddTag && t('text.noOptions')}
        options={availableTags}
        renderInput={renderInput}
        renderTags={renderTags}
        value={value}
        onChange={handleChange}
        {...props}
      />
      <Dialog open={maxError}>
        <DialogContent>{`${max} ${t('error.maxTagAllowed')}`}</DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" onClick={() => setMaxError(false)}>
            {t('button.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TagAutocompleteFilter;
