import i18next from 'i18next';

import { ErrorName, TadaApiResponse } from '@dametis/core';

export interface ParsedError {
  errorName: ErrorName;
  message: string;
}

export const convertApiErrors = (errorName: ErrorName): ParsedError => ({
  errorName,
  message: i18next.t(`errors:alerts.${errorName}`),
});

export const parseTadaErrors = (tadaErrors: TadaApiResponse[]) => {
  const parsedErrors = tadaErrors.reduce<ParsedError[]>((accParsedErrors, apiError) => {
    if (apiError.errors && !accParsedErrors.some(error => error.errorName === apiError.errors?.[0].errorName))
      accParsedErrors.push(convertApiErrors(apiError.errors[0].errorName));
    return accParsedErrors;
  }, []);
  return parsedErrors;
};
