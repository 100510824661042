import { Avatar, AvatarProps, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import { setColorLightness } from 'functions/color';

import ModelVariableSpan from './ModelVariableSpan';
import { useRainbowColor } from './config/rainbow';

interface Props extends AvatarProps {
  kind: 'X' | 'Y';
  index?: number;
}

const ModelVariableAvatar: FC<Props> = ({ kind, index = undefined, sx, ...props }) => {
  const color = useRainbowColor(index);

  const bgcolor = useMemo(() => setColorLightness(color, 95), [color]);

  return (
    <Avatar sx={[{ bgcolor }, ...(Array.isArray(sx) ? sx : [sx])]} variant="rounded" {...props}>
      <Typography fontSize="1.25em" lineHeight={1} variant="h2">
        <ModelVariableSpan index={index} kind={kind} />
      </Typography>
    </Avatar>
  );
};

export default ModelVariableAvatar;
