import { TaskAltOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { Alert, Box, Collapse, InputLabel, Paper, Stack, SvgIcon, Tooltip } from '@mui/material';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CalculationVariable, IsDataVariable, ReferencedBy, ShortcutCategory, SourceColumn, VariableKind } from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import SourceValuePicker, { SourceValue } from 'components/Data/FileImport/UI/SourceValuePicker/SourceValuePicker';
import { areSameSourceColumns } from 'components/Data/FileImport/helpers/areSameSourceColumns';
import { getColumnSourceValue } from 'components/Data/FileImport/helpers/getColumnSourceValue';
import { getSourceColumnName } from 'components/Data/FileImport/helpers/getSourceColumnName';
import VncInput from 'components/VNC/VncInput';
import { VncProps } from 'components/VNC/types';
import { createCalculationVariable } from 'functions/createCalculationVariable';

import DateTimeSourceForm from './DateTimeSourceForm';
import { FormFixedVariable, useMappingSettingsContext } from './MappingSettingsContext';

export interface SourceColumnFormProps {
  column: SourceColumn;
}

export const vncProps: Omit<VncProps, 'value' | 'onChange'> = {
  sourceCategory: ShortcutCategory.VARIABLE,
  disableLego: true,
  disableMaths: true,
  defaultFilters: { kinds: { [VariableKind.REAL]: true, [VariableKind.CONSTANT]: true, [VariableKind.MANUAL]: true } },
  disableFilters: ['kinds'],
};

const SourceColumnForm: FC<SourceColumnFormProps> = ({ column }) => {
  const { t } = useTranslation('fileImport');

  const [isVNCOpen, setIsVNCOpen] = useState<boolean>(false);

  const {
    fixedVariables,
    variablesByReferences,
    dateTimeSource,
    referenceSource,
    valueSource,
    skippedColumns,
    setFixedVariables,
    referencedBy,
    useHeader,
  } = useMappingSettingsContext();

  const foundFixedVariableIndex = useMemo(
    () => fixedVariables.findIndex(fixedVariable => areSameSourceColumns(fixedVariable.source, column)),
    [column, fixedVariables],
  );

  const foundFixedVariable = useMemo(
    () => (foundFixedVariableIndex > -1 ? fixedVariables[foundFixedVariableIndex] : undefined),
    [fixedVariables, foundFixedVariableIndex],
  );

  const calculation = useMemo(
    () =>
      foundFixedVariable?.variableId
        ? createCalculationVariable({ exp: 'var_1', vars: { var_1: { variableUuid: foundFixedVariable?.variableId } } })
        : createCalculationVariable(),
    [foundFixedVariable?.variableId],
  );

  const variable = useMemo(() => variablesByReferences[column.value], [variablesByReferences, column.value]);

  const sourceValue = useMemo(
    () => getColumnSourceValue(column, dateTimeSource, referenceSource, valueSource, skippedColumns, fixedVariables),
    [column, dateTimeSource, referenceSource, valueSource, skippedColumns, fixedVariables],
  );

  const withPaper = useMemo(() => sourceValue === SourceValue.DATE_TIME || sourceValue === SourceValue.FIXED_VARIABLE, [sourceValue]);

  const handleChangeFixedVariable = useCallback(
    (newFixedVariableCalculation: CalculationVariable) => {
      const singleVariable = getSingleVariableCalculation(newFixedVariableCalculation);
      if ((singleVariable && IsDataVariable(singleVariable)) || newFixedVariableCalculation.exp === '') {
        const variableId = singleVariable && IsDataVariable(singleVariable) ? singleVariable.variableUuid : null;
        setFixedVariables(state =>
          state.map<FormFixedVariable>((fixedVariable, index) =>
            foundFixedVariableIndex === index ? { ...fixedVariable, variableId } : fixedVariable,
          ),
        );
      }
    },
    [setFixedVariables, foundFixedVariableIndex],
  );

  const handleChangeSourceValue = useCallback((newSourceValue: SourceValue) => {
    if (newSourceValue === SourceValue.FIXED_VARIABLE) {
      setIsVNCOpen(true);
    }
  }, []);

  return (
    <Paper
      sx={{
        transition: theme => theme.transitions.create(['padding', 'background']),
        p: withPaper ? 2 : 0,
        background: theme => `${theme.palette.background.paper}${withPaper ? 'ff' : '00'}`,
      }}
    >
      <InputLabel
        sx={{
          mb: withPaper ? 1 : 0.5,
          color: theme => (withPaper ? theme.palette.text.primary : undefined),
          transition: theme => theme.transitions.create(['margin-bottom', 'color']),
        }}
      >
        {getSourceColumnName(column, t)}
      </InputLabel>
      <Stack alignItems="center" direction="row" gap={1}>
        <SourceValuePicker column={column} onChange={handleChangeSourceValue} />
        {useHeader && sourceValue === SourceValue.DEFAULT && referencedBy === ReferencedBy.COLUMNS && (
          <Tooltip
            placement="top"
            slotProps={{ tooltip: { sx: { background: 'none', p: 0 } } }}
            title={
              <Alert severity={variable ? 'success' : 'warning'}>
                {variable
                  ? t('tooltip.correspondingVariable', { variableName: variable.name })
                  : t('tooltip.notCorrespondingVariable', { reference: column.value })}
              </Alert>
            }
          >
            <SvgIcon color={variable ? 'secondary' : 'warning'} component={variable ? TaskAltOutlined : WarningAmberOutlined} />
          </Tooltip>
        )}
      </Stack>
      <Collapse in={sourceValue === SourceValue.DATE_TIME}>
        <Box mt={1} width={1}>
          <DateTimeSourceForm />
        </Box>
      </Collapse>
      <Collapse in={sourceValue === SourceValue.FIXED_VARIABLE}>
        <Box mt={1} width={1}>
          <VncInput label={t('label.variable')} open={isVNCOpen} value={calculation} onChange={handleChangeFixedVariable} {...vncProps} />
        </Box>
      </Collapse>
    </Paper>
  );
};

export default memo(SourceColumnForm);
