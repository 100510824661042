export default {
  strength: {
    name: 'Password strength',
    empty: '',
    weak: 'Weak',
    medium: 'Medium',
    strong: 'Strong',
  },
  strengthReason: {
    userInputs: 'The password seems to contain information about your identity',
    lowComplexity: 'The password is not strong enough. Use a mix of letters, numbers and symbols.',
    empty: 'The password is empty',
  },
};
