import { v4 as uuidv4 } from 'uuid';

import { DaChart, DaChartOptions } from 'classes/DaCharts/DaChart';
import { TypedThunk } from 'store';
import {
  chartSetDachart,
  // chartSetTable
} from 'store/slices/playground';

import { IChart, WithDaChart } from '../types';

export const createChart = ({ uuid = uuidv4(), preview = '', daChart = null }: Partial<WithDaChart<IChart>> = {}): WithDaChart<IChart> => ({
  uuid,
  preview,
  previewPath: '',
  daChart,
});

export const exportChart = (chart: IChart | WithDaChart<IChart>): IChart | WithDaChart<IChart> =>
  'daChart' in chart ? { ...chart, daChart: null } : chart;

// ACTIONS

export const setDaChart =
  (chart: WithDaChart<IChart>, container: HTMLDivElement, options: DaChartOptions): TypedThunk<DaChart> =>
  dispatch => {
    const daChart = new DaChart(container, options);
    dispatch(chartSetDachart({ chart, daChart }));
    return daChart;
  };
