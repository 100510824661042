import {
  SharedEntityInfo,
  ShortAlarmInfo,
  ShortPlaygroundInfo,
  ShortProjectInfo,
  ShortReportInfo,
  ShortSynopticInfo,
  ShortTagInfo,
} from '@dametis/core';

export interface Entity extends SharedEntityInfo {
  tags?: ShortTagInfo[];
}

export enum EntityType {
  ALARM = 'alarm',
  PROJECT = 'project',
  PLAYGROUND = 'playground',
  REPORT = 'report',
  SYNOPTIC = 'synoptic',
}

export const entityTypesHiddenInCorpo = [EntityType.ALARM, EntityType.PROJECT, EntityType.SYNOPTIC];

export type EntityProps =
  | { entity: ShortAlarmInfo; type: EntityType.ALARM }
  | { entity: ShortProjectInfo; type: EntityType.PROJECT }
  | { entity: ShortPlaygroundInfo; type: EntityType.PLAYGROUND }
  | { entity: ShortReportInfo; type: EntityType.REPORT }
  | { entity: ShortSynopticInfo; type: EntityType.SYNOPTIC };

export const entityGridSpacing = 2;
export const entityTilePreviewHeight = '127px';
export const entityTileDescriptionHeight = '53px';
export const entityTileHeight = `calc(${entityTilePreviewHeight} + ${entityTileDescriptionHeight})`;
export const entityTileMinWidth = '200px';
