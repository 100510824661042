import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';

import { ShortTagInfo, SynopticExtendedInfo } from '@dametis/core';
import { dia } from '@dametis/rappid';

import { RappidService } from '../../components/Synoptic/services/rappid.service';

export type MenuElement = { cellView: dia.CellView | null };

export type GaugeElement = {
  id: string;
};

export interface SynopticState {
  rappid: RappidService | null;
  selection: dia.Cell[];
  editingMode: boolean;
  newElemMode: boolean;
  menuEl: MenuElement;
  synoptic: Partial<SynopticExtendedInfo>;
  stencilShapesLoaded: boolean;
  arePortsHidden: boolean;
  gauges: GaugeElement[];
}

const initialState: SynopticState = {
  rappid: null,
  selection: [],
  editingMode: false,
  newElemMode: false,
  menuEl: { cellView: null },
  synoptic: {},
  stencilShapesLoaded: false,
  arePortsHidden: false,
  gauges: [],
};

export const synopticSlice: Slice<SynopticState> = createSlice({
  name: 'synoptic',
  initialState,
  reducers: {
    setRappid: (state, action: PayloadAction<RappidService | null>) => {
      Object.assign(state, { rappid: action.payload });
    },
    setSynoptic: (state, action: PayloadAction<Partial<SynopticExtendedInfo>>) => {
      state.synoptic = action.payload;
    },
    setSynopticName: (state, action: PayloadAction<string>) => {
      state.synoptic.name = action.payload;
    },
    setSynopticTags: (state, action: PayloadAction<ShortTagInfo[]>) => {
      state.synoptic.tags = action.payload;
    },
    setEditingMode: (state, action: PayloadAction<boolean>) => {
      state.editingMode = action.payload;
    },
    setNewElemMode: (state, action: PayloadAction<boolean>) => {
      state.newElemMode = action.payload;
    },
    setMenuElement: (state, action: PayloadAction<MenuElement>) => {
      Object.assign(state, { menuEl: action.payload });
    },
    setSynopticSelection: (state, action: PayloadAction<dia.Cell[]>) => {
      state.selection = action.payload;
    },
    setStencilShapesLoaded: (state, action: PayloadAction<boolean>) => {
      state.stencilShapesLoaded = action.payload;
    },
    setPortsHidden: (state, action: PayloadAction<boolean>) => {
      state.arePortsHidden = action.payload;
    },
    addGaugePortal: (state, action: PayloadAction<GaugeElement>) => {
      state.gauges.push(action.payload);
    },
    clearSynopticStore: () => initialState,
  },
});

export const {
  setRappid,
  setSynoptic,
  setSynopticName,
  setSynopticTags,
  setEditingMode,
  setNewElemMode,
  setMenuElement,
  setSynopticSelection,
  setStencilShapesLoaded,
  setPortsHidden,
  addGaugePortal,
  clearSynopticStore,
} = synopticSlice.actions;

export default synopticSlice.reducer;
