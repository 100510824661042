import { CommentInfo, UUID } from '@dametis/core';

import { EntityType } from 'types/comment';

export type EntityFromComment = { uuid: UUID; type: EntityType };

export const getEntityFromComment = (comment: Partial<CommentInfo>): EntityFromComment | null => {
  if (comment.projectId) {
    return { uuid: comment.projectId, type: EntityType.PROJECT };
  }
  if (comment.reportId) {
    return { uuid: comment.reportId, type: EntityType.REPORT };
  }
  if (comment.variableId) {
    return { uuid: comment.variableId, type: EntityType.VARIABLE };
  }
  if (comment.taskId) {
    return { uuid: comment.taskId, type: EntityType.TASK };
  }
  if (comment.alarmId) {
    return { uuid: comment.alarmId, type: EntityType.ALARM };
  }
  return null;
};
