import { FC } from 'react';

import { FolderInfo } from '@dametis/core';

import { MenuTab } from '../LegoCatalog';

import FolderContent from './FolderContent';
import NoFolderTypesContent from './NoFolderContent';

export const ARROW_CLASSNAME = 'arrow';

export interface CatalogContentProps {
  currentFolder?: FolderInfo;
  selectedMenuTab: MenuTab;
}

const CatalogContent: FC<CatalogContentProps> = ({ currentFolder = undefined, selectedMenuTab }) => (
  <>
    {selectedMenuTab === MenuTab.FOLDER_CONTENT && <FolderContent currentFolder={currentFolder} />}
    {selectedMenuTab === MenuTab.NO_FOLDER_CONTENT && <NoFolderTypesContent />}
  </>
);

export default CatalogContent;
